import appConfig from "../../../../appConfig";
import { Properties } from "../types";
import { ConfigMetaModel, Configuration, ConfigurationModel } from "./types/configuration";

class ConfigurationUtils {
  isCustomConfigType(configType: string) {
    return configType === appConfig.customConfigType;
  }

  modelToPayload(model: ConfigurationModel, typePayloadProperties: Properties): Configuration {
    const {
      name,
      createdTime,
      data,
      deleted,
      enabled,
      id,
      tags,
      type,
      version
    } = model;

    const labels: Record<string, string> = {};
    (tags || []).map(tag => {
      let key = "";
      let val = "";
      if (tag.indexOf("=") > 1) {
        const tagArr = tag.split("=");
        key = tagArr[0];
        val = tagArr[1];
      } else {
        key = val = tag;
      }
      return labels[key] = val;
    });

    const configPayload: Configuration = {
      id: {
        typeId: type,
        uuid: id
      },
      name,
      labels,
      payload: {},
      enabled,
      deleted,
      createdTime: createdTime.toString(),
      version: version.toString()
    };

    const isCustomConfigType = this.isCustomConfigType(type);

    if (isCustomConfigType) {
      const propertiesToAdd = Object.keys(data);
      propertiesToAdd.forEach((property) => {
        configPayload.payload[property] = {
          stringVal: data[property],
        };
      });
    } else {
      const propertiesToAdd = Object.keys(typePayloadProperties);
      propertiesToAdd.forEach((property) => {
        const propertyValue = typePayloadProperties[property];
        const payloadKey = propertiesTypeValueMap[propertyValue] || 'stringVal';
        configPayload.payload[property] = {
          [payloadKey]: data[property],
        };
      });
    }

    return configPayload;
  }

  payloadToModel(payload: Configuration, typePayloadProperties: Properties): ConfigurationModel {
    const {
      name = '',
      id: configId = { uuid: '',
        typeId: '' },
      payload: dataPayload,
      enabled = false,
      deleted = false,
      labels = {},
      createdTime = new Date().getTime()
        .toString(),
      version = "1"
    } = payload;
    const {
      uuid: id,
      typeId: type
    } = configId;


    const tags = Object.entries(labels).map(label => (label[0] === label[1]) ? label[0] : `${label[0]}=${label[1]}`);

    const data: Record<string,string> = {};

    const isCustomConfigType = this.isCustomConfigType(type);
    const propertiesToAdd = isCustomConfigType ? Object.keys(dataPayload) : Object.keys(typePayloadProperties);

    propertiesToAdd.forEach((typePayloadProperty) => {
      const level1 = dataPayload[typePayloadProperty] || Object.values(dataPayload)[0]; // fallback for existing configs
      const level2 = Object.values(level1)[0];

      if (typeof level2 === 'string') {
        data[typePayloadProperty] = level2;
      } else {
        data[typePayloadProperty] = JSON.stringify(level2);
      }
    });


    const model: ConfigurationModel = {
      id,
      name,
      type,
      data,
      deleted,
      enabled,
      tags,
      createdTime: parseInt(createdTime, 10),
      version: parseInt(version, 10)
    };
    return model;
  }

  payloadToMeta(data: Partial<Configuration>, typeName: string): ConfigMetaModel {
    const {
      name = '',
      id: configId = { uuid: '',
        typeId: '' },
      enabled = false,
      labels = {},
      createdTime = "0",
      version = "0",
      payload
    } = data;

    const { uuid: id, typeId: type } = configId;
    const tags = Object.entries(labels).map(label => (label[0] === label[1]) ? label[0] : `${label[0]}=${label[1]}`);

    return {
      id,
      type,
      tags,
      enabled,
      name,
      version: parseInt(version, 10),
      createdTime: parseInt(createdTime, 10),
      typeStr: typeName,
      payload
    };
  }
}

const propertiesTypeValueMap: Record<string, string> = {
  '_str': 'stringVal',
  '_bool': 'booleanVal',
  '_long': 'longVal',
  '_double': 'doubleVal',
  '_set': 'setValue',
  '_map': 'mapValue',
  '_objectmap': 'objectMapValue',
  '_date': 'dateVal',
  '_datetime': 'dateTimeVal',
  '_geo': 'geoShapeVal'
};

export const configurationUtils = new ConfigurationUtils();
