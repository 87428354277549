import { groupBy, isEmpty, without } from "lodash";
import { Entity, logger, Traffic } from "../../core";
import entityApiService from "../../services/api/EntityApiService";
import { EntityEnricher, EntityEnricherRegistry, EnrichedData } from "./types";
import { isEntity } from "./utils";
import { ExploreEntityEnricher } from "./ExploreEntityEnricher";
import { TimeSeriesEntityEnricher } from "./TimeseriesEntityEnricher";

const defaultEnricher: EntityEnricher<unknown> = {
  type: "default",
  parser: function <T = unknown>(data: T) {
    logger.debug("EntityEnricher", "Data Parser Fallback to default", data);
    return [];
  },

  enrich: function <T = unknown, E = Entity[]>(data: T[], e: E) {
    logger.debug("EntityEnricher", "Data Enrich Fallback to default", {
      data,
      e
    });
    return data;
  }
};

export const entityEnricherRegistry: EntityEnricherRegistry = {
  types: [] as string[],
  defaultEnricher: defaultEnricher,
  enrichers: new Map<string, EntityEnricher<any>>(),
  enrichmentGlobalEntityMap: new Map<string, string>(),   // An id vs name map populated from different places.

  async enrichData<T>(type: string, data: T[],
    startTimeMillis = 0, endTimeMillis = new Date().getTime()): Promise<EnrichedData<T>> {
    let enricher = this.enrichers.get(type);
    if (!enricher) {
      logger.debug("Entity Enrichment", "No Enrichers passed, using default enricher ");
      enricher = this.defaultEnricher;
    }

    const noEnrichmentResponse = {
      data: data,
      entities: new Map()
    } as EnrichedData<T>;

    if (!data) {
      return Promise.resolve(noEnrichmentResponse);
    }

    if (data) {
      try {
        if (enricher.skipEntityDataFetch) {
          const enrichedData = enricher.enrich(data, this.enrichmentGlobalEntityMap) as T[];
          return Promise.resolve({
            data: enrichedData,
            entities: this.enrichmentGlobalEntityMap
          });
        } else {
          const entities = enricher.parser(data, isEntity);
          if (!isEmpty(entities)) {
            const subsetEntities = without(entities, ...(Array.from(this.enrichmentGlobalEntityMap.keys())));
            const grouped = groupBy(subsetEntities, e => e.startsWith("i_t") ? "traffic" : "entity");

            for (const [key, trafficOrEntityArr] of Object.entries(grouped)) {
              if (key === "traffic") {
                const trafficObjs: Traffic[] = await entityApiService.fetchTrafficForIds(trafficOrEntityArr, startTimeMillis, endTimeMillis);
                trafficObjs.forEach(t => {
                  const { src, context, target } = t;
                  [src, context, target].filter(Boolean).forEach(e => {
                    this.enrichmentGlobalEntityMap.set(e.id, e.name);
                  });
                  const sourceName = (src || context)?.name;
                  const targetName = target?.name;
                  this.enrichmentGlobalEntityMap.set(t.id, `${sourceName} -> ${targetName}`);
                });
              }

              if (key === "entity") {
                const entityObjs = await entityApiService.fetchEntityForIds(trafficOrEntityArr, startTimeMillis, endTimeMillis);
                entityObjs.forEach(e => this.enrichmentGlobalEntityMap.set(e.id, e.name));
              }
            }

            if (!isEmpty(this.enrichmentGlobalEntityMap)) {
              const enrichedData = enricher.enrich(data, this.enrichmentGlobalEntityMap) as T[];
              return Promise.resolve({
                data: enrichedData,
                entities: this.enrichmentGlobalEntityMap
              });
            } else {
              return Promise.resolve(noEnrichmentResponse);
            }
          }
          return Promise.resolve(noEnrichmentResponse);
        }
      } catch (error) {
        logger.error("Entity Enrich Error", "Error while enriching data with entities", error);
        return Promise.resolve(noEnrichmentResponse);
      }
    }
    return Promise.resolve(noEnrichmentResponse);
  },

  registerEnricher<T>(type: string, enricher: EntityEnricher<T>) {
    this.enrichers.set(type, enricher);
  },

  addToEntityToCache(entityNames: Record<string, string>) {
    for (const [entityId, entityName] of Object.entries(entityNames || {})) {
      if (entityId && entityName) {
        this.enrichmentGlobalEntityMap.set(entityId, entityName);
      }
    }
  },

  resetEntityCache() {
    this.enrichmentGlobalEntityMap = new Map();
  },

  lookupEntityCache(entityId: string): string {
    if (this.enrichmentGlobalEntityMap.has(entityId)) {
      return this.enrichmentGlobalEntityMap.get(entityId);
    }
    return entityId;
  },

  getEnrichmentGlobalEntityMap(): Map<string, string> {
    return this.enrichmentGlobalEntityMap;
  }

};

entityEnricherRegistry.registerEnricher('explore', ExploreEntityEnricher(entityEnricherRegistry));
entityEnricherRegistry.registerEnricher('timeseries', TimeSeriesEntityEnricher);
