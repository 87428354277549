import { EntityPropertyMap, EntityPropertySet, ObjectPropertyMap, EntityPropertyValue } from "../../core";
import { PrimType, ParamValueTemplate, TypedObjectValue } from "../../services/api/operationalise";
import { getPropertyKeyForDataType } from "../../utils";


type Value = string | number | boolean | EntityPropertyMap | EntityPropertySet | ObjectPropertyMap;

export const getPropertyValueByDataType = (value: Value, dataType: PrimType) => {
  const propertyValue: EntityPropertyValue = {};

  switch (dataType) {
    case "_bool": {
      propertyValue.booleanVal = value as boolean;
      break;
    }

    case "_date": {
      propertyValue.dateVal = value as string;
      break;
    }

    case "_datetime": {
      propertyValue.dateTimeVal = value as string;
      break;
    }

    case "_double": {
      propertyValue.doubleVal = value as number;
      break;
    }

    case "_long": {
      propertyValue.longVal = value as number;
      break;
    }

    case "_str": {
      propertyValue.stringVal = value as string;
      break;
    }

    case "_map": {
      propertyValue.mapValue = value as EntityPropertyMap;
      break;
    }

    case "_set": {
      propertyValue.setValue = value as EntityPropertySet;
      break;
    }

    default: break;
  }

  return propertyValue;
};

export const getValueFromParamValue = (paramValue: ParamValueTemplate, dataType: PrimType, subType?: string) => {
  const {
    value,
    templateId,
    templateCanvas,
    templateSection
  } = paramValue || {};

  if (subType === 'template-canvas') {
    return templateId || templateCanvas || value;
  }

  if (subType === 'template-section') {
    return templateSection;
  }

  if (value) {
    const key = getPropertyKeyForDataType(dataType);
    return value[key];
  }

  return '';
};

export const getLabelFromParamValue = (paramValue: ParamValueTemplate) => {
  const { label } = paramValue || {};
  if (label) {
    return label;
  }
  return '';
};

export const getValueFromUITypedObject = (paramValue: TypedObjectValue, dataType: PrimType): Value => {
  if (paramValue) {
    const key = getPropertyKeyForDataType(dataType);
    const paramVal = paramValue[key];
    return paramVal;
  }
  return '';
};
