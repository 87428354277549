import { UserServiceTuple, TimeObj } from "../../explore";

export interface OpSchedule {
  schedule: Schedule;
  startOffset: TimeObj;
  endOffset: TimeObj;
  labels?: Record<string, string>;
}

export interface Schedule {
  specificScheduleConfig?: SpecificScheduleConfig;
  execTimeEpochSecs?: number;

  startTimeEpochSecs: number;
  endTimeEpochSecs: number;

  timeZone?: string; // IANA timezone
}

export interface PrimaryBizIdProps {
  bizEntityTypeId?: string;
  eventTypes?: UserServiceList;
}

export interface SecondaryBizIdProps {
  widgetId?: string;
  cohortId?: string;
  labels?: Record<string, string>;
}
export interface BizIdProps {
  primary: PrimaryBizIdProps;
  secondary: SecondaryBizIdProps;
}

export interface UserServiceList {
  userServiceInfo: UserServiceTuple[];
}

export interface SpecificScheduleConfig {
  minute?: ScheduleDefForUnit;
  hour?: ScheduleDefForUnit;
  dayOfMonth?: ScheduleDefForUnit;
  month?: ScheduleDefForUnit;
  dayOfWeek?: ScheduleDefForUnit;
}

export interface ScheduleDefForUnit {
  step?: number;
  range?: OpScheduleDefUnitRange;
  list?: OpScheduleDefUnitList;
}

export interface OpScheduleDefUnitList {
  list: number[];
}

export interface OpScheduleDefUnitRange {
  min: number;
  max: number;
}

export enum ActionRunMode {
  runModeUnset = "runModeUnset",
  bulk = "bulk",
  loop = "loop"
}

export interface CauseMetaData {
  causes: string[];
  causeGraphContext: string;
  actionsContext: string;
}

export enum OpType {
  // real_time, trend, outlier, multi_dim, series_change
  OP_TYPE_NA = "OP_TYPE_NA",
  REAL_TIME = "REAL_TIME",
  TREND = "TREND",
  TIME_OUTLIER = "TIME_OUTLIER",
  SERIES_OUTLIER = "SERIES_OUTLIER",
  MULTI_DIMENSION_ANALYSIS = "MULTI_DIMENSION_ANALYSIS",
  SERIES_CHANGE = "SERIES_CHANGE",
  OP_ANALYSIS = "OP_ANALYSIS"
}