import { cx } from "emotion";
import { isUndefined } from "lodash";
import React, { AllHTMLAttributes, CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

interface IncSmartTextProps extends Omit<AllHTMLAttributes<HTMLDivElement>, 'title'> {
  text: string;
  numLines?: number;
  hideTooltip?: boolean;
  textClass?: string; //by default it is inc-text-body
}

const IncSmartText: React.FC<IncSmartTextProps> = memo((props) => {
  const {
    text,
    hideTooltip = false,
    textClass = '',
    className: pClassName = '',
    numLines = 1,
    ...restProps
  } = props;

  const textElementRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const compareSize = useCallback(() => {
    const div = textElementRef?.current;
    const {
      scrollWidth,
      clientWidth,
      scrollHeight,
      clientHeight
    } = div || {};
    if (div && !isUndefined(scrollWidth) && !isUndefined(clientWidth) && !isUndefined(scrollHeight) && !isUndefined(clientHeight)) {
      setShowTooltip(scrollWidth > clientWidth || scrollHeight > clientHeight);
    }
  }, []);

  const resizeObserver = useMemo(() => new ResizeObserver(compareSize), [compareSize]);

  useEffect(() => {
    const div = textElementRef.current;
    if (div && resizeObserver.observe) {
      resizeObserver.observe(div);
      return () => resizeObserver.unobserve(div);
    }
  }, [compareSize, resizeObserver]);

  const className = cx("inc-smart-text", {
    [textClass]: Boolean(textClass),
    [pClassName]: Boolean(pClassName)
  });

  const title = (hideTooltip || !showTooltip) ? "" : text;

  const style = useMemo<CSSProperties>(() => numLines && numLines > 1 ? {
    ...(restProps.style || {}),
    display: '-webkit-box',
    WebkitLineClamp: numLines,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal'
  } : restProps.style || {}, [numLines, restProps.style]);

  return <div
    className={className}
    ref={textElementRef}
    title={title}
    {...restProps}
    style={style}
  >
    {text}
  </div>;
});

export default IncSmartText;
