import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Tag } from './types';
import IncTag from './Tag';

type IncTagsGroupProps = {
  tags: Tag[];

  onTagClick?: (tag: Tag) => void;

  // This is used when you want to toggle selection in the tags group. If you simply
  // want to show the tags set this to false
  enableToggleMode?: boolean;

  /**
   * If enableSelectionMode is enabled optionally pass in the default Selected
   */
  defaultSelected?: Tag;

  /**
   * Class name to be applied to the tag
   */
  tagClassName?: string;

  /**
   * This is ideally to be used only when dynamically setting a css property
   * Use classname if styles are known ahead of time.
   */
  tagStyle?: React.CSSProperties;
};

const IncTagsGroup: React.FC<IncTagsGroupProps> = ((props) => {

  const {
    tags,
    onTagClick,
    enableToggleMode,
    defaultSelected,
    tagClassName,
    tagStyle
  } = props;

  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(defaultSelected);

  const onClickInternal = useCallback((tag: Tag) => {
    if (enableToggleMode) {
      setSelectedTag(tag);
    }

    onTagClick && onTagClick(tag);
  }, [enableToggleMode, onTagClick]);

  const isTagSelected = useCallback((tag) => {
    if (enableToggleMode) {
      return selectedTag && selectedTag.id === tag.id;
    }

    // If we are not in toggle mode, we want the tag to be rendered as selected
    // since that is the default style
    return true;
  }, [enableToggleMode, selectedTag]);

  useEffect(() => {
    if (!isEqual(selectedTag, defaultSelected)) {
      setSelectedTag(defaultSelected);
    }
  }, [defaultSelected, selectedTag]);

  return (
    <div className='inc-flex-row-wrap'>
      {
        tags && tags.map((t) =>
          <div className='marginRt4' key={t.id}>
            <IncTag
              isNotSelected={!isTagSelected(t)}
              onClick={enableToggleMode ? onClickInternal : null}
              tag={t}
              tagClassName={tagClassName}
              tagStyle={tagStyle}
            />
          </div>
        )
      }
    </div>
  );
});

export default IncTagsGroup;
