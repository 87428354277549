import React, { FC, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { cx, css } from 'emotion';
import { ISaxIcon } from '../icons';

interface Props {
  children: string | JSX.Element | JSX.Element[];
  initHeight: number;

  heightOffset?: number;
  className?: string;
  expandText?: string | JSX.Element;
  onExpand?: () => void;
}

export const AutoExpandContent: FC<Props> = (props) => {
  const {
    children,
    initHeight,
    expandText = 'Read more',
    onExpand,
    heightOffset = initHeight,
    className: pClassName = ''
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(initHeight);
  const [shouldShowExpand, setShouldShowExpand] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const { scrollHeight } = ref.current;
      setShouldShowExpand(scrollHeight > height);
    }
  }, [height]);

  const onExpandClick = useCallback(() => {
    setHeight(prev => prev + heightOffset);
    onExpand && onExpand();
  }, [heightOffset, onExpand]);

  const styleClassName = useMemo(() => css`
    max-height: ${height}px;
    overflow: hidden;
  `, [height]);

  const className = useMemo(() => cx("inc-auto-expand-content", {
    [pClassName]: Boolean(pClassName)
  }), [pClassName]);

  return <div className={className}>
    <div className={styleClassName} ref={ref}>
      {children}
    </div>

    {shouldShowExpand && <div
      className="link-button inc-flex-center-vertical marginTp16"
      onClick={onExpandClick}
    >
      <div className="inc-flex-row inx-flex-center-vertical marginRt8 link-text">
        {expandText}
      </div>
      <ISaxIcon className="link-text" iconName="ArrowDown2" variant="Outline" />
    </div>}
  </div>;
};
