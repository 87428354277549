import { IncSelectOption, generateId } from '@inception/ui';
import { isEmpty, values, cloneDeep, omit, uniq, forEach } from 'lodash';
import { duration } from 'moment';
import {
  BizEntityMetricConfig, BizEntityMetricConfigDefinition, UserServiceFieldMetricConfig, UserServiceFieldMetricConfigDefinition,
  UserServiceMetricConfig, UserServiceMetricConfigDefinition, WidgetConfigDTO, WidgetConfigModes, WidgetResponseDTO,
  MetricDefinition, Visualization, UserServiceFieldSliceSet, SliceSet, WidgetConfigUtils, UserServiceField, MetricDefLabels,
  UserServiceFilterExpression, ConditionWithPickerType, TraceUIAggregation, UserServiceFieldSlice, WidgetQuerySchema,
  DataDefinition, SelectorFilter, WidgetConfig, CohortConfig
} from '../services/api/explore';
import { DataType, getAggregationFromAggrValue, CoreNumericDataTypes } from '../core';
import { isEntityField } from '../field-picker';
import { MetricAggregatorExpanded } from '../core/data/types/MetricTypes';
import { TagFilterSelectionBySliceSet } from '../biz-entity';
import { BizService } from '../services/api/explore/BizServiceCommon';
import { ENTITY_TAG } from './MetricNameUtils';
import { pluralizeWord } from "./Utils";

const maxAggMetrics = 4;

export const EXPRESSION_TAG = 'expression';
export const QUERY_LOOKUP_KEY = 'queryLookupId';

export const getOptionFromString = (s: string) => {
  const option: IncSelectOption = {
    label: s,
    value: s
  };
  return option;
};

const validateUserServiceFieldMetricConfig = (config: UserServiceFieldMetricConfig) => {
  if (isEmpty(config) && isEmpty(config.userServiceField)) {
    return false;
  }
  return true;
};

const validateBizEntityMetricConfig = (config: BizEntityMetricConfig) => {
  if (isEmpty(config) && isEmpty(config.metricName)) {
    return false;
  }
  return true;
};

const validateUserServiceMetricConfig = (config: UserServiceMetricConfig) => {
  if (isEmpty(config) && isEmpty(config.metricName)) {
    return false;
  }
  return true;
};

const validateVisualizations = (widgetConfig: WidgetConfigDTO, metricIds: string[]) => {
  const vizlist = widgetConfig.visualizations;
  let numMetricsVisualised = 0;

  for (let i = 0; i < metricIds.length; i++) {
    let count = 0;
    for (let j = 0; j < vizlist.length; j++) {
      const occurances = vizlist[j].dataDefs.filter(x => x.id === metricIds[i]).length;
      // metric id should not be duplicated in data def list
      if (occurances > 1) {
        return false;
      } else if (occurances === 1) {
        ++count;
      }
    }
    // metric id should occur at least once in at least one visualisation
    numMetricsVisualised += Number(count !== 0);
  }
  return numMetricsVisualised > 0;
};

export const validateWidgetConfig = (widgetResponse: Partial<WidgetResponseDTO>, mode: WidgetConfigModes) => {
  const widgetConfig: WidgetConfigDTO = { ...widgetResponse.widgetConfig };

  const isNounFirst = !isEmpty(widgetConfig.bizEntityType);
  const isVerbFirst = !isEmpty(widgetConfig.userServiceEntityId);

  if (!widgetConfig?.name || !(isNounFirst || isVerbFirst) || isEmpty(widgetConfig.dataDefinition)) {
    return false;
  }

  if (mode === "edit" && !widgetResponse.widgetId) {
    return false;
  }

  const { fields, metrics } = widgetConfig.dataDefinition;

  const fieldIds = Object.keys(fields);
  const metricIds = Object.keys(metrics);

  const numFields = fieldIds.length;
  const numMetrics = metricIds.length;

  const metricsValid = metricIds.reduce((prevValid, metricId) => {
    const metricDef = metrics[metricId];
    if (metricDef) {
      const { id, name, sourceType } = metricDef;
      if (!id || !name || !sourceType) {
        return false;
      }

      const usFieldMetricDef = metricDef as UserServiceFieldMetricConfigDefinition;
      const usMetricDef = metricDef as UserServiceMetricConfigDefinition;
      const bizMetricDef = metricDef as BizEntityMetricConfigDefinition;

      if (sourceType === "userServiceField" && !validateUserServiceFieldMetricConfig(usFieldMetricDef.userServiceFieldMetricConfig)) {
        return false;
      } else if (sourceType === "bizEntityMetric" && !validateBizEntityMetricConfig(bizMetricDef.bizEntityMetricConfig)) {
        return false;
      } else if (sourceType === "userServiceMetric" && !validateUserServiceMetricConfig(usMetricDef.userServiceMetricConfig)) {
        return false;
      } else {
        return prevValid && true;
      }
    } else {
      return false;
    }
  }, true);

  if (!metricsValid) {
    return false;
  }

  if (!numFields && !numMetrics) {
    return false;
  }

  if (numMetrics) {
    return validateVisualizations(widgetConfig, metricIds);
  }

  return true;
};

export const generateWidgetName = (widgetConfigDto: WidgetConfigDTO, defaultMetricName = '', defaultCohortName = ''): string => {
  const entityNamePrefix = widgetConfigDto.bizEntityType ? (widgetConfigDto.entityTypeName || "") : (widgetConfigDto.userServiceEntityName || "");
  const metricName = values(widgetConfigDto.dataDefinition.metrics)[0]?.name || defaultMetricName;
  const cohortName = widgetConfigDto?.cohortDefinition?.name || defaultCohortName;

  const newName = [entityNamePrefix, cohortName, metricName].filter(name => !isEmpty(name)).join(' - ');
  return newName;
};

export function getWidgetResponseClone(response: WidgetResponseDTO) {
  const wrDTO = cloneDeep(response);
  const { widgetConfig, querySchema } = wrDTO;
  const { dataDefinition: { metrics } } = widgetConfig;

  const metricIdMap: Record<string, string> = {};
  const newMetricDefList = Object.keys(metrics)
    .map(id => {
      const newMetric = metrics[id];
      newMetric.id = generateId();
      newMetric.operationalizeConfig = {
        compareConfigs: {}
      };
      delete newMetric.internalFetchConfig;

      metricIdMap[id] = newMetric.id;

      return newMetric;
    });

  const newMetrics: Record<string, MetricDefinition> = {};
  newMetricDefList.forEach(metric => {
    const metricId = metric.id;
    newMetrics[metricId] = metric;
    const isAggMetric = isSystemEntityAggMetric(metric);
    if (isAggMetric) {
      const bizMetricDef = metric as BizEntityMetricConfigDefinition;
      const { bizEntityMetricConfig, labels } = bizMetricDef;
      const oParentMetricId = labels?.parentMetricId || '';
      const nParentMetricId = metricIdMap[oParentMetricId];
      if (oParentMetricId && nParentMetricId && bizEntityMetricConfig) {
        metric.labels = {
          ...labels,
          parentMetricId: nParentMetricId
        };
        bizEntityMetricConfig.metricId = nParentMetricId;
      }
    }
  });
  wrDTO.widgetConfig.dataDefinition.metrics = newMetrics;

  const viz: Visualization[] = response.widgetConfig.visualizations.map(v => ({
    type: v.type,
    id: generateId(),
    dataDefs: newMetricDefList.map(m => ({
      id: m.id,
      type: m.sourceType,
      enabled: true
    }))
  }));
  wrDTO.widgetConfig.visualizations = viz;
  wrDTO.widgetId = "";
  wrDTO.version = 0;
  wrDTO.querySchema = querySchema;

  return wrDTO;
}

// coverts User Service field sliceset to tag slice
export const convertUSFieldSliceSetToTagSlice = (sliceSet: UserServiceFieldSliceSet): SliceSet => (
  WidgetConfigUtils.convertUSFieldSliceSetToTagSlice(sliceSet)
);

export const convertToSliceSet = (sliceSet: SliceSet | UserServiceFieldSliceSet): SliceSet => {
  const ss = sliceSet as SliceSet;
  const uss = sliceSet as UserServiceFieldSliceSet;
  const isUSSliceSet = !isEmpty(uss.slices[0]?.userServiceField);
  const rSliceSet = isUSSliceSet ? convertUSFieldSliceSetToTagSlice(uss) : ss;
  return rSliceSet;
};

const getAggregatedMetricDefs = (metricDef: MetricDefinition): MetricDefinition[] => {
  const usFieldMetricDef = metricDef as UserServiceFieldMetricConfigDefinition;
  const {
    userServiceFieldMetricConfig,
    id: parentMetricId,
    name: parentMetricName
  } = usFieldMetricDef;

  if (userServiceFieldMetricConfig) {
    const systemCreatedMetricAggs = getEntityMetricAggregations();
    const bizEntityAggMetrics: MetricDefinition[] = [];

    systemCreatedMetricAggs.forEach(agg => {
      const aggBizMetricDefs = getAggBizMetricConfigs(agg, parentMetricId, parentMetricName, userServiceFieldMetricConfig);
      bizEntityAggMetrics.push(...aggBizMetricDefs);
    });

    return bizEntityAggMetrics;
  }
  return [];
};

const removeAggMetricsForMetric = (widgetConfigDto: WidgetConfigDTO, metricDef: MetricDefinition): Record<string, MetricDefinition> => {
  const { id: parentMetricId } = metricDef;
  const { dataDefinition: { metrics }, visualizations } = widgetConfigDto;

  const aggMetricIds = Object.keys(metrics).filter(id => {
    const mDef = metrics[id];
    return isRelatedEntityAggMetric(mDef, parentMetricId);
  });

  visualizations.forEach(viz => {
    viz.dataDefs = viz.dataDefs.filter(def => !aggMetricIds.includes(def.id));
  });
  widgetConfigDto.visualizations = visualizations.filter(v => !isEmpty(v.dataDefs));

  // Remove all related agg metrics
  const newMetrics: Record<string, MetricDefinition> = omit(metrics, aggMetricIds);
  return newMetrics;
};

const handleMetricAddOrUpdate = (widgetConfigDto: WidgetConfigDTO, metricDef: MetricDefinition) => {
  const { id: parentMetricId } = metricDef;

  // Remove all related agg metrics
  const newMetrics: Record<string, MetricDefinition> = removeAggMetricsForMetric(widgetConfigDto, metricDef);
  const isAggMetric = isSystemEntityAggMetric(metricDef);
  const generateCohortMetrics = shouldGenerateCohortMetrics(metricDef);
  const excludeDataFetch = (isAggMetric && generateCohortMetrics).toString();

  /**
   * Skip saving the USFM since it'll add redundant metrics.
   * Exclude the USFM from the selector spec since we fetch the data using
   * biz metrics.
   */
  metricDef.doNotSave = isAggMetric;
  metricDef.labels = {
    ...(metricDef.labels || {}),
    excludeDataFetch
  };

  if (generateCohortMetrics) {
    const nAggDefs = getAggregatedMetricDefs(metricDef);
    nAggDefs.forEach(newMetricDef => {
      newMetrics[newMetricDef.id] = newMetricDef;
    });
    widgetConfigDto.visualizations.forEach(viz => {
      const isRelatedViz = viz.dataDefs.some(def => def.id === parentMetricId);
      if (isRelatedViz) {
        nAggDefs.forEach(amDef => viz.dataDefs.push({
          id: amDef.id,
          type: amDef.sourceType,
          enabled: true
        }));
      }
    });
  }

  widgetConfigDto.dataDefinition.metrics = newMetrics;
};

const handleMetricRemove = (widgetConfigDto: WidgetConfigDTO, metricDef: MetricDefinition) => {
  // Remove all related agg metrics
  const newMetrics: Record<string, MetricDefinition> = removeAggMetricsForMetric(widgetConfigDto, metricDef);
  widgetConfigDto.dataDefinition.metrics = newMetrics;
};

export const getEntityMetricAggregations = () => {
  const aggs = ["sum", "max", "min", "count"];
  return uniq(aggs).slice(0, maxAggMetrics);
};

export const isSystemCreatedMetric = <T extends MetricDefLikeObj>(metricDef: T): boolean => {
  const { labels = {} } = metricDef || {};
  const { systemCreated } = labels;
  return systemCreated === "true";
};

export const isRelatedEntityAggMetric = <T extends MetricDefLikeObj>(metricDef: T, parentMetricId: string): boolean => {
  const { labels = {} } = metricDef || {};
  const { systemCreated, parentMetricId: metricParentId } = labels;
  return systemCreated === "true" && metricParentId === parentMetricId;
};

export const isSystemEntityAggMetric = (metricDef: MetricDefinition): boolean => {
  const { labels = {} } = metricDef || {};
  const { isAggMetric, systemCreated } = labels;
  return isAggMetric === "true" && systemCreated === "true";
};

/**
 * Function decides if a metric has any slices available at all.
 * If no slices are available and an aggregator is present a metric is considered to be aggregated.
 * @param metricDef
 */
export const isAggregatedMetric = (metricDef: MetricDefinition): boolean => {
  const { labels = {} } = metricDef || {};
  const { isAggMetric, systemCreated } = labels;
  return isAggMetric === "true" && systemCreated === "true";
};

export const shouldGenerateCohortMetrics = <T extends MetricDefLikeObj>(metricDef: T): boolean => {
  const { labels = {} } = metricDef || {};
  const { generateCohortMetrics } = labels;
  return generateCohortMetrics === "true";
};

export const shouldIncludeMetricInDataFetch = <T extends MetricDefLikeObj>(metricDef: T): boolean => {
  const { labels = {} } = metricDef || {};
  const { excludeDataFetch } = labels;
  return excludeDataFetch !== "true";
};

export const addOrRemoveAggMetricsFromWidgetConfigDto = (
  widgetConfigDto: WidgetConfigDTO,
  metricDef: MetricDefinition,
  removeMetric: boolean
) => {
  if (removeMetric) {
    handleMetricRemove(widgetConfigDto, metricDef);
  } else {
    handleMetricAddOrUpdate(widgetConfigDto, metricDef);
  }
};

const getAggBizMetricConfigs = (
  agg: string,
  parentMetricId: string,
  parentMetricName: string,
  usFieldConfig: UserServiceFieldMetricConfig
): BizEntityMetricConfigDefinition[] => {
  const { sliceSets = [] } = usFieldConfig;

  return sliceSets.map((uss): BizEntityMetricConfigDefinition => {
    const sliceSet = convertUSFieldSliceSetToTagSlice(uss);
    const metricName = getAggMetricName(agg, parentMetricName, sliceSet);

    return {
      id: generateId(),
      bizEntityMetricConfig: {
        metricId: parentMetricId,
        aggregator: agg,
        filter: [],
        metricName: parentMetricName,
        sliceSet
      },
      name: metricName,
      sourceType: "bizEntityMetric",
      labels: {
        systemCreated: "true",
        isAggMetric: "true",
        parentMetricId
      }
    };
  });
};

export const updateRelatedMetricDefNames = (
  parentMetricId: string,
  parentMetricName: string,
  metrics: Record<string, MetricDefinition>
) => {
  const metricIds = Object.keys(metrics);
  const parentMetric = metrics[parentMetricId];
  const sliceSet = WidgetConfigUtils.getSliceSetFromMetricDefinition(parentMetric);

  const relatedMetricIds = metricIds.filter(id => isRelatedEntityAggMetric(metrics[id], parentMetricId));
  relatedMetricIds.forEach(id => {
    const metricDef = metrics[id];
    const agg = getAggregatorFromMetricDefinition(metricDef);
    metricDef.name = getAggMetricName(agg, parentMetricName, sliceSet);
  });
};

const getAggMetricName = (agg: string, parentMetricName: string, sliceSet: SliceSet | UserServiceFieldSliceSet): string => {
  const tagNames = WidgetConfigUtils.getTagNamesFromSliceSet(sliceSet).filter(t => t !== ENTITY_TAG);
  const tagNamesStr = tagNames.join(", ");
  const tagNamesSuffix = tagNamesStr ? ` by ${tagNamesStr}` : "";
  const metricName = `${parentMetricName}${tagNamesSuffix}`;
  return `${agg} (${metricName})`;
};

export const getUSFieldSuffixForTraceQuery = (usField: UserServiceField) => {
  if (usField) {
    const {
      dataType,
      entityField
    } = usField;

    if (isEntityField(dataType) && entityField) {
      return `$${entityField.entityType}`;
    }
  }

  return "";
};

export const getUSFieldNameForTraceQuery = (usField: UserServiceField) => {
  const fieldSuffix = getUSFieldSuffixForTraceQuery(usField);
  return usField?.fieldName ? usField.fieldName + fieldSuffix : '';
};

export const getAggregatedCohortName = (metricDef: MetricDefinition, cohortName: string, entityTypeName: string) => {
  const isCohortAggregatedMetric = isSystemCreatedMetric(metricDef) && isSystemEntityAggMetric(metricDef);
  let cName = cohortName;
  if (!cName) {
    cName = entityTypeName ? `All ${pluralizeWord(entityTypeName)}` : "";
  }
  return isCohortAggregatedMetric ? `${cName} cohort` : '';
};

export const getImplicitSlice = (usField: UserServiceField) => WidgetConfigUtils.getImplicitSlice(usField);

type MetricDefLikeObj = {
  labels?: MetricDefLabels;
};

export const getOperators = (dataType: DataType) => {
  let opList = ["in", "not in", "=", "!=", "=~", "!~", ">", ">=", "<", "<="];
  if (dataType) {
    if (CoreNumericDataTypes.includes(dataType)) {
      opList = ["in", "not in", "=", "!=", ">", ">=", "<", "<="];
    } else {
      if (dataType === "ENTITY") {
        opList = ["in", "not in", "=", "=~", "!~"];
      } else {
        opList = ["in", "not in", "=", "!=", "=~", "!~"];
      }
    }
  }
  return opList.map((op) => getOptionFromString(op));
};

export const getConditionsFromUSFilterExpressions = (usFilters: UserServiceFilterExpression[]) => {
  const conditions: ConditionWithPickerType[] = [];
  usFilters.forEach(usFilter => {
    if (usFilter) {
      const {
        field,
        operator,
        value,
        values
      } = usFilter;

      const condition: ConditionWithPickerType = {
        field: {
          type: "userServiceField",
          payload: field
        },
        operator,
        value,
        values
      };
      conditions.push(condition);
    }
  });

  return conditions;
};

export const getUSFilterExpressionsFromConditions = (conditions: ConditionWithPickerType[], skipValidation = false) => {
  const filters: UserServiceFilterExpression[] = [];
  conditions.forEach(condition => {
    const isFilterValid = skipValidation ? true : validateFilter(condition);
    if (isFilterValid) {
      const filter: UserServiceFilterExpression = {
        field: condition.field.payload as UserServiceField,
        operator: condition.operator,
        value: condition.value,
        values: condition.values
      };
      filters.push(filter);
    }
  });
  return filters;
};

export const getTraceUIAggregation = (aggregator: string): TraceUIAggregation => {
  const aggregation = getAggregationFromAggrValue(aggregator as MetricAggregatorExpanded);
  if (!aggregation) {
    return {
      aggrType: null,
      fraction: null
    };
  }
  return {
    aggrType: aggregation.type,
    fraction: aggregation.fractions
  };
};

export const validateFilter = (filter: ConditionWithPickerType) => filter &&
  (filter?.value === null || filter?.value || filter?.values?.length);

export const getSegmentationMapsFromUSFSliceSets = (sliceSets: UserServiceFieldSliceSet[]) => {
  const segs: Array<Map<string, UserServiceField>> = [];
  sliceSets.forEach(set => {
    const shouldAddSliceSet = !isImplicitUSFSliceSet(set);
    if (shouldAddSliceSet) {
      const map: Map<string, UserServiceField> = new Map();
      set.slices.forEach(slice => {
        const shouldAddSlice = !isImplicitUSFSlice(slice);
        if (shouldAddSlice) {
          map.set(slice.tagName, slice.userServiceField);
        }
      });
      segs.push(map);
    }
  });

  return segs;
};

export const getUSFSliceSetsFromSegmentationMaps = (
  segMaps: Array<Map<string, UserServiceField>>,
  implicitSliceUsField: UserServiceField) => {
  const sliceSets: UserServiceFieldSliceSet[] = [];
  segMaps.forEach(segMap => {
    if (segMap.size > 0) {
      const slices = getSlicesFromSegMap(segMap);
      sliceSets.push({
        slices
      });
    }
  });

  sliceSets.unshift({
    slices: []
  });

  if (implicitSliceUsField) {
    const implicitSlice: UserServiceFieldSlice = getImplicitSlice(implicitSliceUsField);
    sliceSets.forEach(ss => ss.slices.unshift(implicitSlice));
  } else {
    sliceSets.forEach(ss => ss.slices = ss.slices.filter(s => s.tagName !== ENTITY_TAG));
  }

  return sliceSets;
};

export const isImplicitUSFSliceSet = (ss: UserServiceFieldSliceSet): boolean => {
  const { slices } = ss;
  const numSlices = slices.length;
  return (numSlices === 0) || (numSlices === 1 && slices[0].tagName === ENTITY_TAG);
};

export const isImplicitUSFSlice = (s: UserServiceFieldSlice): boolean => s.tagName === ENTITY_TAG;

const getSlicesFromSegMap = (segmap: Map<string, UserServiceField>) => Array.from(segmap.keys()).map(key => ({
  tagName: key,
  userServiceField: segmap.get(key)
}));

export const getDefaultWidgetResponseDto = (
  entityType: string,
  entityId: string,
  entityName: string = null,
  entityTypeName: string = null,
  cohortDefinition?: CohortConfig
): WidgetResponseDTO => ({
  version: 0,
  widgetId: "",
  widgetConfig: {
    name: '',
    isStatic: false,
    bizEntityType: entityType || null,
    userServiceEntityId: entityType ? null : entityId || null,
    userServiceEntityName: entityType ? null : entityName || null,
    entityTypeName: entityTypeName || null,
    visualizations: [],
    cohortDefinition,
    dataDefinition: {
      metrics: {},
      fields: {}
    },
    labels: {}
  },
  //query schema is returned from server never created in UI. Adding this only to main typescript definition integrity.
  querySchema: {
    querySchema: []
  }
});

export const getDefaultWidgetConfigDto = (
  entityType: string,
  entityId: string,
  entityName: string = null,
  entityTypeName: string = null,
  cohortDefinition?: CohortConfig
) => {
  const widgetResponse = getDefaultWidgetResponseDto(entityType, entityId, entityName, entityTypeName, cohortDefinition);
  return widgetResponse.widgetConfig;
};

const countAggTypes: DataType[] = ["STRING", "ENTITY"];

export function getDefaultDataTypeAggregation(dataType: DataType): TraceUIAggregation {

  const agg: MetricAggregatorExpanded = countAggTypes.includes(dataType) ? "count" : "avg";

  const aggr = getAggregationFromAggrValue(agg);
  const uiAggr: TraceUIAggregation = {
    aggrType: aggr.type,
    fraction: aggr.fractions
  };
  return uiAggr;
}

export const getAggregatorFromMetricDefinition = (metricDefinition: MetricDefinition): string => {
  const {
    bizEntityMetricDef,
    usFieldMetricDef,
    usMetricMetricDef
  } = WidgetConfigUtils.getMetricDefinitions(metricDefinition);

  return bizEntityMetricDef?.bizEntityMetricConfig?.aggregator ||
    usFieldMetricDef?.userServiceFieldMetricConfig?.aggregator ||
    usMetricMetricDef?.userServiceMetricConfig?.aggregator || "avg";
};


export function getSliceSetsFromQuerySchema(querySchemas: WidgetQuerySchema[], metricId: string) {
  return querySchemas.filter(schema => schema.metricId === metricId).map(schema => schema.sliceSet);
}

export const extractSliceSetFromBizMetricDefinition = (bizEntityMetricDef: BizEntityMetricConfigDefinition): SliceSet => {
  if (!bizEntityMetricDef) {
    return {
      slices: []
    };
  }
  const { sliceSet, aggregator } = bizEntityMetricDef.bizEntityMetricConfig;
  /**
   * Temporary fix to remove the implicit slice from the slice set when aggregator exists.
   * This is being done since the implicit slice tag will not be part of the response
   * and thus the correlation between slices and tags will not be correct.
   */
  const fSliceSet = cloneDeep(sliceSet);
  if (aggregator) {
    fSliceSet.slices = fSliceSet.slices.filter(s => s.tagName !== ENTITY_TAG);
  }
  return fSliceSet;
};

export const extractSliceSetsFromDataDefinition = (
  dataDefinition: DataDefinition,
  querySchemas: WidgetQuerySchema[],
  skipIncludeInDataFetchCheck = false
): Record<string, SliceSet[]> => {
  const sliceSetsByMetricId: Record<string, SliceSet[]> = {};

  /**
   * WARNING!
   * We need to get data of all visible metrics.
   * Do not use getDisplayMetrics here.
   */
  forEach(dataDefinition.metrics, (mDef, id) => {
    const includeInDataFetch = skipIncludeInDataFetchCheck ? true : shouldIncludeMetricInDataFetch(mDef);
    const addMetric = mDef && includeInDataFetch;
    if (addMetric) {
      const sliceSets: SliceSet[] = getSliceSetsFromQuerySchema(querySchemas, mDef.id);
      sliceSetsByMetricId[id] = sliceSets;
    }
  });

  return sliceSetsByMetricId;
};

export const extractSelectorFiltersFromFilterSelection = (filterSelection: TagFilterSelectionBySliceSet): SelectorFilter[] => {
  const selectorFilters: SelectorFilter[] = [];

  if (filterSelection) {
    const { selectionValues } = filterSelection;
    selectionValues.forEach(sv => {
      const {
        tag,
        selValToDValArr
      } = sv;
      selValToDValArr.forEach((sValRec, sValIdx) => {
        const selectorFilter = selectorFilters[sValIdx] || {
          tags: []
        };
        const sVals = Object.keys(sValRec);
        if (sVals.length > 0) {
          selectorFilter.tags.push({
            key: tag,
            value: sVals
          });
        }
        selectorFilters[sValIdx] = selectorFilter;
      });
    });
  }

  return selectorFilters;
};

export const getWidgetConfigFromDto = (widgetConfigDto: WidgetConfigDTO): WidgetConfig => new BizService()
  .getWidgetConfigFromDto(widgetConfigDto);

export const getDtoFromWidgetConfig = (widgetConfig: WidgetConfig): WidgetConfigDTO => new BizService()
  .getDtoFromWidgetConfig(widgetConfig);

export const getUSFieldWidgetLabels = (entityType: string, eventTypeId: string, fieldName: string): Record<string, string> => ({
  querySource: 'userServiceFieldWidget',
  fieldName,
  eventTypeId,
  entityTypeId: entityType
});

export const getCatalogWidgetLabels = (entityType: string, eventTypeId: string, fieldName: string): Record<string, string> => ({
  querySource: 'catalogWidget',
  fieldName,
  eventTypeId,
  entityTypeId: entityType
});

export const getSeasonSecsStr = (seasonSecs: number): string => {
  if (seasonSecs === -1) {
    return "None";
  } else {
    const d = duration(seasonSecs, 's');
    const humanStr = d.humanize();
    const [magnitudeStr, seasonalityStr] = humanStr.split(" ", 2);
    const magnitude = parseInt(magnitudeStr, 10);

    if (isNaN(magnitude)) {
      return `Every ${seasonalityStr}`;
    } else {
      return `Every ${humanStr}`;
    }
  }
};
