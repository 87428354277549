import { TimeObj } from "../../explore";

export interface OpTriggerCondition {
  startTriggerCondition: WindowTriggerCondition;
  endTriggerCondition?: WindowTriggerCondition;
}

export interface WindowTriggerCondition {
  howManyTimesToViolate: number;
  outOfTime?: TimeObj;
  outOfPoints?: number;
}

export interface OpCloseTrigger {
  anyMatchStartTrigger?: AnyMatchStartTrigger;
}

export interface OpStartTrigger {
  // oneOf
  allMatchStartTrigger?: AllMatchStartTrigger;
  anyMatchStartTrigger?: AnyMatchStartTrigger;
}

export interface AllMatchStartTrigger {
  triggerConditions: TriggerCondition[];
}

export interface AnyMatchStartTrigger {
  triggerConditions: TriggerCondition[];
}

export interface TriggerCondition {  
  // oneOf
  windowTrigger?: WindowTriggerCondition;
  accumulatorTrigger?: AccumulatorBasedTrigger;
}

export enum TriggerType {
  WINDOW = "WINDOW",
  ACCUMULATOR = "ACCUMULATOR"
}

export interface AccumulatorBasedTrigger {
  unitDiff: number;

  // oneOf
  time?: TimeObj;
  count?: number;
}