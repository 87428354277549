import { Steps } from 'antd';
import { cx } from 'emotion';
import React, { FC, useMemo, useCallback, memo } from 'react';
import { IncStepsProps } from './types';


export const IncSteps: FC<IncStepsProps> = memo((props) => {
  const {
    steps,
    onChange,
    selectedStepId,
    className: pClassName = '',
    hideLabels = false,
    ...stepsProps
  } = props;

  const selectedStepIdx = useMemo(() => steps.findIndex(step => step.id === selectedStepId), [selectedStepId, steps]);

  const onStepChange = useCallback((stepIdx: number) => {
    const stepId = steps[stepIdx]?.id;
    onChange(stepId);
  }, [onChange, steps]);

  const className = useMemo(() => cx("inc-steps", {
    [pClassName]: Boolean(pClassName),
    "inc-steps--hide-labels": hideLabels
  }), [hideLabels, pClassName]);

  const stepItems = useMemo(() => steps.map(step => ({
    ...step,
    className: `inc-steps--step ${step.className || ''}`
  })), [steps]);

  return <Steps
    {...stepsProps}
    className={className}
    current={selectedStepIdx}
    direction="horizontal"
    items={stepItems}
    onChange={onStepChange}
    responsive
    type="default"
  />;
});
