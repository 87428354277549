import { TriageClustersResponse, UserServiceFieldSlice, WidgetData } from "../../explore";

export interface InsightMemberShipResponse {
  incidentSeriesCount: number;
  totalSeriesCount: number;

  isSpike: boolean;
  isSpikePositive: boolean; // Used to show spike as green

  impactData: number;
  impactDataStr: string;
  data: number;
  metricName: string;
}

// API to fetch incident series data, impact summary and cause summary
export enum GetIncidentInfoProjection {
  IMPACT_SUMMARY = "IMPACT_SUMMARY",
  CAUSE_SUMMARY = "CAUSE_SUMMARY",
  CAUSE_SUMMARY_ENGLISH = "CAUSE_SUMMARY_ENGLISH",
}

export interface GetIncidentInfoRequest {
  opConfigId: string;
  incidentId: string;
  projection: GetIncidentInfoProjection[];
}

export interface GetIncidentInfoResponse {
  impactSummary: ImpactSummary;
  causeSummary: TriageClustersResponse;
  entityLookupData: Record<string, string>;
}

export interface ImpactSummary {
  // oneOf
  widgetData?: WidgetData;
  impactData?: ImpactSummaryData;

  impactField: UserServiceFieldSlice;
}

export interface ImpactSummaryData {
  data: ImpactSummaryEntry[];
}

export interface ImpactSummaryEntry {
  tags: Record<string, string>;
  value: number;
  deviation: number;
}
