import { InceptionColorPalette, InceptionColors } from "./types";

// Keep in sync with vairables-light.scss
const colors: InceptionColors = {

  // Blacks
  BL900: '#FBFBFC',
  BL800: '#F6F7F8',
  BL700: '#F0F1F2',
  BL600: '#E2E9EE',
  BL500: '#C3D1DF',
  BL400: '#C3D1DF',
  BL300: '#829BB5',
  BL200: '#637D97',
  BL100: '#4B6681',
  BL50: '#324A62',
  BL25: '#1D3349',

  // Neutrals
  N900: '#112131',
  N800: '#1D3349',
  N700: '#324A62',
  N600: '#4B6681',
  N500: '#637D97',
  N400: '#829BB5',
  N300: '#A3B7CC',
  N200: '#C3D1DF',
  N100: '#E2E9EE',
  N50: '#F6F7F8',
  N25: '#FBFBFC',

  // Blues:
  B900: '#122B45',
  B800: '#163B60',
  B700: '#1A548E',
  B600: '#1F68BD',
  B500: '#1A7FEA',
  B400: '#4FA1F3',
  B350: "#60BFFF",
  B300: '#72B5F8',
  B200: '#9CCCFC',
  B100: '#C7E3FE',
  B50: '#EBF5FF',

  // Greens:
  G900: '#023C06',
  G800: '#09580E',
  G700: '#0F7115',
  G600: '#1D9525',
  G500: '#31B23A',
  G400: '#4BCE54',
  G300: '#6EDE75',
  G200: '#95EA9A',
  G100: '#BEF4C2',
  G50: '#E9FCEA',

  // Yellow:
  Y900: '#6A410C',
  Y800: '#8E5C1A',
  Y700: '#C88C14',
  Y600: '#EEAD20',
  Y500: '#F7C548',
  Y400: '#F4CC66',
  Y300: '#F7D987',
  Y200: '#FAE7AE',
  Y100: '#FBF0D0',
  Y50: '#FEF7E7',

  // Reds:
  R900: '#380401',
  R800: '#5A0802',
  R700: '#8F180A',
  R600: '#B52817',
  R500: '#D74837',
  R400: '#EC6555',
  R300: '#F5796B',
  R200: '#FB9284',
  R100: '#FEACA0',
  R50: '#FFDAD1',

  // Orange:
  O900: '#882802',
  O800: '#B64107',
  O700: '#DB5C14',
  O600: '#E67433',
  O500: '#E98349',
  O400: '#EC925E',
  O300: '#F4A77B',
  O200: '#F9B690',
  O100: '#FCD3BB',
  O50: '#FFECE1',

  chartPositive: '#30b239',
  chartNegative: '#ed6555'
};

export const inceptionLightColorPalette: InceptionColorPalette = {
  primary1: colors['B800'],
  primary2: colors['B500'],
  accentGreen: colors['G600'],
  accentYellow: colors['Y600'],
  accentRed: colors['R600'],
  accentOrange: colors['O600'],
  accentBlue: colors['B500'],
  screenBackground: colors['N50'],
  // All colors
  palette: colors
};

