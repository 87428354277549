import { useCallback } from "react";
import { useFetchDataStatus } from "../../../../core";
import fieldPickerApiService from "../FieldPickerApiService";
import { BizJourney } from "../types/fieldpickerTypes";


const useFetchBizJourneyList = () => {
  const {
    data: journeysList,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<BizJourney[], string>({ data: [] });

  const fetchJourneyList = useCallback(async () => {
    setStatus([], true, false, false, '');
    let journeysList: BizJourney[] = [];
    const {
      data,
      error,
      message
    } = await fieldPickerApiService.getJourneysList();

    if (!error) {
      journeysList = data;
    }

    const errMsg = error ? message : '';

    setStatus(journeysList, false, !error, error, errMsg);
  }, [setStatus]);

  return {
    journeysList,
    isError,
    isFetching,
    isSuccess,
    error,
    fetchJourneyList
  };
};

export default useFetchBizJourneyList;
