import { css } from "emotion";
import { getInceptionTheme } from "../../themes/ThemeProvider";
import { InceptionTheme } from "../../themes/types/theme";

const getQueryBuilderStyles = ((theme: InceptionTheme = getInceptionTheme()) => {

  const background = theme.inceptionColors.palette.BL600;
  const border = `none`;

  return {
    queryContainer: css`
            width: 100%;
            border-radius: 3px;
            border: ${border};
            height: 100%;
            `,
    editor: css`
            height: 100%;
            border: 0px;
            background: ${background};
        `,
  };
});


export {
  getQueryBuilderStyles,
};
