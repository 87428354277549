import timeSrv from "./TimeSrv";

/**
 * @description A util method to access global time range in non-react components
 * @deprecated Do not use unless the application is non-react
 * @returns Global time range
 */
const getTimeRange = () => timeSrv.getTimeRange();

/**
 * @description A util method to access global compare time range in non-react components
 * @deprecated Do not use unless the application is non-react
 * @returns Global compare time range
 */
const getCompareTimeRange = () => timeSrv.getCompareTimeRange();

export {
  getTimeRange,
  getCompareTimeRange
};
