import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect, { AsyncProps } from "react-select/async";
import Creatable from "react-select/async-creatable";
import { ActionMeta, InputActionMeta, InputProps, NamedProps, ValueType, components } from 'react-select';
import { handleIsValidNewOption } from '../../utils/components/selectUtils';
import { SelectDropdownIndicator, IncSelectLabel, IncSelectOption, CommonSelectProps } from "./InceptionSelect";
import { CreateLabelFormatter } from './common';

const Input = (props: InputProps) => <components.Input {...props} isHidden={false} />;
export interface IncAsyncSelectProps<OptionType extends IncSelectOption, isMulti extends boolean>
  extends AsyncProps<OptionType>, NamedProps<OptionType, isMulti>, CommonSelectProps {
  readOnly?: boolean;
}

const IncAsyncSelect = <OptionType extends IncSelectOption, IsMulti extends boolean = false>(props: IncAsyncSelectProps<OptionType, IsMulti>) => {
  const {
    className: pClassName = '',
    components: pComponents = {},
    classNamePrefix: pClassNamePrefix,
    wrapperClass,
    disablePopper = false,
    menuPortalTarget: pMenuPortalTarget,
    createLabelPrefix,
    formatCreateLabel,
    readOnly = false,
    label,
    allowCreate,
    helpText,
    helpTextId,
    options,
    ...restProps
  } = props;


  const {
    value,
    isMulti,
    onChange: onValueChange
  } = restProps;

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!value) {
      //set the default empty string when the null value is set.
      //This is required since the value is not changed in the UI and retain the last stored value.
      setInputValue('');
    }
  },[value]);

  const onInputChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };
  const onChange = useCallback((value: ValueType<OptionType, IsMulti>, action: ActionMeta<OptionType>) => {
    if (onValueChange) {
      onValueChange(value, action);
    }
    if (action.action === 'clear' || (action.action === 'select-option' && isMulti)) {
      setInputValue('');
    } else {
      //set the input value as typed by user and selected in the options
      const displayVal: any = value;
      setInputValue(displayVal?.label);
    }
  }, [isMulti, onValueChange]);

  const wrapperClassName = `inc-flex-column inception-select ${wrapperClass || ""} ${readOnly ? "readonly" : ""}`;
  const className = `inception-react-select-container ${pClassName}`;
  const classNamePrefix = pClassNamePrefix ? `inception-react-select ${pClassNamePrefix}` : 'inception-react-select';
  const components = {
    ...pComponents,
    Input,
    DropdownIndicator: pComponents.DropdownIndicator || SelectDropdownIndicator
  };

  const formatCreateLabelValue = useCallback((input: string) => formatCreateLabel ? formatCreateLabel(input)
    : <CreateLabelFormatter createPrefix={createLabelPrefix} input={input} />, [createLabelPrefix, formatCreateLabel]);
  const menuPortalTarget = useMemo(() => pMenuPortalTarget ? pMenuPortalTarget
    : disablePopper ? null
      : document.body
  , [disablePopper, pMenuPortalTarget]);

  return <div className={wrapperClassName} data-readonly={readOnly}>
    {label && <IncSelectLabel
      helpText={helpText}
      helpTextId={helpTextId}
      label={label}
    />}

    {!allowCreate && <AsyncSelect<OptionType, IsMulti>
      className={className}
      classNamePrefix={classNamePrefix}
      components={components}
      defaultOptions={options}
      menuPortalTarget={menuPortalTarget}
      menuShouldScrollIntoView={false}
      options={options}
      {...restProps}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
    />}

    {allowCreate && <Creatable<OptionType, IsMulti>
      className={className}
      classNamePrefix={classNamePrefix}
      components={components}
      defaultOptions={options}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options}
      {...restProps}
      allowCreateWhileLoading={true}
      formatCreateLabel={formatCreateLabelValue}
      isValidNewOption={handleIsValidNewOption}
      menuPortalTarget={menuPortalTarget}
      menuShouldScrollIntoView={false}
      onChange={onChange}
    />}
  </div>;
};

export { IncAsyncSelect };
