import React from 'react';

export interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const IncCardBody: React.FC<Props> = ((props) => (
  <div className='marginTp16'>
    {props.children}
  </div>
));

export default IncCardBody;
