import React, { ComponentPropsWithRef } from "react";
import { cx } from 'emotion';
import { useTheme } from "emotion-theming";
import { IncToolTip, IncInfoIcon } from "../..";
import IncErrorIcon from "../Icons/IncErrorIcon";
import { InceptionTheme } from "../../themes/types/theme";

interface IncTextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement>, ComponentPropsWithRef<any>  {
  label?: string;
  resize?: boolean;

  hasError?: boolean;
  errorText?: string; // pass error text to be shown on tooltip

  helpText?: string; // pass content to show on tooltip
  helpTextId?: string; // pass i18n id to show on tooltip

  containerClassName?: string;
}

const IncTextArea = React.forwardRef<HTMLTextAreaElement, IncTextAreaProps>((props, ref) => {
  const {
    label,
    disabled = false,
    className = '',
    value,
    hasError,
    errorText,
    helpTextId,
    helpText,
    resize = true,
    containerClassName = '',
    ...otherProps
  } = props;

  const theme: InceptionTheme = useTheme();

  const appliedTextInputClassName = cx(
    className,
    'input-text-area',
    hasError ? "inc-text-error" : "",
    resize ? 'inc-text-area-no-resize' : ''
  );

  const textarea = <textarea
    className={appliedTextInputClassName}
    disabled={disabled}
    ref={ref}
    value={value}
    {...otherProps}
  />;

  const appliedContainerClassName = cx('textarea-container', containerClassName);

  return <div className={appliedContainerClassName}>
    {label &&
      <div className='input-label'>
        {label}
        {(helpTextId || helpText) &&
          <IncToolTip
            placement="top-start"
            showArrow
            titleId={helpTextId}
            titleText={helpText}>
            <IncInfoIcon />
          </IncToolTip>
        }
        {(errorText && hasError) &&
          <IncToolTip
            placement="top-start"
            showArrow
            titleText={errorText}
            variant="error">
            <IncErrorIcon style={{ color: theme.inceptionColors.palette.R400 /*R400*/ }} />
          </IncToolTip>
        }
      </div>
    }

    <div>{textarea}</div>
  </div>;
});

export default IncTextArea;
