import { useCallback } from "react";
import { logger } from "../logging/Logger";
import { schemaApiService } from "../../services/api";
import { useFetchDataStatus } from "./useFetchDataStatus";

export const useFetchEntityCount = () => {
  const fetchStatus = useFetchDataStatus<number>();
  const { setStatus } = fetchStatus;

  const getEntityCount = useCallback(async (entityTypeId: string) => {

    logger.debug("entityCountFetcher", "Fetching count of entities", entityTypeId);
    setStatus(0, true, false, false, null);
    try {
      const entityTypeIdToCount: Map<string, number> = await schemaApiService.getEntityTypeCount([entityTypeId]);
      setStatus(entityTypeIdToCount.get(entityTypeId), false, true, false, null);
    } catch (e) {
      setStatus(0, false, false, true, e);
      logger.error("useFiledPicker", "failed to fetch entity fields", e);
    }
  }, [setStatus]);

  return {
    ...fetchStatus,
    getEntityCount
  };
};
