import React, { FC, useCallback, useState } from "react";
import useRootClose from "react-overlays/useRootClose";
import { IncClickAwayProps } from "./types";

const IncClickAway: FC<IncClickAwayProps> = (props) => {
  const { children, onClickAway } = props;

  // We are using callback ref here. Referred from the Bootstrap Overlay code.
  const [docEl, setDocEl] = useState(null);
  const ref = useCallback((element: any) => setDocEl(element), []);

  useRootClose(docEl, (event: any) => {
    //Below is a hack to set click away container open when click event triggered from an IncSelect options popup
    //TODO: above should be managed in a better way
    if (!event.target?.classList.contains("inception-react-select__option")) {
      onClickAway();
    }
  });

  return <>{children(ref)}</>;
};
export default IncClickAway;
