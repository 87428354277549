import React, { useMemo, FC } from 'react';
import { SimpleEntityNameRenderer } from '../entity-properties-popover';
import { generateId } from '../../core';
import { getTimeRange } from '../../core/hooks/time-range/TimeRangeGetter';
import { RenderWithMorePopper } from '../RenderWithMorePopper';
import { AsyncEntityList } from './AsyncEntityList';

type Props = {
  entityIds: string[];
  entityTypeId: string;
  maxEntries?: number;
};

export const EntityListWithLimit: FC<Props> = ({ entityIds, entityTypeId, maxEntries }) => {
  const valueNodes = entityIds.map((v: string) => <SimpleEntityNameRenderer hideIcon id={v} key={generateId()} />);

  const viewMoreComponent = useMemo(() => {
    const {
      from,
      to
    } = getTimeRange();
    const startTime = from.valueOf();
    const endTime = to.valueOf();

    return <AsyncEntityList
      endTime={endTime}
      entityIdFilters={entityIds}
      entityTypeId={entityTypeId}
      entityTypeName={entityTypeId}
      maxEntries={maxEntries || 20}
      startTime={startTime}
    />;
  }, [entityIds, entityTypeId, maxEntries]);

  return <RenderWithMorePopper
    delimiter=" , "
    maxEntries={3}
    moreDetailsComponent={viewMoreComponent}
  >
    {valueNodes}
  </RenderWithMorePopper>;
};
