import { appData } from "../../appConfig";

export const getBizEntityUrlPrefix = () => {
  const isPreviewRelease = appData?.build?.releaseType === 'preview';
  return isPreviewRelease ? '/api/preview/bizEntity' : '/api/bizEntity';
};

export const getUserServiceUrlPrefix = () => {
  const isPreviewRelease = appData?.build?.releaseType === 'preview';
  return isPreviewRelease ? '/api/preview/userService' : '/api/userService';
};

export const getPickerUrlPrefix = () => {
  const isPreviewRelease = appData?.build?.releaseType === 'preview';
  return isPreviewRelease ? '/api/preview/picker' : '/api/picker';
};
