import { isEmpty } from "lodash";
import { checkIsNaN } from "../numeric/utils";
import { CurrencyType } from "../../../../utils";
import { Currency } from "./types";
import { currencyTypeVsConfig } from "./constants";


export const getCurrencyInfo = (currencyValue: string, currencyType: CurrencyType, disableAbbreviations: boolean, withSymbol = false): Currency => {
  const currency: Currency = {
    isValid: false,
    abbreviatedValue: '',
    absoluteValue: ''
  };

  if (isEmpty(currency)) {
    return currency;
  }

  if (currencyValue === "No Min" || currencyValue === "No Max") {
    currency.isValid = true;
    currency.abbreviatedValue = currencyValue;
    return currency;
  }

  const currencyConfig = currencyTypeVsConfig[currencyType];
  //check if currency value is valid
  if(currencyConfig.regex.test(currencyValue)) {
    currency.isValid = true;
    currency.abbreviatedValue = currencyValue;

    //compute absolute value
    currency.absoluteValue = !checkIsNaN(currencyValue) ? Number(currencyValue).toString() :
      currencyConfig.convertToNumber(currencyValue).toString();

    //compute abbreviated value
    if (!disableAbbreviations && !checkIsNaN(currencyValue)) {
      if (withSymbol) {
        currency.abbreviatedValue = currencyConfig.formatter.format(parseInt(currencyValue, 10));
      } else {
        const parts = currencyConfig.formatter.formatToParts(parseInt(currencyValue, 10));
        //Removing currency symbol as UI already shows it
        const abbvValue = parts.filter(p => p.type !== "currency").map(p => p.value)
          .join("");
        currency.abbreviatedValue = abbvValue;
      }
    }
  }
  return currency;
};
