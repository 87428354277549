import React from "react";
import { InfoOutlined } from "./MaterialIcons";

const IncInfoIcon = React.forwardRef<any, any>((props, ref) => <InfoOutlined
  {...props}
  className={`inc-info-icon ${props.className || ''}`}
  ref={ref}
/>);


export default IncInfoIcon;
