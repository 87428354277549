import { generateSSTimeseriesData, generateSingleStatData, generateInsightsData } from "./generators";

const dataDefId = "tklAQxqtjP";
const metricId = "T70iDQdeu";

const lookupMin = 90;
const lookupMax = 110;
const percentageChangeValues = [-11, 16, -19, 6, 5.2, 4, 2, -8, 10, 4, -2, -10, -13, 12, 7.7, 3.2, 5.8, -1.5, -3, 5.5, 7.1, -8.4, 5];

const dataRange: [number, number] = [250, 350];
const timeShiftRange: [number, number] = [250, 300];

export const ss3Data = [generateSingleStatData(
  dataDefId,
  metricId,
  1649656800000,
  172427,
  183880,
  -0.8356337704163792
)];

export const ssTs3Data = [generateSSTimeseriesData(
  dataDefId,
  metricId,
  dataRange,
  timeShiftRange
)];


export const ssEl3Data = [generateInsightsData(
  dataDefId,
  metricId,
  lookupMin,
  lookupMax,
  percentageChangeValues,
  timeShiftRange
)];
