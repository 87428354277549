import { Action } from "./features";

//place all actions under busniess entity here
export enum USERS_ACTIONS {
  invite = "invite",
  getUsers = "getUsers",
  createToken = "createToken",
  getTokens = "getTokens"
}

export const UserFeatureActions: Record<keyof typeof Action, Array<keyof typeof USERS_ACTIONS>> = {

  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  "CREATE": [
    "invite",
    "createToken",
    "getUsers",
    "getTokens"
  ],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  "UPDATE": [],

  //All READ actions goes here
  //Enabled for All
  "READ": [],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  "DELETE": []

};
