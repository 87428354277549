import { isEmpty, isEqual } from "lodash";
import { ENTITY_TAG } from "../../../../utils/MetricNameUtils";
import { getOpdCompareConfig, WidgetConfigUtils } from "../widget/widgetConfigUtils";
import { eventFieldUtils } from "../../../../utils/EventFieldUtils";
import { extractSliceSetFromBizMetricDefinition } from "../../../../utils/ExploreUtils";
import {
  BizEntityMetricConfigDefinition, MetricIncidentList, IncidentResponse, MetricDefinition,
  SliceSet, Slice, UserServiceFieldSlice, UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSliceSet, UserServiceMetricConfigDefinition, WidgetConfigDTO, WidgetResponseDTO, MetricSourceType,
  CompareIncidentList, ExpressionMetricConfigDefinition, MetricAndSliceRecord, WidgetQuerySchema, FieldSourceType, FieldDefinition
} from "../types";
import { shouldExcludeTag } from "../../../../core";

export class WidgetMetricConfigModel {
  private alertMetricConfigs: AlertMetricConfig[];
  private alertMetricConfigsWithCompareConfig: AlertMetricConfig[];
  widgetConfig: WidgetConfigDTO;
  widgetResponseDto: WidgetResponseDTO;
  protected entityId: string;
  protected entityType: string;
  protected entityTypeName: string;

  constructor(widgetResponseDto: WidgetResponseDTO, incidentResponse?: IncidentResponse) {
    this.widgetResponseDto = widgetResponseDto;
    this.widgetConfig = this.widgetResponseDto.widgetConfig;
    this.entityId = this.widgetConfig.userServiceEntityId;
    this.entityType = this.widgetConfig.bizEntityType;
    this.entityTypeName = this.widgetConfig.entityTypeName;
    this.processWidgetConfig(incidentResponse);
  }

  private getIncidentListsFromSliceSet = (
    incidentList: IncidentResponse, metric: MetricDefinition, sliceSet: SliceSet | UserServiceFieldSliceSet
  ): CompareIncidentList[] => {
    if (!incidentList) {
      return [];
    }

    const incidents: MetricIncidentList = incidentList.metricIncidents[metric.id];
    if (!incidents) {
      return [];
    }
    const metricSlices: Slice[] = (sliceSet.slices as Slice[]);
    const incidentArray: CompareIncidentList[] = [];
    incidents.compareIncidents.forEach(x => {
      if (WidgetConfigUtils.compareSlices(x.slice.slices, metricSlices, WidgetConfigUtils.checkIfBizMetricisAggregated(metric))) {
        incidentArray.push(x);
      }
    });
    return incidentArray;
  };

  private processWidgetConfig(incidentResponse?: IncidentResponse) {
    const alertMetrics: AlertMetricConfig[] = [];
    const alertMetricsWithCompareConfig: AlertMetricConfig[] = [];



    if (isEmpty(this.widgetConfig.dataDefinition)) {
      return;
    }

    const metricSliceRecords = WidgetConfigUtils.getMetricSliceSetRecord(this.widgetConfig.dataDefinition.metrics,
      this.widgetResponseDto.querySchema.querySchema);

    const originalMetrics = WidgetConfigUtils.getUserVisibleMetricDefs(this.widgetConfig);

    originalMetrics.forEach(metric => {
      const usFieldConfigDef = metric as UserServiceFieldMetricConfigDefinition;
      const bizEntityMetricConfigDef = metric as BizEntityMetricConfigDefinition;
      const usMetricConfigDef = metric as UserServiceMetricConfigDefinition;
      const expressionMetricConfigDef = metric as ExpressionMetricConfigDefinition;

      const {
        sourceType,
        id,
        operationalizeConfig
      } = metric;

      const getDefaultMetricConfig = (msr: MetricAndSliceRecord): BaseMetricConfig => ({
        metricId: msr.metricId,
        sourceType: msr.metricSourceType,
        metricDef: msr.metricDefinition,
        querySchema: msr.querySchema,
        sliceSet: msr.sliceSet,
        usfSlices: msr.usfSlices,
        name: msr.metricDefinition.name,
      } as BaseMetricConfig);

      const isUsField = sourceType === "userServiceField";
      const isBizEntityMetric = sourceType === "bizEntityMetric";
      const isUsMetric = sourceType === "userServiceMetric";
      const isExpressionMetric = sourceType === "expression";

      const compareConfigExists = !isEmpty(operationalizeConfig?.compareConfigs);

      if (isUsField) {
        const msrArr = metricSliceRecords.filter(msr => msr.metricId === id);
        msrArr.forEach(msr => {
          const {
            alertMetrics: ams,
            compareAlertMetrics: cams
          } = this.processUsFieldDef(usFieldConfigDef, getDefaultMetricConfig(msr), incidentResponse, compareConfigExists);

          alertMetrics.push(...ams);
          alertMetricsWithCompareConfig.push(...cams);
        });

      } else if (isUsMetric) {
        const msr = metricSliceRecords.find(msr => msr.metricId === id);
        const {
          alertMetrics: ams,
          compareAlertMetrics: cams
        } = this.processUSMetricDef(usMetricConfigDef, getDefaultMetricConfig(msr), incidentResponse, compareConfigExists);

        alertMetrics.push(...ams);
        alertMetricsWithCompareConfig.push(...cams);
      } else if (isBizEntityMetric) {
        const msr = metricSliceRecords.find(msr => msr.metricId === id);
        const {
          alertMetrics: ams,
          compareAlertMetrics: cams
        } = this.processBizEntityMetricDef(bizEntityMetricConfigDef, getDefaultMetricConfig(msr), incidentResponse, compareConfigExists);

        alertMetrics.push(...ams);
        alertMetricsWithCompareConfig.push(...cams);
      } else if (isExpressionMetric) {
        const msr = metricSliceRecords.find(msr => msr.metricId === id);
        const {
          alertMetrics: ams,
          compareAlertMetrics: cams
        } = this.processExpressionMetricDef(expressionMetricConfigDef, getDefaultMetricConfig(msr), incidentResponse, compareConfigExists);

        alertMetrics.push(...ams);
        alertMetricsWithCompareConfig.push(...cams);
      }
    });

    this.alertMetricConfigs = alertMetrics;
    this.alertMetricConfigsWithCompareConfig = alertMetricsWithCompareConfig;
  }

  private processSliceSet(
    configDef: UserServiceMetricConfigDefinition | UserServiceFieldMetricConfigDefinition
    | BizEntityMetricConfigDefinition | ExpressionMetricConfigDefinition,
    label: string,
    defaultMetricConfig: BaseMetricConfig,
    incidentResponse?: IncidentResponse,
    defaultTagSlices?: SliceSet
  ): AlertMetricConfig {
    const incidentArray = this.getIncidentListsFromSliceSet(incidentResponse, configDef, defaultMetricConfig.sliceSet);

    let size = 0;
    let activeCount = 0;
    incidentArray.forEach(x => {
      size += x.incidents.length;
      activeCount += x.incidents.filter(i => i.incident.status === "ACTIVE").length;
    });
    const incident = incidentArray[0];

    const metricConfig: AlertMetricConfig = {
      ...defaultMetricConfig,
      label,
      tagSlices: incident?.slice || defaultTagSlices || this.getDefaultSliceSet(),
      size: size,
      activeCount: activeCount,
    };

    return metricConfig;
  }

  private processUSMetricDef(
    usMetricConfigDef: UserServiceMetricConfigDefinition,
    defaultMetricConfig: BaseMetricConfig,
    incidentResponse?: IncidentResponse,
    compareConfigExists = false
  ) {
    const alertMetrics: AlertMetricConfig[] = [];
    const compareAlertMetrics: AlertMetricConfig[] = [];

    const {
      userServiceMetricConfig,
      name
    } = usMetricConfigDef || {};

    const {
      sliceSet: usmSliceSet = {
        slices: []
      }
    } = userServiceMetricConfig || {};

    let sliceLabel = "";
    if (!isEmpty(usmSliceSet.slices)) {
      sliceLabel = getSliceLabelFromSlices(usmSliceSet.slices);
    }

    const label = sliceLabel ? `${name} by${sliceLabel}` : name;
    const metricConfig = this.processSliceSet(usMetricConfigDef, label, defaultMetricConfig, incidentResponse, usmSliceSet);

    alertMetrics.push(metricConfig);
    if (compareConfigExists) {
      compareAlertMetrics.push(metricConfig);
    }

    return {
      alertMetrics,
      compareAlertMetrics
    };
  }

  private processBizEntityMetricDef(
    bizEntityMetricConfigDef: BizEntityMetricConfigDefinition,
    defaultMetricConfig: BaseMetricConfig,
    incidentResponse?: IncidentResponse,
    compareConfigExists = false
  ) {
    const alertMetrics: AlertMetricConfig[] = [];
    const compareAlertMetrics: AlertMetricConfig[] = [];

    const { name } = bizEntityMetricConfigDef || {};
    const sliceSet = extractSliceSetFromBizMetricDefinition(bizEntityMetricConfigDef);

    const metricConfig = this.processSliceSet(bizEntityMetricConfigDef, name, defaultMetricConfig,
      incidentResponse, sliceSet);

    alertMetrics.push(metricConfig);
    if (compareConfigExists) {
      compareAlertMetrics.push(metricConfig);
    }

    return {
      alertMetrics,
      compareAlertMetrics
    };
  }

  private processExpressionMetricDef(
    expressionMetricDef: ExpressionMetricConfigDefinition,
    defaultMetricConfig: BaseMetricConfig,
    incidentResponse?: IncidentResponse,
    compareConfigExists = false
  ) {
    const alertMetrics: AlertMetricConfig[] = [];
    const compareAlertMetrics: AlertMetricConfig[] = [];

    const { name } = expressionMetricDef || {};
    const sliceSet: SliceSet = {
      slices: []
    };

    const metricConfig = this.processSliceSet(expressionMetricDef, name, defaultMetricConfig,
      incidentResponse, sliceSet);

    alertMetrics.push(metricConfig);
    if (compareConfigExists) {
      compareAlertMetrics.push(metricConfig);
    }

    return {
      alertMetrics,
      compareAlertMetrics
    };
  }

  private processUsFieldDef(
    usFieldConfigDef: UserServiceFieldMetricConfigDefinition,
    defaultMetricConfig: BaseMetricConfig,
    incidentResponse?: IncidentResponse,
    compareConfigExists = false
  ) {
    const alertMetrics: AlertMetricConfig[] = [];
    const compareAlertMetrics: AlertMetricConfig[] = [];

    const { name: metricName } = usFieldConfigDef;
    const sliceLabel = getSliceLabelFromSlices(defaultMetricConfig.sliceSet.slices);
    const label = sliceLabel ? `${metricName} by${sliceLabel}` : metricName;
    const metricConfig = this.processSliceSet(usFieldConfigDef, label, defaultMetricConfig, incidentResponse);
    alertMetrics.push(metricConfig);

    if (compareConfigExists) {
      compareAlertMetrics.push(metricConfig);
    }

    return {
      alertMetrics,
      compareAlertMetrics
    };
  }

  protected getSubKeyValuesFromTags(tags: Record<string, string>, groupKey: string): Record<string, string[]>{
    const subKeyValues: Record<string, string[]> = {};

    Object.entries(tags).forEach(([k, v]) => {
      if (k !== groupKey && !shouldExcludeTag(k)) {
        subKeyValues[k] = [v];
      }
    });

    return subKeyValues;
  }

  getSummaryMetricsFromWidgetConfig() {
    return this.alertMetricConfigs;
  }

  getSummaryMetricsWithCompareConfig() {
    return this.alertMetricConfigsWithCompareConfig;
  }

  /**
   *
   * Accessor header map is a key value pair
   * which is used to get the biz entity type name of implicit slice tagName
   *  eg: { i_entity: Merchant }
   */
  getSummaryAccessorHeaderMap(sliceSet: SliceSet): Record<string, string> {
    const accessorHeaderMap: Record<string, string> = {};

    (sliceSet.slices).forEach((slice: Slice | UserServiceFieldSlice) => {
      if (slice.tagName === ENTITY_TAG) {
        accessorHeaderMap[slice.tagName] = this.widgetConfig.bizEntityType ? this.widgetConfig.bizEntityType : "User service";
      } else {
        accessorHeaderMap[slice.tagName] = slice.tagName;
      }
    });
    return accessorHeaderMap;
  }

  /**
   * return MQ id from internal config for given metricId and tags
   */
  getMQId(metricId: string, compareConfigId: string) {
    const metricDef = this.widgetConfig.dataDefinition.metrics[metricId];
    if (!metricDef.internalFetchConfig) {
      return null;
    }
    const compareConfig = getOpdCompareConfig(metricDef, compareConfigId);
    const generatedCompareConfig = metricDef.internalFetchConfig.generatedCompareConfig.compareConfigDetails[compareConfig.compareConfigId];
    const metricToMQIdMap = generatedCompareConfig.scheduledQueryConfig.metricToMQId;

    const mqIdPayload = (Object.values(metricToMQIdMap) || [])[0];

    // mqMap ideally should be defined.
    if (mqIdPayload) {
      return mqIdPayload.mqId;
    } else {
      return null;
    }
  }

  getMatchingConfig(metricId: string, sourceType: MetricSourceType, sliceSet: SliceSet): AlertMetricConfig {
    return this.alertMetricConfigs.find(a => a.metricId === metricId && a.metricDef.sourceType === sourceType && isEqual(a.sliceSet, sliceSet));
  }

  private getDefaultSliceSet(): SliceSet {
    return {
      slices: []
    };
  }
}

interface BaseMetricConfig {
  metricId: string;
  sourceType: MetricSourceType | FieldSourceType;
  metricDef: MetricDefinition | FieldDefinition;
  querySchema: WidgetQuerySchema;
  sliceSet: SliceSet;
  name: string;
  usfSlices?: UserServiceFieldSliceSet;
}

export interface AlertMetricConfig extends BaseMetricConfig {
  tagSlices: SliceSet;
  label: string;
  size: number;
  activeCount?: number;
}

export const AGGR_CASE = "aggr";

export const getSliceLabelFromSlices = (slices: Slice[]) => {
  let sliceLabel = "";
  slices.forEach(s => {
    const { tagName, fieldName } = s;
    if (tagName !== ENTITY_TAG) {
      sliceLabel = `${sliceLabel ? sliceLabel + "," : sliceLabel} ${eventFieldUtils.removeFieldsPrefix(fieldName)}`;
    }
  });
  return sliceLabel;
};
