import { DatasourceSettings, BaseApi, DataQueryRequest, DataQueryResponse, LoadingState } from "../../api/types";
import { DatasourceApi } from "../../api/DatasourceService";
import {
  Op10zeMetricResultDTO, operationaliseV2ApiService, Op10zeMetricResult, OpPreviewDataRequest,
  OpPreviewDataResponse, OpPreviewDataResponseDTO
} from "../../api/operationalise";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { ResultTransformer } from "../prometheus/result_transformer";
import { PromDataResponse } from "../prometheus/core/types";
import { getBizEntityUrlPrefix } from "../../../utils";
import { Op10zeDataQuery, Op10zeQueryDataResponse } from "./types";

export type OperationalizeDatasourceSettings = DatasourceSettings;

export class OperationalizeDatasource extends DatasourceApi<Op10zeDataQuery, OperationalizeDatasourceSettings> {
  private resultTransformer: ResultTransformer;

  constructor(name: string, settings: OperationalizeDatasourceSettings, baseService: BaseApi) {
    super(name, "operationalize", settings, baseService);
    this.resultTransformer = new ResultTransformer();
  }

  async query(request: DataQueryRequest<Op10zeDataQuery>): Promise<DataQueryResponse<Op10zeQueryDataResponse[]>> {
    const target = request.targets[0];

    const {
      payload,
      previewPayload,
      insightMode
    } = target;

    if (payload) {
      return this.getOp10zeData(request, target);
    }

    if (previewPayload) {
      if (insightMode) {
        return this.getInsightPreviewData(request, target);
      } else {
        return this.getOp10zePreviewData(request, target);
      }
    }

    return {
      data: [],
      error: {
        data: {
          error: "Invalid query payload",
          message: "One of previewPayload or payload is required",
        },
        message: "Invalid query payload",
        status: "400",
        statusText: "Bad Request"
      },
      state: LoadingState.Error
    };
  }

  private async getOp10zeData(
    request: DataQueryRequest<Op10zeDataQuery>,
    target: Op10zeDataQuery
  ): Promise<DataQueryResponse<Op10zeQueryDataResponse[]>> {
    const dsInstance = await operationaliseV2ApiService.getInstance();

    const {
      startTime,
      endTime = new Date().valueOf(),
      cancelToken
    } = request;

    const {
      payload,
      opId,
      simulationId,
      incidentId,
      generateDemoData
    } = target;

    const subUrl = simulationId ? `/${opId}/simulation/${simulationId}/fetch/data`
      : incidentId ? `/${opId}/incident/${incidentId}/fetch/data`
        : `/${opId}/fetch/data`;
    const url = this.getBizEntityUrl(subUrl);

    const qPayload = {
      ...payload,
      startSecs: timeRangeUtils.getSecondsFromMillis(startTime),
      endSecs: timeRangeUtils.getSecondsFromMillis(endTime)
    };

    const config = {
      cancelToken,
      params: {
        generateDemoData
      }
    };

    const {
      data,
      ...rest
    } = await dsInstance.post<Op10zeMetricResult, unknown>(url, qPayload, config);

    const partTransformed = this.getTransformedData(data);

    const transformed: Op10zeMetricResultDTO = {
      ...(data || {} as Op10zeMetricResult),
      ...partTransformed
    };

    return {
      ...rest,
      data: [{
        data: transformed,
        previewData: null
      }]
    };
  }

  private async getOp10zePreviewData(
    request: DataQueryRequest<Op10zeDataQuery>,
    target: Op10zeDataQuery
  ): Promise<DataQueryResponse<Op10zeQueryDataResponse[]>> {
    const dsInstance = await operationaliseV2ApiService.getInstance();

    const {
      startTime,
      endTime = new Date().valueOf(),
      cancelToken
    } = request;

    const { previewPayload } = target;

    const url = this.getBizEntityUrl(`/fetch/preview-data`);
    const qPayload: OpPreviewDataRequest = {
      ...previewPayload,
      startTimeMillis: startTime,
      endTimeMillis: endTime
    };

    const {
      data,
      ...rest
    } = await dsInstance.post<OpPreviewDataResponse, unknown>(url, qPayload, { cancelToken });

    const partTransformed = this.getTransformedData(data);

    const transformed: OpPreviewDataResponseDTO = {
      ...(data || {} as OpPreviewDataResponse),
      ...partTransformed
    };

    return {
      ...rest,
      data: [{
        data: null,
        previewData: transformed
      }]
    };
  }


  private async getInsightPreviewData(
    request: DataQueryRequest<Op10zeDataQuery>,
    target: Op10zeDataQuery
  ): Promise<DataQueryResponse<Op10zeQueryDataResponse[]>> {
    const { previewPayload } = target;
    const { data } = await operationaliseV2ApiService.getInsightPreviewData(previewPayload?.opSetupReq.opCreationConfig as any);

    const partTransformed = this.getInsightTransformedData(data.opPreviewDataResponse as any);

    const transformed: OpPreviewDataResponseDTO = {
      ...(data?.opPreviewDataResponse || {} as OpPreviewDataResponse),
      ...partTransformed,
      timeRange: data?.timeRange
    };

    return {
      data: [{
        data: null,
        previewData: transformed
      }]
    };
  }

  private getTransformedData(partResult: PartResult): PartTransformedResult {
    const transformed: PartTransformedResult = {
      compareConfigData: {},
      data: {}
    };

    if (partResult) {
      const {
        compareConfigData: ccData,
        data: orData
      } = partResult;

      Object.keys(orData).forEach(key => {
        const datum = orData[key];

        transformed.data[key] = {
          data: this.resultTransformer.transform(datum as PromDataResponse, {
            skipInternalLabels: true,
            useMetricLabelAsName: true
          }),
          preLimitSelectionCount: 0,
          schema: [],
          seasonSecs: datum.seasonSecs
        };
      });

      Object.keys(ccData).forEach(key => {
        const datum = ccData[key];
        transformed.compareConfigData[key] = {
          data: this.resultTransformer.transform(datum as PromDataResponse, {}),
          preLimitSelectionCount: 0,
          schema: [],
          seasonSecs: datum.seasonSecs,
          suppressionInfo: datum.suppressionInfo || [],
          suppressionReasonInfo: datum.suppressionReasonInfo || {}
        };
      });
    }

    return transformed;
  }


  private getInsightTransformedData(partResult: PartResult): PartTransformedResult {
    const transformed: PartTransformedResult = {
      compareConfigData: {},
      data: {}
    };

    if (partResult) {
      const {
        compareConfigData: ccData,
        data: orData
      } = partResult;

      Object.keys(orData).forEach(key => {
        const datum = orData[key];

        transformed.data[key] = {
          data: this.resultTransformer.transform(datum as PromDataResponse, {
            skipInternalLabels: true,
            useMetricLabelAsName: true
          }),
          preLimitSelectionCount: 0,
          schema: [],
          seasonSecs: datum.seasonSecs
        };
      });

      Object.keys(ccData).forEach(key => {
        const rawDatum = ccData[key];
        const datum = Array.isArray(rawDatum) ? rawDatum[0] : rawDatum;
        transformed.compareConfigData[key] = {
          data: this.resultTransformer.transform(datum as PromDataResponse, {}),
          preLimitSelectionCount: 0,
          schema: [],
          seasonSecs: datum.seasonSecs,
          suppressionInfo: transformed.compareConfigData?.[key]?.suppressionInfo || [],
        };
      });
    }

    return transformed;
  }

  private getBizEntityUrl(subUrl: string) {
    return getBizEntityUrlPrefix() + "/op10ze" + subUrl;
  }
}

type PartResult = Pick<Op10zeMetricResult, 'data' | 'compareConfigData'>;
type PartTransformedResult = Pick<Op10zeMetricResultDTO, 'data' | 'compareConfigData'>;
