import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { currencyTypeVsConfig, CurrencyType } from "@inception/ui";
import { isNil } from "lodash";
import { ApplicationState } from "../../../../store/configureStore";
import { tenantservice } from "../../../../features/admin/tenants/TenantApiService";
import { logger } from "../../logging/Logger";
import appConfig, { appData } from "../../../../appConfig";
import { useTypedQueryParams } from "../useQueryParamsHook";
import { useAuth } from "../../../login/state/useAuth";
import { featureFlagService, FEATURE_FLAGS } from "../../../services/feature-flags";
import { TenantConfig, TenantDrilldownVersion } from "./types";
import { setTenantConfig } from "./reducer";

const useTenantConfig = () => {
  const dispatch = useDispatch();
  const { authState: {user}} = useAuth();
  const tenantConfigState = useSelector((state: ApplicationState) => state.tenantConfig, shallowEqual);
  const userRole = user?.role;

  const { useOldNavigation: urlUseOldNavigation } = useTypedQueryParams();

  const fetchTenantConfig = useCallback(async () => {
    try {
      const response = await tenantservice.getTenantConfig(userRole);
      const config = getConfigWithDefaults(response);

      if (appData?.build?.releaseType === 'preview') {
        config.useNewNavigation = isNil(urlUseOldNavigation) ? true : urlUseOldNavigation !== "true";
        config.useOldNavigation = !config.useNewNavigation;
      } else {
        config.useOldNavigation = !isNil(urlUseOldNavigation) ? urlUseOldNavigation === "true"
          : isNil(config.useOldNavigation) ? false : config.useOldNavigation;
        config.useNewNavigation = !config.useOldNavigation;
      }

      config.useNewWidgetInBusinessCatalog = featureFlagService.isFeatureEnabled(FEATURE_FLAGS.showNewWidgetsInBusinessCatalog);

      dispatch(setTenantConfig({
        tenantConfig: {
          enableBizFlow: false,
          ...config
        },
        isFetching: false
      }));
    } catch (e) {
      logger.error("Error while fetching tenant config from tenant service", e as any);
      dispatch(setTenantConfig({
        tenantConfig: {
          currency: appConfig.defaultCurrencyType as CurrencyType,
          demoTenant: false,
          pocTenant: false,
          enableBizFlow: false,
          defaultDrilldownVersion: TenantDrilldownVersion.v5,
          useBizProcessHome: false,
          useOnboardingHome: false,
          showDemoInsights: false,
          suggestionsLimit: appConfig.defaultSuggestionsLimit,
          showSubVerticalSwitcher: false
        },
        isFetching: false
      }));
    }
  }, [dispatch, urlUseOldNavigation, userRole]);

  const isTenantConfigFetched = !tenantConfigState?.isFetching;

  return {
    fetchTenantConfig,
    tenantConfigState: tenantConfigState?.tenantConfig,
    isTenantConfigFetched
  };
};

export { useTenantConfig };

const getConfigWithDefaults = (config: TenantConfig): TenantConfig => {
  let currency: CurrencyType = config.currency || appConfig.defaultCurrencyType;
  const isCurrencyValid = currencyTypeVsConfig[currency] !== undefined;
  currency = isCurrencyValid ? currency : appConfig.defaultCurrencyType;
  return {
    demoTenant: false,
    pocTenant: false,
    enableBizFlow: false,
    defaultDrilldownVersion: TenantDrilldownVersion.v5,
    useBizProcessHome: false,
    useOnboardingHome: false,
    showDemoInsights: false,
    suggestionsLimit: appConfig.defaultSuggestionsLimit,
    showSubVerticalSwitcher: false,
    blobStoreFileUploadTimeoutMins: 15,
    ...(config || {}),
    currency
  };
};
