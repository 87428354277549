import { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { TimeRange } from "../../core/hooks/time-range/types";
import { EntityPropertyValue } from "../../core";
import { EntityType } from "./entity-mapping";
import { EntityReference } from "./configuration/types/entity";
import { BizField, UserServiceField } from "./explore";
import { EntityTypeResultList, EventTypeResultList, VerticalIdentifier } from "./auto-discovery/types";
import { DimensionV2 } from "./onboarding";

export type SchemaTypes = 'configType' | 'entityType' | 'relationshipType';

export type BaseTypeReference = {
  id: string;
  typeName: string;
  desc: string;
  category?: string; // category: biz
};

export type TrafficTypeReference = {
  id: string;
  kindId: string;
  typeName: string;
  desc: string;
};

export type RelTypeReference = BaseTypeReference & {
  kindId: string;
};

export type Properties = Record<string, string>;
export type IdPropertySet = { prop: string[] };
export type IdProperties = IdPropertySet[];


export type ConfigType = {
  configType: {
    typeReference: BaseTypeReference;
    properties: Properties;
  };
};

export type EntityTypeWrapper = {
  entityType: EntityType;
};

export type TrafficType = {
  trafficType: {
    typeReference: TrafficTypeReference;
    contextEntityTypeId: string;
    sourceEntityTypeId: string;
    targetEntityTypeId: string;
  };
};

export type RelationshipType = {
  relationshipType: {
    typeReference: RelTypeReference;
    rightEntityTypeId: string;
    leftEntityTypeId: string;
    rightToLeftNavName: string;
    leftToRightNavName: string;
    leftCardinality: string;
    rightCardinality: string;
  };
};

export type SchemaType = ConfigType | EntityTypeWrapper | RelationshipType | TrafficType;

export type SchemaConfig = {
  types: [SchemaType];
};

export type EntityAggregationRequest = Record<string, any> & {
  name: string;
  field: string;
};

export type FieldCardinality = {
  value: string;
  ratio: number;
};

export type EntityAggregationMeta = {
  cardinality?: FieldCardinality;
  stats?: {
    count: string;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
};

export type EntityAggregationSuggestion = {
  kind: string;
  field: string;
  description?: string;
  aggregationMeta: EntityAggregationMeta;
  suggestedAggregationOperator: Record<string, any>;
};

export type EntityAggregationValue = {
  key: string;
  label?: string;
  count: string;
  from?: number;
  to?: number;
};

export type EntityAggregationBuckets = {
  buckets: EntityAggregationValue[];
};

export type EntityAggregationEntry = Record<string, EntityAggregationBuckets>;

export type EntityAggregationResponse = {
  entityType: string;
  aggregations: Record<string, Partial<EntityAggregationEntry>>;
};

export type EntityAggregationSuggestionResponse = {
  entityType: string;
  suggestedAggregations: Record<string, EntityAggregationSuggestion>;
};

export type EntityCountRequest = {
  entityType: string;
  reqId: string;
  entityTypeSelector?: Record<string, any>;
  cohortSelector?: {
    cohortId: string;
  };
};

export type FieldValuesCompletionResponse = {
  entityType: string;
  suggestedAggregations: Record<string, EntityAggregationSuggestion>;
  aggregations: EntityAggregationResponse["aggregations"];
};

export type FieldAggregation = {
  field: UserServiceField;
  aggregation: EntityAggregationMeta;
}

export type FieldAggregationResponse = {
  fieldAggregation: FieldAggregation[];
}

export type EntityCountResponse = {
  entityType: string;
  reqId: string;
  count: number;
};

export type SupportedTypes = "apptuit" | "traces" | "mixed" | "explore" | "mock" | "operationalize";

export const SupportedDatasource: Record<SupportedTypes, string> = {
  apptuit: "apptuit",
  traces: "traces",
  mixed: "mixed",
  explore: "explore",
  mock: "mock",
  operationalize: "operationalize"
};

export interface IncDataSource {
  access: string;
  basicAuth?: boolean;
  database?: string;
  id: number;
  isDefault: true;
  jsonData: any;
  name: string;
  orgId: number;
  password?: string;
  readOnly?: boolean;
  type?: SupportedTypes;
  typeLogoUrl?: string;
  url?: string;
  user?: string;
}
export interface DataQuery {
  refId: string;
  query: any;

  hide?: boolean;
  /**
     * Unique, guid like, string used in explore mode
     */
  key?: string;
  /**
     * For mixed data sources the selected datasource is on the query level.
     * For non mixed scenarios this is undefined.
     */
  datasource?: string | null;
  // We want to support legend formatting to all the datasources eventually
  legendFormat?: string;
}

export interface DatasourceSettings {
  id?: number;
  isGrafanaDS: boolean;
}

export enum LoadingState {
  NotStarted = 'NotStarted',
  Loading = 'Loading',
  Streaming = 'Streaming',
  Done = 'Done',
  Error = 'Error',
}

export interface DataQueryError {
  data?: {
    message?: string;
    error?: string;
  };
  message?: string;
  status?: string;
  statusText?: string;
  cancelled?: boolean;
  refId?: string;
}

export interface DataQueryResponse<T = unknown> {
  error?: DataQueryError;
  data?: T;
  state?: LoadingState;
  entityContext?: Map<string, string>;
}

export interface DataQueryRequest<TQuery extends DataQuery> {
  requestId: string;
  dashboardId: string | number;
  panelId: string | number;
  interval: string;
  intervalMs?: number;
  range?: TimeRange;
  targets: TQuery[];
  timezone?: string;
  cacheTimeout?: string;
  //rangeRaw?: RawTimeRange;
  startTime: number;
  endTime?: number;
  scopedVars?: ScopedVars;
  cancelToken?: CancelToken;  // pass this to enable query cancellation
  resolveEntityIdsToName?: boolean;
}

export interface InceptionRequestConfig extends AxiosRequestConfig {
  camelizeResponse?: boolean;
  requestId?: string | number;
  retryCount?: number;
}

export type UrlParams = Record<string, number | string>;

export interface InceptionResponse<T> extends AxiosResponse<T> {
  config: InceptionRequestConfig;
}

export interface InceptionError extends AxiosError { }

export interface BaseApi {
  requestInterceptors?: (config: InceptionRequestConfig) => Promise<InceptionRequestConfig>;
  request?<T, R = InceptionResponse<T>>(config: InceptionRequestConfig): Promise<R>;
  get?<T, B = UrlParams, R = InceptionResponse<T>>(url: string, payload?: B, config?: InceptionRequestConfig): Promise<R>;
  delete?<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R>;
  post?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  put?<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R>;
  cancelRequest?: (id: string) => void;
}

export interface ScopedVar<T = any> {
  text: any;
  value: T;

  // Added these to accommodate dashboard variables.
  //We need these to handle the value replacement in queries
  multi?: boolean;
  includeAll?: boolean;
  allValue?: string;
}

export interface ScopedVars {
  [key: string]: ScopedVar;
}

export interface EntityMetricResult {
  metricId: string;
  externalName: string;
  baseMetricName: string;
  sliceMetricNames?: Record<string, string>;
}

export type EntitySearchResult = {
  data: {
    results: EntitySearchResultEntry[];
  };
};

export type EntitySearchResultEntry = {
  entity: {
    idReferences: EntityReference[];
    typeId: string;
    name: string;
    discoveryTs: string;
    lastSeenTs: string;
    entityId: string;
    displayName: string;
    props?: Record<string, EntityPropertyValue>;
  };
};

export type PropValue = {
  stringVal?: string;
  longVal?: number;
  doubleVal?: number;
  booleanVal?: boolean;
  setValue?: boolean;
  dateVal?: string;
};

export enum CustomEventTypes {
  globalError = "globalError"
}

export type ExportEntityCSVRequest = {
  typeId: string;
  cohortId: string;
  bizField: BizField[];
  fileName: string;
};

export interface UseCaseDiscoveryResponse {
  verticalIdentifier: VerticalIdentifier;
  eventTypes: EventTypeResultList;
  entityTypes: EntityTypeResultList;
  dimensions?: DimensionResultList;
  numberSampleValues?: number;
}

export interface DimensionResultList{
  dimensionResult: DimensionResult[];
}

export interface DimensionResult {
  dimension: DimensionV2;
}
export interface GetDimensionsRequesPayload {
  companyName: string;
  dimensions: string;
  uniqueValueCount: number;
  sampleValueCount: number;
}
export interface SaveOnboardingResponse {
  success?: boolean;
  messege?: string;
  streamId?: string;
  error?: string
}
