import { DataType, FieldDataType } from "../../../core";

export enum DataFrameFieldType {
  time = "time",
  string = "string",
  numeric = "numeric"
}

export enum DataFrameType {
  original = "original",
  compare = "compare",
  aggregated = "aggregated",
  aggregatedCompare = "aggregatedCompare",
  aggregatedPercentage = "aggregatedPercentage"
}

export interface FieldMeta extends Record<string, any> { }

export interface DataFrameMeta extends Record<string, any> {
  dfType?: DataFrameType;
}

export interface Field<T = any, D = T[]> {
  id: string;
  name: string;
  data: D;

  type?: DataFrameFieldType;
  title?: string;
  fieldMeta?: FieldMeta;
}

export interface DataFrame {
  id: string;
  name: string;
  fields: Field[];

  metricName?: string;
  labels?: Record<string, string>;
  eLabels?: Record<string, string>; // Entity labels, similar to entity tags in TimeSeries

  meta?: DataFrameMeta;

  dataType: DataType | "NA";
  subType: FieldDataType;
}
