export enum DEMO_SUMMARY_PAYLOAD_KEYS {
  widgetConfigPayload = "widgetConfigPayload",
  triagePayload = "triagePayload",
  incidentPayload = "incidentPayload",
  entityTypePayload = "entityTypePayload",
  entityLookupPayload = "entityLookupPayload",
  schemaTypePayload = "schemaTypePayload",
  cohortDefinitionPayload = "cohortDefinitionPayload",
  mqConfigPayload = "mqConfigPayload",
  adhocContext = "adhocContext",
  drillDownPayload = "drillDownPayload",
  funnelPayload = "funnelPayload",
  enableFunnelPayload= "enableFunnelPayload",
  drillDownSummaryPayload = "summaryPayloads[1]",
  drillDownFieldsPayload = "fieldsPayload"
}
