import { RouteProps, matchPath } from "react-router-dom";
import timeRangeUtils from "../utils/TimeRangeUtils";
import { RequiredPermissionType } from "../permissions/accessConfig";
import { PermissionsInterface } from "../permissions/PermissionsManager";
import { RawTimeRange } from "./hooks/time-range/types";
import { Role } from "./data";

interface RouteOptions {
  /**
   * @description If the route requires a time range, the current time range is added to the params. Defaults to false.
   */
  requiresTimeRange?: boolean;

  /**
   * @description If the route requires a compare time range, the current time range is added to the params. Defaults to false.
   */
  requiresCompareTimeRange?: boolean;

  /**
   * @description If the route requires left nav. Defaults to true for private routes and false for public routes
   */
  requiresLeftNav?: boolean;

  /**
   * @description If the route requires top bar. Defaults to true for private routes and false for public routes
  */
  requiresTopBar?: boolean;

  /**
  * @description Restricts the route to system tenant. Defaults to false.
  */
  systemTenantOnly?: boolean;

  /**
  * @description Restricts the route to system tenant. Defaults to false.
  */
  bicycleUserOnly?: boolean;

  /**@deprecated This has been deprecated. The pages will by default use the current time range*/
  defaultTimeRange?: RawTimeRange;

  /**
   * @description If the route requires to be accessed by a specific permission.
  */
  requiredPermission?: RequiredPermissionType;

  /**
  * @description If the route requires to be accessed by a specific permission.
 */
  enableUseCaseSwitch?: boolean;

  /**
  * @description If the route requires to be restricted by specific roles.
 */
  restrictedRoles?: Role[];
}

interface IncRouteProps {
  isPublic: boolean;
  props: RouteProps;
  options: RouteOptions;
}

class RouteManager {
  private routes: Map<string, IncRouteProps> = new Map();

  getPrivateRoutes(): RouteProps[] {
    return this.getRoutes(false);
  }

  getPublicRoutes(): RouteProps[] {
    return this.getRoutes(true);
  }

  registerPublicRoute(
    routeProps: RouteProps,
    options?: RouteOptions
  ): void {
    this.registerRoute(routeProps, options, true);
  }

  registerPrivateRoute(
    routeProps: RouteProps,
    options?: RouteOptions
  ): void {
    this.registerRoute(routeProps, options, false);
  }

  isAuthorised(path: string, isSystemTenant: boolean, isBicycleUser: boolean, permissions: PermissionsInterface, role: Role): boolean {
    const isSystemTenantRoute = this.isSystemTenantRoute(path);
    const isBicycleUserOnlyRoute = this.isBicycleUserOnlyRoute(path);
    const isRouteIsRestrictedByRole = this.isRouteIsRestrictedByRole(path, role);
    const canAccess = (isSystemTenantRoute ? isSystemTenant : true) && (isBicycleUserOnlyRoute ? isBicycleUser : true)
      && !isRouteIsRestrictedByRole;
    const isAuthorised = this.isAuthorisedInternal(path, permissions);
    return canAccess && isAuthorised;
  }

  isRouteIsRestrictedByRole(path: string, role: Role): boolean {
    const routeEntry = this.getRouteEntryByMatch(path);
    const options = routeEntry?.options as RouteOptions;
    return options?.restrictedRoles?.includes(role);
  }

  isSystemTenantRoute(path: string): boolean {
    const routeEntry = this.getRouteEntryByMatch(path);
    const options = routeEntry?.options as RouteOptions;
    return options?.systemTenantOnly || false;
  }

  isBicycleUserOnlyRoute(path: string): boolean {
    const routeEntry = this.getRouteEntryByMatch(path);
    const options = routeEntry?.options as RouteOptions;
    return options?.bicycleUserOnly || false;
  }

  isTimeRangeRequired(path: string): boolean {
    const routeEntry = this.getRouteEntryByMatch(path);
    const requiresTimeRange = routeEntry?.options?.requiresTimeRange || false;
    return requiresTimeRange;
  }

  isCompareTimeRangeRequired(path: string): boolean {
    const routeEntry = this.getRouteEntryByMatch(path);
    const requiresCompareTimeRange = routeEntry?.options?.requiresCompareTimeRange || false;
    return requiresCompareTimeRange;
  }

  hasRoute(path: string): boolean {
    return this.getRouteEntryByMatch(path) !== undefined;
  }

  isPrivateRoute(path: string): boolean {
    return !this.isPublicRoute(path);
  }

  isPublicRoute(path: string): boolean {
    return this.getRouteEntryByMatch(path)?.isPublic || false;
  }

  getRouteEntryByMatch(path: string): IncRouteProps | undefined {
    const routes = Array.from(this.routes.entries());
    const match = routes.find(([p]) => matchPath(path, {
      path: p,
      exact: true,
    }));
    const [, routeProps] = match || [];
    return routeProps;
  }

  getRouteEntry(path: string): IncRouteProps | undefined {
    return this.routes.get(path);
  }

  private isAuthorisedInternal(path: string, permissions: PermissionsInterface) {
    const routeEntry = this.routes.get(path);
    const options = routeEntry?.options as RouteOptions;
    const requiredPermission: RequiredPermissionType = options?.requiredPermission || null;

    if (requiredPermission) {
      return permissions.hasAccess(requiredPermission.feature, requiredPermission.action);
    }

    //if no requiredPermission exists, then return true by default
    return true;
  }

  private registerRoute(
    routeProps: RouteProps,
    options: RouteOptions,
    isPublic: boolean
  ): void {
    const { path } = routeProps;

    options = {
      defaultTimeRange: timeRangeUtils.getDefaults(),
      requiresTimeRange: false,
      requiresCompareTimeRange: false,
      requiresLeftNav: isPublic ? false : true,
      requiresTopBar: isPublic ? false : true,
      systemTenantOnly: false,
      ...(options || {}),
    };

    const paths = Array.isArray(path) ? path : [path];
    paths.forEach(p => {
      this.routes.set(p, {
        isPublic,
        props: routeProps,
        options
      });
    });
  }

  private getRoutes(isPublic: boolean): RouteProps[] {
    const incPropsArr = Array.from(this.routes.values());
    const routesArr = incPropsArr.filter(ip => ip.isPublic === isPublic).map(ip => ip.props);
    return routesArr;
  }
}

const routeManager = new RouteManager();

export { routeManager };

