import { last, uniqBy, clone, cloneDeep } from "lodash";
import {
  UserServiceFieldWithMeta, SliceSpec, UserServiceFieldMetricConfigDefinition, PostAgg, OverTagPostAgg, WidgetConfigUtils,
  UserServiceFilterExpression, PostAggProjection, LimitSpecFunction, OverTagAgg, OverTimeAgg,
  SliceSet, SelectorSpec, SelectorTag, WidgetResponseDTO, SortSpecSortBy, WidgetQuerySchema, UserServiceFieldSlice, UserServiceField
} from "../../../services/api/explore";
import {
  TimeRange, DataTypeVisualisationManager, VizToQueryConfig, QueryType, DataTypeAggregationManager,
  generateId, VisualisationConfig, Visualisations, FieldSubType, DataType, numericDataTypes
} from "../../../core";
import { dataTypeManager, ENTITY_TAG, eventFieldUtils, FieldPickerUtils, pluralizeWord } from "../../../utils";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { ExploreQueryType } from "../../../services/datasources/explore/types";
import { ExploreQueryUtils } from "../../../utils/ExploreQueryUtils";
import { EntityAggregationMeta } from "../../../services/api/types";
import { logger } from "../../../services/logging/Logger";
import { TagFilterSelection, ChangeMetric } from "../../../biz-entity";
import { extractSelectorFiltersFromFilterSelection } from "../../../utils/ExploreUtils";
import { DataTypeManagerUtils } from "../../../core/datatype-viz-agg-manager/ManagerUtils";
import { USFWQueryConfig, USFieldQuerySourceConfig, WidgetQuerySourceConfig } from "./models";
import { USFieldDataPayload, VizOption } from "./types";

export class USFieldWidgetUtils {
  static durationFieldName = 'duration';
  static hasErrorFieldName = 'hasError';
  static eventIDFieldName = 'eventID';

  static durationFieldDisplayName = 'latency';
  static hasErrorFieldDisplayName = 'errors';
  static eventIDFieldDisplayName = 'requests';

  static getMetricName(fieldName: string, aggregatedFieldName: string): string {
    switch (fieldName) {
      case this.durationFieldName:
        return "Requests Latency";

      case this.hasErrorFieldName:
        return "Errors Count";

      case this.eventIDFieldName:
        return "Requests Count";

      default:
        return aggregatedFieldName;
    }
  }

  static getDisplayFieldName(userServiceField: UserServiceField, eventTypeName?: string) {
    const fieldName = eventFieldUtils.removeFieldsPrefix(userServiceField?.fieldName || '');

    switch (fieldName) {
      case USFieldWidgetUtils.durationFieldName:
        return USFieldWidgetUtils.durationFieldDisplayName;

      case USFieldWidgetUtils.eventIDFieldName:
        return eventTypeName ? `Total ${pluralizeWord(eventTypeName)}`
          : USFieldWidgetUtils.eventIDFieldDisplayName;

      case USFieldWidgetUtils.hasErrorFieldName:
        return eventTypeName ? `Failed ${pluralizeWord(eventTypeName)}`
          : USFieldWidgetUtils.hasErrorFieldDisplayName;

      default:
        return fieldName;
    }
  }

  static isEventIDField(fieldName: string): boolean {
    return fieldName === this.eventIDFieldName;
  }

  static getFieldName(queryConfig: USFWQueryConfig): string {
    const { sourceQueryConfig } = queryConfig;

    if (sourceQueryConfig.queryType === "userServiceField") {
      const { usField } = sourceQueryConfig;
      return usField.userServiceField.fieldName;
    }

    if (sourceQueryConfig.queryType === "widgetConfig") {
      const { widgetResponse, metricId } = sourceQueryConfig;
      const { widgetConfig } = widgetResponse;
      const metricDef = widgetConfig.dataDefinition.metrics[metricId];
      return metricDef?.name || '';
    }
    // Add implementation for other source types

    return "";
  }

  static isPredefinedField(usfm: UserServiceFieldWithMeta) {
    const { userServiceField, userServiceMetadata } = usfm;
    const { fieldName } = userServiceField;
    const { diagnostic } = userServiceMetadata;
    return diagnostic && this.isPredefinedFieldName(fieldName);
  }

  static getVisualisations(queryConfig: USFWQueryConfig) {
    const queryType = queryConfig?.sourceQueryConfig?.queryType;
    const isWidgetQuerySource = queryType === "widgetConfig";
    let preferTimeseries = false;

    let vizConfig: VisualisationConfig;

    if (isWidgetQuerySource) {
      preferTimeseries = true;
      vizConfig = DataTypeVisualisationManager.getAllVisualisations();
    } else {
      const fieldName = this.getFieldName(queryConfig);
      const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

      if (ootbMetricName) {
        vizConfig = DataTypeVisualisationManager.getVisualisationsForOOTBMetrics(ootbMetricName);
        preferTimeseries = false;
      } else {
        const {
          cardinality,
          uiDataType
        } = this.getDataTypeAndAggInfo(queryConfig);

        preferTimeseries = this.isHighCardinalityNumericMetric(uiDataType, cardinality);
        vizConfig = DataTypeVisualisationManager.getVisualisationsByUIDataType(uiDataType, cardinality);
      }
    }

    return this.getVisualisationOptions(vizConfig, preferTimeseries);
  }

  static getAggregators(queryConfig: USFWQueryConfig) {
    const fieldName = this.getFieldName(queryConfig);

    const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

    if (ootbMetricName) {
      return DataTypeAggregationManager.getAggregationsForOOTBMetrics(ootbMetricName);
    } else {
      const {
        cardinality,
        uiDataType
      } = this.getDataTypeAndAggInfo(queryConfig);

      return DataTypeAggregationManager.getAggregationsByUIDataType(uiDataType, cardinality);
    }
  }

  static getQueryDataPayload(
    widgetResponseDTO: WidgetResponseDTO,
    queryConfig: USFWQueryConfig,
    vizConfig: VizToQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    selectedFilters: TagFilterSelection,
    aggregatedTags?: string[],
    metricId?: string,
    metricType: ChangeMetric = 'current',
    postAggProjection: PostAggProjection = "current",
    limitSpecFunction: LimitSpecFunction = null,
    limit = 30,
    isSingleStatQuery = false
  ): USFieldDataPayload {
    const { sourceQueryConfig, sliceSet, selectorSpec } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        const usfMetricDef = widgetResponseDTO?.widgetConfig?.dataDefinition?.metrics?.[metricId] as UserServiceFieldMetricConfigDefinition;
        return this.getPayloadForUSFieldSource(
          widgetResponseDTO,
          usfMetricDef,
          queryConfig,
          vizConfig,
          timeRange,
          compareTimeRange,
          selectedFilters,
          aggregatedTags,
          metricType,
          postAggProjection,
          limitSpecFunction,
          limit,
          isSingleStatQuery
        );
      }

      case "widgetConfig": {
        return this.getPayloadForWidgetSource(
          widgetResponseDTO,
          queryConfig,
          vizConfig,
          selectorSpec,
          sliceSet,
          timeRange,
          compareTimeRange,
          selectedFilters,
          aggregatedTags,
          metricType,
          postAggProjection,
          limitSpecFunction,
          limit,
          isSingleStatQuery
        );
      }

      default: {
        return {
          mode: ExploreQueryType.adhoc,
          sliceSpec: []
        };
      }
    }
  }

  static getWidgetResponseDTO(
    entityType: string,
    userServiceId: string,
    aggregator: string,
    queryConfig: USFWQueryConfig,
    metricId?: string,
    additionalSlices?: UserServiceFieldSlice[],
    defMetricName?: string
  ): WidgetResponseDTO {
    const { sourceQueryConfig } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        return this.getWidgetResponseDTOForUSFieldSource(
          entityType,
          userServiceId,
          aggregator,
          queryConfig,
          metricId,
          additionalSlices,
          defMetricName
        );
      }

      case "widgetConfig": {
        return this.getWidgetResponseDTOForWidgetSource(queryConfig);
      }

      default: {
        return {
          querySchema: {
            querySchema: []
          },
          version: 1,
          widgetConfig: ExploreQueryUtils.getDefaultWidgetConfig(),
          widgetId: generateId()
        };
      }
    }
  }

  static getAggTagFromPayload(payload: USFieldDataPayload): string[] {
    const { sliceSpec } = payload || {};
    const { postAgg } = sliceSpec?.[0] || {};
    const { overTagAgg } = (postAgg as OverTagPostAgg) || {};
    const { tagName } = overTagAgg || {};
    if (tagName) {
      return tagName;
    }
    return [ENTITY_TAG];
  }

  static isHasErrorField(fieldName: string) {
    return fieldName === this.hasErrorFieldName;
  }

  static getDataTypeAndAggInfo(queryConfig: USFWQueryConfig) {
    let subType: FieldSubType = 'not_set';
    let dataType: DataType = null;
    let aggInfo: EntityAggregationMeta = {
      cardinality: {
        ratio: 0,
        value: "0"
      }
    };

    const { sourceQueryConfig } = queryConfig;

    switch (sourceQueryConfig.queryType) {
      case "userServiceField": {
        const { usField } = sourceQueryConfig;
        const {
          dataType: uDataType,
          subType: uSubType,
          aggInfo: uAggInfo
        } = this.getDataTypeAndAggInfoFromUSField(usField);

        dataType = uDataType;
        subType = uSubType;
        aggInfo = uAggInfo || aggInfo;

        break;
      }

      case "widgetConfig": {
        const { widgetResponse, metricId } = sourceQueryConfig as WidgetQuerySourceConfig;
        const metricDef = widgetResponse?.widgetConfig?.dataDefinition?.metrics?.[metricId];
        if (metricDef?.sourceType === 'userServiceField') {
          const { userServiceField } = metricDef.userServiceFieldMetricConfig;
          const usField: UserServiceFieldWithMeta = {
            userServiceField
          };
          const {
            dataType: uDataType,
            subType: uSubType,
            aggInfo: uAggInfo
          } = this.getDataTypeAndAggInfoFromUSField(usField);

          dataType = uDataType;
          subType = uSubType;
          aggInfo = uAggInfo || aggInfo;
        } else {
          dataType = "ENTITY";
        }

        break;
      }
    }

    const { cardinality } = aggInfo || {};
    const [, uiDataType] = dataTypeManager.getDataTypeWithNameFromKind(dataType, subType);

    const { ratio = 0, value = '0' } = cardinality || {};
    const numCardinality = parseFloat(value);

    return {
      subType,
      dataType,
      aggInfo,
      cardinality: numCardinality,
      cardinalityRatio: ratio,
      uiDataType
    };
  }

  static getDataTypeAndAggInfoFromUSField(usField: UserServiceFieldWithMeta) {
    const { userServiceMetadata, userServiceField } = usField;
    const { dataType: uDataType, entityField, fieldName } = userServiceField;
    const { subType: uSubType } = userServiceMetadata || {};
    const { kindDescriptor, propType } = entityField || {};

    const dataType = propType && propType !== 'NA' ? propType : uDataType;
    const subType = uSubType ? (uSubType as FieldSubType)
      : (fieldName === this.durationFieldName) ? 'duration' :
        kindDescriptor?.type || 'not_set';
    const aggInfo = userServiceMetadata?.aggregationResponse;

    return {
      dataType,
      subType,
      aggInfo
    };
  }

  static getDefaultSlicesBasedOnQueryConfig(queryConfig: USFWQueryConfig, implicitSlice: UserServiceFieldSlice): UserServiceFieldSlice[] {
    if (queryConfig.sourceQueryConfig.queryType === "userServiceField") {
      const usFieldWithMeta = queryConfig.sourceQueryConfig.usField;
      const usField = usFieldWithMeta.userServiceField;
      const cardinality = parseInt(usFieldWithMeta?.userServiceMetadata?.aggregationResponse?.cardinality?.value || "1", 10);
      const isVeryHighCardinalityField = DataTypeManagerUtils.getCardinalityCategory(cardinality) === 'very-high';

      const fieldName = this.getFieldName(queryConfig);
      const ootbMetricName = DataTypeVisualisationManager.getOOTBMetricNameForFieldName(fieldName);

      if (ootbMetricName) {
        return [implicitSlice];
      } else if (isVeryHighCardinalityField) {
        return [];
      } else if (USFieldWidgetUtils.isNumericMetric(usField.dataType)) {
        return [implicitSlice];
      } else {
        const usFieldSlice: UserServiceFieldSlice = {
          tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
          userServiceField: usField,
        };
        return [usFieldSlice];
      }
    }
    return [];
  }

  static isHighCardinalityNumericMetric(uiDataType: DataType, cardinality: number): boolean {
    const isNumericType = this.isNumericMetric(uiDataType);
    const cardinalityCategory = DataTypeVisualisationManager.getCardinalityCategory(cardinality);
    const isHighCardinality = cardinalityCategory === 'high' || cardinalityCategory === 'very-high';

    return isNumericType && isHighCardinality;
  }

  static isNumericMetric(uiDataType: DataType) {
    return numericDataTypes.includes(uiDataType);
  }

  private static getVisualisationOptions(vizConfig: VisualisationConfig, preferTimeseries = false): VizOption[] {
    const { basic, timeseries } = vizConfig;

    const first = preferTimeseries ? timeseries : basic;
    const next = preferTimeseries ? basic : timeseries;

    let vizArray = [
      first[0],
      next[0],
      ...first.slice(1),
      ...next.slice(1)
    ].filter(v => existingViz.includes(v?.visualisation));

    if (vizArray.length < 2) {
      vizArray.push({
        queryType: QueryType.aggregatedTimeseriesOverTag,
        visualisation: Visualisations.timeseries,
        options: {
          aggregateBySliceTag: true
        }
      });
    }

    vizArray = uniqBy(vizArray, v => v.visualisation);

    return vizArray.map((viz, idx) => {
      const id = idx.toString();
      return {
        id,
        ...viz
      };
    });
  }

  private static isPredefinedFieldName(fieldName: string) {
    return [this.durationFieldName, this.hasErrorFieldName, this.eventIDFieldName].includes(fieldName);
  }

  private static getQueryAggregators(
    fieldName: string,
    aggregator: string,
    queryConfig: USFWQueryConfig,
    matchingQuerySchema: WidgetQuerySchema
  ) {
    const { overTagAggregator, overTimeAggregator } = queryConfig || {};

    let metricAggregator = aggregator;

    if ((fieldName === this.eventIDFieldName || fieldName === this.hasErrorFieldName) && aggregator !== 'contribution') {
      metricAggregator = 'count';
    }

    const overTimeAgg = overTimeAggregator || matchingQuerySchema?.defaultTimeAgg ||
      ExploreQueryUtils.getOverTimeAggregatorForAggregator(aggregator);
    const overTagAgg = overTagAggregator || matchingQuerySchema?.defaultTagAgg ||
      ExploreQueryUtils.getOverTagAggregatorForAggregator(aggregator);

    return {
      metricAggregator,
      overTimeAgg,
      overTagAgg
    };
  }

  private static getWidgetResponseDTOForUSFieldSource(
    entityType: string,
    userServiceId: string,
    aggregator: string,
    queryConfig: USFWQueryConfig,
    metricId: string,
    addtionalSlices: UserServiceFieldSlice[] = [],
    defMetricName = ''
  ): WidgetResponseDTO {
    // const addImplicitSlice = !featureFlagService.isFeatureEnabled(FEATURE_FLAGS.skipImplicitSlice);
    const addImplicitSlice = true;

    const {
      usField,
      usfSliceSet,
      filterExpressions: presetFilterExpressions
    } = queryConfig.sourceQueryConfig as USFieldQuerySourceConfig;

    const usFieldCopy = cloneDeep(usField);
    const userServiceField = usFieldCopy.userServiceField;
    const { fieldName, dataType } = userServiceField;

    usFieldCopy.userServiceField.fieldName = this.isHasErrorField(fieldName) ? this.eventIDFieldName : fieldName;
    usFieldCopy.userServiceField.userServices = usFieldCopy.userServiceField.userServices
      .filter(usTuple => usTuple.userServiceEntityId === userServiceId);

    const isPredefinedField = this.isPredefinedFieldName(fieldName);

    const eMetricId = metricId || generateId();
    const widgetConfigDto = ExploreQueryUtils.getUSFieldWidgetConfig(
      entityType,
      userServiceField,
      aggregator,
      !this.isNumericMetric(dataType) && !isPredefinedField,
      addImplicitSlice,
      eMetricId,
      "",
      addtionalSlices
    );

    const usfMetricDef = widgetConfigDto.dataDefinition.metrics[metricId] as UserServiceFieldMetricConfigDefinition;
    usfMetricDef.name = defMetricName || usfMetricDef.name;
    usfMetricDef.userServiceFieldMetricConfig.sliceSets = usfSliceSet || usfMetricDef.userServiceFieldMetricConfig.sliceSets;

    const addHasErrorFilterExpr = fieldName === this.hasErrorFieldName;
    const filterExpressions: UserServiceFilterExpression[] = addHasErrorFilterExpr ? [{
      field: usField.userServiceField,
      operator: "=",
      value: 'true'
    }] : [];
    filterExpressions.push(...(presetFilterExpressions || []));
    usfMetricDef.userServiceFieldMetricConfig.filterExpressions = filterExpressions;

    return {
      querySchema: {
        querySchema: []
      },
      version: 1,
      widgetConfig: widgetConfigDto,
      widgetId: ""
    };
  }

  private static getPayloadForUSFieldSource(
    widgetResponseDTO: WidgetResponseDTO,
    usfMetricDef: UserServiceFieldMetricConfigDefinition,
    queryConfig: USFWQueryConfig,
    vizConfig: VizToQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    selectedFilters: TagFilterSelection,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjection: PostAggProjection,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean
  ): USFieldDataPayload {
    const querySchema = widgetResponseDTO?.querySchema?.querySchema;

    if (!usfMetricDef || !querySchema.length) {
      return {
        sliceSpec: [],
        mode: ExploreQueryType.adhoc
      };
    }

    const { usfSliceSet } = queryConfig.sourceQueryConfig as USFieldQuerySourceConfig;

    const { queryType, options, visualisation } = vizConfig;
    const {
      aggregateBySliceTag = false,
      aggregateByEntityTag = false
    } = options || {};

    usfMetricDef.userServiceFieldMetricConfig.sliceSets = usfSliceSet || usfMetricDef.userServiceFieldMetricConfig.sliceSets;
    const sliceSpec = this.getUSFieldSliceSpecAndFilters(
      querySchema,
      usfMetricDef,
      queryConfig,
      timeRange,
      compareTimeRange,
      queryType,
      aggregateBySliceTag,
      aggregateByEntityTag,
      selectedFilters,
      aggregatedTags,
      metricType,
      postAggProjection,
      limitSpecFunction,
      limit,
      isSingleStatQuery
    );

    if (visualisation === Visualisations.singleStat) {
      sliceSpec.forEach(x => {
        if (x) {
          const postAgg = x.postAgg as OverTagPostAgg;
          if (postAgg?.overTagAgg) {
            postAgg.overTagAgg = {
              aggregator: postAgg.overTagAgg.aggregator,
              tagName: []
            };
          }
        }
      });
    }

    return {
      sliceSpec,
      mode: ExploreQueryType.adhoc
    };
  }

  private static getUSFieldSliceSpecAndFilters(
    querySchema: WidgetQuerySchema[],
    metricDef: UserServiceFieldMetricConfigDefinition,
    queryConfig: USFWQueryConfig,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    queryType: QueryType,
    aggregateByCurrentField: boolean,
    aggregateByEntityTag: boolean,
    selectedFilters: TagFilterSelection,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjection: PostAggProjection,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean
  ) {
    const {
      sliceSet: querySliceSet,
      selectorSpec: querySelectorSpec,
      overTagAgg: presetOverTagAgg,
      overTimeAggFunc
    } = queryConfig;

    const { userServiceFieldMetricConfig, id: metricId } = metricDef;
    const { sliceSets, userServiceField, aggregator } = userServiceFieldMetricConfig;
    const { fieldName } = userServiceField;

    const selectImplicitSliceSet = aggregateByEntityTag && aggregatedTags.length === 1 && aggregatedTags.includes(ENTITY_TAG);
    const usfwSliceSet = selectImplicitSliceSet ? sliceSets[0] : last(sliceSets);
    const sliceSet = querySliceSet || WidgetConfigUtils.convertUSFieldSliceSetToTagSlice(usfwSliceSet);

    const tagNames = sliceSet.slices.map(s => s.tagName);
    const matchingQuerySchema = WidgetConfigUtils.getMatchingQuerySchema(querySchema, metricId, tagNames);

    const {
      metricAggregator,
      overTagAgg,
      overTimeAgg
    } = this.getQueryAggregators(fieldName, aggregator, queryConfig, matchingQuerySchema);

    userServiceFieldMetricConfig.aggregator = metricAggregator;

    const aggregateOverTime = queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTime;
    const aggregateOverTag = queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTag;

    const aggTags = this.getOverTagAggTags(sliceSet, aggregatedTags, aggregateByEntityTag, aggregateByCurrentField);

    const { aggTime, timeShiftCompareSeconds } = this.getAggTimeAndTimeShift(timeRange, compareTimeRange);

    const overTagAggPayload: OverTagAgg = {
      aggregator: presetOverTagAgg?.aggregator || overTagAgg,
      tagName: presetOverTagAgg?.tagName || aggTags
    };

    const overTimeAggPayload: OverTimeAgg = {
      aggregator: overTimeAggFunc || overTimeAgg,
      timeInSeconds: aggTime
    };

    const postAgg: PostAgg = {
      overTagAgg: overTagAggPayload,
      overTimeAgg: overTimeAggPayload,
      projections: [postAggProjection],
      timeShiftCompareSeconds,
      sortSpec: {
        limitSpec: {
          function: limitSpecFunction,
          limit: limit,
        },
        sortBy: this.getSortByBasedOnMetricType(metricType)
      },
      isSingleStatQuery
    };

    if (!aggregateOverTime) {
      delete postAgg.overTimeAgg;
      delete postAgg.sortSpec;
    }

    if (!aggregateOverTag) {
      delete postAgg.overTagAgg;
    }

    if (!limitSpecFunction) {
      delete postAgg.sortSpec;
    }

    const sliceSpec: SliceSpec[] = [{
      metricId,
      sliceSet,
      postAgg,
      selectorSpec: querySelectorSpec || this.getSelectorSpec(sliceSet, selectedFilters)
    }];

    // this.adjustSliceSpecForViz(sliceSpec, visualisation);

    return sliceSpec;
  }

  private static getPayloadForWidgetSource(
    widgetResponseDTO: WidgetResponseDTO,
    queryConfig: USFWQueryConfig,
    vizConfig: VizToQueryConfig,
    selectorSpec: SelectorSpec,
    sliceSet: SliceSet,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    selectedFilters: TagFilterSelection,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjection: PostAggProjection,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean
  ): USFieldDataPayload {
    let sliceSpec: SliceSpec[] = [];
    let mode = ExploreQueryType.adhoc;

    if (widgetResponseDTO) {
      try {
        const { sourceQueryConfig } = queryConfig;
        const { metricId } = sourceQueryConfig as WidgetQuerySourceConfig;
        const { querySchema: { querySchema }, widgetId } = widgetResponseDTO || {
          querySchema: {
            querySchema: [] as WidgetQuerySchema[]
          }
        };
        const matchingQuerySchema = querySchema.filter(qs => qs.metricId === metricId);
        const qsSliceSet = matchingQuerySchema[1]?.sliceSet || matchingQuerySchema[0]?.sliceSet;

        mode = widgetId ? ExploreQueryType.saved : ExploreQueryType.adhoc;

        sliceSet = clone(sliceSet || qsSliceSet || {
          slices: []
        });

        const { queryType, options, visualisation } = vizConfig;
        const {
          aggregateBySliceTag = false,
          aggregateByEntityTag = false
        } = options || {};

        sliceSpec = this.getWidgetSliceSpec(
          querySchema,
          queryConfig,
          sliceSet,
          selectorSpec,
          metricId,
          timeRange,
          compareTimeRange,
          queryType,
          aggregateBySliceTag,
          aggregateByEntityTag,
          visualisation,
          selectedFilters,
          aggregatedTags,
          metricType,
          postAggProjection,
          limitSpecFunction,
          limit,
          isSingleStatQuery
        );
      } catch (e) {
        logger.error("USFieldWidgetDataPayload", "Error construction payload", e);
        sliceSpec = [];
      }
    }

    return {
      mode,
      sliceSpec
    };
  }

  private static getWidgetResponseDTOForWidgetSource(queryConfig: USFWQueryConfig): WidgetResponseDTO {
    try {
      const { sourceQueryConfig } = queryConfig;
      const { widgetResponse } = sourceQueryConfig as WidgetQuerySourceConfig;
      return widgetResponse;
    } catch (e) {
      logger.error("USFieldWidgetDataPayload", "Error construction payload", e);
      const widgetConfig = ExploreQueryUtils.getDefaultWidgetConfig();
      return {
        querySchema: {
          querySchema: []
        },
        version: 1,
        widgetConfig,
        widgetId: generateId()
      };
    }
  }

  private static getWidgetSliceSpec(
    querySchema: WidgetQuerySchema[],
    queryConfig: USFWQueryConfig,
    sliceSet: SliceSet,
    querySelectorSpec: SelectorSpec,
    metricId: string,
    timeRange: TimeRange,
    compareTimeRange: TimeRange,
    queryType: QueryType,
    aggregateByCurrentField: boolean,
    aggregateByEntityTag: boolean,
    visualisation: Visualisations,
    selectedFilters: TagFilterSelection,
    aggregatedTags: string[],
    metricType: ChangeMetric,
    postAggProjection: PostAggProjection,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    isSingleStatQuery: boolean
  ) {
    const {
      overTagAgg: presetOverTagAgg,
      overTimeAggFunc
    } = queryConfig;

    const metricQuerySchemas: Record<string, WidgetQuerySchema> = WidgetConfigUtils.getMaxSlicesSliceSetByMetricId(querySchema);

    let metricIds = Object.keys(metricQuerySchemas).filter(metricId => !selectedFilters?.disabledSeries?.[metricId]?.length);
    if (visualisation === Visualisations.insights) {
      metricIds = metricIds.includes(metricId) ? [metricId] : [];
    }

    const { aggTime, timeShiftCompareSeconds } = this.getAggTimeAndTimeShift(timeRange, compareTimeRange);

    const sliceSpec: SliceSpec[] = metricIds.map(metricId => {
      const qsEntry = metricQuerySchemas[metricId];

      const {
        overTagAgg,
        overTimeAgg
      } = this.getQueryAggregators('', '', queryConfig, qsEntry);

      const aggregateOverTime = queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTime;
      const aggregateOverTag = queryType === QueryType.aggregatedTimeseries || queryType === QueryType.aggregatedTimeseriesOverTag;

      const aggTags = this.getOverTagAggTags(sliceSet, aggregatedTags, aggregateByEntityTag, aggregateByCurrentField);

      const postAgg = this.getWidgetQueryPostAgg(
        presetOverTagAgg,
        overTagAgg,
        aggTags,
        overTimeAggFunc,
        overTimeAgg,
        aggTime,
        postAggProjection,
        timeShiftCompareSeconds,
        limitSpecFunction,
        limit,
        metricType,
        aggregateOverTime,
        aggregateOverTag,
        isSingleStatQuery
      );

      if (visualisation === Visualisations.singleStat) {
        if (postAgg.overTagAgg) {
          postAgg.overTagAgg.tagName = [];
        }
      }

      return {
        metricId,
        sliceSet,
        postAgg,
        selectorSpec: querySelectorSpec || this.getSelectorSpec(sliceSet, selectedFilters)
      };
    });

    return sliceSpec;
  }

  private static getWidgetQueryPostAgg(
    presetOverTagAgg: OverTagAgg,
    overTagAgg: string,
    aggTags: string[],
    overTimeAggFunc: string,
    overTimeAgg: string,
    aggTime: number,
    postAggProjection: PostAggProjection,
    timeShiftCompareSeconds: number,
    limitSpecFunction: LimitSpecFunction,
    limit: number,
    metricType: ChangeMetric,
    aggregateOverTime: boolean,
    aggregateOverTag: boolean,
    isSingleStatQuery: boolean
  ) {
    const overTagAggPayload: OverTagAgg = {
      aggregator: presetOverTagAgg?.aggregator || overTagAgg,
      tagName: presetOverTagAgg?.tagName || aggTags
    };

    const overTimeAggPayload: OverTimeAgg = {
      aggregator: overTimeAggFunc || overTimeAgg,
      timeInSeconds: aggTime
    };

    const postAgg: PostAgg = {
      overTagAgg: overTagAggPayload,
      overTimeAgg: overTimeAggPayload,
      projections: [postAggProjection],
      timeShiftCompareSeconds,
      sortSpec: {
        limitSpec: {
          function: limitSpecFunction,
          limit: limit,
        },
        sortBy: this.getSortByBasedOnMetricType(metricType),
      },
      isSingleStatQuery
    };

    if (!aggregateOverTime) {
      delete postAgg.overTimeAgg;
      delete postAgg.sortSpec;
    }

    if (!aggregateOverTag) {
      delete postAgg.overTagAgg;
    }

    if (!limitSpecFunction) {
      delete postAgg.sortSpec;
    }

    return postAgg;
  }

  private static getSelectorSpec(sliceSet: SliceSet, selectedFilters: TagFilterSelection): SelectorSpec {
    const selectorSpec: SelectorSpec = {
      filters: []
    };

    if (selectedFilters) {
      const {
        filtersBySliceSet,
        bizFilter
      } = selectedFilters;

      const filterBySS = filtersBySliceSet.find(f => {
        const slices1 = this.getSlicesForCompare(f.sliceSet);
        const slices2 = this.getSlicesForCompare(sliceSet);
        return WidgetConfigUtils.compareSlices(slices1, slices2);
      });
      const filters = extractSelectorFiltersFromFilterSelection(filterBySS);

      const bizFilterExists = Boolean(bizFilter?.selectionValues?.length);
      if (bizFilterExists) {
        const selValue = bizFilter.selectionValues[0];
        if (selValue) {
          const {
            tag,
            selValToDValArr = []
          } = selValue;

          const canAddFilter = sliceSet.slices.some(s => s.tagName === tag);

          // Add the biz filter only when the slice set contains the tag
          if (canAddFilter) {
            const selValues = Object.keys(selValToDValArr[0] || {});

            const bizFilters: SelectorTag[] = selValues.length > 0 ? [{
              key: tag,
              value: selValues
            }] : [];

            const fExist = filters.length > 0;
            if (fExist) {
              filters.forEach(f => {
                f.tags.push(...bizFilters);
              });
            } else {
              filters.push({
                tags: [...bizFilters]
              });
            }
          }
        }
      }

      selectorSpec.filters = filters;
    }

    return selectorSpec;
  }

  private static getSlicesForCompare(sliceSet: SliceSet) {
    const slices = sliceSet.slices;
    const sortedSlices = slices.sort((a, b) => a.tagName.localeCompare(b.tagName));
    return sortedSlices;
  }

  private static getOverTagAggTags(
    sliceSet: SliceSet,
    aggregatedTags: string[],
    aggregateByEntityTag: boolean,
    aggregateByCurrentField: boolean
  ) {
    const aggTags = aggregatedTags ? aggregatedTags
      : aggregateByEntityTag ? sliceSet.slices[0]?.tagName ? [sliceSet.slices[0]?.tagName] : []
        : aggregateByCurrentField ? last(sliceSet.slices)?.tagName ? [last(sliceSet.slices)?.tagName] : []
          : [];

    return aggTags;
  }

  private static getSortByBasedOnMetricType(metricType: ChangeMetric): SortSpecSortBy {
    return metricType === 'delta' ? 'current' : metricType;
  }

  private static getAggTimeAndTimeShift(timeRange: TimeRange, compareTimeRange: TimeRange) {
    const { raw: trRaw } = timeRange;
    const { raw: cTrRaw } = compareTimeRange;
    const { from, to } = timeRangeUtils.getTimeRangeMillisFromRaw(trRaw);

    const aggTime = millisToSeconds(to.valueOf()) - millisToSeconds(from.valueOf());
    const timeShiftCompareSeconds = millisToSeconds(timeRangeUtils.getMillisFromOffset(cTrRaw.from));

    return {
      aggTime,
      timeShiftCompareSeconds
    };
  }
}

const existingViz = [
  Visualisations.barChart,
  Visualisations.donut,
  Visualisations.gauge,
  Visualisations.geoMap,
  Visualisations.timeseries,
  Visualisations.treeMap,
  Visualisations.insights,
  Visualisations.histogram,
  Visualisations.singleStat,
  Visualisations.sparkLine,
  Visualisations.table
];

const millisToSeconds = (millis: number) => timeRangeUtils.getSecondsFromMillis(millis);
