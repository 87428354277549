import React, { useState, useEffect } from 'react';
import { InceptionTheme } from '@inception/ui/src/themes/types/theme';
import { useTheme } from 'emotion-theming';

import { Table, Column, TableCellProps, RowMouseEventHandlerParams, TableHeaderProps, Index, AutoSizer } from 'react-virtualized';
import { flattenArray, insertInArray, removeChildren } from '../utils';
import { RowDataInternal, TreeGridProps } from '../types';
import Cell from './Cell';
import { SCROLL_BAR_WIDTH } from './constants';


interface BodyProps<T> {
  options: TreeGridProps<T>;
  width: number;
}

function Body<T>(props: BodyProps<T>) {

  const [dataToDisplay, setDataToDisplay] = useState<Array<RowDataInternal<T>>>([]);
  const [gridWidth, setGridWidth] = useState(props.width);
  const [expandAll] = useState<boolean>(!!props.options.expandAll);

  // This happens on clicking a row
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  // This is used when highlighting rows when filtering data
  const [highlightedRowIndices, setHighlightedRowIndices] = useState<Set<number>>(new Set());

  const theme: InceptionTheme = useTheme();

  useEffect(() => {
    if (props.width > 0) {
      setGridWidth(props.width);
      if (props.options.flatData && props.options.flatData.length > 0) {
        setDataToDisplay(props.options.flatData);
      }
    }

    if (props.options.selectedIndex) {
      setSelectedRowIndex(props.options.selectedIndex);
    }

  }, [props.options.flatData, props.width, expandAll, props.options.selectedIndex]);

  useEffect(() => {
    // TODO: if props.options.highlightRowFn is not passed the grid should support some crude filterin based on data which is passed.
    // It can be as simple as just iterating over the data and selecting it.

    if (dataToDisplay && props.options.highlightRowFn && props.options.filterString && props.options.filterString !== '') {
      setHighlightedRowIndices(props.options.highlightRowFn(dataToDisplay, props.options.filterString));
    } else {
      setHighlightedRowIndices(new Set());
    }
  }, [dataToDisplay, props.options.filterString, props.options.highlightRowFn, props]);

  const clickHandler = (key: string, index: number): void => {
    const tempState = dataToDisplay.slice(0);

    tempState[index]._showChildren = !tempState[index]._showChildren;

    if (tempState[index]._showChildren) {
      insertInArray(
        tempState,
        index + 1,
        flattenArray(tempState[index]._children, expandAll, tempState[index]));
    } else {
      removeChildren(tempState, key);
    }

    setDataToDisplay(tempState);
  };

  const getWrappedHeaderRenderer = (props: TableHeaderProps) => {
    if (props.columnData.headerRender) {
      return props.columnData.headerRender(props, props.columnData.additionalCtx);
    }

    return undefined;
  };

  const cellRenderer = ({ rowData, columnIndex, rowIndex }: TableCellProps) => (
    <div key={`cell_row_${rowIndex}_${columnIndex}`}>
      <Cell
        col={props.options.columnDefs[columnIndex]}
        columnIndex={columnIndex}
        key={`row_${rowIndex}_${columnIndex}`}
        level={rowData._level}
        onClick={clickHandler}
        rowData={rowData}
        rowIndex={rowIndex}
      />
    </div>
  );

  /**
     * Handle the row double click
     * @param info
     */
  const onRowDoubleClick = (info: RowMouseEventHandlerParams): void => {
    if (props.options.onRowDblClick) {
      props.options.onRowDblClick(info.index, info.rowData);
    }
  };

  const onRowClick = (info: RowMouseEventHandlerParams): void => {
    setSelectedRowIndex(info.index);
    if (props.options.onRowClick) {
      props.options.onRowClick(info.index, info.rowData);
    }
  };

  const getRowClassname = (info: Index): string => {
    // Selected row takes precendence over highlighted row
    if (info.index === selectedRowIndex) {
      return 'selected-row';
    }

    if (highlightedRowIndices.has(info.index)) {
      return 'highlighted-row';
    }

    return '';
  };

  const getRowStyle = (info: Index) => {
    if (info.index % 2 === 0) {
      return {
        backgroundColor: theme.colors.gray.light8
      };
    }
    return {
      backgroundColor: "inherit"
    };
  };

  const getColumns = (): React.ReactNode[] => props.options.columnDefs.map((colDef) => {
    if (colDef.headerRender) {
      return (
        <Column cellRenderer={cellRenderer}
          columnData={colDef}
          dataKey={colDef.field}
          flexGrow={colDef.flex}
          headerClassName={colDef.headerClassName}
          headerRenderer={getWrappedHeaderRenderer}

          headerStyle={colDef.headerStyle}
          key={colDef.field}
          label={colDef.headerName}

          style={{ marginLeft: 0 }}

          width={10}
        />
      );
    } else {
      return (
        <Column cellRenderer={cellRenderer}
          dataKey={colDef.field}
          flexGrow={colDef.flex}
          headerClassName={colDef.headerClassName}
          headerStyle={colDef.headerStyle}
          key={colDef.field}
          label={colDef.headerName}
          style={{ marginLeft: 0 }}

          width={10}
        />
      );
    }


  });

  if (props.width === 0 || !dataToDisplay || dataToDisplay.length === 0) {
    return <></>;
  }

  return (
  // +1 is to account for the header row
    <div className={'span-details-grid'}>
      <AutoSizer>
        {({ height }) => (
          <div>
            <Table
              headerClassName={'header'}
              headerHeight={headerHeight}

              // Header related props
              headerStyle={props.options.headerStyle}
              height={height}
              onRowClick={onRowClick}

              // Row related props
              onRowDoubleClick={onRowDoubleClick}
              rowClassName={getRowClassname}
              rowCount={dataToDisplay.length}
              rowGetter={({ index }) => dataToDisplay[index]}
              rowHeight={rowHeight}

              rowStyle={getRowStyle}
              width={gridWidth - SCROLL_BAR_WIDTH}
            >

              {getColumns()}

            </Table>
          </div>
        )}
      </AutoSizer>

    </div>
  );
}

const rowHeight = 48;
const headerHeight = 30;

export default Body;
