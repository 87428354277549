import { cx } from 'emotion';
import { isEmpty } from "lodash";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Overlay } from "react-overlays";
import { IncTooltipProps, Placement } from "./types";

const IncToolTip: React.FC<IncTooltipProps> = (props: IncTooltipProps) => {
  const {
    placement = 'top',
    titleId = '',
    titleText = '',
    titleValues = [],
    titleElement = null,
    showArrow = false,
    children,
    className = '',
    variant = 'default',
    noWrap
  } = props;

  const [show, setShow] = useState(false);
  const onHover = useCallback(() => setShow(true), []);
  const onHoverOut = useCallback(() => setShow(false), []);

  const targetRef = useRef<HTMLDivElement>(null);

  const values = useMemo(() => {
    const values: Record<string, string | number> = {};
    titleValues.map((value: string | number, index: number) => values[index] = value);
    return values;
  }, [titleValues]);

  const childrenProps = {
    ref: targetRef
  };
  const targetElement = React.cloneElement(children, childrenProps);
  const hasCustomTooltip = !isEmpty(titleElement);

  const tooltipClassName = useMemo(() => cx(
    "inc-tooltip",
    `inc-tooltip--${variant}`,
    className,
    {
      "inc-tooltip--no-wrap": noWrap,
      "inc-tooltip--custom-element": hasCustomTooltip
    }
  ), [className, hasCustomTooltip, noWrap, variant]);

  const getToolTipText = useCallback(() => {
    if (titleId) {
      return <FormattedMessage id={titleId} values={values} />;
    }
    return <>{titleText}</>;
  }, [titleId, titleText, values]);

  const setArrowPosition = useCallback((styles: CSSProperties, placement: Placement) => {
    const newStyles = styles ? { ...styles } : {};
    if (styles) {
      if (placement === 'top-end' || placement === 'bottom-end') {
        newStyles.left = '-10px';
      } else if (placement === 'top-start' || placement === 'bottom-start') {
        newStyles.left = '10px';
      } else if (placement === 'left-end' || placement === 'right-end') {
        newStyles.top = '5px';
      } else if (placement === 'left-start' || placement === 'right-start') {
        newStyles.top = '-5px';
      }
    }
    return newStyles;
  }, []);

  useEffect(() => {
    const target = targetRef.current;
    if (target) {
      target.onmouseenter = onHover;
      target.onmouseleave = onHoverOut;
    }
    return () => {
      if (target) {
        target.onmouseenter = null;
        target.onmouseleave = null;
      }
    };
  },[onHover, onHoverOut, targetRef]);

  return (
    <>
      {targetElement}
      <Overlay
        containerPadding={6}
        flip
        offset={[0,10]}
        placement={placement}
        show={show}
        target={targetRef}
      >
        {({ props, arrowProps, placement }) => {
          const arrowClassName = `inc-tooltip--arrow arrow-${placement}`;
          const arrowStyles = arrowProps.style;
          const newArrowStyles = setArrowPosition(arrowStyles, placement);
          const ttClassName = `${tooltipClassName} ${placement}`;

          // eslint-disable-next-line react/no-unknown-property
          return <div {...props as any} className={ttClassName} placement={placement}>
            {showArrow && <div
              {...arrowProps}
              className={arrowClassName}
              style={newArrowStyles}
            />}
            <div className="inc-tooltip--content">
              {titleElement || getToolTipText()}
            </div>
          </div>;
        }}
      </Overlay>
    </>
  );
};

export default IncToolTip;
