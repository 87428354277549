import { isUndefined } from "lodash";
import appConfig from "../../../appConfig";
import { TenantConfig } from "../../../platform/core/hooks/tenant-config";
import datasourceApiManager from "../../../platform/services/api/DatasourceApiService";
import { ApptuitDatasource } from "../../../platform/services/datasources/apptuit/ApptuitDatasource";
import { DruidIngestionConfig } from "../../traces/Analytics/types";
import { Role } from "../../../platform/core";
import { InceptionResponse } from "../../../platform/services/api/types";
import { AddtenantRequest, DruidIngestionConfigResponse, ShardsResponse, Tenant, UserHome } from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}
interface ResponseMessage {
  message: string;
}
class TenantApiService {
  readonly NAMESPACE: string = "ui";
  readonly CONFIG_NAME: string = "tenant_config";

  private datasource: ApptuitDatasource;

  protected async init() {
    this.datasource = await datasourceApiManager.getDefault();
  }

  async getTenants(): Promise<Result<Tenant[]>> {
    const result: Result<Tenant[]> = {
      data: [],
      error: false,
      message: ''
    };
    const url = "/api/tenants";
    try {
      await this.init();
      const response = await this.datasource.get<Tenant[], null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async addTenant(data: AddtenantRequest): Promise<Result<ResponseMessage>> {
    const result: Result<ResponseMessage> = {
      data: { message: '' },
      error: false,
      message: ''
    };
    const url = "/api/tenants";
    try {
      await this.init();
      const response = await this.datasource.post<ResponseMessage, AddtenantRequest>(url, data);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response.data.message;
    }
    return result;
  }

  async getShards(): Promise<Result<ShardsResponse>> {
    const result: Result<ShardsResponse> = {
      data: {
        "i_anomMetricsShard": [],
        "i_metricsShard": [],
        "i_tracesShard": []
      },
      error: false,
      message: ''
    };
    const url = "/api/tenants/shards";
    try {
      await this.init();
      const response = await this.datasource.get<ShardsResponse, null>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = (err as any).response?.data.message;
    }
    return result;
  }

  async getUserHome(): Promise<InceptionResponse<UserHome>>{
    const url = `/api/tenants/users/current-user/home`;
    await this.init();
    const response = await this.datasource.get<UserHome, null>(url);
    return response;
  }

  private async getCurrentTenantConfig(): Promise<TenantConfig>{
    const url = `/api/tenants/current/config/${this.NAMESPACE}/${this.CONFIG_NAME}`;
    await this.init();
    const response = await this.datasource.get<TenantConfig, null>(url);
    return response.data;
  }

  private getMockTenantConfig(): Promise<TenantConfig>{
    return Promise.resolve(mockTenantConfig as TenantConfig);
  }

  getTenantConfig(role: Role): Promise<TenantConfig>{
    if (role === Role.Guest || appConfig.anomShareId) {
      return this.getMockTenantConfig();
    } else {
      return this.getCurrentTenantConfig();
    }
  }

  async getDruidIngestionConfig(): Promise<DruidIngestionConfig> {
    const url = '/api/tenants/system/config/druid/ingestion';
    await this.init();
    const response = await this.datasource.get<DruidIngestionConfigResponse, null>(url);
    const data = response?.data;
    const config: DruidIngestionConfig = {trace: isUndefined(data.__trace) ? true : data.__trace,
      event: isUndefined(data.__event) ? true : data.__event,
      preview: isUndefined(data.__preview) ? true : data.__preview,
      sketch: isUndefined(data.__sketch) ? true : data.__sketch};
    return config;
  }
}
const tenantservice = new TenantApiService();
export { tenantservice };

const mockTenantConfig = {
  demoTenant: false,
  leftNavPreset: [] as any[],
  defaultDrilldownVersion: "v5",
  disableNewDashboardFilterPanel: false,
};
