import { IncSelectOption } from "@inception/ui";
import { CompareOperator, ThresholdOperation, ThresholdOpType } from "../../services/api/operationalise";
import { TimeObj, TimeObjUnit } from "../../services/api/explore/types";

export const comparatorOptions: IncSelectOption[] = [
  {
    label: "spike / drop",
    value: CompareOperator.AboveOrBelow,
  },
  {
    label: "spike",
    value: CompareOperator.Above,
  },
  {
    label: "drop",
    value: CompareOperator.Below,
  },
];

export const violationTypes: IncSelectOption[] = [
  {
    label: "Minutes",
    value: "minutes",
  },
  {
    label: "Points",
    value: "points",
  },
];

export const UI_SCHEDULE_KEY = 'uiSchedule';

export const SCHEDULE_TYPES = {
  whenEventOccurs: 'eventOccurrance',
  everyMinute: 'every minute',
  every5Minutes: 'every 5 minutes',
  every15Minutes: 'every 15 minutes',
  every30Minutes: 'every 30 minutes',
  specificSchedule: 'specificRange',
  everyHour: 'every hour',
  everyDay: 'every day',
  everyWeek: 'every week',
  everyMonth: 'every month',
  custom: 'custom'
};

export const UI_THRESHOLD_KEY = 'uiThreshold';

export const THRESHOLD_TYPES = {
  automatic: 'automatic',
  static: 'static',
  peerGroup: 'peerGroup',
  timeShift: 'timeShift',
  self: 'self',
  selfNonSeasonal: 'selfNonSeasonal',
  selfSeasonal: 'selfSeasonal',
  manual: 'manual'
};

export const defThresholdOp: ThresholdOperation = {
  opType: ThresholdOpType.NUM_STD_DEVS,
  value: 2
};

export const defRollingFreq: TimeObj = {
  unit: TimeObjUnit.minutes,
  value: 1
};

export const defRollingFunction = 'avg';

export const UI_ENTITY_AGGREGATED_KEY = "entityAggregated";

export const TEMPORARY_STATE_KEY = "temporaryState";

export const TEMPORARY_SIMULATION_KEY = "i_temporarySimulation";
export const PRIMARY_CONFIG_AS_SIMULATION_KEY = "i_primaryConfigSimulation";
export const SIMULATION_SCHEDULE_KEY = "i_simulationSchedule";
