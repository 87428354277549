import { isEmpty, forEach } from "lodash";
import { CancelToken } from "axios";
import { validateWidgetConfig } from "../../../utils/ExploreUtils";
import { logger, getParamFromUrl, asyncGetItem } from "../../../core";
import { ResultTransformer } from "../../datasources/prometheus/result_transformer";
import { InceptionResponse } from "../types";
import { DEMO_SUMMARY_PAYLOAD_KEYS } from "../Triage";
import appConfig from "../../../../appConfig";
import { request } from "../request";
import { BizService, WidgetFilterType, WidgetFilterPreset, WidgetFilters, BizEntityApiResult } from "./BizServiceCommon";
import {
  WidgetUpsertResponse, WidgetDataDTO, WidgetData, WidgetResponseDTO,
  WidgetResponse, WidgetListResponse, WidgetRequest, WidgetConfigDTO, WidgetCompareDataRequest,
  WidgetAdhocCompareDataRequest, DraftWidgetResponse, DraftWidgetPayload, CohortListResponse, WidgetAction,
  ResultCohortStateResponse, CohortConfigResponse, WidgetDataRequest, TagFilter, WidgetConfig, CompareQuerySchemaResponse,
  MetricUserServiceFilters, CohortConfig, CompareOperatorResponse, DownloadOptions, DemoDataParams, WidgetAdhocDataRequestV2,
  DataSortSpec, WidgetAdhocDataRequestV2Download, WidgetAdhocDataRequest,
} from "./types/exploreTypes";
import { ResultEntityList, ResultEntity, CohortEntityFilter, SliceSpec, WidgetQuerySchema, WidgetQuerySchemaResponse, WorkBookDef, RawDataResponse, RawDataRequest, BizDataQuery } from "./types";

type SavedWidgetDataRequest = WidgetDataRequest | WidgetAdhocDataRequestV2 | WidgetAdhocDataRequestV2Download | WidgetCompareDataRequest;


class ExploreApiService extends BizService {
  protected resultTransformer = new ResultTransformer();

  async getWidgetConfigs(
    entityId: string,
    entityType: string,
    cohortId?: string,
    filterType: WidgetFilterType = "non-static",
    filterLabels: Record<string, string> = {},
    includeOpInfo?: boolean,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<WidgetResponseDTO[]>> {
    await this.init();
    let url = this.getWidgetUrl(entityId, entityType, "/search");
    url += includeOpInfo ? '?includeOpInfo=true' : '';

    const result: BizEntityApiResult<WidgetResponseDTO[]> = {
      data: [],
      error: false,
      message: "",
    };

    const filters = filterType !== "all" ? [WidgetFilterPreset[filterType]] : [];
    forEach(filterLabels, (value, key) =>
      filters.push({
        key,
        op: "eq",
        value,
      })
    );

    const widgetFilter: WidgetFilters = {
      cohortId: cohortId ? cohortId : "",
      filters
    };

    try {
      const response = await this.datasource.post<WidgetListResponse, unknown>(url, widgetFilter, { cancelToken });
      const { widgetResponse, opBasicInfo = {} } = response.data;
      const widgetItems = widgetResponse.filter((wr) => this.isValidWidgetConfig(wr))
        .map((res) => this.widgetItemResponseToWidgetItem(res, opBasicInfo));
      result.data.push(...widgetItems);

      // Temporary fix on the UI since backend is ignoring this filter
      if (filterType === 'non-static') {
        result.data = result.data.filter(wr => !wr.widgetConfig.isStatic);
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getCohortWidgets(
    entityId: string,
    entityType: string,
    cohortId: string,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<WidgetResponseDTO[]>> {
    await this.init();
    let url = this.getWidgetUrl(entityId, entityType);

    if (cohortId) {
      url = url.replace(this.widgetSubUrl, `/cohort/${cohortId}/widgets`);
    }

    const result: BizEntityApiResult<WidgetResponseDTO[]> = {
      data: [],
      error: false,
      message: "",
    };

    try {
      const response = await this.datasource.get<WidgetListResponse, unknown>(url, {}, { cancelToken });
      const widgetItems = response.data.widgetResponse
        .filter((wr) => this.isValidWidgetConfig(wr))
        .map((wr) => this.widgetItemResponseToWidgetItem(wr));
      result.data.push(...widgetItems);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getWidgetConfig(
    entityId: string,
    entityType: string,
    widgetId: string,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<WidgetResponseDTO>> {
    await this.init();
    const anomShareId = appConfig.anomShareId;
    let subUrl = `/${widgetId}`;
    subUrl += anomShareId ? `?shareId=${anomShareId}` : "";
    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<WidgetResponseDTO> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    try {
      const demoResponse: any = asyncGetItem("widgetConfigPayload");
      let response;

      if (getParamFromUrl("demo")) {
        response = await demoResponse;
      } else if (anomShareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.get<WidgetResponse, unknown>(newUrl, {}, { cancelToken });
      } else {
        response = await this.datasource.get<WidgetResponse, unknown>(url, {}, { cancelToken });
      }
      result.data = this.widgetItemResponseToWidgetItem(response.data);
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async updateWidgetConfig(
    widgetConfigDto: WidgetConfigDTO,
    widgetId: string,
    currentVersion: number,
    widgetActions: WidgetAction = {},
    hashLabels = false,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<WidgetUpsertResponse>> {
    await this.init();
    const {
      bizEntityType = null,
      userServiceEntityId = null,
    } = widgetConfigDto;

    const url = this.getWidgetUrl(userServiceEntityId, bizEntityType, `/${widgetId}`);
    const result: BizEntityApiResult<WidgetUpsertResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const widgetConfig = this.getWidgetConfigFromDto(widgetConfigDto);

    const widgetConfigPayload: WidgetRequest = {
      widgetConfig,
      actions: widgetActions || {},
      version: currentVersion,
    };

    const widgetResponse: Partial<WidgetResponseDTO> = {
      widgetConfig: widgetConfigDto,
      widgetId,
    };

    const isValidConfig = validateWidgetConfig(widgetResponse, "create");

    if (!isValidConfig) {
      result.error = true;
      result.message = "Invalid widget config";
    } else {
      try {
        const response = await this.datasource.post<WidgetUpsertResponse, WidgetRequest>(url, widgetConfigPayload, {
          cancelToken,
          params: {
            hashLabels
          }
        });
        const { errors = [] } = response.data;
        if (errors.length) {
          const errMsg = errors.join(", ");
          throw Error(errMsg);
        } else {
          result.data = response.data;
        }
      } catch (err) {
        this.handleError(err, result);
      }
    }

    return result;
  }

  async saveWidgetConfig(
    widgetConfigDto: WidgetConfigDTO,
    widgetActions: WidgetAction = {},
    hashLabels = false,
    widgetId?: string,
    version?: number,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<WidgetUpsertResponse>> {
    await this.init();
    const {
      bizEntityType = null,
      userServiceEntityId = null,
    } = widgetConfigDto;

    let url = this.getWidgetUrl(userServiceEntityId, bizEntityType);
    if (widgetId) {
      url = this.getWidgetUrl(userServiceEntityId, bizEntityType, `/${widgetId}`);
    }

    url = `${url}?hashLabels=${hashLabels}`;

    const result: BizEntityApiResult<WidgetUpsertResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const widgetConfig = this.getWidgetConfigFromDto(widgetConfigDto);

    const widgetConfigPayload: WidgetRequest = {
      widgetConfig,
      actions: widgetActions,
      version: version
    };

    const widgetResponse: Partial<WidgetResponseDTO> = {
      widgetConfig: widgetConfigDto,
    };

    const isValidConfig = validateWidgetConfig(widgetResponse, "create");

    logger.info("Explore API service", "Save widget called with payload", widgetConfigDto);
    logger.info("Explore API service", "Save widget called with actions", widgetActions || "No actions");
    logger.info("Explore API service", "Save widget triggered with payload", widgetConfigPayload);

    if (!isValidConfig) {
      result.error = true;
      result.message = "Invalid widget config";
    } else {
      try {
        logger.debug("Explore Widget", "Widget Save", widgetConfigPayload);
        const response = await this.datasource.post<WidgetUpsertResponse, WidgetRequest>(url, widgetConfigPayload, { cancelToken });
        const { errors = [] } = response.data;

        if (errors.length) {
          const errMsg = errors.join(", ");
          throw Error(errMsg);
        } else {
          result.data = response.data;
        }
      } catch (err) {
        this.handleError(err, result);
      }
    }

    return result;
  }

  async getWidgetData(
    entityId: string,
    entityType: string,
    widgetId: string,
    startTime: number,
    endTime: number,
    intervalSecs: number,
    sliceSpec: SliceSpec[],
    compareFetchConfig?: WidgetCompareDataRequest,
    cancelToken?: CancelToken,
    cohortEntityFilters: CohortEntityFilter[] = [],
    tagFilters: TagFilter[] = [],
    excludeWidgetData = false,
    limit = 30,
    includeQueryConfig = false,
    useV2ApiForCompare = false,
    metricUserServiceFilters: MetricUserServiceFilters = {},
    demoParams: DemoDataParams = null
  ): Promise<BizEntityApiResult<WidgetDataDTO>> {
    await this.init();
    const timeParams = this.getTimeParams(
      startTime,
      endTime,
      intervalSecs
    );

    const isCompareQuery = !isEmpty(compareFetchConfig);
    const anomShareId = appConfig.anomShareId;
    let subUrl = `/${widgetId}/data`;
    subUrl += isCompareQuery ? "/compare" : "";
    subUrl += timeParams;
    subUrl += includeQueryConfig ? "&includeQueryConfig=true" : "";
    subUrl += useV2ApiForCompare ? "&compareV2Api=true" : "";
    subUrl += anomShareId ? `&shareId=${anomShareId}` : "";

    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const requestBody: SavedWidgetDataRequest = this.getWidgetDataQuery(
      sliceSpec,
      compareFetchConfig,
      cohortEntityFilters,
      tagFilters,
      excludeWidgetData,
      limit,
      metricUserServiceFilters
    );

    const result: BizEntityApiResult<WidgetDataDTO> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const params: Record<string, any> = { ...(demoParams || {}) };

    try {

      let response = null;

      if (anomShareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<WidgetData, SavedWidgetDataRequest>(newUrl, requestBody, {
          cancelToken,
          params
        });
      } else {
        response = await this.datasource.post<WidgetData, SavedWidgetDataRequest>(url, requestBody, {
          cancelToken,
          params
        });
      }


      const metricData = this.processWidgetDataResponse(
        response,
        startTime,
        endTime,
        intervalSecs
      );
      result.data = metricData;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  getWidgetDataQuery(
    sliceSpec: SliceSpec[],
    compareFetchConfig?: WidgetCompareDataRequest,
    cohortEntityFilters: CohortEntityFilter[] = [],
    tagFilters: TagFilter[] = [],
    excludeWidgetData = false,
    limit = 30,
    metricUserServiceFilters: MetricUserServiceFilters = {}
  ) {
    const isCompareQuery = !isEmpty(compareFetchConfig);

    const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
    const requestBody: SavedWidgetDataRequest = isCompareQuery ? {
      ...compareFetchConfig,
    } : {
      sliceSpec,
      limit,
      entityFilters,
      tagFilters,
      excludeWidgetData,
      metricUserServiceFilters,
    };

    return requestBody;
  }

  async getWidgetRawEvents(
    entityId: string,
    entityType: string,
    startTime: number,
    endTime: number,
    bizDataQuery: BizDataQuery,
    cancelToken?: CancelToken,
  ): Promise<BizEntityApiResult<RawDataResponse>> {
    await this.init();
    const result: BizEntityApiResult<RawDataResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };
    const timeParams = this.getTimeParams(
      startTime,
      endTime,
    );
    const anomShareId = appConfig.anomShareId;
    let subUrl = `/rawData${timeParams}`;
    subUrl += anomShareId ? `&shareId=${anomShareId}` : "";
    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const payload = {
      bizDataQuery,
    };

    try {
      if (anomShareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        const response = await request.post<RawDataResponse,RawDataRequest>(newUrl, payload, {
          cancelToken,
        });
        result.data = response.data;
      } else {
        const response = await this.datasource.post<RawDataResponse, RawDataRequest>(
          url,
          payload,
          {
            cancelToken,
          });
        result.data = response.data;
      }
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getWidgetDataByConfig(
    entityType: string,
    entityId: string,
    widgetConfigDto: WidgetConfigDTO,
    version: number,
    startTime: number,
    endTime: number,
    intervalSecs: number,
    sliceSpec: SliceSpec[],
    compareFetchConfig?: WidgetCompareDataRequest,
    cancelToken?: CancelToken,
    cohortEntityFilters: CohortEntityFilter[] = [],
    excludeWidgetData = false,
    limit = 30,
    includeQueryConfig = false,
    metricUserServiceFilters = {},
    demoParams: DemoDataParams = null
  ): Promise<BizEntityApiResult<WidgetDataDTO>> {
    await this.init();
    const timeParams = this.getTimeParams(startTime, endTime, intervalSecs);

    const isCompareQuery = !isEmpty(compareFetchConfig);
    const anomShareId = appConfig.anomShareId;
    let subUrl = `/data`;
    subUrl += isCompareQuery ? "/compare" : "";
    subUrl += timeParams;
    subUrl += includeQueryConfig ? "&includeQueryConfig=true" : "";
    subUrl += anomShareId ? `&shareId=${anomShareId}` : "";

    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<WidgetDataDTO> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const requestBody = this.getWidgetDataQueryByConfig(
      widgetConfigDto,
      version,
      sliceSpec,
      compareFetchConfig,
      cohortEntityFilters,
      excludeWidgetData,
      limit,
      metricUserServiceFilters
    );

    const params: Record<string, any> = { ...(demoParams || {}) };

    try {
      let response = null;
      if (anomShareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<WidgetData, WidgetAdhocDataRequest | WidgetAdhocCompareDataRequest>(newUrl, requestBody, {
          cancelToken,
          params,
        });
      } else {
        response = await this.datasource.post<
        WidgetData,
        WidgetAdhocDataRequest | WidgetAdhocCompareDataRequest
        >(url, requestBody, {
          cancelToken,
          params,
        });
      }

      const metricData = this.processWidgetDataResponse(
        response,
        startTime,
        endTime,
        intervalSecs
      );
      result.data = metricData;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  getWidgetDataQueryByConfig(
    widgetConfigDto: WidgetConfigDTO,
    version: number,
    sliceSpec: SliceSpec[],
    compareFetchConfig?: WidgetCompareDataRequest,
    cohortEntityFilters: CohortEntityFilter[] = [],
    excludeWidgetData = false,
    limit = 30,
    metricUserServiceFilters = {}
  ) {
    const isCompareQuery = !isEmpty(compareFetchConfig);

    const widgetConfig = this.getWidgetConfigFromDto(widgetConfigDto);

    let requestBody: WidgetAdhocDataRequest | WidgetAdhocCompareDataRequest;

    if (isCompareQuery) {
      requestBody = {
        ...compareFetchConfig,
        widgetConfig,
      } as WidgetAdhocCompareDataRequest;
    } else {
      const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
      requestBody = {
        widgetConfig,
        version,
        sliceSpec,
        limit,
        entityFilters,
        excludeWidgetData,
        metricUserServiceFilters,
      } as WidgetAdhocDataRequest;
    }

    return requestBody;
  }

  async downloadWidgetDataByConfigV2(
    entityType: string,
    entityId: string,
    widgetId: string,
    widgetConfigDto: WidgetConfigDTO,
    startTime: number,
    endTime: number,
    intervalSecs: number,
    sliceSpec: SliceSpec[],
    cohortEntityFilters: CohortEntityFilter[] = [],
    excludeWidgetData = false,
    limit = 30,
    metricUserServiceFilters: MetricUserServiceFilters = {},
    dataSortSpec: DataSortSpec,
    workBookDef: WorkBookDef
  ): Promise<BizEntityApiResult<Blob>> {
    await this.init();
    const API_VERSION = "V2";
    const timeParams = this.getTimeParams(
      startTime,
      endTime,
      intervalSecs
    );

    const shareId = appConfig.anomShareId;
    let subUrl = widgetId ? `/${widgetId}/data` + API_VERSION : `/data` + API_VERSION;
    subUrl += "/download";
    subUrl += timeParams;
    subUrl += `&includeQueryConfig=false`;
    subUrl += shareId ? `&shareId=${shareId}` : "";

    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<Blob> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const widgetConfig = widgetConfigDto ? this.getWidgetConfigFromDto(widgetConfigDto) : null;


    const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
    let requestBody: WidgetAdhocDataRequestV2Download | SavedWidgetDataRequest = null;

    const dataRequest: WidgetAdhocDataRequestV2 = {
      sliceSpec,
      limit,
      entityFilters,
      excludeWidgetData,
      metricUserServiceFilters,
      dataSortSpec,
      tagFilters: []
    };

    if (widgetConfig) {
      dataRequest.version = 1;
      dataRequest.widgetConfig = widgetConfig;
    }

    requestBody = {
      dataRequest: dataRequest,
      workbookDef: workBookDef
    } as WidgetAdhocDataRequestV2Download;

    try {
      let response = null;
      if (shareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<Blob, WidgetAdhocDataRequestV2 | SavedWidgetDataRequest>(apiUrl, requestBody, { responseType: 'blob' });
      } else {
        response = await this.datasource.post<Blob, WidgetAdhocDataRequestV2 | SavedWidgetDataRequest>(url,requestBody,{ responseType: 'blob' });
      }


      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async downloadWidgetDataByConfig(
    entityType: string,
    entityId: string,
    widgetId: string,
    widgetConfigDto: WidgetConfigDTO,
    startTime: number,
    endTime: number,
    intervalSecs: number,
    sliceSpec: SliceSpec[],
    cohortEntityFilters: CohortEntityFilter[] = [],
    downloadOptions: DownloadOptions,
    excludeWidgetData = false,
    limit = 30,
    metricUserServiceFilters: MetricUserServiceFilters = {},
  ): Promise<BizEntityApiResult<Blob>> {
    await this.init();
    const timeParams = this.getTimeParams(
      startTime,
      endTime,
      intervalSecs
    );

    const shareId = appConfig.anomShareId;
    let subUrl = widgetId ? `/${widgetId}/data` : `/data`;
    subUrl += "/download";
    subUrl += timeParams;
    subUrl += `&includeQueryConfig=false`;
    subUrl += shareId ? `&shareId=${shareId}` : "";

    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<Blob> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    const widgetConfig = widgetConfigDto ? this.getWidgetConfigFromDto(widgetConfigDto) : null;


    const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
    let requestBody: WidgetAdhocDataRequest | SavedWidgetDataRequest = null;
    if (widgetConfig) {
      requestBody = {
        version: 1,
        sliceSpec,
        widgetConfig,
        limit,
        entityFilters,
        excludeWidgetData,
        metricUserServiceFilters,
        download: downloadOptions
      } as WidgetAdhocDataRequest;
    } else {
      requestBody = {
        sliceSpec,
        limit,
        entityFilters,
        tagFilters: [],
        excludeWidgetData,
        metricUserServiceFilters,
        download: downloadOptions,
      };
    }

    try {
      let response = null;
      if (shareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.post<Blob, WidgetAdhocDataRequest | SavedWidgetDataRequest>(apiUrl, requestBody, { responseType: 'blob' });
      } else {
        response = await this.datasource.post<Blob, WidgetAdhocDataRequest | SavedWidgetDataRequest>(url,requestBody,{ responseType: 'blob' });
      }

      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async createDraftWidget(
    widgetConfig: WidgetConfigDTO,
    entityType: string
  ) {
    const url = this.getWidgetUrl(null, entityType, "/draft");
    await this.init();
    const payload: DraftWidgetPayload = {
      widgetConfig: this.getWidgetConfigFromDto(widgetConfig),
    };
    try {
      const resp = await this.datasource.post<DraftWidgetResponse, DraftWidgetPayload>(url, payload);
      if (resp.data.errors.length > 1) {
        throw Error(resp.data.errors.join(" "));
      }
    } catch (e) {
      logger.error("Explore API Service", "", e);
    }
  }

  async createDraftCohort(
    cohortConfig: CohortConfig,
    entityType: string
  ) {
    const url = this.getBizEntityUrl(`${entityType}/cohort/draft`);
    await this.init();
    try {
      const resp = await this.datasource.post<any, CohortConfig>(
        url,
        cohortConfig
      );
      return resp;
    } catch (e) {
      logger.error("Explore API service", "Draft cohort", e);
    }
  }

  async saveCohort(cohortConfig: CohortConfig, entityType: string) {
    const url = this.getBizEntityUrl(`${entityType}/cohort`);
    await this.init();
    try {
      const resp = await this.datasource.post<any, CohortConfig>(
        url,
        cohortConfig
      );
      return resp;
    } catch (e) {
      logger.error("Explore API service", "Save Cohort", e);
    }
  }

  async getCohortState(cohortId: string, entityTypeId: string, generateDemoData = false) {
    const url = this.getBizEntityUrl(`${entityTypeId}/cohort/state/${cohortId}`);
    await this.init();
    try {
      const resp = await this.datasource.get<ResultCohortStateResponse, null>(url, null, { params: { generateDemoData } });
      return resp.data;
    } catch (e) {
      logger.error("Explore API Service", "", e);
    }
  }

  async getCohortConfig(cohortId: string, entityTypeId: string, generateDemoData = false) {
    const url = this.getBizEntityUrl(`${entityTypeId}/cohort/config/${cohortId}`);
    await this.init();
    try {
      const demoResponse = (await asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.cohortDefinitionPayload)) as InceptionResponse<CohortConfigResponse>;
      const resp = getParamFromUrl("demo") ? demoResponse
        : await this.datasource.get<CohortConfigResponse, null>(url, null, { params: { generateDemoData } });
      return resp.data;
    } catch (e) {
      logger.error("Explore API Service", "", e);
    }
  }

  async listCohort(entityType: string) {
    const shareId = appConfig.anomShareId;
    const params = shareId ? `?shareId=${shareId}` : "";
    const url = this.getBizEntityUrl(`${entityType}/filters/cohorts${params}`);

    await this.init();
    try {

      if (shareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        const response = await request.get<CohortListResponse>(apiUrl);
        return response.data;
      }

      const resp = await this.datasource.get<CohortListResponse, null>(url);
      return resp.data;
    } catch (e) {
      logger.error("Explore API Service", "", e);
    }
  }

  async getCurrentlyImpacted(
    entityTypeId: string,
    cohortId: string,
    startTime: number,
    endTime: number
  ) {
    const url = this.getBizEntityUrl(`${entityTypeId}/cohort/${cohortId ?? "all"}/impacted/entities`);
    await this.init();
    const timeParams = this.getTimeParams(startTime, endTime);

    const result: BizEntityApiResult<ResultEntity[]> = {
      data: [],
      error: false,
      message: "",
      cancelled: false,
    };

    try {
      const { data, status, statusText } = await this.datasource.get<ResultEntityList, never>(`${url}${timeParams}`);
      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data = data?.resultEntities || [];
    } catch (e) {
      logger.error("Explore API Service", "", e);
      this.handleError(e, result);
    }

    return result;
  }

  private isValidWidgetConfig(wr: WidgetResponse): boolean {
    const isValid =
      !isEmpty(wr.widgetConfig?.dataDefinition?.fields) ||
      !isEmpty(wr.widgetConfig?.dataDefinition?.metrics);
    if (!isValid) {
      logger.debug("Explore Api Service", "Invalid Widget config", wr);
    }
    return isValid;
  }

  protected widgetItemResponseToWidgetItem(resItem: WidgetResponse, opBasicInfo: WidgetListResponse['opBasicInfo'] = {}) {
    const {
      errors,
      widgetName,
      version,
      widgetId: id,
      widgetConfig,
      querySchema,
    } = resItem;

    const finalVersion = parseInt((version || 0).toString(), 10);

    const widgetConfigDTO = this.getDtoFromWidgetConfig(widgetConfig);

    const exploreWidgetItem: WidgetResponseDTO = {
      errors,
      version: finalVersion,
      widgetId: id,
      widgetName,
      widgetConfig: widgetConfigDTO,
      entityValidationInfo: resItem.entityValidationInfo,
      querySchema,
      opInfos: resItem?.opBasicInfo?.opBasicInfo || opBasicInfo[id]?.opBasicInfo || []
    };

    return exploreWidgetItem;
  }

  async deleteWidgetReferences(
    entityId: string,
    entityType: string,
    widgetId: string
  ): Promise<BizEntityApiResult<WidgetResponseDTO[]>> {
    await this.init();
    const url = this.getWidgetUrl(entityId, entityType, `/${widgetId}`);

    const result: BizEntityApiResult<WidgetResponseDTO[]> = {
      data: [],
      error: false,
      message: "",
    };

    try {
      const response = await this.datasource.delete<string>(url);
      result.message = response?.statusText;
      return result;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getQuerySchemaForDraftWidgetConfig(widgetConfigDTO: WidgetConfigDTO) {
    await this.init();
    const { bizEntityType, userServiceEntityId } = widgetConfigDTO;

    const anomShareId = appConfig.anomShareId;

    const subUrl = anomShareId ? `/querySchema?shareId=${anomShareId}` : "/querySchema";
    const url = this.getWidgetUrl(userServiceEntityId, bizEntityType, subUrl);
    const widgetConfig = this.getWidgetConfigFromDto(widgetConfigDTO);
    const requestBody = widgetConfig;
    const result: BizEntityApiResult<WidgetQuerySchema[]> = {
      data: [],
      error: false,
      message: "",
      cancelled: false,
    };

    if (!bizEntityType && !userServiceEntityId) {
      logger.error("Explore API Service", "Requested querySchema for widget with no bizEntityType or userServiceEntityId. Returning empty query schema", widgetConfigDTO);
      return result;
    }

    try {
      let data,
        status,
        statusText;
      if (anomShareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        const { data: eData, status: eStatus, statusText: eStatusText } =
          await request.post<WidgetQuerySchemaResponse, WidgetConfig>(apiUrl, requestBody);
        data = eData;
        status = eStatus;
        statusText = eStatusText;
      } else {
        const { data: eData, status: eStatus, statusText: eStatusText } =
          await this.datasource.post<WidgetQuerySchemaResponse, WidgetConfig>(url, requestBody);
        data = eData;
        status = eStatus;
        statusText = eStatusText;
      }


      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data = data?.querySchema || [];
    } catch (e) {
      logger.error("Explore API Service", "", e);
      result.error = true;
      this.handleError(e, result);
    }

    return result;
  }

  async getQuerySchemaForCompare(
    entityTypeId: string,
    userServiceId: string,
    widgetId: string,
    metricId: string,
    compareId: string
  ) {
    await this.init();
    const url = this.getWidgetUrl(
      userServiceId,
      entityTypeId,
      `/${widgetId}/compare/schema`
    );
    const requestBody: Partial<WidgetCompareDataRequest> = {
      compareId,
      metricId,
    };
    const result: BizEntityApiResult<CompareQuerySchemaResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false,
    };

    try {
      const { data, status, statusText } = await this.datasource.post<
      CompareQuerySchemaResponse,
      Partial<WidgetCompareDataRequest>
      >(url, requestBody);
      const isErr = status > 299;
      result.error = isErr;
      result.message = isErr ? statusText : "";
      result.data = data;
    } catch (e) {
      logger.error("Explore API Service", "", e);
      result.error = true;
      this.handleError(e, result);
    }

    return result;
  }

  async getCohortDefaultFilterState(
    opId: string,
    widgetId: string
  ) {
    const url = this.getBizEntityUrl(
      `op10ze/${opId}/compareoperator?widgetId=${widgetId}`
    );
    await this.init();
    try {
      const resp = await this.datasource.get<
      CompareOperatorResponse,
      never
      >(url);
      return resp.data;
    } catch (e) {
      logger.error("Explore API Service", "", e);
    }
  }
}

const exploreApiService = new ExploreApiService();

export default exploreApiService;
