import { range } from "lodash";

const compareResultTS: any[] = [];
const resultTS = range(1, 1083).map(i => {
  const name = `Entity-${i}`;
  const entry = {
    "datapoints": [[1, 164965680000]],
    "refId": "requests_count_Merchant_userService",
    "target": name,
    "tags": {
      "i_entity": name
    },
    "metricName": "",
    "eTags": {
      "i_entity": name
    }
  };

  if (i < 701) {
    compareResultTS.push(entry);
  }
  return entry;
});

export const EAMockData = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "requests_count_Merchant",
        "data": {

        },
        "compareConfigData": {

        },
        "entityLookupData": {

        },
        "dataQueryConfig": {

        },
        "postAggResult": {
          "data": {
            "requests_count_Merchant_userService": {
              "data": resultTS,
              "schema": [] as any,
              "preLimitSelectionCount": 160
            }
          },
          "entityLookupData": {},
          "percentageChangeData": {
            "requests_count_Merchant_userService": {
              "data": resultTS,
              "schema": [] as any,
              "preLimitSelectionCount": 160
            }
          },
          "timeShiftedData": {
            "requests_count_Merchant_userService": {
              "data": compareResultTS,
              "schema": [] as any,
              "preLimitSelectionCount": 160
            }
          }
        }
      }
    ]
  }
];
