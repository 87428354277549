import React from "react";
import CloseSVG from "../../images/icons/Close.svg";
import SlackColorSVG from '../../images/icons/slack-new-logo.svg';
import GmailSVG from "../../images/icons/Gmail.svg";

export { ReactComponent as SliceIcon9 } from "../../images/icons/slices9.svg";
export { ReactComponent as AlertBellIcon14 } from "../../images/icons/alertbell14.svg";
export { ReactComponent as AlertBellIcon8 } from "../../images/icons/alertbell8.svg";
export { ReactComponent as CancelIcon8 } from "../../images/icons/cancel8.svg";
export { ReactComponent as FilterIcon14 } from "../../images/icons/filter14.svg";
export { ReactComponent as FilterIcon8 } from "../../images/icons/filter8.svg";
export { ReactComponent as SaveWithPlustIcon17 } from "../../images/icons/savewithplus17.svg";
export { ReactComponent as SaveIcon16 } from "../../images/icons/save16.svg";
export { ReactComponent as PinIcon } from "../../images/icons/Pin.svg";
export { ReactComponent as SelectIcon } from "../../images/icons/Select.svg";

export { ReactComponent as HomeIcon } from "../../images/icons/Home.svg";
export { ReactComponent as FilledHomeIcon } from "../../images/icons/Home Filled.svg";
export { ReactComponent as DeleteIcon } from "../../images/icons/Delete.svg";

export { ReactComponent as IncUpArrow } from "../../images/icons/TrendArrowUp.svg";
export { ReactComponent as IncDownArrow } from "../../images/icons/TrendArrowDown.svg";

export { ReactComponent as CheckSuccessIcon } from "../../images/icons/CheckSuccess.svg";

export { ReactComponent as ComponentIcon } from "../../images/icons/Component.svg";
export { ReactComponent as APIIcon } from "../../images/icons/API.svg";
export { ReactComponent as ServiceIcon } from "../../images/icons/Service.svg";

export { ReactComponent as AddCircle } from "../../images/icons/Add circle.svg";
export { ReactComponent as RemoveCircle } from "../../images/icons/Remove.svg";
export { ReactComponent as Expand } from "../../images/icons/Expand.svg";
export { ReactComponent as Collapse } from "../../images/icons/Collapse.svg";
export { ReactComponent as ViewingIcon } from "../../images/icons/ViewingIcon.svg";
export { ReactComponent as FlameIcon } from "../../images/icons/fire.svg";
export { ReactComponent as InsightsIcon } from "../../images/icons/insights.svg";
export { ReactComponent as IntervalBasedScheduleIcon } from "../../images/icons/IntervalBasedSchedule.svg";
export { ReactComponent as ContinuousScheduleIcon } from "../../images/icons/ContinuousSchedule.svg";
export { ReactComponent as SalesforceIcon20x15 } from "../../images/icons/Salesforce20x15.svg";
export { ReactComponent as SalesforceIcon24x18 } from "../../images/icons/Salesforce24x18.svg";
export { ReactComponent as FreshdeskIcon } from "../../images/icons/Freshdesk.svg";
export { ReactComponent as WebHooks } from "../../images/icons/web-hook.svg";
export { ReactComponent as CenterFocusIcon } from "../../images/icons/center-focus.svg";
export { ReactComponent as TriangleExclamationRegularIcon } from "../../images/icons/triangle-exclaimation-regular.svg";
export { ReactComponent as DataDogIcon } from "../../images/icons/datadoghq-icon.svg";
export { ReactComponent as CaretRightIcon } from "../../images/icons/caret-right.svg";
export { ReactComponent as EngagedIcon } from "../../images/icons/Engaged.svg";
export { ReactComponent as ColdIcon } from "../../images/icons/Cold.svg";
export { ReactComponent as TriangleExclamationFilledIcon } from "../../images/icons/TriangleExclamationFilled.svg";
export { ReactComponent as PencilLineIcon } from "../../images/icons/PencilLine.svg";
export { ReactComponent as TeamIcon } from "../../images/icons/Team.svg";
export { ReactComponent as PagerDutyIcon } from "../../images/pagerduty.svg";
export { ReactComponent as OpsgenieIcon } from "../../images/opsgenie.svg";
export { ReactComponent as JourneyTopCurve } from "../../images/icons/Journey top curve.svg";
export { ReactComponent as JourneyRightCurve } from "../../images/icons/Journey right curve.svg";
export { ReactComponent as JourneyLeftCurve } from "../../images/icons/Journey left curve.svg";
export { ReactComponent as UserIcon } from "../../images/icons/User.svg";
export { ReactComponent as TransactionalCardIcon } from "../../images/icons/TransactionalCard.svg";
export { ReactComponent as BriefcaseIcon } from "../../images/icons/Briefcase.svg";
export { ReactComponent as CodeIcon } from "../../images/icons/Code.svg";
export { ReactComponent as ClipboardTickIcon } from "../../images/icons/ClipboardTick.svg";
export { ReactComponent as BookIcon } from "../../images/icons/Book.svg";
export { ReactComponent as AddIcon } from "../../images/icons/Add.svg";
export { ReactComponent as CollaborationIcon } from "../../images/icons/Collaboration.svg";
export { ReactComponent as SettingIcon } from "../../images/icons/Setting.svg";
export { ReactComponent as ScannerIcon } from "../../images/icons/Scanner.svg";
export { ReactComponent as HealthIcon } from "../../images/icons/Health.svg";
export { ReactComponent as MonitorMobileIcon } from "../../images/icons/MonitorMobile.svg";
export { ReactComponent as ChartIcon } from "../../images/icons/Chart.svg";
export { ReactComponent as SentryIcon } from "../../images/icons/sentry.svg";
export { ReactComponent as SpinnakerIcon } from "../../images/icons/spinnaker.svg";
export { ReactComponent as HubspotIcon } from "../../images/icons/hubspot.svg";
export { ReactComponent as CircleCiIcon } from "../../images/icons/circleci.svg";
export { ReactComponent as NewRelicIcon } from "../../images/icons/newrelic.svg";
export { ReactComponent as FastlyIcon } from "../../images/icons/fastly.svg";
export { ReactComponent as StripeIcon } from "../../images/icons/stripe.svg";
export { ReactComponent as K8sIcon } from "../../images/icons/k8s.svg";
export { ReactComponent as GoogleAdsIcon } from "../../images/icons/google-ads.svg";
export { ReactComponent as MenuUnfoldIcon } from "../../images/icons/MenuUnfold.svg";
export { ReactComponent as MergeCellsIcon } from "../../images/icons/MergeCells.svg";
export { ReactComponent as OnboardingOvalUpIcon } from "../../images/user-onboarding/onboarding_oval_up.svg";
export { ReactComponent as OnboardingOvalDownIcon } from "../../images/user-onboarding/onboarding_oval_down.svg";
export { ReactComponent as OnboardingUpCurveIcon } from "../../images/user-onboarding/up-curve.svg";
export { ReactComponent as OnboardingDownCurveIcon } from "../../images/user-onboarding/down-curve.svg";
export { ReactComponent as OnboardingUpDownCurveIcon } from "../../images/user-onboarding/up-down-curve.svg";
export { ReactComponent as OnboardingAirplaneSquarePurpleIcon } from "../../images/user-onboarding/airplane-square-purple.svg";
export { ReactComponent as OnboardingGameIcon } from "../../images/user-onboarding/game.svg";
export { ReactComponent as OnboardingLogisticsIcon } from "../../images/user-onboarding/logistics.svg";
export { ReactComponent as OnboardingShopRemoveYellowIcon } from "../../images/user-onboarding/shop-remove-yellow.svg";
export { ReactComponent as OnboardingconfluenceIcon } from "../../images/confluence.svg";
export { ReactComponent as OnboardingAivenIcon } from "../../images/user-onboarding/aiven.svg";
export { ReactComponent as OnboardingKafkaIcon } from "../../images/user-onboarding/kafka.svg";
export { ReactComponent as OnboardingBigQueryIcon } from "../../images/user-onboarding/bigquery.svg";
export { ReactComponent as OnboardingSnowflakeIcon } from "../../images/user-onboarding/snowflake.svg";
export { ReactComponent as BicycleLogoIconLarge } from "../../images/logo-bicycle.svg";
export { ReactComponent as TreeMapIcon } from "../../images/icons/treemap.svg";
export { ReactComponent as DiscoverySplitBranchIcon } from "../../images/auto-discovery-icons/split-branch.svg";
export { ReactComponent as DiscoveryEditIcon } from "../../images/auto-discovery-icons/edit-icon.svg";
export { ReactComponent as DiscoveryPlusCircle } from "../../images/auto-discovery-icons/plus-circle.svg";
export { ReactComponent as DiscoveryDeleteOutlined } from "../../images/auto-discovery-icons/delete-outlined.svg";
export { ReactComponent as ExclamationCircle } from "../../images/auto-discovery-icons/exclamation-circle.svg";
export { ReactComponent as SearchIcon } from "../../images/auto-discovery-icons/search-icon.svg";
export { ReactComponent as ExpandZoomIcon } from "../../images/auto-discovery-icons/expand-alt.svg";
export { ReactComponent as DownCircleIcon } from "../../images/auto-discovery-icons/down-circle.svg";
export { ReactComponent as UpCircleIcon } from "../../images/auto-discovery-icons/up-circle.svg";
export { ReactComponent as EyeIcon } from "../../images/auto-discovery-icons/eye-icon.svg";
export { ReactComponent as EyeInvisibleIcon } from "../../images/auto-discovery-icons/eye-invisible.svg";
export { ReactComponent as JourneyLeftSingle } from "../../images/icons/journey-left-single.svg";
export { ReactComponent as OnboardingSegmentIcon } from "../../images/user-onboarding/segment.svg";
export { ReactComponent as OnboardingConfluentKafka } from "../../images/user-onboarding/confluent-kafka.svg";
export { ReactComponent as SendArrow } from "../../images/user-onboarding/send-arrow.svg";


type IconSize = "small" | "regular" | "large";
interface IconProps {
  size?: IconSize;
}

const getIconDimensions = (size: IconSize) => {
  if (size === "small") {
    return {
      width: 14,
      height: 14
    };
  } else if (size === "regular") {
    return {
      width: 16,
      height: 16
    };
  } else {
    return {
      width: 24,
      height: 24
    };
  }
};

export const CloseIcon = () => <img
  alt="close"
  src={CloseSVG}
  style={{
    width: 20,
    height: 20
  }} />;

export const SlackColorIcon: React.FC<IconProps> = (props) => {
  const { size = 'regular' } = props;
  const { width, height } = getIconDimensions(size);

  return <img
    alt="slack"
    src={SlackColorSVG}
    style={{
      width: width,
      height: height
    }} />;
};

export const GmailIcon: React.FC<IconProps> = (props) => {
  const { size = 'regular' } = props;
  const { width, height } = getIconDimensions(size);

  return <img
    alt="gmail"
    src={GmailSVG}
    style={{
      width: width,
      height: height
    }} />;
};
