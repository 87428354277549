import { useCallback, useMemo } from "react";
import { dashboardApi, DashboardListItem } from "../../dashboard/api";
import { isCohortDashboardItem } from "../../utils/CohortUtils";
import { InitialState, FetchFn, useDataFetch } from "./useDataFetch";

type Props = {
  includeCohortDashboards?: boolean;
};

export const useFetchDashboards = (props?: Props) => {
  const { includeCohortDashboards } = props || {};
  const initialState = useMemo<InitialState<DashboardListItem[], string>>(() => ({
    data: [],
    error: "",
    isError: false,
    isFetching: true,
    message: "",
    isSuccess: false
  }), []);

  const fetchDashboards = useCallback<FetchFn<DashboardListItem[], string>>(async () => {
    const {
      data,
      error,
      message
    } = await dashboardApi.getDashboardList();

    const shouldInclude = (db: DashboardListItem) => includeCohortDashboards || !isCohortDashboardItem(db);

    const errMsg = error ? message : '';
    const dbList: DashboardListItem[] = (data || []).filter(db => shouldInclude(db));
    return {
      data: dbList,
      isSuccess: !error,
      isError: error,
      isFetching: false,
      error: errMsg
    };
  }, [includeCohortDashboards]);

  return useDataFetch(fetchDashboards, initialState);
};
