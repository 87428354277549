import { round } from "lodash";

export type CurrencyType = "USD" | "INR";

const compactNumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  notation: "compact",
  compactDisplay: "short",
  roundingMode: 'trunc'
} as any);

const compactNumberFormatterWithoutFraction = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  notation: "compact",
  compactDisplay: "short",
} as any);

const getPercentFormatter = (decimalPoints = 0) => new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: decimalPoints,
  style: "percent",
  notation: "compact",
  signDisplay: "never"
} as any);

const displayNumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
} as any);

export const formatNumber = (num: number, fractionLess = false, skipRoundOffLimit = false): string => {
  const sign = Math.sign(num);
  const unsignedInt = sign === -1 ? (-1 * num) : num;

  if (unsignedInt === 0) {
    return String(unsignedInt);
  }

  if (!skipRoundOffLimit && unsignedInt < 1000000) {
    return String(round(num, 2));
  }

  return fractionLess ? compactNumberFormatterWithoutFraction.format(num) : compactNumberFormatter.format(num);
};

export const formatPercent = (num1: number, num2 = 100, decimalPoints?: number): string => getPercentFormatter(decimalPoints).format(num1 / (num2));

export const formatDisplayNumber = (num: number, compact = false): string => {
  const max = 99999;
  return (num > max || compact) ? compactNumberFormatter.format(num) : displayNumberFormatter.format(num);
};

export const formatDisplayCurrency = (num: number, currencyType: CurrencyType, compact = false): string => {
  const max = 99999;
  compact = compact || (num > max);
  const formatter = getCurrencyFormatter(currencyType, compact);
  return formatter.format(num);
};

export const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;

const currencyTypeVsLocale: Record<CurrencyType, string> = {
  INR: "en-IN",
  USD: "en-US"
};

export const getCurrencyFormatter = (currencyType: CurrencyType, compact = false, locale?: string) => {
  const numberFormatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  if (compact) {
    (numberFormatOptions as any).notation = "compact";
    (numberFormatOptions as any).compactDisplay = "short";
  }

  locale = locale || currencyTypeVsLocale[currencyType] || "en-US";
  return new Intl.NumberFormat(locale, numberFormatOptions);
};
