import React, { CSSProperties } from "react";
import { UseTableInstanceProps } from "react-table";
import { cx } from "emotion";
import { IncFaIcon } from "../../Icons";
import { TableDataItem } from "../types";

interface Props<T extends TableDataItem> {
  headerGroups: UseTableInstanceProps<T>['headerGroups'];
  resizeEnabled: boolean;
  selectionColumnId: string;
  expansionColumnId: string;
  nonDataColumnIds: string[];
  className: string;
  contentScrollable: boolean;
}

const ColumnHeaders = <T extends TableDataItem>(props: Props<T>) => {
  const {
    headerGroups,
    resizeEnabled,
    selectionColumnId,
    expansionColumnId,
    nonDataColumnIds,
    className,
    contentScrollable
  } = props;

  return <>
    {headerGroups.map(headerGroup => (
      // Disabling this since the getHeaderGroupProps handles it
      /* eslint-disable-next-line react/jsx-key */
      <div {...headerGroup.getHeaderGroupProps({ className })}>
        {headerGroup.headers.map(column => {
          const {
            id,
            width,
            maxWidth,
            minWidth,
            canSort,
            render,
            isSorted,
            isSortedDesc,
            getHeaderProps,
            getSortByToggleProps,
            getResizerProps,
            disableFilters
          } = column;

          let cellClassName = (column as any).className;
          cellClassName = cx("inc-flex-row inc-flex-center", {
            [cellClassName]: Boolean(cellClassName)
          });

          const isDataColumn = [...nonDataColumnIds, selectionColumnId, expansionColumnId].indexOf(id) === -1;

          const style: CSSProperties = {
            width,
            maxWidth,
            minWidth,
          };

          const wrapperStyle = {
            style: {
              flex: resizeEnabled ? `1 0 90%` : `1 0 100%`,
              overflow: "hidden"
            }
          };

          const sortToggleProps = isDataColumn && getSortByToggleProps ? getSortByToggleProps(wrapperStyle) : wrapperStyle;
          delete (sortToggleProps as any).title;

          const headerClassName = cx("inc-table--header-cell", {
            [(column as any).headerClassName]: Boolean((column as any).headerClassName)
          });

          const cellStyle = {
            ...((sortToggleProps as any).style || {}),
            flex: 1
          };

          // Disabling this since the getHeaderProps handles it
          /* eslint-disable-next-line react/jsx-key */
          return <div
            {...getHeaderProps({ style })}
            className={headerClassName}
            data-sort-enabled={canSort}
          >
            <div className={cellClassName} {...sortToggleProps} style={cellStyle}>
              <div className="data-text">
                {render('Header')}
              </div>
              {!disableFilters && Boolean(column.Filter) && <div className="inc-flex-row">
                {render('Filter')}
              </div>}
            </div>
            {canSort && <div className="inc-flex-column">
              <IncFaIcon className={`sorter ${isSorted && isSortedDesc ? "sorter--selected" : ""}`} iconName="caret-up" />
              <IncFaIcon className={`sorter sorter--desc ${isSorted && !isSortedDesc ? "sorter--selected" : ""}`} iconName="caret-down" />
            </div>}
            <div className="inc-table--header-cell-actions">
              {resizeEnabled && getResizerProps && <div {...getResizerProps({ className: "resizer" })} title="Resize column">||</div>}
            </div>
          </div>;
        })}
        {contentScrollable && <div className="inc-table--head--scroll-margin" />}
      </div>
    ))}
  </>;
};

export default ColumnHeaders;
