/**
 * For colors, as the suffix number increases, the property increases. For example, light5 is lighter than light2, and
 * similarily dark5 is darker that dark2.
 */

import { transparentize } from "polished";
import Colors from "./types/colors";

const baseColors = {
  gray: {
    dark1: '#8e8e8e',
    dark2: '#555555',
    dark3: '#464c54',
    dark4: '#3D444D',
    dark5: '#202226',
    dark6: '#141619',
    dark7: '#0b0c0e',
    light1: '#9fa7b3',
    light2: '#b3b3b3',
    light3: '#c7d0d9',
    light4: '#d8d9da',
    light5: '#e9edf2',
    light6: '#ececec',
    light7: '#f4f5f8',
    light8: '#f7f8fa',
    light9: '#fbfbfb'
  },
  black: {
    base: '#000000',
    dark1: '#222426',
    dark2: '#212124',
    dark3: '#1f1f20',
    dark4: '#161719',
    dark5: '#141414',
    light1: '#262628',
    light2: '#292a2d',
    light3: '#2f2f32',
    light4: '#343436',
    light5: '#424345'
  },
  blue: {
    base: '#33b5e5',
    grayish: '#212327',
    dark: '#3274d9',
    shade: '#1f60c4',
    light: '#5794f2',
    faint: '#041126',
    inception: '#2264D1'
  },
  red: {
    dark: '#e02f44',
    base: '#d44a3a',
    shade: '#c4162a',
    secondary: '#EB5757',
  },
  green: {
    base: '#299c46',
    shade: '#23843b',
    secondary: '#3AA76D'
  },
  orange: {
    base: '#eb7b18',
    dark: '#ff780a'
  },
  yellow: {
    base: '#ecbb13',
    secondary: '#FFBE3D',
  },
  purple: '#9933cc',
  variable: '#32d1df',
  white: '#ffffff',
  inputBlack: '#09090b'
};

const colors = {
  brand: {
    primary: baseColors.green.shade,
    success: baseColors.green.base,
    warning: baseColors.orange.base,
    danger: baseColors.red.base,
    secondary: baseColors.blue.dark
  },
  query: {
    red: baseColors.red.base,
    green: '#74e680',
    purple: '#fe85fc',
    keyword: '#66d9ef',
    orange: baseColors.orange.base
  },
  status: {
    online: baseColors.green.base,
    warn: '#f79520',
    critical: baseColors.red.base
  },
  attributes: {
    purple: '#855CF8',
    green: baseColors.green.secondary,
    yellow: baseColors.yellow.secondary,
    red: baseColors.red.secondary
  }
};

const darkColors: Colors = {
  ...baseColors,
  ...colors
};

export default darkColors;

export const darkChartColors: string[] = [
  "#CF5EDA",
  "#7C83FD",
  "#82C8DC",
  "#A186F0",
  "#78D198",
  "#E9CB7B",
  "#F695B3",
  "#008E89",
  "#C0DE8B",
  "#F68989",
  "#E7A516",
  "#953B91",
  "#DA7F8F",
  "#93B5C6",
  "#FFB4B4",
  "#C8C6A7",
  "#705DA0",
  "#508642",
  "#CCA300",
  "#447EBC",
  "#C15C17",
  "#890F02",
  "#0A437C",
  "#6D1F62",
  "#584477",
  "#B7DBAB",
  "#F4C896",
  "#70DBED",
  "#F9BA8F",
  "#F29191",
  "#82B5D8",
  "#E5A8E2",
  "#AEA2E0",
  "#629E51",
  "#E5AC0E",
  "#64B0C8",
  "#E0752D",
  "#BF1B00",
  "#0A50A1",
  "#962D82",
  "#614D93",
  "#9AC48A",
  "#F2C96D",
  "#65C5DB",
  "#F9934E",
  "#EA6460",
  "#5195CE",
  "#D683CE",
  "#806EB7",
  "#3F6833",
  "#967302",
  "#2F575E",
  "#99440A",
  "#58140C",
  "#052B51",
  "#511749",
  "#3F2B5B",
  "#E0F9D7",
  "#FCEACA",
  "#CFFAFF",
  "#F9E2D2",
  "#FCE2DE",
  "#BADFF4",
  "#F9D9F9",
  "#DEDAF7",
  "#AAAAAA",
].map(color => transparentize(0.25, color));
