import { Action } from "./features";

//place all actions under alerts here
export enum ALERTS_ACTIONS {
  viewTrainingDashboard = "viewTrainingDashboard",
  viewEvents = "viewEvents",
  addLinkedDashboard = "addLinkedDashboard",
  editOperationalizeMenu = "editOpersationalizeMenu",
  addEmbededDashboard = "addEmbededDashboard",
}

export const AlertFeatureActions: Record<keyof typeof Action, Array<keyof typeof ALERTS_ACTIONS>> = {

  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  "CREATE": [
    "addEmbededDashboard",
    "addLinkedDashboard",
  ],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  "UPDATE": [
    "editOperationalizeMenu"
  ],

  //All READ actions goes here
  //Enabled for All
  "READ": [
    "viewEvents",
    "viewTrainingDashboard"
  ],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  "DELETE": []

};
