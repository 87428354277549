import { useMemo, useCallback } from "react";
import { operationaliseV2ApiService, ActionCategory, OpCreationConfig } from "../../../services/api/operationalise";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../useDataFetch";

export const useFetchActionCategoryList = (opConfig?: OpCreationConfig, disableAutoFetch=false) => {
  const initialState: InitialState<ActionCategory[], string> = useMemo(() => ({
    data: [],
    isFetching: !disableAutoFetch,
    error: null
  }), [disableAutoFetch]);

  const fetchFn = useCallback<FetchFn<ActionCategory[], string>>(async () =>{
    const result: FetchCallBackResult<ActionCategory[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await operationaliseV2ApiService.getActionCategoryList(opConfig);

      result.data = data?.actionCategories || [];
      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : '';
    } catch (err) {
      result.isError = true;
      result.isSuccess = false;
      result.error = err.message;
    }

    return result;
  }, [opConfig]);

  return useDataFetch(fetchFn, initialState,disableAutoFetch);
};
