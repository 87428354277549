import { cloneDeep, groupBy, isEqual, omit, pick, values } from "lodash";
import { PropertySource } from "../services/api/entity-mapping";
import {
  BizField, BizFieldInfo, BizServiceMetric, EntityMeta, FieldPickerContextDTO, FieldPickerOptionData,
  FieldSchemaResponse, PickerFieldType, RelationshipName, UIUserService, UserServiceField, UserServiceFieldWithMeta,
  UserServiceMetric, UserServiceTuple, UserServiceWithFields
} from "../services/api/explore";
import { DataType, Entity, logger, CoreDataTypes } from "../core";
import { FieldPickerUtils } from "../utils/FieldPickerUtils";
import { ALLOWED_DATA_TYPES, ENTITY_DATA_TYPE } from "./constants";
import { FieldPickerRow } from "./PickerTypes";
import { compare, getUserFieldDatatype, isEntityField } from "./utils";

export class FieldPickerModel {
  private bizFields: BizFieldInfo[];
  private usFields: UserServiceFieldWithMeta[];
  private usEntityFields: UserServiceFieldWithMeta[];
  private spanFields: UserServiceFieldWithMeta[];
  private journeyFields: UserServiceFieldWithMeta[];
  private groupByUSFields: UserServiceFieldWithMeta[];
  private bizMetrics: Array<BizServiceMetric | UserServiceMetric>;
  private perfMetrics: Array<BizServiceMetric | UserServiceMetric>;
  private entityMap: Record<string, EntityMeta>;

  private readonly usfOmitProps: Array<keyof UserServiceField> = ['allUserService', 'userServices'];

  context: FieldPickerContextDTO;

  constructor(context?: FieldPickerContextDTO) {
    this.context = context;
    this.bizFields = [];
    this.usFields = [];
    this.usEntityFields = [];
    this.spanFields = [];
    this.perfMetrics = [];
    this.bizMetrics = [];
    this.entityMap = {};
    this.groupByUSFields = [];
  }

  init(data: FieldSchemaResponse) {
    if (data?.bizFields) {
      this.bizFields = data.bizFields.filter(field => ALLOWED_DATA_TYPES.includes(field.bizField?.entityField?.propType));
    }
    if (data?.userServiceFields) {
      this.usEntityFields = this.filterEntityUsFields(data?.userServiceFields);
      this.usFields = this.filterBizUsFields(data?.userServiceFields);
      this.spanFields = this.filterSpanUSFields(data?.userServiceFields);
      this.journeyFields = this.filterJourneyUSFields(data?.userServiceFields);
      this.groupByUSFields = this.filterGroupByUSFields(data.userServiceFields);
    }
    this.perfMetrics = this.getPerfMetrics(data?.bizMetrics, data?.userServiceMetrics);
    this.bizMetrics = this.getBizMetrics(data?.bizMetrics, data?.userServiceMetrics);

    if (data?.resultMetadata) {
      this.entityMap = data.resultMetadata.resultEntityMap;
    }
  }

  getUserServices(userServiceField: UserServiceField): Entity[] {
    let userServiceList: UserServiceTuple[];
    if (userServiceField.allUserService === true) {
      const { fieldName, dataType, entityField } = userServiceField;
      let allFields: UserServiceFieldWithMeta[] = [];
      allFields = allFields.concat(this.spanFields);
      allFields = allFields.concat(this.usEntityFields);
      allFields = allFields.concat(this.usFields);
      const searchObj = allFields.find(
        (obj) =>
          obj.userServiceField.fieldName === fieldName &&
          obj.userServiceField.dataType === dataType &&
          isEqual(obj.userServiceField?.entityField, entityField)
      );
      userServiceList = searchObj?.userServiceField.userServices;
    } else {
      userServiceList = userServiceField.userServices;
    }
    return this.getEntityUserServicesFromTuple(userServiceList);
  }

  // get user services from type UserServiceTuple into Entity[]
  getEntityUserServicesFromTuple(userServiceList: UserServiceTuple[]) {
    if (!userServiceList) {
      return [];
    }
    const userServices: Entity[] = [];
    userServiceList
      .map((us) => us.userServiceEntityId)
      .forEach((id) => {
        if (this.entityMap[id]) {
          userServices.push({
            id: this.entityMap[id]?.entityId,
            name: this.entityMap[id]?.name,
          });
        }
      });
    return userServices;
  }

  private filterEntityUsFields(userServiceFields: UserServiceFieldWithMeta[]) {
    const entityTypesVisited = new Set<string>();

    const usEntityFields: UserServiceFieldWithMeta[] = [];

    userServiceFields.forEach(usf => {
      const {
        userServiceField,
        userServiceMetadata
      } = usf;

      const {
        dataType,
        entityField
      } = userServiceField || {};

      const isBusinessField = userServiceMetadata?.business;
      const isEntityField = dataType === ENTITY_DATA_TYPE;
      const isValidPropType = entityField?.propType ? ALLOWED_DATA_TYPES.includes(usf.userServiceField?.entityField?.propType)
        : true;

      if (isBusinessField && isEntityField && isValidPropType) {
        const entityType = entityField?.entityType;
        if (!entityTypesVisited.has(entityType) && entityField) {
          entityTypesVisited.add(entityType);

          const cohortField = cloneDeep(usf);
          // cohortField.userServiceField.fieldName = "Cohort";

          const entityField = cohortField.userServiceField.entityField;
          entityField.relNames = [
            {
              relName: "cohort",
              entityType: "i_cohort",
              relId: "i_cohort2member"
            }
          ];
          entityField.propName = "";
          entityField.propType = "NA";
          entityField.kindDescriptor = {
            type: "not_set",
            customTypeName: ""
          };

          usEntityFields.push(cohortField);
        }

        usEntityFields.push(usf);
      }
    });
    usEntityFields.sort((x, y) => compare(x, y));
    return usEntityFields;
  }

  private filterBizUsFields(userServiceFields: UserServiceFieldWithMeta[]) {
    const usFields = userServiceFields.filter(usf => usf.userServiceMetadata?.business
      && usf.userServiceField?.dataType !== ENTITY_DATA_TYPE && ALLOWED_DATA_TYPES.includes(usf.userServiceField?.dataType));
    usFields.sort((x, y) => compare(x, y));
    return usFields;
  }

  private filterSpanUSFields(userServiceFields: UserServiceFieldWithMeta[]) {
    const usspanFields = userServiceFields.filter(usf => usf.userServiceMetadata?.diagnostic
      && ALLOWED_DATA_TYPES.includes(usf.userServiceField?.dataType)
      && (usf.userServiceField?.dataType === ENTITY_DATA_TYPE ?
        (usf.userServiceField?.entityField?.propType ?
          ALLOWED_DATA_TYPES.includes(usf.userServiceField?.entityField?.propType) : true) : true));
    usspanFields.sort((x, y) => compare(x, y));
    return usspanFields;
  }

  private filterJourneyUSFields(userServiceFields: UserServiceFieldWithMeta[]) {
    const usJourneyFields = userServiceFields.filter(usf => usf.userServiceMetadata?.journey
      && ALLOWED_DATA_TYPES.includes(usf.userServiceField?.dataType)
      && (usf.userServiceField?.dataType === ENTITY_DATA_TYPE ?
        (usf.userServiceField?.entityField?.propType ?
          ALLOWED_DATA_TYPES.includes(usf.userServiceField?.entityField?.propType) : true) : true));
    usJourneyFields.sort((x, y) => compare(x, y));
    return usJourneyFields;
  }

  private filterGroupByUSFields(userServiceFields: UserServiceFieldWithMeta[]) {
    const usFields: UserServiceFieldWithMeta[] = [];
    const fieldNames: Set<string> = new Set();

    userServiceFields.forEach(usf => {
      const { userServiceField } = usf;
      const name = userServiceField.fieldName;
      const supportedDataTypes: string[] = [CoreDataTypes.STRING, CoreDataTypes.ENTITY];
      const isEntityProp = userServiceField.dataType === CoreDataTypes.ENTITY && userServiceField.entityField.propName;
      const isBusinessField = usf.userServiceMetadata?.business;
      const isSupportedType = supportedDataTypes.includes(userServiceField.dataType);

      if (!fieldNames.has(name) && isBusinessField && isSupportedType && !isEntityProp) {
        usFields.push(usf);
        fieldNames.add(name);
      }
    });
    return usFields;
  }

  private getBizMetrics(bizMetrics: BizServiceMetric[], usMetrics: UserServiceMetric[]) {
    return [].concat(bizMetrics.filter(x => x.metricMetadata.category === "business"),
      usMetrics.filter(x => x.metricMetadata.category === "business"));
  }

  private getPerfMetrics(bizMetrics: BizServiceMetric[], usMetrics: UserServiceMetric[]) {
    return [].concat(bizMetrics.filter(x => x.metricMetadata.category === "softwarePerformance"),
      usMetrics.filter(x => x.metricMetadata.category === "softwarePerformance"));
  }

  getGroupByUserServiceFields() {
    return this.groupByUSFields;
  }

  // private groupUsMetricByName(userServiceMetrics: UserServiceMetric[]) {
  //   const usMetrics = groupBy(userServiceMetrics, "metricName");
  //   return usMetrics;
  // }


  getUserServiceList(userServiceField: UserServiceFieldWithMeta): string[] {
    const userServiceList: string[] = [];
    userServiceField?.userServiceField?.userServices?.map(us => us.userServiceEntityId).forEach(x => {
      if (this.entityMap[x]) {
        userServiceList.push(this.entityMap[x]?.name);
      }
    });
    return userServiceList;
  }

  // private getEntityMetaForIds(ids: string[]): Record<string, EntityMeta> {

  //   const idMap: Record<string, EntityMeta> = {};
  //   for (const [id, entityMeta] of Object.entries(this.entityMap)) {
  //     if (ids.includes(id)) {
  //       idMap[id] = entityMeta;
  //     }
  //   }
  //   return idMap;
  // }

  /**
   *
   * @param usField pass a widget usField to select a fully primed data model from fieldPicker
   */
  findUserServiceFieldMeta(usField: UserServiceField): UserServiceFieldWithMeta {
    const getKey = (usf: UserServiceField) => `${usf.fieldName}${usf.dataType}${usf.bizEntityFieldName}`;
    const key = getKey(usField);
    const usfMeta = [...this.usFields, ...this.usEntityFields].find(usfMeta => key === getKey(usfMeta.userServiceField));
    return usfMeta;
  }

  // getUserServiceFieldDto(userServiceField: UserServiceField): UserServiceFieldDTO {
  //   return {
  //     ...userServiceField,
  //     userServiceEntityMap: this.getEntityMetaForIds(userServiceField.userServiceEntityIds)
  //   };
  // }

  findMetricInfo(metricName: string): BizServiceMetric | UserServiceMetric {
    const metric = [...this.bizMetrics, ...this.perfMetrics].find(x => x.metricName === metricName);
    return metric;
  }

  findBizFieldInfo(bizField: BizField) {
    return this.bizFields.find(b => isEqual(bizField, b.bizField));
  }

  getMetricNames(): string[] {
    return [...this.bizMetrics, ...this.perfMetrics].map(m => m.metricName);
  }

  getEntityMap() {
    return this.entityMap;
  }

  getUsName(usId: string) {
    return this.entityMap[usId]?.name || usId;
  }

  getUsFieldSources(field: UserServiceField) {
    const usIdToUSTupleMap = groupBy(field.userServices, (us) => us.userServiceEntityId);
    return Object.keys(usIdToUSTupleMap).map(usId => {
      const rootApiIds = usIdToUSTupleMap[usId].map(us => us.rootApi).filter(Boolean);
      const source: UIUserService = {
        userService: {
          id: usId,
          name: this.entityMap[usId]?.name ?? usId
        },
        rootApis: rootApiIds.map(rootapiId => ({
          id: rootapiId,
          name: this.entityMap[rootapiId]?.name ?? rootapiId
        }))
      };
      return source;
    });
  }

  isAllUSSelected(field: UserServiceField) {
    return field.allUserService ?? false;
  }

  private getFieldsTableData(
    list: UserServiceFieldWithMeta[],
    selectedOptions: FieldPickerOptionData[],
    skipOptions?: FieldPickerOptionData[]
  ) {
    const data: Array<FieldPickerRow<"userServiceField">> = [];

    list.forEach(field => {
      const { userServiceField } = field;

      const skipOption = skipOptions?.find(op => {
        const payload = omit((op.payload as UserServiceField), this.usfOmitProps);
        const curField = omit(userServiceField, this.usfOmitProps);
        return isEqual(payload, curField);
      });

      if (!skipOption) {
        const customDataType = field.userServiceField.entityField?.kindDescriptor?.type || "not_set";
        const selectedOption = selectedOptions.find(op => {
          const payload = omit((op.payload as UserServiceField), this.usfOmitProps);
          const curField = omit(userServiceField, this.usfOmitProps);
          return isEqual(payload, curField);
        });

        const row: FieldPickerRow<"userServiceField"> = {
          field: userServiceField,
          fieldType: "userServiceField",
          fieldLabel: FieldPickerUtils.getUserServiceFieldLabel(userServiceField),
          datatype: getUserFieldDatatype(userServiceField),
          customDataType: customDataType,
          sources: this.getUsFieldSources(userServiceField),
          rowSelected: Boolean(selectedOption),
          selectedSources: selectedOption ? this.getUsFieldSources((selectedOption.payload as UserServiceField)) : [],
          readOnly: selectedOption?.readOnly || false
        };

        data.push(row);
      }
    });

    return data;
  }

  getFieldPickerOptionData(
    searchScope: PickerFieldType,
    fieldName: string,
    fieldType: DataType,
    entityType?: string,
    propName?: string,
    relNames?: RelationshipName[]
  ): FieldPickerOptionData {

    if (searchScope === "userServiceField") {
      const field = [...this.usFields, ...this.usEntityFields, ...this.spanFields].find((f: UserServiceFieldWithMeta) => {
        const usf = f.userServiceField;
        return usf.dataType === fieldType && usf.fieldName === fieldName
          && (fieldType === "ENTITY" && usf.entityField?.entityType === entityType &&
            usf.entityField?.propName === propName && isEqual(usf.entityField?.relNames, relNames));
      });
      return {
        payload: field?.userServiceField,
        type: "userServiceField"
      };
    }

    if (searchScope === "bizEntityField") {
      const bizField = this.bizFields.find(f => {
        const bf = f.bizField;
        const { propName, propType, entityType: et } = bf.entityField;
        return propName === fieldName && propType === fieldType && et === entityType;
      });

      return {
        payload: bizField,
        type: "bizEntityField"
      };
    }
    return null;
  }

  getUserServiceEntityFieldsTableData(
    selectedOptions: FieldPickerOptionData[],
    skipOptions?: FieldPickerOptionData[],
    customUserServiceFields?: UserServiceFieldWithMeta[]
  ) {
    const nonExistingFields = (customUserServiceFields || []).filter(usField => {
      const matchingUSField = this.usEntityFields.find(eUsField => eUsField.userServiceField.fieldName === usField.userServiceField.fieldName);
      return !matchingUSField;
    });
    const fields = [...this.usEntityFields, ...nonExistingFields];
    return this.getFieldsTableData(fields, selectedOptions, skipOptions);
  }

  getUserServiceFieldsTableData(selectedOptions: FieldPickerOptionData[], skipOptions?: FieldPickerOptionData[]) {
    return this.getFieldsTableData(this.usFields, selectedOptions, skipOptions);
  }

  getUserServiceSpansTableData(selectedOptions: FieldPickerOptionData[], skipOptions?: FieldPickerOptionData[]) {
    return this.getFieldsTableData(this.spanFields, selectedOptions, skipOptions);
  }

  getBizEntityMetricTableData(selectedOptions: FieldPickerOptionData[]) {
    return this.bizMetrics.map(m => {
      const row: FieldPickerRow<"bizEntityMetric" | "userServiceMetric"> = {
        field: m,
        fieldLabel: m.metricName,
        fieldType: (m as UserServiceMetric).entityId ? "userServiceMetric" : "bizEntityMetric",
        rowSelected: Boolean(selectedOptions.find(op => isEqual(op.payload, m)))
      };

      return row;
    });
  }

  getPerfMetricTableData(selectedOptions: FieldPickerOptionData[]) {
    return this.perfMetrics.map(m => {
      const row: FieldPickerRow<"bizEntityMetric" | "userServiceMetric"> = {
        field: m,
        fieldLabel: m.metricName,
        fieldType: (m as UserServiceMetric).entityId ? "userServiceMetric" : "bizEntityMetric",
        rowSelected: Boolean(selectedOptions.find(op => isEqual(op.payload, m)))
      };

      return row;
    });
  }

  private getBizFieldSourcesProperty(bizfield: BizFieldInfo): PropertySource[] {
    const sources = bizfield.metadata?.bizFieldSources;
    if (sources?.length > 0) {
      return sources.map(s => ({
        type: s.sourceType,
        name: s.sourceName,
        sourceId: null
      }));
    }
    return [];
  }

  getBizEntityFieldsTableData(selectedOptions: FieldPickerOptionData[]) {
    return this.bizFields.map(f => {
      const propstype = f.bizField?.entityField?.propType;
      const customTypeName = f.bizField?.entityField?.kindDescriptor?.type || "not_set";
      const dataType = (!propstype) ? "NA" : propstype === "NA" ? "ENTITY" : propstype;
      const row: FieldPickerRow<"bizEntityField"> = {
        field: f,
        fieldLabel: FieldPickerUtils.getBizFieldLabel(f),
        fieldType: "bizEntityField",
        datatype: dataType,
        customDataType: customTypeName,
        propertySources: this.getBizFieldSourcesProperty(f),
        rowSelected: Boolean(selectedOptions.find(op => isEqual(op.payload, f)))
      };

      return row;
    });
  }

  getMatchingField(fieldName: string, bizEntityFieldName: string, dataType: UserServiceField['dataType'], entityField: UserServiceField['entityField']) {
    const matchingField = [...this.usFields, ...this.usEntityFields].find(f => {
      const payload = pick(f.userServiceField, ['fieldName', 'dataType', 'entityField', 'bizEntityFieldName']);
      // Need to add undefined since _.pick omits undefined values while picking
      payload.entityField = payload.entityField || undefined;
      payload.bizEntityFieldName = payload.bizEntityFieldName || undefined;
      payload.fieldName = payload.fieldName || undefined;
      payload.dataType = payload.dataType || undefined;

      const curField = {
        fieldName,
        bizEntityFieldName,
        dataType,
        entityField
      };
      return isEqual(payload, curField);
    });
    return matchingField;
  }

  getEntityUSField(bizEntityFieldName?: string): UserServiceField {
    const { entityType } = this.context || {};
    let expectedEntityType = '';

    if (entityType) {
      // Noun-first case
      expectedEntityType = entityType;
    } else {
      // Verb-first case
      expectedEntityType = "i_userService";
    }

    const allUSFields = [...this.usFields, ...this.usEntityFields, ...this.spanFields];

    const entityUsField = allUSFields.find(usField => {
      const { userServiceField } = usField;
      const { dataType, entityField, fieldName } = userServiceField;
      const { relNames, propType, entityType } = entityField || {};

      const derivedBizEntityFieldName = (bizEntityFieldName || '')
        .split("$")
        .slice(0, -1)
        .join("$");
      const fieldNameMatches = bizEntityFieldName ? derivedBizEntityFieldName === fieldName : true;

      return fieldNameMatches
        && isEntityField(dataType)
        && propType === "NA"
        && relNames.length === 0
        && entityType === expectedEntityType;
    })?.userServiceField;

    if (!entityUsField) {
      logger.error("FieldPickerModel:getEntityUSField", "Could not find entity US field", entityType);
    }
    return entityUsField;
  }

  getEventIDField(): UserServiceField {
    const allUSFields = [...this.usFields, ...this.usEntityFields, ...this.spanFields];

    const eventIDUSField = allUSFields.find(usField => usField.userServiceField.fieldName === 'eventID')?.userServiceField;

    if (!eventIDUSField) {
      logger.error("FieldPickerModel:getEntityUSField", "Could not find eventID field");
    }
    return eventIDUSField;
  }

  private getFieldsByUserServiceId(list: UserServiceFieldWithMeta[], userServiceId: string) {
    const fields: UserServiceFieldWithMeta[] = [];
    list.forEach(x => {
      if (x.userServiceField.userServices.find(x => x.userServiceEntityId === userServiceId)) {
        fields.push(x);
      }
    });
    return fields;
  }

  groupFieldsByUserService() {
    const userServices = values(this.entityMap);
    let userServiceAndFieldsData: UserServiceWithFields[] = userServices.map((x: EntityMeta) => {
      const usPerfFields = this.getFieldsByUserServiceId(this.spanFields, x.entityId);
      const usFields = this.getFieldsByUserServiceId(this.usFields, x.entityId);
      const usEntityFields = this.getFieldsByUserServiceId(this.usEntityFields, x.entityId);
      const usJourneyFields = this.getFieldsByUserServiceId(this.journeyFields, x.entityId);
      return {
        usId: x.entityId,
        userServiceInfo: x,
        perfFields: usPerfFields,
        usFields,
        usEntityFields,
        usJourneyFields
      };
    }, []);

    userServiceAndFieldsData = userServiceAndFieldsData.filter(x => x.perfFields.length > 0 || x.usFields.length > 0 || x.usEntityFields.length > 0);
    return userServiceAndFieldsData;
  }

  getAllUserServiceFields(): UserServiceFieldWithMeta[] {
    return [...this.usFields, ...this.usEntityFields, ...this.spanFields, ...this.journeyFields];
  }

  getAllUserServiceFieldsTableData(): Array<FieldPickerRow<"userServiceField">> {
    const allFields = this.getAllUserServiceFields();
    return this.getFieldsTableData(allFields, []);
  }

  getUserServiceFieldsByType() {
    return {
      perfFields: this.spanFields,
      businessFields: this.usFields,
      entityFields: this.usEntityFields,
      journeyFields: this.journeyFields
    };
  }

  getAllUserservices() {
    return values(this.entityMap);
  }
}
