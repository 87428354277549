import { IncFaIconName, ISaxIconProps } from "@inception/ui";
import { ImpactedWidgetList, UserServiceFieldSlice, UserServiceFieldSliceSet } from "../../explore";
import { DrilldownTypes } from "../../triage-v2";
import { BizActionConfig } from "./ActionTypesV2";
import { CauseMetaData, OpSchedule, OpType } from "./CommonTypesV2";
import { StorySummarizationDef } from "./op-story-summary";
import { SuppressionConfigDef } from "./suppressions";
import { OpThreshold } from "./thresholds";
import { OpStartTrigger } from "./triggers";
import { UberOperationalizeTypes } from "./uber-operationalize";

export interface OpCreationFragment {
  opType: OpType;
  isOpportunity: boolean;
  importance: string;

  sections: OpCreationFragmentSection[];
  summary: OpCreationFragmentSummary;
}

export enum OpCreationFragmentSectionType {
  UNSET = "UNSET",
  INSIGHT_GENERATION = "INSIGHT_GENERATION",
  INSIGHT_EXPLANATION = "INSIGHT_EXPLANATION",
  INSIGHT_ACTION = "INSIGHT_ACTION"
}

export interface OpCreationFragmentSection {
  icon: IncFaIconName | ISaxIconProps['iconName'];
  name: string;
  description: string;
  
  sectionType: OpCreationFragmentSectionType;
  sectionElements: OpCreationFragmentSectionElement[];
}

export enum OpCreationFragmentSectionElementType {
  UNSET = 'UNSET',
  IMPACTED_WIDGETS = 'IMPACTED_WIDGETS',
  DIAGNOSTIC_FIELDS = 'DIAGNOSTIC_FIELDS',
  CAUSE_META_DATA = 'CAUSE_META_DATA',
  SUMMARY_TEMPLATE = 'SUMMARY_TEMPLATE',
  OP_SCHEDULE = 'OP_SCHEDULE',
  OP_BIZ_DATA_QUERY = 'OP_BIZ_DATA_QUERY',
  OP_THRESHOLD = 'OP_THRESHOLD',
  OP_START_TRIGGER = 'OP_START_TRIGGER',
  SUPPRESSION = 'SUPPRESSION',
  DIMENSIONS = 'DIMENSIONS',
  SLICE_SETS = 'SLICE_SETS',
  RELATED_KPI = 'RELATED_KPI',
  BIZ_ACTION = "BIZ_ACTION",
  DRILLDOWN_STEP = "DRILLDOWN_STEP"
}

export interface OpCreationFragmentSectionElement {
  info: string;
  input: string;

  elementType: OpCreationFragmentSectionElementType;
  elementHeaderText: string;
  headerTags: string[];

  iconName: IncFaIconName | ISaxIconProps['iconName'];
  iconSvg: string;
  iconColor: string;

  displayText: string;
  displayTextList: DisplayTextListItem[];
  isMarkdown: boolean;

  //oneOf
  impactedWidgets?: ImpactedWidgetList;
  diagnosticFields?: UserServiceFieldSliceSet;
  causeMetaData?: CauseMetaData;
  summaryTemplate?: StorySummarizationDef;
  opSchedule?: OpSchedule;
  threshold?: OpThreshold;
  startTriggers?: OpStartTrigger;
  suppression?: Suppressions;
  dimensions?: Dimensions;
  sliceSets?: SliceSets;
  relatedKPIs?: RelatedKPIs;
  bizAction?: BizActionConfig;
  drillDownStep?: DrilldownTypes.DrillDownStep;
}

export interface DisplayTextListItem {
  displayText: string;
  isMarkdown: boolean;
  tags: string[];
}

export type Suppressions = {
  suppression: SuppressionConfigDef[];
}

export type Dimensions = {
  dimensions: UserServiceFieldSlice[];
}

export type RelatedKPIs = {
  kpi: UberOperationalizeTypes.OpBizDataQuery[];
}

export type SliceSets = {
  sliceSets: UserServiceFieldSliceSet[];
}

export interface OpCreationFragmentSummary {
  opType: OpType;
  isOpportunity: boolean;
  importance: string;

  sections: OpCreationFragmentSummarySection[];
}

export interface OpCreationFragmentSummarySection {
  name: string;
  summary: string;

  sectionType: OpCreationFragmentSectionType;
}