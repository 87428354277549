import { REQUEST_COUNT_MERCHANT_LOGIN } from "./request-count-login";
import { REQUEST_COUNT_MERCHANT_PAYMENT } from "./request-count-payment";
import { REQUEST_COUNT_MERCHANT_CART } from "./request-count-cart";
import { ERROR_COUNT_MERCHANT, ERROR_COUNT_MERCHANT_US } from "./error-count";
import { REQUEST_COUNT_MERCHANT_US, REQUEST_COUNT_MERCHANT } from "./request-count";

export function getEntityTableData(metricType: string) {
  switch (metricType) {
    case "i_userService:00000000-0000-0000-0000-000382ed1058:0": return REQUEST_COUNT_MERCHANT_LOGIN;
    case "i_userService:00000000-0000-0000-0000-00036b6c40b8:0": return REQUEST_COUNT_MERCHANT_PAYMENT;
    case "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0": return REQUEST_COUNT_MERCHANT_CART;
    case "errors": return ERROR_COUNT_MERCHANT;
    case "errors_per_us": return ERROR_COUNT_MERCHANT_US;
    case "requests": return REQUEST_COUNT_MERCHANT;
    case "requests_per_us": return REQUEST_COUNT_MERCHANT_US;
    default: return [];
  }
}

