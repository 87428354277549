import { cx } from 'emotion';
import React, { useState, useRef } from 'react';
import { getInceptionTheme } from '../../../themes/ThemeProvider';
import { formatNumber } from '../../../utils';
import IncPopper from '../../Popper/Popper';

export type SingleHorizontalBarData = {
  /**
       * Optional id for the data.
       */
  id?: string | number;

  /**
       * label for the data. For e.g. it can be the name of the entity.
       */
  label: string;

  value: number;
};

type SingleHorizontalBarProps = {
  primaryData: SingleHorizontalBarData;

  /**
   * Secondary data is related to the primary data and should be a subset of that data.
   * For e.g. if primary data is request count, secondary data is number of errors.
   * Number of errors is an subset of request count.
   */
  secondaryData: SingleHorizontalBarData;

  /**
   * Label for the primary metric data. For e.g. Requests, Errors, etc.
   * Note: The data in SingleHorizontalBarData corresponds to the label for the bar
   */
  valueLabelPrimary: string;

  /**
   * Label for secondary data
   */
  valueLabelSecondary?: string;

  /**
   * If you want the bar to show from right to left instead of the default left to right.
   */
  isReversedDirection?: boolean;

  /**
     * This calculates how much width the bar will take.
     * e.g. If max === primaryData.value, this will take 100% width. Depending on
     * on how much width a bar should take with respect to other bars, this can be set.
     */
  max?: number;

  /**
   * By default the label for bar is aligned at the bottom of the bar. Set these
   * to change the alignment.
   */
  primaryLabelAlign?: 'top' | 'bottom';

  /**
   * If both primary and secondary values are provided, setting this to true will show percentage
   * of secondary compared to primary.
   */
  showPercentage?: boolean;
};

/**
 * This is the chart which shows 2 sets of data which are related to each other.
 * For e.g. Total count of requests and number of errors in those requests
 *
 * @param props
 * @returns
 */
const IncSingleHorizontalBar: React.FC<SingleHorizontalBarProps> = ((props) => {
  const {
    primaryData,
    valueLabelPrimary,
    secondaryData,
    valueLabelSecondary,
    max = 1,
    isReversedDirection,
    primaryLabelAlign,
    showPercentage
  } = props;

  const {
    label: primaryLabel,
    value: primaryValue
  } = primaryData;

  const {
    value: secondaryValue = 0
  } = secondaryData || {};

  const width1 = (primaryValue / max) * 100 + '%';
  const width2 = (secondaryValue / primaryValue) * 100 + '%';

  //const barClassname = cx('bar-outer', isReversedDirection ? 'reverse-bar' : '');
  const labelClassName = cx('label inc-text-subtext-medium', isReversedDirection ? 'reverse-bar-label' : '');

  const formattedPrimaryValue = formatNumber(primaryValue);
  const formattedSecondaryValue = formatNumber(secondaryValue);

  const barLabel = `${primaryLabel}`;
  let barValue = ``;
  if (showPercentage && secondaryValue) {

    barValue = ((secondaryValue / primaryValue) * 100).toFixed(1) + `%  (${formattedSecondaryValue} / ${formattedPrimaryValue})`;
  } else {
    barValue = `(${valueLabelPrimary}: ${formattedPrimaryValue}`;
    if (secondaryValue && valueLabelSecondary) {
      barValue += `, ${valueLabelSecondary}: ${formattedSecondaryValue}`;
    }
    barValue += ')';
  }

  return (
    <div className='inc-single-horizontal-bar-wrapper inc-single-horizontal-bar'>

      {
        // if we want to show label on left
        primaryLabelAlign && primaryLabelAlign === 'top' ?
          <div className={cx(labelClassName, `label-position-top`)} style={{display: 'flex',
            justifyContent: 'space-between'}}>
            <div className={'label'}>{barLabel}</div>
            <div className='inc-text-body-medium value'>{barValue}</div>
          </div>
          : <></>
      }

      <div style={{ width: width1 }}>
        <HorizontalBar barWidth={width2} size="m"></HorizontalBar>
      </div>

      {/* The bar value is always below the bar but label depends on the alignment */}

      {
        !primaryLabelAlign || primaryLabelAlign === 'bottom' ?
          <div className={cx(labelClassName, 'label-position-bottom')}>
            <div className={cx(labelClassName)}>{barLabel}</div>
            <div>{barValue}</div>
          </div> : null
      }

    </div>

  );
});



interface HorizontalBarProps {
  barWidth: string;

  backgroundColor?: string;
  barColor?: string;
  isReverse?: boolean;
  size?: "xxs" | "xs" | "m";
  target?: string;
  bordered?: boolean;
  hoverElement?: JSX.Element;
}

export const HorizontalBar = (props: HorizontalBarProps) => {

  const { N100, R400 } = getInceptionTheme().inceptionColors.palette;

  const { size = "m", backgroundColor = N100, barColor = R400, isReverse = false, barWidth, target, bordered, hoverElement } = props;

  const clsNames = cx(`bar-display-${size}`);
  const [showHoverEl, setShowHoverEl] = useState<boolean>(false);
  const popUpRef = useRef<HTMLDivElement>(null);

  return <>
    <div
      className={`inc-single-horizontal-bar ${bordered ? 'bar-bordered' : ''}`}
      onMouseEnter={() => setShowHoverEl(true)}
      onMouseLeave={() => setShowHoverEl(false)}
      ref={popUpRef}
    >
      <div className={cx(clsNames, "bar-outer inc-flex-column inc-flex-grow", isReverse ? "reverse-bar" : "")}>
        <div className={`bar inc-flex-row`} style={{ backgroundColor: backgroundColor }}>
          <div className={cx("bar-outer inc-flex-grow", isReverse ? "reverse-bar" : "")}>
            <div className={'bar'} style={{ backgroundColor: barColor,
              width: barWidth }} />
            {target ? <div className={`target-line target-line-${size}`} style={{left: target}}></div> : <></>}
          </div>
        </div>
      </div>
    </div>
    {hoverElement && <IncPopper
      anchorEl={popUpRef as any}
      placement='top'
      show={showHoverEl}
    >
      {hoverElement}
    </IncPopper>}
  </>;
};


export default IncSingleHorizontalBar;
