import { cx } from 'emotion';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { getIconSpriteId, IncFaIconName } from './IncFaIconsList';
import { IncFaIconProps } from './types';

const IncFaIcon: React.FC<IncFaIconProps> = ((props) => {
  const {
    iconName,
    className: pClassName = "",
    variant = "solid",
    stackIconName,
    color = 'currentColor',
    spin = false,
    spinPulse = false,
    spinReverse = false,
    pulse = false,
    beat = false,
    fade = false,
    beatFade = false,
    bounce = false,
    shake = false,
    flip = null,
    size = null,
    pull = null,
    rotation = null,
    regular = false,
    ...restProps
  } = props;

  const computedVariant = regular ? "regular" : variant;

  const className = useMemo(() => cx("fa svg-inline--fa", {
    [pClassName]: Boolean(pClassName),
    "fa-spin": spin,
    "fa-spin-pulse": spinPulse,
    "fa-spin-reverse": spinReverse,
    "fa-pulse": pulse,
    "fa-beat": beat,
    "fa-fade": fade,
    "fa-beat-fade": beatFade,
    "fa-bounce": bounce,
    "fa-shake": shake,
    [`fa-flip-${flip}`]: Boolean(flip),
    [`fa-${size}`]: typeof size === 'string' && Boolean(size),
    [`fa-pull-${pull}`]: Boolean(pull),
    [`fa-rotate-${rotation}`]: Boolean(rotation)
  }), [beat, beatFade, bounce, fade, flip, pClassName, pull, pulse, rotation, shake, size, spin, spinPulse, spinReverse]);

  const getSvgUrlAndViewBox = useCallback((iconName: IncFaIconName) => {
    iconName = (iconName.startsWith("fa-") ? iconName.replace("fa-", "") : iconName) as IncFaIconName;
    const {
      iconSpriteId,
      iconVariants,
      viewBox
    } = getIconSpriteId(iconName);
    const finalVariant = iconVariants.includes(computedVariant) ? computedVariant : iconVariants[0];

    return {
      spriteUrl: `/fa-icon-sprite/${finalVariant}.svg#${iconSpriteId}`,
      viewBox
    };
  }, [computedVariant]);

  const baseIcon = useMemo(() => {
    const {spriteUrl, viewBox } = getSvgUrlAndViewBox(iconName);
    return <svg
      {...restProps}
      className={className}
      fill={color}
      stroke={color}
      viewBox={viewBox}
    >
      <use xlinkHref={spriteUrl} />
    </svg>;
  }, [className, color, getSvgUrlAndViewBox, iconName, restProps]);

  const stackIcon = useMemo(() => {
    if (!stackIconName) {
      return <></>;
    }

    const { spriteUrl, viewBox } = getSvgUrlAndViewBox(stackIconName);
    const style: CSSProperties = {
      ...(restProps.style || {}),
      position: "absolute",
      height: "100%",
      width: "100%",
      left: 0,
      top: 0
    };
    return <svg
      {...restProps}
      className={className}
      fill={color}
      stroke={color}
      style={style}
      viewBox={viewBox}
    >
      <use xlinkHref={spriteUrl} />
    </svg>;
  }, [className, color, getSvgUrlAndViewBox, restProps, stackIconName]);

  if (!stackIconName) {
    return baseIcon;
  }

  return <div className="fa-layers display-inline-block position-relative">
    {baseIcon}
    {stackIcon}
  </div>;
});

export default IncFaIcon;

