import { createAction } from "@reduxjs/toolkit";
import appConfig from "../../../../appConfig";
import { TenantConfig, TenantConfigState, TenantDrilldownVersion } from "./types";

const defaultTenantConfig: TenantConfig = {
  currency: 'USD',
  demoTenant: false,
  pocTenant: false,
  enableBizFlow: false,
  defaultDrilldownVersion: TenantDrilldownVersion.v5,
  useOnboardingHome: false,
  useBizProcessHome: false,
  showDemoInsights: false,
  suggestionsLimit: appConfig.defaultSuggestionsLimit,
  showSubVerticalSwitcher: false
};

const defaultTenantConfigState: TenantConfigState = {
  tenantConfig: defaultTenantConfig,
  isFetching: true
};

const setTenantConfig = createAction<TenantConfigState>("set-tenant-config");

const updateAppConfig = (state: TenantConfig) => {
  appConfig.tenantConfig = state;
};

const tenantConfigReducer = (state = defaultTenantConfigState, { type, payload }: any) => {
  let nState = state;
  switch (type) {
    case 'set-tenant-config':
      nState = {
        ...state,
        ...payload
      };
      break;
    default:
      break;
  }
  updateAppConfig(nState?.tenantConfig);
  return nState;
};
export { setTenantConfig, tenantConfigReducer };
