import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApplicationState } from "../../../../store/configureStore";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { routeManager } from "../../InceptionRouteManager";
import { TimeZone } from "../../moment_wrapper";
import { useTenantConfig } from "../tenant-config";
import { refreshAction, setTimeRangeAction, setCompareTimeRangeAction, setCompareEnabledAction, setTimeRangeEnabledAction, setTimeSelectorsEnabledAction, setTimeRangeSelectorDisabledAction, setRefreshDisabledAction, setIsCustomTimeRangeInitialized} from "./reducer";
import { RawTimeRange, TimeRangeState } from "./types";
import timeSrv from "./TimeSrv";

const useTimeRange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeRangeState: Readonly<TimeRangeState> = useSelector((storeState: ApplicationState) => storeState.timeRange);

  const updateUrlInternal = useCallback((from: string, to: string, cFrom: string, timeZone?: TimeZone) => {
    const path = history.location.pathname;
    const routeRequiresTimeRange = routeManager.isTimeRangeRequired(path);
    const routeRequiresCompareTimeRange = routeManager.isCompareTimeRangeRequired(path);

    const url = window.location.pathname + window.location.search;

    from = routeRequiresTimeRange ? from : '';
    to = routeRequiresTimeRange ? to : '';
    cFrom = routeRequiresCompareTimeRange ? cFrom : '';

    const newUrl = timeRangeUtils.getUrl(from, to, url, cFrom, timeZone);
    history.replace(newUrl);
  }, [history]);

  const setTimeRangeInternal = useCallback((newTimeRange: RawTimeRange, updateUrl = true, isCompare = false) => {
    // Doing this to prevent lag since this may trigger data fetch operations
    window.setTimeout(() => {
      const action = isCompare ? setCompareTimeRangeAction : setTimeRangeAction;
      dispatch(action({ ...newTimeRange }));

      const rawFrom = newTimeRange.from;
      const rawTo = newTimeRange.to;

      const oCTRRaw = timeSrv.getCompareTimeRange().raw;
      const oTRRaw = timeSrv.getTimeRange().raw;

      if (updateUrl && rawFrom) {
        const from = isCompare ? oTRRaw.from : rawFrom;
        const to =isCompare ? oTRRaw.to : rawTo;
        const cFrom = isCompare ? rawFrom : oCTRRaw.from;
        const timeZone = newTimeRange.timeZone;

        updateUrlInternal(from, to, cFrom, timeZone);
      }
    }, 0);
  }, [dispatch, updateUrlInternal]);

  const setShowCompareSelector = useCallback((enabled: boolean) => {
    dispatch(setCompareEnabledAction(enabled));
  }, [dispatch]);

  const setShowTimeRangeSelector = useCallback((enabled: boolean) => {
    dispatch(setTimeRangeEnabledAction(enabled));
  }, [dispatch]);

  const setDisableTimeRangeSelector = useCallback((disabled: boolean) => {
    dispatch(setTimeRangeSelectorDisabledAction(disabled));
  }, [dispatch]);

  const setDisableRefresh = useCallback((disabled: boolean) => {
    dispatch(setRefreshDisabledAction(disabled));
  }, [dispatch]);

  const setTimeSelectorsEnabled = useCallback((enabledArr: [boolean, boolean]) => {
    dispatch(setTimeSelectorsEnabledAction(enabledArr));
  }, [dispatch]);

  const setTimeRange = useCallback((newTimeRange: RawTimeRange, updateUrl = true) => {
    setTimeRangeInternal(newTimeRange, updateUrl, false);
  }, [setTimeRangeInternal]);

  const setCompareTimeRange = useCallback((newTimeRange: RawTimeRange, updateUrl = true) => {
    setTimeRangeInternal(newTimeRange, updateUrl, true);
  }, [setTimeRangeInternal]);

  const getLatestTimeRange = useCallback(() =>
    timeRangeUtils.getTimeRangeFromRaw(timeRangeState.timeRange.raw)
  , [timeRangeState]);

  const getLatestCompareTimeRange = useCallback(() =>
    timeRangeUtils.getTimeRangeFromRaw(timeRangeState.compareTimeRange.raw)
  , [timeRangeState]);

  const refresh = useCallback(() => {
    dispatch(refreshAction());
  }, [dispatch]);

  const updateTimeRangeParams = useCallback((from: string, to: string, timeZone?: TimeZone) => {
    const { from: cFrom } = timeSrv.getCompareTimeRange().raw;
    updateUrlInternal(from, to, cFrom, timeZone);
  }, [updateUrlInternal]);

  const updateCompareTimeRangeParams = useCallback((cFrom: string) => {
    const { from, to, timeZone } = timeSrv.getTimeRange().raw;
    updateUrlInternal(from, to, cFrom, timeZone);
  }, [updateUrlInternal]);

  const setIsCustomTRangeInitialized = useCallback((isInitialized: boolean) => {
    dispatch(setIsCustomTimeRangeInitialized(isInitialized));
  }, [dispatch]);

  const { tenantConfigState, isTenantConfigFetched } = useTenantConfig();

  useEffect(() => {
    if (isTenantConfigFetched && tenantConfigState?.customTimeRanges?.length && !timeRangeState?.isCustomTimeRangeInitialized) {
      let idx = -1;

      for (let index = 0; index < tenantConfigState?.customTimeRanges?.length; index++) {
        const item = tenantConfigState?.customTimeRanges?.[index];
        if (item?.isDefault) {
          idx = index;
          break;
        }
      }

      if (idx >= 0) {
        const timeRange: RawTimeRange = {
          from: tenantConfigState?.customTimeRanges?.[idx]?.from,
          to: tenantConfigState?.customTimeRanges?.[idx]?.to
        };
        if (timeRange?.from?.length && timeRange?.to?.length) {
          timeRangeUtils.setDefaultTimeRange(timeRange.from, timeRange.to);
          setIsCustomTRangeInitialized(true);
          setTimeRange(timeRange);
        }
      }
    }
  }, [isTenantConfigFetched, setIsCustomTRangeInitialized, setTimeRange, tenantConfigState, timeRangeState]);

  return {
    timeRange: timeRangeState?.timeRange,
    getLatestTimeRange,
    setTimeRange,
    compareTimeRange: timeRangeState?.compareTimeRange,
    getLatestCompareTimeRange,
    setCompareTimeRange,
    refresh,
    showCompareSelector: timeRangeState?.showCompareSelector,
    setShowCompareSelector,
    showTimeRangeSelector: timeRangeState?.showTimeRangeSelector,
    setShowTimeRangeSelector,
    setTimeSelectorsEnabled,
    disableTimeRangeSelector: timeRangeState?.disableTimeRangeSelector,
    setDisableTimeRangeSelector,
    disableRefresh: timeRangeState?.disableRefresh,
    setDisableRefresh,
    updateTimeRangeParams,
    updateCompareTimeRangeParams,
    setIsCustomTRangeInitialized,
    isCustomTimeRangeInitialized:timeRangeState?.isCustomTimeRangeInitialized
  };
};

export { useTimeRange };


