import { CurrencyType } from "@inception/ui";
import { LeftNavItemName } from "../../../../app/leftNav/constants";
import { TCCustomTimeRange } from "../time-range/types";

export interface TenantConfig {
  currency: CurrencyType;
  demoTenant: boolean;
  pocTenant: boolean;
  enableBizFlow: boolean;

  useOnboardingHome: boolean;
  useBizProcessHome: boolean;
  defaultDrilldownVersion: TenantDrilldownVersion;
  showDemoInsights: boolean;
  suggestionsLimit: number;

  showSubVerticalSwitcher: boolean;

  leftNavPreset?: LeftNavItemName[];
  disableNewDashboardFilterPanel?: boolean;
  enableCalendarInLeftNavPanel?: boolean;
  enableDiscoverySandbox?: boolean
  customTimeRanges?: TCCustomTimeRange[];
  showSampleDataCardBanner?: boolean;
  enableNewTriageSummary?: boolean;
  enableKpiBasedOnboarding?: boolean;
  enableRecipeInOp10zePreview?: boolean;

  blobStoreFileUploadTimeoutMins?: number;

  /**
   * @deprecated Use useOldNavigation instead;
   * Backward compatibility has been added to construct useOldNavigation based on this, when former is null
   */
  useNewNavigation?: boolean;
  useOldNavigation?: boolean;

  // Temporary tenantFlag being constructed from tenant and feature flag
  useNewWidgetInBusinessCatalog?: boolean;
}

export enum TenantDrilldownVersion {
  v3 = "v3",
  v4 = "v4",
  v5 = "v5"
}

export interface TenantConfigState {
  tenantConfig: TenantConfig;
  isFetching: boolean;
}
