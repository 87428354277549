import { castArray, isEmpty } from "lodash";

export const EXCLUDE_TAGS: string[] = ['_apptuit_named_query', '__apptuit__metric', '__apptuit__mq', '__apptuit_ts'];
export const QUANTILE_TAG = 'quantile';
export const NAME_TAG = '__name__';

export const shouldExcludeTag = (tag: string, excludeNameTag?: boolean) => {
  const excludeRegEx = /^(__apptuit__|__inception__|_inception_).*$/g;
  const excludeTags = [...EXCLUDE_TAGS];
  excludeNameTag && excludeTags.push(NAME_TAG);

  return !isEmpty(tag) && (excludeTags.includes(tag) || excludeRegEx.test(tag));
};

export const DataTypeWithDefault = {

  /**
   * Takes a input and if its not empty return it else fallsback to default value
   * @param val
   * @param defaultVal
   */
  getStr: function (val: string | number , defaultVal: string = null): string {
    return !isEmpty(val) ? val.toString() : defaultVal;
  },

  getStrArr: function (val: string | string[], defaultVal: string[] = []): string[] {
    return !isEmpty(val) ? castArray(val) as string[] : defaultVal;
  },

  getTypedArr: function <T>(val: T | T[], defaultVal: T[] = []): T[] {
    return !isEmpty(val) ? castArray(val) as T[] : defaultVal;
  }
};

export const getSafeNumber = (num: number, safeDefault = 0) => {
  const safeNum = parseInt(num.toString(), 10);
  return isNaN(safeNum) ? safeDefault : safeNum;
};

export const getChangePercent = (value: number, timeShiftValue: number) => {
  const changeValue = Math.abs(timeShiftValue - value);
  const changePercent = (changeValue / timeShiftValue) * 100;

  const changePercentStr = isNaN(changePercent) ? "" : changePercent.toFixed(2);

  return {
    changePercent,
    changePercentStr
  };
};
