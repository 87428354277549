import React from "react";
import { groupBy, isUndefined, sortBy } from "lodash";
import { templateSrv } from "../../../dashboard/variables/TemplateSrv";
import { logger } from "../../logging/Logger";
import { DatasourceApi } from "../../api/DatasourceService";
import {
  BaseApi,
  DataQueryResponse, DataQueryRequest, InceptionResponse,
  InceptionRequestConfig, InceptionError, DataQueryError, LoadingState, ScopedVars
} from "../../api/types";
import { generateId, MQuery } from "../../../core";
import { APPTUIT_SERIES_TYPE } from "../../../utils";
import { ApptuitDataResponse, IncidentQueryResultMap, RawQuery } from "./ApptuitDatasourceTypes";
import { ApptuitQuery, ApptuitDatasourceApi, ApptuitSettings } from "./types";


export class ApptuitDatasource extends DatasourceApi<ApptuitQuery, ApptuitSettings> implements ApptuitDatasourceApi {

  private readonly ADE_QUERY_API = "/api/ade/query";
  private readonly ADE_ANOM_API = "/api/anoms/api/query";

  private tagKeys: Record<string, string[]> = {};

  constructor(name: string, settings: ApptuitSettings, baseService: BaseApi) {
    super(name, "apptuit", settings, baseService);
  }

  query(request: DataQueryRequest<ApptuitQuery>): Promise<DataQueryResponse> {
    const targets = request.targets || [];
    targets.forEach(target => {
      const ts: any = target.ts;
      if (ts.query) {
        ts.query = templateSrv.replace(ts.query, request.scopedVars, 'pipe');
      }
    });
    const groupByQueryType = groupBy(targets, (t: ApptuitQuery) => t.type);
    const allPromises = [];
    for (const [type, targetsByType] of Object.entries(groupByQueryType)) {
      if (type === "query" || type === "timeseries") {
        allPromises.push(...this.queryMetrics(targetsByType, request));
      } else {
        allPromises.push(...this.queryIncidents(targetsByType, request));
      }
    }

    return Promise.all(allPromises).then((results: DataQueryResponse[]) => {
      const mergedData: any = [];
      const errors: any = [];
      (results || []).forEach((r: DataQueryResponse) => {
        const {
          data,
          error
        } = r;
        mergedData.push(...data as any);
        if (error) {
          errors.push(error);
        }
      });
      return {
        data: mergedData,
        error: errors[0]
      };
    });
  }

  queryIncidents(targets: ApptuitQuery[], request: DataQueryRequest<ApptuitQuery>): Array<Promise<DataQueryResponse>> {
    //console.log(targets, request);

    const promiseArray = targets.map((t): Promise<DataQueryResponse> =>
      this.post<ApptuitDataResponse, string>(this.ADE_ANOM_API, (t.ts as RawQuery).query, {
        cancelToken: request.cancelToken,
        params: {
          start: request.startTime,
          end: request.endTime,
        }
      })
        .then((response: InceptionResponse<ApptuitDataResponse>) => this.handleQueryOutput(t, response, request.startTime, request.endTime))
        .then((result: any[]) => {
          for (const r in result) {
            const res = result[r];
            const { target: seriesName, tags } = res;
            const type = tags[APPTUIT_SERIES_TYPE];

            // anomaly series and mtsincident do not have __aptuit_type tag. Should verify if it can be fixed in the back-end
            res.target = !isUndefined(IncidentQueryResultMap[type]) ? type : seriesName;
          }
          return { data: result };
        })
        .catch((err: InceptionError): DataQueryResponse => this.handleQueryError(err))
    );
    return promiseArray;
  }

  queryMetrics(targets: ApptuitQuery[], request: DataQueryRequest<ApptuitQuery>): Array<Promise<DataQueryResponse>> {
    const promiseArray = targets.map((t) => {
      const query = (t.ts as RawQuery).query;
      // Trigger request only if the query is non-empty
      if (query) {
        const queryPayload = {
          q: query
        };
        return this.post<ApptuitDataResponse, Record<string, string>>('/api/query', queryPayload, {
          cancelToken: request.cancelToken,
          params: {
            "payload_type": "json",
            start: request.startTime,
            end: request.endTime,
          }
        }).then((response: InceptionResponse<ApptuitDataResponse>) => this.handleQueryOutput(t, response, request.startTime, request.endTime))
          .then((result: any[]) => ({ data: result }))
          .catch((err: InceptionError): DataQueryResponse => this.handleQueryError(err));
      } else {
        return Promise.resolve({ data: [] });
      }
    });
    return promiseArray;
  }

  getDPs(start: number, end: number, dps: Array<[number, number | 'NaN' | null]>) {
    const ret = [];
    let lastTS;
    let lastVal;
    for (const i in dps) {
      const ts = dps[i][0] * 1000;
      let val = dps[i][1];
      if (ts < start) {
        if (val !== 'NaN') {
          lastTS = ts;
          lastVal = val;
        }
        continue;
      }
      if (lastTS !== undefined) {
        ret.push([lastVal, lastTS]); // push one previous point (if we have one) to keep lines connected
        lastTS = undefined;
      }
      if (end !== null && ts > end) {
        // if we have a specified end
        if (val !== 'NaN') {
          ret.push([val, ts]); // push one point beyond end if we have one to keep lines connected
          break;
        } else {
          continue;
        }
      }
      if (val === 'NaN') {
        val = null;
        //continue;     - this converts wide-tables to series - can do this for now - between Fill and Grafana options
      }
      ret.push([val, ts]);
    }
    return ret;
  }


  getTargetName(result: any, outputId: string, legendFormat: string) {
    if (outputId === "anomaly") {  // change result output series name with id anomaly to anomalies.
      return IncidentQueryResultMap.anomaly;
    } else if (legendFormat) {
      //return templateSrv.formatLabel(legendFormat, {}, result.tags);
    } else {
      const tags = this.getTagNames(result);
      const name = `${result.metric}{${tags}}`;
      return name;
    }
  }

  getTagNames(result: any) {
    let t = '';
    let ct = [];
    for (const i in result.tags) {
      ct.push(i);
    }
    ct = sortBy(ct, tag => tag);
    for (const i in ct) {
      t += ct[i] + ': ' + result.tags[ct[i]];
      t += ', ';
    }
    if (t.length > 0) {
      t = t.slice(0, t.length - 2);
    }
    return t;
  }

  async handleQueryOutput(
    target: ApptuitQuery,
    response: InceptionResponse<ApptuitDataResponse>,
    start: number,
    end: number
  ): Promise<any> {
    //console.log(response);
    let result = [];
    const outputs = response.data.outputs;
    const allOutputPromises: Array<Promise<any>> = [];
    for (const o in outputs) {
      // outputs -> id:, result: [{series, ...}]
      // qs has only one component in it
      //const q = queryOps.getById(qs, outputs[o].id);
      //const tags = {} as any;
      for (const r in outputs[o].result) {
        const ts = {} as any;
        const id = outputs[o].id;

        const res = outputs[o].result[r];
        const dps = res.dps;

        res.tags = {
          ...((res as any).metricTags || res.tags || {})
        };

        delete (res as any).metricTags;

        ts['target'] = this.getTargetName(res, id, target.legendFormat);
        ts['datapoints'] = this.getDPs(start, end, dps);
        ts['tags'] = res.tags || {};
        ts['refId'] = target.refId || id;
        result.push(ts);

        const rejectedDPCount = dps.length - ts['datapoints'].length;
        logger.debug('Apptuit Datasource', 'target - ' + JSON.stringify(target, null, 2));
        logger.debug('Apptuit Datasource', 'Received datapoints: ' + dps.length);
        logger.debug('Apptuit Datasource', 'Rejected datapoints: ' + rejectedDPCount);

        // get tags for metrics (for suggest)
        // if (q != null && q.metric) {
        //   for (const t in outputs[o].result[r].tags) {
        //     tags[t] = tags[t] || [];
        //     const tagv = outputs[o].result[r].tags[t];
        //     if (tags[t].indexOf(tagv) === -1) {
        //       tags[t].push(tagv);
        //     }
        //   }
        //   for (const t in outputs[o].result[r].aggregatedTags) {
        //     const atag = outputs[o].result[r].aggregatedTags[t];
        //     tags[atag] = tags[atag] || [];
        //     tags[atag].push('');
        //   }
        //   console.log("Setting tags", tags, "for metric ", q.metric, "current tagKeys = ", this.tagKeys);
        //   this.tagKeys[q.metric] = this.tagKeys[q.metric] || {};
        //   for (const tag in tags) {
        //     this.tagKeys[q.metric][tag] = this.tagKeys[q.metric][tag] || [];
        //     const tagV = this.tagKeys[q.metric][tag];
        //     for (const val in tags[tag]) {
        //       if (tagV.indexOf(tags[tag][val]) === -1) {
        //         tagV.push(tags[tag][val]);
        //       }
        //     }
        //   }
        // }
        //console.log("tagKeys - ", this.tagKeys);
      }

      allOutputPromises.push(Promise.resolve());
    }
    await Promise.all(allOutputPromises);

    result = sortBy(result, ts => ts.target);
    return result;
  }

  handleQueryError(err: InceptionError): DataQueryResponse {
    const {
      message,
      response
    } = err;

    logger.error('Apptuit Datasource', `Query error: ${message}`);

    const errMessage = response?.data?.data?.errors[0].message || message;
    return {
      data: [],
      error: {
        data: {
          message: errMessage,
          error: message
        },
        message: message,
        status: response?.status?.toString(),
        statusText: response?.statusText
      },
    };
  }

  get<T, B = never, R = InceptionResponse<T>>(url: string, payload?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.get(dsUrl, payload, config);
  }

  post<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.post(dsUrl, data, config);
  }

  delete<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.delete(dsUrl, config);
  }

  put<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.put(dsUrl, data, config);
  }

  getMonitoredQueries() {
    return this.get(this.ADE_QUERY_API).then((response) => response)
      .catch((e) => e);
  }

  getMonitoredQuery(mqId: string, from: number, to: number, includeIncidents: boolean) {
    const url = `${this.ADE_QUERY_API}/${mqId}?start=${from}&end=${to}&includeIncidents=${includeIncidents}`;
    return this.get(url).then((response) => response)
      .catch((e) => e);
  }

  getMts(mqId: string): Promise<InceptionResponse<{ data: MQuery[] }>> {
    const url = `${this.ADE_QUERY_API}/${mqId}/mts`;
    return this.get<MQuery[], never>(url)
      .catch((e) => e);
  }

  /**
     * Fetch Multiple mq data at once for Incident Time line
     * @param mqIds string array
     * @param from
     * @param to
     * @param includeIncidents boolean
     */
  getMultipleMonitoredQuery(mqIds: string[], from: number, to: number, includeIncidents: boolean) {
    let promiseArray = [];
    promiseArray = mqIds.map((mqId) => {
      const url = `${this.ADE_QUERY_API}/${mqId}?start=${from}&end=${to}&includeIncidents=${includeIncidents}`;
      return this.get(url).then((response) => response)
        .catch((e) => e);
    });
    return Promise.all(promiseArray);
  }

  getIncidentDetails(mqId: string, incidentId: string) {
    const url = `${this.ADE_QUERY_API}/${mqId}/incident/${incidentId}`;
    return this.get(url).then((response) => response)
      .catch((e) => e);
  }

  async suggestQuery(query: string, scopedVars: ScopedVars = {}): Promise<DataQueryResponse<string[]>> {
    const data: string[] = [];
    const error: DataQueryError = {
      data: {
        error: '',
        message: ''
      },
      status: '',
      statusText: ''
    };
    let state: LoadingState = LoadingState.Done;

    if (!query) {
      error.data = {
        error: 'Invalid query',
        message: 'Received an empty query'
      };
      error.status = '404';
      error.statusText = 'Invalid query';
      state = LoadingState.Error;
      return {
        data,
        error,
        state
      };
    }

    let interpolated = query;
    interpolated = templateSrv.replace(query, scopedVars, 'pipe');

    const metricsRegex = /metrics\((.*)\)/;
    // const tagNamesRegex = /tag_names\((.*)\)/;
    const tagValuesRegex = /tag_values\((.*?),\s?(.*)\)/;
    const tagNamesSuggestRegex = /suggest_tagk\((.*)\)/;
    const tagValuesSuggestRegex = /suggest_tagv\((.*)\)/;

    const metricsQuery = interpolated.match(metricsRegex);
    if (metricsQuery) {
      return this.performSuggestQuery(metricsQuery[1], 'metric');
    }

    // const tagNamesQuery = interpolated.match(tagNamesRegex);
    // if (tagNamesQuery) {
    //   return this.performMetricKeyLookup(tagNamesQuery[1]).then(responseTransform);
    // }

    const tagValuesQuery = interpolated.match(tagValuesRegex);
    if (tagValuesQuery) {
      let tagk;
      let filters;
      if (tagValuesQuery[2].indexOf(',') !== -1) {
        tagk = tagValuesQuery[2].substr(0, tagValuesQuery[2].indexOf(','));
        filters = tagValuesQuery[2].substr(tagValuesQuery[2].indexOf(',') + 1).trim();
      } else {
        tagk = tagValuesQuery[2];
      }
      return this.performCustomMetricKeyValueLookup(tagValuesQuery[1], tagk, filters);
    }

    const tagNamesSuggestQuery = interpolated.match(tagNamesSuggestRegex);
    if (tagNamesSuggestQuery) {
      return this.performSuggestQuery(tagNamesSuggestQuery[1], 'tagk');
    }

    const tagValuesSuggestQuery = interpolated.match(tagValuesSuggestRegex);
    if (tagValuesSuggestQuery) {
      return this.performSuggestQuery(tagValuesSuggestQuery[1], 'tagv');
    }

    return {
      data,
      error,
      state
    };
  }

  async performSuggestQuery(query: string, type: 'metric' | 'tagk' | 'tagv', max = 1000): Promise<DataQueryResponse<string[]>> {
    try {
      const { data } = await this.get(`/api/suggest/${type}`, {
        s: query,
        n: max
      });
      return {
        data: data as string[],
        error: {
          data: {
            error: '',
            message: ''
          }
        },
        state: LoadingState.Done
      };
    } catch (err) {
      return {
        data: [],
        error: {
          data: {
            error: err.response.data.error || 'Suggest error',
            message: err.response.data.message || err.message || 'Suggest error'
          }
        },
        state: LoadingState.Error
      };
    }
  }

  async performCustomMetricKeyValueLookup(
    metric: string,
    tagk: string,
    filters = '',
    s = '',
    max = 1000
  ): Promise<DataQueryResponse<string[]>> {
    if (!metric || !tagk) {
      return {
        data: [],
        error: {
          data: {
            error: 'Invalid query',
            message: 'Empty query.tag key'
          }
        },
        state: LoadingState.Error
      };
    }

    try {
      const { data } = await this.get('/api/suggest/tagv', {
        metric: metric,
        filters: filters,
        tagk: tagk,
        s: s,
        n: max
      });
      return {
        data: data as string[],
        error: {
          data: {
            error: '',
            message: ''
          }
        },
        state: LoadingState.Done
      };
    } catch (err) {
      return {
        data: [],
        error: {
          data: {
            error: err.response.data.error || 'Suggest error',
            message: err.response.data.message || err.message || 'Suggest error'
          }
        },
        state: LoadingState.Error
      };
    }
  }

  getDefaultQuery(): ApptuitQuery {
    return {
      query: '',
      refId: generateId(),
      ts: {
        query: '',
        id: '',
        alias: ''
      },
      type: 'query',
      datasource: this.name,
      hide: false
    };
  }

  components = {
    QueryEditor: React.Fragment
  };

}
