import { IncFaIconName, ISaxIconProps } from "@inception/ui";
import { BizDataQuery } from "../../explore";
import { AlertFilterResponse } from "../../operationalise";

export enum FeedType {
  UNKNOWN = 'UNKNOWN',
  INSIGHT = 'INSIGHT',
  TASK = 'TASK',
  NEW_OBJECT = 'NEW_OBJECT',
  EXPLORE = 'EXPLORE',
}

export enum FeedActionType {
  UNKNOWN = 'UNKNOWN',
  NAVIGATE = 'NAVIGATE',
  OPEN = 'OPEN',
  NOOP = 'NOOP',
  CREATE = 'CREATE',
}

export enum FeedDestinationType {
  DESTINATION_UNKNOWN = 'DESTINATION_UNKNOWN',
  DESTINATION_URL = 'DESTINATION_URL',
  DESTINATION_USE_CASE = 'DESTINATION_USE_CASE',
  DESTINATION_WIDGET = 'DESTINATION_WIDGET',
  DESTINATION_OP = 'DESTINATION_OP',
  DESTINATION_DASHBOARD = 'DESTINATION_DASHBOARD',
  DESTINATION_CHAT = 'DESTINATION_CHAT',
  DESTINATION_REPORT = 'DESTINATION_REPORT',
  DESTINATION_TRIAGE = 'DESTINATION_TRIAGE',
  DESTINATION_INSIGHTS = "DESTINATION_INSIGHTS"
}

export enum FeedObjectToCreate {
  NA = 'NA',
  OP = 'OP',
  DASHBOARD = 'DASHBOARD',
  USE_CASE = 'USE_CASE',
  CHAT = 'CHAT',
}

export enum FeedObjectToOpen {
  NA_OBJECT_TO_OPEN = "NA_OBJECT_TO_OPEN",
  MARKDOWN = "MARKDOWN"
}

export interface FeedThumbnail {
  imageUrl?: string;
  videoUrl?: string;
  insightThumbnail?: FeedInsightThumbnail;
  altText: string;
  icon: IncFaIconName | ISaxIconProps['iconName'];
}

export interface FeedInsightThumbnail {
  opId: string;
  alertFilterResponse: AlertFilterResponse;
  bizDataQuery: BizDataQuery;
}

export interface Feed {
  id: string;
  title: string;
  description: string;
  summary: string;
  thumbnail: FeedThumbnail;
  link: string;
  feedType: FeedType;
  actions: FeedAction[];
}

export interface FeedAction {
  actionType: FeedActionType;
  actionButtonIcon: IncFaIconName | ISaxIconProps['iconName'];
  actionName: string;
  destination: FeedDestination;
  objectToCreate: FeedObjectToCreate;
  objectToOpen: FeedObjectToOpen;
}

export interface FeedDestination {
  destinationType: FeedDestinationType;

  url?: string;
  useCaseId?: string;
  widgetId?: string;
  opId?: string;
  dashboardId?: string;
  chatId?: string;
  reportId?: string;
}
