import { parse } from "query-string";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { TimeZone } from "../../moment_wrapper";
import { TimeRange } from "./types";

class TimeSrv {
  private timeRange: TimeRange;
  private compareTimeRange: TimeRange;

  constructor() {
    // Parse the URL time range
    const {
      from,
      to,
      compareFrom,
      timeZone
    } = parse(window.location.search);

    this.timeRangeInit(from, to, timeZone as TimeZone);
    this.compareTimeRangeInit(compareFrom);
  }

  getTimeRange() {
    return this.timeRange;
  }

  setTimeRange(from: string, to: string, timeZone?: TimeZone) {
    this.setTimeRangeInternal(from, to, timeZone);
    this.setCompareTimeRangeInternal(this.compareTimeRange.raw.from);
  }

  getCompareTimeRange() {
    return this.compareTimeRange;
  }

  setCompareTimeRange(from: string) {
    this.setCompareTimeRangeInternal(from);
  }

  refresh() {
    const { from, to, timeZone } = this.timeRange.raw;
    const { from: cFrom } = this.compareTimeRange.raw;

    this.setTimeRangeInternal(from, to, timeZone);
    this.setCompareTimeRangeInternal(cFrom);
  }

  private setTimeRangeInternal(from: string, to: string, timeZone?: TimeZone) {
    const tr = timeRangeUtils.getTimeRangeFromRaw({
      from,
      to,
      timeZone
    });
    this.timeRange = tr;
  }

  private setCompareTimeRangeInternal(fromStr: string) {
    const cTr = timeRangeUtils.getCompareTimeRangeFromRaw(this.timeRange, fromStr);
    this.compareTimeRange = cTr;
  }

  private compareTimeRangeInit(from: string | string[]) {
    if (!from) {
      from = timeRangeUtils.getCompareDefaults().from;
    } else {
      from = typeof from === 'string' ? from : from[0];
    }
    this.setCompareTimeRangeInternal(from);
  }

  private timeRangeInit(from: string | string[], to: string | string[], timeZone?: TimeZone) {
    from = from ?
      typeof from === 'string' ? from
        : from[0]
      : '';
    to = to ?
      typeof to === 'string' ? to
        : to[0]
      : '';

    const defaults = timeRangeUtils.getDefaults();
    from = from || defaults.from;
    to = to || defaults.to;

    this.setTimeRangeInternal(from, to, timeZone);
  }
}

const timeSrv = new TimeSrv();

export default timeSrv;
