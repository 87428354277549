import { cloneDeep, forEach, lowerFirst } from "lodash";
import { toString } from "cronstrue";
import { getFormattedDateTime, IncDateTimeFormat } from "@inception/ui";
import { OpCreationConfig, OpThreshold, CompareOperator, ThresholdType, Op10zeStage, WindowTriggerCondition, WhatsNewConfig } from "../../services/api/operationalise";
import { TimeObjUnit, BizDataQuery, UserServiceFieldSliceSet, SliceSet, fieldPickerApiService, exploreApiService } from "../../services/api/explore";
import { UI_SCHEDULE_KEY, SCHEDULE_TYPES, UI_THRESHOLD_KEY, THRESHOLD_TYPES, opIdParamName, actionParamName, stageParamName } from "../constants";
import { OpContext, OpDefContext } from "../context/types";
import { generateId } from "../../core";
import { getTimeRange } from "../../core/hooks/time-range/TimeRangeGetter";
import timeRangeUtils from "../../utils/TimeRangeUtils";
import { isValidCohortId, getAllCohortDefinition } from "../../utils";
import { OpHomeURLAction } from "../types";
import { getLabelFromBizDataQuery } from "../v3/editors/BizDataQueryEditor/utils";
import { getLabelForTimeObj } from "../../utils/DurationUtils";
import { getCronExpressionForSpecificSchedule } from "./ScheduleUtils";


export const getDefaultOpConfig = (entityTypeId = '', eventTypeId = ''): OpCreationConfig => {
  const defaultOpConfig: OpCreationConfig = {
    createdBy: null,
    description: '',
    idProps: {
      primary: {},
      secondary: {}
    },
    lastUpdatedBy: null,
    name: '',
    opCreationConfigDef: {
      bizDataQuery: {
        sliceSpec: {
          selectorSpec: {
            filters: []
          },
          sliceSet: {
            slices: []
          },
          metricId: ''
        },
        id: '',
        labels: {
          name: ''
        }
      },
      suppression: [],
      internalConfigId: '',
      rollingFreq: {
        unit: TimeObjUnit.days,
        value: 1
      },
      rollingFunction: 'avg',
      schedule: {
        schedule: {
          endTimeEpochSecs: null,
          startTimeEpochSecs: null,
          specificScheduleConfig: {
            hour: null,
            dayOfMonth: null,
            dayOfWeek: null,
            minute: {
              step: 1
            },
            month: null
          }
        },
        endOffset: null,
        startOffset: null,
        labels: {
          [UI_SCHEDULE_KEY]: SCHEDULE_TYPES.everyMinute
        }
      },
      threshold: getDefaultStaticThreshold(),
      startTrigger: {
        allMatchStartTrigger: {
          triggerConditions: [{
            windowTrigger: getDefaultStartTrigger()
          }]
        }
      }
    },
    opCreationConfigExpr: null,
    runBooks: [],
    bizActions: {},
    stage: Op10zeStage.production
  };

  if (entityTypeId) {
    defaultOpConfig.idProps.primary.bizEntityTypeId = entityTypeId;
  }

  if (eventTypeId) {
    defaultOpConfig.idProps.primary.eventTypes = {
      userServiceInfo: [{
        userServiceEntityId: eventTypeId
      }]
    };
  }

  return defaultOpConfig;
};

export const getDefaultWhatsNewConfig = (): WhatsNewConfig => ({
  doSetup: false,
  lookbackPeriod: {
    unit: TimeObjUnit.minutes,
    value: 1,
  },
});

export const isFieldOperationalise = (context: OpContext) => {
  const { selectionContext: opSelectionContext, scheduleSelectionContext } = context;
  return opSelectionContext === 'field' || scheduleSelectionContext === 'field';
};

export const getDefaultThreshold = (): OpThreshold => ({
  aboveThresholdDef: null,
  comparator: CompareOperator.Above,
  thresholdType: ThresholdType.AUTO,
  belowThresholdDef: null,
  labels: {
    [UI_THRESHOLD_KEY]: THRESHOLD_TYPES.automatic
  }
});

export const getDefaultStartTrigger = (): WindowTriggerCondition => ({
  howManyTimesToViolate: 3,
  outOfPoints: 5
});

export const getDefaultStaticThreshold = (): OpThreshold => ({
  aboveThresholdDef: {
    scalar: 10,
    offsetThreshold: null,
    peerThreshold: null,
    rollingFreq: null,
    rollingFunction: null,
    thresholdOp: null
  },
  comparator: CompareOperator.Above,
  thresholdType: ThresholdType.STATIC,
  belowThresholdDef: null,
  labels: {
    [UI_THRESHOLD_KEY]: THRESHOLD_TYPES.static
  }
});

export const getMetricSliceSet = (bizDataQuery: BizDataQuery): UserServiceFieldSliceSet | SliceSet => {
  const { buildingBlockConfig, sliceSpec, id, widgetConfig } = bizDataQuery || {};

  if (buildingBlockConfig) {
    const { buildingBlockDef } = buildingBlockConfig;
    if (buildingBlockDef) {
      const { sliceDef } = buildingBlockDef;
      const { sliceSets } = sliceDef || {};
      if (sliceSets?.length) {
        return sliceSets[0];
      }
    }
  } else if (id || widgetConfig) {
    return sliceSpec.sliceSet || {
      slices: []
    };
  }

  return {
    slices: []
  };
};

export const getCloneConfig = (opCreationConfig: OpCreationConfig) => {
  const opConfig = cloneDeep(opCreationConfig);

  opConfig.simulations = [];

  delete opConfig.isDraft;
  delete opConfig.createdBy;
  delete opConfig.lastUpdatedBy;
  delete opConfig.internalConfigId;
  delete opConfig.opCreationConfigDef?.internalConfigId;

  const nBizActions: OpCreationConfig['bizActions'] = {};
  forEach(opConfig.bizActions, bizAction => {
    const actionId = generateId();
    const nBizAction = cloneDeep(bizAction);

    if (nBizAction?.alertActionConfig) {
      nBizAction.alertActionConfig.actionId = actionId;
      nBizAction.alertActionConfig.opConfigId = [];
    }

    nBizActions[actionId] = nBizAction;
  });
  opConfig.bizActions = nBizActions;

  const buildingBlockConfigId = generateId();
  if (opConfig.opCreationConfigDef?.bizDataQuery?.buildingBlockConfig) {
    const prevId = opConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.id;
    opConfig.opCreationConfigDef.bizDataQuery.buildingBlockConfig.id = buildingBlockConfigId;
    opConfig.opCreationConfigDef.bizDataQuery.sliceSpec.buildingBlockConfigId = buildingBlockConfigId;

    const usFilters = opConfig.opCreationConfigDef.bizDataQuery.metricUserServiceFilters?.[prevId];
    if (usFilters) {
      opConfig.opCreationConfigDef.bizDataQuery.metricUserServiceFilters[buildingBlockConfigId] = usFilters;
      delete opConfig.opCreationConfigDef.bizDataQuery.metricUserServiceFilters[prevId];
    }
  } else if(opConfig.outlierConfig?.bizDataQuery?.bizDataQuery?.buildingBlockConfig) {
    const prevId = opConfig.outlierConfig.bizDataQuery.bizDataQuery.buildingBlockConfig.id;
    opConfig.outlierConfig.bizDataQuery.bizDataQuery.buildingBlockConfig.id = buildingBlockConfigId;
    opConfig.outlierConfig.bizDataQuery.bizDataQuery.sliceSpec.buildingBlockConfigId = buildingBlockConfigId;

    const usFilters = opConfig.outlierConfig.bizDataQuery.bizDataQuery.metricUserServiceFilters?.[prevId];
    if (usFilters) {
      opConfig.outlierConfig.bizDataQuery.bizDataQuery.metricUserServiceFilters[buildingBlockConfigId] = usFilters;
      delete opConfig.outlierConfig.bizDataQuery.bizDataQuery.metricUserServiceFilters[prevId];
    }
  }

  return opConfig;
};

export const fetchContext = async (entityTypeId: string, eventTypeId: string, cohortId: string, context: OpDefContext) => {
  const promises = [
    fetchEntityTypeInfo(entityTypeId, context),
    fetchEventTypeInfo(eventTypeId, context),
    fetchCohortConfigDef(cohortId, entityTypeId, context),
    fetchCohortState(cohortId, entityTypeId, context)
  ];

  await Promise.allSettled(promises);
};

export const getOpHomeQueryParams = (
  opId: string,
  action: OpHomeURLAction,
  queryParams: Record<string, string>,
  stage?: Op10zeStage
) => {
  const opHomeQueryParams: Record<string, string> = {
    ...queryParams,
    [opIdParamName]: opId,
    [actionParamName]: action
  };

  if(stage) {
    opHomeQueryParams[stageParamName] = stage;
  }

  return opHomeQueryParams;
};

export const getLabelForOpCreationConfig = async (opCreationConfig: OpCreationConfig) => {
  const { opCreationConfigDef, outlierConfig } = opCreationConfig;
  const {
    bizDataQuery: OpBizDataQuery,
    threshold,
    schedule: opSchedule,
    rollingFreq: opRollingFreq,
  } = opCreationConfigDef || {};
  const {bizDataQuery: outlierOpBizDataQuery,  schedule: outlierSchedule} = outlierConfig || {};
  const { bizDataQuery: outlierBizDataQuery, rollingFreq: outlierRollingFreq } = outlierOpBizDataQuery || {};

  const bizDataQuery = OpBizDataQuery || outlierBizDataQuery;
  const schedule = opSchedule || outlierSchedule;
  const rollingFreq = opRollingFreq || outlierRollingFreq;

  const {
    schedule: sch,
    endOffset,
    startOffset,
    labels
  } = schedule || {};

  const displayRollingFreq = startOffset && endOffset ? null : rollingFreq;
  const bizDataQueryLabel = await getLabelFromBizDataQuery(bizDataQuery, displayRollingFreq, 'where');
  const thresholdPrefix = threshold?.comparator === CompareOperator.Above ? 'Spike in'
    : threshold?.comparator === CompareOperator.Below ? 'Drop in'
      : threshold?.comparator === CompareOperator.AboveOrBelow ? 'Spike or drop in'
        : '';

  const {
    endTimeEpochSecs,
    startTimeEpochSecs,
    specificScheduleConfig,
  } = sch;

  let scheduleLabel = labels?.[UI_SCHEDULE_KEY];

  if (!scheduleLabel || scheduleLabel === SCHEDULE_TYPES.custom) {
    const cronExpr = specificScheduleConfig ? getCronExpressionForSpecificSchedule(specificScheduleConfig)
      : '';
    scheduleLabel = cronExpr ? toString(cronExpr) : '';
  }

  const startTimeSecs = startTimeEpochSecs ? parseInt(startTimeEpochSecs.toString(), 10) : 0;
  if (startTimeSecs) {
    scheduleLabel += ` starting from ${getFormattedDateTime(startTimeEpochSecs * 1000, IncDateTimeFormat.minimal)}`;
  }

  const endTimeSecs = endTimeEpochSecs ? parseInt(startTimeEpochSecs.toString(), 10) : 0;
  if (endTimeSecs) {
    scheduleLabel += ` and ending at ${getFormattedDateTime(endTimeEpochSecs * 1000, IncDateTimeFormat.minimal)}`;
  }

  scheduleLabel = lowerFirst(scheduleLabel);

  if (startOffset && endOffset) {
    const startLabel = getLabelForTimeObj(startOffset, 'lg', true);
    const endLabel = getLabelForTimeObj(endOffset, 'lg', true);

    scheduleLabel += ` in between ${startLabel} ago to ${endLabel} ago`;
  }

  return `${thresholdPrefix} ${bizDataQueryLabel} ${scheduleLabel}`;
};

const fetchEntityTypeInfo = async (entityTypeId: string, context: OpDefContext) => {
  if (entityTypeId) {
    const { data } = await fieldPickerApiService.getBizEntityType(entityTypeId, context.generateDemoData);
    context.entityTypeInfo = data;
  }
};

const fetchEventTypeInfo = async (eventTypeId: string, context: OpDefContext) => {
  const timeRange = getTimeRange();
  const { fromMillis, toMillis } = timeRangeUtils.getMillisFromTimeRange(timeRange);

  if (eventTypeId) {
    const { data } = await fieldPickerApiService.getUserserviceInfo(eventTypeId, fromMillis, toMillis, context.generateDemoData);
    context.eventTypeInfo = data;
  }
};

const fetchCohortConfigDef = async (cohortId: string, entityTypeId: string, context: OpDefContext) => {
  if (isValidCohortId(cohortId)) {
    const data = await exploreApiService.getCohortConfig(cohortId, entityTypeId, context.generateDemoData);
    context.cohortDefinition = data?.cohortDefinition;
  } else {
    context.cohortDefinition = getAllCohortDefinition(entityTypeId);
  }
};

const fetchCohortState = async (cohortId: string, entityTypeId: string, context: OpDefContext) => {
  if (isValidCohortId(cohortId)) {
    const data = await exploreApiService.getCohortState(cohortId, entityTypeId, context.generateDemoData);
    context.cohortState = data;
  }
};
