
import { isEmpty } from "lodash";
import { MetricAggregations, MetricAggregator, MetricAggregatorExpanded, QuantileFraction } from "../types/MetricTypes";


export type AggregationUIOptions = {
  label: MetricAggregations | "None";
  value: MetricAggregatorExpanded;
  type: MetricAggregator;
  fractions?: QuantileFraction;
  longDisplayName: string;
};

export type QuantileAggregators = 'P25' | 'P50' | 'P90' | 'P95' | 'P99';
export type CardinalityAggregators = 'count' | 'distinctCount' | 'last';
export type ArithmeticAggregators = 'avg' | 'min' | 'max' | 'sum' | 'contribution';

export type Aggregators = ArithmeticAggregators | CardinalityAggregators | QuantileAggregators;

export const MetricAggregationsRecord: Record<Aggregators | string, AggregationUIOptions> = {
  avg: {
    label: MetricAggregations.Average,
    value: "avg",
    type: "avg",
    longDisplayName: "Average"
  },
  count: {
    label: MetricAggregations.Count,
    value: "count",
    type: "count",
    longDisplayName: "Count"
  },
  distinctCount: {
    label: MetricAggregations.DistinctCount,
    value: "distinctCount",
    type: "distinctCount",
    longDisplayName: "Distinct count"
  },
  min: {
    label: MetricAggregations.Min,
    value: "min",
    type: "min",
    longDisplayName: "Minimum"
  },
  max: {
    label: MetricAggregations.Max,
    value: "max",
    type: "max",
    longDisplayName: "Maximum"
  },
  sum: {
    label: MetricAggregations.Sum,
    value: "sum",
    type: "sum",
    longDisplayName: "Total"
  },
  P25: {
    label: MetricAggregations.P25,
    value: "P25",
    type: "quantile",
    fractions: "0.25",
    longDisplayName: "P25"
  },
  P50: {
    label: MetricAggregations.P50,
    value: "P50",
    type: "quantile",
    fractions: "0.5",
    longDisplayName: "P50"
  },
  P90: {
    label: MetricAggregations.P90,
    value: "P90",
    type: "quantile",
    fractions: "0.9",
    longDisplayName: "P90"
  },
  P95: {
    label: MetricAggregations.P95,
    value: "P95",
    type: "quantile",
    fractions: "0.95",
    longDisplayName: "P95"
  },
  P99: {
    label: MetricAggregations.P99,
    value: "P99",
    type: "quantile",
    fractions: "0.99",
    longDisplayName: "P99"
  },
  last: {
    label: MetricAggregations.Last,
    value: "last",
    type: "last",
    longDisplayName: "Last"
  },
  contribution: {
    label: MetricAggregations.Contribution,
    value: "contribution",
    type: "contribution",
    longDisplayName: "contribution"
  },
};

export const getAggregationFromAggrValue = (aggregatorValue: MetricAggregatorExpanded): AggregationUIOptions => {
  const aggrKeys = Object.keys(MetricAggregationsRecord) as Aggregators[];
  let aggregation = null;
  aggrKeys.forEach(x => {
    if (MetricAggregationsRecord[x].value.toUpperCase() === aggregatorValue.toUpperCase()) {
      aggregation = MetricAggregationsRecord[x];
    }
  });
  return aggregation;
};

export const getAggregationUIOption = (agg: MetricAggregator, fraction: QuantileFraction): AggregationUIOptions => {
  if (isEmpty(agg)) {
    return null;
  } else if (agg !== "quantile") {
    return MetricAggregationsRecord[agg];
  } else {
    let aggregation = null;
    const aggrKeys = Object.keys(MetricAggregationsRecord) as Aggregators[];
    aggrKeys.forEach(x => {
      if (MetricAggregationsRecord[x].fractions === fraction) {
        aggregation = MetricAggregationsRecord[x];
      }
    });
    return aggregation;
  }
};
