import Highcharts, { Chart } from 'highcharts';
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import React, { useRef, useEffect } from "react";
import { getInceptionTheme } from '../../../themes/ThemeProvider';
import { BuildGaugeOptions, IncActivityGaugeDataProp } from "./IncActivityGaugeOptionsBuilder";

highchartsMore(Highcharts);
solidGauge(Highcharts);

const renderChart = (container: HTMLElement, payload: Highcharts.Options): Chart => Highcharts.chart(container, payload);

interface Props {
  chartOptions: IncActivityGaugeDataProp;
  centerLabel?: string;
}

const IncActivityGauge: React.FC<Props> = (props: Props) => {

  const theme = getInceptionTheme();

  const ref = useRef<HTMLDivElement>(null);
  const payload = BuildGaugeOptions(props.chartOptions, theme, props.centerLabel);
  useEffect(() => {
    if (ref.current) {
      renderChart(ref.current, payload);
    }
  }, [payload, ref]);

  return <div ref={ref}></div>;
};

export default IncActivityGauge;

