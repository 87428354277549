import { Entity } from '../../../core';

export const parseBusinessGraphEntityResponse = (response: any) => {
  const entity: Entity[][] = [];
  const boEntities = response.data.entityLookup;
  (boEntities || []).map((boEntity: any) => {
    const boName = boEntity.name;
    if (boName) {
      const apiEntities = boEntity.bo2api;
      (apiEntities || []).map((apiEntity: any) => {
        const apiName = apiEntity.name;
        if (apiName) {
          const components = apiEntity.component2api;
          (components || []).map((component: any) => {
            const serviceInstances = component.component2ServiceInstance;
            (serviceInstances || []).map((serviceInstance: any) => {
              const containers = serviceInstance.serviceInstance2Container;
              (containers || []).map((container: any) => {
                const pods = container.container2Pod;
                (pods || []).map((pod: any) => {
                  const podName = pod.name;
                  entity.push([boEntity, apiEntity, component, pod]);
                  return podName;
                });
                return container;
              });
              return serviceInstance;
            });
            return component;
          });
        }
        return apiEntity;
      });
    }
    return boEntity;
  });
  return entity;
};
