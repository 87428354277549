import { IncDuration, IncSelect, IncTextfield } from '@inception/ui';
import { IncSelectOption } from '@inception/ui/src/components/Select/InceptionSelect';
import { debounce, isEmpty, isEqual, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

enum RelativeDurationType {
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS
}

enum RelativeDurationSuffix {
  MINUTES = "m",
  HOURS = "h",
  DAYS = "d",
  WEEKS = "w",
  MONTHS = "M"
}

export interface IncSelectorDuration {
  durationType: RelativeDurationType;
  duration: number | string;
  durationSuffix?: RelativeDurationSuffix;
}

interface DurationSelectProps {
  className?: string;
  duration?: IncSelectorDuration;
  hideLabel?: boolean;
  onChange: (duration: IncSelectorDuration) => void;
  hideTimeValues?: boolean;
  label?: string;
  isDisabled?: boolean;
  readOnly?: boolean;
}


const IncDurationSelector: React.FC<DurationSelectProps> = (props) => {
  const {
    onChange,
    duration,
    hideLabel,
    hideTimeValues,
    label = "Duration",
    className = '',
    isDisabled = false,
    readOnly = false
  } = props;

  const [durationNum, setDurationNum] = useState<string>("");
  const [durationType, setDurationtype] = useState<IncSelectOption>(null as any);

  useEffect(() => {
    if (!duration) {
      return;
    }
    setDurationNum(duration?.duration.toString());
    setDurationtype(getDurationTypeOption(duration.durationType));
  }, [duration]);

  const callOnChange = useCallback((newDuration: IncSelectorDuration) => {
    if (isEqual(duration, newDuration)) {
      return;
    }

    onChange(newDuration);
  }, [duration, onChange]);

  const debounedCallOnChange = useMemo(() => debounce(callOnChange, 300), [callOnChange]);
  const durationMapOpts = hideTimeValues ? durationMapDateTypes : durationMap;
  const durationOptions = useMemo<IncSelectOption[]>(() => map(durationMapOpts, (v, k) => ({
    label: v,
    value: k.toString()
  })), [durationMapOpts]);

  const durationError = durationNum && durationNum <= '-1' ? 'Duration should be a positive number' : '';

  const onDurationNumChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numVal = value === "" ? 0 : parseInt(value, 10);
    const valid = !isNaN(numVal) && numVal >= 0;
    if (valid) {
      setDurationNum(value);
      debounedCallOnChange({
        duration: numVal,
        durationType: parseInt(durationType.value, 10),
        durationSuffix: durationSuffixMap[parseInt(durationType.value, 10)]
      });
    }
  }, [debounedCallOnChange, durationType]);

  const onDurationTypeChange = useCallback((op: IncSelectOption) => {
    const val = parseInt(op.value, 10);
    setDurationtype(op);

    callOnChange({
      duration: parseInt(durationNum, 10),
      durationType: val,
      durationSuffix: durationSuffixMap[val]
    });
  }, [callOnChange, durationNum]);

  const appliedClassName = `duration-select ${className}`;

  return <div className={appliedClassName}>
    {!hideLabel && <div className="marginBt6 inc-label-common">
      {label}
    </div>}
    <div className="inc-flex-row">
      <IncTextfield
        autoAdjustWidth
        disabled={isDisabled}
        errorText={durationError}
        hasError={!isEmpty(durationError)}
        label=""
        onBlur={() => {
          if (!durationNum) {
            setDurationNum("0");
          }
        }}
        onChange={onDurationNumChange}
        readOnly={readOnly}
        type='number'
        value={durationNum}
      />
      <IncSelect
        autoAdjustWidth
        autoSort={false}
        isDisabled={isDisabled}
        isMulti={false}
        isSearchable={false}
        label=""
        menuPlacement="auto"
        menuPortalTarget={document.body}
        onChange={onDurationTypeChange as any}
        options={durationOptions}
        readOnly={readOnly}
        value={durationType}
      />
    </div>
  </div>;
};

export default IncDurationSelector;

const durationMapTimeTypes = {
  [RelativeDurationType.MINUTES]: 'Minutes',
  [RelativeDurationType.HOURS]: 'Hours',
};
const durationMapDateTypes = {
  [RelativeDurationType.DAYS]: 'Days',
  [RelativeDurationType.WEEKS]: 'Weeks',
  [RelativeDurationType.MONTHS]: 'Months',
};
const durationMap = {
  ...durationMapDateTypes,
  ...durationMapTimeTypes,
};

const durationSuffixMap: any = {
  [RelativeDurationType.MINUTES]: RelativeDurationSuffix.MINUTES,
  [RelativeDurationType.HOURS]: RelativeDurationSuffix.HOURS,
  [RelativeDurationType.DAYS]: RelativeDurationSuffix.DAYS,
  [RelativeDurationType.WEEKS]: RelativeDurationSuffix.WEEKS,
  [RelativeDurationType.MONTHS]: RelativeDurationSuffix.MONTHS
};

function getDurationTypeOption(durationType: RelativeDurationType) {
  const op: IncSelectOption = {
    label: durationMap[durationType],
    value: durationType?.toString()
  };
  return op;
}


export function durationToString(incDuration: IncDuration): string {
  const {
    duration: durationNum,
    durationType
  } = incDuration;

  let durationTypeStr = '';

  switch (durationType as unknown as RelativeDurationType) {
    case RelativeDurationType.MINUTES: {
      durationTypeStr = 'm';
      break;
    }
    case RelativeDurationType.HOURS: {
      durationTypeStr = 'h';
      break;
    }
    case RelativeDurationType.DAYS: {
      durationTypeStr = 'd';
      break;
    }
    case RelativeDurationType.WEEKS: {
      durationTypeStr = 'w';
      break;
    }
    case RelativeDurationType.MONTHS: {
      durationTypeStr = 'M';
      break;
    }
  }

  return durationNum + durationTypeStr;
}


export function stringToDuration(dsIntervalStr: string): IncSelectorDuration {
  const magnitude = parseInt(dsIntervalStr, 10);
  const unit = dsIntervalStr.replace(magnitude.toString(), '');
  const tValue = Object.keys(durationSuffixMap).find((key: any) => durationSuffixMap[key] === unit);
  const type = tValue? parseInt(tValue,10): null;

  return {
    durationType: type as any,
    duration: magnitude,
    durationSuffix: unit as any,
  };
}
