import React, { useRef, useEffect, useMemo } from "react";
import Dropzone from "react-dropzone";
import { omit } from "lodash";
import { cx } from "emotion";
import { IncFaIcon } from "../Icons";
import { copyRefs } from "../../utils../../utils/components/copyRefs";
import IncDropzoneProps from "./types";


const omitProps = [
  'rootClass',
  'rootStyles',
  'rootRef',
  'btnClass',
  'btnStyles',
  'btnRef',
  'btnContent',
  'rootContent',
  'accept'
];

const defBtnContent = (multiple: boolean) => <span>
  <IncFaIcon className="icon" iconName="search" />
  <span className="label">Browse file{multiple ? "s" : ''}</span>
</span>;

const defRootContent = (multiple: boolean) => <span className="text">
  Drag and drop to open file{multiple ? "s" : ''}
</span>;

const IncDropzone: React.FC<IncDropzoneProps> = (props) => {
  const dropzoneProps = omit(props, omitProps);
  const {
    multiple = false,
    disabled = false,
    rootClass = "",
    rootStyles = {},
    rootContent: pRootContent,
    rootRef = null,
    btnClass = "",
    btnContent: pBtnContent,
    btnRef = null,
    btnStyles = {},
    accept,
    children
  } = props;

  if (accept && accept.length > 0) {
    dropzoneProps.accept = accept;
  }

  const rootClassName = cx(
    'inc-dropzone',
    {
      disabled,
      'inc-dropzone--no-drag': props.noDrag || false,
      'inc-dropzone--no-click': props.noClick || false,
    },
    rootClass
  );
  const btnClassName = cx('inc-dropzone--btn', { disabled }, btnClass);

  const dropzoneRef = useRef(null);

  useEffect(() => copyRefs(rootRef, dropzoneRef));

  const onClick = () => {
    if (dropzoneRef.current) {
      const dropzone = dropzoneRef.current as any;
      dropzone.open();
    }
  };

  const rootContent = useMemo(() => pRootContent || defRootContent(multiple), [multiple, pRootContent]);
  const btnContent = useMemo(() => pBtnContent || defBtnContent(multiple), [multiple, pBtnContent]);

  return (
    <Dropzone {...dropzoneProps} noClick ref={dropzoneRef}>
      {({ getRootProps, getInputProps }) => {
        const rootProps = getRootProps();
        const inputProps = getInputProps();

        return <>
          {children && children(rootProps, inputProps, onClick)}
          {!children && <div className={rootClassName} {...rootProps} style={rootStyles}>
            <input {...inputProps} multiple={multiple} />
            {!dropzoneProps.noDrag && rootContent}
            {!dropzoneProps.noDrag && !dropzoneProps.noClick && <p className="text sep">(OR)</p>}
            {!dropzoneProps.noClick && <button className={btnClassName} disabled={disabled} onClick={onClick}
              ref={btnRef} style={btnStyles}>
              {btnContent}
            </button>}
          </div>}
        </>;
      }}
    </Dropzone>
  );
};

export default IncDropzone;
