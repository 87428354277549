import { Action } from "./features";

//place all actions under busniess entity here
export enum AUTO_DISCOVERY_ACTIONS {
  createRequest = "createRequest",
  viewEvents = "viewEvents"
}

export const AutoDiscoveryFeatureActions: Record<keyof typeof Action, Array<keyof typeof AUTO_DISCOVERY_ACTIONS>> = {

  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  "CREATE": [
    "createRequest"
  ],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  "UPDATE": [],

  //All READ actions goes here
  //Enabled for All
  "READ": [
    "viewEvents"
  ],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  "DELETE": []

};
