import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, logger, useDataFetch } from "../../../../core";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";
import { GetIncidentInfoRequest, GetIncidentInfoResponse } from "../types";

export const useFetchInsightSummaryInfo = (
  opConfigId: string,
  incidentId: string,
  projection: GetIncidentInfoRequest['projection'],
  startTimeMillis: number,
  endTimeMillis: number,
  generateDemoData = false,
  lazyFetch = false
) => {
  const initialState = useMemo<InitialState<GetIncidentInfoResponse, string>>(() => ({
    data: null,
    error: null,
    isError: false,
    isFetching: !lazyFetch,
    isSuccess: false
  }), []);

  const fetchFn = useCallback<FetchFn<GetIncidentInfoResponse, string>>(async () => {
    const result: FetchCallBackResult<GetIncidentInfoResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await operationaliseV2ApiService.getInsightSummaryInfo(
        opConfigId,
        incidentId,
        startTimeMillis,
        endTimeMillis,
        projection,
        generateDemoData
      );

      result.isError = error;
      result.isSuccess = !error;
      result.data = data;
      result.error = error ? message : null;
    } catch (err) {
      logger.error("UseFetchInsightMembership", "Error fetching incidents", err);
      result.isError = true;
      result.error = err.message?.toString() || err.toString();
    }

    return result;
  }, [endTimeMillis, generateDemoData, incidentId, opConfigId, projection, startTimeMillis]);

  return useDataFetch(fetchFn, initialState, lazyFetch);
};
