import { useState, useCallback } from "react";
import { noOp } from "../../utils";

export const useToggleState = (
  initialState = false,
  onOpen = noOp,
  onClose = noOp
) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => {
    setIsOpen(true);
    onOpen();
  }, [onOpen]);


  const close = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return {
    isOpen,
    open,
    close,
    toggle,
    setIsOpen
  };
};
