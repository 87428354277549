import { useCallback, useMemo } from "react";
import { FetchCallBackResult, InitialState, useDataFetch } from "../../../../core";
import { UseCaseCatalogList } from "../types";
import { useCaseCatalogApiService } from "../UseCaseCatalogApiService";

interface Props {
  verticalId: string;
  subVerticalId: string;
  companyName: string;
}

export const useFetchUseCaseCatalog = (props: Props) => {
  const {
    verticalId,
    subVerticalId,
    companyName
  } = props;

  const initialData = useMemo<InitialState<UseCaseCatalogList, string>>(() => ({
    data: null,
    error: null,
    isError: false,
    isFetching: true,
    isSuccess: false,
  }), []);

  const fetchSuggestedUsecases = useCallback(async () => {
    const result: FetchCallBackResult<UseCaseCatalogList, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await useCaseCatalogApiService.getUseCaseCatalogConfig(verticalId, subVerticalId, companyName);

      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        result.data = data;
        result.isSuccess = true;
      }
    } catch (e) {
      result.error = e;
      result.isError = true;
    }
    return result;
  }, [companyName, subVerticalId, verticalId]);

  return useDataFetch(fetchSuggestedUsecases, initialData, true);
};
