import { getParamFromUrl, asyncGetItem} from '../../core';
import { logger } from '../logging/Logger';
import appConfig from '../../../appConfig';
import datasourceApiManager from './DatasourceApiService';
import { ConfigType, EntityTypeWrapper, TrafficType, InceptionResponse, RelationshipType, SchemaType } from './types';
import { DEMO_SUMMARY_PAYLOAD_KEYS } from './Triage';
import { TypedObjectValue } from './operationalise';

interface SchemeApiServiceInterface {
  getSchema: (params?: Record<string, string>, url?: string) => Promise<InceptionResponse<any>>;
  addSchema: (schema: SchemaType) => Promise<SchemaType>;
  updateMetadata: (entityId: string, key: string, value: TypedObjectValue) => Promise<boolean>;
  getEntityTypes: () => Promise<InceptionResponse<SchemaTypesResponse<EntityTypeWrapper>>>;
  getTrafficTypes: () => Promise<InceptionResponse<SchemaTypesResponse<TrafficType>>>;
  getConfigTypes: () => Promise<InceptionResponse<SchemaTypesResponse<ConfigType>>>;
  getRelTypes: () => Promise<InceptionResponse<SchemaTypesResponse<RelationshipType>>>;
  getEntityTypeCount: (entityTypeIs: string[]) => Promise<Map<string, number>>;
  getEntityTypeInfo: (entityType: string) => Promise<InceptionResponse<EntityTypeWrapper>>;
}

export type SchemaTypesResponse<T = SchemaType> = {
  types: T[];
};

type TypeMetaUpdateAction = 'edit' | 'TypeMetaUpdateAction_unset' | 'reset';
type TypeMetaUpdateField = 'name' | 'TypeMetaUpdateField_unset' | 'desc' | 'domain' | 'category' | 'metadata';

export type UpdateBizEntityMetaPayload = {
  field: TypeMetaUpdateField,
  action: TypeMetaUpdateAction,
  key: string,
  value: TypedObjectValue
}

export type UpdateBizEntityResponse = {
  booleanVal: boolean;
}

class SchemaApiService implements SchemeApiServiceInterface {
  readonly SCHEMA_URL: string = '/schema-store/api/v1/types';
  readonly SCHEMA_URL_ENTITY_TYPES = '/schema-store/api/v1/kinds/_entity/types';

  async updateMetadata(entityId: string, key: string, value: TypedObjectValue): Promise<boolean> {
    const url = `${this.SCHEMA_URL_ENTITY_TYPES}/${entityId}/update-metadata`;
    const payload: UpdateBizEntityMetaPayload = {
      field: 'metadata',
      action: 'edit',
      key,
      value
    };
    try {
      const data = await datasourceApiManager.getDefault().put<UpdateBizEntityResponse,UpdateBizEntityMetaPayload>(url, payload);
      return data?.data?.booleanVal || false;
    } catch (error) {
      logger.error("SchemaApiService", "Error while updating metadata", error);
      return false;
    }
  }

  async getSchema(params = {}, url = ''): Promise<InceptionResponse<any>> {
    url = `${this.SCHEMA_URL}${url}`;
    try {
      const data: any = getParamFromUrl("demo") ? await asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.schemaTypePayload)
        : await datasourceApiManager.getDefault().get(url, params);
      return data;
    } catch (e) {
      logger.error("SchemaApiService", "Error while fetching schema types", e);
      throw e;
    }
  }

  async addSchema(newSchema: SchemaType): Promise<SchemaType> {
    try {
      const result = await datasourceApiManager.getDefault().post<SchemaType, SchemaType>(this.SCHEMA_URL, newSchema);

      return result.data;
    } catch (e) {
      logger.error("SchemaApiService", "Error while fetching schema types", e);
      throw e;
    }
  }

  async getEntityTypes(): Promise<InceptionResponse<SchemaTypesResponse<EntityTypeWrapper>>> {

    if (appConfig.anomShareId) {
      return Promise.resolve({
        config: {},
        data: { types: [mockEntityType] },
        headers: {},
        status: 200,
        statusText: "OK",
      } as any);
    }

    const entityTypeResponse: InceptionResponse<SchemaTypesResponse<EntityTypeWrapper>> = await schemaApiService.getSchema({ typeId: '_entity' });
    return entityTypeResponse;
  }

  async getEntityTypeInfo(entityType: string): Promise<InceptionResponse<EntityTypeWrapper>> {
    const schemaCall: any = getParamFromUrl("demo") ? asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.entityTypePayload) :
      schemaApiService.getSchema(
        { typeId: '_entity' },
        `/${entityType}`
      );
    const entityTypeResponse: InceptionResponse<EntityTypeWrapper> = await schemaCall;
    return entityTypeResponse;
  }

  async getTrafficTypes(): Promise<InceptionResponse<SchemaTypesResponse<TrafficType>>> {
    const entityTypeResponse: InceptionResponse<SchemaTypesResponse<TrafficType>> = await schemaApiService.getSchema({ typeId: '_traffic' });
    return entityTypeResponse;
  }

  async getConfigTypes(): Promise<InceptionResponse<SchemaTypesResponse<ConfigType>>> {
    const configTypeResponse: InceptionResponse<SchemaTypesResponse<ConfigType>> = await schemaApiService.getSchema({ typeId: '_config' });
    return configTypeResponse;
  }

  async getRelTypes(): Promise<InceptionResponse<SchemaTypesResponse<RelationshipType>>> {
    const relTypeResponse: InceptionResponse<SchemaTypesResponse<RelationshipType>> = await schemaApiService.getSchema({ typeId: '_rel' });
    return relTypeResponse;
  }

  async getEntityTypeCount(entityTypeIds: string[]): Promise<Map<string, number>> {
    const url = `${this.SCHEMA_URL_ENTITY_TYPES}/count`;
    const retMap: Map<string, number> = new Map();

    try {
      const result = await datasourceApiManager.getDefault().post(url, { typeIds: entityTypeIds });

      const countObj = (result.data as any).types as Record<string, string>;

      for (const [key, value] of Object.entries(countObj)) {
        retMap.set(key, parseInt(value, 10));
      }
    } catch (e) {
      logger.error("SchemaApiService", "Error while fetching schema types", e);
    }

    return retMap;
  }
}

const schemaApiService: SchemeApiServiceInterface = new SchemaApiService();

export default schemaApiService;

export const mockEntityType = {
  "entityType": {
    "typeReference": {
      "id": "Location",
      "typeName": "Location",
      "desc": "Location",
      "category": "biz",
      "metadata": {
        "icon": "map-pin"
      }
    },
    "idPropSet": [
      {
        "prop": [
          "Id"
        ]
      }
    ],
    "propertiesMeta": {
      "Id": {
        "versioned": true,
        "indexed": true
      },
      "i_metadata": {
        "kind": "_map",
        "indexed": true
      },
      "i__missing_rels": {
        "kind": "_set",
        "indexed": true
      },
      "Name": {
        "indexed": true
      },
      "user_labels": {
        "kind": "_map",
        "indexed": true
      }
    },
    "indexGroup": "default",
    "propertyMapping": {
      "Id": {
        "columnId": "ten111.default.idx.str0"
      },
      "i_metadata": {
        "kind": "_map",
        "columnId": "ten111.default.idx.set2"
      },
      "i__missing_rels": {
        "kind": "_set",
        "columnId": "ten111.default.idx.set1"
      },
      "Name": {
        "columnId": "ten111.default.idx.str1"
      },
      "user_labels": {
        "kind": "_map",
        "columnId": "ten111.default.idx.set0"
      }
    },
    "revPropMapping": {
      "ten111.default.idx.str0": "Id",
      "ten111.default.idx.set2": "i_metadata",
      "ten111.default.idx.set1": "i__missing_rels",
      "ten111.default.idx.str1": "Name",
      "ten111.default.idx.set0": "user_labels"
    },
    "lifecycle": {
      "maxVersions": 5
    }
  }
};
