//import '!style-loader!css-loader!sass-loader!./waterfall.scss';
import { IncToggle } from '@inception/ui';
import { debounce } from 'lodash';
import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TableHeaderProps } from 'react-virtualized';
import IncTextfield from '../Textfield/TextField';
import TreeGrid from '../TreeGrid/TreeGrid';
import { CellRenderer, ColDef, RowDataInternal } from '../TreeGrid/types';
import { createRowDataInternalObject, flattenArray, insertInArray, removeChildren } from '../TreeGrid/utils';
import TimelineMarker from './components/TimelineMarker';
import TimelineEventBar from "./TimelineEventBar";
import { DerivedData, Span, SpanTreeNode, Tag, Trace } from './types';
import { API, BACKEND_API, CALLED_API, CLIENT, COMPONENT, constructSpanTree, SERVICE } from './utils';

interface WaterfallProps {
  trace: Trace;
  onSpanClick?: (span: Span) => void;
  getRootSpan?: (span: Span) => void;
  selectedSpanId?: string;
  showSpanSpentTime?: boolean;
  entityRenderer?: FC<any>;
  logger: any;
}

const additionalCtx = {
  startTime: 0,
  totalDuration: 0
};

const Waterfall: FC<WaterfallProps> = (props) => {
  const { logger } = props;

  const [data, setData] = useState<Array<RowDataInternal<SpanTreeNode>>>([]);
  const [dataToDisplay, setDataToDisplay] = useState<Array<RowDataInternal<SpanTreeNode>>>([]);
  const rootSpan = useRef<RowDataInternal<SpanTreeNode>>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const [filterString, setFilterString] = useState<string>('');

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const OperationNameRenderer = ({ data: rowData }: CellRenderer<RowDataInternal<SpanTreeNode>>): React.ReactElement => {
    if (rowData.spanID === 'root') {
      return <></>;
    }
    const Component = props.entityRenderer;

    if (!Component) {
      return <></>;
    }

    let api: ReactNode;
    let service: ReactNode;
    let component: ReactNode;
    let apiId = rowData[API];
    const componentId = rowData[COMPONENT];
    const serviceId = rowData[SERVICE];
    const backendId = rowData[BACKEND_API];
    const calledApiId = rowData[CALLED_API];
    const isClientSpan = rowData.spanKind?.toLowerCase() === CLIENT.toLowerCase();

    if (isClientSpan) {
      apiId = calledApiId ?? backendId;
    }

    if (apiId) {
      api = <Component id={apiId} useFallback />;
    } else {
      logger.warn('Entity', 'Fallback to operationName as api entityId is null');
      api = <FallbackEntityRenderer name={rowData.operationName} />;
    }

    if (serviceId) {
      service = <Component id={serviceId} useFallback />;
    } else {
      logger.warn('Entity', 'Fallback to serviceName as service entityId  is null');
      service = <FallbackEntityRenderer name={rowData.serviceName} />;
    }

    if (componentId) {
      component = <Component id={componentId} useFallback />;
    } else {
      logger.warn('Entity', 'Fallback to - as component entityId is null');
      component = <span>{'-'}</span>;
    }

    let elements: ReactNode;
    if (service && component) {
      elements = <span className='inc-flex-row'>({service},&nbsp;{component})</span>;
    }
    if (service && !component) {
      elements = <span className='inc-flex-row'>({service},&nbsp;)</span>;
    }
    if (!service && component) {
      elements = <span className='inc-flex-row'>(,&nbsp;{component})</span>;
    }

    // indent = indent || 0;
    // const offset = rowData?._hasChildren ? indent + 12 : indent;
    return (
      <div className='inc-flex-column'>
        <span className='ts-api-name'>{api}</span>
        {
          elements ? (
            <span className="treegrid-row-newline inc-flex-row paddingTp6">
              {/* <span className="treegrid-indent" style={{width: offset}}></span> */}
              <span className="treegrid-row-sub-info">{elements}</span>
            </span>
          ) : null
        }
      </div>
    );
  };

  const TimelineRender = ({
    data: rowData,
    isCollapsed,
    additionalCtx
  }: CellRenderer<RowDataInternal<SpanTreeNode>>): React.ReactElement => (
    <TimelineEventBar
      collapsed={isCollapsed}
      event={rowData as any}
      overallStartTimeInMs={additionalCtx.startTime}
      showSpanSpentTime={props.showSpanSpentTime}
      totalDurationInMs={additionalCtx.totalDuration}></TimelineEventBar>
  );

  const TimelineColumnHeaderRenderer = (col: TableHeaderProps, additionalCtx: any): React.ReactElement => (
    <TimelineMarker durationInMs={additionalCtx.totalDuration} numMarkers={5} />
  );

  const onRowClick = (index: number, rowData: SpanTreeNode) => {
    if (props.onSpanClick) {
      props.onSpanClick(rowData);
    }
  };

  const [columnDefs] = useState<Array<ColDef<SpanTreeNode>>>([
    {
      field: 'spanID',
      headerName: 'API (service, component)',
      flex: 0.5,
      cellRendererFramework: OperationNameRenderer
    },
    {
      field: '',
      headerName: 'Timeline',
      headerRender: TimelineColumnHeaderRenderer,
      flex: 0.7,
      cellRendererFramework: TimelineRender,
      additionalCtx: additionalCtx
    },
  ]);

  const getRootSpanCallback = useCallback((span: Span) => {
    if (props.getRootSpan) {
      props.getRootSpan(span);
    }

    // eslint-disable-next-line
  }, []);

  const getError = useCallback(() => {
    const filteredErrorTrace: Trace = { ...props.trace };
    const rootSpan = filteredErrorTrace.traceFields.filter(span => (span.isRoot === "true"))[0];
    const root: SpanTreeNode = {
      ...rootSpan,
      children: [],
      depth: 0,
      collapsed: false,
    };
    let errorData: Array<RowDataInternal<SpanTreeNode>> = [];
    filteredErrorTrace.traceFields = filteredErrorTrace.traceFields.filter(span => (span.isRoot !== "true" && (span.spanError === "true" || (span.errors && span.errors.length > 0))));
    if (filteredErrorTrace.traceFields.length === 0 && (root.spanError === 'true' || (root.errors && root.errors.length > 0))) {
      errorData = flattenArray([createRowDataInternalObject(root)], true);
    } else if (filteredErrorTrace.traceFields.length > 0) {
      const derivedErrorData: DerivedData | null = constructSpanTree(filteredErrorTrace, root);
      if (derivedErrorData?.spanTree) {
        errorData = flattenArray(
          derivedErrorData.spanTree.map((node) => createRowDataInternalObject(node)),
          true);
      }
    }
    return errorData;
  }, [props.trace]);

  const error = useMemo(() => (getError), [getError]);

  useEffect(() => {
    const derivedData: DerivedData | null = constructSpanTree(props.trace);

    let flattedData: Array<RowDataInternal<SpanTreeNode>> = [];
    if (derivedData?.spanTree) {
      flattedData = flattenArray(
        derivedData?.spanTree.map((node) => createRowDataInternalObject(node)),
        true);
      (rootSpan as any).current = flattedData[0];
      setData(flattedData);
      setDataToDisplay(flattedData);
    }

    let selectionIndex = 0;
    if (props.selectedSpanId) {

      for (let i = 0; i < flattedData.length; ++i) {
        if (flattedData[i].spanId === props.selectedSpanId) {
          selectionIndex = i;
          break;
        }
      }
    }

    setSelectedIndex(selectionIndex);

    if (flattedData && flattedData[selectionIndex]) {
      getRootSpanCallback(flattedData[selectionIndex] as unknown as Span);
    }

    additionalCtx.startTime = derivedData?.startTime || -1;
    additionalCtx.totalDuration = derivedData?.duration || -1;
  }, [props.trace, getRootSpanCallback, props.selectedSpanId]);

  useEffect(() => {
    if (showError) {
      setDataToDisplay(error);
    } else {
      setDataToDisplay(data);
    }
  }, [data, error, showError]);

  const expandData = useCallback(() => {
    if (rootSpan.current) {
      const tempState: Array<RowDataInternal<SpanTreeNode>> = [rootSpan.current];
      tempState[0]._showChildren = true;
      insertInArray(
        tempState, 1,
        flattenArray(tempState[0]._children, true, tempState[0]));
      setDataToDisplay(tempState);
    }
  }, []);

  const collapseData = useCallback(() => {
    if (rootSpan.current) {
      const tempState: Array<RowDataInternal<SpanTreeNode>> = [rootSpan.current];
      tempState[0]._showChildren = false;
      removeChildren(tempState, tempState[0]._key);
      setDataToDisplay(tempState);
    }
  }, []);

  useEffect(() => {
    if (expandAll) {
      expandData();
    } else {
      collapseData();
    }
  }, [collapseData, expandAll, expandData]);

  const getHighlightedRows = useCallback((array: Array<RowDataInternal<SpanTreeNode>>, str: string): Set<number> => filter(array, str), []);

  const onErrorChange = useCallback(() => {
    setShowError(!showError);
  }, [showError]);

  const onSearchType = (searchText: string) => {
    setFilterString(searchText);
  };

  const onSearchDebounce = debounce((value: string) => onSearchType(value), 500);

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <div className={'wrapper'} style={{ width: '100%' }}>
      <div className={'span-configs'}>
        <IncTextfield className={'span-searchbar'} onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onSearchDebounce(e.target.value)} placeholder={'Search spans'}></IncTextfield>
        <IncToggle
          checked={showError}
          className={'span-toggle'}
          disabled={getError().length === 0}
          label="Show Error Spans"
          onChange={onErrorChange}
          variant="success"
        />
        <IncToggle
          checked={expandAll}
          label="Expand Tree"
          onChange={() => setExpandAll(!expandAll)}
          variant="success"
        />
      </div>
      <TreeGrid<SpanTreeNode>
        columnDefs={columnDefs}
        expandAll={true}
        filterString={filterString}
        flatData={dataToDisplay}
        highlightRowFn={getHighlightedRows}
        onRowClick={onRowClick}
        selectedIndex={selectedIndex}
      />
    </div>
  );
};

const filter = (array: Array<RowDataInternal<SpanTreeNode>>, filterString: string): Set<number> => {
  // Get indexes to highlight

  const indices = new Set<number>();

  if (!filterString || filterString === '') {
    return indices;
  }

  if (array) {
    array.forEach((row: RowDataInternal<SpanTreeNode>, index: number) => {

      for (const [key, value] of Object.entries(row)) {
        // don't go over nested objects here. tags will be iterated over later.
        if (typeof value === 'object') {
          continue;
        }

        // omit certain keys
        if (omitKeysFromSearch.indexOf(key) !== -1) {
          continue;
        }

        if (value && (value + '').toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
          indices.add(index);
        }
      }

      const tags: Tag[] = row.tags;
      if (tags) {
        tags.forEach((tag: Tag) => {
          const value = tag.value;
          if (value && (value + '').toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
            indices.add(index);
          }
        });
      }
    });
  }
  return indices;
};

const FallbackEntityRenderer = (props: { name: string }) => (
  <span className={'ts-api-name'} title={props.name}>{props.name}</span>
);

const omitKeysFromSearch: string[] = ["traceSlowestSpanId"];

export default React.memo(Waterfall);
