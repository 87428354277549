import { cloneDeep, merge, values, random, keys, isEqual } from "lodash";
import { RelativeDurationType, IncDuration } from "@inception/ui";
import {
  AlertAnomTimeStartCondition, AlertAnomCountStartCondition, ManualInsightConfig, CompareConfig, CompareConfigType, MetricDefinition,
  NotifyActionConfig, StaticCompareConfigDefinition, TimeShiftCompareConfigDefinition, UpdateActionChannelConfig,
  UpdateActionRuleConfig, PeerGroupTabConfig, PeerGroupCompareConfigDefinition, BizFieldInfo, TagFilter, AnomalyDetectionConfig,
  BaselineCompareConfigDefinition, InsightsConfigTabs, TypedObject, EntityOpToTraceQueryOpMap, MetricAndSliceRecord, SliceSet,
  UserServiceFieldSliceSet, UserServiceFieldSlice, Slice, WidgetConfigUtils, ActionConfigTestPayload, FILL_VAL_DEFAULT,
  TraceUIAggregation, MetricSourceType
} from "../../platform/services/api/explore";
import { ActionSourceTypes, ActionCategoryType, DeleteActionConfig, PrimType, TypeObjectValKeys, UIIntegrationActionConfig } from "../../platform/services/api/operationalise";
import { dateTime, generateId, getAggregationFromAggrValue, getAggregationUIOption } from "../core";
import { MetricAggregatorExpanded } from "../core/data/types/MetricTypes";
import { getIncidentConfigDtoFromConfig } from "./alertUtils";
import { ENTITY_TAG } from "./MetricNameUtils";
import { getKeyByValue, isJsonString } from "./Utils";
import { eventFieldUtils } from "./EventFieldUtils";

const getRollingAggrFunction = (): TraceUIAggregation => ({
  aggrType: "avg",
});

export const defaultManualConfig: ManualInsightConfig =  {
  boundValue: 100,
  boundType: "below",
  dsFreqSecs: 60,
  violationCount: 5,
  totalTimeOrPoints: 25,
  violationType: "minutes",
  thresholdType: "number",
  dsFunc: null,
  fillValue: FILL_VAL_DEFAULT,
  rollingAggFunc: getRollingAggrFunction(),
  rollingFreq: 1,
  rollingDurationType: RelativeDurationType?.MINUTES
};

export const defaultPeerGroupConfig: PeerGroupTabConfig = {
  boundType: {
    label: "Below",
    value: "below"
  },
  boundValue: 40,
  dsFreqSecs: 60,
  violationCount: 5,
  totalTimeOrPoints: 25,
  violationType: "minutes",
  aggrValue: {
    aggrType: "avg"
  },
  fields: [],
  dsFunc: null,
  rollingAggFunc: getRollingAggrFunction(),
  rollingFreq: 1,
  rollingDurationType: RelativeDurationType?.MINUTES
};

export const defaultAnomalyDetectionConfig: AnomalyDetectionConfig = {
  boundType: "aboveOrBelow",
  pointsValue: 15,
  violationCount: 5,
  violationType: "minutes",
  seasonSecs: "auto",
  ashFreq: "auto",
  fillValue: FILL_VAL_DEFAULT,
  rollingAggFunc: getRollingAggrFunction(),
  rollingFreq: 1,
  rollingDurationType: RelativeDurationType?.MINUTES
};

export const getDefaultCompareConfig = (id: string, compareName: string): CompareConfig => ({
  type: CompareConfigType.static,
  compareName,
  compareConfigId: id,
  staticCompareConfig: {
    "upperBound": 100
  },
  incidentConfig: {
    id: generateId(),
    evaluationCriteria: "{\"incidents\":{\"start-triggers\":{\"or\":[{\"anom-time\":\"25m\",\"time\":\"25m\",\"anom-sign\":\"+\",\"type\":\"anom-time\"}]}}}"
  },
  dsFunction: "",
  dsFreqSecs: 300,
  rollingFreqSecs: 60,
  rollingFunction: "",
  includes: [],
  sliceSet: null,
  usfSlices: null
});

export const getInsightTabFromCompareConfig = (compareConfig: CompareConfig): InsightsConfigTabs => compareConfig.type === CompareConfigType.baseline
  ? "anomalyDetection"
  : compareConfig.type === CompareConfigType.peer
    ? "peerGroup"
    : "manual";

const getRollingWindowAggrFromRollingFunc = (rf: MetricAggregatorExpanded): TraceUIAggregation => {
  const rollingWindowAggr = getAggregationFromAggrValue(rf);
  if (rollingWindowAggr) {
    return {
      aggrType: rollingWindowAggr.type,
      fraction: rollingWindowAggr.fractions,
    };
  }
  return getRollingAggrFunction();
};

const getDsFreqMinsFromSecs = (dsFreqSecs: number) => {
  const secs = dsFreqSecs !== 0 ? dsFreqSecs : 60;
  const minutes = Math.round(secs / 60);
  return minutes > 0 ? minutes : 1;
};

const getManualInsightConfig = (compareConfig: CompareConfig): ManualInsightConfig => {
  const insightConfig = { ...defaultManualConfig };
  const incidentConfig = getIncidentConfigDtoFromConfig(compareConfig.incidentConfig);
  const startTrigger = incidentConfig.startTriggers[0];

  const {
    lowerThresholdMultiplier,
    upperThresholdMultiplier
  } = compareConfig["incidentConfig"];
  const { dsFreqSecs, dsFunction } = compareConfig;

  if (compareConfig.type === CompareConfigType.static) {
    const staticConfig = compareConfig.staticCompareConfig;
    insightConfig.thresholdType = "number";

    const isAboveBoundType = startTrigger['anom-sign'] === "+" || isEqual(staticConfig.lowerBound?.toString(), Number.MIN_SAFE_INTEGER.toString());
    const isBelowBoundType = startTrigger["anom-sign"] === "-" || isEqual(staticConfig.upperBound?.toString(), Number.MAX_SAFE_INTEGER.toString());
    if (isAboveBoundType) {
      insightConfig.boundType = "above";
      insightConfig.boundValue = staticConfig.upperBound;
    } else if (isBelowBoundType) {
      insightConfig.boundType = "below";
      insightConfig.boundValue = staticConfig.lowerBound;
    } else {
      insightConfig.boundType = "below";
      insightConfig.boundValue = 100;
    }

  } else if (compareConfig.type === CompareConfigType.timeshift) {
    insightConfig.thresholdType = "percentage";
    insightConfig.shiftSecs = compareConfig.timeShiftCompareConfig.shiftSecs;
    const isBelowBoundType = startTrigger['anom-sign'] === "-" || lowerThresholdMultiplier;
    const isAboveBoundType = startTrigger['anom-sign'] === "+" || upperThresholdMultiplier;
    if (isBelowBoundType) {
      insightConfig.boundType = "below";
      insightConfig.boundValue = lowerThresholdMultiplier * 100;
    } else if (isAboveBoundType) {
      insightConfig.boundType = "above";
      insightConfig.boundValue = upperThresholdMultiplier * 100;
    } else {
      insightConfig.boundType = "above";
      insightConfig.boundValue = 25;
    }
  }

  const dsFreqMins = getDsFreqMinsFromSecs(dsFreqSecs);
  const dsFuncAggr = getAggregationFromAggrValue(
    dsFunction as MetricAggregatorExpanded
  );
  if (dsFuncAggr) {
    insightConfig.dsFunc = {
      aggrType: dsFuncAggr.type,
      fraction: dsFuncAggr.fractions
    };
  }

  const {
    duration,
    durationType
  } = getRollingFreqAndDurationType(compareConfig.rollingFreqSecs);


  insightConfig.rollingFreq = duration;
  insightConfig.rollingDurationType = durationType;

  insightConfig.rollingAggFunc = getRollingWindowAggrFromRollingFunc(
    compareConfig.rollingFunction as MetricAggregatorExpanded
  );

  insightConfig.fillValue = compareConfig.mqProps?.["fill.value"] || FILL_VAL_DEFAULT;

  if (incidentConfig.startTriggers[0]?.type === 'anom-time') {
    const trigger = startTrigger as AlertAnomTimeStartCondition;
    const anomTime = parseInt(trigger["anom-time"], 10);
    const totalTimeOrPoints = parseInt(trigger.time, 10);
    return {
      ...insightConfig,
      dsFreqSecs: compareConfig.dsFreqSecs,
      violationCount: Math.round(anomTime / dsFreqMins),
      totalTimeOrPoints: totalTimeOrPoints,
      violationType: "minutes",
    };
  } else {
    const trigger = startTrigger as AlertAnomCountStartCondition;
    return {
      ...insightConfig,
      dsFreqSecs: compareConfig.dsFreqSecs,
      violationCount: parseInt(trigger["anom-count"], 10),
      totalTimeOrPoints: parseInt(trigger["total-points"], 10),
      violationType: "points"
    };
  }
};

const getPeerGroupTagConfig = (compareConfig: PeerGroupCompareConfigDefinition): PeerGroupTabConfig => {
  const peerGrpInsightConfig = { ...defaultPeerGroupConfig };
  const incidentConfig = getIncidentConfigDtoFromConfig(compareConfig.incidentConfig);
  const startTrigger = incidentConfig.startTriggers[0];
  const { dsFreqSecs, dsFunction } = compareConfig;

  const {
    lowerThresholdMultiplier,
    upperThresholdMultiplier
  } = compareConfig["incidentConfig"];

  if (startTrigger["anom-sign"] === "+" || upperThresholdMultiplier) {
    peerGrpInsightConfig.boundType = {
      label: "Above",
      value: "above"
    };
    peerGrpInsightConfig.boundValue = (upperThresholdMultiplier * 100);
  } else if (startTrigger["anom-sign"] === "-" || lowerThresholdMultiplier) {
    peerGrpInsightConfig.boundType = {
      label: "Below",
      value: "below",
    };
    peerGrpInsightConfig.boundValue = lowerThresholdMultiplier * 100;
  } else {
    peerGrpInsightConfig.boundType = {
      label: "Above or Below",
      value: "aboveOrBelow",
    };
    peerGrpInsightConfig.boundValue = lowerThresholdMultiplier * 100;
  }
  const aggr = getAggregationFromAggrValue(compareConfig.peerGroupCompareConfig.aggFunc as MetricAggregatorExpanded);
  peerGrpInsightConfig.aggrValue = {
    aggrType: aggr.type,
    fraction: aggr.fractions
  };

  const dsFreqMins = getDsFreqMinsFromSecs(dsFreqSecs);
  peerGrpInsightConfig.fields = compareConfig.peerGroupCompareConfig.bizFields.map(x => ({
    type: "bizEntityField",
    payload: {
      bizField: x
    }
  }));

  const dsFuncAggr = getAggregationFromAggrValue(
    dsFunction as MetricAggregatorExpanded
  );
  if (dsFuncAggr) {
    peerGrpInsightConfig.dsFunc = {
      aggrType: dsFuncAggr.type,
      fraction: dsFuncAggr.fractions,
    };
  }

  peerGrpInsightConfig.rollingAggFunc = getRollingWindowAggrFromRollingFunc(compareConfig.rollingFunction as MetricAggregatorExpanded);

  const { duration, durationType } = getRollingFreqAndDurationType(
    compareConfig.rollingFreqSecs
  );
  peerGrpInsightConfig.rollingFreq = duration;
  peerGrpInsightConfig.rollingDurationType = durationType;

  if (startTrigger?.type === 'anom-time') {
    const trigger = startTrigger as AlertAnomTimeStartCondition;
    const anomTime = parseInt(trigger["anom-time"], 10);
    const totalTimeOrPoints = parseInt(trigger.time, 10);
    return {
      ...peerGrpInsightConfig,
      dsFreqSecs: compareConfig.dsFreqSecs,
      violationCount: Math.round(anomTime / dsFreqMins),
      totalTimeOrPoints: totalTimeOrPoints,
      violationType: "minutes",
    };
  } else {
    const trigger = startTrigger as AlertAnomCountStartCondition;
    return {
      ...peerGrpInsightConfig,
      dsFreqSecs: compareConfig.dsFreqSecs,
      violationCount: parseInt(trigger["anom-count"], 10),
      totalTimeOrPoints: parseInt(trigger["total-points"], 10),
      violationType: "points"
    };
  }
};

const getAnomalyDetectionConfigFromBaseLineConfig = (compareConfig: BaselineCompareConfigDefinition): AnomalyDetectionConfig => {
  const anomalyDetectionConfig = { ...defaultAnomalyDetectionConfig };

  anomalyDetectionConfig.seasonSecs = compareConfig.mqProps["season.secs"] || "auto";
  anomalyDetectionConfig.ashFreq = compareConfig.shConfig.type === "auto" ? "auto" : compareConfig.shConfig.frequency[0].toString();
  anomalyDetectionConfig.fillValue = compareConfig.mqProps["fill.value"] || FILL_VAL_DEFAULT;
  const { dsFreqSecs } = compareConfig;
  const dsFreqMins = getDsFreqMinsFromSecs(dsFreqSecs);

  anomalyDetectionConfig.rollingAggFunc = getRollingWindowAggrFromRollingFunc(compareConfig?.rollingFunction as MetricAggregatorExpanded);
  const { duration, durationType } = getRollingFreqAndDurationType(
    compareConfig.rollingFreqSecs
  );
  anomalyDetectionConfig.rollingFreq = duration;
  anomalyDetectionConfig.rollingDurationType = durationType;

  const response = getIncidentConfigDtoFromConfig(compareConfig.incidentConfig);
  if (response.startTriggers[0]?.type === 'anom-time') {
    const startTrigger = response.startTriggers[0] as AlertAnomTimeStartCondition;
    anomalyDetectionConfig.violationType = "minutes";
    const anomTime = parseInt(startTrigger["anom-time"], 10);
    const totalTimeOrPoints = parseInt(startTrigger.time, 10);
    const boundType = startTrigger["anom-sign"] === "+" ? "above" : startTrigger["anom-sign"] === "-" ? "below" : "aboveOrBelow";
    return {
      ...anomalyDetectionConfig,
      boundType,
      violationCount: Math.round(anomTime/dsFreqMins),
      pointsValue: totalTimeOrPoints
    };
  } else {
    const startTrigger = response.startTriggers[0] as AlertAnomCountStartCondition;
    const boundType = startTrigger["anom-sign"] === "+" ? "above" : startTrigger["anom-sign"] === "-" ? "below" : "aboveOrBelow";
    return {
      ...anomalyDetectionConfig,
      boundType,
      violationCount: parseInt(startTrigger["anom-count"], 10),
      pointsValue: parseInt(startTrigger["total-points"], 10),
      violationType: "points"
    };
  }
};

/**
 * @param compareConfig from operationalizeConfig
 * @returns {
 * boundValue(number)
 * boundType(string)
 * boundTime(Relative)
 * violationValue(number)
 * violationTime(Relative)
 */
export const extractInsightConfig = (compareConfig: CompareConfig): ManualInsightConfig | AnomalyDetectionConfig | PeerGroupTabConfig => {
  switch (compareConfig.type) {
    case CompareConfigType.static: {
      return getManualInsightConfig(compareConfig as StaticCompareConfigDefinition);
    }
    case CompareConfigType.timeshift: {
      return getManualInsightConfig(compareConfig as TimeShiftCompareConfigDefinition);
    }
    case CompareConfigType.baseline: {
      return getAnomalyDetectionConfigFromBaseLineConfig(compareConfig as BaselineCompareConfigDefinition);
    }
    case CompareConfigType.peer: {
      return getPeerGroupTagConfig(compareConfig as PeerGroupCompareConfigDefinition);
    }
    default: {
      return defaultManualConfig;
    }
  }
};

export const getActionConfigsForCompareConfig = (compareConfig: CompareConfig): Record<string, Record<string, NotifyActionConfig[]>> => {
  const actionConfigs = Object.values(compareConfig?.actionConfigs || []);
  if (actionConfigs.length) {
    return actionConfigs.reduce((acc: any, actionConfig: NotifyActionConfig) => {
      const channelConfig = actionConfig.actionChannelConfig;
      if (acc[channelConfig.actionType]) {
        if (acc[channelConfig.actionType][channelConfig.sourceTypeId]) {
          acc[channelConfig.actionType][channelConfig.sourceTypeId].push(actionConfig);
        } else {
          acc[channelConfig.actionType][channelConfig.sourceTypeId] = [];
          acc[channelConfig.actionType][channelConfig.sourceTypeId].push(actionConfig);
        }
      } else {
        acc[channelConfig.actionType] = {};
        acc[channelConfig.actionType][channelConfig.sourceTypeId] = [];
        acc[channelConfig.actionType][channelConfig.sourceTypeId].push(actionConfig);
      }
      return acc;
    }, {});
  }
  return {};
};

export const getActionConfigsForMetric = (metricObject: MetricDefinition) => {
  const compareConfigs = Object.entries(metricObject?.operationalizeConfig?.compareConfigs || {});
  if (compareConfigs.length) {
    return compareConfigs.reduce((acc, [compareId, compareConfigObject]) => {
      acc[compareId] = getActionConfigsForCompareConfig(compareConfigObject);
      return acc;
    }, {} as Record<string, any>);
  }
  return {};
};

export const extractActionConfigsForMetrics = (metrics: Record<string, MetricDefinition>) => {
  const metricsArr = Object.entries(metrics);
  const actionConfigsForMetrics = {
    actionConfigs: {}
  };
  if (metricsArr.length) {
    actionConfigsForMetrics.actionConfigs = metricsArr.reduce((acc, [metricId, metricObject]) => {
      acc[metricId] = getActionConfigsForMetric(metricObject);
      return acc;
    }, {} as Record<string, any>);
  }
  return actionConfigsForMetrics;
};

export const extractFirstActionConfigObject = (metricActionObject: Record<string, any>) => {
  const actionsConfigMetricObj = Object.values(metricActionObject)[0];
  const actionsConfigCompareObj = Object.values(actionsConfigMetricObj)[0];
  const result = actionsConfigCompareObj
    ? Object.values(actionsConfigCompareObj)[0]
    : {};
  return result;
};

//ideally this method should take metrics, compareConfigId, actionConfigId
//and add actionConfig to compareConfig object under a metric
export const setNewDefaultSlackActionConfig = (compareConfig: CompareConfig, payload: NotifyActionConfig) => {
  const compareObj = { ...compareConfig };
  const newPayload = {} as any;
  newPayload[payload.actionId] = {
    actionId: payload.actionId,
    ...payload
  } as NotifyActionConfig;
  compareObj["actionConfigs"] = compareObj["actionConfigs"] ? merge(compareObj["actionConfigs"], newPayload) : newPayload;
  return compareObj;
};

//ideally this method should take metrics, compareConfigId, actionConfigId
//and add actionConfig to compareConfig object under a metric
export const setNewDefaultActionConfig = (compareConfig: CompareConfig, payload: NotifyActionConfig) => {
  const compareObj = { ...compareConfig };
  const newPayload: Record<string, NotifyActionConfig> = {
    [payload.actionId]: {
      actionId: payload.actionId,
      ...payload,
    },
  };

  compareObj["actionConfigs"] = compareObj["actionConfigs"] ? merge(compareObj["actionConfigs"], newPayload) : newPayload;
  return compareObj;
};

const getEvaluationCriteria = (
  violationType: string, totalTimeOrPoints: number, dsFreqMins: number, violationCount: number, boundType: AnomalyDetectionConfig["boundType"]
) => {
  const anomTime = `${dsFreqMins * violationCount}m`;
  const time = `${totalTimeOrPoints}m`;
  const appendAnomSign = (obj: Record<string, string | number>) => {
    if (boundType === "above") {
      obj["anom-sign"] = "+";
    } else if (boundType === "below") {
      obj["anom-sign"] = "-";
    }
  };

  let evaluationCriteria = null;
  if (violationType === "minutes") {
    const val: Record<string,string> = {
      "anom-time": anomTime,
      time,
      type: "anom-time",
    };
    appendAnomSign(val);
    evaluationCriteria = {
      incidents: {
        "start-triggers": {
          or: [
            {
              ...val
            },
          ],
        },
      },
    };
  } else {
    const val: Record<string, string | number> = {
      "anom-count": violationCount,
      "total-points": totalTimeOrPoints,
      type: "anom-count",
    };
    appendAnomSign(val);
    evaluationCriteria = {
      incidents: {
        "start-triggers": {
          or: [
            {
              ...val
            }
          ]
        }
      }
    };
  }

  return evaluationCriteria;
};

const getRollingFreqSeconds = (rollingFreq: number, rollingDurationType: IncDuration["durationType"]) => {
  switch (rollingDurationType) {
    case RelativeDurationType.SECONDS: {
      return rollingFreq;
    }
    case RelativeDurationType.MINUTES: {
      return rollingFreq * 60;
    }
    case RelativeDurationType.HOURS: {
      return rollingFreq * 60 * 60;
    }
    case RelativeDurationType.DAYS: {
      return rollingFreq * 60 * 60 * 24;
    }
    case RelativeDurationType.WEEKS: {
      return rollingFreq * 60 * 60 * 24 * 7;
    }
    case RelativeDurationType.MONTHS: {
      return rollingFreq * 60 * 60 * 24 * 7 * 30;
    }
  }
};

const getRollingFreqAndDurationType = (rollingFreqSecs: number): IncDuration => {
  const months = Math.floor(rollingFreqSecs / (3600 * 24 * 30));
  const weeks = Math.floor(rollingFreqSecs / (3600 * 24 * 7));
  const days = Math.floor(rollingFreqSecs / (3600 * 24));
  const hours = Math.floor((rollingFreqSecs % (3600 * 24)) / 3600);
  const minutes = Math.floor((rollingFreqSecs % 3600) / 60);

  if (months > 0) {
    return {
      duration: months,
      durationType: RelativeDurationType.MONTHS,
    };
  } else if (weeks > 0) {
    return {
      duration: weeks,
      durationType: RelativeDurationType.WEEKS,
    };
  } else if (days > 0) {
    return {
      duration: days,
      durationType: RelativeDurationType.DAYS,
    };
  } else if (hours > 0) {
    return {
      duration: hours,
      durationType: RelativeDurationType.HOURS,
    };
  } else if (minutes > 0) {
    return {
      duration: minutes,
      durationType: RelativeDurationType.MINUTES,
    };
  } else {
    return {
      duration: 1,
      durationType: RelativeDurationType.MINUTES
    };
  }
};

export const getCompareConfigFromManualInsightConfig = (payload: ManualInsightConfig, id: string,
  compareName: string, filters?: TagFilter[][]) => {
  const {
    boundType,
    boundValue,
    dsFreqSecs,
    totalTimeOrPoints,
    violationCount,
    violationType,
    thresholdType,
    shiftSecs,
    dsFunc,
    fillValue,
    rollingFreq,
    rollingDurationType,
    rollingAggFunc
  } = payload;

  const evaluationCriteria = getEvaluationCriteria(
    violationType,
    totalTimeOrPoints,
    Math.round(dsFreqSecs / 60),
    violationCount,
    boundType as AnomalyDetectionConfig["boundType"]
  );

  const {
    aggrType,
    fraction
  } = rollingAggFunc || {};

  const rollingFunction = getAggregationUIOption(aggrType, fraction)?.value || "";

  const compareConfig: Partial<StaticCompareConfigDefinition | TimeShiftCompareConfigDefinition> = {
    compareConfigId: id,
    compareName,
    type: thresholdType === "number" ? CompareConfigType.static : CompareConfigType.timeshift,
    dsFreqSecs,
    incidentConfig: {
      id: generateId(),
      evaluationCriteria: JSON.stringify(evaluationCriteria)
    },
    mqProps: {},
    dsFunction: dsFunc?.aggrType ? getAggregationUIOption(dsFunc.aggrType, dsFunc.fraction).value : "",
    includes: filters?.map(x => ({
      filters: x
    })),
    rollingFunction: rollingFunction,
    rollingFreqSecs: getRollingFreqSeconds(rollingFreq, rollingDurationType)
  };

  compareConfig.mqProps["fill.value"] = fillValue;

  if (thresholdType === "number") {
    compareConfig["type"] = CompareConfigType.static;
    const staticCompare = compareConfig as StaticCompareConfigDefinition;
    if (boundType === "above") {
      staticCompare['staticCompareConfig'] = {
        upperBound: boundValue,
        lowerBound: Number.MIN_SAFE_INTEGER
      };
    } else {
      staticCompare['staticCompareConfig'] = {
        lowerBound: boundValue,
        upperBound: Number.MAX_SAFE_INTEGER
      };
    }
  } else {
    compareConfig["type"] = CompareConfigType.timeshift;
    const timeShiftCompare = compareConfig as TimeShiftCompareConfigDefinition;
    timeShiftCompare["timeShiftCompareConfig"] = {
      shiftSecs: shiftSecs
    };
    delete (compareConfig as any)["staticCompareConfig"];
    if (boundType === "above") {
      timeShiftCompare["incidentConfig"].upperThresholdMultiplier = boundValue / 100;
    } else {
      timeShiftCompare["incidentConfig"].lowerThresholdMultiplier = boundValue / 100;
    }
  }

  return compareConfig as StaticCompareConfigDefinition | TimeShiftCompareConfigDefinition;
};

export const getCompareConfigFromPeerGroupInsight = (payload: PeerGroupTabConfig, id: string,
  compareName: string, filters?: TagFilter[][]) => {
  const {
    boundType,
    boundValue,
    dsFreqSecs,
    totalTimeOrPoints,
    violationCount,
    aggrValue,
    fields,
    violationType,
    dsFunc,
    rollingAggFunc,
    rollingFreq,
    rollingDurationType,
  } = payload;

  const evaluationCriteria = getEvaluationCriteria(
    violationType,
    totalTimeOrPoints,
    Math.round(dsFreqSecs / 60),
    violationCount,
    boundType.value as AnomalyDetectionConfig["boundType"]
  );
  const { aggrType, fraction } = rollingAggFunc || {};
  const rollingFunction = getAggregationUIOption(aggrType, fraction)?.value || "";

  const newCompareConfig: PeerGroupCompareConfigDefinition = {
    compareName,
    compareConfigId: id,
    type: CompareConfigType.peer,
    dsFreqSecs,
    incidentConfig: {
      id: generateId(),
      evaluationCriteria: JSON.stringify(evaluationCriteria)
    },
    peerGroupCompareConfig: {
      aggFunc: getAggregationUIOption(aggrValue.aggrType, aggrValue.fraction).value,
      bizFields: fields.map(x => (x.payload as BizFieldInfo).bizField)
    },
    dsFunction: dsFunc?.aggrType ? getAggregationUIOption(dsFunc.aggrType, dsFunc.fraction).value : "",
    includes: filters.map(x => ({
      filters: x
    })),
    rollingFunction: rollingFunction,
    rollingFreqSecs: getRollingFreqSeconds(rollingFreq, rollingDurationType)
  };

  if (boundType.value === "above") {
    newCompareConfig["incidentConfig"].upperThresholdMultiplier = boundValue/ 100;
  } else if (boundType.value === "below") {
    newCompareConfig["incidentConfig"].lowerThresholdMultiplier = boundValue/ 100;
  } else {
    newCompareConfig["incidentConfig"].lowerThresholdMultiplier = boundValue/ 100;
    newCompareConfig["incidentConfig"].upperThresholdMultiplier = boundValue/ 100;
  }

  return newCompareConfig;
};

export const getCompareConfigFromAnomalyDetectionConfig = (config: AnomalyDetectionConfig, id: string,
  compareName: string, filters?: TagFilter[][]) => {
  const dsFreqSecs = 60;
  const {
    boundType,
    pointsValue,
    violationCount,
    fillValue,
    seasonSecs,
    ashFreq,
    violationType,
    rollingAggFunc,
    rollingFreq,
    rollingDurationType
  } = config;

  const rollingFunction = rollingAggFunc?.aggrType ?  getAggregationUIOption(rollingAggFunc.aggrType, rollingAggFunc.fraction).value : "";
  const evaluationCriteria = getEvaluationCriteria(
    violationType,
    pointsValue,
    1,
    violationCount,
    boundType
  );

  const newCompareConfig: BaselineCompareConfigDefinition = {
    compareConfigId: id,
    type: CompareConfigType.baseline,
    incidentConfig: {
      id: generateId(),
      evaluationCriteria: JSON.stringify(evaluationCriteria),
    },
    mqProps: {},
    shConfig: {
      type: ashFreq === "auto" ? "auto" : "specified",
      frequency: [],
    },
    includes: filters.map((x) => ({
      filters: x,
    })),
    compareName,
    rollingFunction: rollingFunction,
    rollingFreqSecs: getRollingFreqSeconds(rollingFreq, rollingDurationType),
    dsFreqSecs,
  };

  if (seasonSecs !== "auto") {
    newCompareConfig.mqProps["season.secs"] = seasonSecs;
  }

  newCompareConfig.mqProps["fill.value"] = fillValue;

  if (ashFreq !== "auto") {
    newCompareConfig.shConfig.frequency.push(Number(ashFreq));
  }

  return newCompareConfig;
};

export const deleteActionConfig = (compareConfig: CompareConfig, payload: DeleteActionConfig) => {
  const compareObj = { ...compareConfig };
  const actionConfigs = compareObj["actionConfigs"];
  const actionId = payload.actionId;
  delete actionConfigs[actionId];
  return compareObj;
};

//ideally this method should take metrics, compareConfigId, actionConfigId
//and add actionConfig to compareConfig object under a metric
export const updateNotifySlackActionRuleConfig = (compareConfig: CompareConfig, payload: UpdateActionRuleConfig) => {
  const compareObj = { ...compareConfig };
  const actionConfigFromCompareConfig = compareConfig["actionConfigs"][
    payload.actionId
  ] as NotifyActionConfig;
  //find which condition to find and push the expression and when to add/replace expression
  actionConfigFromCompareConfig.actionRuleConfig.conditions[0].expressions.length = 0;
  actionConfigFromCompareConfig.actionRuleConfig.conditions[0].expressions = payload.expressions;
  return compareObj;
};

const updateActionRuleConfigOperators = (actionConfig: NotifyActionConfig) => {
  const ruleConfig = actionConfig.actionRuleConfig;
  if (ruleConfig.conditions?.length > 0 && ruleConfig.conditions[0].expressions?.length > 0) {
    ruleConfig.conditions[0].expressions = ruleConfig.conditions[0].expressions.filter(
      (x) => Boolean(x.bizField) && (x.value || x.values.length > 0)
    );
    if (ruleConfig?.conditions[0]?.expressions?.length === 0) {
      actionConfig.actionRuleConfig = {};
      return;
    }
    ruleConfig.conditions[0].expressions.forEach((x) => {
    // todo: fix this when proto of the entityOP and TraceQueryOP get merged
    // this code is only used as a work around to support traceQueryOperators in BizFeildPredicate
      x.op = EntityOpToTraceQueryOpMap[x.op] as any;
    });
  }
};

export const getTestActionPayload = (compareConfig: CompareConfig,
  actionId: string, widgetId: string, metricId: string, bizEntityType: string): ActionConfigTestPayload => {

  const clonedConfig = filterActionConfigs(compareConfig);
  const actionConfigObj = clonedConfig.actionConfigs[actionId];

  return {
    actionConfig: actionConfigObj,
    compareConfig: clonedConfig,
    widgetId: widgetId,
    metricId,
    bizEntityType
  };
};

export const filterActionConfigs = (compareConfig: CompareConfig): CompareConfig => {
  const clonedConfig = cloneDeep(compareConfig);
  const actionConfigs = clonedConfig.actionConfigs;
  values(actionConfigs).forEach(x => {
    updateActionRuleConfigOperators(x);
  });

  clonedConfig.includes = clonedConfig.includes.filter(x => (x.filters.filter(filter => filter.tagValues.length > 0)).length > 0);
  clonedConfig.includes.forEach((x) => {
    x.filters = x.filters.filter(filter => filter.tagValues.length > 0);
  });
  return clonedConfig;
};

export const updateNotifySlackActionChannelConfig = (compareConfig: CompareConfig, payload: UpdateActionChannelConfig) => {
  const compareObj = { ...compareConfig };
  const actionConfigFromCompareConfig = compareObj["actionConfigs"][payload.actionId] as NotifyActionConfig;
  //find which condition to find and push the expression and when to add/replace expression
  actionConfigFromCompareConfig.actionChannelConfig = payload.template;
  return compareObj;
};

export const getTypedObjValueKeyBasedOnType = (type: PrimType): TypeObjectValKeys => {
  switch (type) {
    case "_str": {
      return "stringVal";
    }
    case "_bool": {
      return "booleanVal";
    }
    case "_date": {
      return "dateVal";
    }
    case "_datetime": {
      return "dateTimeVal";
    }
    case "_double": {
      return "doubleVal";
    }
    case "_long": {
      return "longVal";
    }
    case "_map": {
      return "mapValue";
    }
    case "_set": {
      return "setValue";
    }
  }
};

export const getTypeFromPrimType = (type: PrimType) => {
  switch (type) {
    case "_str":
      return "string";
    case "_datetime":
      return "string";
    case "_date":
      return "string";
    case "_bool":
      return "boolean";
    case "_double":
      return "integer";
    case "_long":
      return "integer";
    case "_map":
    case "_set":
    case "_objectmap":
      return "object";
    default:
      return "string";
  }
};

export const getParamsFromIntegrationActionConfig = (
  uiIntegrationActionConfig: UIIntegrationActionConfig, actionTemplate?: Record<string, any>
) => {
  const params: Record<string, TypedObject> = {};
  const uiParams = uiIntegrationActionConfig.uiParams;
  keys(uiParams).forEach(x => {
    if (uiParams[x].visible) {
      const valKey = getTypedObjValueKeyBasedOnType(uiParams[x].type);
      if (actionTemplate && actionTemplate[x]) {
        params[x] = {
          type: uiParams[x].type,
          value: {
            [valKey]:
              uiParams[x].type === "_map"
                ? isJsonString(actionTemplate[x])
                  ? { entries: JSON.parse(actionTemplate[x]) }
                  : actionTemplate[x]
                : actionTemplate[x],
          },
        };
      } else {
        const key = getTypedObjValueKeyBasedOnType(uiParams[x].type);
        params[x] = {
          type: uiParams[x].type,
          value: {
            [valKey]: uiParams[x].defaultValue.value
              ? (uiParams[x].defaultValue.value[key] as any)
              : uiParams[x].type === "_map" ? {} : "",
          },
        };
      }
    }
  });
  return params;
};

export const updateActionConfigParams = (params: Record<string, TypedObject>, key: string, value: any) => {
  // const newParams = cloneDeep(params);

  const valKey = getTypedObjValueKeyBasedOnType(params[key].type);

  params[key].value = {
    [valKey]: value,
  };

  return params;
};

export const getActionTemplateFromParams = (
  params: Record<string, TypedObject>
) => {
  const data: Record<string, any> = {};

  keys(params).forEach((x) => {
    const key = getTypedObjValueKeyBasedOnType(params[x].type);
    if (key === "mapValue") {
      data[x] = params[x].value?.[key] ? JSON.stringify(params[x].value[key].entries) : "";
    } else {
      data[x] = params[x].value?.[key] ? params[x].value[key] || "" : "";
    }
  });

  return data;
};

export const validateSalesForceTask = (
  templateData: Record<string, any>
): boolean => {
  let isValid = true;

  values(templateData).forEach((x) => {
    if (!x) {
      isValid = false;
    }
  });
  return isValid;
};

export const validateOperationalizeActions = (actionConfigs: NotifyActionConfig[]): boolean => {
  const inValidConfig = actionConfigs.find(x =>
    x.actionChannelConfig.actionType === ActionCategoryType.AUTOMATE &&
      !validateSalesForceTask(
        getActionTemplateFromParams(x.actionChannelConfig.params)
      )
  );
  return !inValidConfig;
};


export const updateMockTimeSeriesBasedOnInsightConfig = (
  boundValue: number,
  boundType: string,
  dsFreqMins: number,
  violationCount: number,
  jsonPayload: any,
  seriesName?: string
) => {
  const payload = cloneDeep(jsonPayload);

  const series = [ ...jsonPayload.series ];
  const dataPointsLength = violationCount * 2;
  series[0].data = [[]];
  series[1].data = [[]];

  series[0].name = seriesName || series[0].label;

  const startTime = dateTime().subtract(dsFreqMins * violationCount * 2, "minutes");
  payload.options.xAxis.max = dateTime().unix();
  payload.options.xAxis.min = dateTime(startTime).unix();
  for (let i = 0; i<dataPointsLength; i++) {
    series[0].data[i] = [];
    series[1].data[i] = [];
    const timeSecs = dateTime(startTime)
      .add(dsFreqMins * i, "minutes")
      .unix();
    series[0].data[i][0] = timeSecs;
    series[1].data[i][0] = timeSecs;

    if (i >= Number(dataPointsLength/4) && i <= Number(dataPointsLength/2 * 1.5)) {
      let anomPoint = 0;
      if (boundType === "above") {
        anomPoint = random(boundValue, boundValue + (boundValue * Math.random()), true);
      } else {
        anomPoint = random(boundValue - (boundValue * Math.random()), boundValue, true);
      }
      series[0].data[i][1] = anomPoint;
      series[1].data[i][1] = anomPoint;
    } else {
      let point = 0;
      if (boundType === "above") {
        point = random(boundValue - (boundValue * Math.random()), boundValue, true);
      } else {
        point = random(boundValue, boundValue + (boundValue * Math.random()), true);
      }
      series[0].data[i][1] = point;
      series[1].data[i][1] = null;
    }
  }

  payload.properties.yAxis.plotLines[0].value = boundValue; //target line display
  payload.series = series;
  // console.log('payload is ', payload);
  return payload;
};

export const updatePeerGroupMockBasedOnInsightConfig = (
  boundPercent: number,
  boundType: string,
  dsFreqMins: number,
  violationCount: number,
  seriesName: string,
  jsonPayload: any
) => {
  const payload = cloneDeep(jsonPayload);
  const series = [...jsonPayload.series];
  const dataPointsLength = violationCount * 2;
  series[0].data = [[]];
  series[1].data = [[]];
  series[1].name = seriesName || series[1].label;

  const startTime = dateTime().subtract(
    dsFreqMins * violationCount * 2,
    "minutes"
  );
  payload.options.xAxis.max = dateTime().unix();
  payload.options.xAxis.min = dateTime(startTime).unix();
  const baseValue = 100;
  for (let i = 0; i < dataPointsLength; i++) {
    series[0].data[i] = [];
    series[1].data[i] = [];
    const timeSecs = dateTime(startTime)
      .add(dsFreqMins * i, "minutes")
      .unix();
    series[0].data[i][0] = timeSecs;
    series[1].data[i][0] = timeSecs;

    if ( i >= Number(dataPointsLength / 4) && i <= Number((dataPointsLength / 2) * 1.5)) {
      const point = random(baseValue, baseValue + 5, true);
      let anomPoint = 0;
      const randomPercent = random(boundPercent, boundPercent + 5);
      if (boundType === "above") {
        anomPoint = point * (randomPercent / 100 + 1);
      } else {
        anomPoint = point * (1 - boundPercent / 100);
      }
      series[0].data[i][1] = anomPoint;
      series[1].data[i][1] = point;
    } else {
      const point1 = random(baseValue, baseValue + 5, true);
      const point2 = random(baseValue, baseValue + 10, true);
      series[0].data[i][1] = point1;
      series[1].data[i][1] = point2;
    }
  }

  payload.series = series;
  payload.properties.yAxis.plotLines = null;
  // console.log('payload is ', payload);
  return payload;
};

export const getUpdatedAnomalyDetectionMockJson = (
  violationCount: number,
  boundType: string,
  jsonPayload: any,
  seriesName?: string
) => {
  const payload = cloneDeep(jsonPayload);

  const series = [ ...jsonPayload.series ];
  const dataPointsLength = violationCount * 4;
  series[0].data = [[]];
  series[1].data = [[]];
  series[2].data = [[]];

  series[0].name = seriesName || series[0].label;

  const startTime = dateTime().subtract(dataPointsLength, "minutes");
  const baseValue = 100;
  payload.options.xAxis.max = dateTime().unix();
  payload.options.xAxis.min = dateTime(startTime).unix();

  for (let i = 0; i<dataPointsLength; i++) {
    series[0].data[i] = [];
    series[1].data[i] = [];
    series[2].data[i] = [];
    const timeSecs = dateTime(startTime).add(i, "minutes")
      .unix();
    series[0].data[i][0] = timeSecs;
    series[1].data[i][0] = timeSecs;
    series[2].data[i][0] = timeSecs;

    const confidentBandVal1 = random(baseValue - 5, baseValue - 10, true);
    const confidentBandVal2 = random(baseValue + 5, baseValue + 10, true);

    series[2].data[i][1] = confidentBandVal1;
    series[2].data[i][2] = confidentBandVal2;

    const point = random(baseValue, baseValue + 5, true);
    series[0].data[i][1] = point;


    let anomPoint = 0;

    const posAnomPoint = random(baseValue + 20, baseValue + 15, true);
    const negAnomPoint = random(baseValue - 20, baseValue - 15, true);

    if (violationCount >= 3) {
      if (
        i >= Number(dataPointsLength / 5) * 1 &&
      i <= Number(dataPointsLength / 5) * 1 + Number(dataPointsLength / 5) + 1
      ) {
        anomPoint = negAnomPoint;
        series[0].data[i][1] = anomPoint;

        if (boundType === "above") {
          series[1].data[i][1] = null;
        } else if (boundType === "below") {
          series[1].data[i][1] = anomPoint;
        } else {
          series[1].data[i][1] = anomPoint;
        }

      } else if (
        i >= Number(dataPointsLength / 5) * 3 &&
      i <= Number(dataPointsLength / 5) * 3 + Number(dataPointsLength / 5) + 1
      ) {
        anomPoint = posAnomPoint;
        series[0].data[i][1] = anomPoint;

        if (boundType === "above") {
          series[1].data[i][1] = anomPoint;
        } else if (boundType === "below") {
          series[1].data[i][1] = null;
        } else {
          series[1].data[i][1] = anomPoint;
        }

      } else {
        series[1].data[i][1] = null;
      }
    } else {
      if (
        i >= Number(dataPointsLength / 4) &&
         i <= Number((dataPointsLength / 2) * 1.5)
      ) {
        let anomPoint = 0;

        const posAnomPoint = random(baseValue + 15, baseValue + 10, true);
        const negAnomPoint = random(baseValue - 15, baseValue - 10, true);

        if (boundType === "above") {
          anomPoint = posAnomPoint;
        } else if (boundType === "below") {
          anomPoint = negAnomPoint;
        } else {
          const bool = random(1, 10, false) % 2 === 0;
          anomPoint = bool ? posAnomPoint : negAnomPoint;
        }

        series[1].data[i][1] = anomPoint;
        series[0].data[i][1] = anomPoint;
      } else {
        series[1].data[i][1] = null;
      }
    }
  }

  payload.series = series;
  // console.log('payload is ', series);
  return payload;
};

export const getMetricAndSliceValue = (metricSliceRecords: MetricAndSliceRecord[], metricId: string, slices: string[]): MetricAndSliceRecord =>
  metricSliceRecords.find(msr => {
    const eSlices = WidgetConfigUtils.getTagNamesFromSlices(msr.sliceSet?.slices || []);
    const usfSlices = WidgetConfigUtils.getTagNamesFromSlices(msr.usfSlices?.slices || []);
    const sliceSetMatch = isEqual(eSlices.sort(), slices.sort());
    const usfSliceSetMatch = isEqual(usfSlices.sort(), slices.sort());
    return msr.metricId === metricId && (sliceSetMatch || usfSliceSetMatch);
  });


export const processCompareConfigForEdit = (metrics: Record<string, MetricDefinition>, compareId: string,
  metricAndSliceRecords: MetricAndSliceRecord[]) => {

  let selectedMetric: MetricDefinition = null;
  let compareConfig: CompareConfig = null;

  values(metrics).forEach(metric => {
    values(metric.operationalizeConfig?.compareConfigs).forEach(cc => {
      if (cc.compareConfigId === compareId) {
        selectedMetric = metric;
        compareConfig = cloneDeep(cc);
      }
    });
  });

  if (selectedMetric && compareConfig) {
    const metricAndSliceValue: MetricAndSliceRecord = metricAndSliceRecords.find(msr => {
      const checkIfBizMetricisAggregated = WidgetConfigUtils.checkIfBizMetricisAggregated(msr.metricDefinition);
      const sliceSetMatch = msr.sliceSet && compareConfig.sliceSet ?
        WidgetConfigUtils.compareSliceSets(
          msr.sliceSet, compareConfig.sliceSet, checkIfBizMetricisAggregated
        ) : false;
      const usfSliceSetMatch = msr.usfSlices && compareConfig.usfSlices ?
        WidgetConfigUtils.compareSliceSets(
          msr.usfSlices, compareConfig.usfSlices,checkIfBizMetricisAggregated
        ) : false;
      return msr.metricId === selectedMetric.id && (sliceSetMatch || usfSliceSetMatch);
    });

    compareConfig.compareConfigId = generateId();
    compareConfig.incidentConfig.id = generateId();

    const newActionConfigs: Record<string, NotifyActionConfig> = {};
    values(compareConfig.actionConfigs).forEach((x) => {
      const newId = generateId();
      x.actionId = newId;

      if (
        x.actionChannelConfig.actionType === ActionCategoryType.NOTIFY &&
        x.actionChannelConfig.sourceTypeId === ActionSourceTypes.SLACK &&
        x.actionRuleConfig?.conditions?.length === 0
      ) {
        x.actionRuleConfig = {
          conditions: [
            {
              expressions: [
                {
                  bizField: null,
                  op: "eq",
                  value: "",
                  values: []
                }
              ],
            },
          ],
        };
      } else {
        x.actionRuleConfig.conditions?.forEach(cnd => {
          cnd.expressions?.forEach(exp => {
            // todo: fix this when proto of the entityOP and TraceQueryOP get merged
            // this code is only used as a work around to support traceQueryOperators in BizFeildPredicate
            exp.op = getKeyByValue(exp.op, EntityOpToTraceQueryOpMap) as any;
          });
        });
      }
      newActionConfigs[newId] = { ...x };
    });

    compareConfig.actionConfigs = newActionConfigs;

    let tagFilterList: TagFilter[][] = [];
    const baseTagFilters: TagFilter[] = getTagFiltersFromMetricAndSliceValue(metricAndSliceValue);

    if (compareConfig.includes.length > 0) {
      for (let i = 0; i < compareConfig.includes.length; i++) {
        tagFilterList.push(cloneDeep(baseTagFilters));
      }

      compareConfig.includes.forEach((x, i) => {
        tagFilterList[i].forEach((tgf) => {
          x.filters.forEach((filter) => {
            if (tgf.fieldName === filter.fieldName) {
              tgf.tagValues = filter.tagValues;
            }
          });
        });
      });
    } else {
      tagFilterList = [baseTagFilters];
    }

    return {
      metricAndSliceValue,
      compareConfig,
      tagFilters: tagFilterList,
    };
  }
  return {
    metricAndSliceValue: null,
    compareConfig: null,
    tagFilters: []
  };
};

export const getTagFiltersFromUSFSliceSet = (
  sliceSet: UserServiceFieldSliceSet
): TagFilter[] => {
  const tagFilters: TagFilter[] = [];
  if (sliceSet?.slices?.length > 0) {
    (sliceSet as UserServiceFieldSliceSet)?.slices.forEach(
      (x: UserServiceFieldSlice) => {
        if (x.tagName !== ENTITY_TAG) {
          tagFilters.push({
            fieldName: x.userServiceField.fieldName,
            op: "in",
            tagName: x.tagName,
            tagValue: "",
            tagValues: [],
          });
        }
      }
    );
  }
  return tagFilters;
};

export const getTagFiltersFromMetricAndSliceValue = (msr: MetricAndSliceRecord) => {
  let baseTagFilters: TagFilter[] = [];
  if (msr.usfSlices) {
    baseTagFilters = getTagFiltersFromUSFSliceSet(msr.usfSlices);
  } else if (msr.sliceSet) {
    baseTagFilters = getTagFiltersFromSliceSet(msr.sliceSet);
  }
  return baseTagFilters;
};

export const getTagFiltersFromSliceSet = (sliceSet: SliceSet): TagFilter[] => {
  const tagFilters: TagFilter[] = [];
  if (sliceSet?.slices?.length > 0) {
    (sliceSet as SliceSet)?.slices.forEach((x: Slice) => {
      if (x.tagName !== ENTITY_TAG) {
        tagFilters.push({
          fieldName: x.fieldName,
          op: "in",
          tagName: x.tagName,
          tagValue: "",
          tagValues: [],
        });
      }
    });
  }
  return tagFilters;
};

export const getCompareConfigFromInsightsConfig = (config: PeerGroupTabConfig | AnomalyDetectionConfig | ManualInsightConfig,
  insightsTab: InsightsConfigTabs, compareConfigId: string, compareName: string, filters: TagFilter[][]): CompareConfig => {
  let compareConfig = null;
  if (insightsTab === "manual") {
    compareConfig = getCompareConfigFromManualInsightConfig(config as ManualInsightConfig, compareConfigId, compareName,
      filters);
  } else if (insightsTab === "peerGroup") {
    compareConfig = getCompareConfigFromPeerGroupInsight(
      config as PeerGroupTabConfig,
      compareConfigId,
      compareName, filters,
    );
  } else {
    compareConfig = getCompareConfigFromAnomalyDetectionConfig(
      config as AnomalyDetectionConfig,
      compareConfigId,
      compareName, filters,
    );
  }
  return compareConfig;
};

export const generateCompareName = (
  metricAndSliceValue: MetricAndSliceRecord, bizEntityType: string, metricName: string, cohortName = ""
): string => {
  const sliceLabel = ((metricAndSliceValue.sliceSet || metricAndSliceValue.usfSlices || {} as any).slices as any[] || []).map(s => s.tagName).join(",");
  let compareName = `${bizEntityType} > ${cohortName ? cohortName + " > " : ""} ${metricName} ${sliceLabel ? "> " + sliceLabel : ""}`;
  let i = 0;
  while (compareName.length > 100 && i < 3) {
    if (i === 0) {
      compareName = `${bizEntityType} > ${cohortName ? cohortName + " > ": ""} ${metricName}`;
    } else if (i === 1) {
      compareName = `${bizEntityType} > ${metricName}`;
    } else {
      compareName = metricName;
    }
    i++;
  }
  return compareName;
};

/**
 * this function validates the insight configs possible field values
 * @param config
 * @param insightsTab
 * @returns
 */

export const validateInsightConfig = (config: PeerGroupTabConfig | AnomalyDetectionConfig | ManualInsightConfig, insightsTab: InsightsConfigTabs) => {
  const result = {
    errMsgId: "",
    isValid: true
  };
  if (insightsTab === "peerGroup") {
    const dsFreqMins = Math.round((config as PeerGroupTabConfig).dsFreqSecs / 60);
    const totalTimeOrPoints = (config as PeerGroupTabConfig).violationType === "minutes" ?
      Math.round((config as PeerGroupTabConfig).totalTimeOrPoints / dsFreqMins)
      :
      (config as PeerGroupTabConfig).totalTimeOrPoints;
    if (config.violationCount > totalTimeOrPoints) {
      result.errMsgId =
        config.violationType === "points"
          ? "operationalise.violation.count.exceeds.total.count"
          : "operationalise.violation.count.exceeds.total.time";
      result.isValid = false;
    }
  } else if (insightsTab === "anomalyDetection") {
    const anomDetectionConfig = config as AnomalyDetectionConfig;
    const fillValue = anomDetectionConfig.fillValue;
    if (config.violationCount > anomDetectionConfig.pointsValue) {
      result.errMsgId = anomDetectionConfig.violationType === "points" ? "operationalise.violation.count.exceeds.total.count" : "operationalise.violation.count.exceeds.total.time";
      result.isValid = false;
    } else if (fillValue !== FILL_VAL_DEFAULT && isNaN(Number(fillValue))) {
      result.errMsgId = "operationalise.fill.value.error.msg";
      result.isValid = false;
    }
  } else {
    const dsFreqMins = Math.round((config as ManualInsightConfig).dsFreqSecs / 60);
    const manualConfig = config as ManualInsightConfig;
    const totalTimeOrPoitns = manualConfig.violationType === "minutes" ? Math.round(manualConfig.totalTimeOrPoints / dsFreqMins) : manualConfig.totalTimeOrPoints;
    if (manualConfig.violationCount > totalTimeOrPoitns) {
      result.errMsgId =
        manualConfig.violationType === "points"
          ? "operationalise.violation.count.exceeds.total.count"
          : "operationalise.violation.count.exceeds.total.time";
      result.isValid = false;
    }
  }
  return result;
};

export const getSliceLabelFromUserServiceFieldSliceSet = (SliceSet: UserServiceFieldSliceSet) => {
  let sliceLabel = "";
  const slices = SliceSet.slices.filter(x => x.tagName !== ENTITY_TAG);
  slices.forEach(s => {
    sliceLabel = `${sliceLabel ? sliceLabel + "," : sliceLabel} ${eventFieldUtils.removeFieldsPrefix(s.userServiceField.fieldName)}`;
  });
  return sliceLabel;
};

export const getMetricLabel = (metricDef: MetricDefinition, sourceType: MetricSourceType, usfSlices: UserServiceFieldSliceSet) => {
  let label = metricDef?.name || '';
  if (sourceType === "userServiceField" && usfSlices) {
    const sliceLabel = getSliceLabelFromUserServiceFieldSliceSet(usfSlices);
    if (sliceLabel) {
      label = `${label} by${sliceLabel}`;
    }
  }
  return label;
};
