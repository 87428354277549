import { logger } from "../logging/Logger";
import { ApptuitDatasource } from "../datasources/apptuit/ApptuitDatasource";
import { TypedObjectValue } from "./operationalise";
import datasourceApiManager from "./DatasourceApiService";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

interface ResponseStatus {
  status: string;
}

export type UpdateUserServiceEntityMetaPayload = {
  entityId: string;
  key: string;
  value: TypedObjectValue;
};

class EntityIngestApiService {
  readonly BASE_URL: string = "/entity-store-ingest/api/v1/";
  private datasource: ApptuitDatasource;

  async updateUserServiceEntityMeta(
    userServiceMetaData: UpdateUserServiceEntityMetaPayload
  ): Promise<Result<ResponseStatus>> {
    this.init();
    const url = this.getUrl("change/sysprop");
    const result: Result<ResponseStatus> = {
      data: { status: "" },
      error: false,
      message: "",
    };

    try {
      const response = await datasourceApiManager
        .getDefault()
        .post<ResponseStatus, UpdateUserServiceEntityMetaPayload>(
        url,
        userServiceMetaData
      );
      result.data = response.data;
      result.message = result.data.status;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
      logger.error(
        "EventApiService",
        "Error updating the user service entity meta",
        err
      );
    }

    return result;
  }

  private getUrl(url: string) {
    return this.BASE_URL + url;
  }

  private async init() {
    if (!this.datasource) {
      this.datasource = await datasourceApiManager.getDefault();
    }
  }
}

const entityIngestApiService = new EntityIngestApiService();
export default entityIngestApiService;
