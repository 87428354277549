import React, { useMemo } from "react";
import { Checkbox } from "antd";
import { omit, isEmpty, pick } from "lodash";
import { cx } from "emotion";
import { generate } from "shortid";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import IncToolTip from "../Tooltip/Tooltip";
import { IncFaIcon } from "../Icons";
import { ICheckboxProps, IncCheckboxProps } from "./types";

const omitProps = [
  'color',
  'label',
  'labelProps',
  'className',
  'name',
  'skipChangeOnLabelClick',
  'noImplicitHeight',
  'infoText',
  'infoTextAsLabel',
  'onChange'
];

const IncCheckbox = React.forwardRef<HTMLInputElement, IncCheckboxProps>((props, ref) => {
  const {
    label: labelStr,
    disabled = false,
    className: pClassName = '',
    labelProps = {},
    name: pName,
    required,
    skipChangeOnLabelClick,
    id: pId,
    noImplicitHeight = false,
    infoText = '',
    infoTextAsLabel = false,
    onChange
  } = props;

  const labelPlacement = labelProps.placement || 'start';
  const name = useMemo(() => pName || generate(), [pName]);
  const id = pId || name;

  const cbLabelClass = !isEmpty(labelStr) ? `checkbox--has-label-${labelPlacement}` : '';
  const cbClassName = cx(
    'checkbox',
    { 'checkbox--disabled': disabled },
    cbLabelClass
  );

  const labelClassName = `input-label ${disabled ? 'disabled' : ''}`;

  const onChangeInternal = (evt: CheckboxChangeEvent) => {
    if (onChange) {
      onChange(evt as unknown as React.ChangeEvent<HTMLInputElement>, evt.target.checked);
    }
  };

  const dataPropKeys = Object.keys(props).filter(prop => prop.startsWith('data-'));
  const dataProps = pick(props, dataPropKeys) as any;
  const checkboxProps: ICheckboxProps = omit(props, omitProps);
  const checkbox = <Checkbox
    className={cbClassName}
    name={name}
    ref={ref}
    {...checkboxProps}
    id={id}
    onChange={onChangeInternal}
  />;

  const requiredLabel = required ? <span className="inc-span-required-star">*</span> : null;
  const htmlFor = skipChangeOnLabelClick ? undefined : id;
  const labelContent = <>{labelStr}{requiredLabel}</>;

  const labelJsx = <>
    {labelStr && <>
      {!infoText && <label className={labelClassName} htmlFor={htmlFor}>
        {labelContent}
      </label>}

      {Boolean(infoText) && <>
        {infoTextAsLabel && <div className="inc-flex-column flex-gap-8">
          <label className={labelClassName} htmlFor={htmlFor}>
            {labelContent}
          </label>
          <div className="inc-flex-row inc-text-inactive inc-text-element-medium">
            {infoText}
          </div>
        </div>}

        {!infoTextAsLabel && <div className="inc-flex-row inc-flex-center-vertical flex-gap-8">
          <label className={labelClassName} htmlFor={htmlFor}>
            {labelContent}
          </label>
          <IncToolTip titleText={infoText}>
            <div className="inc-flex-row inc-text-inactive inc-text-element-medium">
              <IncFaIcon iconName="info-circle" />
            </div>
          </IncToolTip>
        </div>}
      </>}
    </>}
  </>;

  const className = cx("checkbox-container", {
    [pClassName]: !!pClassName,
    'no-implicit-height': noImplicitHeight || infoTextAsLabel
  });

  return <div className={className} {...dataProps}>
    {labelPlacement === 'start' ? labelJsx : checkbox}
    {labelPlacement === 'end' ? labelJsx : checkbox}
  </div>;
});

export default IncCheckbox;
