import { css } from "emotion";

import { InceptionTheme } from '../../';
import { getInceptionTheme } from "../../themes/ThemeProvider";

const getErrorStyles = ((theme: InceptionTheme = getInceptionTheme()) => {
  const { red } = theme.colors;

  return {
    errorContent: css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            min-height: 150px;
        `,
    errorImage: css`
            max-width: 100%;
        `,
    errorText: css`
            color: ${red.base};
            text-align: center;
            display: flex;
        `,
  };
});

export {
  getErrorStyles,
};
