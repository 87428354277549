import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

type NotifierType = "error" | "warning" | "success" | "info" | "default";

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notify = useCallback((message: string, variant: NotifierType) => {
    if (message) {
      enqueueSnackbar(message, { variant });
    }
  }, [enqueueSnackbar]);

  const notifyError = useCallback((message: string) => notify(message, 'error'), [notify]);
  const notifyWarning = useCallback((message: string) => notify(message, 'warning'), [notify]);
  const notifySuccess = useCallback((message: string) => notify(message, 'success'), [notify]);
  const notifyInfo = useCallback((message: string) => notify(message, 'info'), [notify]);
  const notifyLog = useCallback((message: string) => notify(message, 'default'), [notify]);

  const notifier = useMemo(() => ({
    notifyError,
    notifyWarning,
    notifySuccess,
    notifyLog,
    notifyInfo
  }), [notifyError, notifyInfo, notifyLog, notifySuccess, notifyWarning]);

  return notifier;
};

export { useNotifications };
