import { cx } from "emotion";
import { useTheme } from "emotion-theming";
import { isEmpty, omit } from "lodash";
import React from "react";
import Switch from "react-switch";
import { InceptionTheme } from "../../themes/types/theme";
import IncInfoIcon from "../Icons/IncInfoIcon";
import IncToolTip from "../Tooltip/Tooltip";
import IncToggleProps from "./types";

const omitProps = [
  'label',
  'className',
  'color',
  'ref',
  'helpText',
  'labelVariant'
];

const IncToggle: React.FC<IncToggleProps> = (props) => {
  const {
    label: labelStr,
    className = '',
    checked = false,
    helpText,
    onChange = () => { },
    variant = 'primary',
    labelVariant = 'default',
    required
  } = props;

  const theme: InceptionTheme = useTheme();

  const toggleClasses = cx({
    "toggle": true,
    "toggle-border": !checked
  });

  let colors: ToggleColors = getPrimaryColors(theme);
  if (variant === 'success') {
    colors = getSuccessColors(theme);
  }

  const toggleProps = omit(props, omitProps);
  const toggle = <Switch
    activeBoxShadow='none'
    checked
    checkedIcon={false}
    className={toggleClasses}
    handleDiameter={16}
    height={12}
    offColor={colors.offTrackColor}
    offHandleColor={colors.offHandleColor}
    onChange={(checked) => onChange(checked)}
    onColor={colors.onTrackColor}
    onHandleColor={colors.onHandleColor}
    uncheckedIcon={false}
    width={28}
    {...toggleProps}
  />;

  const labelClassName = cx("inc-label-common", {
    "with-info": !isEmpty(helpText),
    "card-header-label": labelVariant === "card-header"
  });
  const label = labelStr ? <label className={labelClassName}>{labelStr}</label> : null;

  return <div
    className={`toggle-container ${className}`}
    data-checked={checked}
    onClick={(e) => e.stopPropagation()}
  >
    <div className="inc-flex-row inc-flex-center-vertical marginRt6">
      {label}
      {required && <span className="inc-span-required-star marginLt2">*</span>}
      {(helpText) &&
        <IncToolTip
          placement="top-start"
          showArrow
          titleText={helpText}
        >
          <IncInfoIcon />
        </IncToolTip>
      }
    </div>
    {toggle}
  </div>;
};

export default IncToggle;


type ToggleColors = {
  onTrackColor: string;
  onHandleColor: string;
  offTrackColor: string;
  offHandleColor: string;
};

const getPrimaryColors = (theme: InceptionTheme): ToggleColors => ({
  onTrackColor: theme.inceptionColors.palette.B100,
  onHandleColor: theme.inceptionColors.palette.B500,
  offTrackColor: theme.inceptionColors.palette.N100,
  offHandleColor: theme.inceptionColors.palette.N25
});

const getSuccessColors = (theme: InceptionTheme): ToggleColors => ({
  onTrackColor: theme.inceptionColors.palette.G100,
  onHandleColor: theme.inceptionColors.palette.G500,
  offTrackColor: theme.inceptionColors.palette.N100,
  offHandleColor: theme.inceptionColors.palette.N25
});
