import React, { ReactElement, useMemo } from 'react';
import { components, OptionProps, PlaceholderProps, ValueContainerProps, IndicatorProps } from 'react-select';
import { css } from 'emotion';
import { getInceptionTheme } from '../../themes/ThemeProvider';
import { IncPill } from '../Pill';
import { SelectDropdownIndicator } from '../Select';
import { SelectionOption } from './types';

export const IncMultiSelectOptionRenderer = (props: OptionProps<SelectionOption, true>) => (
  <div>
    <components.Option {...props}>
      <div className="inc-flex-row inc-flex-center-vertical">
        <input checked={props.isSelected} onChange={() => null} type="checkbox" />
        <div className="paddingLt6" style={{ marginRight: 'auto' }}>{props.label}</div>
        {props.data.meta && <div className="paddingLt6 inc-text-element-medium"
          style={{ color: getInceptionTheme().inceptionColors.palette.N600 }}
        >
          {props.data.meta}
        </div>}
      </div>
    </components.Option>
  </div>
);

type VProps = ValueContainerProps<SelectionOption, true> & {
  hideOptionsCount?: boolean;
  showSelectedOptions?: string;
  showAsPills?: boolean;
};

export const IncMultiSelectValueContainerRenderer = React.memo(({ children, ...props }: VProps) => {
  const totalCount = props && props.options ? props.options.length - 1 : 0;
  const { hideOptionsCount = false, showSelectedOptions = false, showAsPills = false } = props;

  let actualNumSelected = 0;
  let computedNumSelected = 0;
  if (props && props.getValue()) {
    const count = props.getValue().length || 0;
    actualNumSelected = count;

    // if selected is 0, which means we are showing all the series.

    if (count === 0) {
      computedNumSelected = totalCount;
    } else if (count === props.options.length) {
      computedNumSelected = props.options.length - 1;
    } else {
      computedNumSelected = count;
    }
  }

  const placeholder = props.selectProps?.inputValue ? '' : props.selectProps?.placeholder;
  const placeholderClassName = css`
    position: absolute;
    color: ${getInceptionTheme().inceptionColors.palette.N300};
    left: 12px;
  `;

  const selectedCountClassName = css({
    padding: `4px 10px`,
    backgroundColor: `#eaeaea`,
    borderRadius: 4,
    fontSize: 12
  });

  const pillContainer = css({
    marginRight: `6px`,
    fontSize: 12,
    maxWidth: 100
  });


  const selectedOptionValue = useMemo(() => {
    if (showSelectedOptions) {
      const selectedValues = props.getValue ? props.getValue() : [];
      const labels = selectedValues.map((o) => o.label);
      const valsToShow = labels.slice(0, 2);
      const remVals = actualNumSelected - 2;
      if (showAsPills) {
        const pills = valsToShow.map((x) => (
          <IncPill
            className={pillContainer}
            key={x}
            label={x}
            labelClass={"text-ellipsis"}
          />
        ));
        const remValues = remVals > 0 ? (
          <IncPill
            className={pillContainer}
            label={`+ ${remVals} more`}
            labelClass={"text-ellipsis"}
          />
        ) : null;
        return <div className="inc-flex-row">{pills} {remValues}</div>;
      } else {
        let selectedText = valsToShow.join(", ");
        selectedText += remVals > 0 ? ` & ${remVals} more` : "";

        return <div className="inc-text-subtext-medium">{selectedText}</div>;
      }
    }
    return null;
  }, [actualNumSelected, pillContainer, props, showAsPills, showSelectedOptions]);

  const showPlaceholder = (!showSelectedOptions || (actualNumSelected === 0)) && Boolean(placeholder);

  return (
    <components.ValueContainer {...props}>
      {showPlaceholder && <div className={placeholderClassName}>{placeholder}</div>}
      {selectedOptionValue}
      {!hideOptionsCount && <components.Placeholder {...props as PlaceholderProps<SelectionOption, true>}>
        {props && <div className={selectedCountClassName}>
          {computedNumSelected} / {totalCount}
        </div>}
      </components.Placeholder>}

      {/* This is required to show the options when clicking on the dropdown and hide it when clicking outside */}
      {React.Children.map(children as ReactElement[], (child: ReactElement) => child && child.props.id && child.props.id.indexOf('-input') !== -1 ? child : null)}

    </components.ValueContainer>
  );
});

type CIProps = IndicatorProps<SelectionOption, true> & {
  extJsx?: JSX.Element;
  clearTextJsx?: JSX.Element;
};

export const IncMultiSelectClearIndicator = React.memo(({
  extJsx,
  clearTextJsx,
  children,
  ...props
}: CIProps) => (
  <>
    {Boolean(extJsx) && <div className="inc-flex-row inc-flex-center-vertical flex-gap-4">
      <components.ClearIndicator {...props}>
        {clearTextJsx || children}
      </components.ClearIndicator>
      {extJsx}
    </div>}
    {!extJsx && <components.ClearIndicator {...props}>
      {clearTextJsx || children}
    </components.ClearIndicator>}
  </>
));

type DIProps = IndicatorProps<SelectionOption, true> & {
  extJsx?: JSX.Element;
};

export const IncMultiSelectDropdownIndicator = React.memo(({
  extJsx
}: DIProps) => (
  <>
    {Boolean(extJsx) && <div className="inc-flex-row inc-flex-center-vertical flex-gap-4" onClick={(e) => e.stopPropagation()}>
      {extJsx}
      <SelectDropdownIndicator />
    </div>}
    {!extJsx && <SelectDropdownIndicator />}
  </>
));
