import { useMemo, useCallback } from "react";
import { InitialState, useDataFetch, FetchFn, FetchCallBackResult } from "../../../../core";
import { AlertFilterResponse, AlertFilterResponses, OpCreationConfig } from "../types";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";

export const useFetchIncidentsDataForOpConfig = (
  opCreationConfig: OpCreationConfig,
  startTimeMillis: number,
  endTimeMillis: number,
  lazyFetch = false,
  includeSnapshots = false,
  includeLookupData = false,
  includeAdditionalData = false,
  includeActions = false,
  generateDemoData = false
) => {
  const initState = useMemo<InitialState<AlertFilterResponse, string>>(() => ({
    data: null,
    error: null,
    isError: false,
    isFetching: !lazyFetch,
    isSuccess: false
  }), [lazyFetch]);

  const fetchFn = useCallback<FetchFn<AlertFilterResponse, string>>(async () => {
    const resData: FetchCallBackResult<AlertFilterResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await operationaliseV2ApiService.getIncidentsDataForOpConfig(
        opCreationConfig,
        startTimeMillis,
        endTimeMillis,
        includeSnapshots,
        includeLookupData,
        includeAdditionalData,
        includeActions,
        generateDemoData
      );

      if (error) {
        resData.error = typeof message !== 'string' ? "Error fetching incidents" : message;
        resData.isError = true;
      } else {
        resData.isSuccess = true;
        resData.data = Object.values(data?.alertFilterResponses || {})[0].alertFilterResponse;
      }
    } catch (err) {
      resData.error = err.toString();
      resData.isError = true;
    }

    return resData;
  }, [endTimeMillis, generateDemoData, includeActions, includeAdditionalData, includeLookupData, includeSnapshots, opCreationConfig, startTimeMillis]);


  return useDataFetch(fetchFn, initState, lazyFetch);
};
