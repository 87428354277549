import React, { useMemo } from "react";
import { IncFaIcon, IncFaIconName } from "../..";
import { Card } from "..";

interface DisplayCardProps {
  type: "square" | "rectangle";
  variant?: "light" | "dark";
  label: string;
  labelClass?: string;
  icon?: IncFaIconName | JSX.Element;
  iconPosition?: 'top' | 'center';
  iconSize?: 'small' | 'regular' | 'large';
  subText?: string;
  subTextColor?: string;
  subTextClass?: string;
  onClick?: () => void;
  additionalInfo?: JSX.Element | JSX.Element[];
  className?: string;
  focused?: boolean;
  cursorPointer?: boolean;
}

const DisplayCard: React.FC<DisplayCardProps> = (props: DisplayCardProps) => {
  const {
    type,
    label,
    subText,
    icon,
    additionalInfo,
    onClick,
    variant = "light",
    labelClass = "",
    subTextColor = "",
    subTextClass = "",
    iconPosition = "center",
    iconSize = "regular",
    className = "",
    focused = false,
    cursorPointer = true
  } = props;
  const iconClassNames = `${iconSize}-icon-container ${iconPosition==='center' ? 'inc-flex-center-vertical' : 'marginTp8'}`;

  const cardContent = useMemo(() => (
    <div className={`card-content ${type==='rectangle' && icon ? 'inc-flex-row' : 'inc-flex-column inc-flex-center-horizontal'}`}>
      {
        icon &&
              <div className={iconClassNames}>
                {typeof icon === 'string' ? <IncFaIcon className={`icon ${focused ? "icon-focused" : ""} icon-${iconSize}`} iconName={icon} /> : icon}
              </div>
      }
      <div className="inc-flex-column inc-flex-center-horizontal">
        <span className={`inc-text-body-medium label ${labelClass}`}>
          {label}
        </span>
        {
          subText &&
            <span className={`inc-text-subtext subtext ${subTextClass}`} style={{color: subTextColor}}>
              {subText}
            </span>
        }
        {
          additionalInfo &&
          <div className="additional-info">
            {additionalInfo}
          </div>
        }
      </div>
    </div>
  ),[type, icon, iconClassNames, focused, iconSize, labelClass, label, subText, subTextClass, subTextColor, additionalInfo]);

  const classes = `${
    type === "square"
      ? `square-container-${variant}`
      : `rectangle-container-${variant}`
  } ${className} ${focused ? "focused-display-card" : ""} ${
    cursorPointer ? "cursor-pointer" : ""
  }`;

  return (
    <Card classNames={[classes]} onClick={onClick}>
      {cardContent}
    </Card>
  );
};

export default DisplayCard;
