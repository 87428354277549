import { EntityPropertyValue } from "../../../../core";
import { ActionCategoryType, TemplateCanvas, TemplateSection } from "./ActionTypesV2";
import { ActionRunMode } from "./CommonTypesV2";
import { UIIntegrationActionConfig } from "./operationaliseTypes";

export enum ActionTriggerMode {
  on_incident = 'on_incident',
  on_schedule = 'on_schedule',
  on_event = 'on_event',
}

export interface ActionSourceType {
  sourceTypeId: string;
  name: string;
  icon: string;
  defaultRunMode: ActionRunMode;
  demo: boolean;
  noConnector: boolean;
  supportedActionRunModes: ActionRunMode[];
  supportsTestActionWithRealAlertOnly?: boolean;
  supportedActionTriggerModes: ActionTriggerMode[];
  defaultActionTriggerMode?: ActionTriggerMode;
}

export interface ActionCategory {
  actionCategoryType: ActionCategoryType;
  name: string;
  actionSourceTypes: ActionSourceType[];
}

export interface ActionCategoryList {
  actionCategories: ActionCategory[];
}

export interface ActionSourceTypeList {
  sourceTypes: ActionSourceType[];
}

export interface ActionRunModeLabels {
  bulk: string;
  loop: string;
}

export interface UIIntegrationActionConfigList {
  uiIntegrationActionConfigs: UIIntegrationActionConfig[];
  actionRunModeLabels: ActionRunModeLabels;
}

export interface ParamValue {
  id: string;
  name: string;
  props: Record<string, EntityPropertyValue>;
  alertTemplateDef: AlertTemplateDef;
}

export interface SingleParamValuesResponse {
  value: ParamValue[];
}

export interface AlertTemplateConfigList {
  alertTemplateConfig: AlertTemplateConfig[];
}

export interface AlertTemplateConfig {
  templateId: string;
  name: string;
  labels: Record<string, string>;
  alertTemplateDef: AlertTemplateDef;
}

export interface AlertTemplateDef {
  templateText: string;
  templateCanvas: TemplateCanvas;
  templateSection: TemplateSection;
}
