import { DataQueryRequest } from "../../../../api/types";
import { getUETableData } from "./UETableData";
import { getUETreemapMockData } from "./UETreeMapMockData";

export const getUEMockData = (request: DataQueryRequest<any>) => {
  const { requestId, targets } = request;
  const dataType = requestId.replace("UE-", "").split("-")[0];
  if (dataType === "table") {
    return getUETableData();
  } else {
    const isCompare = requestId.includes('compare');
    const target = targets[0];
    const fieldName = Object.keys(target.query.aggregations)[0];
    return getUETreemapMockData(fieldName, isCompare);
  }
};
