import { IncFaIconName } from "@inception/ui";
import { CSSProperties } from "react";

export enum Visualisations {
  pieChart = "pie-chart",
  timeseries = "timeseries",
  barChart = "bar-chart",
  gauge = "gauge",
  geoMap = "geo-map",
  treeMap = "tree-map",
  donut = "donut",
  heatMap = "heat-map",
  stackedBarChart = "stacked-bar-chart",
  singleStat = "single-stat",
  insights = "top-k",
  histogram = "histogram",
  sparkLine = "spark-line",
  table = "table"
}

export type IconInfo = {
  iconName: IncFaIconName;
  tooltip: string;
  style?: CSSProperties;
};

export const iconInfoMap: Record<Visualisations, IconInfo> = {
  [Visualisations.barChart]: {
    iconName: "chart-column",
    tooltip: "Bar chart"
  },
  [Visualisations.donut]: {
    iconName: "circle-notch",
    tooltip: "Donut"
  },
  [Visualisations.gauge]: {
    iconName: "circle-notch",
    tooltip: "Gauge",
    style: {
      clipPath: "polygon(0 0, 100% 0%, 100% 50%, 0 50%)",
      transform: "translateY(4px)"
    }
  },
  [Visualisations.geoMap]: {
    iconName: "map-location-dot",
    tooltip: "Geo map"
  },
  [Visualisations.pieChart]: {
    iconName: "chart-pie",
    tooltip: "Pie chart"
  },
  [Visualisations.timeseries]: {
    iconName: "chart-line",
    tooltip: "Timeseries"
  },
  [Visualisations.treeMap]: {
    iconName: "chart-tree-map",
    tooltip: "Tree map",
    style: {
      height: 16,
      marginLeft: - 4,
      marginRight: -2,
      transform: "scale(1.2)"
    }
  },
  [Visualisations.singleStat]: {
    iconName: "6",
    tooltip: 'Single Stat'
  },
  [Visualisations.heatMap]: {
    iconName: 'diagram-cells',
    tooltip: 'Heat map'
  },
  [Visualisations.stackedBarChart]: {
    iconName: 'bars-progress',
    tooltip: 'Stacked bar chart'
  },
  [Visualisations.insights]: {
    iconName: "table-list",
    tooltip: "Insights"
  },
  [Visualisations.histogram]: {
    iconName: "chart-column",
    tooltip: "Histogram"
  },
  [Visualisations.sparkLine]: {
    iconName: "chart-area",
    tooltip: "Spark line"
  },
  [Visualisations.table]: {
    iconName: "table",
    tooltip: "Table"
  }
};
