import { useCallback, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";

export const THEME_KEY = 'INC_THEME';

const useInceptionTheme = () => {
  const { getItem, setItem } = useLocalStorage();
  const inceptionTheme = useMemo(() => getItem(THEME_KEY) === 'light' ? 'light' : 'dark', [getItem]) as 'dark' | 'light';
  const setInceptionTheme = useCallback((theme: 'dark' | 'light') => setItem(THEME_KEY, theme), [setItem]);

  return {
    inceptionTheme,
    setInceptionTheme
  };
};

export default useInceptionTheme;
