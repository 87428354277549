import { useCallback } from "react";
import { DemoDataParams, ExploreUserService, useFetchExploreUserservices } from "../../services/api/explore";
import { useFetchUseCaseUnderlyingInfo } from "./useFetchUseCaseUnderlyingInfo";
import { useTenantConfig } from "./tenant-config";
import { useVerticalConfig } from "./vertical-config";


export const useFetchEventTypesForAddWidget = (demoDataParams?: DemoDataParams) => {
  const { tenantConfigState } = useTenantConfig();
  const { useNewNavigation } = tenantConfigState || {};

  const { verticalConfig } = useVerticalConfig();
  const { useCaseId } = verticalConfig;

  const {
    error: beUserservicesError,
    exploreUserservices,
    fetchUserserviceList,
    isError: isBEUserservicesError,
    isFetching: isBEUserservicesFetching
  } = useFetchExploreUserservices(demoDataParams);

  const {
    eventTypes,
    isFetching: ucETIsFetching,
    isSuccess: usETIsSuccess,
    fetchUnderlyingInfo
  } = useFetchUseCaseUnderlyingInfo();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts
  const fetchBeUserServicesAndUnderlyingInfo = useCallback((fromMillis: number, toMillis: number) => {
    fetchUnderlyingInfo();
  }, [fetchUnderlyingInfo]);

  return (useNewNavigation && useCaseId) ? {
    isFetching: ucETIsFetching,
    isError: !usETIsSuccess,
    error: '',
    exploreUserservices: eventTypes as ExploreUserService[],
    fetchExploreUserservices: fetchBeUserServicesAndUnderlyingInfo
  } : {
    exploreUserservices: exploreUserservices,
    isFetching: isBEUserservicesFetching,
    isError: isBEUserservicesError,
    error: beUserservicesError,
    fetchExploreUserservices: fetchUserserviceList
  };
};
