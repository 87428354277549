import { Entity, Property, EntityQueryPredicate, LogicalPredicate, EntityProjection } from "../../core";
import { isValidCohortId } from "../../utils/CohortUtils";
import { logger } from "../logging/Logger";
import { ApptuitDatasource } from "../datasources/apptuit/ApptuitDatasource";
import { getBizEntityUrlPrefix } from "../../utils";
import appConfig from "../../../appConfig";
import datasourceApiManager from "./DatasourceApiService";
import {
  EntityAggregationRequest, EntityAggregationResponse, EntityAggregationSuggestionResponse, InceptionResponse, EntitySearchResult,
  PropValue, EntitySearchResultEntry, EntityCountRequest, EntityCountResponse, ExportEntityCSVRequest, FieldValuesCompletionResponse
} from "./types";
import { EntitySearchRequest } from "./configuration/types/entity";
import { getMockAggregations, getMockSuggestions } from "./mock/CohortVariables";
import { isErrorResponse } from "./utils";
import { BizField } from "./explore";
import { request } from "./request";
import { SourceViewRequest, SourceViewResponse } from "./tenant-ai/types";

interface EntityStoreApiService {
  getSampleEntities: (entityType: string, fields: string[], startTime: number, endTime: number) => Promise<Entity[]>;
  suggestEntityAggregation: (startTime: number, endTime: number, entityType: string, cohortId: string,
    fields: string[], mock?: boolean) => Promise<InceptionResponse<EntityAggregationSuggestionResponse>>;
  getEntityAggregation: (startTime: number, endTime: number, entityType: string, cohortId: string,
    aggregations: EntityAggregationRequest[], mock?: boolean) => Promise<InceptionResponse<EntityAggregationResponse>>;
  searchEntities: (entityTypeId: string, startTime: number, endTime: number, cohortEntityId?: string, predicates?: EntityQueryPredicate[],
    logicalPredicate?: LogicalPredicate, fields?: string[], maxEntries?: number, entityIds?: string[]) => Promise<EntitySearchResultEntry[]>;
  searchEntitiesV2(entityTypeId: string, searchText: string ,startTime: number, endTime: number, cohortId: string): Promise<EntitySearchResultEntry[]>
  getEntityCount: (entityTypeId: string, startTime: number, endTime: number, cohortEntityId?: string) => Promise<number>;
  getEntitiesCsvUrl: (entityType: string, bizFields: BizField[], name: string, cohortId?: string) => Promise<string>;
  getFieldValues: (startTime: number, endTime: number, entityType: string, cohortId: string, fields: string[]) =>
    Promise<InceptionResponse<FieldValuesCompletionResponse>>;
  getFileSummaryContent(payload: SourceViewRequest):Promise<SourceViewResponse>;
}

class EntityStoreApiServiceImpl implements EntityStoreApiService {
  fetchEntityCSV: (entityType: string, bizFields: BizField[], name: string, cohortId?: string) => Promise<any>;
  readonly BASE_URL: string = '/entity-store/api/v1/entity';
  private datasource: ApptuitDatasource;

  async getSampleEntities(entityTypeId: string, fields: string[], startTime: number, endTime: number): Promise<Entity[]> {
    await this.init();
    const subUrl = "/search.json";
    const url = this.getUrl(subUrl, startTime, endTime);

    const projections: EntityProjection[] =
      fields.map((f) => ({
        fieldType: 'prop',
        name: f
      }));

    projections.push({
      fieldType: 'displayName',
      name: 'displayName'
    });

    try {
      const data: EntitySearchResult = await this.datasource.post(url, {
        entityFilter: {
          typeId: entityTypeId,
          projections
        }
      });

      // Return only maximum of 5
      const sampleEntities = data.data?.results?.slice(0, 5) || [];

      const entities: Entity[] = [];

      for (let i = 0; i < sampleEntities.length; ++i) {
        const se = sampleEntities[i];

        if (!se || !se.entity) {
          continue;
        }

        const dataTypes: string[] = ['stringVal', 'longVal', 'doubleVal', 'dateVal', 'setValue', 'booleanVal'];
        const properties: Property[] = [];

        if (se.entity.props) {
          for (const [propName, propValueWrapper] of Object.entries(se.entity.props)) {
            let propertyValue = null;

            // We don't know in the response what is the data type of the field, so iterate over the
            // datatypes array to see if there is a value

            for (let i = 0; i < dataTypes.length; ++i) {
              const key: keyof PropValue = dataTypes[i] as unknown as keyof PropValue;

              if (propValueWrapper[key]) {

                if (key === 'setValue') {
                  const setValues: any[] = (propValueWrapper[key] as any).values as any[];
                  propertyValue = setValues.join(',');
                } else {
                  propertyValue = propValueWrapper[key];
                }

                break;
              }
            }

            properties.push({
              name: propName,
              value: propertyValue as string
            });
          }
        }

        entities.push({
          id: se.entity.entityId,
          name: se.entity.name,
          properties
        });
      }

      return entities;
    } catch (e) {
      logger.error("EntityStoreApiServiceImpl", "Error while fetching sample entities", e);
    }

    return [];
  }

  async searchEntities(
    entityTypeId: string,
    startTime: number,
    endTime: number,
    cohortId = '',
    predicates: EntityQueryPredicate[] = [],
    logicalPredicate: LogicalPredicate = null,
    fields: string[] = [],
    maxEntries: number,
    entityIds: string[]
  ): Promise<EntitySearchResultEntry[]> {
    await this.init();
    const subUrl = "/search.json";
    const url = this.getUrl(subUrl, startTime, endTime);

    let projections: EntityProjection[] =
      fields.map((f) => ({
        fieldType: 'prop',
        name: f
      }));

    projections.push({
      fieldType: 'displayName',
      name: 'displayName'
    });

    if (fields.includes("*")) {
      projections = [{
        fieldType: 'prop',
        name: "*"
      }];
    }

    try {
      const req: EntitySearchRequest = {
        entityFilter: {
          typeId: entityTypeId,
          projections
        }
      };

      if (predicates?.length) {
        req.entityFilter.predicates = predicates;
      }

      const cId = this.getCohortId(cohortId);
      if (cId) {
        req.entityFilter.cohortId = cId;
      }

      if (logicalPredicate) {
        req.entityFilter.logicalPredicate = logicalPredicate;
      }

      if (maxEntries) {
        req.entityFilter.pageSize = maxEntries + 1;
      }

      if (entityIds) {
        req.entityFilter.entityIds = entityIds;
      }

      const {
        data: response
      } = await this.datasource.post<EntitySearchResult['data'], EntitySearchRequest>(url, req);
      return response?.results || [];
    } catch (e) {
      logger.error("EntityStoreApiServiceImpl", "Error while fetching sample entities", e);
    }

    return [];
  }

  async searchEntitiesV2(
    entityTypeId: string,
    searchText: string,
    startTime: number,
    endTime: number,
    cohortId = '',
  ): Promise<EntitySearchResultEntry[]> {
    await this.init();
    let url = `/api/bizEntity/${entityTypeId}/entities`;
    const anomShareId = appConfig.anomShareId;

    url += `?startTimeMillis=${startTime}&endTimeMillis=${endTime}`;
    url += `&filterText=${searchText}`;
    url += cohortId ? `&cohortId=${cohortId}` : "";
    url += anomShareId ? `&shareId=${anomShareId}` : "";

    try {
      if (anomShareId) {
        const newUrl = `${appConfig.apiDomainUrl}${url}`;
        const { data: response } = await request.get<EntitySearchResult["data"]>(newUrl);
        return response?.results || [];
      }

      const {
        data: response
      } = await this.datasource.get<EntitySearchResult['data'], unknown>(url);
      return response?.results || [];
    } catch (e) {
      logger.error("EntityStoreApiServiceImpl", "Error while fetching sample entities", e);
    }

    return [];
  }

  async suggestEntityAggregation(
    startTime: number,
    endTime: number,
    entityType: string,
    cohortId: string,
    fields: string[],
    mock = false
  ) {
    await this.init();
    const subUrl = "/attributes/suggest-aggregations";
    const url = this.getUrl(subUrl, startTime, endTime);
    const payload: Record<string, any> = {
      entityType,
      fields
    };

    const cId = this.getCohortId(cohortId);
    if (cId) {
      payload.cohortSelector = {
        cohortId: cId
      };
    } else {
      payload.entityTypeSelector = {};
    }

    const promise = mock ? getMockSuggestions(fields) : this.datasource.post<EntityAggregationSuggestionResponse, any>(url, payload);
    return promise;
  }

  async getFieldValues(
    startTime: number,
    endTime: number,
    entityType: string,
    cohortId: string,
    fields: string[]
  ) {
    await this.init();
    const shareId = appConfig.anomShareId;

    const paramsUrl = shareId ? `?st=${startTime}&et=${endTime}&shareId=${shareId}` : `?st=${startTime}&et=${endTime}`;

    const url = `/api/bizEntity/${entityType}/filters/fields/values${paramsUrl}`;
    const payload: Record<string, any> = {
      entityType,
      fields
    };

    const cId = this.getCohortId(cohortId);
    if (cId) {
      payload.cohortSelector = {
        cohortId: cId
      };
    } else {
      payload.entityTypeSelector = {};
    }

    if (shareId) {
      const newUrl = `${appConfig.apiDomainUrl}${url}`;
      const promise = request.post<FieldValuesCompletionResponse, any>(newUrl, payload);
      return promise;
    }

    const promise = this.datasource.post<FieldValuesCompletionResponse, any>(url, payload);
    return promise;
  }

  async getEntityAggregation(
    startTime: number,
    endTime: number,
    entityType: string,
    cohortId: string,
    aggregations: EntityAggregationRequest[],
    mock = false
  ) {
    await this.init();
    const subUrl = "/attributes/aggregations";
    const url = this.getUrl(subUrl, startTime, endTime);

    const payload: Record<string, any> = {
      entityType,
      aggregations
    };

    const cId = this.getCohortId(cohortId);
    if (cId) {
      payload.cohortSelector = {
        cohortId: cId
      };
    } else {
      payload.entityTypeSelector = {};
    }

    const promise = mock ? getMockAggregations(aggregations) : this.datasource.post<EntityAggregationResponse, any>(url, payload);
    return promise;
  }

  async getEntityCount(
    entityType: string,
    startTime: number,
    endTime: number,
    cohortId?: string
  ): Promise<number> {
    await this.init();
    const subUrl = "/count";
    const url = this.getUrl(subUrl, startTime, endTime);

    const payload: EntityCountRequest = {
      entityType,
      reqId: `${entityType}_${startTime}_${endTime}`,
      entityTypeSelector: {}
    };

    const cId = this.getCohortId(cohortId);
    if (cId) {
      payload.cohortSelector = {
        cohortId: cId
      };
      delete payload.entityTypeSelector;
    }

    try {
      const {
        data,
        status,
        statusText
      } = await this.datasource.post<EntityCountResponse, EntityCountRequest>(url, payload);

      const isError = isErrorResponse(status);
      if (isError) {
        logger.error("EntityStoreApiServiceImpl", `Error while fetching entity count: ${statusText}`, data);
        return 0;
      } else {
        return parseInt((data.count || 0).toString(), 10);
      }
    } catch(e){
      logger.error("EntityStoreApiServiceImpl", "Error while fetching entity count", e);
      return 0;
    }
  }

  async getEntitiesCsvUrl(entityType: string, bizFields: BizField[], name: string, cohortId?: string): Promise<string> {
    await this.init();
    const url = getBizEntityUrlPrefix() + "/exportCSV";

    const payload: ExportEntityCSVRequest = {
      bizField: bizFields,
      cohortId: cohortId || "",
      fileName: name,
      typeId: entityType
    };
    const stringifiedPayload = JSON.stringify(payload);
    const queryParamStr = `?req=${stringifiedPayload}`;

    return new Promise((resolve) => {
      resolve(encodeURI(this.datasource.getUrl(`${url}${queryParamStr}`)));
    });
  }

  async getFileSummaryContent(payload: SourceViewRequest):Promise<SourceViewResponse>{
    await this.init();
    const url = getBizEntityUrlPrefix() + "/knowledgeBase/sourceView";
    try {
      const {
        data,
        status,
        statusText
      } = await this.datasource.post<SourceViewResponse,SourceViewRequest>(url, payload);

      const isError = isErrorResponse(status);
      if (isError) {
        logger.error("EntityStoreApiServiceImpl", `Error while fetching file summary: ${statusText}`, data);
      } else {
        return data;
      }
    } catch(e){
      logger.error("EntityStoreApiServiceImpl", "Error while fetching file summary", e);
    }
    return null;
  }

  private getCohortId(cohortId: string) {
    return isValidCohortId(cohortId) ? cohortId : null;
  }

  private getUrl(subUrl: string, st: number, et: number) {
    return `${this.BASE_URL}${subUrl}?st=${st}&et=${et}`;
  }

  private async init() {
    if (!this.datasource) {
      this.datasource = await datasourceApiManager.getDefault();
    }
  }
}

const entityStoreApiService: EntityStoreApiService = new EntityStoreApiServiceImpl();
export default entityStoreApiService;
