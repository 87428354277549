import _, { isArray, trim } from "lodash";
import { ScopedVars } from "../../services/api/types";
import kbn from "../../services/datasources/core/kbn";
import VariableImpl from "../model-impl/VariableImpl";
import { VARIABLE_PREFIX } from "./constants";
import { getVariableRegex } from "./utils";

class TemplateSrv {
  variables: VariableImpl[];

  private regex = getVariableRegex();
  private index: any = {};
  private builtIns: any = {};

  constructor() {
    this.builtIns['__interval'] = {
      text: '1s',
      value: '1s'
    };
    this.builtIns['__interval_ms'] = {
      text: '100',
      value: '100'
    };
    this.variables = [];
  }

  /**
   * template for legend format is test:{{test}}
   * template for variable replace is test:{{$test}}
   * Ideally we should not have two formats.
   * @param template
   * @param variables
   * @param labelData pass tags, key value pairs.
   */

  formatLabel(template: string, variables: ScopedVars, labelData: Record<string,string>) {

    const regexStr = `{{\\${VARIABLE_PREFIX}?(\\w)+}}`;
    const regex = new RegExp(regexStr, 'g');
    return template.replace(regex, (match: string) => {
      const key = trim(match, '{|}');
      let value = variables ? variables[key]?.value : '';
      value = isArray(value) ? value.join(', ') : value;
      value = value || labelData[key] || '-NA-';
      return value;
    });
  }

  formatValue(value: any, format: any, variable: any) {
    // for some scopedVars there is no variable
    variable = variable || {};

    if (typeof format === 'function') {
      return format(value, variable, this.formatValue);
    }

    switch (format) {
      case 'regex': {
        if (typeof value === 'string') {
          return kbn.regexEscape(value);
        }

        const escapedValues = _.map(value, kbn.regexEscape);
        if (escapedValues.length === 1) {
          return escapedValues[0];
        }
        return '(' + escapedValues.join('|') + ')';
      }
      case 'pipe': {
        if (typeof value === 'string') {
          return value;
        }
        return value.join('|');
      }
      default: {
        if (_.isArray(value) && value.length > 1) {
          return '{' + value.join(',') + '}';
        }
        return value;
      }
    }
  }

  getVariableName(expression: string) {
    this.regex.lastIndex = 0;
    const match = this.regex.exec(expression);
    if (!match) {
      return null;
    }
    const variableName = match.slice(1).find(match => match !== undefined);
    return variableName;
  }

  private variableExists(expression: string) {
    const name = this.getVariableName(expression);
    return name && this.index[name] !== void 0;
  }

  private getVariableValue(variableName: string, fieldPath: string | undefined, scopedVars: ScopedVars) {
    const scopedVar = scopedVars[variableName];
    if (!scopedVar) {
      return null;
    }
    return scopedVar.value;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  replace(target: string, scopedVars?: ScopedVars, format?: string | Function): any {
    if (!target) {
      return target;
    }

    // Reset the regex since it might be used at other places or at this place continuously
    this.regex.lastIndex = 0;

    return target.replace(this.regex, (match, var1, var2, fmt2, var3, fieldPath, fmt3) => {
      const variableName = var1 || var2 || var3;
      const variable = scopedVars[variableName];
      const fmt = fmt2 || fmt3 || format;

      if (scopedVars) {
        const value = this.getVariableValue(variableName, fieldPath, scopedVars);
        if (value !== null && value !== undefined && value !== variable?.allValue) {
          return this.formatValue(value, fmt, variable);
        }
      }

      if (!variable) {
        return match;
      }

      let value = variable?.value;
      if (value && value === variable.allValue) {
        value = variable.allValue;
        // skip formatting of custom all values
        return this.replace(value);
      }

      if (fieldPath) {
        const fieldValue = this.getVariableValue(variableName, fieldPath, {
          [variableName]: { value: value,
            text: '' },
        });
        if (fieldValue !== null && fieldValue !== undefined) {
          return this.formatValue(fieldValue, fmt, variable);
        }
      }

      const res = this.formatValue(value, fmt, variable);
      return res;
    });
  }
}

export const templateSrv = new TemplateSrv();
