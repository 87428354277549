import { generateSSTimeseriesData, generateSingleStatData, generateInsightsData } from "./generators";

const dataDefId = "33leaauWGt";
const metricId = "T70iDQdeu";

const lookupMin = 110;
const lookupMax = 130;
const percentageChangeValues = [12, 17, -2, 16, -5, -7, 13, -15, 10, 8, 5, 3, 11, 9, -2, 15, -12, 4, 2, 3];

const dataRange: [number, number] = [250, 350];
const timeShiftRange: [number, number] = [250, 300];

export const ss2Data = [generateSingleStatData(
  dataDefId,
  metricId,
  1649656800000,
  249.66751067136988,
  242.77468752149332,
  0.3588882610090408
)];

export const ssTs2Data = [generateSSTimeseriesData(
  dataDefId,
  metricId,
  dataRange,
  timeShiftRange
)];


export const ssEl2Data = [generateInsightsData(
  dataDefId,
  metricId,
  lookupMin,
  lookupMax,
  percentageChangeValues,
  timeShiftRange
)];
