import { useCallback, useEffect, useRef } from "react";
import { useFetchDataStatus, FetchDataStatus } from "./useFetchDataStatus";

type DataStates<R, E> = Omit<FetchDataStatus<R, E>, 'setStatus' | 'resetStates'>;
export type FetchCallBackResult<R, E> = Omit<DataStates<R, E>, 'isFetching'> & {
  isCancelled?: boolean;
};
export type UpdatePartialDataFn<R> = (getStateFn: (prev: R) => R) => void;
export type FetchFn<R, E> = (partDataUpdateCallback?: UpdatePartialDataFn<R>) => Promise<FetchCallBackResult<R, E>>;
export type InitialState<R, E> = Partial<DataStates<R, E>>;

export const useDataFetch = <R, E = unknown>(fetchFn: FetchFn<R, E>, initialState?: InitialState<R, E>, disableAutoFetch = false) => {
  const unmountedRef = useRef(false);
  const prevState = useRef<InitialState<R, E>>(initialState || {
    data: null,
    error: null,
    isError: false,
    isFetching: false,
    isSuccess: false
  });

  const {
    setStatus,
    data,
    error,
    isError,
    isFetching,
    isSuccess,
  } = useFetchDataStatus<R, E>(initialState);

  const setDataInternal = useCallback((getStateFn: (prev: R) => R, setSuccessState?: boolean) => {
    const nextData = getStateFn(prevState.current.data);
    prevState.current.data = nextData;
    setStatus(
      nextData,
      setSuccessState ? false : prevState.current.isFetching,
      setSuccessState ? true : prevState.current.isSuccess,
      setSuccessState ? false : prevState.current.isError,
      setSuccessState ? null : prevState.current.error
    );
  }, [setStatus]);

  const setData = useCallback((getStateFn: (prev: R) => R) => {
    setDataInternal(getStateFn, true);
  }, [setDataInternal]);

  const setPartialData = useCallback((getStateFn: (prev: R) => R) => {
    setDataInternal(getStateFn, false);
  }, [setDataInternal]);

  const fetch = useCallback(async () => {
    if (!unmountedRef.current) {
      setStatus(prevState.current.data, true, false, false, null);
    }

    const {
      data: d,
      error: e,
      isError: iE,
      isSuccess: iS,
      isCancelled
    } = await fetchFn(setPartialData);

    if (!unmountedRef.current) {
      prevState.current = {
        data: d,
        error: e,
        isError: iE,
        isFetching: isCancelled,
        isSuccess: iS
      };
      setStatus(d, isCancelled, iS, iE, e);
    }
  }, [fetchFn, setPartialData, setStatus]);

  useEffect(() => {
    unmountedRef.current = false;

    if (!disableAutoFetch) {
      fetch();
    }

    return () => {
      unmountedRef.current = true;
    };
  }, [fetch, disableAutoFetch]);

  return {
    data,
    error,
    isError,
    isSuccess,
    isFetching,
    refetch: fetch,
    setData
  };
};
