import { useCallback, useState } from "react";

const useForceUpdate = (callback?: () => void) => {
  const [, setUpdater] = useState(0);

  const update = useCallback(() => {
    setUpdater(u => u + 1);
    if (callback) {
      callback();
    }
  }, [callback, setUpdater]);

  return update;
};

export { useForceUpdate };

