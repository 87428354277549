import { forEach, isEmpty } from "lodash";
import { InceptionResponse } from "../types";
import { MetricInfo } from "../../datasources/prometheus/core/types";
import { ResultTransformer } from "../../datasources/prometheus/result_transformer";
import { TimeSeries } from "../../../core";
import {
  CohortEntityFilter, ExploreEntityFilter, ForcastResult, WidgetData, WidgetDataDTO, PostAggResult,
  ExploreDataResponse, MetricResultData
} from "./types";

export class BizDataProcessor {
  protected resultTransformer = new ResultTransformer();

  processWidgetDataResponse(
    response: InceptionResponse<WidgetData>,
    startTime: number,
    endTime: number,
    intervalSecs: number
  ): WidgetDataDTO {
    const {
      fieldResult,
      metricResults,
      funnelResult
    } = response.data;

    const exploreWidgetData: WidgetDataDTO = {
      fieldResult,
      metricResults: [],
      funnelResult
    };

    metricResults.forEach((mResult) => {
      const {
        data: dataJson,
        dataDefinitionId,
        compareConfigData: compareConfigDataJson,
        entityLookupData,
        dataQueryConfig,
        postAggResult,
        forecastResult,
        warnings = [],
      } = mResult;

      const postAggResultDTO: PostAggResult = {
        data: {},
        entityLookupData: {},
        percentageChangeData: {},
        timeShiftedData: {},
      };

      const forecastResultDTO: ForcastResult = {
        data: {},
        delta: {},
        entityLookupData: {},
        percentageChangeData: {},
        lowerBoundData: {},
        upperBoundData: {},
      };

      // Delete the meta headers
      delete dataJson.headers;
      delete compareConfigDataJson.headers;

      const parseData = (
        dataJson: Record<string, ExploreDataResponse>
      ): Record<string, MetricResultData> => {
        const parsedData: Record<string, MetricResultData> = {};
        forEach(dataJson || {}, (expData, key) => {
          if (!isEmpty(expData)) {
            const options = {
              refId: key,
              step: intervalSecs.toString(),
              start: Math.floor(startTime / 1000),
              end: Math.floor(endTime / 1000),
            };
            const tsArr = this.resultTransformer.transform(
              expData,
              options
            );
            const schema = this.getSchemaFromResponse(expData);

            const metricData: MetricResultData = {
              data: tsArr,
              schema,
              preLimitSelectionCount:
                expData.data?.preLimitSelectionCount || schema.length,
              seasonSecs: expData.seasonSecs,
            };

            parsedData[key] = metricData;
          }
        });
        return parsedData;
      };

      const seriesData = parseData(dataJson);
      const compareSeriesData = parseData(compareConfigDataJson);

      const {
        data = {},
        entityLookupData: postAggEntityLookupData = {},
        percentageChangeData: percentChangeData = {},
        timeShiftedData: timeShiftData = {},
      } = postAggResult || {};

      postAggResultDTO.data = parseData(data);
      postAggResultDTO.entityLookupData = postAggEntityLookupData;
      postAggResultDTO.percentageChangeData = parseData(
        percentChangeData
      );
      postAggResultDTO.timeShiftedData = parseData(timeShiftData);

      if (!isEmpty(forecastResult)) {
        const {
          data: forecastData = {},
          delta: forecastDeltaData = {},
          entityLookupData: forecastEntityLookupData = {},
          percentageChangeData: forecastPercentageChangeData = {},
          lowerBoundData = {},
          upperBoundData = {}
        } = forecastResult;

        forecastResultDTO.data = parseData(forecastData);
        forecastResultDTO.delta = parseData(forecastDeltaData);
        forecastResultDTO.entityLookupData = forecastEntityLookupData;
        forecastResultDTO.percentageChangeData = parseData(forecastPercentageChangeData);
        forecastResultDTO.lowerBoundData = parseData(lowerBoundData);
        forecastResultDTO.upperBoundData = parseData(upperBoundData);
      }

      exploreWidgetData.metricResults.push({
        dataDefinitionId,
        data: seriesData,
        compareConfigData: compareSeriesData,
        entityLookupData,
        dataQueryConfig,
        postAggResult: postAggResultDTO,
        forecastResult: forecastResultDTO,
        warnings
      });
    });

    return exploreWidgetData;
  }

  enrichResults(metricData: WidgetDataDTO) {
    metricData?.metricResults?.forEach(result => {
      const {
        data,
        entityLookupData,
        compareConfigData,
        postAggResult
      } = result;

      const {
        data: postAggData,
        percentageChangeData,
        entityLookupData: postAggEntityLookupData,
        timeShiftedData
      } = postAggResult;

      const fEntityLookupData = {
        ...(entityLookupData || {}),
        ...(postAggEntityLookupData || {})
      };

      enrichData(data, fEntityLookupData);
      enrichData(compareConfigData, fEntityLookupData);
      enrichData(postAggData, fEntityLookupData);
      enrichData(percentageChangeData, fEntityLookupData);
      enrichData(timeShiftedData, fEntityLookupData);
    });
  }

  private getSchemaFromResponse(promData: ExploreDataResponse): MetricInfo[] {
    let schema = promData?.data?.resultSchema || [];
    if (!schema.length) {
      schema = (promData?.data?.result || []).map((r) => r.metric);
    }
    return schema;
  }

  getExploreEntityFilters(cohortEntityFilters: CohortEntityFilter[]): ExploreEntityFilter[] {
    const predicates = cohortEntityFilters
      .filter(({ fieldType }) => fieldType === "bizEntityField")
      .map(({ predicate }) => predicate);

    return predicates.length ? [{
      filters: predicates
    }] : [];
  }
}

const enrichData = (dataRec: Record<string, MetricResultData>, lookup: Record<string, string>) => {
  const dataArr = Object.values(dataRec || {});
  dataArr.forEach(data => {
    data.data?.forEach(d => {
      const ts = d as TimeSeries;
      const { tags } = ts;

      if (tags && lookup) {
        const eTags: Record<string, string> = {};
        Object.keys(tags).forEach(tagKey => {
          const rawValue = tags[tagKey];
          eTags[tagKey] = lookup[rawValue] || rawValue;
        });
        ts.eTags = eTags;
      }
    });
  });
};
