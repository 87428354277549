import { TemplateVariable } from "@inception/connector-config-ui/src";
import { SCHEDULE_TYPE } from "../../explore";
import { EntityPropertyValue, PrimKind } from "../../../../core";

export interface SetScheduleProps {
  schedule: SCHEDULE_TYPE;
}

export interface DeleteActionConfig {
  actionId: string;
}

export interface OperationaliseActionType {
  actionTypeId: string;
  actionTypeName: string;
}

export interface ActionTypesResponse {
  actionTypes: OperationaliseActionType[];
  isBizEntityAggregated: boolean;
}

export type PrimType = PrimKind;


export type TypedObjectValue = EntityPropertyValue;

export type TypeObjectValKeys = keyof TypedObjectValue;

export interface ActionConfigUIParam {
  name: string;
  label: string;
  required: boolean;
  type: PrimType;
  visible: boolean;
  editable: boolean;
  description: string;
  dependsOn: string;
  dependsOnValuesMap: any;
  defaultValue: {
    type: PrimType;
    value: TypedObjectValue;
  };
  hasValuesLookup: boolean;
  fieldOrder: number;
  subtype: string;
  requiredAtRuntime: boolean;
  disableCustomTemplates: boolean;
}

export interface UIIntegrationActionConfig {
  actionDefConfigId: string;
  actionDefConfigName: string;

  connectionId: string;
  connectionName: string;

  entityTypeId: string;

  id: string;
  name: string;
  isDefault: boolean;
  uiParams: Record<string, ActionConfigUIParam>;
}

export interface ActionDefConfig {
  icon: string;
  name: string;
  sourceTypeId: ActionSourceTypes;
  uiIntegrationActionConfigs: UIIntegrationActionConfig[];
}

export interface ActionDefConfigResponse {
  actionConfigDetails: ActionDefConfig[];
}

export enum ActionSourceTypes {
  SLACK = "source-slack",
  SALESFORCE = "source-salesforce",
  WEBHOOK = "source-webhook",
  UPDATEFIELD = "",
}

export interface SlackChannel {
  name: string;
  id: string;
}

export interface ActionsLookUpResponse {
  paramValues: Record<string, string>;
}

export interface TemplateVariableResponse {
  variables: TemplateVariable[];
}
