import { random } from "lodash";
import ssEntityLookupData from "../../../../../../fakeData/mock_entities";
import { generateMetricKey } from "./common";
import { ssMockTimestamps } from "./timestamps";

export const generateSSTimeseriesData = (
  dataDefId: string,
  metricId: string,
  datapointsRange: [number, number],
  timeShiftedDatapointsRange: [number, number],
) => {
  const metricKey = generateMetricKey(dataDefId, metricId);
  return {
    "data": [
      {
        "metricResults": [
          {
            "dataDefinitionId": `${dataDefId}`,
            "data": {},
            "compareConfigData": {},
            "entityLookupData": {},
            "dataQueryConfig": {},
            "warnings": [] as any[],
            "postAggResult": {
              "data": {
                [metricKey]: {
                  "data": [
                    {
                      "datapoints": generateDataPoints(datapointsRange[0], datapointsRange[1]),
                      "refId": metricKey,
                      "target": " ",
                      "tags": {},
                      "metricName": "",
                      "eTags": {}
                    }
                  ],
                  "schema": [
                    {}
                  ],
                  "preLimitSelectionCount": 1
                }
              },
              "entityLookupData": ssEntityLookupData,
              "percentageChangeData": {},
              "timeShiftedData": {
                [metricKey]: {
                  "data": [
                    {
                      "datapoints": generateDataPoints(timeShiftedDatapointsRange[0], timeShiftedDatapointsRange[1]),
                      "refId": metricKey,
                      "target": " ",
                      "tags": {},
                      "metricName": "",
                      "eTags": {}
                    }
                  ],
                  "schema": [
                    {}
                  ],
                  "preLimitSelectionCount": 1
                }
              }
            }
          }
        ]
      }
    ],
    "entityContext": {}
  };
};

const generateDataPoints = (min: number, max: number): Array<[number, number]> => (
  ssMockTimestamps.map(ts => [
    random(min, max),
    ts
  ])
);
