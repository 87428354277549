import moment from "moment";
import { TimeRange } from "../../core/hooks/time-range/types";
import { DataQuery } from "../../services/api/types";
import { dateTime } from "../../core/moment_wrapper";
import { DataType } from "../../core";
import VariableImpl from "../model-impl/VariableImpl";

export type WidgetType = 'timeseries' | 'donut' | 'data-grid' | 'metric-number' | 'image' | 'heatmap' | 'horizontal-bar-chart' | 'request-count-widget' | 'filter' | string;
export interface Query extends DataQuery {
  expr?: any;
}


export interface CommonWidgetProperties {
  background?: string;
  description?: string;
  transparent?: boolean;
  borderLess?: boolean;
  dataType?: DataType;
  unit?: string;
  downsample?: string;
  viewState?: WidgetViewState;
}

export enum WidgetViewState {
  FULLSCREEN = 'fullscreen',
  MINIMIZE = 'minimize'
}

export interface CommonWidgetOptions extends Record<string, any> {
  hideActionsOnDragAndSelect?: boolean;
  syncTooltip?: boolean;
  noPadding?: boolean;
}

export interface WidgetMeta {
  edit?: boolean;
  compare?: boolean;
  hideHeader?: boolean;
  resizable: boolean;
  hideActions?: boolean;
  hideTimeRange?: boolean;
}

// Back-end model
export default interface BaseWidgetModel {
  id: string;
  title: string;
  description?: string;
  type: WidgetType;

  datasource: string;
  queries: Query[];
  variables?: VariableImpl[];

  timeRange?: TimeRange;
  downsample?: string; //30s, 15s, 900s
  // Used for setting up comparisons in thw widget
  comparisonModel?: WidgetComparisonModel;

  properties?: CommonWidgetProperties;
  options?: CommonWidgetOptions;

  /**
   * Pass in optionally the styles which apply to the widget.
   */
  style?: React.CSSProperties;
  background?: string;
  meta?: WidgetMeta;
}

// Model to be used for dashboard comparison
export enum ComparisonType {
  RELATIVE,
  CUSTOM
}

export enum RelativeDurationType {
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS
}

export enum RelativeDurationSuffix {
  MINUTES = "m",
  HOURS = "h",
  DAYS = "d",
  WEEKS = "w",
  MONTHS = "M"
}

export type WidgetComparisonModel = {
  comparisonType: ComparisonType;
  // If comparisonType is custom, durationType and durationValue are optional
  durationType?: RelativeDurationType;
  durationValue?: number;

  customStartTime?: Date;
  customEndTime?: Date;
};

export const getDefaultComparisonModel = (): WidgetComparisonModel => ({
  comparisonType: ComparisonType.RELATIVE,
  durationType: RelativeDurationType.DAYS,
  durationValue: 1,
  customStartTime: new Date(Date.now() - 60 * 60 * 1000),
  customEndTime: new Date(Date.now())
});

export const getComparisonTimeRange = (model: WidgetComparisonModel, globalTimeRange: TimeRange): TimeRange => {
  if (!model || !globalTimeRange) {
    return {
      from: dateTime(new Date()),
      to: dateTime(new Date()),
      raw: {  // Raw is not going to get used, so setting it to empty
        from: '',
        to: ''
      }
    };
  }

  let startTime = 0;
  let endTime = 0;
  if (model.comparisonType === ComparisonType.RELATIVE) {
    const currentStartTime = moment(globalTimeRange.from.valueOf());

    switch (model.durationType) {

      case RelativeDurationType.MINUTES: {
        startTime = currentStartTime.subtract(model.durationValue, 'minutes').valueOf();
        break;
      }
      case RelativeDurationType.HOURS: {
        startTime = currentStartTime.subtract(model.durationValue, 'hours').valueOf();
        break;
      }
      case RelativeDurationType.DAYS: {
        startTime = currentStartTime.subtract(model.durationValue, 'days').valueOf();
        break;
      }
      case RelativeDurationType.WEEKS: {
        startTime = currentStartTime.subtract(model.durationValue, 'weeks').valueOf();
        break;
      }
      case RelativeDurationType.MONTHS: {
        startTime = currentStartTime.subtract(model.durationValue, 'months').valueOf();
        break;
      }
    }

    endTime = startTime + (globalTimeRange.to.valueOf() - globalTimeRange.from.valueOf());

  } else if (model.comparisonType === ComparisonType.CUSTOM) {
    startTime = model.customStartTime.getTime();
    endTime = model.customEndTime.getTime();
  }

  return {
    from: dateTime(startTime),
    to: dateTime(endTime),
    raw: {  // Raw is not going to get used, so setting it to empty
      from: '',
      to: ''
    }
  };
};
