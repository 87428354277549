import React, { ReactElement } from 'react';
import shortid from 'shortid';
// import moment from 'moment';
import { SpanTreeNode } from "./types";

const MIN_BAR_WIDTH = 0.5;
const MILLISEC_IN_SEC = 1000;
const MIN_BAND_WIDTH = 0.5;

interface TimelineEventBar {
  event: SpanTreeNode;

  // this is the start time of the first span in entire trace.
  overallStartTimeInMs: number;

  // total duration for entire trace
  totalDurationInMs: number;

  collapsed?: boolean;

  showSpanSpentTime?: boolean;
}

const TimelineEventBar: React.FC<TimelineEventBar> = ((props: TimelineEventBar) => {

  const getPosition = (node: SpanTreeNode): React.CSSProperties => {
    const diff = parseInt(node.startTime, 10) - props.overallStartTimeInMs;

    const left = (diff * 100) / (props.totalDurationInMs) + '%';

    // set minimum width on all bars to MIN_BAR_WIDTH atleast
    const width = Math.max((parseInt(node.duration, 10) / props.totalDurationInMs) * 100, MIN_BAR_WIDTH) + '%';

    // if (left > 99.5) {
    //   left -= MIN_BAR_WIDTH;
    // }
    //console.log("LEFT: " + left);

    return {
      position: 'absolute',
      width,
      left,
    };
  };

  const createSpentTimeBand = (left: number, width: number): ReactElement => {
    const key = shortid.generate();

    return (
      <span
        className="ts-timeline-band"
        key={key}
        style={{left: left + '%',
          width: width + '%'}}
      />);
  };

  const getSpentTimeBands = (node: SpanTreeNode): ReactElement[] => {
    let width = 0;
    let left = 0;
    const spentTimeBands: ReactElement[] = [];

    if (node.spentTime === node.duration) {
      width = 100;
      left = 0;
      spentTimeBands.push(createSpentTimeBand(left, width));
    } else if (node.children?.length) {
      let nodeStartTime = node.startTime;
      // sort the first level child spans
      node.children.sort((a: SpanTreeNode, b: SpanTreeNode) => parseInt(a.startTime, 10) - parseInt(b.startTime, 10));
      // threshold in micro secs just to check if two spans have started almost at the same time.
      const threshold = 5;
      let fLeft = 0,
        fWidth = 0;
      node.children.forEach((child: SpanTreeNode) => {
        // gap of parent span starttime and child span starttime
        const gap: number = Math.abs(parseInt(child.startTime, 10) - parseInt(nodeStartTime, 10));
        // width of the child wrt current node duration
        const childWidth = ((parseInt(child.duration, 10) / (parseInt(node.duration, 10))) * 100);
        // console.log(`
        //   Node start time: ${moment(parseInt(nodeStartTime, 10) / 1000).format('YYYY-MM-DD HH:mm:ss.SSS a')},
        //   Child start time: ${moment(parseInt(child.startTime, 10) / 1000).format('YYYY-MM-DD HH:mm:ss.SSS a')},
        //   Child end time: ${moment(parseInt(child.endTime, 10) / 1000).format('YYYY-MM-DD HH:mm:ss.SSS a')},
        //   Gap: ${gap},
        //   Child Width: ${childWidth},
        //   Final Width: ${fWidth},
        //   fLeft: ${fLeft},
        // `);
        // total width or gap we have filled
        if (gap > threshold) {
          // gives the band width
          width = Math.max( (gap / (parseInt(node.duration, 10))) * 100, MIN_BAND_WIDTH);
          const band = createSpentTimeBand(fLeft, width);
          spentTimeBands.push(band);
          // next span start time
          nodeStartTime = child.endTime;
          // move right as much as the span duration width
          fLeft += width + childWidth;
          fWidth = fLeft;
        } else {
          nodeStartTime = child.endTime;
          fLeft += childWidth;
          fWidth += childWidth;
        }
      });
      // to check if the width has filled the entire span
      if (fWidth < 100) {
        spentTimeBands.push(createSpentTimeBand(fWidth, 100 - fWidth));
      }
    } else {
      width = Math.max((parseInt(node.spentTime, 10) / props.totalDurationInMs) * 100, MIN_BAND_WIDTH);
      spentTimeBands.push(createSpentTimeBand(left, width));
    }

    return spentTimeBands;
  };

  const getDuration = (): string => {
    const timeInMillis = parseInt(props.event.duration, 10) / 1000;

    // if total duration is greater than 3 seconds, show everything in sec
    if (timeInMillis > 1000) {
      return (timeInMillis / MILLISEC_IN_SEC).toFixed(2) + ' s';
    } else {
      return timeInMillis.toFixed(2) + ' ms';
    }
  };

  const getSpans = (): SpanTreeNode[] => {
    // We need to merge the collapsed children as well. so get the spans for those.
    if (props.collapsed) {
      const collapsedSpans: SpanTreeNode[] = traverseTree(props.event);
      return collapsedSpans || [];
    }

    return [props.event];
  };

  const traverseTree = (root: SpanTreeNode, operation?: (node: SpanTreeNode) => void): SpanTreeNode[] => {
    const traversedNodes: SpanTreeNode[] = [];
    traversedNodes.push(root);

    function traverseTreeRec(root: SpanTreeNode) {
      if (root && root.children && root.children.length > 0) {
        root.children.forEach((n) => {
          traversedNodes.push(n);
          if (operation) {
            operation(n);
          }
          traverseTreeRec(n);
        });
      }
    }

    traverseTreeRec(root);
    return traversedNodes;
  };

  const isErrorSpan = (span: SpanTreeNode): boolean => span.spanError === 'true';

  return (
    <div className={'ts-timeline-events-row'}>
      {
        getSpans().map((span) => <div key={shortid.generate()} style={getPosition(span)}>
          {/* <span className="tooltiptext"> Duration: { getDuration() } ms </span><br/>  */}
          {/* { !props.collapsed && <div> {getDuration()} ms</div> } */}
          <div className={isErrorSpan(span) ?
            'ts-timeline-bar ts-timeline-bar-error-span' : 'ts-timeline-bar'}>

            {/* If we are in collapsed state, only show the value for 1st span which is essentially the collapsed one */}
            {
              !props.collapsed ? <span>
                {getDuration()}
              </span> : null
            }
            { props.showSpanSpentTime ? getSpentTimeBands(span) : null }
          </div>
        </div>)
      }
    </div>
  );
});

export default TimelineEventBar;
