import shortid from "shortid";
import { v4, validate } from "uuid";

const METRIC_ALLOWED_CHARACTER_SET = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const SPECIAL_CHARACTER_SET = "-@";

const CHARACTER_SET = `${METRIC_ALLOWED_CHARACTER_SET}${SPECIAL_CHARACTER_SET}`;
shortid.characters(CHARACTER_SET);

function getRandomCharacter(length: number) {
  const charactersLength = METRIC_ALLOWED_CHARACTER_SET.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += METRIC_ALLOWED_CHARACTER_SET.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const generateId = (): string => {
  const id: any = shortid.generate();
  const hasSpecialChars = /.*[-_@].*/g;

  // Add this check to prevent tests from failing due to TS
  if (hasSpecialChars.test(id) && id.replaceAll) {
    const idSanitized = id.replaceAll("_", getRandomCharacter(1))
      .replaceAll("-", getRandomCharacter(1))
      .replaceAll("@", getRandomCharacter(1));
    // console.log(`Generated id ${id}, Sanitized id ${idSanitized}`);
    return idSanitized;
  }
  return id;
};

export const generateUUID = () => v4();
export const isValidUUID = (id: string) => validate(id);
