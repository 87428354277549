import { isObject } from "lodash";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import datasourceApiManager from "../DatasourceApiService";
import { Entity, EntityProjection } from "../../../core";
import { CohortIntersectionRequest, CohortIntersectionResponse, CohortDiffResponse, CohortDiffResult } from "./types";
import { BizEntityApiResult } from "./BizServiceCommon";

class CohortApiService {
  private url = "/cohort-service/api/v1/cohorts";

  protected datasource: ApptuitDatasource;

  async getCohortIntersection(cohortIds: string[], startTimeMillis: number, endTimeMillis: number, fields: string[] = []) {
    await this.init();
    const url = this.getUrl("/intersect", [startTimeMillis, endTimeMillis]);


    const projections: EntityProjection[] = fields.map(field => ({
      fieldType: "prop" as any,
      name: field
    }));

    projections.push({
      fieldType: "displayName" as any,
      name: "displayName"
    });

    const result: BizEntityApiResult<Entity[]> = {
      data: [],
      error: false,
      message: "",
      cancelled: false
    };

    const payload: CohortIntersectionRequest = {
      cohortIds,
      projections
    };

    try {
      const response = await this.datasource.post<CohortIntersectionResponse, CohortIntersectionRequest>(url, payload);
      const {
        error,
        memberEntities = []
      } = response.data;
      if (!error) {
        result.data = memberEntities;
      } else {
        result.error = true;
        result.message = error;
      }
    } catch (err) {
      result.error = true;
      result.message = this.handleError(err);
    }
    return result;
  }

  async getCohortDiff(cohortId: string, startTimeMillis: number, endTimeMillis: number) {
    await this.init();
    const subUrl = `/${cohortId}/diff.json`;
    const url = this.getUrl(subUrl, [startTimeMillis, endTimeMillis]);

    const result: BizEntityApiResult<CohortDiffResult> = {
      data: {
        missingFromEarlyCohort: [],
        missingFromLaterCohort: [],
        laterCount: 0,
        earlierCount: 0
      },
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.get<CohortDiffResponse, null>(url);
      const {
        error,
        missingFromEarlyCohort = [],
        missingFromLaterCohort = [],
        laterCount,
        earlierCount
      } = response.data;
      if (!error) {
        result.data = {
          missingFromEarlyCohort,
          missingFromLaterCohort,
          laterCount,
          earlierCount
        };
      } else {
        result.error = true;
        result.message = error;
      }
    } catch (err) {
      result.error = true;
      result.message = this.handleError(err);
    }
    return result;
  }

  private getUrl(subUrl?: string, timeParams?: [number, number]) {
    const timeParamsStr = this.getTimeParams(timeParams[0], timeParams[1]);
    const url = `${this.url}${subUrl}${timeParamsStr}`;
    return url;
  }

  private getTimeParams(startTimeMillis: number, endTimeMillis: number) {
    return `?st=${startTimeMillis}&et=${endTimeMillis}`;
  }

  private handleError(err: any): string {
    let message = err.message;

    // If the error is not JS error but AxiosError
    if (err.response?.data) {
      message = isObject(err.response.data) ? err.response.data?.message || message : err.response.data;
    }

    return message;
  }

  private async init() {
    this.datasource = await datasourceApiManager.getDefault();
  }
}

const cohortApiService = new CohortApiService();
export default cohortApiService;
