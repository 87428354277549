import axios, { AxiosPromise } from "axios";
import { User, Org } from '../../../core/data';
import appConfig from "../../../../appConfig";

export class LoginApiService {

  private apptuitAxios = axios.create({
    baseURL: appConfig.baseUrl + appConfig.basePath,
    withCredentials: false
  });

  private customAxios = axios.create({
    baseURL: appConfig.baseUrl,
    withCredentials: true
  });

  apptuitLoggedIn(): AxiosPromise<any> {
    return this.apptuitAxios.get("/api/login/ping");
  }

  apptuitLogin(username: string, password: string, email?: string): AxiosPromise<any> {
    const params = new URLSearchParams();
    params.append("email", email || "");
    params.append('user', username);
    params.append('password', password);

    return this.apptuitAxios({
      method: "POST",
      url: "/login",
      data: params
    });
  }

  fetchUser(): AxiosPromise<User> {
    return this.apptuitAxios.get("/api/user").then(u => {
      const user = (u.data as User);
      user.isBicycleUser = user.email.endsWith(`@${appConfig.branding.emailDomain}`);
      return u;
    });
  }

  fetchOrg(): AxiosPromise<Org> {
    return this.apptuitAxios.get("/api/org");
  }

  herokuLogin(username: string, password: string) {

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return this.customAxios({
      method: 'POST',
      url: '/login',
      data: params
    });
  }

  herokuFetchUserInfo(): AxiosPromise<User> {
    return this.customAxios.get("/user", { withCredentials: true });
  }

}

const loginApiService = new LoginApiService();

export default loginApiService as LoginApiService;
