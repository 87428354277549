import { isEmpty } from "lodash";
import { NumericDataInfo } from "./types";
import { getNumericDataConfiguration } from "./constants";

export function checkIsNaN(input: string): boolean {
  return isNaN(Number(input));
}

export const getNumberInfo = (numValue: string, disableAbbreviations: boolean, locale?: string): NumericDataInfo => {
  const numDataInfo: NumericDataInfo = {
    isValid: false,
    abbreviatedValue: '',
    absoluteValue: ''
  };

  if (isEmpty(numDataInfo)) {
    return numDataInfo;
  }

  if (numValue === "No Min" || numValue === "No Max") {
    numDataInfo.isValid = true;
    numDataInfo.abbreviatedValue = numValue;
    return numDataInfo;
  }

  const numDataConfig = getNumericDataConfiguration(locale);
  //check if numeric value is valid
  if (numDataConfig.regex.test(numValue)) {
    numDataInfo.isValid = true;
    numDataInfo.abbreviatedValue = numValue;

    //compute absolute value
    numDataInfo.absoluteValue = !checkIsNaN(numValue) ? Number(numValue).toString() :
      numDataConfig.convertToNumber(numValue).toString();

    //compute abbreviated value
    if (!disableAbbreviations && !checkIsNaN(numValue)) {
      numDataInfo.abbreviatedValue = numDataConfig.formatter.format(parseInt(numValue, 10));
    }
  }
  return numDataInfo;
};

