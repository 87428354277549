import { pick } from 'lodash';

const entityLookupData = {
  "Merchant:00000000-0000-0000-0000-0003a3fa1008:0": "Sofaclub",
  "Merchant:00000000-0000-0000-0000-0003a1924008:0": "Food52",
  "Merchant:00000000-0000-0000-0000-000350f420f8:0": "Overdrive Brands",
  "Merchant:00000000-0000-0000-0000-00038fc1a028:0": "Kelapo",
  "Merchant:00000000-0000-0000-0000-000393d640c8:0": "Talk Mobile",
  "Merchant:00000000-0000-0000-0000-00039cb0b0e0:0": "Phoenics Electronics",
  "Merchant:00000000-0000-0000-0000-00038ff130c8:0": "Level Up Development",
  "Merchant:00000000-0000-0000-0000-0003a3f4a008:0": "AlerStallings",
  "Merchant:00000000-0000-0000-0000-000350945050:0": "GreenWave Reality",
  "Merchant:00000000-0000-0000-0000-000393d9c0c8:0": "SOUTHERN DESIGNS",
  "Merchant:00000000-0000-0000-0000-0003f2200040:0": "Studio Movie Grill",
  "Merchant:00000000-0000-0000-0000-00035cc190a8:0": "Guidekick",
  "Merchant:00000000-0000-0000-0000-000393d1e0c8:0": "Greysox",
  "Merchant:00000000-0000-0000-0000-00038b0e5088:0": "The Revenue Optimization Companies",
  "Merchant:00000000-0000-0000-0000-000350960078:0": "Tenon Tours",
  "Merchant:00000000-0000-0000-0000-00038ff430c8:0": "Sustainable Supply",
  "Merchant:00000000-0000-0000-0000-000393dad0c8:0": "Nike",
  "Merchant:00000000-0000-0000-0000-000353669018:0": "Blount Fine Foods",
  "Merchant:00000000-0000-0000-0000-00039cb3d0e0:0": "Impact Networking",
  "Merchant:00000000-0000-0000-0000-00039caf40e0:0": "CFO Alliance",
  "Merchant:00000000-0000-0000-0000-00038ff150c8:0": "Weisser Distributing",
  "Merchant:00000000-0000-0000-0000-000350f550f8:0": "Los Angeles Distributing Company",
  "Merchant:00000000-0000-0000-0000-00038fead0c8:0": "James Martin Furniture",
  "Merchant:00000000-0000-0000-0000-00033afff0a0:0": "NanoRacks",
  "Merchant:00000000-0000-0000-0000-00033b0190a0:0": "Sweet Peach Wax & Sugaring Studio",
  "Merchant:00000000-0000-0000-0000-000383d34058:0": "Las Vegas Expo",
  "Merchant:00000000-0000-0000-0000-00033b0050a0:0": "Heinz",
  "Merchant:00000000-0000-0000-0000-000393e170c8:0": "sbug",
  "Merchant:00000000-0000-0000-0000-000350999078:0": "Xero Shoes",
  "Merchant:00000000-0000-0000-0000-00037f6790b0:0": "DEX Imaging",
  "Merchant:00000000-0000-0000-0000-0003f21ca040:0": "Cooper's Hawk Winery & Restaurants",
  "Merchant:00000000-0000-0000-0000-0003509a4078:0": "GreenCage Security",
  "Merchant:00000000-0000-0000-0000-00033a948020:0": "Gun.io",
  "Merchant:00000000-0000-0000-0000-000383ce1058:0": "SRS Distribution",
  "Merchant:00000000-0000-0000-0000-00039cafb0e0:0": "All Things Barbecue",
  "Merchant:00000000-0000-0000-0000-00034c0a8070:0": "Freddy's Frozen Custard & Steakburgers",
  "Merchant:00000000-0000-0000-0000-0003b39720c0:0": "The Cabinet Joint",
  "Merchant:00000000-0000-0000-0000-00038ff720c8:0": "GreenGoose!",
  "Merchant:00000000-0000-0000-0000-00035cbd80a8:0": "Soflo Mattress",
  "Merchant:00000000-0000-0000-0000-00039cb370e0:0": "Insight Global",
  "Merchant:00000000-0000-0000-0000-00039cae40e0:0": "Corkcicle",
  "Merchant:00000000-0000-0000-0000-00035362b018:0": "AA Metals",
  "Merchant:00000000-0000-0000-0000-00039cb620e0:0": "Gucash",
  "Merchant:00000000-0000-0000-0000-00038b151088:0": "Allbirds",
  "Merchant:00000000-0000-0000-0000-0003a3f5b008:0": "Pete and Gerry's Organics",
  "Merchant:00000000-0000-0000-0000-00039cb140e0:0": "complexnetwork",
  "Merchant:00000000-0000-0000-0000-0003c351e0d0:0": "American Paper Optics",
  "Merchant:00000000-0000-0000-0000-0003f21d8040:0": "simba",
  "Merchant:00000000-0000-0000-0000-00038fc62028:0": "The Reagor Dykes Auto Group",
  "Merchant:00000000-0000-0000-0000-0003b39b20c0:0": "GroupFlier",
  "Merchant:00000000-0000-0000-0000-00039cb3a0e0:0": "PreScouter",
  "Merchant:00000000-0000-0000-0000-00034e091078:0": "GreenBiz Group",
  "Merchant:00000000-0000-0000-0000-000350f460f8:0": "Blink Fitness",
  "Merchant:00000000-0000-0000-0000-0003c34db0d0:0": "Nootie",
  "Merchant:00000000-0000-0000-0000-0003a3fb7008:0": "Grain Management",
  "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0": "GroundedPower",
  "Merchant:00000000-0000-0000-0000-00038fc40028:0": "Evolution Event Solutions",
  "Merchant:00000000-0000-0000-0000-00033a946020:0": "knix",
  "Merchant:00000000-0000-0000-0000-00038fc6f028:0": "Greystone",
  "Merchant:00000000-0000-0000-0000-0003b39c50c0:0": "Marine Layer",
  "Merchant:00000000-0000-0000-0000-00038b0f1088:0": "Mosquito Squad",
  "Merchant:00000000-0000-0000-0000-00034e069078:0": "Computer Overhauls",
  "Merchant:00000000-0000-0000-0000-0003efbe0040:0": "Goettl Air Conditioning",
  "Merchant:00000000-0000-0000-0000-000393d6d0c8:0": "Artifact Uprising",
  "Merchant:00000000-0000-0000-0000-0003c35180d0:0": "Graffiti",
  "Merchant:00000000-0000-0000-0000-0003a3fbb008:0": "2chainz",
  "Merchant:00000000-0000-0000-0000-00035099c078:0": "Gramovox",
  "Merchant:00000000-0000-0000-0000-00036bc7e0b8:0": "Beef Jerky Outlet",
  "Merchant:00000000-0000-0000-0000-00038b128088:0": "Staples",
  "Merchant:00000000-0000-0000-0000-00036bc870b8:0": "onezerOne",
  "Merchant:00000000-0000-0000-0000-00034e0ca078:0": "College Hunks Hauling Junk and Moving (West Chester, PA)",
  "Merchant:00000000-0000-0000-0000-000393e160c8:0": "Bombas",
  "Merchant:00000000-0000-0000-0000-0003b39a90c0:0": "DriveTime",
  "Merchant:00000000-0000-0000-0000-0003b39a60c0:0": "Meriwether & Tharp",
  "Merchant:00000000-0000-0000-0000-0003a3f38008:0": "Beck Partners",
  "Merchant:00000000-0000-0000-0000-00034e012078:0": "Peachtree Tents & Events",
  "Merchant:00000000-0000-0000-0000-000383d14058:0": "Argotrak",
  "Merchant:00000000-0000-0000-0000-00038b1ab088:0": "The/Studio",
  "Merchant:00000000-0000-0000-0000-00038fe830c8:0": "Four Foods Group",
  "Merchant:00000000-0000-0000-0000-00033b0280a0:0": "Green Is Good",
  "Merchant:00000000-0000-0000-0000-000350981078:0": "KeenAlignment",
  "Merchant:00000000-0000-0000-0000-000383d2f058:0": "Hand Therapy Solutions",
  "Merchant:00000000-0000-0000-0000-00034c0b9070:0": "Squaremouth",
  "Merchant:00000000-0000-0000-0000-0003efc38040:0": "Grand Prix Holdings USA",
  "Merchant:00000000-0000-0000-0000-000393dee0c8:0": "Famous Toastery",
  "Merchant:00000000-0000-0000-0000-0003f21c7040:0": "Raney's",
  "Merchant:00000000-0000-0000-0000-00033b01c0a0:0": "FlexPrint Managed Print Solutions",
  "Merchant:00000000-0000-0000-0000-00038fee20c8:0": "Gro",
  "Merchant:00000000-0000-0000-0000-000350998050:0": "Ivy Lane",
  "Merchant:00000000-0000-0000-0000-0003509d9078:0": "Green Plug",
  "Merchant:00000000-0000-0000-0000-000383d57058:0": "Savewize Wholesale Distribution",
  "Merchant:00000000-0000-0000-0000-0003efbc9040:0": "Villa Dolce Gelato",
  "Merchant:00000000-0000-0000-0000-00038fe820c8:0": "Semihandmade",
  "Merchant:00000000-0000-0000-0000-00034c09b070:0": "FranchiseHelp",
  "Merchant:00000000-0000-0000-0000-0003b39960c0:0": "EmazingLights / iHeartRaves / IntoTheAM",
  "Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0": "Franklin Media",
  "Merchant:00000000-0000-0000-0000-00034c0b8070:0": "Groove",
  "Merchant:00000000-0000-0000-0000-00035cc130a8:0": "Sterling Talent Solutions",
  "Merchant:00000000-0000-0000-0000-000350938050:0": "Private Jets",
  "Merchant:00000000-0000-0000-0000-000393d2b0c8:0": "EventSphere",
  "Merchant:00000000-0000-0000-0000-000350f490f8:0": "Cogent Analytics",
  "Merchant:00000000-0000-0000-0000-00038b127088:0": "MGX Copy",
  "Merchant:00000000-0000-0000-0000-00038fc67028:0": "BizIQ",
  "Merchant:00000000-0000-0000-0000-00038fbf4028:0": "Pure Solutions",
  "Merchant:00000000-0000-0000-0000-0003843f00e8:0": "PCI Group",
  "Merchant:00000000-0000-0000-0000-00038b10a088:0": "Readerlink",
  "Merchant:00000000-0000-0000-0000-00033a961020:0": "Rising Sun Farms",
  "Merchant:00000000-0000-0000-0000-00039caf90e0:0": "Level 2 Legal Solutions",
  "Merchant:00000000-0000-0000-0000-0003efc19040:0": "Pure Romance",
  "Merchant:00000000-0000-0000-0000-0003843c90e8:0": "Hawkers Asian Street Fare",
  "Merchant:00000000-0000-0000-0000-00039caf10e0:0": "Sender One Climbing",
  "Merchant:00000000-0000-0000-0000-0003a3f6e008:0": "Monogram Foods",
  "Merchant:00000000-0000-0000-0000-00038feb10c8:0": "Heartfelt Creations",
  "Merchant:00000000-0000-0000-0000-00037f6bb0b0:0": "Titan",
  "Merchant:00000000-0000-0000-0000-0003f21bc040:0": "Parkmobile",
  "Merchant:00000000-0000-0000-0000-000350979050:0": "ONEHOPE",
  "Merchant:00000000-0000-0000-0000-0003b3a590c0:0": "Automotive Avenues",
  "Merchant:00000000-0000-0000-0000-00035098a078:0": "Lightfoot Mechanical",
  "Merchant:00000000-0000-0000-0000-0003b39800c0:0": "Grouper",
  "Merchant:00000000-0000-0000-0000-00033a91a020:0": "Eventellect",
  "Merchant:00000000-0000-0000-0000-000383ce5058:0": "AOK RVs",
  "Merchant:00000000-0000-0000-0000-00035cc560a8:0": "Air Filter Factory",
  "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0": "Royal Vending",
  "Merchant:00000000-0000-0000-0000-00038fbfb028:0": "Fifteen Five",
  "Merchant:00000000-0000-0000-0000-000393d6f0c8:0": "Proozy",
  "Merchant:00000000-0000-0000-0000-0003f21d3040:0": "Alpha Connect",
  "Merchant:00000000-0000-0000-0000-00035cbe10a8:0": "Xtenfer Consulting",
  "Merchant:00000000-0000-0000-0000-00034e074078:0": "Watterson",
  "Merchant:00000000-0000-0000-0000-00038b0f0088:0": "Pharmapacks",
  "Merchant:00000000-0000-0000-0000-00038b0f6088:0": "Healthy Harvest",
  "Merchant:00000000-0000-0000-0000-000383d15058:0": "Amijag",
  "Merchant:00000000-0000-0000-0000-00033b0120a0:0": "PPT Solutions (Oklahoma)",
  "Merchant:00000000-0000-0000-0000-0003a3f3d008:0": "Halton",
  "Merchant:00000000-0000-0000-0000-00035cc390a8:0": "Russell Cellular",
  "Merchant:00000000-0000-0000-0000-0003a3f30008:0": "Tender Loving Empire",
  "Merchant:00000000-0000-0000-0000-00036bcae0b8:0": "MPACT Strategic Consulting",
  "Merchant:00000000-0000-0000-0000-000383cf4058:0": "Magnolia Innovation",
  "Merchant:00000000-0000-0000-0000-00034c0c6070:0": "EAU DE LUXE",
  "Merchant:00000000-0000-0000-0000-0003843fb0e8:0": "Guestmob",
  "Merchant:00000000-0000-0000-0000-00038b16e088:0": "Gymshark",
  "Merchant:00000000-0000-0000-0000-0003b39b70c0:0": "HMS Global Maritime",
  "Merchant:00000000-0000-0000-0000-00038b133088:0": "Agile Velocity",
  "Merchant:00000000-0000-0000-0000-00034e0d7078:0": "Gravy",
  "Merchant:00000000-0000-0000-0000-00038fc41028:0": "MJ Seats",
  "Merchant:00000000-0000-0000-0000-00038fc44028:0": "Young Automotive Group",
  "Merchant:00000000-0000-0000-0000-0003f21ac040:0": "Tallwave",
  "Merchant:00000000-0000-0000-0000-00036bcd60b8:0": "dressbarn",
  "Merchant:00000000-0000-0000-0000-00038fef30c8:0": "Cable Ties Unlimited",
  "Merchant:00000000-0000-0000-0000-00039cb670e0:0": "Peridot Solutions",
  "Merchant:00000000-0000-0000-0000-000353661018:0": "Hawks",
  "Merchant:00000000-0000-0000-0000-000350923050:0": "Jersey Mike's Subs",
  "Merchant:00000000-0000-0000-0000-000393e240c8:0": "OrderMyGear",
  "Merchant:00000000-0000-0000-0000-000350999071:0": "Anchor Distributing",
  "Merchant:00000000-0000-0000-0000-00038fe9c0c1:0": "GraffitiGeo",
  "Merchant:00000000-0000-0000-0000-000350923054:0": "Green Biologics",
  "Merchant:00000000-0000-0000-0000-00039cb140e5:0": "The Wilson Group",
  "Merchant:00000000-0000-0000-0000-00038b1bf088:0": "GraffitiTech",
  "Merchant:00000000-0000-0000-0000-000350f640f8:0": "Granify",
  "Merchant:00000000-0000-0000-0000-00033affb0a0:0": "Dynamic Pricing Partners",
  "Merchant:00000000-0000-0000-0000-000350f480f8:0": "Gungroo",
  "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0": "RK Parisi Enterprises",
  "Merchant:00000000-0000-0000-0000-00035cbd70a8:0": "PR Management",
  "Merchant:00000000-0000-0000-0000-00035cc3a0a8:0": "WMK",
  "Merchant:00000000-0000-0000-0000-0003efbdc040:0": "Winc (formerly Club W)",
  "Merchant:00000000-0000-0000-0000-0003a3f25008:0": "reCoerce",
  "Merchant:00000000-0000-0000-0000-0003b3a5a0c0:0": "Rapha",
  "Merchant:00000000-0000-0000-0000-0003b39e60c0:0": "Gregory Environmental",
  "Merchant:00000000-0000-0000-0000-0003efbde040:0": "Grubster",
  "Merchant:00000000-0000-0000-0000-00038b1d0088:0": "Graftworx",
  "Merchant:00000000-0000-0000-0000-00036bcdc0b8:0": "Conrad Electronic AG",
  "Merchant:00000000-0000-0000-0000-00035cc060a8:0": "Greenopedia",
  "Merchant:00000000-0000-0000-0000-00033a918020:0": "Automotive Development Group",
  "Merchant:00000000-0000-0000-0000-000353642018:0": "Buckfire & Buckfire",
  "Merchant:00000000-0000-0000-0000-0003f2214040:0": "Guangdong Hengxing Group",
  "Merchant:00000000-0000-0000-0000-00034c08d070:0": "Done Right Merchandising",
  "Merchant:00000000-0000-0000-0000-00034c0eb070:0": "Gutenberg Technology",
  "Merchant:00000000-0000-0000-0000-00036bcc10b8:0": "Buildingstars (Charlotte, NC)",
  "Merchant:00000000-0000-0000-0000-000393d950c8:0": "GroupVisual.io",
  "Merchant:00000000-0000-0000-0000-000383d3e058:0": "Folkbridge",
  "Merchant:00000000-0000-0000-0000-0003a1968008:0": "Groovy Corp.",
  "Merchant:00000000-0000-0000-0000-00033b0380a0:0": "Grasswire",
  "Merchant:00000000-0000-0000-0000-0003a3f99008:0": "Guokang Health Management",
  "Merchant:00000000-0000-0000-0000-0003b398d0c0:0": "Trademasters Service",
  "Merchant:00000000-0000-0000-0000-00034c0ce070:0": "Event Decor Direct",
  "Merchant:00000000-0000-0000-0000-00038ff290c8:0": "Greystripe",
  "Merchant:00000000-0000-0000-0000-00039cb4e0e0:0": "Admiral",
  "Merchant:00000000-0000-0000-0000-00034c0e6070:0": "Hallspot",
  "Merchant:00000000-0000-0000-0000-00037f6dd0b0:0": "Gruppo La Patria",
  "Merchant:00000000-0000-0000-0000-000383cf8058:0": "Buzz Franchise Brands",
  "Merchant:00000000-0000-0000-0000-00035091e050:0": "Home Chef",
  "Merchant:00000000-0000-0000-0000-00038fc91028:0": "Green Valley Produce",
  "Merchant:00000000-0000-0000-0000-00034e092078:0": "Greenhouse Apps",
  "Merchant:00000000-0000-0000-0000-0003844190e8:0": "Greenway Health",
  "Merchant:00000000-0000-0000-0000-00038ff9b0c8:0": "Olympikus",
  "Merchant:00000000-0000-0000-0000-000350f5c0f8:0": "Interactions",
  "Merchant:00000000-0000-0000-0000-0003b3a550c0:0": "Mikasa",
  "Merchant:00000000-0000-0000-0000-00038fc07028:0": "Cabinets",
  "Merchant:00000000-0000-0000-0000-00033a968020:0": "GupShup",
  "Merchant:00000000-0000-0000-0000-000393d280c8:0": "Fracture",
  "Merchant:00000000-0000-0000-0000-00038ff0a0c8:0": "Grid2020",
  "Merchant:00000000-0000-0000-0000-000383d40058:0": "Greenwave Foods, Inc.",
  "Merchant:00000000-0000-0000-0000-0003a198a008:0": "JP Fuji Group",
  "Merchant:00000000-0000-0000-0000-00038b1c9088:0": "Halldis",
  "Merchant:00000000-0000-0000-0000-00034e0d3078:0": "Theindiacrafthouse",
  "Merchant:00000000-0000-0000-0000-0003c353b0d0:0": "Guangzhou Metech",
  "Merchant:00000000-0000-0000-0000-00034dfe4078:0": "Sports Made Personal",
  "Merchant:00000000-0000-0000-0000-00035cbe20a8:0": "Starlite Productions",
  "Merchant:00000000-0000-0000-0000-00038ff790c8:0": "Gulf States Cryotherapy",
  "Merchant:00000000-0000-0000-0000-000393d120c8:0": "Yamibuy",
  "Merchant:00000000-0000-0000-0000-00038ff8c0c8:0": "Pirma",
  "Merchant:00000000-0000-0000-0000-00038b15a088:0": "Groopie",
  "Merchant:00000000-0000-0000-0000-00036bcc80b8:0": "ShoppersChoice",
  "Merchant:00000000-0000-0000-0000-00034e01c078:0": "Lighting Unlimited",
  "Merchant:00000000-0000-0000-0000-0003a195f008:0": "GrandCentral",
  "Merchant:00000000-0000-0000-0000-00033a911020:0": "AllStaff Solutions",
  "Merchant:00000000-0000-0000-0000-00033a952020:0": "GreenNote",
  "Merchant:00000000-0000-0000-0000-00038fe910c8:0": "eComPartners",
  "Merchant:00000000-0000-0000-0000-00034c07e070:0": "Shefit",
  "Merchant:00000000-0000-0000-0000-0003844110e8:0": "Chrono24",
  "Merchant:00000000-0000-0000-0000-000393e080c8:0": "2XU",
  "Merchant:00000000-0000-0000-0000-00034e0bb078:0": "Greenvity Counications",
  "Merchant:00000000-0000-0000-0000-000393d2d0c8:0": "SomerTile",
  "Merchant:00000000-0000-0000-0000-000350fa70f8:0": "Handicraftshop",
  "Merchant:00000000-0000-0000-0000-00037f68d0b0:0": "Graft Concepts",
  "Merchant:00000000-0000-0000-0000-0003536a7018:0": "Zur Rose Suisse AG",
  "Merchant:00000000-0000-0000-0000-000393de50c8:0": "Distritech",
  "Merchant:00000000-0000-0000-0000-00038ff6f0c8:0": "Hadrian Electrical Engineering",
  "Merchant:00000000-0000-0000-0000-0003efc37040:0": "GraphSQL",
  "Merchant:00000000-0000-0000-0000-00033a95e020:0": "HALFPOPS",
  "Merchant:00000000-0000-0000-0000-0003a193b008:0": "GrowthForce",
  "Merchant:00000000-0000-0000-0000-00034c0dd070:0": "HALO Maritime Defense Systems",
  "Merchant:00000000-0000-0000-0000-00038b114088:0": "Zicom",
  "Merchant:00000000-0000-0000-0000-00035cbf10a8:0": "CVP (Customer Value Partners)",
  "Merchant:00000000-0000-0000-0000-0003a1976008:0": "Guest of a Guest",
  "Merchant:00000000-0000-0000-0000-00038fc66028:0": "Grafoid",
  "Merchant:00000000-0000-0000-0000-0003efbb5040:0": "PopSockets",
  "Merchant:00000000-0000-0000-0000-00038fcc4028:0": "H2HCare",
  "Merchant:00000000-0000-0000-0000-00038b1dd088:0": "HackMyPic",
  "Merchant:00000000-0000-0000-0000-00034e008078:0": "P.L.A.Y. Pet Lifestyle and You",
  "Merchant:00000000-0000-0000-0000-0003a3f9e008:0": "Zopper",
  "Merchant:00000000-0000-0000-0000-0003844270e8:0": "Latestone",
  "Merchant:00000000-0000-0000-0000-00038ff370c8:0": "Onda",
  "Merchant:00000000-0000-0000-0000-00038fed90c8:0": "HAM-IT",
  "Merchant:00000000-0000-0000-0000-0003c352c0d0:0": "Grand Cru",
  "Merchant:00000000-0000-0000-0000-00038442c0e8:0": "Lehner Versand AG",
  "Merchant:00000000-0000-0000-0000-00033a959020:0": "Greencart",
  "Merchant:00000000-0000-0000-0000-0003b3a720c0:0": "Voylla",
  "Merchant:00000000-0000-0000-0000-0003b3a2e0c0:0": "Munich",
  "Merchant:00000000-0000-0000-0000-00034e084078:0": "Growth Oriented Development Software",
  "Merchant:00000000-0000-0000-0000-0003c35520d0:0": "Sportique",
  "Merchant:00000000-0000-0000-0000-0003a1994008:0": "Haivision",
  "Merchant:00000000-0000-0000-0000-0003b399b0c0:0": "memoryBlue",
  "Merchant:00000000-0000-0000-0000-00037f68e0b0:0": "GRAM Acquisition",
  "Merchant:00000000-0000-0000-0000-00038b113088:0": "Dana's Bakery",
  "Merchant:00000000-0000-0000-0000-000383d1a058:0": "Graphite Software Corp.",
  "Merchant:00000000-0000-0000-0000-0003536bb018:0": "GW Services",
  "Merchant:00000000-0000-0000-0000-0003c352d0d0:0": "Grokr",
  "Merchant:00000000-0000-0000-0000-0003f221a040:0": "Gunn & Moore",
  "Merchant:00000000-0000-0000-0000-00038feb50c8:0": "Stria",
  "Merchant:00000000-0000-0000-0000-0003843cf0e8:0": "iHeartDogs",
  "Merchant:00000000-0000-0000-0000-0003b3a630c0:0": "Greenhouse Strategies",
  "Merchant:00000000-0000-0000-0000-00035362a018:0": "BoxyCharm",
  "Merchant:00000000-0000-0000-0000-0003b3a540c0:0": "Hactus",
  "Merchant:00000000-0000-0000-0000-00034c0c9070:0": "Gravity Renewables",
  "Merchant:00000000-0000-0000-0000-00039cb6b0e0:0": "Graph Story",
  "Merchant:00000000-0000-0000-0000-00034e017078:0": "Freedom Boat Club",
  "Merchant:00000000-0000-0000-0000-000353699018:0": "Brine",
  "Merchant:00000000-0000-0000-0000-00038fc7a028:0": "Guo Xian Scientific and Technical Corporation",
  "Merchant:00000000-0000-0000-0000-000393de40c8:0": "GSIP Holdings",
  "Merchant:00000000-0000-0000-0000-0003a3f2b008:0": "SleekEZ",
  "Merchant:00000000-0000-0000-0000-0003a3fad008:0": "Grocery Shopping Network",
  "Merchant:00000000-0000-0000-0000-0003509c5078:0": "L37 Creative",
  "Merchant:00000000-0000-0000-0000-00037f6d70b0:0": "GT Channel",
  "Merchant:00000000-0000-0000-0000-0003efc03040:0": "Elevation Sports",
  "Merchant:00000000-0000-0000-0000-00037f6d10b0:0": "Grows Up",
  "Merchant:00000000-0000-0000-0000-00038b171088:0": "Greengro Technologies",
  "Merchant:00000000-0000-0000-0000-000393dbf0c8:0": "Graphenics",
  "Merchant:00000000-0000-0000-0000-0003844120e8:0": "Prestogifts",
  "Merchant:00000000-0000-0000-0000-0003b39790c0:0": "Snap36",
  "Merchant:00000000-0000-0000-0000-0003f21c4040:0": "GreenHunter Energy",
  "Merchant:00000000-0000-0000-0000-00038fc6a028:0": "CI&T",
  "Merchant:00000000-0000-0000-0000-00038ff300c8:0": "GrowYo",
  "Merchant:00000000-0000-0000-0000-00035cbf00a8:0": "Kung Fu Tea",
  "Merchant:00000000-0000-0000-0000-0003b39d00c0:0": "GranData",
  "Merchant:00000000-0000-0000-0000-000350fac0f8:0": "GroupTalent",
  "Merchant:00000000-0000-0000-0000-000393e020c8:0": "GTV Corporation",
  "Merchant:00000000-0000-0000-0000-000353683018:0": "Grokker",
  "Merchant:00000000-0000-0000-0000-00035368f018:0": "Craftsvilla",
  "Merchant:00000000-0000-0000-0000-00034e076078:0": "Greenhouse Software",
  "Merchant:00000000-0000-0000-0000-00034c08c070:0": "Varghese Suersett PLLC",
  "Merchant:00000000-0000-0000-0000-0003a1969008:0": "Greenwood Hall",
  "Merchant:00000000-0000-0000-0000-00036bcc00b8:0": "Guaranteach",
  "Merchant:00000000-0000-0000-0000-000393d3b0c8:0": "Volio Imports",
  "Merchant:00000000-0000-0000-0000-00038fcb6028:0": "Sondico",
  "Merchant:00000000-0000-0000-0000-0003509bd078:0": "Quechua",
  "Merchant:00000000-0000-0000-0000-0003b3a6f0c0:0": "Kickers",
  "Merchant:00000000-0000-0000-0000-0003536b5018:0": "Acerbis",
  "Merchant:00000000-0000-0000-0000-00038fc90028:0": "NEWPRO operating",
  "Merchant:00000000-0000-0000-0000-00039cb4f0e0:0": "Inspect-All Services",
  "Merchant:00000000-0000-0000-0000-00036bcb90b8:0": "GTI Capital Group",
  "Merchant:00000000-0000-0000-0000-00035093a050:0": "Third Party Pet",
  "Merchant:00000000-0000-0000-0000-0003a3f74008:0": "FlashRouters",
  "Merchant:00000000-0000-0000-0000-0003a1967008:0": "Champion",
  "Merchant:00000000-0000-0000-0000-0003b3a230c0:0": "LUTA",
  "Merchant:00000000-0000-0000-0000-0003efbdd040:0": "GreenWizard",
  "Merchant:00000000-0000-0000-0000-0003f21fc040:0": "Greenside Holdings",
  "Merchant:00000000-0000-0000-0000-00033a953020:0": "GROUNDBOOTH",
  "Merchant:00000000-0000-0000-0000-0003c353a0d0:0": "GT Solar",
  "Merchant:00000000-0000-0000-0000-0003509a3050:0": "Reebok",
  "Merchant:00000000-0000-0000-0000-00034e03a078:0": "Hack Upstate",
  "Merchant:00000000-0000-0000-0000-0003844210e8:0": "GrowBLOX",
  "Merchant:00000000-0000-0000-0000-0003b3a640c0:0": "GLocal",
  "Merchant:00000000-0000-0000-0000-0003843c30e8:0": "Storebound",
  "Merchant:00000000-0000-0000-0000-00034e043078:0": "GroupThat, Inc.",
  "Merchant:00000000-0000-0000-0000-0003c34dc0d0:0": "Deal Partners",
  "Merchant:00000000-0000-0000-0000-00038fc56028:0": "GridNetworks",
  "Merchant:00000000-0000-0000-0000-00039caea0e0:0": "The Auto Cave",
  "Merchant:00000000-0000-0000-0000-000383d0c058:0": "Renesent",
  "Merchant:00000000-0000-0000-0000-00035097c078:0": "Graitec",
  "Merchant:00000000-0000-0000-0000-00038fc99028:0": "Group Coerce",
  "Merchant:00000000-0000-0000-0000-00034e0b0078:0": "Zookr",
  "Merchant:00000000-0000-0000-0000-00038b11f088:0": "3 Blind Mice Window Coverings",
  "Merchant:00000000-0000-0000-0000-00038ff470c8:0": "Hail Varsity",
  "Merchant:00000000-0000-0000-0000-0003efc27040:0": "Mitchell & Ness",
  "Merchant:00000000-0000-0000-0000-00038fc4d028:0": "Merrimak Capital Company",
  "Merchant:00000000-0000-0000-0000-0003c34d10d0:0": "Grant Enterprises",
  "Merchant:00000000-0000-0000-0000-00038441d0e8:0": "Groovideo",
  "Merchant:00000000-0000-0000-0000-000393d7c0c8:0": "Groopic Inc.",
  "Merchant:00000000-0000-0000-0000-000350978050:0": "Grey Island Energy",
  "Merchant:00000000-0000-0000-0000-0003efc20040:0": "Gudville",
  "Merchant:00000000-0000-0000-0000-00037f6ba0b0:0": "Xotezo",
  "Merchant:00000000-0000-0000-0000-00038ff700c8:0": "Carbrini",
  "Merchant:00000000-0000-0000-0000-00038feec0c8:0": "Andes Global Trading",
  "Merchant:00000000-0000-0000-0000-00038feb60c8:0": "ASP-America's Swiing Pool Company",
  "Merchant:00000000-0000-0000-0000-000350f970f8:0": "League",
  "Merchant:00000000-0000-0000-0000-0003efbc4040:0": "Akervall Technologies",
  "Merchant:00000000-0000-0000-0000-0003f21f1040:0": "Next Day Dumpsters",
  "Merchant:00000000-0000-0000-0000-000393dd00c8:0": "Molten",
  "Merchant:00000000-0000-0000-0000-0003b39e70c0:0": "Hampton Creek",
  "Merchant:00000000-0000-0000-0000-00033a91e020:0": "EDGE Business Systems",
  "Merchant:00000000-0000-0000-0000-0003844260e8:0": "Lonsdale",
  "Merchant:00000000-0000-0000-0000-00033b0300a0:0": "GroundMetrics",
  "Merchant:00000000-0000-0000-0000-00034e0a7078:0": "GruvIt",
  "Merchant:00000000-0000-0000-0000-0003efbed040:0": "Provogue",
  "Merchant:00000000-0000-0000-0000-0003844040e8:0": "GT Nexus",
  "Merchant:00000000-0000-0000-0000-0003c35590d0:0": "Majestic Athletic",
  "Merchant:00000000-0000-0000-0000-0003843e60e8:0": "Guidecentral",
  "Merchant:00000000-0000-0000-0000-0003509bb078:0": "ISC",
  "Merchant:00000000-0000-0000-0000-000393d630c8:0": "American Small Business Alliance",
  "Merchant:00000000-0000-0000-0000-00036bceb0b8:0": "GuideSpark",
  "Merchant:00000000-0000-0000-0000-00038ff190c8:0": "Greetz",
  "Merchant:00000000-0000-0000-0000-0003b3a130c0:0": "GrowOp Technology",
  "Merchant:00000000-0000-0000-0000-00038ff970c8:0": "Nanque",
  "Merchant:00000000-0000-0000-0000-00038fc82028:0": "Gramble World BV",
  "Merchant:00000000-0000-0000-0000-000350953050:0": "Sunseed Research",
  "Merchant:00000000-0000-0000-0000-00035366c018:0": "W Services Group",
  "Merchant:00000000-0000-0000-0000-00036bcc70b8:0": "Granite Investment Group",
  "Merchant:00000000-0000-0000-0000-00038ff040c8:0": "GraffitiGeo",
  "Merchant:00000000-0000-0000-0000-0003a3f6d008:0": "Graviton",
  "Merchant:00000000-0000-0000-0000-0003a3fa6008:0": "Green Chips",
  "Merchant:00000000-0000-0000-0000-00039cb5d0e0:0": "Graphdive",
  "Merchant:00000000-0000-0000-0000-00038fc2a028:0": "Knockerball USA",
  "Merchant:00000000-0000-0000-0000-00038fcba028:0": "H&F Bread Company",
  "Merchant:00000000-0000-0000-0000-0003536ae018:0": "Gurnard Perch Sophisticated Technologies",
  "Merchant:00000000-0000-0000-0000-00034e02c078:0": "Grow the Planet",
  "Merchant:00000000-0000-0000-0000-000350983078:0": "Gri Bros",
  "Merchant:00000000-0000-0000-0000-00038ff5b0c8:0": "Cariloha",
  "Merchant:00000000-0000-0000-0000-00034e0a0078:0": "HALO2CLOUD",
  "Merchant:00000000-0000-0000-0000-0003f21a1040:0": "All American Entertainment",
  "Merchant:00000000-0000-0000-0000-0003a197d008:0": "Gullivearth",
  "Merchant:00000000-0000-0000-0000-000350968050:0": "Graine de Cadeaux",
  "Merchant:00000000-0000-0000-0000-00034c0e0070:0": "Greener Expressions",
  "Merchant:00000000-0000-0000-0000-000353676018:0": "GuidesMob",
  "Merchant:00000000-0000-0000-0000-0003b39a40c0:0": "Marjac Ventures",
  "Merchant:00000000-0000-0000-0000-00038ff2d0c8:0": "Growl Media",
  "Merchant:00000000-0000-0000-0000-00038ff770c8:0": "Greenpie",
  "Merchant:00000000-0000-0000-0000-000350993078:0": "Grow",
  "Merchant:00000000-0000-0000-0000-00038b1a3088:0": "Soapbox",
  "Merchant:00000000-0000-0000-0000-000393deb0c8:0": "Gura Gear",
  "Merchant:00000000-0000-0000-0000-0003efbf7040:0": "Klymit",
  "Merchant:00000000-0000-0000-0000-00035093c050:0": "Owenby Law",
  "Merchant:00000000-0000-0000-0000-00038440f0e8:0": "GVISP 1",
  "Merchant:00000000-0000-0000-0000-000350986050:0": "SkySale Schweiz GmbH",
  "Merchant:00000000-0000-0000-0000-0003a3fba008:0": "Guam Pak Express",
  "Merchant:00000000-0000-0000-0000-000383d38058:0": "Babyoye",
  "Merchant:00000000-0000-0000-0000-00038ff620c8:0": "GreenTec-USA",
  "Merchant:00000000-0000-0000-0000-00039cb160e0:0": "Buyers Edge",
  "Merchant:00000000-0000-0000-0000-00034c0c0070:0": "Sayva Solutions",
  "Merchant:00000000-0000-0000-0000-00038fc92028:0": "GuestCentric Systems",
  "Merchant:00000000-0000-0000-0000-00035098a050:0": "GroundLink",
  "Merchant:00000000-0000-0000-0000-0003c35260d0:0": "Greengate Power",
  "Merchant:00000000-0000-0000-0000-00033a966020:0": "GreenCloud",
  "Merchant:00000000-0000-0000-0000-0003a196d008:0": "Performax",
  "Merchant:00000000-0000-0000-0000-00034e08a078:0": "Great Mobile Meetings",
  "Merchant:00000000-0000-0000-0000-0003a3fb6008:0": "Gryphon Networks",
  "Merchant:00000000-0000-0000-0000-000350f670f8:0": "Balsam Hill",
  "Merchant:00000000-0000-0000-0000-000383d41058:0": "Grand St.",
  "Merchant:00000000-0000-0000-0000-00033b0470a0:0": "GreenPeak Technologies",
  "Merchant:00000000-0000-0000-0000-000393dcb0c8:0": "Meyba",
  "Merchant:00000000-0000-0000-0000-00034e04d078:0": "Hissho Sushi",
  "Merchant:00000000-0000-0000-0000-000393e030c8:0": "GROUNDFLOOR",
  "Merchant:00000000-0000-0000-0000-000393d810c8:0": "Granite Properties",
  "Merchant:00000000-0000-0000-0000-000350fa10f8:0": "Grand Circus",
  "Merchant:00000000-0000-0000-0000-00036bcd50b8:0": "Gust",
  "Merchant:00000000-0000-0000-0000-00037f6780b0:0": "Segero",
  "Merchant:00000000-0000-0000-0000-000393e150c8:0": "Rifle Paper Co.",
  "Merchant:00000000-0000-0000-0000-00034c08b070:0": "The Pi Group",
  "Merchant:00000000-0000-0000-0000-0003b3a200c0:0": "Guardity Technologies",
  "Merchant:00000000-0000-0000-0000-0003a3f7c008:0": "HacemeUnRegalo",
  "Merchant:00000000-0000-0000-0000-0003a3f90008:0": "Grafighters",
  "Merchant:00000000-0000-0000-0000-000350986078:0": "Guard RFID Solutions",
  "Merchant:00000000-0000-0000-0000-00033b03f0a0:0": "Prosperity Organic Foods",
  "Merchant:00000000-0000-0000-0000-00038fc8d028:0": "Grapevine Talk",
  "Merchant:00000000-0000-0000-0000-0003509c7078:0": "Greener Solutions Scrap Metal Recycling",
  "Merchant:00000000-0000-0000-0000-00034c0db070:0": "Gritness",
  "Merchant:00000000-0000-0000-0000-0003a3f89008:0": "Gaatha",
  "Merchant:00000000-0000-0000-0000-000393e190c8:0": "GraphOn",
  "Merchant:00000000-0000-0000-0000-0003c35340d0:0": "gumi",
  "Merchant:00000000-0000-0000-0000-0003efbfd040:0": "iBaby",
  "Merchant:00000000-0000-0000-0000-0003b3a070c0:0": "Prospecs",
  "Merchant:00000000-0000-0000-0000-00039cb3e0e0:0": "Arizona Fireplaces",
  "Merchant:00000000-0000-0000-0000-000383d08058:0": "KLDiscovery",
  "Merchant:00000000-0000-0000-0000-0003efc26040:0": "GreenRoad Technologies",
  "Merchant:00000000-0000-0000-0000-00038b1e8088:0": "StalkBuyLove",
  "Merchant:00000000-0000-0000-0000-00035cbd20a8:0": "Extraordinary Journeys",
  "Merchant:00000000-0000-0000-0000-00034c0cc070:0": "Faherty",
  "Merchant:00000000-0000-0000-0000-00034dfeb078:0": "Nexsentio",
  "Merchant:00000000-0000-0000-0000-00034dfd8078:0": "Silk Worldwide",
  "Merchant:00000000-0000-0000-0000-00038feef0c8:0": "The Denzel Group",
  "Merchant:00000000-0000-0000-0000-0003f21ba040:0": "Web River Group",
  "Merchant:00000000-0000-0000-0000-00038fc81028:0": "GreenBytes",
  "Merchant:00000000-0000-0000-0000-00033b05c0a0:0": "DeinDeal AG",
  "Merchant:00000000-0000-0000-0000-000393db40c8:0": "Homesake",
  "Merchant:00000000-0000-0000-0000-00038fc84028:0": "GuardianEdge Technologies",
  "Merchant:00000000-0000-0000-0000-00035099b050:0": "GroundCntrl",
  "Merchant:00000000-0000-0000-0000-00035cc540a8:0": "Future Energy Solutions",
  "Merchant:00000000-0000-0000-0000-0003c35370d0:0": "GroupVox",
  "Merchant:00000000-0000-0000-0000-00033a8ed020:0": "Cloudbeds",
  "Merchant:00000000-0000-0000-0000-000383d33058:0": "Lululemon Athletica",
  "Merchant:00000000-0000-0000-0000-00039cb0f0e0:0": "Side By Side Stuff",
  "Merchant:00000000-0000-0000-0000-00038b0d8088:0": "Magnolia Boutique",
  "Merchant:00000000-0000-0000-0000-00033b0360a0:0": "Grand Rounds",
  "Merchant:00000000-0000-0000-0000-00033b0000a0:0": "Spark Innovation",
  "Merchant:00000000-0000-0000-0000-00038b16a088:0": "Online Rewards",
  "Merchant:00000000-0000-0000-0000-0003b3a440c0:0": "Granular",
  "Merchant:00000000-0000-0000-0000-0003efbe5040:0": "Michael Hyatt & Company",
  "Merchant:00000000-0000-0000-0000-00038ff360c8:0": "Granite Technologies",
  "Merchant:00000000-0000-0000-0000-00038b1de088:0": "Hamilton Insurance Group",
  "Merchant:00000000-0000-0000-0000-00038b19a088:0": "Firstcry",
  "Merchant:00000000-0000-0000-0000-00034c0f3070:0": "Mondetta",
  "Merchant:00000000-0000-0000-0000-0003efbbb040:0": "Peddle",
  "Merchant:00000000-0000-0000-0000-00038fc5a028:0": "Purple Onion Catering",
  "Merchant:00000000-0000-0000-0000-00033b0620a0:0": "Guide Financial",
  "Merchant:00000000-0000-0000-0000-00038fc52028:0": "GridMarkets",
  "Merchant:00000000-0000-0000-0000-000353635018:0": "General Materials",
  "Merchant:00000000-0000-0000-0000-0003efc2c040:0": "Growish",
  "Merchant:00000000-0000-0000-0000-0003a196b008:0": "Guardant Health",
  "Merchant:00000000-0000-0000-0000-00035092b050:0": "Growth Solutions",
  "Merchant:00000000-0000-0000-0000-00038ff200c8:0": "Greenphire",
  "Merchant:00000000-0000-0000-0000-0003a3f7f008:0": "GrayBug",
  "Merchant:00000000-0000-0000-0000-0003c34e50d0:0": "The Idea Box - Powered by Proforma",
  "Merchant:00000000-0000-0000-0000-00038b178088:0": "Halloran Consulting Group",
  "Merchant:00000000-0000-0000-0000-0003843e50e8:0": "Graze",
  "Merchant:00000000-0000-0000-0000-00034e0da078:0": "Alanic",
  "Merchant:00000000-0000-0000-0000-000353636018:0": "E Simplified",
  "Merchant:00000000-0000-0000-0000-00034c0fb070:0": "HackerHAND",
  "Merchant:00000000-0000-0000-0000-0003b3a1a0c0:0": "Gyft",
  "Merchant:00000000-0000-0000-0000-00035095e050:0": "GTI",
  "Merchant:00000000-0000-0000-0000-00037f6db0b0:0": "Santana",
  "Merchant:00000000-0000-0000-0000-00039cb6a0e0:0": "Conforama Suisse S.A.",
  "Merchant:00000000-0000-0000-0000-000350962078:0": "RDS Team",
  "Merchant:00000000-0000-0000-0000-00034dff9078:0": "Luke's Lobster",
  "Merchant:00000000-0000-0000-0000-00039cb0e0e0:0": "GreenFuel",
  "Merchant:00000000-0000-0000-0000-00038ff070c8:0": "Grasshoppers!",
  "Merchant:00000000-0000-0000-0000-000383d5d058:0": "Grapeshot",
  "Merchant:00000000-0000-0000-0000-00038fca5028:0": "Greasebook",
  "Merchant:00000000-0000-0000-0000-00034e09d078:0": "Fashionandyou",
  "Merchant:00000000-0000-0000-0000-000393e1b0c8:0": "Great Dream",
  "Merchant:00000000-0000-0000-0000-00033b0730a0:0": "Grassroots Business Fund",
  "Merchant:00000000-0000-0000-0000-00038ff400c8:0": "GuestShots",
  "Merchant:00000000-0000-0000-0000-00037f6e10b0:0": "Marathon",
  "Merchant:00000000-0000-0000-0000-0003b3a730c0:0": "Coop Bau+Hobby",
  "Merchant:00000000-0000-0000-0000-00035cbf60a8:0": "Proforma 3rd Degree Marketing",
  "Merchant:00000000-0000-0000-0000-0003a3f40008:0": "Cookie Cutters Haircuts for Kids",
  "Merchant:00000000-0000-0000-0000-000393d900c8:0": "Grata",
  "Merchant:00000000-0000-0000-0000-000350f8d0f8:0": "Grow Mobile",
  "Merchant:00000000-0000-0000-0000-000353689018:0": "Great Parents Academy",
  "Merchant:00000000-0000-0000-0000-0003efbf4040:0": "Green Box Online Science and Technology",
  "Merchant:00000000-0000-0000-0000-00039cb380e0:0": "Ground Zero Group Corporation",
  "Merchant:00000000-0000-0000-0000-00035098b078:0": "Grenville Strategic Royalty",
  "Merchant:00000000-0000-0000-0000-0003f21e3040:0": "Graphicly",
  "Merchant:00000000-0000-0000-0000-000393dc50c8:0": "Hi-Tec",
  "Merchant:00000000-0000-0000-0000-00035095c078:0": "The Hardware Company",
  "Merchant:00000000-0000-0000-0000-00038ff5f0c8:0": "Head",
  "Merchant:00000000-0000-0000-0000-0003a1941008:0": "Bulk TV & Internet",
  "Merchant:00000000-0000-0000-0000-0003b39ff0c0:0": "Guardian Healthcare",
  "Merchant:00000000-0000-0000-0000-00035cc530a8:0": "Rep Fitness",
  "Merchant:00000000-0000-0000-0000-0003509b6078:0": "Gonoise",
  "Merchant:00000000-0000-0000-0000-0003f2193040:0": "Affordable Luxury Group",
  "Merchant:00000000-0000-0000-0000-0003efc29040:0": "Togofogo",
  "Merchant:00000000-0000-0000-0000-000393df40c8:0": "GreenerU",
  "Merchant:00000000-0000-0000-0000-0003efbfa040:0": "Greenland Hong Kong Holdings Limited",
  "Merchant:00000000-0000-0000-0000-0003b3a040c0:0": "GreenTechnology Innovations",
  "Merchant:00000000-0000-0000-0000-0003f21dd040:0": "Nomad",
  "Merchant:00000000-0000-0000-0000-000350f7a0f8:0": "City Winery",
  "Merchant:00000000-0000-0000-0000-0003843d90e8:0": "1-Stop Translation USA",
  "Merchant:00000000-0000-0000-0000-00038b1e5088:0": "H2020",
  "Merchant:00000000-0000-0000-0000-00039cb650e0:0": "Habbits",
  "Merchant:00000000-0000-0000-0000-00034c0cf070:0": "Graspr",
  "Merchant:00000000-0000-0000-0000-00033a93b020:0": "Handipoints",
  "Merchant:00000000-0000-0000-0000-0003844330e8:0": "Bluestone",
  "Merchant:00000000-0000-0000-0000-0003a195e008:0": "Gunosy",
  "Merchant:00000000-0000-0000-0000-000353655018:0": "Infinity Massage Chairs",
  "Merchant:00000000-0000-0000-0000-00038fe7e0c8:0": "Chad T. WIlson Law Firm PLLC",
  "Merchant:00000000-0000-0000-0000-0003843e20e8:0": "Granite Networks",
  "Merchant:00000000-0000-0000-0000-00038b18e088:0": "O'Neills",
  "Merchant:00000000-0000-0000-0000-0003b3a500c0:0": "Grooveshark",
  "Merchant:00000000-0000-0000-0000-00039cb220e0:0": "Gumiyo",
  "Merchant:00000000-0000-0000-0000-00035cc200a8:0": "Clickstop",
  "Merchant:00000000-0000-0000-0000-0003efbe1040:0": "HandInScan",
  "Merchant:00000000-0000-0000-0000-00038ff7f0c8:0": "Erre?",
  "Merchant:00000000-0000-0000-0000-00038fc61028:0": "Great Atlantic & Pacific Tea",
  "Merchant:00000000-0000-0000-0000-00038ff860c8:0": "GT Urological",
  "Merchant:00000000-0000-0000-0000-000393d5a0c8:0": "Factory Expo Home Centers",
  "Merchant:00000000-0000-0000-0000-00038fc68028:0": "Hadapt",
  "Merchant:00000000-0000-0000-0000-000350972050:0": "Hacking the President Film Partners",
  "Merchant:00000000-0000-0000-0000-00034e060078:0": "Graphenix Development",
  "Merchant:00000000-0000-0000-0000-0003b3a400c0:0": "Gridcentric",
  "Merchant:00000000-0000-0000-0000-00035cc100a8:0": "Gravity",
  "Merchant:00000000-0000-0000-0000-00038fcd5028:0": "Gurubooks",
  "Merchant:00000000-0000-0000-0000-000350989078:0": "Giftcart",
  "Merchant:00000000-0000-0000-0000-00038fe850c8:0": "Transparent BPO",
  "Merchant:00000000-0000-0000-0000-00034e06d078:0": "Nykaa",
  "Merchant:00000000-0000-0000-0000-0003a199a008:0": "Quick",
  "Merchant:00000000-0000-0000-0000-000393dca0c8:0": "Graphene Energy",
  "Merchant:00000000-0000-0000-0000-00039cb050e0:0": "Northern Planet",
  "Merchant:00000000-0000-0000-0000-00036bce40b8:0": "haku",
  "Merchant:00000000-0000-0000-0000-00034c0bf070:0": "Gymtrack",
  "Merchant:00000000-0000-0000-0000-00035cc570a8:0": "Groxis",
  "Merchant:00000000-0000-0000-0000-00033a955020:0": "HALSCION",
  "Merchant:00000000-0000-0000-0000-00038ff760c8:0": "Guavus",
  "Merchant:00000000-0000-0000-0000-00038b185088:0": "Page Per Page",
  "Merchant:00000000-0000-0000-0000-00038fca9028:0": "Wilson",
  "Merchant:00000000-0000-0000-0000-00033b0330a0:0": "Groundswell Technologies",
  "Merchant:00000000-0000-0000-0000-0003a1955008:0": "Five Lakes Coffee",
  "Merchant:00000000-0000-0000-0000-000353672018:0": "Grocio",
  "Merchant:00000000-0000-0000-0000-00034e0a1078:0": "Hairdressr",
  "Merchant:00000000-0000-0000-0000-000353659018:0": "The Stem",
  "Merchant:00000000-0000-0000-0000-0003efbea040:0": "Moncler",
  "Merchant:00000000-0000-0000-0000-00033a912020:0": "DMedia Associates",
  "Merchant:00000000-0000-0000-0000-00036bcba0b8:0": "Grand",
  "Merchant:00000000-0000-0000-0000-000383d60058:0": "Coop Genossenschaft",
  "Merchant:00000000-0000-0000-0000-000350f810f8:0": "GT Energy",
  "Merchant:00000000-0000-0000-0000-000383d27058:0": "Columbia",
  "Merchant:00000000-0000-0000-0000-0003b3a600c0:0": "Ardent Learning",
  "Merchant:00000000-0000-0000-0000-0003a194e008:0": "Concierge Cleaning Services",
  "Merchant:00000000-0000-0000-0000-000353695018:0": "HandelabraGames",
  "Merchant:00000000-0000-0000-0000-00034e0b4078:0": "Luanvi",
  "Merchant:00000000-0000-0000-0000-0003b3a570c0:0": "New Era",
  "Merchant:00000000-0000-0000-0000-00034e067078:0": "Gray Hawk Payment Technologies",
  "Merchant:00000000-0000-0000-0000-00038b1c2088:0": "Green Zebra Grocery",
  "Merchant:00000000-0000-0000-0000-000393dda0c8:0": "HabitRPG",
  "Merchant:00000000-0000-0000-0000-00035363b018:0": "BigSpeak Speakers Bureau",
  "Merchant:00000000-0000-0000-0000-00033b0760a0:0": "Speedo",
  "Merchant:00000000-0000-0000-0000-00038ff3c0c8:0": "VYRIAN",
  "Merchant:00000000-0000-0000-0000-0003a1945008:0": "Groupe Adeuza",
  "Merchant:00000000-0000-0000-0000-00035097f078:0": "ShipSound",
  "Merchant:00000000-0000-0000-0000-000353652018:0": "Travlu",
  "Merchant:00000000-0000-0000-0000-00038fcb2028:0": "GreenLancer",
  "Merchant:00000000-0000-0000-0000-00038fef50c8:0": "Greenplum Software",
  "Merchant:00000000-0000-0000-0000-00038b1cb088:0": "GreenOwl Mobile",
  "Merchant:00000000-0000-0000-0000-000393e060c8:0": "Guardium",
  "Merchant:00000000-0000-0000-0000-00038ff230c8:0": "GymRealm",
  "Merchant:00000000-0000-0000-0000-00039cb250e0:0": "Indiangiftsportal",
  "Merchant:00000000-0000-0000-0000-0003b3a1d0c0:0": "GRR Systems",
  "Merchant:00000000-0000-0000-0000-0003efbf8040:0": "Grower's Secret",
  "Merchant:00000000-0000-0000-0000-00036bcd10b8:0": "GTRAN",
  "Merchant:00000000-0000-0000-0000-00035098b050:0": "Hachiko",
  "Merchant:00000000-0000-0000-0000-0003a1978008:0": "Penalty",
  "Merchant:00000000-0000-0000-0000-0003843db0e8:0": "Graffle",
  "Merchant:00000000-0000-0000-0000-00038ff530c8:0": "HYPERICE",
  "Merchant:00000000-0000-0000-0000-0003536a6018:0": "GreenPoint Partners",
  "Merchant:00000000-0000-0000-0000-00038fea90c8:0": "CO2 Partners",
  "Merchant:00000000-0000-0000-0000-0003c35170d0:0": "PEARL Schweiz GmbH",
  "Merchant:00000000-0000-0000-0000-00033b0560a0:0": "GramVaani",
  "Merchant:00000000-0000-0000-0000-00034e0aa078:0": "ImpressArt",
  "Merchant:00000000-0000-0000-0000-000350f9a0f8:0": "New Balance",
  "Merchant:00000000-0000-0000-0000-00036bca10b8:0": "MyJobHelper",
  "Merchant:00000000-0000-0000-0000-00038ff330c8:0": "GraphLab",
  "Merchant:00000000-0000-0000-0000-0003a1988008:0": "Gulfstream Technologies",
  "Merchant:00000000-0000-0000-0000-000353629018:0": "SOCi",
  "Merchant:00000000-0000-0000-0000-00038fc15028:0": "TickPick",
  "Merchant:00000000-0000-0000-0000-0003efc2f040:0": "Pony",
  "Merchant:00000000-0000-0000-0000-000350fa50f8:0": "Grupo Intercros",
  "Merchant:00000000-0000-0000-0000-000350f580f8:0": "TrendPoint Systems",
  "Merchant:00000000-0000-0000-0000-0003843c20e8:0": "MedMinder",
  "Merchant:00000000-0000-0000-0000-0003a193e008:0": "J. Thor Productions",
  "Merchant:00000000-0000-0000-0000-0003f21f0040:0": "Service Professor",
  "Merchant:00000000-0000-0000-0000-0003a3f5c008:0": "BisonOffice",
  "Merchant:00000000-0000-0000-0000-00038fc25028:0": "Cave Tools",
  "Merchant:00000000-0000-0000-0000-0003f21d7040:0": "Kukri",
  "Merchant:00000000-0000-0000-0000-00033b0660a0:0": "Guidefitter",
  "Merchant:00000000-0000-0000-0000-00035cc270a8:0": "Ackermann Vertriebs AG",
  "Merchant:00000000-0000-0000-0000-00034e080078:0": "Grupanya",
  "Merchant:00000000-0000-0000-0000-000393de10c8:0": "GroupSpaces",
  "Merchant:00000000-0000-0000-0000-000393dfa0c8:0": "GRAVIDI",
  "Merchant:00000000-0000-0000-0000-00035cc300a8:0": "Le Coq Sportif",
  "Merchant:00000000-0000-0000-0000-00034e077078:0": "Classic Sportswear",
  "Merchant:00000000-0000-0000-0000-0003f21e0040:0": "HALO Medical Technologies",
  "Merchant:00000000-0000-0000-0000-00038b1db088:0": "Purplle",
  "Merchant:00000000-0000-0000-0000-0003b39fc0c0:0": "Groove Club",
  "Merchant:00000000-0000-0000-0000-00033a922020:0": "Big Night Entertainment Group",
  "Merchant:00000000-0000-0000-0000-00036bcda0b8:0": "Grand Round Table",
  "Merchant:00000000-0000-0000-0000-00035096b050:0": "Gravitant",
  "Merchant:00000000-0000-0000-0000-00033a909020:0": "Bruno Total Home Performance",
  "Merchant:00000000-0000-0000-0000-00038ff5c0c8:0": "Deuter Sport",
  "Merchant:00000000-0000-0000-0000-00038b10b088:0": "Vigilant Global Trade Services",
  "Merchant:00000000-0000-0000-0000-000383d35058:0": "Ping",
  "Merchant:00000000-0000-0000-0000-00035094d050:0": "Grandis",
  "Merchant:00000000-0000-0000-0000-00036bc750b8:0": "NEU",
  "Merchant:00000000-0000-0000-0000-000393e0a0c8:0": "Halo Neuroscience",
  "Merchant:00000000-0000-0000-0000-0003c354d0d0:0": "Gynzy",
  "Merchant:00000000-0000-0000-0000-0003a3f87008:0": "HackSurfer",
  "Merchant:00000000-0000-0000-0000-00033b0670a0:0": "Ellison Bakery",
  "Merchant:00000000-0000-0000-0000-000353687018:0": "Graphene Frontiers",
  "Merchant:00000000-0000-0000-0000-00038b167088:0": "STEG Electronics AG",
  "Merchant:00000000-0000-0000-0000-0003f21a4040:0": "Early Upgrade",
  "Merchant:00000000-0000-0000-0000-00034e063078:0": "Golden Gate BPO Solutions",
  "Merchant:00000000-0000-0000-0000-0003b39a10c0:0": "Ankit",
  "Merchant:00000000-0000-0000-0000-0003b3a510c0:0": "Reusch",
  "Merchant:00000000-0000-0000-0000-00037f6cb0b0:0": "Guguchu",
  "Merchant:00000000-0000-0000-0000-00035cc0f0a8:0": "No Fear",
  "Merchant:00000000-0000-0000-0000-00034e00a078:0": "Festivals Unlimited",
  "Merchant:00000000-0000-0000-0000-00033a964020:0": "Tokaido",
  "Merchant:00000000-0000-0000-0000-000350932050:0": "Outer Banks Blue",
  "Merchant:00000000-0000-0000-0000-0003b39ef0c0:0": "Keuka",
  "Merchant:00000000-0000-0000-0000-0003a1956008:0": "GrownOut",
  "Merchant:00000000-0000-0000-0000-00035364b018:0": "Sgt. Clean's Car Wash",
  "Merchant:00000000-0000-0000-0000-00035cbd00a8:0": "Merchology",
  "Merchant:00000000-0000-0000-0000-0003b3a2b0c0:0": "Hachimenroppi",
  "Merchant:00000000-0000-0000-0000-00033b0100a0:0": "E-file",
  "Merchant:00000000-0000-0000-0000-0003b3a670c0:0": "Weby",
  "Merchant:00000000-0000-0000-0000-0003efbf2040:0": "Grama Vidiyal Micro Finance",
  "Merchant:00000000-0000-0000-0000-00034e046078:0": "Mizuno",
  "Merchant:00000000-0000-0000-0000-0003f2202040:0": "Valuecart",
  "Merchant:00000000-0000-0000-0000-000350973050:0": "Dyla",
  "Merchant:00000000-0000-0000-0000-00038ff5d0c8:0": "O'Neill",
  "Merchant:00000000-0000-0000-0000-000383d3a058:0": "Gongs Unlimited",
  "Merchant:00000000-0000-0000-0000-000350956050:0": "Growing Stars",
  "Merchant:00000000-0000-0000-0000-00033b06c0a0:0": "Gray Routes Innovative Distribution",
  "Merchant:00000000-0000-0000-0000-00033b0170a0:0": "Asurint",
  "Merchant:00000000-0000-0000-0000-0003f2209040:0": "Hachi Labs",
  "Merchant:00000000-0000-0000-0000-000350f8b0f8:0": "Graphic India",
  "Merchant:00000000-0000-0000-0000-0003b3a010c0:0": "Gul",
  "Merchant:00000000-0000-0000-0000-0003536a4018:0": "Show Imaging",
  "Merchant:00000000-0000-0000-0000-00035cbf40a8:0": "CQ fluency",
  "Merchant:00000000-0000-0000-0000-0003efc1b040:0": "Let's Bands",
  "Merchant:00000000-0000-0000-0000-0003509ce078:0": "Gudeng Precision",
  "Merchant:00000000-0000-0000-0000-0003a3f8c008:0": "GutCheck",
  "Merchant:00000000-0000-0000-0000-000393de80c8:0": "Guroo",
  "Merchant:00000000-0000-0000-0000-000393dbb0c8:0": "Grupo IMO",
  "Merchant:00000000-0000-0000-0000-00034c0d4070:0": "GumGum",
  "Merchant:00000000-0000-0000-0000-0003b39930c0:0": "Thistle Health",
  "Merchant:00000000-0000-0000-0000-0003f2218040:0": "Kappa",
  "Merchant:00000000-0000-0000-0000-00035cc350a8:0": "Lotto",
  "Merchant:00000000-0000-0000-0000-0003f21dc040:0": "Fairtex Gym",
  "Merchant:00000000-0000-0000-0000-00038b0dc088:0": "FireDisc Cookers",
  "Merchant:00000000-0000-0000-0000-00036bcc50b8:0": "Greenscreen Animals",
  "Merchant:00000000-0000-0000-0000-0003c35400d0:0": "Upward Projects",
  "Merchant:00000000-0000-0000-0000-00033a939020:0": "OTTO'S AG",
  "Merchant:00000000-0000-0000-0000-0003844070e8:0": "Graphic Stadium",
  "Merchant:00000000-0000-0000-0000-0003c35150d0:0": "Hallway Social Learning Network",
  "Merchant:00000000-0000-0000-0000-00038b1b7088:0": "Teriyaki Madness",
  "Merchant:00000000-0000-0000-0000-0003efbc7040:0": "Edge",
  "Merchant:00000000-0000-0000-0000-00038ff920c8:0": "Hackermeter",
  "Merchant:00000000-0000-0000-0000-000393d8f0c8:0": "Guomai",
  "Merchant:00000000-0000-0000-0000-0003b397b0c0:0": "HS Brands International",
  "Merchant:00000000-0000-0000-0000-00038ff250c8:0": "Grouply",
  "Merchant:00000000-0000-0000-0000-0003efc33040:0": "Guanghetang",
  "Merchant:00000000-0000-0000-0000-00033a914020:0": "MizAuctions",
  "Merchant:00000000-0000-0000-0000-0003f21b3040:0": "Kettlebell Kitchen",
  "Merchant:00000000-0000-0000-0000-000353679018:0": "365 Retail Markets",
  "Merchant:00000000-0000-0000-0000-000393d7a0c8:0": "GreenElectric Power Corp",
  "Merchant:00000000-0000-0000-0000-00038fccd028:0": "FBT",
  "Merchant:00000000-0000-0000-0000-00038b176088:0": "Great East Energy",
  "Merchant:00000000-0000-0000-0000-00034e037078:0": "Guardian EMS Products",
  "Merchant:00000000-0000-0000-0000-00034e08d078:0": "Guardian 8 Holdings",
  "Merchant:00000000-0000-0000-0000-00038fbf5028:0": "Threadbird",
  "Merchant:00000000-0000-0000-0000-00034c0e9070:0": "Karhu",
  "Merchant:00000000-0000-0000-0000-00035093f050:0": "Ski Butlers",
  "Merchant:00000000-0000-0000-0000-00038b159088:0": "Graphene Technologies",
  "Merchant:00000000-0000-0000-0000-0003c35230d0:0": "Patrick",
  "Merchant:00000000-0000-0000-0000-00034e0c8078:0": "Dynamic",
  "Merchant:00000000-0000-0000-0000-00038fe8b0c8:0": "CircusTrix",
  "Merchant:00000000-0000-0000-0000-00038fecd0c8:0": "Guavas",
  "Merchant:00000000-0000-0000-0000-00034c0f1070:0": "Haerhead Navigation",
  "Merchant:00000000-0000-0000-0000-0003efbda040:0": "Mainstream Boutique",
  "Merchant:00000000-0000-0000-0000-00035cc1d0a8:0": "Gusto",
  "Merchant:00000000-0000-0000-0000-00038441b0e8:0": "Haerhead Systems",
  "Merchant:00000000-0000-0000-0000-0003b3a5e0c0:0": "Archiesonline",
  "Merchant:00000000-0000-0000-0000-00038fcaf028:0": "Greendizer",
  "Merchant:00000000-0000-0000-0000-000383d26058:0": "Gutenbergz",
  "Merchant:00000000-0000-0000-0000-00038b16c088:0": "Fashionphile",
  "Merchant:00000000-0000-0000-0000-00034e06f078:0": "Gruvi",
  "Merchant:00000000-0000-0000-0000-00038fc94028:0": "Gumroad",
  "Merchant:00000000-0000-0000-0000-00036bce20b8:0": "Gruvie",
  "Merchant:00000000-0000-0000-0000-0003b39cb0c0:0": "Couter Air Technology",
  "Merchant:00000000-0000-0000-0000-0003a3f5a008:0": "Milosi",
  "Merchant:00000000-0000-0000-0000-000393db60c8:0": "Gruppo MutuiOnline",
  "Merchant:00000000-0000-0000-0000-0003f2210040:0": "Greenext",
  "Merchant:00000000-0000-0000-0000-000350965050:0": "Grandex Inc",
  "Merchant:00000000-0000-0000-0000-0003b3a100c0:0": "K-Swiss",
  "Merchant:00000000-0000-0000-0000-00035367e018:0": "Graymark Healthcare",
  "Merchant:00000000-0000-0000-0000-0003a3fa4008:0": "Haer and Grind",
  "Merchant:00000000-0000-0000-0000-00033b05e0a0:0": "Gro Intelligence",
  "Merchant:00000000-0000-0000-0000-00038ff080c8:0": "Guocool",
  "Merchant:00000000-0000-0000-0000-00033a8f8020:0": "Signature Transportation Group",
  "Merchant:00000000-0000-0000-0000-00038ff4f0c8:0": "CleverMade",
  "Merchant:00000000-0000-0000-0000-000350964050:0": "GraphScience",
  "Merchant:00000000-0000-0000-0000-00034c0d3070:0": "Groove Biopharma",
  "Merchant:00000000-0000-0000-0000-00037f6a90b0:0": "Graphite Systems",
  "Merchant:00000000-0000-0000-0000-00038b1c5088:0": "Givova",
  "Merchant:00000000-0000-0000-0000-0003f2211040:0": "GuestDriven",
  "Merchant:00000000-0000-0000-0000-000350f540f8:0": "Jonathan's Grille",
  "Merchant:00000000-0000-0000-0000-0003c35570d0:0": "Gruburg",
  "Merchant:00000000-0000-0000-0000-00038b142088:0": "Grono.net",
  "Merchant:00000000-0000-0000-0000-00033a93e020:0": "Koovs",
  "Merchant:00000000-0000-0000-0000-00036bcd30b8:0": "Hacker School",
  "Merchant:00000000-0000-0000-0000-0003a3f46008:0": "Amerisleep",
  "Merchant:00000000-0000-0000-0000-00038fc95028:0": "GreenSQL",
  "Merchant:00000000-0000-0000-0000-00037f6b80b0:0": "Gilbert",
  "Merchant:00000000-0000-0000-0000-00039cb130e0:0": "Groove Customer Support",
  "Merchant:00000000-0000-0000-0000-00035cc430a8:0": "Guangzhou Broad Vision Telecom",
  "Merchant:00000000-0000-0000-0000-000350974050:0": "Shapes Secrets",
  "Merchant:00000000-0000-0000-0000-00038ff840c8:0": "GSOUND",
  "Merchant:00000000-0000-0000-0000-0003a1947008:0": "RapidVisa",
  "Merchant:00000000-0000-0000-0000-0003a3fb9008:0": "Hackster, Inc.",
  "Merchant:00000000-0000-0000-0000-00038ff170c8:0": "Hakia",
  "Merchant:00000000-0000-0000-0000-0003c35480d0:0": "Grivy",
  "Merchant:00000000-0000-0000-0000-00038fc5f028:0": "Fort Collins Heating and Air Conditoning",
  "Merchant:00000000-0000-0000-0000-00034c0bb070:0": "Y Charter",
  "Merchant:00000000-0000-0000-0000-00037f6ca0b0:0": "GuiaBolso",
  "Merchant:00000000-0000-0000-0000-000350973078:0": "Her Hair Company",
  "Merchant:00000000-0000-0000-0000-0003986d1060:0": "Proforma IdeaPress",
  "Merchant:00000000-0000-0000-0000-0003f2201040:0": "Grillin In The City",
  "Merchant:00000000-0000-0000-0000-00034e045078:0": "PeopleG2",
  "Merchant:00000000-0000-0000-0000-0003536be018:0": "Shop-Apotheke Europe N.V.",
  "Merchant:00000000-0000-0000-0000-0003efbb9040:0": "The Spa & Sauna Co",
  "Merchant:00000000-0000-0000-0000-000383d51058:0": "Myntra",
  "Merchant:00000000-0000-0000-0000-0003b3a180c0:0": "Russell Athletic",
  "Merchant:00000000-0000-0000-0000-00034e09a078:0": "Guided Surgery Solutions",
  "Merchant:00000000-0000-0000-0000-00034e028078:0": "Risk International Services",
  "Merchant:00000000-0000-0000-0000-00038ff740c8:0": "Pearl Izumi",
  "Merchant:00000000-0000-0000-0000-00039cb0a0e0:0": "Green Farms Energy",
  "Merchant:00000000-0000-0000-0000-00035cc0e0a8:0": "Shyaway",
  "Merchant:00000000-0000-0000-0000-00038fcbf028:0": "H2scan",
  "Merchant:00000000-0000-0000-0000-0003509ab078:0": "Gudog",
  "Merchant:00000000-0000-0000-0000-00034e09c078:0": "Optimum RV",
  "Merchant:00000000-0000-0000-0000-00038fcb8028:0": "Grupo Le�����oso SACV",
  "Merchant:00000000-0000-0000-0000-00033b0490a0:0": "British Knights",
  "Merchant:00000000-0000-0000-0000-00033a8fc020:0": "San Francisco Art Exchange",
  "Merchant:00000000-0000-0000-0000-0003844160e8:0": "GreenTech Automotive",
  "Merchant:00000000-0000-0000-0000-00034e0a3078:0": "GrexIt",
  "Merchant:00000000-0000-0000-0000-00038b10f088:0": "Material Handling Services",
  "Merchant:00000000-0000-0000-0000-00033b0350a0:0": "GroupMe",
  "Merchant:00000000-0000-0000-0000-0003a1972008:0": "The Event Studio",
  "Merchant:00000000-0000-0000-0000-00038440d0e8:0": "Amuza",
  "Merchant:00000000-0000-0000-0000-00038feda0c8:0": "Prep Obsessed",
  "Merchant:00000000-0000-0000-0000-00035369a018:0": "Geox",
  "Merchant:00000000-0000-0000-0000-0003c352f0d0:0": "GuestSpan",
  "Merchant:00000000-0000-0000-0000-00038b1e3088:0": "Guidesly",
  "Merchant:00000000-0000-0000-0000-0003a3f69008:0": "Mid-Atlantic ProTel",
  "Merchant:00000000-0000-0000-0000-0003c35140d0:0": "Gydget",
  "Merchant:00000000-0000-0000-0000-00034dfd1078:0": "Thought Logic Consulting",
  "Merchant:00000000-0000-0000-0000-00038b1b6088:0": "Fnp",
  "Merchant:00000000-0000-0000-0000-00038fc46028:0": "Graftec Electronics",
  "Merchant:00000000-0000-0000-0000-0003c34ee0d0:0": "6 Degrees Group",
  "Merchant:00000000-0000-0000-0000-0003a3f7d008:0": "New Vision Nutrition",
  "Merchant:00000000-0000-0000-0000-00035cc030a8:0": "GreatDay Auto Group, Inc.",
  "Merchant:00000000-0000-0000-0000-00038fe940c8:0": "Suite Experience Group",
  "Merchant:00000000-0000-0000-0000-00038442a0e8:0": "Newu",
  "Merchant:00000000-0000-0000-0000-00038b1d3088:0": "GTx",
  "Merchant:00000000-0000-0000-0000-0003b3a3a0c0:0": "Macron",
  "Merchant:00000000-0000-0000-0000-00038fc0f028:0": "OnPrem Solution Partners",
  "Merchant:00000000-0000-0000-0000-0003536b3018:0": "Group-IB",
  "Merchant:00000000-0000-0000-0000-00039cb460e0:0": "OLLI Salumeria Americana",
  "Merchant:00000000-0000-0000-0000-00034e04a078:0": "Indiarush",
  "Merchant:00000000-0000-0000-0000-000393e230c8:0": "MassPay",
  "Merchant:00000000-0000-0000-0000-00038b1da088:0": "Guitar Party",
  "Merchant:00000000-0000-0000-0000-000350963078:0": "Your Location Lubrication",
  "Merchant:00000000-0000-0000-0000-00038fc77028:0": "Guided Delivery Systems",
  "Merchant:00000000-0000-0000-0000-000350966050:0": "Legea",
  "Merchant:00000000-0000-0000-0000-00038ff090c8:0": "Kempa",
  "Merchant:00000000-0000-0000-0000-00038fe9b0c8:0": "Vedder Holsters",
  "Merchant:00000000-0000-0000-0000-00035367f018:0": "New Law Business Model",
  "Merchant:00000000-0000-0000-0000-00034e07f078:0": "Prettysecrets",
  "Merchant:00000000-0000-0000-0000-0003a3f70008:0": "Green Genes",
  "Merchant:00000000-0000-0000-0000-000383d36058:0": "Guide",
  "Merchant:00000000-0000-0000-0000-0003efc17040:0": "Tchibo (Schweiz) AG",
  "Merchant:00000000-0000-0000-0000-00039cb4a0e0:0": "GuideIT",
  "Merchant:00000000-0000-0000-0000-00035cbe50a8:0": "Denken Solutions",
  "Merchant:00000000-0000-0000-0000-0003c352a0d0:0": "Gram Games",
  "Merchant:00000000-0000-0000-0000-00035cc280a8:0": "Green Dot Corporation",
  "Merchant:00000000-0000-0000-0000-00035cc1c0a8:0": "Guangzhou Youboy Network",
  "Merchant:00000000-0000-0000-0000-00038feea0c8:0": "Tiesta Tea Company",
  "Merchant:00000000-0000-0000-0000-00033b0750a0:0": "Looptworks",
  "Merchant:00000000-0000-0000-0000-000393d250c8:0": "Flix Brewhouse",
  "Merchant:00000000-0000-0000-0000-0003c34f80d0:0": "City Brew Tours",
  "Merchant:00000000-0000-0000-0000-000393d8e0c8:0": "GuestMetrics",
  "Merchant:00000000-0000-0000-0000-00038ff490c8:0": "H2Mob",
  "Merchant:00000000-0000-0000-0000-00038b1ac088:0": "GreenGar",
  "Merchant:00000000-0000-0000-0000-00035095c050:0": "Greentoe",
  "Merchant:00000000-0000-0000-0000-00038fc1f028:0": "milk + honey",
  "Merchant:00000000-0000-0000-0000-0003f21d1040:0": "FNB Merchants",
  "Merchant:00000000-0000-0000-0000-000353696018:0": "GuideWall",
  "Merchant:00000000-0000-0000-0000-0003a3f80008:0": "Scooter's Coffee",
  "Merchant:00000000-0000-0000-0000-0003efc07040:0": "gShift Labs",
  "Merchant:00000000-0000-0000-0000-0003f2219040:0": "Guruji",
  "Merchant:00000000-0000-0000-0000-00033a94c020:0": "H.BLOOM",
  "Merchant:00000000-0000-0000-0000-00039cb2f0e0:0": "Greytip Software",
  "Merchant:00000000-0000-0000-0000-000350f3d0f8:0": "Affiliated Counications dba Alert Counications",
  "Merchant:00000000-0000-0000-0000-0003a3f9b008:0": "GRUZOBZOR",
  "Merchant:00000000-0000-0000-0000-00034e0ce078:0": "Yepme",
  "Merchant:00000000-0000-0000-0000-00035cc010a8:0": "GreenDot Trans",
  "Merchant:00000000-0000-0000-0000-00034c0cb070:0": "GreenDust",
  "Merchant:00000000-0000-0000-0000-00039cb690e0:0": "Gremln",
  "Merchant:00000000-0000-0000-0000-0003f21ff040:0": "Huel",
  "Merchant:00000000-0000-0000-0000-0003b396b0c0:0": "Vibe Kayaks",
  "Merchant:00000000-0000-0000-0000-0003efc10040:0": "GTE Mangement Corp",
  "Merchant:00000000-0000-0000-0000-00035cc4f0a8:0": "Grovac",
  "Merchant:00000000-0000-0000-0000-00038fcc6028:0": "H2Sonics",
  "Merchant:00000000-0000-0000-0000-00033b01d0a0:0": "Lone Cone",
  "Merchant:00000000-0000-0000-0000-00039cb440e0:0": "GroundWork",
  "Merchant:00000000-0000-0000-0000-0003b3a2c0c0:0": "Grupo Phoenix",
  "Merchant:00000000-0000-0000-0000-00038b1e1088:0": "HAWK Electronics GmbH",
  "Merchant:00000000-0000-0000-0000-0003b39e90c0:0": "GroSocial",
  "Merchant:00000000-0000-0000-0000-00034e096078:0": "Guerrilla RF",
  "Merchant:00000000-0000-0000-0000-0003509b9078:0": "Halalati",
  "Merchant:00000000-0000-0000-0000-0003a192f008:0": "National Automotive Experts/NWAN",
  "Merchant:00000000-0000-0000-0000-0003b39f20c0:0": "FIREKING BAKING COMPANY",
  "Merchant:00000000-0000-0000-0000-00035097a050:0": "Gweepi Medical",
  "Merchant:00000000-0000-0000-0000-000383d2c058:0": "GrantAdler",
  "Merchant:00000000-0000-0000-0000-0003536a5018:0": "Green and Red Technologies (G&R)",
  "Merchant:00000000-0000-0000-0000-00038fcd6028:0": "Gtxh",
  "Merchant:00000000-0000-0000-0000-00038b111088:0": "Specialist ID",
  "Merchant:00000000-0000-0000-0000-00039cb540e0:0": "Guangzhou Yingzheng Information Technology",
  "Merchant:00000000-0000-0000-0000-00038ff240c8:0": "Greenlight Technologies",
  "Merchant:00000000-0000-0000-0000-0003a1979008:0": "Green & Pleasant",
  "Merchant:00000000-0000-0000-0000-00033b0570a0:0": "Puma",
  "Merchant:00000000-0000-0000-0000-00038fc69028:0": "Greencloud Technologies",
  "Merchant:00000000-0000-0000-0000-00038fc7d028:0": "Graftys",
  "Merchant:00000000-0000-0000-0000-0003efbbd040:0": "Scalefast",
  "Merchant:00000000-0000-0000-0000-0003b3a030c0:0": "Koncerted",
  "Merchant:00000000-0000-0000-0000-00035cbde0a8:0": "Koncept Events",
  "Merchant:00000000-0000-0000-0000-00038b14f088:0": "Striker Concepts / Risk Racing",
  "Merchant:00000000-0000-0000-0000-0003efc00040:0": "Luggage To Ship",
  "Merchant:00000000-0000-0000-0000-0003c353f0d0:0": "HAKIM Information Technology",
  "Merchant:00000000-0000-0000-0000-00037f6d40b0:0": "Greenleaf Trust",
  "Merchant:00000000-0000-0000-0000-0003f21ea040:0": "Faballey",
  "Merchant:00000000-0000-0000-0000-00033a941020:0": "Gruppo Waste Italia",
  "Merchant:00000000-0000-0000-0000-000393de70c8:0": "Guesthouse Network",
  "Merchant:00000000-0000-0000-0000-000393e050c8:0": "Guangzhou Teiron Network Science and Technology",
  "Merchant:00000000-0000-0000-0000-00038b174088:0": "Graematter",
  "Merchant:00000000-0000-0000-0000-00033b0600a0:0": "Guangzhou CK1",
  "Merchant:00000000-0000-0000-0000-00038ffa60c8:0": "Prince",
  "Merchant:00000000-0000-0000-0000-00034c0e4070:0": "Guerillapps",
  "Merchant:00000000-0000-0000-0000-0003efc1d040:0": "GreenWatt",
  "Merchant:00000000-0000-0000-0000-00038ff440c8:0": "Clovia",
  "Merchant:00000000-0000-0000-0000-0003f21f2040:0": "K2 Sports",
  "Merchant:00000000-0000-0000-0000-00039cb6d0e0:0": "Jako",
  "Merchant:00000000-0000-0000-0000-00038fc60028:0": "Jeunesse Global",
  "Merchant:00000000-0000-0000-0000-00034c0c7070:0": "Loudmouth Golf",
  "Merchant:00000000-0000-0000-0000-00033a93c020:0": "Haer & Chisel, Inc.",
  "Merchant:00000000-0000-0000-0000-00036bc890b8:0": "Elixiter",
  "Merchant:00000000-0000-0000-0000-0003509db078:0": "Gratafy",
  "Merchant:00000000-0000-0000-0000-000350930050:0": "PARTSCO",
  "Merchant:00000000-0000-0000-0000-00038b107088:0": "Coon Cents Distributors",
  "Merchant:00000000-0000-0000-0000-00038b1ba088:0": "Grockit",
  "Merchant:00000000-0000-0000-0000-00038b0f9088:0": "90 Day Year",
  "Merchant:00000000-0000-0000-0000-0003f21b8040:0": "Sock Fancy",
  "Merchant:00000000-0000-0000-0000-00033b06e0a0:0": "GrandCamp",
  "Merchant:00000000-0000-0000-0000-0003c35010d0:0": "The Payroll Company (TPC)",
  "Merchant:00000000-0000-0000-0000-00035cc210a8:0": "Granicus",
  "Merchant:00000000-0000-0000-0000-0003a3f85008:0": "Greenville Chamber",
  "Merchant:00000000-0000-0000-0000-000350991078:0": "Joma",
  "Merchant:00000000-0000-0000-0000-0003c354b0d0:0": "Guanxi.me",
  "Merchant:00000000-0000-0000-0000-0003f2216040:0": "Merooj",
  "Merchant:00000000-0000-0000-0000-00034c0ba070:0": "Camp Gladiator",
  "Merchant:00000000-0000-0000-0000-0003b39ed0c0:0": "Automated Drive Systems",
  "Merchant:00000000-0000-0000-0000-00038ff4d0c8:0": "Katydid",
  "Merchant:00000000-0000-0000-0000-00038fe760c8:0": "Absolute Merch",
  "Merchant:00000000-0000-0000-0000-00037f6c40b0:0": "GuzzMobile",
  "Merchant:00000000-0000-0000-0000-0003a196f008:0": "Bolton Remote",
  "Merchant:00000000-0000-0000-0000-00038b186088:0": "Guangdong Guofang Medical Technology",
  "Merchant:00000000-0000-0000-0000-00038440a0e8:0": "Guidance Software",
  "Merchant:00000000-0000-0000-0000-0003a3fab008:0": "GrouPAY",
  "Merchant:00000000-0000-0000-0000-00034e032078:0": "HaloSource",
  "Merchant:00000000-0000-0000-0000-000393db00c8:0": "GreenTrapOnline",
  "Merchant:00000000-0000-0000-0000-0003a1983008:0": "Outdoor Voices",
  "Merchant:00000000-0000-0000-0000-00038ff610c8:0": "GreenGo Energy A/S",
  "Merchant:00000000-0000-0000-0000-000350f7b0f8:0": "Green Planet Architects",
  "Merchant:00000000-0000-0000-0000-00033a8ff020:0": "Background Screeners of America",
  "Merchant:00000000-0000-0000-0000-00033b02d0a0:0": "GRNE Solutions",
  "Merchant:00000000-0000-0000-0000-0003c35250d0:0": "Peak",
  "Merchant:00000000-0000-0000-0000-00038b18f088:0": "greenovation Biotech",
  "Merchant:00000000-0000-0000-0000-0003a3f61008:0": "ROWDYDOW bbQ WorldWide",
  "Merchant:00000000-0000-0000-0000-00035096c050:0": "Fabletics",
  "Merchant:00000000-0000-0000-0000-00038b157088:0": "Guojia New Materials",
  "Merchant:00000000-0000-0000-0000-00034e082078:0": "SquarePlanet",
  "Merchant:00000000-0000-0000-0000-00035cc250a8:0": "Guarnic",
  "Merchant:00000000-0000-0000-0000-00035097d078:0": "Gorilla Stationers",
  "Merchant:00000000-0000-0000-0000-00033a92a020:0": "Solutions YES",
  "Merchant:00000000-0000-0000-0000-00038fc93028:0": "Ground Up Biosolutions",
  "Merchant:00000000-0000-0000-0000-00035cc160a8:0": "Halo Beverages",
  "Merchant:00000000-0000-0000-0000-0003b39b90c0:0": "PROTEX RESTAURANT SERVICES",
  "Merchant:00000000-0000-0000-0000-0003a3f6a008:0": "Gratci",
  "Merchant:00000000-0000-0000-0000-00034e0c3078:0": "Guangdong Delian Group",
  "Merchant:00000000-0000-0000-0000-000393def0c8:0": "Halotechnics",
  "Merchant:00000000-0000-0000-0000-00035cc4e0a8:0": "GreenRay Solar",
  "Merchant:00000000-0000-0000-0000-00034c0b5070:0": "Koddi",
  "Merchant:00000000-0000-0000-0000-00034e0ab078:0": "Law Offices of Anidjar & Levine",
  "Merchant:00000000-0000-0000-0000-0003c352e0d0:0": "GripeO",
  "Merchant:00000000-0000-0000-0000-0003509b1078:0": "HackPad",
  "Merchant:00000000-0000-0000-0000-00035094e078:0": "Calm",
  "Merchant:00000000-0000-0000-0000-00036bccc0b8:0": "GreenIQ",
  "Merchant:00000000-0000-0000-0000-0003b39e40c0:0": "gulu",
  "Merchant:00000000-0000-0000-0000-0003509be078:0": "Gravity Powerplants",
  "Merchant:00000000-0000-0000-0000-00035cbe80a8:0": "Chairish",
  "Merchant:00000000-0000-0000-0000-0003536bd018:0": "Voonik",
  "Merchant:00000000-0000-0000-0000-00038ff6a0c8:0": "Jabong",
  "Merchant:00000000-0000-0000-0000-000350f720f8:0": "The Ticket Group",
  "Merchant:00000000-0000-0000-0000-0003a3f64008:0": "CarLotz",
  "Merchant:00000000-0000-0000-0000-00036bce30b8:0": "Haload",
  "Merchant:00000000-0000-0000-0000-0003f21cf040:0": "GroovinAds",
  "Merchant:00000000-0000-0000-0000-0003509c4078:0": "Graymatics",
  "Merchant:00000000-0000-0000-0000-0003a3f9d008:0": "Grameen Financial Services",
  "Merchant:00000000-0000-0000-0000-00035369d018:0": "Vyomshop",
  "Merchant:00000000-0000-0000-0000-000350f790f8:0": "NINE LINE APPAREL",
  "Merchant:00000000-0000-0000-0000-0003a1980008:0": "Granite Horizon",
  "Merchant:00000000-0000-0000-0000-00034e098078:0": "Raymond Ltd",
  "Merchant:00000000-0000-0000-0000-000393d2c0c8:0": "GDS Link",
  "Merchant:00000000-0000-0000-0000-0003f2207040:0": "GZ",
  "Merchant:00000000-0000-0000-0000-0003a3f94008:0": "Greengage Mobile",
  "Merchant:00000000-0000-0000-0000-000393e070c8:0": "Groupalia",
  "Merchant:00000000-0000-0000-0000-00035cc580a8:0": "GTX Messaging",
  "Merchant:00000000-0000-0000-0000-00035098c050:0": "Group Phoebe Ingenica",
  "Merchant:00000000-0000-0000-0000-00038fee30c8:0": "Musto",
  "Merchant:00000000-0000-0000-0000-00038ff350c8:0": "Grady Health System",
  "Merchant:00000000-0000-0000-0000-000383d45058:0": "Group Therapy Records",
  "Merchant:00000000-0000-0000-0000-00034e08e078:0": "Grapeword",
  "Merchant:00000000-0000-0000-0000-0003b3a450c0:0": "Greentech Media",
  "Merchant:00000000-0000-0000-0000-00039cb2c0e0:0": "Great Basin",
  "Merchant:00000000-0000-0000-0000-0003b396e0c0:0": "Killer Concepts",
  "Merchant:00000000-0000-0000-0000-00034e035078:0": "Chosen Payments",
  "Merchant:00000000-0000-0000-0000-000350954050:0": "Groove Biopharma.",
  "Merchant:00000000-0000-0000-0000-00038fc23028:0": "William Painter",
  "Merchant:00000000-0000-0000-0000-000383ce7058:0": "Relief-Mart",
  "Merchant:00000000-0000-0000-0000-00033b0780a0:0": "Totaltoys",
  "Merchant:00000000-0000-0000-0000-00035cc420a8:0": "GrubHub",
  "Merchant:00000000-0000-0000-0000-00038ff640c8:0": "Caratlane",
  "Merchant:00000000-0000-0000-0000-00035366d018:0": "Greenling",
  "Merchant:00000000-0000-0000-0000-00034e065078:0": "One Stop Equine Shop",
  "Merchant:00000000-0000-0000-0000-00035cbda0a8:0": "INCREMENTOR",
  "Merchant:00000000-0000-0000-0000-0003b39980c0:0": "17hats",
  "Merchant:00000000-0000-0000-0000-00038b0f5088:0": "BMNT Partners",
  "Merchant:00000000-0000-0000-0000-0003844310e8:0": "Donnay",
  "Merchant:00000000-0000-0000-0000-0003a1966008:0": "Coolwinks",
  "Merchant:00000000-0000-0000-0000-000353693018:0": "Greenstack",
  "Merchant:00000000-0000-0000-0000-00036bcc30b8:0": "Graffiti World",
  "Merchant:00000000-0000-0000-0000-00034e0cc078:0": "Haer & Chisel",
  "Merchant:00000000-0000-0000-0000-0003875cb0e8:0": "Trucks & Parts of Pennsylvania",
  "Merchant:00000000-0000-0000-0000-0003a3f31008:0": "Proforma Angelini + Diamond Solutions",
  "Merchant:00000000-0000-0000-0000-00038fc1d028:0": "OneSource Virtual",
  "Merchant:00000000-0000-0000-0000-00038442b0e8:0": "Galvin Green",
  "Merchant:00000000-0000-0000-0000-0003a1957008:0": "Overcart",
  "Merchant:00000000-0000-0000-0000-000393dcf0c8:0": "Haitaobei",
  "Merchant:00000000-0000-0000-0000-0003b3a150c0:0": "Gruppo Argenta",
  "Merchant:00000000-0000-0000-0000-00035cc2e0a8:0": "Groopt",
  "Merchant:00000000-0000-0000-0000-00035cbe70a8:0": "Balani Custom Clothiers",
  "Merchant:00000000-0000-0000-0000-00038fc1c028:0": "Monster Technology",
  "Merchant:00000000-0000-0000-0000-0003844220e8:0": "H-FARM Ventures",
  "Merchant:00000000-0000-0000-0000-0003c35510d0:0": "Nivia",
  "Merchant:00000000-0000-0000-0000-0003c35380d0:0": "Groupe Athena",
  "Merchant:00000000-0000-0000-0000-0003a191c008:0": "FIGS",
  "Merchant:00000000-0000-0000-0000-00035369c018:0": "Guanri",
  "Merchant:00000000-0000-0000-0000-00036bcd90b8:0": "Li-Ning",
  "Merchant:00000000-0000-0000-0000-000393d960c8:0": "Gradwell",
  "Merchant:00000000-0000-0000-0000-00038fefd0c8:0": "Graphenea",
  "Merchant:00000000-0000-0000-0000-00037f6ac0b0:0": "Gynesonics",
  "Merchant:00000000-0000-0000-0000-00038ff650c8:0": "GruupMeet",
  "Merchant:00000000-0000-0000-0000-0003c35290d0:0": "Soffe",
  "Merchant:00000000-0000-0000-0000-0003f21f9040:0": "Gravie",
  "Merchant:00000000-0000-0000-0000-00033b04e0a0:0": "Grovo",
  "Merchant:00000000-0000-0000-0000-00038fc76028:0": "Green Charge Networks",
  "Merchant:00000000-0000-0000-0000-00038ff110c8:0": "Nomis",
  "Merchant:00000000-0000-0000-0000-00038b12d088:0": "Greenmonster",
  "Merchant:00000000-0000-0000-0000-00039cb1d0e0:0": "Culinary Eye Catering & Events",
  "Merchant:00000000-0000-0000-0000-0003efbb6040:0": "Y7 Studio",
  "Merchant:00000000-0000-0000-0000-00038fc2c028:0": "Wahid",
  "Merchant:00000000-0000-0000-0000-00036bc760b8:0": "Real Plans",
  "Merchant:00000000-0000-0000-0000-0003a3f8e008:0": "GreenPal",
  "Merchant:00000000-0000-0000-0000-00038ffa90c8:0": "Home24 SE",
  "Merchant:00000000-0000-0000-0000-00038b177088:0": "Guided Therapeutics",
  "Merchant:00000000-0000-0000-0000-0003a198f008:0": "Mitre",
  "Merchant:00000000-0000-0000-0000-0003b3a520c0:0": "Guess Your Songs",
  "Merchant:00000000-0000-0000-0000-00034e062078:0": "Groupsite",
  "Merchant:00000000-0000-0000-0000-00034e0b9078:0": "SPECS",
  "Merchant:00000000-0000-0000-0000-00039cb200e0:0": "Guang Lian Shi Dai",
  "Merchant:00000000-0000-0000-0000-00038fc7c028:0": "GroupZoom",
  "Merchant:00000000-0000-0000-0000-00035cc220a8:0": "Greak Lake Carbon Fiber (GLCF)",
  "Merchant:00000000-0000-0000-0000-0003843e00e8:0": "Country Archer Jerky Co.",
  "Merchant:00000000-0000-0000-0000-00033b02a0a0:0": "Spice Jungle",
  "Merchant:00000000-0000-0000-0000-0003f21c2040:0": "Grove Labs",
  "Merchant:00000000-0000-0000-0000-00035cc050a8:0": "GreenMantra Technologies",
  "Merchant:00000000-0000-0000-0000-00034e059078:0": "Guangdong Mingyang Electric Group",
  "Merchant:00000000-0000-0000-0000-00034e0c2078:0": "GT Advanced Technologies",
  "Merchant:00000000-0000-0000-0000-00034e0d6078:0": "GTxcel",
  "Merchant:00000000-0000-0000-0000-000353657018:0": "Vitality Bowls Enterprise",
  "Merchant:00000000-0000-0000-0000-000383d19058:0": "Grove Instruments",
  "Merchant:00000000-0000-0000-0000-0003efc0a040:0": "Gravity R&D",
  "Merchant:00000000-0000-0000-0000-00038fca4028:0": "Riddell",
  "Merchant:00000000-0000-0000-0000-00034e002078:0": "SPRINGROCK",
  "Merchant:00000000-0000-0000-0000-00037f6e00b0:0": "Lacoste",
  "Merchant:00000000-0000-0000-0000-0003f2213040:0": "Guanya Education Group",
  "Merchant:00000000-0000-0000-0000-000350958078:0": "jBloom",
  "Merchant:00000000-0000-0000-0000-00038b1bd088:0": "groSolar",
  "Merchant:00000000-0000-0000-0000-0003b3a5b0c0:0": "Reliant Foodservice",
  "Merchant:00000000-0000-0000-0000-0003509b8078:0": "Hunkem?ller HKMX",
  "Merchant:00000000-0000-0000-0000-00033b0080a0:0": "Joorney Business Plans",
  "Merchant:00000000-0000-0000-0000-0003a1986008:0": "GuestCrew",
  "Merchant:00000000-0000-0000-0000-00038ff910c8:0": "Guzu",
  "Merchant:00000000-0000-0000-0000-000350957050:0": "Grand River Aseptic Manufacturing",
  "Merchant:00000000-0000-0000-0000-0003a3f88008:0": "Hall",
  "Merchant:00000000-0000-0000-0000-00034c0e1070:0": "Greenbox Technologies",
  "Merchant:00000000-0000-0000-0000-0003a3f28008:0": "Campman",
  "Merchant:00000000-0000-0000-0000-00037f67c0b0:0": "North Central Distributors",
  "Merchant:00000000-0000-0000-0000-000393d9f0c8:0": "GuardiCore",
  "Merchant:00000000-0000-0000-0000-00035cc090a8:0": "Guangdong Baolihua New Energy Stock",
  "Merchant:00000000-0000-0000-0000-000383d55058:0": "GreenSand",
  "Merchant:00000000-0000-0000-0000-00035cc3b0a8:0": "Gray Line of Tennessee",
  "Merchant:00000000-0000-0000-0000-00038b173088:0": "Environmentallights",
  "Merchant:00000000-0000-0000-0000-00034c0d8070:0": "MerryStockings",
  "Merchant:00000000-0000-0000-0000-00033b0640a0:0": "Groom Energy Solutions",
  "Merchant:00000000-0000-0000-0000-000393dff0c8:0": "Gridsum",
  "Merchant:00000000-0000-0000-0000-00038b17c088:0": "GroupCard",
  "Merchant:00000000-0000-0000-0000-00038b0e2088:0": "June & January",
  "Merchant:00000000-0000-0000-0000-000393d920c8:0": "Shoppersstop",
  "Merchant:00000000-0000-0000-0000-000383cfa058:0": "TripShock",
  "Merchant:00000000-0000-0000-0000-000350f6f0f8:0": "Guides.co",
  "Merchant:00000000-0000-0000-0000-0003b3a620c0:0": "GRAYL",
  "Merchant:00000000-0000-0000-0000-000350f7c0f8:0": "Gumhouse",
  "Merchant:00000000-0000-0000-0000-0003efbe6040:0": "Uhlsport",
  "Merchant:00000000-0000-0000-0000-00033a930020:0": "groopify",
  "Merchant:00000000-0000-0000-0000-00034e0c6078:0": "De Marchi",
  "Merchant:00000000-0000-0000-0000-000393d820c8:0": "H2i Technologies",
  "Merchant:00000000-0000-0000-0000-00034c0d1070:0": "Green Revolution Cooling",
  "Merchant:00000000-0000-0000-0000-000353624018:0": "TAFT",
  "Merchant:00000000-0000-0000-0000-0003f2191040:0": "Azure Knowledge",
  "Merchant:00000000-0000-0000-0000-0003a3fa5008:0": "Grassroots Unwired",
  "Merchant:00000000-0000-0000-0000-0003a3fbe008:0": "Handa Pharmaceuticals",
  "Merchant:00000000-0000-0000-0000-0003f21d2040:0": "Hamilton Thorne",
  "Merchant:00000000-0000-0000-0000-00034c0c8070:0": "One Way",
  "Merchant:00000000-0000-0000-0000-00039cb6c0e0:0": "Greenko Group",
  "Merchant:00000000-0000-0000-0000-000393d1c0c8:0": "Kuli Kuli Foods",
  "Merchant:00000000-0000-0000-0000-00038ff980c8:0": "Great Technology",
  "Merchant:00000000-0000-0000-0000-00038b0fb088:0": "Skin by Lovely",
  "Merchant:00000000-0000-0000-0000-0003a3f5e008:0": "Case Snow Management",
  "Merchant:00000000-0000-0000-0000-00037f6c30b0:0": "Gola",
  "Merchant:00000000-0000-0000-0000-0003536ba018:0": "GuidePal",
  "Merchant:00000000-0000-0000-0000-00038ff4e0c8:0": "Guvera",
  "Merchant:00000000-0000-0000-0000-0003b39ee0c0:0": "Gridco",
  "Merchant:00000000-0000-0000-0000-00033a957020:0": "Sergio Tacchini",
  "Merchant:00000000-0000-0000-0000-0003a1963008:0": "Kelme",
  "Merchant:00000000-0000-0000-0000-000350967050:0": "GROU.PS",
  "Merchant:00000000-0000-0000-0000-00036bcc60b8:0": "Guardian Analytics",
  "Merchant:00000000-0000-0000-0000-000383d4b058:0": "MCS Marketing Powered by Proforma",
  "Merchant:00000000-0000-0000-0000-0003efbbc040:0": "Images Luxury Nail Lounge",
  "Merchant:00000000-0000-0000-0000-000383d1b058:0": "Graph Alchemist",
  "Merchant:00000000-0000-0000-0000-00038b0f2088:0": "Swift Straw II",
  "Merchant:00000000-0000-0000-0000-00034c0fa070:0": "Graphite Software",
  "Merchant:00000000-0000-0000-0000-0003efc24040:0": "Grand Perfecta",
  "Merchant:00000000-0000-0000-0000-0003509d8078:0": "Le Tigre",
  "Merchant:00000000-0000-0000-0000-000383d12058:0": "GreenLink Networks",
  "Merchant:00000000-0000-0000-0000-00033a927020:0": "Zeel",
  "Merchant:00000000-0000-0000-0000-0003b3a120c0:0": "Croma",
  "Merchant:00000000-0000-0000-0000-0003f21eb040:0": "Group 47",
  "Merchant:00000000-0000-0000-0000-00038442e0e8:0": "GroupCharger",
  "Merchant:00000000-0000-0000-0000-000350997050:0": "Graveyard Pizza",
  "Merchant:00000000-0000-0000-0000-00034e022078:0": "TAYSE INTERNATIONAL TRADING",
  "Merchant:00000000-0000-0000-0000-0003b39ae0c0:0": "pNeo",
  "Merchant:00000000-0000-0000-0000-00036bc8b0b8:0": "MagMod",
  "Merchant:00000000-0000-0000-0000-0003f21e5040:0": "Gravity Jack",
  "Merchant:00000000-0000-0000-0000-00038fc6c028:0": "Nuun & Company",
  "Merchant:00000000-0000-0000-0000-00038b19c088:0": "Guii",
  "Merchant:00000000-0000-0000-0000-00036bc990b8:0": "Ledge Lounger",
  "Merchant:00000000-0000-0000-0000-000350994078:0": "Guesty",
  "Merchant:00000000-0000-0000-0000-0003509ae078:0": "Gushcloud",
  "Merchant:00000000-0000-0000-0000-0003b3a290c0:0": "Greenleaf Book Group",
  "Merchant:00000000-0000-0000-0000-00035cc120a8:0": "Guru Technologies",
  "Merchant:00000000-0000-0000-0000-0003c35550d0:0": "Grey Area",
  "Merchant:00000000-0000-0000-0000-00038ff8a0c8:0": "GTFO Ventures",
  "Merchant:00000000-0000-0000-0000-00034dfd7078:0": "Natural Force",
  "Merchant:00000000-0000-0000-0000-000350970050:0": "Guiltlessbeauty",
  "Merchant:00000000-0000-0000-0000-00038b1e0088:0": "Ivivva athletica",
  "Merchant:00000000-0000-0000-0000-000393db30c8:0": "Gridpoint Systems",
  "Merchant:00000000-0000-0000-0000-00033b04a0a0:0": "GrupHediye",
  "Merchant:00000000-0000-0000-0000-00036bce60b8:0": "GroupTie",
  "Merchant:00000000-0000-0000-0000-00033a937020:0": "Guardly",
  "Merchant:00000000-0000-0000-0000-000393dac0c8:0": "Great Lakes Graphite",
  "Merchant:00000000-0000-0000-0000-0003c350b0d0:0": "Great Lakes Pharmaceuticals",
  "Merchant:00000000-0000-0000-0000-0003b39ce0c0:0": "Bridge Partners",
  "Merchant:00000000-0000-0000-0000-0003efc34040:0": "Gramco",
  "Merchant:00000000-0000-0000-0000-0003b3a3b0c0:0": "Very",
  "Merchant:00000000-0000-0000-0000-000353677018:0": "Maverik Lacrosse",
  "Merchant:00000000-0000-0000-0000-0003b3a320c0:0": "Ajio",
  "Merchant:00000000-0000-0000-0000-000393da30c8:0": "CoTa Global",
  "Merchant:00000000-0000-0000-0000-0003a1973008:0": "Lorna Jane",
  "Merchant:00000000-0000-0000-0000-00034e0af078:0": "Guided Interventions",
  "Merchant:00000000-0000-0000-0000-00034c0da070:0": "TYKA",
  "Merchant:00000000-0000-0000-0000-000393d990c8:0": "Guangzhou Huan Company",
  "Merchant:00000000-0000-0000-0000-0003c35350d0:0": "GreenItaly1",
  "Merchant:00000000-0000-0000-0000-00038ff810c8:0": "Group IV Semiconductor",
  "Merchant:00000000-0000-0000-0000-00038fc8c028:0": "Mirraw",
  "Merchant:00000000-0000-0000-0000-00034dfe0078:0": "Tile",
  "Merchant:00000000-0000-0000-0000-00038fc73028:0": "Handango",
  "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0": "GreenPocket",
  "Merchant:00000000-0000-0000-0000-0003b3a4b0c0:0": "Growlife",
  "Merchant:00000000-0000-0000-0000-00033b0410a0:0": "Beverly Hills Teddy Bear",
  "Merchant:00000000-0000-0000-0000-00034e0bf078:0": "Grupo A",
  "Merchant:00000000-0000-0000-0000-00038b193088:0": "GreenVolts",
  "Merchant:00000000-0000-0000-0000-000383d09058:0": "American Meetings"
};

export const usLookupData = {
  "i_userService:00000000-0000-0000-0000-00036b6c40b8:0": "Payment",
  "i_userService:00000000-0000-0000-0000-00034bb02070:0": "Search",
  "i_userService:00000000-0000-0000-0000-000382ed1058:0": "Login",
  "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0": "AddToCart",
  "i_userService:00000000-0000-0000-0000-0003921fa0c8:0": "Payout"
};

export const spliceAndGetMockEntities = (min: number, max: number, dataset: Record<string, string>): Record<string, string> =>
  pick(dataset, Object.keys(dataset).slice(min, max));


export default entityLookupData;
