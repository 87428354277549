
import axios, { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import appConfig from "../../../appConfig";
import DashboardModel, { DashboardModelMeta } from "../models/DashboardModel";
import {
  GrafanaDashboardListResult, GrafanaCreateOrUpdateDashboardAPIResponse, GrafanaDashboardOperationResponse,
  GrafanaCreateOrUpdateDashboardResult, GrafanaGetDashboardModel, GrafanaGetDashboardResult, GrafanaDashboard,
  GrafanaDashboardModel, DashboardListItem
} from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

class DashboardApi {
  private DB_ID_PREFIX = "inc_";
  private CREATE_DASHBOARD_PATH = 'create';
  private GET_DASHBOARD_PATH = 'db';
  private UPDATE_DASHBOARD_PATH = 'update';
  private DELETE_DASHBOARD_PATH = 'delete';
  private DASHBOARD_LIST_PATH = 'list';
  private INC_DASHBOARD_TYPE = 'dash-inc';
  private DEPRECATED_TAG = "deprecated";

  private dashboardAxiosConfig = axios.create({
    baseURL: appConfig.dashboardUrl,
  });

  getDashboardList = async (): Promise<Result<DashboardListItem[]>> => {
    const params = {
      type: this.INC_DASHBOARD_TYPE
    };
    const dashboardResultResponse: AxiosResponse<GrafanaDashboardListResult[]> =
      await this.dashboardAxiosConfig.get(this.DASHBOARD_LIST_PATH, { params });

    let data: DashboardListItem[] = undefined,
      error = false,
      message = '';

    try {
      const {
        data: dashboardListResult,
        statusText
      } = dashboardResultResponse;

      if (dashboardListResult) {
        const nonDeprecatedDashboardListResult: DashboardListItem[] = [];

        dashboardListResult.forEach(dbResult => {
          const {
            createdAt,
            createdBy,
            id,
            title,
            uid,
            updatedAt,
            updatedBy,
            tags
          } = dbResult;

          if (!tags.includes(this.DEPRECATED_TAG)) {
            const listItem: DashboardListItem = {
              id: this.getDashboardIdFromGrafanaUid(uid),
              name: title,
              created: createdAt,
              createdBy,
              updated: updatedAt,
              updatedBy,
              apptuitDbId: id,
              tags,
              isMigrated: false
            };

            nonDeprecatedDashboardListResult.push(listItem);
          }
        });

        data = nonDeprecatedDashboardListResult;
      } else {
        error = true;
        message = statusText;
      }
    } catch (err) {
      error = true;
      message = err.message;

      // If the error is not JS error but AxiosError
      if (err.response?.data) {
        message = err.response.data.message;
      }
    }

    return {
      data,
      error,
      message
    };
  };

  createOrUpdateDashboard = async (
    dbData: DashboardModel,
    isUpdate: boolean
  ): Promise<Result<GrafanaCreateOrUpdateDashboardAPIResponse>> => {
    const url = isUpdate ? this.UPDATE_DASHBOARD_PATH : this.CREATE_DASHBOARD_PATH;

    let data = -1,
      error = false,
      message = '';

    try {
      const dbRequestData = this.getApptuitRequestJson(dbData, isUpdate);
      const createDbAxiosResponse: AxiosResponse<GrafanaCreateOrUpdateDashboardResult> = await this.dashboardAxiosConfig.post(url, dbRequestData);
      const {
        status,
        message: statusMessage,
        version
      } = createDbAxiosResponse.data;
      if (status === 'success') {
        data = version;
      } else {
        message = statusMessage;
        error = true;
      }
    } catch (err) {
      error = true;
      message = err.message;

      // If the error is not JS error but AxiosError
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
    }

    return {
      data: {
        version: data,
        id: dbData.id
      },
      error,
      message
    };
  };

  deleteDashboard = async (dashboardId: string): Promise<Result<string>> => {
    const uid = this.getDashboardUid(dashboardId);
    const url = `${this.DELETE_DASHBOARD_PATH}/${uid}`;

    let data: string = undefined,
      error = false,
      message = '';
    try {
      const dbAxiosResponse: AxiosResponse<GrafanaDashboardOperationResponse> = await this.dashboardAxiosConfig.delete(url);
      const {
        data: dbResponse,
        statusText
      } = dbAxiosResponse;
      if (dbResponse) {
        const {
          error: resError,
          message: resMessage
        } = dbResponse;
        if (!resError) {
          data = 'success';
          message = resMessage;
        } else {
          error = true;
          message = resMessage;
        }
      } else {
        error = true;
        message = `Dashboard delete failed (${statusText})`;
      }
    } catch (err) {
      error = true;
      message = err.message;

      // If the error is not JS error but AxiosError
      if (err.response?.data) {
        message = err.response.data.message;
      }
    }
    return {
      data,
      error,
      message
    };
  };

  getDashboard = async (dashboardId: string): Promise<Result<GrafanaGetDashboardModel>> => {
    const uid = this.getDashboardUid(dashboardId);
    const response = await this.getDashboardInternal(uid);
    const newResponse: Result<GrafanaGetDashboardModel> = {
      ...response,
      data: undefined
    };
    if (!response.error && response.data) {
      const {
        dashboard,
        meta,
        apptuitDbId
      } = response.data;

      const incMeta: Partial<DashboardModelMeta> = {
        ...dashboard.meta,
        createdAt: meta.created,
        createdBy: meta.createdBy,
        updatedAt: meta.updated,
        updatedBy: meta.updatedBy
      };

      const dbData: DashboardModel = {
        ...dashboard,
        meta: incMeta
      };

      newResponse.data = {
        dashboard: dbData,
        meta,
        apptuitDbId
      };
    }
    return newResponse;
  };

  deleteDashboardWidget = async (widgetId: string): Promise<Result<string>> => {
    const url = `${appConfig.defaultExploreDsName}/${widgetId}`;
    let data: string = undefined,
      error = false,
      message = '';
    try {
      const dbAxiosResponse: AxiosResponse<GrafanaDashboardOperationResponse> = await this.dashboardAxiosConfig.delete(url);
      const {
        data: dbResponse,
        statusText
      } = dbAxiosResponse;
      if (dbResponse) {
        const {
          error: resError,
          message: resMessage
        } = dbResponse;
        if (!resError) {
          data = 'success';
          message = resMessage;
        } else {
          error = true;
          message = resMessage;
        }
      } else {
        error = true;
        message = `Dashboard widget delete failed (${statusText})`;
      }
    } catch (err) {
      error = true;
      message = err.message;

      // If the error is not JS error but AxiosError
      if (err.response?.data) {
        message = err.response.data.message;
      }
    }
    return {
      data,
      error,
      message
    };
  };

  private getDashboardInternal = async (dashboardId: string): Promise<Result<GrafanaGetDashboardResult>> => {
    const url = `${this.GET_DASHBOARD_PATH}/${dashboardId}`;

    let data: GrafanaGetDashboardResult = undefined,
      error = false,
      message = '';
    try {
      const dbAxiosResponse: AxiosResponse<GrafanaDashboard> = await this.dashboardAxiosConfig.get(url);
      const {
        data: dbResponse,
        statusText
      } = dbAxiosResponse;
      if (dbResponse) {
        const {
          dashboard,
          meta
        } = dbResponse;
        if (dashboard && dashboard.inceptionJson) {
          // Fix for explore widgets that have widgetResponse added to them
          (dashboard.inceptionJson.widgets || []).forEach(w => {
            const { type } = w;
            if (type === "entity-data" && w["widgetResponse"]) {
              console.log("WidgetResponse found, deleting it", w["widgetResponse"]);
              delete w["widgetResponse"];
            }
          });

          data = {
            dashboard: this.getDashboardFromApptuitJson(dashboard),
            apptuitDbId: dashboard.id,
            meta
          };
          message = 'Dashboard fetch success';
        } else {
          error = true;
          message = 'Selected dashboard is not a valid inception dashboard';
        }
      } else {
        error = true;
        message = `Dashboard fetch failed (${statusText})`;
      }
    } catch (err) {
      error = true;
      message = err.message;

      // If the error is not JS error but AxiosError
      if (err.response?.data) {
        message = err.response.data.message;
      }
    }
    return {
      data,
      error,
      message
    };
  };

  private getApptuitRequestJson = (data: DashboardModel, isUpdate: boolean): Pick<GrafanaDashboard, 'dashboard'> => {
    const { id, name: title, version, meta } = data;

    const dbData = cloneDeep(data);
    dbData.version = isUpdate ? dbData.version + 1 : version;

    const { tags = [], isMigrated } = meta || {};
    const uid = this.getDashboardUid(id);

    return {
      dashboard: {
        uid,
        title,
        version,
        inceptionJson: dbData,
        tags: isMigrated ? [...tags, this.DEPRECATED_TAG] : tags,
        isDeprecated: isMigrated,
        isMigrated
      }
    };
  };

  private getDashboardFromApptuitJson(apptuitJson: GrafanaDashboardModel): DashboardModel {
    const {
      inceptionJson,
      isMigrated = false,
      tags = []
    } = apptuitJson;

    inceptionJson.meta = {
      ...(inceptionJson.meta || {}),
      tags,
      isMigrated
    };

    return inceptionJson;
  }

  // Util method to get the DB id as stored by apptuit backend.
  // We do not want the actual inception dashboard's ID to have inc_ prefix
  private getDashboardUid(id: string) {
    return this.DB_ID_PREFIX + id;
  }

  private getDashboardIdFromGrafanaUid(grDbId: string) {
    return grDbId.replace(this.DB_ID_PREFIX, '');
  }
}

const grafanaDashboardApi = new DashboardApi();
export default grafanaDashboardApi;
