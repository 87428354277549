export enum Feature {
  businessEntity = 'businessEntity',
  operationalize = 'operationalize',
  configurations = 'configurations',
  dashboards = 'dashboards',
  integrations = 'integrations',
  users = 'users',
  autoDiscovery = 'autoDiscovery',
  alerts = 'alerts'
}

export enum Action {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}
