import { useCallback } from "react";
import { useFetchDataStatus } from "../../../../core";
import { TriageConfigDTO, TriagePathConfigType } from "../types";
import { triage3ApiService } from "../../Triage";

export const useFetchTriageConfig = (initialIsFetching = false) => {
  const {
    data,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<TriageConfigDTO[], string>({
    data: null,
    isFetching: initialIsFetching
  });

  const fetchTriageConfigs = useCallback(async (
    entityTypeId: string,
    configType: TriagePathConfigType,
    cohortId?: string,
    widgetId?: string,
    labels: Record<string, string> = {}
  ) => {
    setStatus(null, true, false, false, null);
    try {
      const response = await triage3ApiService.getTriagePathConfigs({
        entityTypeId: entityTypeId,
        configType: configType,
        eventTypes: null,
        labels,
        cohortId,
        widgetId
      });
      setStatus(response.data || [], false, true, false, null);
    } catch (e) {
      console.log(e);
      setStatus(null, false, false, true, e);
    }
  }, [setStatus]);

  return {
    data,
    error,
    isError,
    isFetching,
    isSuccess,
    fetchTriageConfigs
  };
};
