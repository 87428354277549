/**
 * For colors, as the suffix number increases, the property increases. For example, light5 is lighter than light2, and
 * similarily dark5 is darker that dark2.
 */

import { transparentize } from "polished";
import Colors from "./types/colors";

const baseColors = {
  gray: {
    dark1: '#767980',
    dark2: '#52545c',
    dark3: '#464c54',
    dark4: '#343b40',
    dark5: '#202226',
    dark6: '#141619',
    dark7: '#0b0c0e',
    light1: '#9fa7b3',
    light2: '#acb6bf',
    light3: '#c7d0d9',
    light4: '#c7d0d9', // adding this to adhere to interface (FIX REQUIRED)
    light5: '#dde4ed',
    light6: '#e9edf2',
    light7: '#e9edf2', // adding this to adhere to interface (FIX REQUIRED)
    light8: '#f7f8fa',
    light9: '#f7f8fa'  // adding this to adhere to interface (FIX REQUIRED)
  },
  black: {
    base: '#000000',
    dark1: '#343436',
    dark2: '#303133',
    dark3: '#292a2d',
    dark4: '#262628',
    dark5: '#1e2028',
    light1: '#35373f',
    light2: '#35373f', // adding this to adhere to interface (FIX REQUIRED)
    light3: '#41444b',
    light4: '#41444b', // adding this to adhere to interface(FIX REQUIRED)
    light5: '#424345'
  },
  blue: {
    base: '#0083b3',
    grayish: '#212327',
    dark: '#3274d9',
    shade: '#1f60c4',
    light: '#5794f2',
    faint: '#f5f9ff',
    inception: '#2264D1' // as per the figma mock named inception Blue
  },
  red: {
    dark: '#e02f44',
    base: '#d44a3a',
    shade: '#c4162a',
    secondary: '#EB5757',
  },
  green: {
    base: '#3eb15b',
    shade: '#369b4f',
    secondary: '#3AA76D',
  },
  orange: {
    base: '#ff7941',
    dark: '#ed5700'
  },
  yellow: {
    base: '#ff851b',
    secondary: '#FFBE3D',
  },
  purple: '#9954bb',
  variable: '#007580',
  white: '#ffffff',
  inputBlack: '#09090b',
};

const colors = {
  brand: {
    primary: baseColors.green.shade,
    success: baseColors.green.base,
    warning: baseColors.orange.base,
    danger: baseColors.red.base,
    secondary: baseColors.blue.dark
  },
  query: {
    red: baseColors.red.base,
    green: baseColors.green.base,
    purple: baseColors.purple,
    keyword: baseColors.blue.dark,
    orange: baseColors.orange.base
  },
  status: {
    online: baseColors.green.shade,
    warn: '#f79520',
    critical: baseColors.red.shade
  },
  attributes: {
    purple: '#855CF8',
    green: baseColors.green.secondary,
    yellow: baseColors.yellow.secondary,
    red: baseColors.red.secondary
  }
};

const lightColors: Colors = {
  ...baseColors,
  ...colors
};

export default lightColors;

export const lightChartColors: string[] = [
  "#2F80ED",
  "#925CB0",
  "#ff8ba7",
  "#ffbf71",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#c2bd30",
  "#7EB26D",
  "#EAB839",
  "#6ED0E0",
  "#EF843C",
  "#E24D42",
  "#1F78C1",
  "#BA43A9",
  "#705DA0",
  "#508642",
  "#CCA300",
  "#447EBC",
  "#C15C17",
  "#890F02",
  "#0A437C",
  "#6D1F62",
  "#584477",
  "#B7DBAB",
  "#F4D598",
  "#70DBED",
  "#F9BA8F",
  "#F29191",
  "#82B5D8",
  "#E5A8E2",
  "#AEA2E0",
  "#629E51",
  "#E5AC0E",
  "#64B0C8",
  "#E0752D",
  "#BF1B00",
  "#0A50A1",
  "#962D82",
  "#614D93",
  "#9AC48A",
  "#F2C96D",
  "#65C5DB",
  "#F9934E",
  "#EA6460",
  "#5195CE",
  "#D683CE",
  "#806EB7",
  "#3F6833",
  "#967302",
  "#2F575E",
  "#99440A",
  "#58140C",
  "#052B51",
  "#511749",
  "#3F2B5B",
  "#E0F9D7",
  "#FCEACA",
  "#CFFAFF",
  "#F9E2D2",
  "#FCE2DE",
  "#BADFF4",
  "#F9D9F9",
  "#DEDAF7",
  "#AAAAAA",
].map(color => transparentize(0.25, color));
