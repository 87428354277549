import { IncSelectorDuration } from '@inception/ui';
import { cloneDeep } from 'lodash';
import { getAggregationFromAggrValue, DataType, MetricAggregatorExpanded } from '../../platform/core';
import {
  ConditionWithPickerType, MetricPredicate, TraceUIAggregation,
  UserServiceFieldMetricConfigDefinition
} from "../../platform/services/api/explore";
import { getOpFromPredicateOp } from '../../platform/utils/CohortUtils';
import { ExploreQueryUtils } from '../../platform/utils/ExploreQueryUtils';
import { getOptionFromString, getDefaultDataTypeAggregation } from '../../platform/utils/ExploreUtils';
import { getDurationFromTimeObj } from '../../platform/utils/DurationUtils';
import { RelativeDurationType } from '../../platform/dashboard/models/BaseWidgetModel';

export function getUIAggregation(agg: MetricAggregatorExpanded): TraceUIAggregation {
  const aggr = getAggregationFromAggrValue(agg);
  const uiAggr: TraceUIAggregation = {
    aggrType: aggr.type,
    fraction: aggr.fractions
  };
  return uiAggr;
}

export function getAggrFromPredicate(predicate: MetricPredicate, useDefault = false): TraceUIAggregation {
  if (!predicate) {
    return useDefault ? getDefaultDataTypeAggregation(null) : null;
  }


  if (!predicate.aggregator) {
    if (predicate.metric?.sourceType === "userServiceField") {
      let dataType: DataType = null;
      const usf = predicate.metric as UserServiceFieldMetricConfigDefinition;
      dataType = usf.userServiceFieldMetricConfig.userServiceField.dataType;
      return getDefaultDataTypeAggregation(dataType);
    }
  }
  return getUIAggregation((predicate.aggregator || "avg") as MetricAggregatorExpanded);
}

export function getDurationFromPredicate(predicate: MetricPredicate): IncSelectorDuration {
  const { aggregateDuration, aggregateDurationSeconds } = predicate || {};
  if (!aggregateDurationSeconds && !aggregateDuration) {
    return null;
  }
  return aggregateDuration ? getDurationFromTimeObj(aggregateDuration)
    : getTimeshiftSelectionFromSecs(aggregateDurationSeconds);
}

export function getOperationOptionFromPredicate(predicate: MetricPredicate) {
  if (!predicate || !predicate.op) {
    return null;
  }
  const opString = getOpFromPredicateOp(predicate.op);
  return getOptionFromString(opString);
}

export function getValidFilters(filters: ConditionWithPickerType[]) {
  const validFilters = filters.map(x => {
    if (!ExploreQueryUtils.validateFilter(x)) {
      return null;
    }
    return cloneDeep(x);
  });
  return validFilters;
}


export function getShiftSecs(duration: number, durationType: RelativeDurationType): number {
  let factor: number;

  switch (durationType) {
    case RelativeDurationType.MINUTES: {
      factor = durationMap.minute;
      break;
    }

    case RelativeDurationType.HOURS: {
      factor = durationMap.hour;
      break;
    }

    case RelativeDurationType.DAYS: {
      factor = durationMap.day;
      break;
    }

    case RelativeDurationType.WEEKS: {
      factor = durationMap.week;
      break;
    }

    case RelativeDurationType.MONTHS: {
      factor = durationMap.month;
      break;
    }

    default: factor = 0;
  }

  return duration * factor;
}

export function getTimeshiftSelectionFromSecs(shiftSecs: number): {
  duration: number;
  durationType: RelativeDurationType;
} {
  let duration: number;
  let durationType: RelativeDurationType;

  duration = (shiftSecs / durationMap.minute);
  const isMinutes = duration < 60;

  if (!isMinutes) {
    duration = (shiftSecs / durationMap.hour);

    const isHours = duration < 24;
    if (!isHours) {
      duration = (shiftSecs / durationMap.day);
      const isDays = duration < 7;

      if (!isDays) {
        duration = (shiftSecs / durationMap.week);
        const isWeeks = duration < 5;

        if (!isWeeks) {
          duration = (shiftSecs / durationMap.month);
          durationType = RelativeDurationType.MONTHS;
        } else {
          durationType = RelativeDurationType.WEEKS;
        }
      } else {
        durationType = RelativeDurationType.DAYS;
      }
    } else {
      durationType = RelativeDurationType.HOURS;
    }
  } else {
    durationType = RelativeDurationType.MINUTES;
  }

  return {
    duration,
    durationType
  };
}

const durationMap = {
  minute: 60,
  hour: 60 * 60,
  day: 24 * 60 * 60,
  week: 7 * 24 * 60 * 60,
  month: 30 * 24 * 60 * 60
};
