import { getInceptionTheme, InceptionTheme } from '@inception/ui';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'emotion-theming';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { render as reactDomRender } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ThemeImporter from './app/themes/ThemeImporter';

import configureStore from './store';
import { history } from './store/rootReducer';

// import messages_fr from "./translations/fr-FR.json";
import MessagesEn from "./translations/en-US.json";

// const messages = {
//     'fr': messages_fr,
//     'en': MessagesEn
// };

const initialState = (window as any).INITIAL_REDUX_STATE;
const store = configureStore(initialState);

export const renderReactComponent = (reactElement: React.ReactNode, targetDiv: HTMLElement | Element) => {
  const componentToRender = getReactComponentWithContext(reactElement, getInceptionTheme());
  reactDomRender(componentToRender, targetDiv);
};

const getReactComponentWithContext = (reactElement: React.ReactNode, theme: InceptionTheme) => <ThemeImporter>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale="en" messages={MessagesEn}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          maxSnack={3}
        >
          <ThemeProvider theme={theme}>
            {reactElement}
          </ThemeProvider>
        </SnackbarProvider>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>
</ThemeImporter >;


