import { useMemo } from "react";
import { useAuth } from "../../login/state/useAuth";
import { PermissionsInterface } from "../../permissions/PermissionsManager";

interface AccessPrivilege {
  canView: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
  permissions?: PermissionsInterface;
}

const useAccessPrivilege = (): AccessPrivilege => {
  const { authState } = useAuth();
  const { user, permissions } = authState;
  const userRole = user?.role;

  return useMemo(() => ({
    canView: true,
    canCreate: userRole !== "Viewer",
    canEdit: userRole !== "Viewer",
    canDelete: userRole !== "Viewer",
    permissions
  }),[permissions, userRole]);
};

export default useAccessPrivilege;
