import { TimeSeries } from "../../../core";
import { DatasourceApi } from "../../api/DatasourceService";
import { BaseApi, DataQuery, DataQueryRequest, DataQueryResponse, DatasourceSettings, InceptionRequestConfig, InceptionResponse } from "../../api/types";
import { ExploreQuery } from "../explore/types";
import { TraceQuery } from "../traces/TraceDatasource";
import widgetMockDataHandlerRegistry from "./MockDataRegistry";
import { getRCWMockData, getECSMockData, getEntityTableData, EAMockData, getUEMockData } from "./mockData";

export interface MockQuery extends DataQuery {
  metric: any;
  output: boolean;
  alias: any;
  id: any;
  agg: any;
  rate: string;
  dsInterval: any;
  dsFn: any;
  mockData: any;
  tags?: any;
  eTags?: any;
}

export interface MockSettings extends DatasourceSettings { }

export class MockDatasource extends DatasourceApi<MockQuery, MockSettings> implements DatasourceApi<MockQuery, MockSettings> {

  private tagKeys: Record<string, string[]> = {};

  constructor(name: string, settings: MockSettings, baseService: BaseApi) {
    super(name, "mock", settings, baseService);
  }

  private getRCWSeries(request: DataQueryRequest<TraceQuery>) {
    const isCompare = request.requestId.includes('compare');
    const target = request.targets[0];
    const fieldName = Object.keys(target.query.aggregations)[0];
    return getRCWMockData(fieldName, isCompare) as any;
  }

  private getECSSeries(requestId: string) {
    return getECSMockData(requestId);
  }

  private getEASeries() {
    return EAMockData as any;
  }

  private getUESeries(request: DataQueryRequest<any>) {
    return getUEMockData(request);
  }

  private getEntityTableSeries(request: DataQueryRequest<ExploreQuery>): any {
    const target = request.targets[0];
    const { refId, payload: { tagFilters } } = target;
    const requestId = request.requestId;
    const userService = tagFilters.length > 0 ? tagFilters[0].tagValue : null;
    if (userService) {
      return getEntityTableData(userService);
    }
    const usRequest = requestId.startsWith('entity-table-us');
    if (refId.startsWith('errors')) {
      return getEntityTableData(usRequest ? 'errors_per_us' : 'errors');
    }
    return getEntityTableData(usRequest ? 'requests_per_us' : 'requests');
  }

  query(request: DataQueryRequest<MockQuery>): Promise<DataQueryResponse> {
    const { targets } = request;
    let timeseries: TimeSeries[] = [];
    if (targets.length > 0) {
      const requestId = request.requestId;
      const mockHandler = widgetMockDataHandlerRegistry.getHandler(requestId);
      if (mockHandler) {
        timeseries = mockHandler.getData(request);
      } else if (requestId.includes('RCW')) {
        timeseries = this.getRCWSeries(request);
      } else if (requestId.startsWith("ECS")) {
        timeseries = this.getECSSeries(requestId);
      } else if (requestId.startsWith('EA')) {
        timeseries = this.getEASeries();
      } else if (requestId.startsWith('entity-table')) {
        timeseries = this.getEntityTableSeries(request as any);
      } else if (requestId.startsWith('UE')) {
        timeseries = this.getUESeries(request as any) as any;
      }else {
        timeseries = request.targets.map(t =>
          ({
            target: t.metric || "mock",
            datapoints: t.mockData || [[12875, Date.now()]],
            tags: t.tags || {},
            eTags: t.eTags || {},
            metricName: t.metric || "mock",
          } as TimeSeries));
      }

      return Promise.resolve({
        data: timeseries
      } as DataQueryResponse);
    }
  }



  get<T, B, R = InceptionResponse<T>>(url: string, payload?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.get(dsUrl, payload, config);
  }

  post<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.post(dsUrl, data, config);
  }

  delete<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.delete(dsUrl, config);
  }

  put<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.put(dsUrl, data, config);
  }


}
