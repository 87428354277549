export type DataType = "STRING" | "LONG" | "BOOLEAN" | "DATETIME" | "ENTITY" | "DOUBLE" | "MAP" | "SET" | "DATE"
| "LIST_STRING" | "LIST_LONG" | "LIST_ENTITY" | "LIST_DOUBLE" | "EMAIL" | "GEOLOCATION" | "URL" | "ZIP_CODE"
| "DATE_STRING" | "TELEPHONE" | "ADDRESS" | "IP_ADDRESS" | "CURRENCY" | "NUMBER" | "EPOCH_MILLIS" | "EPOCH_SECS" | "EPOCH_MICROS" | "DURATION_MILLIS" | "DURATION_SECS"
| "PERCENT_1" | "PERCENT_100" | "EPOCH" | "DURATION" | "OBJECT_MAP" | "ERROR";

export type PrimKind = "_str" | "_long" | "_double" | "_date" | "_datetime" | "_bool" | "_set" | "_map" | "_objectmap" | "_geo";

//TODO: remove _date and _datetime usages as prim/base types throughout UI
export type FieldPrimType = "_unset" | "_long" | "_double" | "_date" | "_bool"
| "_boolean" | "_map" | "_objectmap" | "_set" | "_array" | "_str" | "_array_map" | "_array_long" | "_array_double" | "_datetime"
| "_array_date" | "_array_dateTime" | "_array_boolean" | "_array_str" | "_list_str" | "_list_long" | "_list_double" | "_list_entity";

export type FieldSubType = "currency" | "email" | "url" | "ipAddress" | "not_set" | "none" | "geolocation" | "number"
| "percent_1" | "percent_100" | "epoch_millis" | "epoch_secs" | "ip_address" | "address" | "tel" | "zip_code" | "date_str" | "duration_millis"
| "duration_secs" | "duration_micros" | "date" | "datetime" | "entity" | "epoch" | "duration" | "error" | "epoch_micros";

//FieldDataType is union of both PrimTypes and SubTypes
export type FieldDataType = FieldPrimType | FieldSubType;

export const DataTypeToPrimTypeMap = {
  "STRING": "_str",
  "LONG": "_long",
  "BOOLEAN": "_bool",
  "DATETIME": "_datetime",
  "ENTITY": "_unset",
  "DOUBLE": "_double",
  "MAP": "_map",
  "SET": "_set",
  "DATE": "_date",
  "LIST_STRING": "_array_str",
  "LIST_LONG": "_array_long",
  "LIST_DOUBLE": "_array_double",
  "LIST_ENTITY": "_array_entity",
  "EMAIL": "_array_str",
  "GEOLOCATION": "_geo",
  "URL": "_str",
  "ZIP_CODE": "_str",
  "DATE_STRING": "_date",
  "TELEPHONE": "_str",
  "ADDRESS": "_str",
  "IP_ADDRESS": "_str",
  "CURRENCY": "_str",
  "NUMBER": "_str",
  "EPOCH_MILLIS": "_long",
  "EPOCH_SECS": "_long",
  "EPOCH_MICROS": "_long",
  "DURATION_MILLIS": "_long",
  "DURATION_SECS": "_long",
  "PERCENT_1": "_str",
  "PERCENT_100": "_str",
  "EPOCH": "_datetime",
  "DURATION": "_datetime",
  "OBJECT_MAP": "_objectmap",
  "ERROR": "_unset"
};

export type CoreDataType =
  "INT64" |
  "LONG" |
  "DOUBLE" |
  "FLOAT64" |
  "STRING" |
  "LIST_STRING" |
  "ENTITY" |
  "LIST_ENTITY" |
  "BINARY" |
  "BOOL" |
  "COMPLEX" |
  "UNKNOWN" |
  "DATE" |
  "DATETIME";

/** enum definition of all possible Datatypes  */
export enum CoreDataTypes {
  INT64 = "INT64",
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  FLOAT64 = "FLOAT64",
  STRING = "STRING",
  LIST_STRING = "STRING",
  ENTITY = "ENTITY",
  LIST_ENTITY = "ENTITY",
  BINARY = "BINARY",
  BOOL = "BOOL",
  COMPLEX = "COMPLEX",
  UNKNOWN = "UNKNOWN",
  DATE = "DATE",
  DATETIME = "DATETIME"
}

export const CoreNumericDataTypes: string[] = [CoreDataTypes.INT64, CoreDataTypes.LONG, CoreDataTypes.DOUBLE, CoreDataTypes.FLOAT64];

export const CoreDateDataTypes: string[] = [CoreDataTypes.DATE, CoreDataTypes.DATETIME];

export const AllCoreDataTypes: string[] = [...CoreNumericDataTypes, ...CoreDateDataTypes,
  CoreDataTypes.STRING, CoreDataTypes.ENTITY, CoreDataTypes.BINARY, CoreDataTypes.BOOL, CoreDataTypes.COMPLEX, CoreDataTypes.UNKNOWN];
