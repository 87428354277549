
import { AceSnippet, TokenProp } from './types';

abstract class QueryBuilderModel {
  suggestKeys?(params?: string): Promise<AceSnippet[]>;
  suggestValues?(token?: TokenProp): Promise<AceSnippet[]>;
  suggestOperators?(type?: string): Promise<AceSnippet[]>;
  suggestConditions?(type?: string): Promise<AceSnippet[]>;
  getMaxWidthOfSuggestedSnippet?(snippets: AceSnippet[]): number;
}


export default QueryBuilderModel;
