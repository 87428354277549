import React, { useCallback, useState } from "react";
import { components, ControlProps, MenuProps, OptionsType } from 'react-select';
import { getSelectValueText } from "../../../utils/components/selectUtils";
import IncClickAway from "../../ClickAway/ClickAway";
import { IncFaIcon } from "../../Icons";
import IncSmartText from "../../SmartText/SmartText";
import IncTextfield from "../../Textfield/TextField";
import { IncSelect, IncSelectOption, IncSelectProps } from "../InceptionSelect";

type SelectProps<OptionType extends IncSelectOption>
  = Omit<IncSelectProps<OptionType, true>, 'components' | 'disablePopper' | 'menuIsOpen'>;

interface IncSlimSelectProps<OptionType extends IncSelectOption> extends SelectProps<OptionType> {
  /**
   * max width in pixels for the component.
   */
  maxWidth?: number;
  maxItemsForLabel?: number;
  customValueJsx?: JSX.Element;
}

export const IncSlimMultiSelect = <OptionType extends IncSelectOption>(props: IncSlimSelectProps<OptionType>) => {
  const {
    isSearchable,
    options,
    wrapperClass,
    customValueJsx,
    label,
    onChange,
    maxWidth,
    maxItemsForLabel = 1
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const numOptions = (options || []).length;

  const ValueContainer = useCallback((props: ControlProps<OptionType, true>) => {
    const selectedOp = props.getValue();
    const displayText = getSelectValueText(
      selectedOp as OptionType[],
      numOptions,
      label,
      undefined,
      false,
      maxItemsForLabel,
      maxWidth
    );

    const onSearchTextChange = (e: any) => setSearchText(e.target.value);
    const onClickAway = () => {
      setMenuIsOpen(false);
      setSearchText("");
    };

    const jsx = menuIsOpen && isSearchable ? <IncTextfield
      autoFocus
      containerClassName="slim-text-field"
      onChange={onSearchTextChange}
      value={searchText}
    />
      : selectedOp.length > 1 ? displayText : (
        <div style={{ width: maxWidth ? maxWidth : "inherit" }}>
          <span className="group-title text-ellipsis">
            <IncSmartText text={`${displayText}`} />
          </span>
        </div>
      );

    return (
      <IncClickAway onClickAway={onClickAway}>
        {(ref) => <div
          className={`inception-select--slim-container`}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          ref={ref}
        >
          {!customValueJsx && <>
            {jsx}
            <IncFaIcon className="marginLt4" iconName="caret-down" />
          </>}
          {customValueJsx}
        </div>}
      </IncClickAway>
    );
  }, [customValueJsx, isSearchable, label, maxItemsForLabel, maxWidth, menuIsOpen, numOptions, searchText]);

  const MenuList = useCallback((props: MenuProps<OptionType, true>) => <div className="inception-select--slim-menu-list">
    <components.Menu {...props} />
  </div>, []);

  const onSelectionChange = useCallback((options: OptionsType<OptionType>, action: any) => {
    onChange && onChange(options, action);
    setMenuIsOpen(false);
  }, [onChange]);

  return <IncSelect<OptionType, true>
    {...props}
    components={{
      Control: ValueContainer,
      Menu: MenuList
    }}
    disablePopper
    inputValue={searchText}
    isMulti
    menuIsOpen={menuIsOpen}
    onChange={onSelectionChange}
    wrapperClass={`inception-select--slim ${wrapperClass ?? ''}`}
  />;
};

