import { lighten, darken, transparentize } from "polished";

import { inceptionDarkColorPalette } from "@inception/ui-styles";
import { InceptionTheme } from "./types/theme";
import Plugins from "./types/plugins";
import Inputs from "./types/inputs";
import darkColors, { darkChartColors } from "./colors.dark";

const {
  gray,
  black,
} = darkColors;
const pageBg = black.dark4;
const pageText = gray.light4;

const plugins: Plugins = {
  table: {
    background: black.dark2,
    text: pageText,
    titleBackground: gray.light7,
    titleText: pageText,
    border: `1px solid ${gray.dark4}`,
    hoverBackground: gray.light8,
  },
  graph: {
    background: black.dark2,
    text: pageText,
    legendText: darken(0.2, pageText),
    legendTextHover: pageText,
    legendDisabled: transparentize(0.7, pageText),
    titleText: pageText,
    titleBackground: gray.light8
  },
  donut: {
    background: black.dark2,
    text: pageText,
    legendText: pageText,
    titleText: pageText,
    titleBackground: gray.light8
  }
};

const inputs: Inputs = {
  button: {
    background: 'transparent',
    defaultBackground: black.light1,
    primaryBackground: darkColors.green.shade,
    secondaryBackground: darkColors.blue.shade,
    errorBackground: darkColors.brand.danger,
    infoBackground: darkColors.blue.base,
    successBackground: darkColors.brand.success,
    warningBackground: darkColors.brand.warning,
    text: pageText,
    defaultText: gray.light4,
    defaultHover: lighten(0.05, black.light1),
    navBackground: black.light1,
    navHover: lighten(0.03, black.light1),
    navBorder: '1px solid #2f2f32'
  },
  checkbox: {
    background: gray.dark5,
    border: `1px solid ${gray.dark2}`,
    checkedBackground: 'linear-gradient(0deg, #0E5A8A, #48AFF0)',
    color: gray.dark5,
    containerBg: pageBg,
    containerDisabledBg: darkColors.blue.grayish,
    containerBorder: `1px solid ${black.light1}`
  },
  toggle: {
    background: gray.dark5,
    shadow: '0 0 3px black',
    thumbColor: black.light2,
    trackOffBackground: gray.dark2,
    trackOnBackground: 'linear-gradient(90deg, #0E5A8A, #48AFF0)',
    containerBg: pageBg,
    containerDisabledBg: darkColors.blue.grayish,
    containerBorder: `1px solid ${black.light1}`
  },
  label: {
    background: darkColors.blue.grayish,
    text: pageText,
    disabledText: darken(0.1, pageText),
    border: `1px solid ${black.light1}`
  },
  textField: {
    background: black.dark5,
    disabledBackground: gray.dark6,
    disabledText: darken(0.1, gray.light1),
    text: gray.light3,
    border: `1px solid ${black.light1}`,
    focusBorder: `1px solid ${black.light1}`,
    focusShadow: `0 0 3px ${darkColors.blue.light}`
  },
  radioButton: {
    color: gray.dark1,
    checkedColor: '#48AFF0',
    containerBg: pageBg,
    containerDisabledBg: darkColors.blue.grayish,
    containerBorder: `1px solid ${black.light1}`
  },
  dropdown: {
    background: black.dark5,
    disabledBackground: gray.dark6,
    disabledText: darken(0.1, gray.light1),
    text: gray.light3,
    border: `1px solid ${black.light1}`,
    focusBorder: `1px solid ${black.light1}`,
    focusShadow: `0 0 5px ${darkColors.blue.light}`
  },
};

const darkTheme: InceptionTheme = {
  name: 'Dark',
  type: 'dark',

  colors: darkColors,
  inceptionColors: inceptionDarkColorPalette,
  chartColors: darkChartColors,

  plugins,
  inputs
};

export default darkTheme;
