import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { getSelectValueText } from '../../utils/components/selectUtils';
import { IncFaIcon } from '../Icons';
import IncPopper from '../Popper/Popper';
import IncMultiSelectCheckBox from './MultiSelectCheckBox';
import { SelectionOption } from './types';

export type MultiSelectOption = SelectionOption;

type SeriesSelectItemProps = {
  options: MultiSelectOption[];
  value: MultiSelectOption[];
  onSelectChange: (args: MultiSelectOption[], isAllSelected: boolean) => void;

  label?: string;
  maxWidth?: number;
  itemsCount?: number;
  menuAsPortal?: boolean;
  /**
   * displays literal 'All' as selected items if all items are selected
   */
  displayLiteralAll?: boolean;
  showPlaceholder?: boolean;
  noSelectText?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  allowCreate?: boolean;
  skipAllOption?: boolean;
  isLoading?: boolean;
  hideOptionsCount?: boolean;
  customValueJsx?: JSX.Element;
};

export const IncMultiSelect: React.FC<SeriesSelectItemProps> = React.memo((props) => {
  const {
    label = '',
    options,
    value,
    onSelectChange,
    maxWidth,
    menuAsPortal = false,
    itemsCount = 1,
    displayLiteralAll = false,
    showPlaceholder = false,
    noSelectText = 'Select',
    hideLabel = false,
    disabled = false,
    allowCreate = false,
    skipAllOption = false,
    isLoading = false,
    hideOptionsCount = false,
    customValueJsx
  } = props;

  const [showDropdownKey, setShowDropdownKey] = useState<boolean>(false);
  const dropDownWrapperRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const placeholder = useMemo(() => showPlaceholder ? `Select ${label}` : '', [label, showPlaceholder]);

  const handleOutSideClick = useCallback((event) => {
    if (showDropdownKey) {
      if (menuAsPortal) {
        const clickedOutsidePortal = !dropDownRef.current?.contains(event.target);
        const clickedOutsideComponent = !dropDownWrapperRef.current?.contains(event.target);
        if (clickedOutsideComponent && clickedOutsidePortal) {
          // pop up click away listener
          setShowDropdownKey(false);
        }
      } else if (!dropDownWrapperRef.current?.contains(event.target)) {
        // wrapper click away listener
        setShowDropdownKey(false);
      }
    }
  }, [showDropdownKey, menuAsPortal]);

  useEffect(() => {
    document.addEventListener('click', handleOutSideClick, false);
    return () => document.removeEventListener('click', handleOutSideClick, false);
  }, [handleOutSideClick]);

  const handleDropdownClick = useCallback(() => {
    setShowDropdownKey(curkey => !curkey);
  }, []);

  const renderSelectedOptions = useCallback((selected: MultiSelectOption[]) =>
    getSelectValueText(selected, options.length, label, noSelectText, displayLiteralAll && skipAllOption, itemsCount, maxWidth)
  , [options.length, label, noSelectText, displayLiteralAll, skipAllOption, itemsCount, maxWidth]);

  const onSeriesSelectionChange = useCallback((selected, id, isAllSelected) => {
    onSelectChange(selected, isAllSelected);
  }, [onSelectChange]);

  const multiSelect = useMemo(() => showDropdownKey ? <div className="inc-multi-select-dd-list">
    <IncMultiSelectCheckBox
      allowCreate={allowCreate}
      autoFocus
      defaultOptions={value}
      disablePopper
      disabled={disabled}
      hideOptionsCount={hideOptionsCount}
      id={label}
      isLoading={isLoading}
      menuIsOpen
      onChange={onSeriesSelectionChange}
      options={options}
      placeholder={placeholder}
      renderInCard
      skipAllOption={skipAllOption}
      width={200}
    />
  </div> : <></>, [showDropdownKey, allowCreate, value, disabled, hideOptionsCount, label, isLoading, onSeriesSelectionChange,
    options, placeholder, skipAllOption]);

  const className = useMemo(() => {
    let className = "inc-multi-select";
    if (disabled) {
      className += ' disableClick';
    }
    return className;
  }, [disabled]);

  return (
    <div className={className}>
      {label && !hideLabel && <div className="inc-multi-select-name">{label}:</div>}
      <div className="inc-multi-select-dd" ref={dropDownWrapperRef}>
        <div className="inc-multi-select-selected" onClick={handleDropdownClick}>
          {!customValueJsx && <>
            <div className="inc-multi-select-selected--text">
              {renderSelectedOptions(value)}
            </div>
            <div className="inc-multi-select-dd-icon">
              {showDropdownKey && <IncFaIcon iconName="caret-up" />}
              {!showDropdownKey && <IncFaIcon iconName="caret-down" />}
            </div>
          </>}
          {customValueJsx}
        </div>
        {menuAsPortal ? <IncPopper
          anchorEl={dropDownWrapperRef.current as HTMLDivElement}
          className="portalSelect"
          placement="bottom-start"
          ref={dropDownRef}
          show={showDropdownKey}
        >
          {multiSelect}
        </IncPopper> : multiSelect}
      </div>
    </div>
  );
}
);
