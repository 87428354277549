import React, { useState, useEffect, useCallback } from 'react';
import { IncFaIcon, IncClickAway, IncPopper } from '@inception/ui';

export type SimpleSelectOption = {
  label: string;
  value: string | number | boolean;
  // to identity in case if you have similar values
  id?: string | number;
};

type IncSimpleSelectProps = {
  options: SimpleSelectOption[];
  /**
   * text mode & link mode to apply various colors
   */
  mode: 'text' | 'link';
  onSelect: (selectedOption: SimpleSelectOption) => void;
  /**
   * default value to be selected
   */
  defaultSelected: SimpleSelectOption;
};

export const IncSimpleSelect: React.FC<IncSimpleSelectProps> = ((props: IncSimpleSelectProps) => {
  const {
    options,
    mode,
    onSelect,
    defaultSelected,
  } = props;

  const [optionSelected, setOptionSelected] = useState<SimpleSelectOption>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    }, []);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (defaultSelected && options?.length && !optionSelected) {
      const defaultOption = options.find(option => option.value === defaultSelected.value);

      setOptionSelected(defaultOption);
    }
  }, [defaultSelected, optionSelected, options]);

  const handleOptionSelect = useCallback((option: SimpleSelectOption) => {
    onSelect(option);
    setOptionSelected(option);
    setAnchorEl(null);
  }, [onSelect]);

  const containerClassName = mode === 'link' ? 'link-mode' : 'text-mode';

  if (!options?.length) {
    console.warn('Please provide options');
    return <></>;
  }

  return (
    <>
      <div className={`simple-select-container flex flex-grow ${containerClassName}`} onClick={handleClick}>
        <span className={'simple-select-selected-option'}>{optionSelected?.label}</span>
        <IncFaIcon className="inc-text-header" iconName="caret-down" />
      </div>
      <IncClickAway
        onClickAway={handleClose}
      >
        {(ref) => <IncPopper
          anchorEl={anchorEl as HTMLElement}
          offset={{
            x: 0,
            y: 0
          }}
          placement="bottom-start"
          ref={ref}
          show={open}
        >
          <div className="simple-select-options flex">
            {
              options.map((option, index) => {
                const { value } = option;
                const isSelected = value === optionSelected?.value;

                return (
                  <SelectOption
                    isSelected={isSelected}
                    key={`${index}-${value}`}
                    onOptionSelect={handleOptionSelect}
                    option={option}
                  />
                );
              })
            }
          </div>
        </IncPopper>
        }
      </IncClickAway>
    </>
  );
});

type SelectOptionProps = {
  option: SimpleSelectOption;
  isSelected: boolean;
  onOptionSelect: (option: SimpleSelectOption) => void;
};

const SelectOption: React.FC<SelectOptionProps> = (props: SelectOptionProps) => {
  const { option, isSelected, onOptionSelect } = props;

  const onSelect = useCallback(
    () => {
      onOptionSelect(option);
    }, [onOptionSelect, option]
  );
  const { label } = option;

  return (
    <div className={`simple-select-option flex-grow ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      {label}
    </div>
  );
};
