import { isEdge } from "react-flow-renderer";

export const getAnimatedApiTraffic = (nodeId: string, elements: any, animatedEdgeIds: string[], direction: "left" | "right") => {
  if (direction === "left") {
    elements.forEach((x: any) => {
      if (isEdge(x) && x.target === nodeId && !animatedEdgeIds.includes(x.id) && !x.id.includes("unverified")) {
        //x.animated = true;
        x.style = {
          ...x.style,
          ...highlightConnectionLineStyle
        };
        animatedEdgeIds.push(x.id);
        getAnimatedApiTraffic(x.source, elements, animatedEdgeIds, "left");
      }
    });
  } else {
    elements.forEach((x: any) => {
      if (isEdge(x) && x.source === nodeId && !animatedEdgeIds.includes(x.id) && !x.id.includes("unverified")) {
        //x.animated = true;
        x.style = {
          ...x.style,
          ...highlightConnectionLineStyle
        };
        animatedEdgeIds.push(x.id);
        getAnimatedApiTraffic(x.target, elements, animatedEdgeIds, "right");
      }
    });
  }
};

const highlightConnectionLineStyle = {
  stroke: '#39ACFF',
  opacity: 1
};
