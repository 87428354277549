import appConfig from '../../../appConfig';
import { logger } from "../../core";
import { ProxyApiDatasource } from '../datasources/proxy-api-datasource/ProxyApiDatasource';
import { TraceDatasource } from "../datasources/traces/TraceDatasource";
import { ExploreDatasource } from '../datasources/explore/datasource';
import { MockDatasource } from '../datasources/mock/MockDatasource';
import { OperationalizeDatasource } from '../datasources/operationalize/datasource';
import { IncDataSource, SupportedDatasource, SupportedTypes } from "./types";
import { request } from "./request";
import { ApptuitDatasource } from '../datasources/apptuit/ApptuitDatasource';
import { featureFlagService, FEATURE_FLAGS } from '../feature-flags';

type DatasourceInstance = TraceDatasource | ProxyApiDatasource | ExploreDatasource | MockDatasource | OperationalizeDatasource;

class DatasourceApiManager {
  private instances: Map<string, DatasourceInstance>;
  private datasources: IncDataSource[];
  constructor() {
    this.instances = new Map();
    this.datasources = [];
  }

  init(datasources: IncDataSource[]) { // Initialized during app boot up. Currently in AppOutline.tsx
    this.datasources = datasources || [];
    this.setCommonHeader();
    datasources.map(ds => {
      const i = this.createInstance(ds);
      this.instances.set(`${ds.name}`, i);
      return i;
    });

    this.setTraceDatasource();
    this.setExploreDatasource();
    this.setMockDatasource();
    this.setOperationalizeDatasource();
  }

  // Setting grafana org Header as common headers to make inception app sticky to an org.
  private setCommonHeader() {
    const dsAny = this.datasources[0];
    if (dsAny && dsAny.orgId) {
      request.setCommonHeader(appConfig.grafanaOrgHeader, `${dsAny.orgId}`);
    }
  }

  private setTraceDatasource() {
    this.instances.set('traces', new TraceDatasource('traces', {
      isGrafanaDS: true,
      id: null,
      proxyUrl: this.instances.get(appConfig.defaultDatasource).getUrl("")
    }, request));
  }

  private setMockDatasource() {
    this.instances.set('mock', new MockDatasource('traces', {
      isGrafanaDS: false,
      id: null,
    }, request));
  }

  private setExploreDatasource() {
    this.instances.set(appConfig.defaultExploreDsName, new ExploreDatasource(appConfig.defaultExploreDsName, {
      isGrafanaDS: true,
      id: null
    }, request));
  }

  private setOperationalizeDatasource() {
    this.instances.set(appConfig.defaultOp10zeDsName, new OperationalizeDatasource(appConfig.defaultOp10zeDsName, {
      isGrafanaDS: true,
      id: null
    }, request));
  }

  createInstance(datasource: IncDataSource) {
    switch (datasource.type) {
      case SupportedDatasource.apptuit:
        const ctor = ProxyApiDatasource;
        return new ctor(datasource.name, {
          id: datasource.id,
          isGrafanaDS: true
        }, request);
      case SupportedDatasource.explore:
        return new ExploreDatasource(datasource.name, {
          id: datasource.id,
          isGrafanaDS: true,
        }, request);
      case SupportedDatasource.operationalize:
        return new OperationalizeDatasource(datasource.name, {
          id: datasource.id,
          isGrafanaDS: true,
        }, request);
      default:
        logger.debug("DatasourceApiService", `No Supporting datasource class found for ${datasource.name} of type ${datasource.type}`);
      //throw Error(`No Supporting datasource class found for ${datasource.name} of type ${datasource.type}`);
      // Not throwing an error as we don't want the page to break.
    }

  }

  get(dsName: string) {
    const instance = this.instances.get(dsName);
    if (!instance) {
      throw new Error(`Invalid datasource ${dsName}. Either datasource type is not supported or datasource doesn't exist.`);
    }
    return instance;
  }

  getSupportedDatasources(): Record<string, SupportedTypes> {
    const supportedDS: Record<string, SupportedTypes> = {};
    this.instances.forEach(i => {
      if (i) {
        supportedDS[i.name] = i.type;
      }
    });
    return supportedDS;
  }

  getDefault(): ProxyApiDatasource {
    return this.get(appConfig.defaultDatasource) as ProxyApiDatasource;
  }
}

const datasourceApiManager = new DatasourceApiManager();
export default datasourceApiManager;
