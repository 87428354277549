import { SpecificScheduleConfig, ScheduleDefForUnit } from "../../services/api/operationalise";

export const getCronExpressionForSpecificSchedule = (scheduleConfig: SpecificScheduleConfig): string => {
  const minute = scheduleConfig?.minute ? getCronExpressionForUnit(scheduleConfig?.minute) : '0';
  const hour = scheduleConfig?.hour ? getCronExpressionForUnit(scheduleConfig?.hour)
    : scheduleConfig?.minute ? '*'
      : '0';
  const dayOfMonth = scheduleConfig?.dayOfMonth ? getCronExpressionForUnit(scheduleConfig?.dayOfMonth) : '*';
  const month = scheduleConfig?.month ? getCronExpressionForUnit(scheduleConfig?.month) : '*';
  const dayOfWeek = scheduleConfig?.dayOfWeek ? getCronExpressionForUnit(scheduleConfig?.dayOfWeek) : '*';

  return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
};

export function getScheduleConfigFromCronExpr(cronExpression: string): SpecificScheduleConfig {
  const parts = cronExpression.split(' ');

  const scheduleConfig: SpecificScheduleConfig = {};

  if (parts.length >= 1) {
    const minute = convertExpressionToScheduleDefForUnit(parts[0]);
    if (minute) {
      scheduleConfig.minute = minute;
    }
  }

  if (parts.length >= 2) {
    if (parts[1] === '*') {
      scheduleConfig.hour = {
        step: 1
      };
    } else {
      const hour = convertExpressionToScheduleDefForUnit(parts[1]);
      if (hour) {
        scheduleConfig.hour = hour;
      }
    }
  }

  if (parts.length >= 3) {
    const dayOfMonth = convertExpressionToScheduleDefForUnit(parts[2]);
    if (dayOfMonth) {
      scheduleConfig.dayOfMonth = dayOfMonth;
    }
  }

  if (parts.length >= 4) {
    const month = convertExpressionToScheduleDefForUnit(parts[3]);
    if (month) {
      scheduleConfig.month = month;
    }
  }

  if (parts.length >= 5) {
    const dayOfWeek = convertExpressionToScheduleDefForUnit(parts[4]);
    if (dayOfWeek) {
      scheduleConfig.dayOfWeek = dayOfWeek;
    }
  }

  return scheduleConfig;
}

function getCronExpressionForUnit(unit: ScheduleDefForUnit): string {
  if (unit?.list) {
    return unit.list.list.join(',');
  }

  if (unit?.range) {
    return `${unit.range.min}-${unit.range.max}`;
  }

  if (unit?.step) {
    return `*/${unit.step}`;
  }

  return '*';
}

function convertExpressionToScheduleDefForUnit(expression: string): ScheduleDefForUnit | undefined {
  if (expression === '*') {
    return null;
  }

  if (expression.includes(',')) {
    const list: number[] = [];
    expression.split(',').forEach((value) => {
      const rangeValues = value.split('-');
      if (rangeValues.length === 1) {
        const hr = parseInt(value, 10);
        list.push(hr);
      } else {
        const [min, max] = rangeValues.map((value) => parseInt(value, 10));
        for (let hr = min; hr <= max; hr++) {
          list.push(hr);
        }
      }
    });

    return {
      list: {
        list
      }
    };
  }

  if (expression.includes('-') && expression.includes('/')) {
    const list: number[] = [];
    const [minMaxExpr, adderStr] = expression.split('/');
    const [min, max] = minMaxExpr.split('-').map((value) => parseInt(value, 10));
    const adder = parseInt(adderStr, 10);

    let startVal = min;

    while (startVal <= max) {
      list.push(startVal);
      startVal += adder;
    }

    return {
      list: {
        list
      }
    };
  }

  if (expression.includes('-') && !expression.includes('/')) {
    const [min, max] = expression.split('-').map((value) => parseInt(value, 10));
    return {
      range: {
        min,
        max
      }
    };
  }

  if (expression.startsWith('*/')) {
    const step = parseInt(expression.substring(2), 10);
    return { step };
  }

  const numValue = parseInt(expression, 10);
  if (!isNaN(numValue)) {
    return {
      list: {
        list: [numValue]
      }
    };
  }

  return undefined;
}

