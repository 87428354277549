import { cx } from 'emotion';
import React, { FC, useMemo, memo } from 'react';
import { generateId } from '../../utils';
import { IncFaIcon } from '../Icons';
import { IncBreadCrumbsProps } from './types';


export const IncBreadCrumbs: FC<IncBreadCrumbsProps> = memo((props) => {
  const {
    items,
    separatorIcon = 'caret-right',
    className: pClassName = ''
  } = props;

  const uniqId = useMemo(() => `inc_breadcrumb-${generateId()}`, []);

  const breadCrumbItems = useMemo(() => items.map((brItem, idx) => {
    const {
      label,
      className: pClassName = '',
      onClick,
      renderer
    } = brItem;

    const isLastItem = idx === items.length - 1;

    const className = cx('inc-bread-crumbs__item inc-flex-row inc-flex-center-vertical flex-gap-12', {
      [pClassName]: Boolean(pClassName),
      "inc-cursor-pointer": Boolean(onClick)
    });

    const key = `${uniqId}-breadcrumb-item-${idx}-${label}`;

    return <div className={className} key={key} onClick={onClick}>
      {renderer && renderer()}
      {!renderer && <>
        <div className="inc-bread-crumbs__item__label inc-flex-row inc-flex-center-vertical">{label}</div>
        {!isLastItem && <IncFaIcon iconName={separatorIcon} />}
      </>}
    </div>;
  }), [items, separatorIcon, uniqId]);

  const className = cx('inc-flex-row inc-flex-center-vertical flex-gap-12', {
    [pClassName]: Boolean(pClassName)
  });

  return <div className={className}>
    {breadCrumbItems}
  </div>;
});
