import React, { useCallback, useEffect, useState, useMemo, FC } from 'react';
import LoadingSpinner from '../Loading/Loading';
import { fetchEntityPropertiesForId, MISSING } from '../../utils/EntityUtils';
import { Entity } from '../../core';
import { logger } from '../../services/logging/Logger';
import { EntityNameRenderer } from './EntityNameRenderer';
import { EntityPropertiesPopover, EntityPropertiesPopoverContent } from './EntityPropertiesPopover';
import { EntityDetails } from './types';

export interface SimpleEntityNameRendererProps {
  id: string;
  name?: string; // If name is passed it will not be fetched from the server
  entityDetails?: EntityDetails; // If details are passed it will not be fetched from the server
  extSections?: Map<string, Map<string, string>>;
  hideIcon?: boolean;

  showPropertiesPopover?: boolean;
  minimalLoader?: boolean;
  propertiesOnly?: boolean;

  onDetailsFetched?: (entityDetails: EntityDetails) => void;
}

export const SimpleEntityNameRenderer: FC<SimpleEntityNameRendererProps> = React.memo((props) => {
  const {
    id,
    name,
    entityDetails: pEntityDetails,
    showPropertiesPopover = false,
    minimalLoader = false,
    hideIcon = false,
    propertiesOnly = false,
    extSections,
    onDetailsFetched
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [entityDetails, setEntityDetails] = useState<EntityDetails>({
    id,
    name,
    ...(pEntityDetails || {})
  });

  const getEntityNameFromProperties = (entity: Entity) => entity.properties?.find(x => x.name === "displayName")?.value
        || entity.properties?.find(x => x.name === "name")?.value || "";

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result: Entity = await fetchEntityPropertiesForId(id);

      if (onDetailsFetched) {
        onDetailsFetched(result);
      }

      if (result) {
        setEntityDetails({
          ...result,
          name: result.name || getEntityNameFromProperties(result) || result.id
        });
      } else {
        setEntityDetails({
          id,
          name: id,
          properties: []
        });
      }
    } catch (error) {
      setEntityDetails({
        id,
        name: id,
        properties: []
      });
      logger.error('SimpleEntityNameRenderer', "Error occurred while fetching displayName", error);
    } finally {
      setLoading(false);
    }
  }, [id, onDetailsFetched]);

  useEffect(() => {
    const idExists = (id && id !== MISSING);
    const shouldFetchProperties = !name || (!pEntityDetails && showPropertiesPopover);

    if (idExists && shouldFetchProperties) {
      fetchData();
    }
  }, [fetchData, id, showPropertiesPopover, name, pEntityDetails]);

  useEffect(() => {
    if (name) {
      setEntityDetails(prevState => ({
        ...prevState,
        name
      }));
    }
  }, [name]);

  const nameComponent = useMemo(() => {
    if (entityDetails) {
      return <EntityNameRenderer
        entityDetails={entityDetails}
        hideIcon={hideIcon}
        hideTooltipOnEllipsis={showPropertiesPopover}
      />;
    }
  }, [entityDetails, hideIcon, showPropertiesPopover]);

  if (!id || id === MISSING) {
    return <>-</>;
  }

  if (loading) {
    const titleText = minimalLoader ? " " : ""; // Empty string will show "Loading...""
    return <LoadingSpinner titleText={titleText} />;
  }

  if (propertiesOnly) {
    return <EntityPropertiesPopoverContent entityDetails={entityDetails} extSections={extSections} />;
  }

  return showPropertiesPopover ? <EntityPropertiesPopover entityDetails={entityDetails} extSections={extSections}>
    {nameComponent}
  </EntityPropertiesPopover> : nameComponent;
});
