import React, { cloneElement, ReactElement, useMemo, useCallback } from 'react';
import { ColDef, RowDataInternal } from '../types';
import { getKeyValue } from '../utils';

const LEVEL_OFFSET = 16;

interface CellProps<T> {
  col: ColDef<T>;
  rowData: RowDataInternal<T>;
  level: number;
  rowIndex: number;
  columnIndex: number;
  onClick(key: string, index: number): void;
}

function Cell<T>(props: CellProps<T>) {
  const getExpandIcon = (data: RowDataInternal<T>, clickHandler: () => void): ReactElement => {
    if (data._hasChildren) {
      if (data._showChildren) {
        return <span className="treegrid-expander"><i className="fa fa-caret-down" onClick={clickHandler}></i></span>;
      }

      return <span className="treegrid-expander"><i className="fa fa-caret-right" onClick={clickHandler}></i></span>;
    }
    return <></>;
  };

  const clickHandler = (): void => {
    if (props.rowData._hasChildren) {
      props.onClick(props.rowData._key, props.rowIndex);
    }
  };

  const getIndent = (level: number) => <span className="treegrid-indent" style={{paddingLeft: level * LEVEL_OFFSET}}></span>;

  const getContent = useCallback((columnDef: ColDef<T>, level: number, rowData: RowDataInternal<T>): ReactElement => {
    const property = columnDef.field;
    const propertyValue = getKeyValue<any, any>(property)(rowData);

    const isCollapsed = rowData._hasChildren && !rowData._showChildren;
    const indent = level * LEVEL_OFFSET;

    if (columnDef.cellRendererFramework) {
      return cloneElement(columnDef.cellRendererFramework(
        {
          value: propertyValue,
          data: rowData,
          isCollapsed,
          additionalCtx: columnDef.additionalCtx,
          indent
        }
      ));
    }

    if (propertyValue === null || propertyValue === undefined) {
      return <></>;
    }

    return propertyValue;
  },[]);

  const content = useMemo(() => getContent(props.col, props.level, props.rowData), [getContent, props.col, props.level, props.rowData]);

  if (!props.rowData._visible) {
    return null;
  }

  const hasChildren: JSX.Element = getExpandIcon(props.rowData, clickHandler);

  const expandIcon: JSX.Element | null = props.columnIndex === 0 ? hasChildren : null;
  const offset: JSX.Element | null = props.columnIndex === 0 ? getIndent(props.level) : null;

  const item =
        <div className='inc-flex-row' key={`${props.rowData._id}_${props.col.field}`}>
          {offset}
          {expandIcon}
          {content}
        </div>;


  return (
    <>
      {item}
    </>
  );
}

export default Cell;
