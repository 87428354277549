import { isUndefined } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDropdownToggle, useDropdownMenu, Dropdown } from "react-overlays";
import { UseDropdownMenuOptions } from "react-overlays/DropdownMenu";
import { IncMenuProps, IncMenuItemProps, IncMenuToggleProps } from "./types";

interface ToggleProps {
  toggleElFn: IncMenuProps['toggle'];
}

interface MenuItemProps {
  className: string;
  offset: [number, number];
  itemsElFn: IncMenuProps['items'];
}

const IncToggle: FC<ToggleProps> = ({ toggleElFn }) => {
  const [props, { show, toggle: toggleMenu }] = useDropdownToggle();
  const usrToggleProps: IncMenuToggleProps = {
    show,
    toggleMenu
  };

  return <div {...props} className="inc-menu--toggle">
    {toggleElFn(usrToggleProps)}
  </div>;
};

const IncMenuItems: FC<MenuItemProps> = ({ offset, itemsElFn, className }) => {
  const menuOptions: UseDropdownMenuOptions = {
    offset,
    flip: true,
    popperConfig: {
      strategy: "fixed"
    }
  };

  className = `inc-menu--menu ${className}`;

  const {
    props,
    close
  } = useDropdownMenu(menuOptions);

  const closeMenu = useCallback((e: Event) => {
    const target = (e.currentTarget as any);
    if (target.blur) {
      target.blur();
    }
    close(e);
  }, [close]);

  const usrMenuItemProps: IncMenuItemProps = {
    closeMenu,
    className: 'inc-menu--menu-item'
  };

  return <div {...props} className={className} role="menu">
    {itemsElFn(usrMenuItemProps)}
  </div>;
};

const IncMenu: FC<IncMenuProps> = (props) => {
  const {
    offset: uOffset = {
      x: 5,
      y: 5
    },
    dropType = 'down',
    toggle,
    defaultShow = false,
    defaultFocus = false,
    items,
    show: usrShow = false,
    onToggle: usrToggle = () => { },
    className: uClassName = '',
    menuClassName: uMenuClassName = '',
    ...containerProps
  } = props;

  const offset: [number, number] = [isUndefined(uOffset.x) ? 5 : uOffset.x, isUndefined(uOffset.y) ? 5 : uOffset.y];
  const className = `inc-menu ${uClassName}`;

  const [show, setShow] = useState(usrShow);

  useEffect(() => setShow(usrShow), [usrShow]);

  const onToggle = useCallback((nextShow: boolean, e?: React.SyntheticEvent) => {
    setShow(nextShow);
    usrToggle(show, e);
  }, [show, usrToggle]);

  return <Dropdown
    defaultShow={defaultShow}
    drop={dropType}
    focusFirstItemOnShow={defaultFocus}
    itemSelector=".inc-menu--menu-item"
    onToggle={onToggle}
    show={show}
  >
    {({ props: ddProps }) => <div {...containerProps} {...ddProps} className={className}>
      <IncToggle toggleElFn={toggle} />
      <IncMenuItems className={uMenuClassName} itemsElFn={items} offset={offset}/>
    </div>
    }
  </Dropdown>;
};

export default IncMenu;
