import React, { CSSProperties, useCallback, useMemo, MouseEvent, useEffect, useState } from "react";
import { debounce, isEmpty, merge } from "lodash";
import { Cell, Row, UseTableInstanceProps } from "react-table";
import { cx } from "emotion";
import { TableDataItem } from "../types";

interface Props<T extends TableDataItem> {
  rows: UseTableInstanceProps<T>['rows'];
  prepareRow: UseTableInstanceProps<T>['prepareRow'];
  subRowRenderer?: (row: T, depth: number) => JSX.Element | HTMLElement | string | null;
  onClick?: (rowData: T, event: MouseEvent<HTMLElement>) => void;
  onDblClick?: (rowData: T, event: MouseEvent<HTMLElement>) => void;
  getStyle?: (rowData: T) => void | CSSProperties | undefined;
  selectionColumnId: string;
  expansionColumnId: string;
  nonDataColumnIds: string[];
  isResizing: boolean;
  resizeColumnWidths: Record<string,any>;
  isLoading: boolean;
  hasError: boolean;
  loadingMessage: string | JSX.Element | HTMLElement | undefined;
  noDataMessage: string | JSX.Element | HTMLElement | undefined;
  errorDataMessage: string | JSX.Element | HTMLElement | undefined;
  className: string;
  cellClassName: string;
  selectRowOnClick?: boolean;
  selectedRowIds?: Record<string,boolean>;
}

type RowComponent = Array<string | HTMLElement | JSX.Element>;

const Rows = <T extends TableDataItem>(props: Props<T>) => {
  const {
    rows,
    prepareRow,
    onClick: pOnClick,
    onDblClick: pOnDblClick,
    getStyle: pGetStyle,
    selectionColumnId,
    expansionColumnId,
    nonDataColumnIds,
    hasError,
    errorDataMessage,
    isLoading,
    loadingMessage,
    cellClassName,
    noDataMessage,
    className,
    selectRowOnClick = false,
    subRowRenderer,
    isResizing,
    resizeColumnWidths,
    selectedRowIds
  } = props;

  const [renderRows, setRenderRows] = useState<RowComponent>([]);

  const shouldTriggerClickEvent = useCallback((columnId: string) =>
    [...nonDataColumnIds, selectionColumnId, expansionColumnId].indexOf(columnId) === -1 && !isLoading
  , [expansionColumnId, isLoading, nonDataColumnIds, selectionColumnId]);

  const onClick = useCallback((row: Row<T>, cell: Cell<T>, event: MouseEvent<HTMLElement>) => {
    const columnId = cell.column.id;
    const rowData = row.original;
    const shouldTriggerOnclick = shouldTriggerClickEvent(columnId);

    if (pOnClick && shouldTriggerOnclick) {
      selectRowOnClick ? row.toggleRowSelected() : pOnClick(rowData, event);
    }
  }, [pOnClick, shouldTriggerClickEvent, selectRowOnClick]);

  const onDblClick = useCallback((row: Row<T>, cell: Cell<T>, event: MouseEvent<HTMLElement>) => {
    const columnId = cell.column.id;
    const shouldTriggerOnclick = shouldTriggerClickEvent(columnId);

    if (pOnDblClick && shouldTriggerOnclick) {
      pOnDblClick(row.original, event);
    }
  }, [pOnDblClick, shouldTriggerClickEvent]);

  const clickEnabled = useMemo(() => ((pOnClick !== null && pOnClick !== undefined)
    || (pOnDblClick !== null && pOnDblClick !== undefined)) && !isLoading, [isLoading, pOnClick, pOnDblClick]);

  const debouncedRowsUpdate = useMemo(() => debounce((rows: RowComponent) => setRenderRows(rows), 500), []);

  useEffect(() => {
    const renderFn = () => rows.map(row => {
      prepareRow(row);
      const {
        depth,
        isSelected,
        getRowProps,
        cells,
        original,
        subRows
      } = row;
      const isExpandable = subRows?.length > 0 || false;
      const rowClassName = `${className} ${depth ? 'sub-row' : ''} ${isSelected ? "selected" : ""} ${isExpandable ? "expandable":""}`;
      const rowProps = getRowProps({ className: rowClassName });
      rowProps.style = merge(rowProps.style, pGetStyle ? pGetStyle(row.original) : {});

      let resultRowComponent;

      if (subRowRenderer && depth) {
        resultRowComponent = subRowRenderer(original, depth);
      }

      if (!resultRowComponent) {
        // Disabling this since the getRowProps handles it
        /* eslint-disable-next-line react/jsx-key */
        resultRowComponent = <div
          {...rowProps}
          data-click-defined={`${clickEnabled}`}
        >
          {cells.map((cell, idx) => {
            const key = cell.column.id + '_' + idx;
            return <TCell
              cell={cell as any}
              className={cellClassName}
              idx={idx}
              isLoading={isLoading}
              key={key}
              onClick={onClick as any}
              onDblClick={onDblClick as any}
              row={row as any}
            />;
          })
          }
        </div>;
      }
      return resultRowComponent;
    });

    const nRows = renderFn();

    if (isResizing && !isEmpty(resizeColumnWidths)) {
      debouncedRowsUpdate(nRows);
    } else {
      setRenderRows(nRows);
    }
  }, [isResizing, rows, prepareRow, className, clickEnabled, cellClassName, isLoading, onClick, onDblClick,
    setRenderRows, debouncedRowsUpdate, resizeColumnWidths, subRowRenderer, selectedRowIds, pGetStyle]);

  return <>
    {hasError && <div className="inc-table-error-message">
      {errorDataMessage || "Data error"}
    </div>}
    {isLoading && loadingMessage && <div className="inc-table-loading-message">
      {loadingMessage}
    </div>}
    {(!isLoading || (isLoading && !loadingMessage)) && !hasError && <>
      {
        rows.length > 0 && renderRows
      }
      {
        rows.length === 0 && !isLoading && <div className="inc-table-loading-message">
          {noDataMessage || "No data found"}
        </div>
      }
    </>
    }
  </>;
};

export default Rows;

type CProps<T extends TableDataItem> = {
  idx: number;
  row: Row<T>;
  cell: Cell<T, any>;
  onClick: (row: Row<T>, cell: Cell<T, any>, event: MouseEvent<HTMLElement>) => void;
  onDblClick: (row: Row<T>, cell: Cell<T, any>, event: MouseEvent<HTMLElement>) => void;
  isLoading: boolean;
  className: string;
};

const TCell = React.memo(<T extends TableDataItem>(props: CProps<T>) => {
  const {
    idx,
    cell,
    onClick,
    onDblClick,
    isLoading,
    className: cellClassName,
    row
  } = props;

  const { depth, original } = row;
  const isReadOnly = original?.readOnly || false;

  const {
    column,
    getCellProps,
    render
  } = cell;

  const {
    width,
    minWidth,
    maxWidth,
    disableTableEvents,
    className: colClassName
  } = (column as any) || {};

  const enableClickEvent = !disableTableEvents || !isReadOnly;

  const cellStyle: CSSProperties = {
    width,
    minWidth,
    maxWidth,
    pointerEvents: isReadOnly ? 'none' : 'auto',
    opacity: isReadOnly ? 0.8 : 1
  };

  const fCellClassName = cx(cellClassName, {
    [colClassName]: Boolean(colClassName)
  });

  const cellProps = getCellProps({
    className: fCellClassName,
    style: cellStyle
  });

  const content = <div className="data-text" style={{ paddingLeft: idx === 0 ? 10 * depth : 0 }}>
    {!isLoading && render('Cell')}
    {isLoading && render('Footer')}
  </div>;

  // Disabling this since the getRCellProps handles it
  /* eslint-disable-next-line react/jsx-key */
  return enableClickEvent ? <div
    {...cellProps}
    onAuxClick={(evt) => onClick(row, cell, evt)}
    onClick={(evt) => onClick(row, cell, evt)}
    onDoubleClick={(evt) => onDblClick(row, cell, evt)}
  >
    {content}
  </div> : <div  {...cellProps}>
    {content}
  </div>;
});

