import { Entity } from "../../../core";
import { TimeRange } from "../../../core/hooks/time-range/types";
import datasoureApiManager from "../DatasourceApiService";
import { EntityTypeWrapper } from "../types";
import { TriageRequestPayload, EntityTriageResponse, BizSegment, BOSegmentsForEntityResponse } from './types';

class TriageApiService {
  readonly triageUrl = "/api/ade/triage";

  async getTriageData(triageRequestPayload: TriageRequestPayload): Promise<EntityTriageResponse> {
    const response = await datasoureApiManager.getDefault().post<EntityTriageResponse, any, any>(this.triageUrl, triageRequestPayload);
    return response.data;
  }

  async getBOSegmentsForBizEntityType(entityType: EntityTypeWrapper, userServices: Entity[], timeRange: TimeRange) {
    const url = "/api/ade/triage/interestedSegments";
    const payload = {
      timeRange: {
        startSecs: timeRange.from.unix(),
        endSecs: timeRange.to.unix()
      },
      userServices: userServices.map(us => ({
        id: us.id,
        type: us.type
      })),
      bizEntityType: entityType?.entityType?.typeReference.id
    };
    const response = await datasoureApiManager.getDefault().post<BOSegmentsForEntityResponse, any>(url, payload);
    const data = response.data;
    const apiIdToBoIdToSegMap: Map<string, Map<string, BizSegment[]>> = new Map();
    Object.keys(data).forEach(apiId => {
      const bizObj = data[apiId];
      const boIdToSegmentMap: Map<string, BizSegment[]> = new Map();
      Object.keys(bizObj).forEach(boId => {
        boIdToSegmentMap.set(boId, bizObj[boId]);
      });
      apiIdToBoIdToSegMap.set(apiId, boIdToSegmentMap);
    });
    return apiIdToBoIdToSegMap;
  }
}

const triageApiService = new TriageApiService();

export default triageApiService;
