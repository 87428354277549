import darkTheme from './theme.dark';
import lightTheme from './theme.light';
import { InceptionTheme, ThemeType } from "./types/theme";

// Do not export this.
let themeTypeInternal: ThemeType;

const setInceptionTheme = (themeType: ThemeType): void => {
  console.log("Theme Provider", `Setting theme to ${themeType}`);
  themeTypeInternal = themeType;
};

const getInceptionTheme = (): InceptionTheme => {
  if (!themeTypeInternal) {
    //logger.error("Theme Provider", "Theme not set. Defaulting to light mode");
    return darkTheme;
  }

  const theme = themeTypeInternal === 'light' ? lightTheme : darkTheme;
  return theme;
};


export {
  getInceptionTheme,
  setInceptionTheme
};
