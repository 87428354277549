import { isEmpty } from "lodash";
import { getDuration } from "../../../DateTimeFormatter/DateTime";
import { checkIsNaN } from "../numeric";
import { NumericDataInfo } from "../numeric/types";
import { getDurationDataConfiguration } from "./constants";

export const getDurationInfo = (durationValue: string, disableAbbreviations: boolean): NumericDataInfo => {
  const durationDataInfo: NumericDataInfo = {
    isValid: false,
    abbreviatedValue: '',
    absoluteValue: ''
  };

  if (isEmpty(durationDataInfo)) {
    return durationDataInfo;
  }

  if (durationValue === "No Min" || durationValue === "No Max") {
    durationDataInfo.isValid = true;
    durationDataInfo.abbreviatedValue = durationValue;
    return durationDataInfo;
  }

  const durationDataConfig = getDurationDataConfiguration();
  //check if duration value is valid
  if (durationDataConfig.regex.test(durationValue)) {
    durationDataInfo.isValid = true;
    durationDataInfo.abbreviatedValue = durationValue;

    //compute absolute value
    durationDataInfo.absoluteValue = !checkIsNaN(durationValue) ? Number(durationValue).toString() :
      durationDataConfig.convertToNumber(durationValue).toString();

    //compute abbreviated value
    if (!disableAbbreviations && !checkIsNaN(durationValue)) {
      durationDataInfo.abbreviatedValue = getDuration(durationValue);
    }
  }
  return durationDataInfo;
};
