import { useCallback } from "react";
import { useFetchDataStatus } from "../../../../core";
import { EntityTypeWrapper } from "../../types";
import fieldPickerApiService from "../FieldPickerApiService";
import { BizEntityType } from "../types/fieldpickerTypes";

export const convertBizEntityToEntityType = (list: BizEntityType[]) => (list || []).map(item => ({
  entityType: {
    typeReference: {
      id: item.entityTypeId,
      typeName: item.name
    }
  }
} as EntityTypeWrapper));


const useFetchExploreBizEntityTypes = () => {
  const {
    data: bizEntityTypes,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<BizEntityType[], string>({ data: [] });

  const fetchBizEntityList = useCallback(async () => {
    setStatus([], true, false, false, '');
    let bizEntityTypes: BizEntityType[] = [];
    const {
      data,
      error,
      message
    } = await fieldPickerApiService.getBizEntityTypes();

    if (!error) {
      bizEntityTypes = data;
    }

    const errMsg = error ? message : '';

    setStatus(bizEntityTypes, false, !error, error, errMsg);
  }, [setStatus]);

  return {
    bizEntityTypes,
    isError,
    isFetching,
    isSuccess,
    error,
    fetchBizEntityList
  };
};

export default useFetchExploreBizEntityTypes;
