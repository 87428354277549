import { BizService } from "../explore/BizServiceCommon";
import { GetDimensionsRequesPayload, SaveOnboardingResponse, UseCaseDiscoveryResponse } from "../types";
import { v4 as uuidV4 } from "uuid";
import {
  AddKnowledgeBaseRequest, AddMemberRequest, AddWidgetRequest, AddWidgetResponse, EventTypeSettingsList, InsightDescription, KnowledgeBaseSettingsList,
  MemberSettings, SuggestDataQueryDefRequest, SuggestedDataQueryResponse, UpdateEventTypeSettingsRequest, UseCaseConfig, UseCaseConfigList,
  UseCaseDataQueryConfig, UseCaseSaveRequest, UseCaseSaveResponse, UseCaseSchema, UsecaseUpsertResponse, WidgetDescription,
  AddOp10zeRequest, AddOp10zeResponse, UseCaseUnderlyingInfo, UseCaseOp10zeSearchRequest, UseCaseOp10zeSearchResponse, UpdateUseCaseMetadataRequest,
  SuggestUsecasesResponseV2, UseCaseSuggestionsRequest, SuggestKPIResponse, SuggestKPIRequest, GetUseCaseFeedsResponse, GetUseCaseFeedsRequest, RecommendedUseCase,
  UseCaseSuggestionSummaryRequest, UseCaseSuggestionSummaryResponse, KPIDataQueryDefRequest, KPIInsightsRequest, KPIInsightsResponse
} from "./types";
import { CancelToken } from "axios";


class UseCaseApiService extends BizService {
  getUseCaseSchema(id: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${id}`);

    const request = () => this.datasource.get<UseCaseSchema, null>(url);
    return this.getResult(request);
  }

  generateDataQueryDef(
    verticalId: string,
    subVerticalId: string,
    widgetDescription: WidgetDescription,
    insightDescription: InsightDescription,
    useCaseName: string,
    companyName?: string
  ) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/v2/generateDataQueryDef`);
    const payload: SuggestDataQueryDefRequest = {
      subVerticalId,
      verticalId,
      companyName,
      useCaseName
    };

    if (companyName) {
      payload.companyName = companyName;
    }

    if (widgetDescription) {
      payload.widgetDescription = widgetDescription;
    } else if (insightDescription) {
      payload.insightDescription = insightDescription;
    }

    const request = () => this.datasource.post<SuggestedDataQueryResponse, SuggestDataQueryDefRequest>(url, payload);
    return this.getResult(request);
  }

  saveUseCase(verticalId: string, subVerticalId: string, useCaseConfig: UseCaseConfig, companyName?: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/v2/save`);
    const payload: UseCaseSaveRequest = {
      subVerticalId,
      verticalId,
      useCaseConfig
    };

    if (companyName) {
      payload.companyName = companyName;
    }

    const request = () => this.datasource.post<UseCaseSaveResponse, UseCaseSaveRequest>(url, payload);
    return this.getResult(request);
  }

  updateUseCase(verticalId: string, subVerticalId: string, useCaseId: string, useCaseConfig: UseCaseConfig, companyName?: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/v2/${useCaseId}`);
    const payload: UseCaseSaveRequest = {
      subVerticalId,
      verticalId,
      useCaseConfig
    };

    if (companyName) {
      payload.companyName = companyName;
    }

    const request = () => this.datasource.post<UseCaseSaveResponse, UseCaseSaveRequest>(url, payload);
    return this.getResult(request);
  }

  deleteUseCase(useCaseId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}`);
    const request = () => this.datasource.delete<UsecaseUpsertResponse>(url);
    return this.getResult(request);
  }

  renameUseCase(useCaseId: string, useCaseName?: string, useCaseDescription?: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/metadata`);
    const payload: UpdateUseCaseMetadataRequest = {};

    if (useCaseName) {
      payload.name = useCaseName;
    }

    if (useCaseDescription) {
      payload.description = useCaseDescription;
    }

    const request = () => this.datasource.post<UsecaseUpsertResponse, UpdateUseCaseMetadataRequest>(url, payload);
    return this.getResult(request);
  }

  getUseCasesForMember(companyName: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/member?companyName=${companyName}`);

    const request = () => this.datasource.get<UseCaseConfigList, never>(url);
    return this.getResult(request);
  }

  addMemberToUseCase(useCaseId: string, memberId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/addMember`);
    const payload: AddMemberRequest = {
      userId: memberId
    };

    const request = () => this.datasource.post<MemberSettings, AddMemberRequest>(url, payload);
    return this.getResult(request);
  }

  removeMemberFromUseCase(useCaseId: string, memberId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/removeMember/${memberId}`);

    const request = () => this.datasource.delete<UsecaseUpsertResponse>(url);
    return this.getResult(request);
  }

  addWidgetToUseCase(useCaseId: string, dataQueryConfig: UseCaseDataQueryConfig) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/addWidget`);

    const request = () => this.datasource.post<AddWidgetResponse, AddWidgetRequest>(url, { query: dataQueryConfig });
    return this.getResult(request);
  }

  removeWidgetFromUseCase(useCaseId: string, dataQueryConfigId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/removeWidget/${dataQueryConfigId}`);

    const request = () => this.datasource.delete<UsecaseUpsertResponse>(url);
    return this.getResult(request);
  }

  addOp10zeToUseCase(useCaseId: string, opIds: string[]) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/addOp10ze`);

    const request = () => this.datasource.post<AddOp10zeResponse, AddOp10zeRequest>(url, { opIds });
    return this.getResult(request);
  }

  removeOp10zeFromUseCase(useCaseId: string, dataQueryConfigId: string, opId: string[]) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/removeOp10ze/${dataQueryConfigId}/${opId}`);

    const request = () => this.datasource.put<UsecaseUpsertResponse, never>(url);
    return this.getResult(request);
  }

  deleteOp10zeFromUseCase(useCaseId: string, dataQueryConfigId: string, opId: string[]) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/deleteOp10ze/${dataQueryConfigId}/${opId}`);

    const request = () => this.datasource.delete<UsecaseUpsertResponse>(url);
    return this.getResult(request);
  }

  getOp10zeForUseCases(useCaseIds: string[], companyName: string) {
    this.init();

    const url = this.getBizEntityUrl('usecase/v2/op10ze/search');
    const payload: UseCaseOp10zeSearchRequest = {
      useCaseIds,
      companyName
    }

    const request = () => this.datasource.post<UseCaseOp10zeSearchResponse, UseCaseOp10zeSearchRequest>(url, payload);
    return this.getResult(request);
  }

  getEventSettings(useCaseId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/eventSettings`);

    const request = () => this.datasource.get<EventTypeSettingsList, never>(url);
    return this.getResult(request);
  }

  updateEventSettings(useCaseId: string, eventTypeSettingsList: EventTypeSettingsList) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/eventSettings`);

    const request = () => this.datasource.post<UsecaseUpsertResponse, UpdateEventTypeSettingsRequest>(url, {
      eventTypeSettingsList
    });
    return this.getResult(request);
  }

  getKnowledgeBaseSettings(useCaseId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/knowledgeBaseSettings`);

    const request = () => this.datasource.get<KnowledgeBaseSettingsList, never>(url);
    return this.getResult(request);
  }

  addKnowledgeBaseSettings(useCaseId: string, requestPayload: AddKnowledgeBaseRequest) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/addKnowledgeBaseSettings`);

    const request = () => this.datasource.post<KnowledgeBaseSettingsList, AddKnowledgeBaseRequest>(url, requestPayload);
    return this.getResult(request);
  }

  deleteKnowledgeBaseSettings(useCaseId: string, knowledgeBaseId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/knowledgeBaseSettings/${knowledgeBaseId}`);

    const request = () => this.datasource.delete<UsecaseUpsertResponse>(url);
    return this.getResult(request);
  }

  discoverUseCase(useCaseId: string, companyName: string) {
    this.init()

    const subUrl = useCaseId ? `usecase/v2/discover/${useCaseId}`
      : `usecase/v2/discover/allUseCases?companyName=${companyName}`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.get<UseCaseDiscoveryResponse, unknown>(url);
    return this.getResult(request);
  }

  getUseCaseSampleValues(useCaseId: string, payload: UseCaseDiscoveryResponse) {
    this.init()

    const subUrl = useCaseId ? `usecase/v2/discover/${useCaseId}/samplevalues` : `usecase/v2/discover/allUseCases/samplevalues`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<UseCaseDiscoveryResponse, UseCaseDiscoveryResponse>(url, payload);
    return this.getResult(request);
  }

  connectRawData(
    companyName: string,
    eventTypes: UseCaseDiscoveryResponse["eventTypes"],
    entityTypes: UseCaseDiscoveryResponse["entityTypes"]
  ) {
    this.init()

    const url = `/event-mapping/api/v1/data-onboarding/connect/rawdata`;
    const payload = {
      companyName,
      eventTypes,
      entityTypes,
      traceInfo: {
        traceId: uuidV4()
      },
    }

    const request = () => this.datasource.post<SaveOnboardingResponse, any>(url, payload);
    return this.getResult(request);
  }

  getUnderlyingInfoForUseCase(useCaseId: string) {
    this.init()

    const url = this.getBizEntityUrl(`usecase/${useCaseId}/underlyingInfo`)

    const request = () => this.datasource.get<UseCaseUnderlyingInfo>(url);
    return this.getResult(request);
  }

  getFeedForUseCases(useCaseIds: string[], cancelToken?: CancelToken) {
    this.init();

    const url = this.getBizEntityUrl(`usecase/user/feed`);

    const request = () => this.datasource.post<GetUseCaseFeedsResponse, GetUseCaseFeedsRequest>(url, { useCaseIds }, { cancelToken });
    return this.getResult(request);
  }

  getDimensions(useCaseId: string, payload: GetDimensionsRequesPayload) {
    this.init();
    const url = this.getBizEntityUrl(`usecase/v2/discover/${useCaseId}/addDimensions`);
    const request = () => this.datasource.get<UseCaseDiscoveryResponse, GetDimensionsRequesPayload>(url, payload);
    return this.getResult(request);
  }

  getSuggestedUseCases(companyName: string, verticalId: string, subVerticalId: string, chatId: string) {
    this.init();

    const subUrl = `usecase/v2/suggest`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<SuggestUsecasesResponseV2, UseCaseSuggestionsRequest>(url, {
      chatId,
      verticalIdentifier: {
        companyName,
        subVertical: subVerticalId,
        vertical: verticalId
      }
    });
    return this.getResult(request);
  }

  getUseCaseSummaryForSuggestion(suggestion: RecommendedUseCase) {
    this.init();

    const subUrl = `usecase/v2/suggest/summary`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<UseCaseSuggestionSummaryResponse, UseCaseSuggestionSummaryRequest>(url, {
      useCaseRecommendation: suggestion
    });
    return this.getResult(request);
  }

  getSuggestedKPIs(useCaseConfig: UseCaseConfig, companyName: string, verticalId: string, subVerticalId: string, chatId: string) {
    this.init();

    const subUrl = `usecase/v2/suggest/kpis`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<SuggestKPIResponse, SuggestKPIRequest>(url, {
      companyName,
      chatId,
      subVerticalId,
      verticalId,
      useCaseConfig
    });
    return this.getResult(request);
  }

  generateKPIDataQueryDef(
    kpiName: string,
    useCaseName: string,
    companyName: string,
    verticalId: string,
    subVerticalId: string,
    useCaseId?: string,
    useCaseConfig?: UseCaseConfig
  ) {
    this.init();

    const subUrl = `usecase/v2/generateKPIQueryDef`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<SuggestKPIResponse, KPIDataQueryDefRequest>(url, {
      kpiName,
      useCaseName,
      verticalIdentifier: {
        companyName,
        subVertical: subVerticalId,
        vertical: verticalId
      },
      useCaseId,
      useCaseConfig
    });
    return this.getResult(request);
  }

  generateInsightsForKPI(
    kpiName: string,
    kpiDescription: string,
    useCaseName: string,
    useCaseDescription: string,
    involvedEvents: string[],
    companyName: string,
    verticalId: string,
    subVerticalId: string,
    useCaseId?: string,
    useCaseConfig?: UseCaseConfig,
    insightsCount = 1
  ) {
    this.init();

    const subUrl = `usecase/v2/generateInsights`;
    const url = this.getBizEntityUrl(subUrl);

    const request = () => this.datasource.post<KPIInsightsResponse, KPIInsightsRequest>(url, {
      kpiName,
      kpiDescription,
      useCaseName,
      useCaseDescription,
      involvedEvents,
      insightsCount,
      verticalIdentifier: {
        companyName,
        subVertical: subVerticalId,
        vertical: verticalId
      },
      useCaseId,
      useCaseConfig
    });
    return this.getResult(request);
  }
}

export const useCaseApiService = new UseCaseApiService();
