import { generateSSTimeseriesData, generateSingleStatData, generateInsightsData } from "./generators";

const dataDefId = "AWHOr3YDGJ";
const metricId = "T70iDQdeu";

const lookupMin = 70;
const lookupMax = 90;
const percentageChangeValues = [15, 12, 8, 7, -8, 10, 4, -2, 18, 13, 6.5, 7.7, 3.2, 5.8, -1.5, -3, 5.5, 7.1, 8.4, 17];

const dataRange: [number, number] = [41000000, 43000000];
const timeShiftRange: [number, number] = [38000000, 41000000];

export const ss5Data = [generateSingleStatData(
  dataDefId,
  metricId,
  1649656800000,
  42461372,
  40115271,
  -1.5166296878894718
)];

export const ssTs5Data = [generateSSTimeseriesData(
  dataDefId,
  metricId,
  dataRange,
  timeShiftRange
)];


export const ssEl5Data = [generateInsightsData(
  dataDefId,
  metricId,
  lookupMin,
  lookupMax,
  percentageChangeValues,
  timeShiftRange
)];
