import {
  PredicateOp, EntityOperation, BizFieldPredicate, ResultCohortStateResponse, exploreApiService, BizField,
  MetricPredicate, UserServiceField, CohortConfig, EntityField
} from "../services/api/explore";
import { DashboardListItem } from "../dashboard";
import { generateId, MetricAggregationsRecord } from "../core";
import { RelativeDurationType } from "../dashboard/models/BaseWidgetModel";
import { getShiftSecs } from "../explore/utils";
import { asyncPoller, pluralizeWord } from "./Utils";
import { getTimeObjFromDuration } from "./DurationUtils";
import { eventFieldUtils } from "./EventFieldUtils";
import { getImplicitSlice } from "./ExploreUtils";

export const PredOpMap: Record<string, PredicateOp> = {
  "in": "in",
  "=": "eq",
  '!=': "ne",
  '>': "gt",
  '>=': "ge",
  '<': "lt",
  '<=': "le"
};

export const EntityNumericOpMap: Record<string, EntityOperation> = {
  "in": "in",
  "=": "eq",
  '!=': "neq",
  '<': "lt",
  '<=': "le",
  '>': "gt",
  '>=': "ge"
};

export const EntityNonNumericOpMap: Record<string, EntityOperation> = {
  "in": "in",
  "=": "eq",
  '!=': "neq",
  "=~": "regex",
  "startsWith": "startsWith"
};

export const EntityDateTimeOpMap: Record<string, EntityOperation> = {
  "=": "eq",
  '<': "lt",
  '>': "gt",
  "range": "range"
};


export const EntityOpMap: Record<string, EntityOperation> = {
  ...EntityNumericOpMap,
  ...EntityNonNumericOpMap,
  ...EntityDateTimeOpMap
};

export function getPredicateOp(op: string): PredicateOp {
  return PredOpMap[op];
}

export function getOpFromPredicateOp(pOp: PredicateOp) {
  return Object.keys(PredOpMap).find(k => PredOpMap[k] === pOp);
}

export function getEntityOperation(op: string): EntityOperation {
  return EntityOpMap[op];
}

export function getOpFromEntityOperation(pOp: EntityOperation) {
  return Object.keys(EntityOpMap).find(k => EntityOpMap[k] === pOp);
}

export function getDefaultPredicateOperator(isDateTimeOrDate: boolean) {
  if (isDateTimeOrDate) {
    return Object.keys(EntityOpMap).find(k => k === "range") || "";
  }
  return Object.keys(EntityOpMap).find(k => k === "=") || "";
}

export function getPredicateValueFromInitialPredicate(initialPredicate: BizFieldPredicate) {
  if (initialPredicate?.op === "in" || initialPredicate?.op === "range") {
    return initialPredicate.values || [];
  } else {
    return initialPredicate.value || "";
  }
}

/**
 *  @returns a function to cancel the polling.
 */
export function pollCohortState(cohortId: string, entityTypeId: string,
  onStateChange: (cohortStateResp: ResultCohortStateResponse) => void, onError?: (e: unknown) => void) {

  const callBack = (cohortStateResp: ResultCohortStateResponse, count: number, isError: boolean, error: unknown): boolean => {
    if (isError && onError) {
      onError(error);
    }

    if (cohortStateResp) {
      onStateChange(cohortStateResp);
    }

    const shouldPoll = (cohortStateResp?.status === "success" || cohortStateResp?.status === "failed") ? false : true;
    return shouldPoll;
  };

  return asyncPoller<ResultCohortStateResponse>(async () => await exploreApiService.getCohortState(cohortId, entityTypeId),
    callBack, true);
}

export const getNumericPredicateFromValues = (minValue: string, maxValue: string) => {
  let op: EntityOperation = "eq";
  let value: string | string[];

  const minExists = minValue !== null && minValue !== '' && minValue !== "NaN";
  const maxExists = maxValue !== null && maxValue !== '' && maxValue !== "NaN";

  if (!minExists && !maxExists) {
    op = "gt";
    value = "0";
  } else if (!minExists && maxExists) {
    op = "le";
    value = maxValue;
  } else if (minExists && !maxExists) {
    op = "ge";
    value = minValue;
  } else {
    const areValuesEqual = minValue === maxValue;
    op = areValuesEqual ? "eq" : "range";
    value = areValuesEqual ? minValue : [minValue, maxValue];
  }

  return {
    op,
    value
  };
};

export const ALL_ENTITIES_COHORT = "all";

export const getAllCohortDefinition = (entityTypeId: string): CohortConfig => ({
  cohortId: ALL_ENTITIES_COHORT,
  name: `All ${pluralizeWord(entityTypeId)}`,
  cohortCreationConfig: {
    bizFieldPredicates: [],
    metricPredicates: []
  }
});

export function isValidCohortId(cohortId: string) {
  return cohortId && cohortId !== ALL_ENTITIES_COHORT;
}

export function getCohortIdPredicate(cohortId: string, entityTypeId: string): BizFieldPredicate {
  if (isValidCohortId(cohortId) && entityTypeId) {
    const entityField = getCohortEntityField(entityTypeId);
    const bizField: BizField = {
      entityField
    };
    return {
      bizField,
      op: "eq",
      value: cohortId,
      values: []
    };
  }
  return null;
}

export function getCohortEntityField(entityTypeId: string): EntityField {
  if (entityTypeId) {
    return {
      entityType: entityTypeId,
      relNames: [
        {
          relName: "cohort",
          entityType: "i_cohort",
          relId: "i_cohort2member"
        }
      ],
      propType: "NA",
      propName: "id",
      kindDescriptor: {
        type: "not_set",
        customTypeName: ""
      }
    };
  }
  return null;
}

export function isCohortField(usfield: UserServiceField) {
  const entityFieldRelName = usfield?.entityField?.relNames?.[0];
  if (entityFieldRelName?.entityType === "i_cohort" && entityFieldRelName?.relName === "cohort") {
    return true;
  }
  return false;
}

export function getAllCohortName(entityTypeName: string) {
  return "All " + pluralizeWord(entityTypeName);
}

export function getCohortFiltersForEventQuery(queryKey: string, cohortId: string) {
  const entityFieldFilter = `${queryKey}.__id__ != null`;
  const filters = [entityFieldFilter];
  if (isValidCohortId(cohortId)) {
    const cohortFilter = `${queryKey}.rel:cohort.id = '${cohortId}'`;
    filters.push(cohortFilter);
  }
  return filters;
}

export const DB_COHORT_TAG = "cohort";
export const getCohortDashboardTags = (entityTypeId: string, cohortId: string) => [DB_COHORT_TAG, entityTypeId, cohortId];

export const isCohortDashboardItem = (dbListItem: DashboardListItem): boolean => (dbListItem.tags || []).includes(DB_COHORT_TAG);

export const getDefaultUsageEventMetricPredicate = (usField: UserServiceField): MetricPredicate => {
  const usFieldCopy: UserServiceField = {
    ...usField,
    fieldName: usField.bizEntityFieldName
  };

  return {
    aggregateDuration: getTimeObjFromDuration(1, RelativeDurationType.HOURS),
    aggregateDurationSeconds: getShiftSecs(1, RelativeDurationType.HOURS),
    aggregator: "sum",
    op: "ge",
    value: "100",
    metric: {
      id: generateId(),
      sourceType: "userServiceField",
      name: MetricAggregationsRecord["sum"].longDisplayName + ' ' + eventFieldUtils.removeFieldsPrefix(usField.fieldName),
      operationalizeConfig: {
        compareConfigs: null
      },
      userServiceFieldMetricConfig: {
        aggregator: "count",
        filterExpressions: [],
        sliceSets: [{
          slices: [getImplicitSlice(usFieldCopy)]
        }],
        userServiceField: usField
      }
    }
  };
};
