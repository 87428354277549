import { round } from "lodash";
import { duration } from "moment";

export const convertTimeToHumanString = (timeInMicros: number, fixed = 0) => {
  if (timeInMicros < 1000) {
    return `${parseFloat(`${timeInMicros}`).toFixed(fixed)}µs`;
  }

  const timeInMillis = timeInMicros / 1000;
  const roundedMillis = round(parseFloat(`${timeInMillis}`), fixed);

  const durationMillisObj = duration(roundedMillis, 'ms');
  const msDuration = durationMillisObj.milliseconds();
  const secsDuration = durationMillisObj.seconds();
  const minsDuration = durationMillisObj.minutes();
  const hoursDuration = durationMillisObj.hours();
  const daysDuration = durationMillisObj.days();
  const monthsDuration = durationMillisObj.months();
  const yearsDuration = durationMillisObj.years();

  let humanString = "";
  humanString += yearsDuration ? `${yearsDuration}y ` : "";
  humanString += monthsDuration ? `${monthsDuration}M ` : "";
  humanString += daysDuration ? `${daysDuration}d ` : "";
  humanString += hoursDuration ? `${hoursDuration}h ` : "";
  humanString += minsDuration ? `${minsDuration}m ` : "";
  humanString += secsDuration ? `${secsDuration}s ` : "";
  humanString += msDuration ? `${msDuration}ms ` : "";

  return humanString;
};
