import { PrimType } from "../services/api/operationalise";
import { EntityPropertyValue } from "../core";

export const getPropertyKeyForDataType = (dataType: PrimType): keyof EntityPropertyValue => {
  let key: keyof EntityPropertyValue;

  switch (dataType) {
    case "_bool": {
      key = "booleanVal";
      break;
    }

    case "_date": {
      key = "dateVal";
      break;
    }

    case "_datetime": {
      key = "dateTimeVal";
      break;
    }

    case "_double": {
      key = "doubleVal";
      break;
    }

    case "_long": {
      key = "longVal";
      break;
    }

    case "_map": {
      key = "mapValue";
      break;
    }

    case "_set": {
      key = "setValue";
      break;
    }

    case "_str": {
      key = "stringVal";
      break;
    }

    case "_objectmap": {
      key = "objectMapVal";
      break;
    }

    default: break;
  }

  return key;
};
