type ApptuitUser = {
  authLabels: any[];
  avatarUrl: string;
  createdAt: string;
  email: string;
  id: number;
  isDisabled: boolean;
  isExternal: boolean;
  isGrafanaAdmin: boolean;
  login: string;
  name: string;
  orgId: number;
  theme: string;
  updatedAt: string;
};

export type User = ApptuitUser & {
  role: Role;
  apptuitRole?: Role; // Temporary location to store actual user role

  username?: string;
  jwtToken?: string;
  isBicycleUser?: boolean;
  isWalkthroughUser?: boolean;
  isMockUser?:  boolean;

  //user permissions will be a record
  permissions?: Record<string, any>;
};


export type Error = {
  message: string;
  statusCode: number;
  error?: string;
};

export interface Org {
  orgId: number;
  name: string;
  role: Role;
  allowSwitch?: boolean;
  allowSupport?: boolean;
  allowedSupportRoles?: Role[];
}

export enum Role {
  Admin = "Admin",
  Editor = "Editor",
  Viewer = "Viewer",
  Guest = "Guest",
}
