import { Path, LocationDescriptorObject } from 'history';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import timeRangeUtils from '../../utils/TimeRangeUtils';
import { routeManager } from '../InceptionRouteManager';
import { useTimeRange } from './time-range/useTimeRangeHook';
import { TimeRange } from './time-range/types';
import { getTimeRange, getCompareTimeRange } from './time-range/TimeRangeGetter';

export interface URLOptions {
  queryParams?: Record<string, string | string[]>;
  reload?: boolean;
  newTab?: boolean;
  silentReload?: boolean; //temp way to fix Redirect issue + component remounting.
  replace?: boolean;
}

export function useInceptionRoute() {
  const history = useHistory();
  const { timeRange, compareTimeRange } = useTimeRange();

  const getRoute = useCallback((path: string, queryParams: Record<string, string | string[]> = {}, useAbsoluteTimeRange = false): Path =>
    getInceptionRoute(path, queryParams, timeRange, compareTimeRange, useAbsoluteTimeRange)
  , [compareTimeRange, timeRange]);

  const navigate = useCallback((path: string, options?: URLOptions): void => {
    const {
      queryParams = {},
      newTab = false,
      reload = false,
      silentReload = false,
      replace = false
    } = options || {};

    const [pathWithoutSearch, searchStr] = path.split('?');
    const searchParams = new URLSearchParams(searchStr);
    searchParams.forEach((value, key) => queryParams[key] = value);

    if (path) {
      if (path.endsWith("/logout")) {
        // Go to apptuit logout, comeback to inception login :)
        window.location.href = path;
      } else {
        if (silentReload) {
          queryParams['silentReload'] = "true";
        }
        const url = getRoute(pathWithoutSearch, queryParams);
        if (newTab) {
          window.open(url, "_blank");
        } else if (reload) {
          window.location.href = url;
        } else if(replace){
          history.replace(url);
        } else {
          history.push(url);
        }
      }
    }
  }, [getRoute, history]);

  const updateQueryParams = useCallback((queryParams: Record<string, string | string[]>) => {
    const updatedQueryParams: Record<string, string | string[]> = {};

    const searchStr = window.location.search.replace('?', '');
    const searchParams = new URLSearchParams(searchStr);
    searchParams.forEach((value, key) => {
      let values = updatedQueryParams[key];
      if (values) {
        if (!Array.isArray(values)) {
          values = [values];
        }
        values.push(value);
        updatedQueryParams[key] = values;
      } else {
        updatedQueryParams[key] = value;
      }
    });

    Object.assign(updatedQueryParams, queryParams);

    Object.keys(updatedQueryParams).forEach(key => {
      if (!updatedQueryParams[key]) {
        delete updatedQueryParams[key];
      }
    });

    const url = getRoute(window.location.pathname, updatedQueryParams);
    history.replace(url);
  }, [getRoute, history]);

  return {
    navigate,
    getRoute,
    updateQueryParams
  };
}

export const getInceptionRoute = (
  path: string,
  queryParams: Record<string, string | string[]> = {},
  timeRange?: TimeRange,
  compareTimeRange?: TimeRange,
  useAbsoluteTimeRange = false
): Path => {

  timeRange = timeRange || getTimeRange();
  compareTimeRange = compareTimeRange || getCompareTimeRange();

  let route = path;

  const pathObj: LocationDescriptorObject = {
    pathname: path,
    search: !isEmpty(queryParams) ? `?${stringify(queryParams)}` : ''
  };

  const routeRequiresTimeRange = routeManager.isTimeRangeRequired(path);
  const routeRequiresCompareTimeRange = routeManager.isCompareTimeRangeRequired(path);

  let from = queryParams.from,
    to = queryParams.to,
    cFrom = queryParams.compareFrom;

  if (timeRange && timeRange.raw && routeRequiresTimeRange && !from && !to) {
    const {
      raw: {
        from: fromStr,
        to: toStr
      },
      from: fromDT,
      to: toDT
    } = timeRange;

    from = useAbsoluteTimeRange ? fromDT.valueOf().toString() : fromStr;
    to = useAbsoluteTimeRange ? toDT.valueOf().toString() : toStr;
  }

  if (compareTimeRange && compareTimeRange.raw && routeRequiresCompareTimeRange && !cFrom) {
    const {
      raw: {
        from: fromStr,
      },
      from: fromDT
    } = compareTimeRange;

    cFrom = useAbsoluteTimeRange ? fromDT.valueOf().toString() : fromStr;
  }

  const search = pathObj.search as string;
  const newSearch = timeRangeUtils.getUrl(from as string, to as string, search, cFrom as string);
  pathObj.search = newSearch;
  route = getURLFromLocationDesc(pathObj);

  return route;
};

const getURLFromLocationDesc = (locDesc: LocationDescriptorObject): string => {
  const {
    hash = '',
    pathname,
    search = ''
  } = locDesc;

  let url = pathname;

  if (search && search !== '?') {
    url += search[0] === '?' ? search : `?${search}`;
  }

  if (hash && hash !== '#') {
    url += hash[0] === '#' ? hash : `#${hash}`;
  }

  return url;
};
