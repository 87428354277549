import { PREFIX_FIELDS, PREFIX_SUMMARY } from "../services/api/traces/constants";

class EventFieldUtils{
  removeFieldsPrefix = (fieldName: string) => this.isEventField(fieldName) ? (fieldName || '').replace(PREFIX_FIELDS, '')
    : fieldName;

  addFieldsPrefix = (tagName: string) => `${PREFIX_FIELDS}${tagName}`;

  removeSummaryPrefix = (fieldName: string) => this.isSummaryField(fieldName) ? (fieldName || '').replace(PREFIX_SUMMARY, '')
    : fieldName;

  isSummaryField = (fieldName: string) => (fieldName || '').startsWith(PREFIX_SUMMARY);

  isEventField = (fieldName: string) => (fieldName || '').startsWith(PREFIX_FIELDS);

  trimPrefixFields(key: string) {
    if (this.isEventField(key)) {
      return this.removeFieldsPrefix(key);
    } else if (this.isSummaryField(key)) {
      return this.removeSummaryPrefix(key);
    }
    return key;
  }

  concatWithEntityType = (str: string, entityType: string) => `${str}$${entityType}`;
}

export const eventFieldUtils = new EventFieldUtils();
