
const countryData = [
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-000383ea1058:0\"}",
    "datapoints": [
      [
        4914,
        1649272096000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-000383ea1058:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "USA"
    },
  },
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-00038fe5d028:0\"}",
    "datapoints": [
      [
        33781,
        1649272096000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-00038fe5d028:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "Canada"
    }
  },
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-0003901250c8:0\"}",
    "datapoints": [
      [
        4384,
        1649272096000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-0003901250c8:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "United Kingdom"
    }
  }
];

const countryCmpData = [
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-000383ea1058:0\"}",
    "datapoints": [
      [
        9350,
        1648667296000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-000383ea1058:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "USA"
    }
  },
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-00038fe5d028:0\"}",
    "datapoints": [
      [
        47378,
        1648667296000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-00038fe5d028:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "Canada"
    }
  },
  {
    "target": "response_time_Country {Country=\"Country:00000000-0000-0000-0000-0003901250c8:0\"}",
    "datapoints": [
      [
        7152,
        1648667296000
      ]
    ],
    "tags": {
      "Country": "Country:00000000-0000-0000-0000-0003901250c8:0"
    },
    "metricName": "response_time_Country",
    "eTags": {
      "Country": "United Kingdom"
    }
  }
];

const browserData = [
  {
    "target": "response_time_Browser {Browser=\"__missing__\"}",
    "datapoints": [
      [
        35330,
        1649272937000
      ]
    ],
    "tags": {
      "Browser": "__missing__"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Chrome\"}",
    "datapoints": [
      [
        2710,
        1649272937000
      ]
    ],
    "tags": {
      "Browser": "Chrome"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Firefox\"}",
    "datapoints": [
      [
        697,
        1649272937000
      ]
    ],
    "tags": {
      "Browser": "Firefox"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Forefox\"}",
    "datapoints": [
      [
        129,
        1649272937000
      ]
    ],
    "tags": {
      "Browser": "Forefox"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Safari\"}",
    "datapoints": [
      [
        304,
        1649272937000
      ]
    ],
    "tags": {
      "Browser": "Safari"
    },
    "metricName": "response_time_Browser"
  }
];

const browserCmpData = [
  {
    "target": "response_time_Browser {Browser=\"__missing__\"}",
    "datapoints": [
      [
        43089,
        1648668137000
      ]
    ],
    "tags": {
      "Browser": "__missing__"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Chrome\"}",
    "datapoints": [
      [
        3167,
        1648668137000
      ]
    ],
    "tags": {
      "Browser": "Chrome"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Firefox\"}",
    "datapoints": [
      [
        837,
        1648668137000
      ]
    ],
    "tags": {
      "Browser": "Firefox"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Forefox\"}",
    "datapoints": [
      [
        169,
        1648668137000
      ]
    ],
    "tags": {
      "Browser": "Forefox"
    },
    "metricName": "response_time_Browser"
  },
  {
    "target": "response_time_Browser {Browser=\"Safari\"}",
    "datapoints": [
      [
        299,
        1648668137000
      ]
    ],
    "tags": {
      "Browser": "Safari"
    },
    "metricName": "response_time_Browser"
  }
];

const paymentData = [
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"__missing__\"}",
    "datapoints": [
      [
        1992,
        1649356737000
      ]
    ],
    "tags": {
      "Payment_Medium": "__missing__"
    },
    "metricName": "response_time_Payment_Medium"
  },
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"Shopify_POS\"}",
    "datapoints": [
      [
        4549,
        1649356737000
      ]
    ],
    "tags": {
      "Payment_Medium": "Shopify_POS"
    },
    "metricName": "response_time_Payment_Medium"
  },
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"Wallet\"}",
    "datapoints": [
      [
        31903,
        1649356737000
      ]
    ],
    "tags": {
      "Payment_Medium": "Wallet"
    },
    "metricName": "response_time_Payment_Medium"
  }
];

const paymentCmpData = [
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"__missing__\"}",
    "datapoints": [
      [
        4631,
        1648751937000
      ]
    ],
    "tags": {
      "Payment_Medium": "__missing__"
    },
    "metricName": "response_time_Payment_Medium"
  },
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"Shopify_POS\"}",
    "datapoints": [
      [
        5272,
        1648751937000
      ]
    ],
    "tags": {
      "Payment_Medium": "Shopify_POS"
    },
    "metricName": "response_time_Payment_Medium"
  },
  {
    "target": "response_time_Payment_Medium {Payment_Medium=\"Wallet\"}",
    "datapoints": [
      [
        36186,
        1648751937000
      ]
    ],
    "tags": {
      "Payment_Medium": "Wallet"
    },
    "metricName": "response_time_Payment_Medium"
  }
];

const cityData = [{
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-000354462078:0\"}",
  "datapoints": [
    [
      20351,
      1649356946000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-000354462078:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Vancouver"
  }
}, {
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-000382eb4048:0\"}",
  "datapoints": [
    [
      12882,
      1649356946000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-000382eb4048:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Toronto"
  }
}, {
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-0003ae9a90d8:0\"}",
  "datapoints": [
    [
      5081,
      1649356946000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-0003ae9a90d8:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Montreal"
  }
}];

const cityCmpData = [{
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-0003ae9a90d8:0\"}",
  "datapoints": [
    [
      6720,
      1648752146000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-0003ae9a90d8:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Montreal"
  }
}, {
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-000382eb4048:0\"}",
  "datapoints": [
    [
      15072,
      1648752146000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-000382eb4048:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Toronto"
  }
}, {
  "target": "response_time_City {City=\"City:00000000-0000-0000-0000-000354462078:0\"}",
  "datapoints": [
    [
      23863,
      1648752146000
    ]
  ],
  "tags": {
    "City": "City:00000000-0000-0000-0000-000354462078:0"
  },
  "metricName": "response_time_City",
  "eTags": {
    "City": "Vancouver"
  }
}];

const merchData = [{
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-0003509a4078:0\"}",
  "datapoints": [
    [
      2710,
      1649359817000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-0003509a4078:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "GreenCage Security"
  }
},
{
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-00033a948020:0\"}",
  "datapoints": [
    [
      2594,
      1648755017000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-00033a948020:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "Gun.io"
  }
}, {
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-00036bcbc0b8:0\"}",
  "datapoints": [
    [
      2691,
      1648755017000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "Grain Management"
  }
}];

const merchCmpData = [{
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-0003509a4078:0\"}",
  "datapoints": [
    [
      2768,
      1648755017000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-0003509a4078:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "GreenCage Security"
  }
},
{
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-00036bcbc0b8:0\"}",
  "datapoints": [
    [
      2879,
      1649359817000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "Grain Management"
  }
}, {
  "target": "response_time_Merchant {Merchant=\"Merchant:00000000-0000-0000-0000-00033a948020:0\"}",
  "datapoints": [
    [
      2494,
      1648755017000
    ]
  ],
  "tags": {
    "Merchant": "Merchant:00000000-0000-0000-0000-00033a948020:0"
  },
  "metricName": "response_time_Merchant",
  "eTags": {
    "Merchant": "Gun.io"
  }
}];

export function getUETreemapMockData(field: string, isCompare = false) {
  switch (field) {
    case "Country": return isCompare ? countryCmpData : countryData;
    case "Browser": return isCompare ? browserCmpData : browserData;
    case "Payment_Medium": return isCompare ? paymentCmpData : paymentData;
    case "City": return isCompare ? cityCmpData : cityData;
    case "Merchant": return isCompare ? merchCmpData : merchData;
  }
}
