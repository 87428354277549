import React from "react";
import { Slider } from "antd";
import { IncLabel } from "../..";
import { IncSingleSliderProps } from "./types";

export const IncSingleSlider: React.FC<IncSingleSliderProps> = React.forwardRef((props, ref) => {
  const {
    label: labelStr,
    disabled = false,
    className = '',
    labelProps = {},
    value = 0,
    ...restSliderProps
  } = props;

  const labelPlacement = labelProps.placement || 'start';

  const slider = <Slider
    ref={ref}
    value={value}
    {...restSliderProps}
  />;
  const label = labelStr ? <IncLabel {...labelProps} disabled={disabled} >{labelStr}</IncLabel> : null;


  return <div className={`slider-container ${className}`}>
    {labelPlacement === 'start' ? label : slider}
    {labelPlacement === 'end' ? label : slider}
  </div>;
});
