import queryString, { ParsedQuery } from "query-string";
import { appData } from "../../appConfig";
import { convertToBoolean } from "../utils/Utils";

export type UrlValueType = string | boolean | number;

export interface UrlParamsKeyValue {
  key: string;
  value: UrlValueType;
}

const EMPTY_STRING = '';

function getParamFromUrl(key: string): UrlValueType {
  const values: ParsedQuery<UrlValueType> = queryString.parse(window.location.search);
  return values[key] ? values[key] as UrlValueType : EMPTY_STRING;
}

function getBooleanParamValue(key: string): boolean {
  const value: UrlValueType = getParamFromUrl(key);
  const booleanValue = convertToBoolean(value as string);
  return booleanValue ? booleanValue : false;
}

function getNumberParamValue(key: string): number {
  const value: UrlValueType = getParamFromUrl(key);
  return value !== EMPTY_STRING ? parseFloat(value as string) : -1;
}

function getParamsFromUrl(keys: string[]): UrlParamsKeyValue[] {
  if (!keys) {
    return null;
  }

  const keyValuePairs: UrlParamsKeyValue[] = keys.map((key: string) => {
    const v: UrlValueType = getParamFromUrl(key);
    // check if its actually a number
    if (!isNaN(parseFloat(v as string))) {
      return {
        key: key,
        value: parseFloat(v as string)
      };
    } else if (convertToBoolean(v as string)) {
      return {
        key: key,
        value: convertToBoolean(v as string)
      };
    }

    return {
      key,
      value: v as string
    };
  });

  return keyValuePairs;
}

function getURLwithPreviewHost(url: string) {
  if (appData.build.releaseType === "preview") {
    const urlObj = new URL(url);
    urlObj.hostname = urlObj.hostname.startsWith(IONE_PREFIX) ? urlObj.hostname.replace(IONE_PREFIX, PREVIEW_IONE_PREFIX)
      : urlObj.hostname.startsWith(DEV_PREFIX) ? urlObj.hostname.replace(DEV_PREFIX, PREVIEW_DEV_PREFIX)
        : urlObj.hostname;

    url = urlObj.toString();
  }

  return url;
}

const IONE_PREFIX = "app.ione.bicycle";
const PREVIEW_IONE_PREFIX = "preview.ione.bicycle";
const DEV_PREFIX = "app.dev.bicycle.io";
const PREVIEW_DEV_PREFIX = "preview.dev.bicycle";

// Gets the name in this format: https://app.dev.bicycle.io/
const getHostname = (): string => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const retStr = `${protocol}//${host}`;
  return retStr;
};

export {
  getParamFromUrl,
  getBooleanParamValue,
  getNumberParamValue,
  getParamsFromUrl,
  getHostname,
  getURLwithPreviewHost
};
