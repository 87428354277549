import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, logger, useDataFetch } from "../../../../core";
import { ToolMetaDataList } from "../../explore";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";

export const useFetchToolSpec = (opId: string,disableAutoFetch=false) => {
  
  const initialState = useMemo<InitialState<ToolMetaDataList, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: !disableAutoFetch,
      isSuccess: false,
    }),
    [disableAutoFetch]
  );

  const fetchFn = useCallback<FetchFn<ToolMetaDataList, string>>(async () => {
    const result: FetchCallBackResult<ToolMetaDataList, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false,
    };

    try {
      const {
        data,
        error,
        message,
      } = await operationaliseV2ApiService.fetchToolSpec(opId);

      result.isError = error;
      result.isSuccess = !error;
      result.data = data;
      result.error = error ? message : null;
    } catch (err: any) {
      logger.error("useFetchToolSpec", "Error fetching tool spec", err);
      result.isError = true;
      result.error = err?.message?.toString() || err.toString();
    }

    return result;
  }, [opId]);

  return useDataFetch(fetchFn, initialState, disableAutoFetch);
}