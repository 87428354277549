import { css, cx } from "emotion";
import { isEmpty } from "lodash";
import React from "react";
import IncLabelProps from "./types";

const IncLabel: React.FC<IncLabelProps> = (props) => {
  const {
    className = '',
    disabled = false,
    width = ''
  } = props;


  const widthClass = isEmpty(width) ? '' : css`width: ${width};`;
  const labelClass = cx('inc-label-common inc-flex-row inc-flex-center-vertical', {
    [className]: Boolean(className),
    disableClick: disabled,
    [widthClass]: Boolean(widthClass)
  });

  return <div className={labelClass}>
    {props.children}
  </div>;
};

export default IncLabel;
