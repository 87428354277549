import { random } from "lodash";
import ssEntityLookupData, { spliceAndGetMockEntities } from "../../../../../../fakeData/mock_entities";
import { generateSchemaPayload, generateMetricKey } from "./common";

export const generateInsightsData = (
  dataDefId: string,
  metricId: string,
  lookupMin: number,
  lookupMax: number,
  percentageChangeValues: number[],
  timeShiftDataPoints: [number, number]
) => {
  const metricKey = generateMetricKey(dataDefId, metricId);
  const numValues = percentageChangeValues.length;
  const timeShiftDataValues = getDataValuesInRange(timeShiftDataPoints, numValues);
  const dataValues = timeShiftDataValues.map((value, idx) => {
    const perChange = percentageChangeValues[idx];
    const sign = Math.abs(perChange) === perChange ? 1 : -1;
    const change = (value * Math.abs(percentageChangeValues[idx]) / 100);
    return value + (change * sign);
  });
  const schemaPayload = generateSchemaPayload(ssEntityLookupData);

  return {
    "data": [
      {
        "metricResults": [
          {
            "dataDefinitionId": dataDefId,
            "data": {},
            "compareConfigData": {},
            "entityLookupData": ssEntityLookupData,
            "warnings": [] as any[],
            "dataQueryConfig": {},
            "postAggResult": {
              "data": {
                [metricKey]: {
                  "data": generateData(lookupMin, lookupMax, dataValues, metricKey),
                  "schema": schemaPayload,
                  "preLimitSelectionCount": 160
                }
              },
              "entityLookupData": ssEntityLookupData,
              "percentageChangeData": {
                [metricKey]: {
                  "data": generateData(lookupMin, lookupMax, percentageChangeValues, metricKey),
                  "schema": schemaPayload,
                  "preLimitSelectionCount": 160
                }
              },
              "timeShiftedData": {
                [metricKey]: {
                  "data": generateData(lookupMin, lookupMax, timeShiftDataValues, metricKey),
                  "schema": schemaPayload,
                  "preLimitSelectionCount": 160
                }
              }
            }
          }
        ]
      }
    ],
    "entityContext": {}
  };
};

const generateData = (
  lookupMin: number,
  lookupMax: number,
  values: number[],
  metricKey: string
) => {
  const fakeEntities = spliceAndGetMockEntities(lookupMin, lookupMax, ssEntityLookupData);

  return Object.keys(fakeEntities)
    .map((key, idx) => template(key, values[idx], metricKey));
};

const getDataValuesInRange = (range: [number, number], numValues: number) => {
  const values: number[] = [];
  for (let i = 0; i < numValues; i++) {
    values.push(random(range[0], range[1]));
  }
  return values;
};

const template = (entityId: string, value: number, metricKey: string) => ({
  "datapoints": [
    [
      value,
      1649656800000
    ]
  ],
  "refId": metricKey,
  "target": ` - ${entityId} `,
  "tags": {
    "i_entity": entityId
  },
  "metricName": "",
  "eTags": {
    "i_entity": entityId
  }
});