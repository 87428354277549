import appConfig from "../../../../appConfig";
import { DashboardShareConfig, GetDashboardResult, GrafanaDashboardMeta, GrafanaDashboardModel } from "../../../dashboard";
import DashboardModel from "../../../dashboard/models/DashboardModel";
import { isJsonString } from "../../../utils";
import { ApptuitDatasource } from "../../datasources/apptuit/ApptuitDatasource";
import { ConfigMetaModel, Configuration } from "../configuration/types/configuration";
import { configurationUtils } from "../configuration/utils";
import datasourceApiManager from "../DatasourceApiService";
import { request } from "../request";
import DashboardApiServiceInterface from "./IDashboardApiService";
import { DashboardListingResponse } from "./types";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
  statusCode?: number;
}

class DashboardApiService implements DashboardApiServiceInterface {
  baseUrl = "/api/dashboards";

  private datasource: ApptuitDatasource;

  protected init() {
    this.datasource = datasourceApiManager.getDefault();
  }

  async getDashboards() {
    const url = this.baseUrl;

    const result: Result<ConfigMetaModel[]> = {
      data: [],
      error: false,
      message: "",
    };

    try {
      this.init();
      const response = await this.datasource.get<DashboardListingResponse, null>(url);
      result.data = response.data.configs.map((config) =>
        configurationUtils.payloadToMeta(config, "")
      );
    } catch (err) {
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  async getDashboard(dashboardId: string, pShareId?: string) {
    const result: Result<GetDashboardResult> = {
      data: null,
      error: false,
      message: "",
    };

    const shareId = pShareId || appConfig.anomShareId;
    const subUrl = shareId
      ? `${this.baseUrl}/${dashboardId}?shareId=${shareId}`
      : `${this.baseUrl}/${dashboardId}`;

    try {
      let response = null;

      if (shareId) {
        const url = `${appConfig.apiDomainUrl}${subUrl}`;
        response = await request.get<Configuration>(url);
      } else {
        this.init();
        const url = subUrl;
        response = await this.datasource.get<Configuration, null>(url);
      }
      const config = configurationUtils.payloadToMeta(response.data, "");
      const { payload } = config;
      const { dashboardJson, dashboardMeta } = payload || {};
      const pDashboardJson = isJsonString(dashboardJson?.stringVal)
        ? (JSON.parse(dashboardJson?.stringVal) as GrafanaDashboardModel)
        : null;
      const pDashboardMeta = isJsonString(dashboardMeta?.stringVal)
        ? (JSON.parse(dashboardMeta?.stringVal) as GrafanaDashboardMeta)
        : null;
      let dashboard: DashboardModel = null;

      if (pDashboardJson) {
        dashboard = (pDashboardJson as GrafanaDashboardModel).inceptionJson;
      }

      result.data = {
        dashboard,
        meta: pDashboardMeta,
      };
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
      result.statusCode = err.response.status;
    }

    return result;
  }

  async getSharedDashboardInfo(dashboardId: string, shareId: string) {
    let url = `${this.baseUrl}/${dashboardId}/share`;
    url += `?shareId=${shareId}`;

    const result: Result<DashboardShareConfig> = {
      data: null,
      error: false,
      message: "",
    };

    try {
      let response = null;

      if (appConfig.anomShareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.get<DashboardShareConfig>(apiUrl);
      } else {
        this.init();
        response = await this.datasource.get<DashboardShareConfig, null>(url);
      }
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = err.message;
      result.statusCode = err.response.status;
    }

    return result;
  }

  async getDashboardIcon(pShareId?: string) {
    let url = `${this.baseUrl}/icon`;
    const shareId = pShareId || appConfig.anomShareId;

    url += shareId ? `?shareId=${shareId}` : "";

    const result: Result<any> = {
      data: null,
      error: false,
      message: "",
    };

    try {
      let response = null;

      if (appConfig.anomShareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        response = await request.get<Blob>(apiUrl, null, { responseType: "blob" });
      } else {
        this.init();
        response = await this.datasource.get<Blob, null>(url);
      }
      result.data = response.data;
    } catch (err) {
      result.error = true;
      result.message = err.message;
    }

    return result;
  }
}

const dashboardApiService = new DashboardApiService();

export default dashboardApiService;
