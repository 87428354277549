import React, { FC, useMemo, useCallback } from "react";
import { IncSmartText, IncList, IncListProps } from "@inception/ui";
import { SimpleEntityNameRenderer, EntityDetails } from "../entity-properties-popover";

export type EntityListData = {
  entityIds: string[];
  entityLookupData: Record<string, string>;
  entityDetailsData?: Record<string, EntityDetails>;
};

interface Props {
  entityTypeName: string;
  initialData?: EntityListData;
  searchable?: boolean;
  title?: string;
  sortData?: boolean;
  sortFn?: IncListProps['sortFn'];
  onSearch?: (searchStr: string) => Promise<EntityListData>;
  debounceTimeSecs?: number;
  showEntityInfoOnHover?: boolean;
  rowRenderer?: (entityId: string, entityName: string, defaultElement: JSX.Element) => JSX.Element;
  size?: IncListProps['size'];
}

export const EntityList: FC<Props> = React.memo((props) => {
  const {
    entityTypeName,
    searchable,
    title,
    sortData = false,
    sortFn,
    initialData: pInitialData,
    onSearch: pOnSearch,
    debounceTimeSecs = 1,
    showEntityInfoOnHover = false,
    rowRenderer,
    size
  } = props;


  const initialData = useMemo<IncListProps['initialData']>(() => pInitialData ? ({
    keys: pInitialData.entityIds || [],
    lookupData: pInitialData.entityLookupData || {}
  }) : null, [pInitialData]);


  const defaultOnSearch = useCallback(async (searchStr: string): Promise<EntityListData> => {
    const { keys = [], lookupData = {} } = initialData || {};
    if (!searchStr) {
      return {
        entityIds: keys,
        entityLookupData: lookupData
      };
    }

    const filteredLookup: Record<string, string> = {};

    const entityIds = Object.keys(lookupData);
    const filteredEntityIds = entityIds.filter(id => lookupData[id].toLowerCase().includes(searchStr.toLowerCase()));
    filteredEntityIds.forEach(id => filteredLookup[id] = lookupData[id]);

    return {
      entityIds: filteredEntityIds,
      entityLookupData: filteredLookup
    };
  }, [initialData]);

  const onSearch = useCallback<IncListProps['onSearch']>(async (searchStr: string) => {
    const searchPromise = pOnSearch ?? defaultOnSearch;
    const {
      entityIds,
      entityLookupData
    } = await searchPromise(searchStr);

    return {
      keys: entityIds,
      lookupData: entityLookupData,
    };
  }, [pOnSearch, defaultOnSearch]);

  const renderer = useCallback<IncListProps['renderer']>((key: string, lookupValue: string) => {
    const defaultElement = <EntityListRow
      entityId={key}
      entityName={lookupValue}
      showEntityInfoOnHover={showEntityInfoOnHover}
    />;
    return rowRenderer ? rowRenderer(key, lookupValue, defaultElement) : defaultElement;
  }, [rowRenderer, showEntityInfoOnHover]);

  return <IncList
    debounceTimeSecs={debounceTimeSecs}
    initialData={initialData}
    onSearch={onSearch}
    renderer={renderer}
    searchText={entityTypeName}
    searchable={searchable}
    size={size}
    sortData={sortData}
    sortFn={sortFn}
    title={title}
  />;
});

interface EntityListRowProps {
  entityId: string;
  entityName: string;
  showEntityInfoOnHover?: boolean;
}

const EntityListRow: React.FC<EntityListRowProps> = (props) => {
  const {
    entityId,
    entityName,
    showEntityInfoOnHover = false
  } = props;

  return <>
    {!showEntityInfoOnHover && <IncSmartText text={entityName} />}
    {showEntityInfoOnHover && <SimpleEntityNameRenderer
      id={entityId}
      minimalLoader
      name={entityName}
      showPropertiesPopover
    />}
  </>;
};
