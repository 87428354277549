import { TimeObj, TimeObjUnit, TimeWindow } from "../services/api/explore";
import { RelativeDurationType } from "../dashboard/models/BaseWidgetModel";
import { RawTimeRange } from "../core";
import { pluralizeWord } from "./Utils";

export function getTimeObjFromDuration(duration: number, durationType: RelativeDurationType): TimeObj {
  let durationUnit: TimeObjUnit;

  switch (durationType) {
    case RelativeDurationType.MINUTES: {
      durationUnit = TimeObjUnit.minutes;
      break;
    }

    case RelativeDurationType.HOURS: {
      durationUnit = TimeObjUnit.hours;
      break;
    }

    case RelativeDurationType.DAYS: {
      durationUnit = TimeObjUnit.days;
      break;
    }

    case RelativeDurationType.WEEKS: {
      durationUnit = TimeObjUnit.weeks;
      break;
    }

    case RelativeDurationType.MONTHS: {
      durationUnit = TimeObjUnit.months;
      break;
    }

    default: durationUnit = TimeObjUnit.unset;
  }

  return {
    unit: durationUnit,
    value: duration
  };
}

export function getDurationFromTimeObj(duration: TimeObj): {
  duration: number;
  durationType: RelativeDurationType;
} {
  const { unit, value } = duration;
  let durationType: RelativeDurationType;

  switch (unit) {
    case TimeObjUnit.minutes: {
      durationType = RelativeDurationType.MINUTES;
      break;
    }

    case TimeObjUnit.hours: {
      durationType = RelativeDurationType.HOURS;
      break;
    }

    case TimeObjUnit.days: {
      durationType = RelativeDurationType.DAYS;
      break;
    }

    case TimeObjUnit.weeks: {
      durationType = RelativeDurationType.WEEKS;
      break;
    }

    case TimeObjUnit.months: {
      durationType = RelativeDurationType.MONTHS;
      break;
    }

    default: durationType = RelativeDurationType.HOURS;
  }

  return {
    duration: value,
    durationType
  };
}

type SuffixSize = 'sm' | 'md' | 'lg';

export const getLabelForTimeObj = (
  duration: TimeObj,
  suffixSize: SuffixSize = 'sm',
  shouldPluralise = false,
  addLySuffix = false
): string => {
  const timeObjUnitSuffixMap = getTimeObjUnitSuffixMap();

  const {
    unit = TimeObjUnit.unset,
    value
  } = duration || {};
  const unitSuffix = timeObjUnitSuffixMap[unit]?.[suffixSize] || '';

  if (addLySuffix && value <= 2) {
    const canAddLySuffix = unit === TimeObjUnit.hours || unit === TimeObjUnit.weeks || unit === TimeObjUnit.months || unit === TimeObjUnit.years;

    if (canAddLySuffix) {
      if (value === 1) {
        return timeObjUnitSuffixMap[unit].lg + 'ly';
      } else if (value === 2 && unit !== TimeObjUnit.hours) {
        return 'bi-' + timeObjUnitSuffixMap[unit].lg + 'ly';
      }
    }
  }

  if (!shouldPluralise || suffixSize === 'sm') {
    return value + unitSuffix;
  }

  return pluralizeWord(unitSuffix, value, true);
};

export const getTimeObjUnitSuffixMap = (): Record<TimeObjUnit, Record<SuffixSize, string>> => ({
  [TimeObjUnit.millis]: {
    lg: 'millisecond',
    md: 'milli',
    sm: 'ms'
  },
  [TimeObjUnit.seconds]: {
    lg: 'second',
    md: 'sec',
    sm: 's'
  },
  [TimeObjUnit.minutes]: {
    lg: 'minute',
    md: 'min',
    sm: 'm'
  },
  [TimeObjUnit.hours]: {
    lg: 'hour',
    md: 'hr',
    sm: 'h'
  },
  [TimeObjUnit.days]: {
    lg: 'day',
    md: 'day',
    sm: 'd'
  },
  [TimeObjUnit.weeks]: {
    lg: 'week',
    md: 'week',
    sm: 'w'
  },
  [TimeObjUnit.months]: {
    lg: 'month',
    md: 'mon',
    sm: 'M'
  },
  [TimeObjUnit.years]: {
    lg: 'year',
    md: 'yr',
    sm: 'y'
  },
  [TimeObjUnit.unset]: {
    lg: '',
    md: '',
    sm: ''
  }
});

export const getTimeObjFromInterval = (intervalStr: string): TimeObj => {
  const suffixMap = getTimeObjUnitSuffixMap();

  const value = parseInt(intervalStr, 10);
  const suffix = intervalStr.replace(value.toString(), '');

  let unit: TimeObjUnit;
  Object.keys(suffixMap).forEach(key => {
    const timeKey = key as TimeObjUnit;
    const suffixValue = suffixMap[timeKey].sm;

    if (suffix === suffixValue) {
      unit = timeKey;
    }
  });

  return {
    unit,
    value
  };
};

export const getRawTimeRangeFromTimeWindowObj = (timeWindow: TimeWindow) => {
  let timeRange: RawTimeRange = null;
  if (timeWindow?.relativeTime) {
    const timeObjUnitSuffix = getTimeObjUnitSuffixMap();
    const relativeTimeObj = timeWindow.relativeTime;
    const timeObjSuffix = timeObjUnitSuffix[relativeTimeObj.unit].sm;
    const from = `now-${relativeTimeObj.value}${timeObjSuffix}`;
    const to = "now";
    timeRange = {
      from,
      to,
    };
  } else if (timeWindow?.timeRange) {
    const timeRangeMillis = timeWindow.timeRange;
    timeRange = {
      from: timeRangeMillis.startTimeMillis.toString(),
      to: timeRangeMillis.endTimeMillis.toString(),
    };
  }
  return timeRange;
};
