import { DerivedData, Span, SpanTreeNode, Trace } from "./types";

const rootSpan: Span = {
  traceID: 'root',
  spanID: 'root',
  operationName: '',
  serviceName: '',
  references: [],
  startTime: "0",
  duration: "0",
  endTime: "0",
  spentTime: "0",
  cpuTime: "0",
  isRoot: "false",
  spanError: "false",
  spanKind: "",
  processServiceName: "",
  serviceInstance: "",
  endpoint: "",
  componentEndpoint: "",
  component: "",
  api: "",
  componentName: "",
  calledApi: "",
  backendInstance: "",
  process: {
    serviceName: "",
    tags: []
  },
  tags: [],
  logs: [],
  fields: [],
  warnings: '',
  rootApi: '',
};

export function constructSpanTree(trace: Trace, predefinedRoot?: SpanTreeNode): DerivedData | null {
  if (!trace || !trace.traceFields) {
    return null;
  }

  // Construct the SpanTreeNodes from spans and store it in the map for lookup when assigning children
  const spanIdToSpanTreeNode: Map<string, SpanTreeNode> = new Map();
  trace.traceFields.forEach((span: Span) => {
    const node: SpanTreeNode = {
      ...span,
      children: [],
      depth: 0,
      collapsed: false,
      serviceName: ''
    };
    spanIdToSpanTreeNode.set(span.spanID, node);
  });

  let root: SpanTreeNode;
  if(predefinedRoot){
    root = predefinedRoot;
    spanIdToSpanTreeNode.set(root.spanID, root);
  } else {
    root = {
      ...rootSpan,
      children: [],
      depth: 0,
      collapsed: false,
      serviceName: ''
    };
  }
  // Iterate over the raw spans and use the references.refType = 'CHILD_OF' to assign children to nodes
  trace.traceFields.forEach((span: Span) => {
    const childTreeNode = spanIdToSpanTreeNode.get(span.spanID);
    if (span.references && span.references[0] && (span.references[0].refType === 'CHILD_OF' || span.references[0].refType === 'FOLLOWS_FROM')) {

      const { refType } = span.references[0];

      if (refType === 'CHILD_OF' || refType === 'FOLLOWS_FROM') {
        const parentSpanId = span.references[0].spanID;

        // Get correspinding tree nodes from the map and assign children.
        const parentTreeNode = spanIdToSpanTreeNode.get(parentSpanId);

        if (!parentTreeNode) {
          console.log("No parent or child tree node found", span.spanID, span.references);
          if (childTreeNode) {
            root.children.push(childTreeNode);
          }
        } else {
          if (childTreeNode) {
            parentTreeNode?.children.push(childTreeNode);
          }
        }
      } else {
        throw new Error(`Unrecognized ref type`);
      }
    } else {
      if (childTreeNode) {
        root.children.push(childTreeNode);
      }
    }

    // Add process info to the span
    if (childTreeNode) {
      childTreeNode.serviceName = span.serviceName;
    }
  });

  recursivelySortChildren(root);

  //console.log ("Root children:" + root.children.length);
  // console.log ("TreeNodes children:" + treeNodes.length);

  return {
    spanTree: [predefinedRoot? root: root.children[0]],
    duration: getTraceDuration(trace.traceFields),
    startTime: getTraceStartTime(trace.traceFields)
  };
}

const recursivelySortChildren = (root: SpanTreeNode) => {
  if (!root || !root.children || root.children.length === 0) {
    return;
  }

  root.children.sort(comparator);

  root.children.forEach((child) => {
    recursivelySortChildren(child);
  });
};

const comparator = (nodeA: SpanTreeNode, nodeB: SpanTreeNode) => nodeA.startTime > nodeB.startTime ? 1 : -1;

// const sortNodeByTime = (root : SpanTreeNode) => {

//     if (!root || !root.children || root.children.length === 0) {
//         return;
//     }

//     debugger;

//     root.children.forEach((node : SpanTreeNode) => {
//         if (node.children && node.children.length > 0) {
//             node.children.sort(comparator);

//             node.children.forEach(n => sortNodeByTime(n));
//         };
//     });
// }

const getTraceStartTime = (spans: Span[]): number => {
  const s: Span | null = spans.reduce(
    (minTsSpan, current) => {
      if (parseInt(minTsSpan.startTime, 10) < parseInt(current.startTime, 10)) {
        return minTsSpan;
      } else {
        return current;
      }
    }
  );

  return parseInt(s.startTime, 10);
};

const getTraceDuration = (spans: Span[]) => {
  const timestamp = getTraceStartTime(spans);
  let prevDuration = 0;
  let maxValue = 0;
  spans.forEach((span) => {
    const val = parseInt(span.startTime, 10) - timestamp + parseInt(span.duration, 10);
    maxValue = Math.max(val, prevDuration);
    prevDuration = maxValue;

  });

  return maxValue;
};

export const COMPONENT = 'component';
export const SERVICE = 'service';
export const API = 'api';
export const CALLED_API = 'calledApi';
export const BACKEND_API = 'backendInstance';
export const CLIENT = 'CLIENT';
