import React from "react";
import { IncFaIcon } from "../Icons";

type Position = "topcenter" | "center" | "topleft" | "right";
interface LoadingSpinnerProps {
  label?: string;
  position?: Position;
  className?: string;
}

const IncLoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  const {
    label = "Loading...",
    position = "center",
    className: pClassName,
  } = props;

  const className = `inc-flex-row inc-flex-grow loadingspinner ${pClassName}`;

  return (
    <>
      <div className={className}>
        <div className={`inc-flex-grow ${position}`}>
          <div className="inc-flex-row inc-flex-center-vertical">
            <IncFaIcon className="marginRt8" iconName="spinner" spin />
            {label}
          </div>
        </div>
      </div>
    </>
  );
};

export default IncLoadingSpinner;
