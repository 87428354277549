import { convertToNumber, defNumberFormatter, NumericDataConguration } from "../numeric";

const locale = "en-US";

export const durationConfigMap: Record<string, NumericDataConguration> = {
  [locale]: {
    quickValues: ["1h", "2h", "6h", "1d", "2d"],
    regex: new RegExp(/^([0-9]*\.?[0-9]+)(y|M|d|h|m)?$/),
    abbreviation: {
      "y": 31556952000,
      "M": 2592000000,
      "w": 604800000,
      "d": 86400000,
      "h": 3600000,
      "m": 60000,
    },
    formatter: defNumberFormatter,
    convertToNumber: function (input: string): number {
      return convertToNumber(input, {
        abbreviationMap: this.abbreviation,
        regex: this.regex
      });
    }
  }
};

export const getDurationDataConfiguration = (): NumericDataConguration => (durationConfigMap["en-US"]);
