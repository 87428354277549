import { useMemo, useCallback } from "react";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../../../../core";
import { operationaliseV2ApiService } from "..";
import { AlertCountResponses } from "../types";

type Result = AlertCountResponses['alertCountResponses'];

export const useFetchIncidentsCountForMultipleOps = (
  opIds: string[],
  startTimeMillis: number,
  endTimeMillis: number,
  timeIntervalMillis: number,
  generateDemoData?: boolean,
  lazyFetch?: boolean
) => {
  const initialState = useMemo<InitialState<Result, string>>(() => ({
    data: {},
    error: null,
    isError: false,
    isFetching: !lazyFetch,
    isSuccess: false
  }), [lazyFetch]);

  const fetchFn = useCallback<FetchFn<Result, string>>(async () => {
    const result: FetchCallBackResult<Result, string> = {
      data: {},
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await operationaliseV2ApiService.getIncidentsCountForMultipleOp10ze(
        opIds,
        startTimeMillis,
        endTimeMillis,
        timeIntervalMillis,
        generateDemoData
      );

      result.data = data?.alertCountResponses || {};
      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : '';
    } catch (err) {
      result.isError = true;
      result.error = err.message || err.toString();
    }

    return result;
  }, [endTimeMillis, generateDemoData, opIds, startTimeMillis, timeIntervalMillis]);

  return useDataFetch(fetchFn, initialState, lazyFetch);
};
