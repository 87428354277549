import { routePaths } from "../../app/RoutePaths";
import { getInceptionRoute } from "../core/hooks";
import { getOpHomeQueryParams } from "../operationalise-v2/utils";

class LinkGeneratorUtils {
  getOperationalizeEdit(entityTypeId: string, widgetId: string, compareId: string, metricId: string, useNewApi = false) {
    let path: string;
    let params: Record<string, string>;

    if (!useNewApi) {
      path = `${routePaths.operationalize}/${entityTypeId}/${widgetId}/edit`;
      params = {
        compareId,
        metricId
      };
    } else {
      path = routePaths.operationalizeHome;
      params = getOpHomeQueryParams(compareId, "edit", {});
    }

    return getInceptionRoute(path, params);
  }

  getDrillDown(widgetId: string, entityTypeId: string, mode: string, incidentId: string, compareId: string, mqId: string, metricId: string,
    slices: string[] = [], from: number, to: number | string) {
    let url = `/alerts/widget/summary/${widgetId}?entityType=${entityTypeId}&mode=${mode}&incidentId=${incidentId}` +
    `&compareId=${compareId}&from=${from}&to=${to}&metricId=${metricId}&mqId=${mqId}`;
    if (slices.length > 0) {
      slices.forEach((slice) => (url += `&slices=${slice}`));
    }
    return url;
  }

  getAddConfigurationLink(configTypeId?: string) {
    let addRoute = `${routePaths.configuration}/add`;
    if (configTypeId) {
      addRoute = `${addRoute}?configType=${configTypeId}`;
    }
    return addRoute;
  }

  getEditConfigurationLink(configTypeId: string, configId: string) {
    let editRoute = `${routePaths.configuration}/edit`;
    if (configTypeId && configId) {
      editRoute = `${editRoute}?configType=${configTypeId}&id=${configId}`;
    }
    return editRoute;
  }

  getConfigurationListLink(configTypeId?: string) {
    let listRoute = `${routePaths.configurationList}`;
    if (configTypeId) {
      listRoute = `${listRoute}?configType=${configTypeId}`;
    }
    return listRoute;
  }
}

export const linkGeneratorUtils = new LinkGeneratorUtils();
