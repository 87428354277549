import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store/configureStore";
import appConfig from "../../../../appConfig";
import { setTitlePayload } from "./reducer";
import { TitleState } from "./types";

const useTitle = () => {
  const dispatch = useDispatch();
  const titleState = useSelector((storeState: ApplicationState) => ({ ...storeState.title }), shallowEqual);

  const setTitle = useCallback((newTitleState: TitleState) => {
    const { title = '' } = newTitleState;
    document.title = `${title ? `${title} - ` : ''}${appConfig.branding.prefix}`;
    dispatch(setTitlePayload(newTitleState));
  }, [dispatch]);

  const hideUseCaseSelector = useCallback(() => {
    setTitle({ ...titleState,
      hideUseCaseSelector: true
    });
  }, [setTitle, titleState]);

  const showUseCaseSelector = useCallback(() => {
    setTitle({
      ...titleState,
      hideUseCaseSelector: false
    });
  }, [setTitle, titleState]);

  return {
    showUseCaseSelector,
    hideUseCaseSelector,
    setTitle,
    titleState
  };
};

export { useTitle };

