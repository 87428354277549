import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../../store/configureStore';
import { loginApiService, uiApiService, BootConfigPayload } from '../../services/api';
import { Role, Org, User, AuthType } from '../../core';
import appConfig from "../../../appConfig";
import PermissionsManager, { PermissionsInterface } from '../../permissions/PermissionsManager';
import { accessRegister } from '../../permissions/AccessRegister';
import { AuthCheckError, AuthError, AuthLogout, AuthSuccess, AuthUpdateOrgs } from "./actions";
import { BootConfigUpdate } from './useBootConfig';
import { AuthState, BootConfigState } from './types';

const useAuth = () => {
  const dispatch = useDispatch();
  const authState: AuthState = useSelector((storeState: ApplicationState) => ({ ...storeState.auth }), shallowEqual);
  const bootConfigState: BootConfigState = useSelector((storeState: ApplicationState) => ({ ...storeState.bootConfig }), shallowEqual);

  const logoutUser = useCallback(() => {
    dispatch(AuthLogout());
  }, [dispatch]);

  const postUserLogin = useCallback(async (bootConfigPayload: BootConfigPayload) => {
    try {
      const user: User = bootConfigPayload.user;
      const userPreferences = bootConfigPayload.preferences;
      let role = Role.Viewer;
      let orgs: Org[] = [];

      const isSupportLoginEnabled = bootConfigPayload?.isSupportLoginEnabled;
      const isSupportSession = bootConfigPayload?.isSupportSession;
      const currentOrg = bootConfigPayload.org;

      if (isSupportLoginEnabled) {
        orgs = await uiApiService.getAllowedOrgs();
      } else {
        orgs = bootConfigPayload.userOrgs;
      }

      role = ((isSupportSession && bootConfigPayload.supportContext) ?
        bootConfigPayload.supportContext?.role
        : user?.role || (orgs.find(org => org.orgId === currentOrg.id)?.role) || role);

      const tempRole = localStorage.getItem(`TENANT_UI_ROLE_${currentOrg.id}`) as Role;
      user.role = tempRole || role;

      user.apptuitRole = role;
      user.isWalkthroughUser = user.email.startsWith("demo_") && user.role === "Viewer";
      user.isBicycleUser = user.email.endsWith(`@${appConfig.branding.emailDomain}`) || user.email === appConfig.smokeTestUserEmail;

      const permissions: PermissionsInterface = new PermissionsManager(accessRegister.getAccesses(), user);

      //USAGE: permissions.hasAccess(Feature.businessEntity, ActionsMap.addEntity);

      dispatch(AuthSuccess({
        user,
        currentOrg,
        orgs,
        permissions
      }));
      dispatch(BootConfigUpdate({
        user: user,
        org: currentOrg,
        userOrgs: orgs,
        preferences: userPreferences,
        authType: bootConfigPayload.authType,
        ...(isSupportLoginEnabled && { isSupportLoginEnabled }),
        ...(isSupportSession && {
          isSupportSession,
          supportContext: bootConfigPayload?.supportContext
        })

      }));
    } catch (error) {
      const status = (error as any).response.status;
      const message = (error as any).response.statusText;
      dispatch(AuthError({
        error: {
          message: message,
          statusCode: status
        }
      }));
    }
  }, [dispatch]);

  const performAuthCheck = useCallback(async () => {
    try {
      const bootConfigPayload = await uiApiService.fetchBootConfig();
      bootConfigPayload.authType = bootConfigPayload.authType ? bootConfigPayload.authType : AuthType.password;
      postUserLogin(bootConfigPayload);
    } catch (error) {
      const status = (error as any)?.response?.status;
      const message = (error as any)?.response?.statusText;
      dispatch(AuthCheckError({
        error: {
          message: message,
          statusCode: status
        }
      }));
    }

  }, [dispatch, postUserLogin]);

  const validateCredentials = useCallback(async ({ username, password }) => {
    try {
      await loginApiService.apptuitLogin(username, password);
      const bootConfigPayload = await uiApiService.fetchBootConfig();
      postUserLogin(bootConfigPayload);
    } catch (error) {
      const status = (error as any).response.status;
      const message = (error as any).response.statusText;
      dispatch(AuthError({
        error: {
          message: message,
          statusCode: status
        }
      }));
    }
  }, [dispatch, postUserLogin]);

  const getOrgsForSupportUser = useCallback(async() => {
    if (bootConfigState.isSupportLoginEnabled) {
      const orgs = await uiApiService.getAllowedOrgs();
      if (orgs.length) {
        dispatch(AuthUpdateOrgs(orgs));
      }
    }
  }, [bootConfigState.isSupportLoginEnabled, dispatch]);

  return {
    authState,
    getOrgsForSupportUser,
    performAuthCheck,
    logoutUser,
    validateCredentials
  };
};

export { useAuth };
