import { uniq, groupBy, forEach } from "lodash";
import React from "react";
import { IncToolTip, IncFaIcon, IncPill, IncFaIconName } from "@inception/ui";
import {
  BizFieldPredicate, MetricUserServiceFilters, WidgetConfigUtils,
  UserServiceFilterExpression,
  EntityField
} from "../services/api/explore";
import { Options, FieldPickerUtils } from "./FieldPickerUtils";


export const getEntityFilterLabelsComponent = (
  entityFilters: BizFieldPredicate[], eventFilters: MetricUserServiceFilters, options: Options, icon: IncFaIconName = "filter"
) => {
  const entityFilterLabels = getFilterLabelsFromEntityFilters(entityFilters, options);

  let eventFilterLabels: string[] = [];
  Object.values(eventFilters).forEach((usFilterListEntry) => {
    const {
      expressionTree,
      userServiceFilters
    } = usFilterListEntry;

    if (userServiceFilters) {
      userServiceFilters.forEach(usf => {
        const { userServiceFilterExpressions } = usf;
        const filterLabels = userServiceFilterExpressions.map((x) => WidgetConfigUtils.getUsFilterExpressionLabel(x));
        eventFilterLabels = [...eventFilterLabels, ...filterLabels];
      });
    } else if (expressionTree) {
      const exprFilterLabel = WidgetConfigUtils.getUsFilterExpressionTreeLabel(expressionTree);
      eventFilterLabels = exprFilterLabel ? [...eventFilterLabels, exprFilterLabel] : eventFilterLabels;
    }
  });

  const filterLabels = [
    ...entityFilterLabels,
    ...eventFilterLabels,
  ];
  const filteredLabels = uniq(filterLabels);

  const hoverElement = infoPopper(filteredLabels);

  return (
    <IncToolTip titleElement={hoverElement}>
      <span className="marginRt8">
        <IncFaIcon iconName={icon} />
      </span>
    </IncToolTip>
  );
};

export const getFilterLabelsFromEventFilters = (
  eventFilters: UserServiceFilterExpression[],
  entityLookUp: Record<string, string>
) =>
  eventFilters.map((x) =>
    WidgetConfigUtils.getUsFilterExpressionLabel(x, entityLookUp)
  );


export const getFilterLabelsFromEntityFilters = (entityFilters: BizFieldPredicate[], options?: Options) => entityFilters.map(entityFilter => {
  if (entityFilter?.bizField) {
    const { label } = FieldPickerUtils.getBizFieldPredicatePillLabelAndInfo(
      entityFilter,
      options
    );
    return label;
  }
  return null;
}).filter(x => x !== null);

export const getUniqBizFieldPredicatesByPropName = (allPredicates: BizFieldPredicate[]) => {
  const groupedPredicates = groupBy(allPredicates, p => {
    const { bizField } = p;
    const { entityField } = bizField;
    const { entityType, propName } = entityField;

    return [entityType, propName].join('_');
  });

  const fPredicates: BizFieldPredicate[] = [];

  forEach(groupedPredicates, predicates => {
    const { bizField } = predicates[0];
    const { entityField: { propType } } = bizField;

    if (propType === 'STRING') {
      const valuesSet = new Set<string>();

      predicates.forEach(({ value, values }) => {
        if (value) {
          valuesSet.add(value);
        }

        if (values?.length) {
          values.forEach(value => valuesSet.add(value));
        }
      });

      const valuesArr = Array.from(valuesSet);
      const multipleValues = valuesArr.length > 1;

      const op = multipleValues ? "in" : 'eq';
      const value = multipleValues ? null : valuesArr[0];
      const values = multipleValues ? valuesArr : [];

      fPredicates.push({
        bizField,
        op,
        value,
        values
      });
    } else {
      fPredicates.push(...predicates);
    }
  });

  return fPredicates;
};

export const getBizFieldPredicateForEntityField = (
  entityField: EntityField,
  value: string
): BizFieldPredicate => ({
  bizField: {
    entityField: {
      ...entityField,
      propName: 'Name',
      propType: 'STRING'
    }
  },
  op: 'eq',
  value,
  values: []
});

const infoPopper = (labels: string[]) => (
  <div className="infoPopper">
    {labels.map((x, i) => (
      <IncPill className="pill-container marginBt8" key={i} label={x}
        labelClass={"pill-text"} />
    ))}
  </div>
);
