import { groupBy, forEach, isEmpty, first, isEqual, intersection, omit, cloneDeep, values, isArray, uniqBy, zipObject } from "lodash";
import qs from 'query-string';
import { IncSelectOption } from "@inception/ui";
import { DataType, generateId, logger } from "../../../../core";
import {
  WidgetConfigDTO, WidgetResponseDTO, MetricDefinition, CompareConfigType, TagFilter, MetricConfigDefinition,
  CompareConfig, UserServiceFieldSliceSet, UserServiceFieldSlice, UserServiceFieldMetricConfigDefinition,
  UserServiceFieldWithMeta, UserServiceMetricConfigDefinition, BizEntityMetricConfigDefinition,
  ExpressionMetricConfigDefinition, UserServiceFilterExpression, SelectorSpec, SliceSet, SelectorTag,
  WidgetQuerySchema, Slice, UserServiceField, OverTimeAggregators, MetricAndSliceRecord, CohortConfig,
  ExpressionMetricConfig, SliceSpec, EntityMetricConfigDefinition, BizDataQuery, BuildingBlockConfig, UserServiceFilterExpressionTree, DataDefinition
} from "../types";
import { shouldExcludeTag, NAME_TAG, QUANTILE_TAG } from "../../../../core/utils";
import { FieldPickerUtils } from "../../../../utils/FieldPickerUtils";
import { isSystemCreatedMetric, convertToSliceSet, getDefaultWidgetResponseDto, getDtoFromWidgetConfig } from "../../../../utils/ExploreUtils";
import { ENTITY_TAG } from "../../../../utils/MetricNameUtils";
import { BizIdProps } from "../../operationalise";

export const newWidgetConfigDto = (
  entityType: string,
  entityId: string,
  entityName: string = null,
  entityTypeName: string = null
): WidgetResponseDTO => getDefaultWidgetResponseDto(entityType, entityId, entityName, entityTypeName);


export class WidgetConfigUtils {
  constructor() {
    logger.debug("WidgetConfigUtils", "Util class");
  }

  static extractSliceSpecsFromExpressionMetric(expressionMetricConfig: ExpressionMetricConfig): SliceSpec[] {
    const sliceSpecArr: SliceSpec[] = [];
    if (expressionMetricConfig && expressionMetricConfig.expression) {
      const expression = expressionMetricConfig.expression;
      const expMetricConfigs = [
        expression.leftExpr?.expressionMetricConfig,
        expression.rightExpr?.expressionMetricConfig,
      ].filter(Boolean);
      expMetricConfigs.forEach((exp) =>
        sliceSpecArr.push(
          ...WidgetConfigUtils.extractSliceSpecsFromExpressionMetric(exp)
        )
      );
      return sliceSpecArr;
    } else if (expressionMetricConfig && expressionMetricConfig.sliceSpec) {
      sliceSpecArr.push(expressionMetricConfig.sliceSpec);
      return sliceSpecArr;
    } else {
      return sliceSpecArr;
    }
  }

  static getMetricSliceSetRecord(
    metrics: Record<string, MetricDefinition>,
    widgetQuerySchemas: WidgetQuerySchema[],
    options?: {
      handleUnSavedExpSubMetrics?: boolean;
    }
  ): MetricAndSliceRecord[] {
    const records: MetricAndSliceRecord[] = [];

    const { handleUnSavedExpSubMetrics = false } = (options || {});
    if (isEmpty(metrics) || isEmpty(widgetQuerySchemas)) {
      return records;
    }

    const metricIdsToOmit: string[] = [];
    widgetQuerySchemas.forEach(querySchema => {
      const {
        componentSourceFields = {},
        metricId
      } = querySchema;

      if (metrics[metricId]?.sourceType === 'expression') {
        const componentMetricIds = Object.keys(componentSourceFields);
        metricIdsToOmit.push(...componentMetricIds);
      }
    })

    values(metrics).forEach(metric => {
      if (!metric || metricIdsToOmit.includes(metric.id)) {
        return;
      }

      const ss = WidgetConfigUtils.getSliceSetFromMetricDefinition2(metric);

      if (isArray(ss)) {
        (ss as UserServiceFieldSliceSet[]).forEach(s => {
          const querySchema = WidgetConfigUtils.getMatchingQuerySchema(widgetQuerySchemas, metric.id,
            WidgetConfigUtils.getTagNamesFromSliceSet(s as UserServiceFieldSliceSet));
          const compareConfigs = Object.values((metric.operationalizeConfig?.compareConfigs || {}));
          const filtered = compareConfigs.filter(cc => WidgetConfigUtils.compareSliceSets(cc.usfSlices, s));
          records.push(constructMSR(metric, querySchema, s, filtered));
        });

      } else if (metric.sourceType === "expression") {
        const querySchema = widgetQuerySchemas.find(x => x.metricId === metric.id);
        const compareConfigs = Object.values((metric.operationalizeConfig?.compareConfigs || {}));
        records.push(constructMSR(metric, querySchema, null, compareConfigs));
        if (handleUnSavedExpSubMetrics) {
          const sliceSpecs = WidgetConfigUtils.extractSliceSpecsFromExpressionMetric(metric.expressionMetricConfig);
          uniqBy(sliceSpecs, (ss) => ss.metricId).forEach(sSpec => {
            const unSavedMetricDef = metrics[sSpec.metricId];
            const querySchema = widgetQuerySchemas.find(x => x.metricId === unSavedMetricDef.id);
            if (unSavedMetricDef.doNotSave && unSavedMetricDef.sourceType === "userServiceField") {
              const sliceSet = unSavedMetricDef.userServiceFieldMetricConfig.sliceSets.map((usfSlice) => convertToSliceSet(usfSlice));
              records.push(constructMSR(unSavedMetricDef, querySchema, null, [], sliceSet[0]));
            }
          });
        }
      } else if (metric.sourceType === "entity") {
        const compareConfigs = Object.values((metric.operationalizeConfig?.compareConfigs || {}));
        records.push(constructMSR(metric, widgetQuerySchemas[0], null, compareConfigs));
      } else {
        const querySchema = WidgetConfigUtils.getMatchingQuerySchema(widgetQuerySchemas, metric.id,
          WidgetConfigUtils.getTagNamesFromSliceSet(
            ss as SliceSet,
            WidgetConfigUtils.checkIfBizMetricisAggregated(metric)
          ));
        const compareConfigs = Object.values((metric.operationalizeConfig?.compareConfigs || {}));
        records.push(constructMSR(metric, querySchema, null, compareConfigs));
      }
    });
    return records;
  }


  static getCompareType(widgetConfig: WidgetConfigDTO, metricId: string): CompareConfigType | null {
    const metricDef: MetricDefinition = widgetConfig.dataDefinition.metrics[metricId];
    const compareConfig = getOpdCompareConfig(metricDef);
    if (compareConfig) {
      return compareConfig.type;
    }
    return null;
  }

  static getCompareTypeDisplayName(compareType: CompareConfigType): string {
    if (CompareConfigType.peer === compareType) {
      return "Peer comparision";
    }

    if (CompareConfigType.timeshift === compareType) {
      return "Time shift comparision";
    }

    if (CompareConfigType.baseline === compareType) {
      return "Baseline comparision";
    }

    return "Comparision";
  }

  static getMetricDefinition(widgetConfig: WidgetConfigDTO, metricId: string) {
    return widgetConfig?.dataDefinition.metrics[metricId];
  }

  static getTagNamesFromSliceSet(sliceSet: SliceSet | UserServiceFieldSliceSet, skipEntityTag = false) {
    const slices = (sliceSet.slices || []) as Array<(Slice | UserServiceFieldSlice)>;
    if (skipEntityTag) {
      return slices.map((x) => x.tagName).filter(x => x !== ENTITY_TAG)
        .sort();
    }
    return slices.map((x) => x.tagName).sort();
  }

  static getCompareConfig(widgetConfig: WidgetConfigDTO, metricId: string, compareId: string) {
    return this.getMetricDefinition(widgetConfig, metricId)?.operationalizeConfig?.compareConfigs?.[compareId];
  }

  static getActionConfig(widgetConfig: WidgetConfigDTO, metricId: string, compareId: string, actionId: string) {
    return this.getCompareConfig(widgetConfig, metricId, compareId)?.actionConfigs[actionId];
  }

  static checkIfSliceSetMatches(
    sliceSet: SliceSet | UserServiceFieldSliceSet,
    tagNames: string[],
    skipEntityTag = false,
    allowPartialMatch = false
  ) {
    let fTagNames = [];
    let sliceTagNames = [];
    if (skipEntityTag) {
      fTagNames = tagNames.filter((tag) => this.shouldIncludeTag(tag) && tag !== ENTITY_TAG).sort();
      sliceTagNames = (sliceSet.slices as any[])
        .map((s) => s.tagName).filter(x => x !== ENTITY_TAG)
        .sort();
    } else {
      fTagNames = tagNames.filter((tag) => this.shouldIncludeTag(tag)).sort();
      sliceTagNames = (sliceSet.slices as any[]).map((s) => s.tagName).sort();
    }

    if (allowPartialMatch) {
      const commonTags = intersection(sliceTagNames, fTagNames);
      return Boolean(commonTags.length);
    }

    return isEqual(sliceTagNames, fTagNames);
  }

  static getSliceSetTagsMatches(sliceSet: SliceSet | UserServiceFieldSliceSet, tagNames: string[]) {
    const fTagNames = tagNames.filter(tag => this.shouldIncludeTag(tag)).sort();
    const sliceTagNames = (sliceSet.slices as any[]).map(s => s.tagName).sort();
    const intersectionTagNames = intersection(sliceTagNames, fTagNames);
    return intersectionTagNames;
  }

  /**
   * Given two slice sets , Compare one with other based on tagName and give result
   * Does Safe Null checks for slice set being undefined or SliceSet being undefined
   * @param sliceSet1 first compare set
   * @param sliceSet2 second compare set
   */
  static compareSliceSets(
    sliceSet1: SliceSet | UserServiceFieldSliceSet,
    sliceSet2: SliceSet | UserServiceFieldSliceSet,
    skipEntityTag = false
  ): boolean {
    // Null check as a fail safe
    if (!sliceSet1 || !sliceSet1?.slices || !sliceSet2 || !sliceSet2?.slices) {
      return false;
    }
    return this.compareSlices(sliceSet1.slices, sliceSet2.slices, skipEntityTag);
  }

  /**
   * Given two slice sets , Compare one with other based on tagName and give result
   * Does Safe Null checks for slice set being undefined or Slices being undefined
   * @param slices1 first compare set
   * @param slices2 second compare set
   */
  static compareSlices(slices1: Array<Slice | UserServiceFieldSlice>, slices2: Array<Slice | UserServiceFieldSlice>, skipEntityTag = false): boolean {
    // Null check as a fail safe
    if (!slices1 || !slices2) {
      return false;
    }
    const sliceTagNames1 = this.getTagNamesFromSlices(slices1, skipEntityTag);
    const sliceTagNames2 = this.getTagNamesFromSlices(slices2, skipEntityTag);
    return isEqual(sliceTagNames1, sliceTagNames2);
  }

  static getTagNamesFromSlices(slices: Array<(Slice | UserServiceFieldSlice)>, skipEntityTag = false) {
    if (skipEntityTag) {
      return slices
        .map((x) => x.tagName)
        .filter((tag) => this.shouldIncludeTag(tag) && tag !== ENTITY_TAG)
        .sort();
    }
    return slices
      .map((x) => x.tagName)
      .filter((tag) => this.shouldIncludeTag(tag))
      .sort();
  }

  static shouldIncludeTag(tag: string) {
    // This will check for tags like _apptuit_** or _inception_**
    const isExcludeTag = shouldExcludeTag(tag);
    const isNameTag = tag === NAME_TAG;
    const isQuantileTag = tag === QUANTILE_TAG;
    return !isExcludeTag && !isNameTag && !isQuantileTag;
  }

  static getMatchingQuerySchema(
    querySchemas: WidgetQuerySchema[],
    metricId: string,
    sliceTagNames: string[],
    allowPartialMatch = false
  ) {
    let matchingSchema: WidgetQuerySchema;
    if (metricId) {
      const metricSchemas = querySchemas.filter(q => q.metricId === metricId);
      if (sliceTagNames) {
        const sliceSchemas = metricSchemas.filter(q => {
          const { sliceSet } = q;
          const sliceSetMatches = this.checkIfSliceSetMatches(sliceSet, sliceTagNames, false, allowPartialMatch);
          return sliceSetMatches;
        });
        matchingSchema = sliceSchemas[0];
      } else {
        matchingSchema = metricSchemas[0];
      }
    } else {
      matchingSchema = querySchemas[0];
    }
    return matchingSchema;
  }

  /**
   * This util returns all the metrics definitions that are to be shown in the UI in various places
   * like summary, operationalise. This util hides the aggregated system metrics that are added when
   * the user chooses to aggregate the entities away.
   */
  static getUserVisibleMetricDefs = (widgetConfig: WidgetConfigDTO) => {
    if (!widgetConfig) {
      return [];
    }

    const { dataDefinition } = widgetConfig;
    const { metrics = {} } = dataDefinition || {};
    const displayMetrics: MetricDefinition[] = [];
    Object.keys(metrics).forEach(metricId => {
      const mDef = metrics[metricId];
      const canDisplay = !mDef.doNotSave;
      if (canDisplay) {
        displayMetrics.push(mDef);
      }
    });

    return displayMetrics;
  };

  /**
   * This util returns all the metrics definitions that can be published.
   * This util hides the aggregated system metrics and biz metrics.
   * This is required to skip cohort agg metrics.
   */
  static getPublishableMetrics = (widgetConfig: WidgetConfigDTO) => {
    if (!widgetConfig) {
      return [];
    }

    const { dataDefinition } = widgetConfig;
    const { metrics = {} } = dataDefinition || {};
    const displayMetrics: MetricDefinition[] = [];
    Object.keys(metrics).forEach(metricId => {
      const mDef = metrics[metricId];
      const canDisplay = !mDef.doNotSave && !isSystemCreatedMetric(mDef) && mDef.sourceType !== "bizEntityMetric";
      if (canDisplay) {
        displayMetrics.push(mDef);
      }
    });

    return displayMetrics;
  };

  static getDisplaySlicesForSliceSet(ss: SliceSet | UserServiceFieldSliceSet) {
    const slices = (ss.slices || []) as Array<(Slice | UserServiceFieldSlice)>;
    return slices.filter(s => s.tagName !== ENTITY_TAG && !shouldExcludeTag(s.tagName));
  }

  static getMetricDefinitions(metricDefinition: MetricDefinition) {
    const usFieldMetricDef = metricDefinition as UserServiceFieldMetricConfigDefinition;
    const usMetricMetricDef = metricDefinition as UserServiceMetricConfigDefinition;
    const bizEntityMetricDef = metricDefinition as BizEntityMetricConfigDefinition;
    const expressionMetricDef = metricDefinition as ExpressionMetricConfigDefinition;
    const entityMetricDef = metricDefinition as EntityMetricConfigDefinition;

    return {
      usFieldMetricDef,
      usMetricMetricDef,
      bizEntityMetricDef,
      expressionMetricDef,
      entityMetricDef
    };
  }

  private static getSliceSetFromMetricDefinition2(metricDef: MetricDefinition): SliceSet | UserServiceFieldSliceSet[] {
    const {
      usFieldMetricDef,
      usMetricMetricDef,
      bizEntityMetricDef,
      expressionMetricDef,
      entityMetricDef
    } = this.getMetricDefinitions(metricDef);
    if (usFieldMetricDef?.userServiceFieldMetricConfig) {
      return usFieldMetricDef.userServiceFieldMetricConfig.sliceSets;
    } else if (usMetricMetricDef.userServiceMetricConfig) {
      return usMetricMetricDef.userServiceMetricConfig.sliceSet;
    } else if (bizEntityMetricDef.bizEntityMetricConfig) {
      return bizEntityMetricDef.bizEntityMetricConfig.sliceSet;
    } else if (expressionMetricDef.expressionMetricConfig) {
      return { slices: [] };
    } else if (entityMetricDef.entityMetricConfig) {
      return { slices: [] };
    } else {
      return { slices: [] };
    }
  }

  /**
   * @deprecated
   * Use getSliceSetFromMetricDefinition2
   */
  static getSliceSetFromMetricDefinition(metricDef: MetricDefinition): SliceSet | UserServiceFieldSliceSet {
    const {
      usFieldMetricDef,
      usMetricMetricDef,
      bizEntityMetricDef,
      expressionMetricDef
    } = this.getMetricDefinitions(metricDef);
    if (usFieldMetricDef?.userServiceFieldMetricConfig) {
      return usFieldMetricDef.userServiceFieldMetricConfig.sliceSets[0];
    } else if (usMetricMetricDef.userServiceMetricConfig) {
      return usMetricMetricDef.userServiceMetricConfig.sliceSet;
    } else if (bizEntityMetricDef.bizEntityMetricConfig) {
      return bizEntityMetricDef.bizEntityMetricConfig.sliceSet;
    } else if (expressionMetricDef.expressionMetricConfig) {
      return { slices: [] };
    } else {
      return { slices: [] };
    }
  }

  static getQuerySchema(metricDefs: Array<UserServiceFieldMetricConfigDefinition
    | BizEntityMetricConfigDefinition>, skipCohortMetrics: boolean): WidgetQuerySchema[] {

    const querySchemas: WidgetQuerySchema[] = [];

    const getQuerySchema = (id: string, name: string, sliceSet: SliceSet, aggregator: OverTimeAggregators): WidgetQuerySchema => ({
      metricId: id,
      metricName: name,
      sliceSet: sliceSet,
      resultKey: `_inception_${id}_ALL_${(this.getTagNamesFromSliceSet(sliceSet)).filter(s => s !== ENTITY_TAG)
        .join("_")}`,
      defaultTagAgg: "avg",
      defaultTimeAgg: aggregator
    });

    metricDefs.forEach(mDef => {
      if (mDef.sourceType === "userServiceField") {
        const sliceSets = mDef.userServiceFieldMetricConfig.sliceSets;
        (sliceSets || []).forEach(sliceSet => {
          querySchemas.push(getQuerySchema(
            mDef.id,
            mDef.name,
            convertToSliceSet(sliceSet),
            mDef.userServiceFieldMetricConfig.aggregator
          ));
        });
      }
      if (mDef.sourceType === "bizEntityMetric"
        && (mDef.labels.isAggMetric !== "true" || (!skipCohortMetrics && mDef.labels.isAggMetric === "true"))) {
        querySchemas.push(getQuerySchema(
          mDef.id,
          mDef.name,
          convertToSliceSet(mDef.bizEntityMetricConfig.sliceSet),
          mDef.bizEntityMetricConfig.aggregator
        ));
      }
    });
    return querySchemas;
  }

  static getLinkedDashboardsForCompareConfig(compareConfig: CompareConfig) {
    const { props } = compareConfig || {};
    const { linkedDashboards } = props || {};
    return linkedDashboards?.setValue?.values || [];
  }

  static updateLinkedDashboardsForCompareConfig(compareConfig: CompareConfig, linkedDashboards: string[]) {
    const { props } = compareConfig;
    const { linkedDashboards: linkedDashboardsProp } = props || {};
    if (linkedDashboardsProp) {
      linkedDashboardsProp.setValue.values = linkedDashboards;
    } else {
      const nProps = {
        linkedDashboards: {
          setValue: {
            values: linkedDashboards
          }
        }
      };
      if (!props) {
        compareConfig.props = {
          ...nProps
        };
      } else {
        compareConfig.props = {
          ...props,
          ...nProps
        };
      }
    }
  }

  static checkIfBizMetricisAggregated(metricDef: MetricDefinition) {
    if (metricDef.sourceType === "bizEntityMetric" && !isEmpty(metricDef.bizEntityMetricConfig.aggregator)) {
      return true;
    }
    return false;
  }

  static getEntityIdsFromFilterExpressions(filtersExpressions: UserServiceFilterExpression[]) {
    const entityIds: string[] = [];
    getEntityIdsFromFilterExpressions(filtersExpressions, entityIds);
    return entityIds;
  }

  static getEntityIdsFromFilterTree(filterTree: UserServiceFilterExpressionTree) {
    const entityIds: string[] = [];
    getEntityIdsFromExpressionTree(filterTree, entityIds);
    return entityIds;
  }

  static getUsFilterExpressionTreeLabel(
    filterTree: UserServiceFilterExpressionTree,
    entityLookUp: Record<string, string> = {},
    level = 0
  ): string {
    if (!filterTree) {
      return null;
    }

    const {
      filterNodes,
      logicalOperator
    } = filterTree;

    const nodeFilters = (filterNodes || []).map(node => {
      const {
        expression,
        expressionTree
      } = node;

      if (expression) {
        return this.getUsFilterExpressionLabel(expression, entityLookUp);
      }

      if (expressionTree) {
        return this.getUsFilterExpressionTreeLabel(expressionTree, entityLookUp, level + 1);
      }

      return '';
    });

    let currentFiltersStr = nodeFilters.filter(Boolean).join(` ${logicalOperator} `);
    currentFiltersStr = level > 0 ? `( ${currentFiltersStr} )` : currentFiltersStr;

    return currentFiltersStr;
  }

  static getUsFilterExpressionLabel(
    filterExpression: UserServiceFilterExpression,
    entityLookUp: Record<string, string> = {},
    valueOnly = false
  ) {
    const filterFieldName = FieldPickerUtils.getUserServiceFieldLabel(filterExpression.field);
    const filterOperator = filterExpression.operator;
    const filterFieldValue = filterExpression?.value?.replace(/['"]+/g, '');
    const filterFieldValues = filterExpression?.values?.map(x => x.replace(/['"]+/g, ''));
    const lookUpVal = entityLookUp[filterFieldValue] ? entityLookUp[filterFieldValue] : filterFieldValue;
    const lookUpVals = (filterFieldValues || []).map(x => entityLookUp[x] ? entityLookUp[x] : x);
    if (lookUpVals.length > 0) {
      return FieldPickerUtils.getLabelAndInfo(filterFieldName, filterOperator, lookUpVals)?.label;
    }

    return valueOnly ? `${lookUpVal || lookUpVals}`
      : `${filterFieldName} ${filterOperator} ${lookUpVal || lookUpVals}`;
  }

  private static getTagFilterExpressionLabel(tagFilter: TagFilter, entityLookUp?: Record<string, string>) {
    const tagVal = tagFilter.tagValue.replace(/['"]+/g, "");
    const tagVals = tagFilter.tagValues.map((tv) => tv.replace(/['"]+/g, ""));
    const lookUpVal = entityLookUp[tagVal] ? entityLookUp[tagVal] : tagVal;
    const lookUpVals = tagVals.map(x => entityLookUp[x] ? entityLookUp[x] : x);
    return `${tagFilter.tagName} ${tagFilter.op} ${lookUpVal || lookUpVals}`;
  }

  static getFilterExpressionFromMetricDef(metricDef: MetricDefinition, entityLookUp?: Record<string, string>) {
    let filterExprs: string[] = [];
    if (metricDef.sourceType === "userServiceField") {
      filterExprs = metricDef.userServiceFieldMetricConfig.filterExpressions.map(
        (filter) => this.getUsFilterExpressionLabel(filter, entityLookUp)
      );
    } else if (metricDef.sourceType === "bizEntityMetric") {
      filterExprs = metricDef.bizEntityMetricConfig.filter.map((f) =>
        this.getTagFilterExpressionLabel(f, entityLookUp)
      );
    } else if (metricDef.sourceType === "userServiceMetric") {
      filterExprs = metricDef.userServiceMetricConfig.filter.map((f) =>
        this.getTagFilterExpressionLabel(f, entityLookUp)
      );
    }
    return filterExprs;
  }

  static convertUSFieldSliceSetToTagSlice(sliceSet: UserServiceFieldSliceSet): SliceSet {
    return {
      slices: (sliceSet?.slices || []).map(slice => {
        const {
          tagName,
          userServiceField
        } = slice;

        const {
          fieldName,
          entityField
        } = userServiceField;

        const {
          entityType = ''
        } = entityField || {};

        const fieldType = this.getUSFieldDataType(userServiceField);
        return {
          tagName,
          fieldName,
          fieldType,
          entityTypeName: entityType
        };
      })
    };
  }

  static getUSFieldDataType(userServiceField: UserServiceField) {
    const {
      dataType,
      entityField
    } = userServiceField;

    const {
      propType,
      propName = ''
    } = entityField || {};

    const entityFieldPropType = !propName ? dataType : (propType || dataType) as DataType;
    const fieldType = entityField ? entityFieldPropType : dataType;

    return fieldType;
  }

  static getMaxSlicesSliceSetByMetricId(querySchema: WidgetQuerySchema[]) {
    const metricQuerySchemas: Record<string, WidgetQuerySchema> = {};
    querySchema.forEach(qsEntry => {
      const { metricId, sliceSet } = qsEntry;

      const prevSliceSet = metricQuerySchemas[metricId]?.sliceSet;
      if (prevSliceSet) {
        const prevNumSlices = prevSliceSet?.slices?.length || 0;
        const currNumSlices = sliceSet.slices.length;

        if (currNumSlices > prevNumSlices) {
          metricQuerySchemas[metricId] = qsEntry;
        }
      } else {
        metricQuerySchemas[metricId] = qsEntry;
      }
    });

    return metricQuerySchemas;
  }

  static getImplicitSlice(usField: UserServiceField): UserServiceFieldSlice {
    const entityUsField = cloneDeep(usField);

    if (!entityUsField.bizEntityFieldName) {
      entityUsField.fieldName = "userService";
    }

    return {
      tagName: ENTITY_TAG,
      userServiceField: {
        ...entityUsField,
        dataType: 'ENTITY'
      }
    };
  }

  static getUserServiceListFromUserServiceField(usField: UserServiceField): string[] {
    return usField.userServices.map(usf => usf.userServiceEntityId);
  }

  static getWidgetConfigDtoFromBizDataQuery(bizDataQuery: BizDataQuery) {
    const {
      id: widgetId,
      widgetConfig,
      buildingBlockConfig
    } = bizDataQuery;

    const widgetConfigDto = buildingBlockConfig ? this.getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig)
      : widgetConfig ? getDtoFromWidgetConfig(widgetConfig)
        : null;

    return {
      widgetConfigDto,
      widgetId
    };
  }

  static getWidgetConfigDtoFromBuildingBlockConfig(buildingBlockConfig: BuildingBlockConfig): WidgetConfigDTO {
    const {
      name,
      id,
      bizIdProps,
      buildingBlockDef
    } = buildingBlockConfig;

    const bizEntityType = bizIdProps?.primary?.bizEntityTypeId;
    const userServiceEntityId = bizEntityType ? null
      : bizIdProps?.primary?.eventTypes?.userServiceInfo?.[0]?.userServiceEntityId;

    const {
      aggregator,
      fieldConfig,
      filters,
      sliceDef
    } = buildingBlockDef;

    return {
      bizEntityType,
      userServiceEntityId,
      dataDefinition: {
        fields: {},
        metrics: {
          [id]: {
            id,
            name,
            sourceType: 'userServiceField',
            userServiceFieldMetricConfig: {
              aggregator,
              filterExpressions: filters?.filterExpressions || [],
              sliceSets: sliceDef?.sliceSets || [],
              userServiceField: fieldConfig?.userServiceField
            }
          }
        }
      },
      isStatic: false,
      name,
      visualizations: [],
    };
  }

  static getEntityTypeAndEventTypeFromIdProps = (idProps: BizIdProps) => {
    const { primary, secondary } = idProps || {};

    const {
      bizEntityTypeId,
      eventTypes
    } = primary || {};

    const { widgetId, cohortId } = secondary || {};

    return {
      entityTypeId: bizEntityTypeId,
      eventTypeId: eventTypes?.userServiceInfo?.[0]?.userServiceEntityId,
      widgetId,
      cohortId
    };
  };

  static getWidgetConfigClone(widgetConfig: WidgetConfigDTO): WidgetConfigDTO {
    const cloneWidgetConfig = cloneDeep(widgetConfig);

    const metrics = cloneWidgetConfig.dataDefinition.metrics;
    const oMetricIds = Object.keys(metrics);
    const nMetricIds = oMetricIds.map(() => generateId());

    const metricIdLookup = zipObject(oMetricIds, nMetricIds);
    oMetricIds.forEach(oMetricId => {
      const metric = metrics[oMetricId];
      delete metrics[oMetricId];

      const nMetricId = metricIdLookup[oMetricId];
      metric.id = nMetricId;

      if (metric.sourceType === 'expression') {
        modifyMetricIdsForExpressionMetricConfig(metric.expressionMetricConfig, metricIdLookup);
      }

      metrics[nMetricId] = metric;
    });

    let strLabels = JSON.stringify(widgetConfig.labels);
    oMetricIds.forEach(oMetricId => {
      const nMetricId = metricIdLookup[oMetricId];
      strLabels = strLabels.replaceAll(oMetricId, nMetricId);
    });

    cloneWidgetConfig.labels = JSON.parse(strLabels);

    cloneWidgetConfig.visualizations.forEach(viz => {
      viz.dataDefs.forEach(def => {
        def.id = metricIdLookup[def.id] || def.id;
      });
    });

    return cloneWidgetConfig;
  }

  static getVisibleMetricIds(metrics: DataDefinition['metrics']) {
    const visibleMetricIds: string[] = [];

    const metricIds = Object.keys(metrics);
    const extractedMetricIds: string[] = [];

    metricIds.forEach((metricId) => {
      const metric = metrics[metricId];

      if (metric?.sourceType === 'expression') {
        visibleMetricIds.push(metricId);

        const subMetricIds = getMetricIdsForExpressionMetric(metric.expressionMetricConfig);
        extractedMetricIds.push(...subMetricIds, metricId);
      }
    });

    metricIds.forEach((metricId) => {
      if (!extractedMetricIds.includes(metricId)) {
        visibleMetricIds.push(metricId);
      }
    });

    return visibleMetricIds;
  }

  static modifyUSFSliceSetForBizDataQuery(bizDataQuery: BizDataQuery, usfSliceSet: UserServiceFieldSliceSet) {
    if (bizDataQuery) {
      const { widgetConfig, buildingBlockConfig, sliceSpec } = bizDataQuery;
      const sliceSet = this.convertUSFieldSliceSetToTagSlice(usfSliceSet)

      let shouldModifySliceSpec = false;
      if (buildingBlockConfig && buildingBlockConfig.buildingBlockDef) {
        buildingBlockConfig.buildingBlockDef.sliceDef = {
          sliceSets: [usfSliceSet]
        }
        shouldModifySliceSpec = true;
      } else if (widgetConfig && sliceSpec?.metricId) {
        const metricToModify = widgetConfig.dataDefinition.metrics[sliceSpec.metricId];
        if (metricToModify) {
          if (metricToModify.sourceType === "userServiceField") {
            metricToModify.userServiceFieldMetricConfig.sliceSets = [usfSliceSet];
          } else if (metricToModify.sourceType === 'expression') {
            const sliceSpecsToModify = getMetricNodesForExpressionMetric(metricToModify.expressionMetricConfig);
            sliceSpecsToModify.forEach(sliceSpec => {
              sliceSpec.sliceSet = sliceSet;
              
              const childMetric = widgetConfig.dataDefinition.metrics[sliceSpec.metricId];
              if (childMetric?.sourceType === "userServiceField") {
                childMetric.userServiceFieldMetricConfig.sliceSets = [usfSliceSet];
              }
            })
          }
          shouldModifySliceSpec = true;
        }
      }

      if (shouldModifySliceSpec) {
        sliceSpec.sliceSet = sliceSet;
      }
    }
  }
}

export const getSelectorSpecFromTagFilters = (tagFilters: TagFilter[][]) => {
  const selectorSpec: SelectorSpec = {
    filters: []
  };

  tagFilters.forEach(tfArr => {
    const groups = groupBy(tfArr, tf => tf.fieldName);
    const tags: SelectorTag[] = [];

    forEach(groups, (tFs, fieldName) => {
      const tagValues = tFs.map(tf => tf.tagValue);
      tags.push({
        key: fieldName,
        value: tagValues
      });
    });

    selectorSpec.filters.push({
      tags
    });
  });

  return selectorSpec;
};

export const getWidgetTraceQueryLinks = (dataQueryConfig: Record<string, any>, widgetConfig: WidgetConfigDTO) => {
  if (dataQueryConfig?.aggregations) {
    const aggregations = dataQueryConfig.aggregations;
    const entityTypeName = widgetConfig.bizEntityType;
    const usEntityName = widgetConfig?.userServiceEntityName;
    const filterValue = dataQueryConfig.filters?.value.replace(/\\/g, '');
    const queryObjs: Record<string, any> = {};
    const queryLinks: IncSelectOption[] = [];
    const constructLink = (obj: Record<string, any>) => qs.stringify(obj);

    const constructAggregationColumn = (aggregationObj: Record<string, any>, aggregation: string): Record<string, any> => {
      const queryObj: Record<string, any> = {};
      const loopAggr = (obj: Record<string, any>, aggr: string) => {
        if (obj.fromField) {
          queryObj[aggr] = {
            column: obj.fromField
          };
        }
        if (obj.aggregations) {
          loopAggr(Object.values(obj.aggregations)[0], Object.keys(obj.aggregations)[0]);
        }
      };
      loopAggr(aggregationObj, aggregation);
      return queryObj;
    };

    const aggregationsKeys = Object.keys(aggregations);
    aggregationsKeys.forEach(aggr => {
      if (aggr === "i_entity") {
        queryObjs[entityTypeName] = {
          column: [aggregations[aggr].fromField]
        };
      } else if (aggr === "results") {
        queryObjs[usEntityName] = {
          column: [aggregations[aggr].field]
        };
      } else {
        const aggrQueryObj: Record<string, any> = constructAggregationColumn(aggregations[aggr], aggr);
        const queryKeys = [];
        const queryColumnValues = [];
        for (const [key, value] of Object.entries(aggrQueryObj)) {
          queryKeys.push(key === "i_entity" ? entityTypeName : key);
          queryColumnValues.push(value.column);
        }
        if (queryKeys.length) {
          queryObjs[queryKeys.join(", ")] = {
            column: queryColumnValues
          };
        }
      }
    });

    for (const [key, val] of Object.entries(queryObjs)) {
      queryLinks.push({
        label: key,
        value: constructLink({
          column: val.column,
          query: filterValue
        })
      });
    }
    return queryLinks;
  } else {
    return [];
  }
};

/** @deprecated
 * Utility to return a compare Config or first Compare config to support old UI need to be deleted when old UI is no loneger supported
 * @param metricDef Metric Def object can be null
 */

export function getOpdCompareConfig<MD extends MetricConfigDefinition>(metricDef: MD, compareConfigId?: string): CompareConfig {

  const compareConfigs = metricDef?.operationalizeConfig?.compareConfigs;
  let selectedCC: CompareConfig = null;
  if (!isEmpty(compareConfigs)) {
    if (compareConfigId) {
      selectedCC = compareConfigs[compareConfigId];
    }
    selectedCC = first(Object.values(compareConfigs));
  }
  return selectedCC;
}

export const getWidgetResponsesByUSField = (
  widgetResponses: WidgetResponseDTO[],
  usFieldWithMeta: UserServiceFieldWithMeta,
  userServiceId?: string
) => (widgetResponses || []).filter(wResponse => {
  const querySchemas = wResponse.querySchema.querySchema;
  const matchingQuerySchemas = getQuerySchemasByUSField(querySchemas, usFieldWithMeta, userServiceId);
  return matchingQuerySchemas.length > 0;
});

export const getWidgetResponsesByCohortMatch = (
  widgetResponses: WidgetResponseDTO[],
  cohortConfig: CohortConfig
): WidgetResponseDTO[] => widgetResponses.filter(wResponse => {
  const { cohortDefinition } = wResponse.widgetConfig;
  const configCohortId = cohortConfig?.cohortId || "";
  const widgetCohortId = cohortDefinition?.cohortId || "";
  return configCohortId === widgetCohortId;
});

export const getMetricWidgetResponses = (
  widgetResponses: WidgetResponseDTO[]
): WidgetResponseDTO[] => widgetResponses.filter((widgetResponse) => {
  const { metrics } = widgetResponse?.widgetConfig?.dataDefinition || {};
  return !isEmpty(metrics);
});

export const getQuerySchemasByUSField = (
  querySchemas: WidgetQuerySchema[],
  usFieldWithMeta: UserServiceFieldWithMeta,
  userServiceId?: string,
  skipSummaryMetrics?: boolean,
  compareBEFieldName?: boolean
) => {
  const matchingQuerySchemas: WidgetQuerySchema[] = [];
  querySchemas.forEach(qs => {
    const { sourceUserServiceField } = qs;
    const { userServiceField } = usFieldWithMeta;
    let usFieldsMatching = false;
    if (sourceUserServiceField == null) {
      const { componentSourceFields } = qs;
      forEach(componentSourceFields, (usf) => {
        usFieldsMatching = usFieldsMatching || compareUSFields(usf, userServiceField, userServiceId, compareBEFieldName);
      });
    } else {
      usFieldsMatching = compareUSFields(sourceUserServiceField, userServiceField, userServiceId, compareBEFieldName);
    }
    const skipMetric = skipSummaryMetrics && isSystemCreatedMetric(qs);
    if (usFieldsMatching && !skipMetric) {
      matchingQuerySchemas.push(qs);
    }
  });

  return matchingQuerySchemas;
};

export const getMetricIdsForExpressionMetric = (expressionMetricConfig: ExpressionMetricConfig) => {
  const metricIds = new Set<string>();
  extractMetricIdFromConfig(expressionMetricConfig, metricIds, null);

  return Array.from(metricIds);
};

export const getMetricNodesForExpressionMetric = (expressionMetricConfig: ExpressionMetricConfig) => {
  const sliceSpecs: SliceSpec[] = [];
  extractMetricIdFromConfig(expressionMetricConfig, null, sliceSpecs);

  return sliceSpecs;
}

const extractMetricIdFromConfig = (
  expressionMetricConfig: ExpressionMetricConfig,
  metricIdsSet: Set<string>,
  sliceSpecs: Array<SliceSpec>
): void => {
  const { expression, sliceSpec } = expressionMetricConfig;

  const metricId = sliceSpec?.metricId;
  if (metricId) {
    if (metricIdsSet) {
      metricIdsSet.add(metricId);
    }

    if (sliceSpecs) {
      sliceSpecs.push(sliceSpec);
    }
  }

  if (!expression) {
    return;
  }

  const { leftExpr, rightExpr } = expression;
  if (leftExpr?.expressionMetricConfig) {
    extractMetricIdFromConfig(leftExpr.expressionMetricConfig, metricIdsSet, sliceSpecs);
  }

  if (rightExpr?.expressionMetricConfig) {
    extractMetricIdFromConfig(rightExpr.expressionMetricConfig, metricIdsSet, sliceSpecs);
  }

  return;
};

export const modifyMetricIdsForExpressionMetricConfig = (expressionMetricConfig: ExpressionMetricConfig, metricIdLookup: Record<string, string>) => {
  const { expression, sliceSpec } = expressionMetricConfig;

  const metricId = sliceSpec?.metricId;
  if (metricId) {
    sliceSpec.metricId = metricIdLookup[metricId];
  }

  if (!expression) {
    return;
  }

  const { leftExpr, rightExpr } = expression;
  if (leftExpr?.expressionMetricConfig) {
    modifyMetricIdsForExpressionMetricConfig(leftExpr.expressionMetricConfig, metricIdLookup);
  }

  if (rightExpr?.expressionMetricConfig) {
    modifyMetricIdsForExpressionMetricConfig(rightExpr.expressionMetricConfig, metricIdLookup);
  }

  return;
};

export const compareUSFields = (usFieldA: UserServiceField, usFieldB: UserServiceField, usId?: string, compareBEFieldName = false) => {
  const shouldCompareUS = !isEmpty(usId);
  let usMatches = true;

  if (shouldCompareUS) {
    const usFieldAHasUS = isUserServiceIncludedInUSField(usFieldA, usId);
    const usFieldBHasUS = isUserServiceIncludedInUSField(usFieldB, usId);
    usMatches = usFieldAHasUS && usFieldBHasUS;
  }

  if (shouldCompareUS && !usMatches) {
    return false;
  }

  const omitUSFieldA = omit(usFieldA, omitProps);
  const omitUSFieldB = omit(usFieldB, omitProps);

  const usFieldsMatch = isEqual(omitUSFieldA, omitUSFieldB);

  if (usFieldsMatch && compareBEFieldName) {
    return usFieldA.bizEntityFieldName === usFieldB.bizEntityFieldName;
  }

  return usFieldsMatch;
};

export const isUserServiceIncludedInUSField = (usField: UserServiceField, usId: string) => {
  const { userServices = [], allUserService = false } = usField || {};

  if (allUserService) {
    return true;
  }

  const matchingUsIdx = userServices.findIndex(({ userServiceEntityId }) => userServiceEntityId === usId);
  return matchingUsIdx !== -1;
};

const omitProps: Array<keyof UserServiceField> = [
  "allUserService",
  "userServices",
  "bizEntityFieldName",
  "displayBizEntityFieldName"
];

const getEntityIdsFromFilterExpressions = (filtersExpressions: UserServiceFilterExpression[], entityIds: string[]) => {
  filtersExpressions.forEach((f) => {
    const dataType =
      f.field.entityField?.propType !== "NA"
        ? f.field.entityField?.propType
        : f.field.dataType;
    if (dataType === "ENTITY") {
      if (f.operator === "in") {
        entityIds.push(...(f.values || []));
      } else {
        entityIds.push(f.value);
      }
    }
  });
};

const getEntityIdsFromExpressionTree = (expressionTree: UserServiceFilterExpressionTree, entityIds: string[]) => {
  if (expressionTree) {
    const { filterNodes } = expressionTree;
    filterNodes?.forEach(filterNode => {
      const {
        expression,
        expressionTree
      } = filterNode;

      if (expression) {
        getEntityIdsFromFilterExpressions([expression], entityIds);
      }

      if (expressionTree) {
        getEntityIdsFromExpressionTree(expressionTree, entityIds);
      }
    });
  }

  return;
};

const constructMSR = (
  metric: MetricDefinition,
  querySchema: WidgetQuerySchema,
  usfSlices?: UserServiceFieldSliceSet,
  compareConfigs?: CompareConfig[],
  sliceSet?: SliceSet
): MetricAndSliceRecord => ({
  metricId: metric.id,
  metricSourceType: metric.sourceType,
  metricDefinition: metric,
  querySchema,
  usfSlices: usfSlices,
  sliceSet: querySchema?.sliceSet || sliceSet,
  isOperationalized: !isEmpty(compareConfigs),
  compareConfigs: compareConfigs || []
});