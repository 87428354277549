import entityLookupData from "../../../../../fakeData/mock_entities";

export const REQUEST_COUNT_MERCHANT_PAYMENT = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "requests_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1650346946000,
            "to": 1650348746000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_requests_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "d8886c72061f4f6d8a343dae1e0b741f",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      13,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      8,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      619,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e091078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      7,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      646,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3fb7008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      15,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      721,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003efc34040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      7,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      485,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393db30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      13,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      726,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353677018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      521,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      15,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      776,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350960078:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "entityLookupData": entityLookupData,
          "percentageChangeData": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      -42.10526315789473,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18.181818181818183,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -38.88888888888889,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -29.411764705882355,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12.5,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -33.33333333333333,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -10,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -14.285714285714285,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      20,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      33.33333333333333,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -17,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e091078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -46.15384615384615,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -23.074670571010249,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3fb7008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      72.72727272727273,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      60,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -21.052631578947366,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16.666666666666664,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003efc34040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -22.22222222222222,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -22.857142857142854,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393db30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -18.181818181818183,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -31.25,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9.090909090909092,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -20,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -14.285714285714285,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -11.11111111111111,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      55.55555555555556,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9.090909090909092,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -41.17647058823529,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -18.75,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      15.07674597083653108,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353677018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      31.25,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11.11111111111111,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      87.5,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10.078064012490242,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350960078:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "timeShiftedData": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      19,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      17,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      8,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      15,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      12,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1300,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e091078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      13,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1366,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3fb7008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003efc34040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393db30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      15,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      11,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      17,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1303,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353677018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      9,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      8,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1281,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350960078:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          }
        }
      }
    ]
  }
];
