import { cx } from 'emotion';
import React from 'react';
import { Col, ColProps, Container, Row, setConfiguration, RowProps, ContainerProps} from 'react-grid-system';

setConfiguration({
  gutterWidth: 16,
  maxScreenClass: 'xl'
});

// TODO: The ref is added here to overcome the type mismatch error related to it.
type IncContainerProps = ContainerProps & {
  ref?: any;
  skipPadding?: boolean;
};

type IncRowProps = RowProps & {
  ref?: any;
};

type IncColProps = ColProps & {
  ref?: any;
};

const IncContainer: React.FC<IncContainerProps> = ((props: IncContainerProps) => {
  const { skipPadding = false, style, className, children } = props;

  return (
    <Container
      className={skipPadding ? className : `inc-layout-container ${className}`}
      fluid
      style={style}
    >{children}</Container>
  );
});

const IncRow: React.FC<IncRowProps> = ((props: IncRowProps) => {

  const {
    className = ''
  } = props;

  const appliedClassName = cx(
    className,
    'inc-layout-row'
  );

  return <Row {...props} className={appliedClassName} />;
});

const IncCol: React.FC<IncColProps> = ((props: IncColProps) => {
  const {
    className = ''
  } = props;

  const appliedClassName = cx(
    className,
    'inc-layout-col'
  );
  return <Col {...props} className={appliedClassName} />;
});

export {
  IncContainer,
  IncCol,
  IncRow
};

