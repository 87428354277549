import React, { RefObject } from "react";
import { defaultsDeep } from "lodash";
import { cx } from "emotion";

type Layout = {
  noPadding?: boolean;
  noBorder?: boolean;
  transparent?: boolean;
};

export interface Props {
  children: React.ReactNode | React.ReactNode[];
  layout?: Layout;
  classNames?: string[];
  onClick?: () => void;
  onToggleFullScreen?: (fullScreenEnabled: boolean) => void;
  enableFullScreenMode?: boolean;
}

const noop = () => { };

const defaultLayout: Layout = {
  noPadding: false,
  noBorder: false,
  transparent: false,
};

const SimpleCard = React.forwardRef((props: Props, ref) => {

  const layout = defaultsDeep(props.layout, defaultLayout);
  const classes = props.classNames || [];

  const layoutClasses = [
    layout.noPadding ? "noPadding" : "",
    layout.noBorder ? "noBorder" : "",
    layout.transparent? "transparent" : "",
  ];

  return (
    <div className={cx(
      "inc-card-layout",
      ...layoutClasses,
      ...classes)
    } onClick={props.onClick || noop} ref={ref as RefObject<HTMLDivElement>}>
      { props.children}
    </div>
  );
});

export default React.memo(SimpleCard);
