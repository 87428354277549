import { Series } from "highcharts";
import { formatNumber } from "./NumberFormatter";

// export type NumberFormatterCallbackFunction = (number: number, decimals: number, decimalPoint?: string, thousandsSep?: string) => string;
export const HighChartsNumberFormatter = (num: number): string => formatNumber(num);

// export type SeriesLegendItemClickCallbackFunction = (this: Series, event: SeriesLegendItemClickEventObject) => void;
// TODO change the types to above when Highcharts is included in this package.
export const HighChartsLegendClick = function (this: Series, e: any) {
  let skipRedraw = false;

  const clickedSeriesIndex: number = this.index;
  const series = this.chart.series; // the type here should be Series.

  const selectedSeriesIndex: Set<number> = new Set();

  for (let i = 0; i < series.length; i++) {
    const serie = series[i];
    if (isSeriesVisible(serie)) {
      selectedSeriesIndex.add(i);
    }
    skipRedraw = skipRedraw || shouldSkipRedraw(serie);
  }

  const isCtrlCmdPressed = (e.browserEvent.metaKey || e.browserEvent.ctrlKey);

  // 1. Regular click behavior:
  if (!isCtrlCmdPressed) {

    // There is nothing selected and if we click on a series, show all the series
    if (selectedSeriesIndex.size === 0) {
      showAllSeries(series);
    } else if (selectedSeriesIndex.size === series.length) {
      hideAllSeries(series);
      showClickedSeries(series, clickedSeriesIndex);
    } else {
      // Not all series are visible.
      // If only 1 series is visible
      if (selectedSeriesIndex.size === 1) {
        // and that is one which was clicked again, revert back to showing all series
        if (selectedSeriesIndex.has(clickedSeriesIndex)) {
          showAllSeries(series);
        } else {
          // select the series and hide others
          hideAllSeries(series);
          showClickedSeries(series, clickedSeriesIndex);
        }
      } else {
        // select the series and hide others
        hideAllSeries(series);
        showClickedSeries(series, clickedSeriesIndex);
      }
    }
  } else {
    // 2.Cmd/Ctrl click behavior
    const serie = series[clickedSeriesIndex];
    const isVisible = isSeriesVisible(serie);
    if (!isVisible) {
      showClickedSeries(series, clickedSeriesIndex);
    } else {
      hideClickedSeries(series, clickedSeriesIndex);
    }
  }

  if (!skipRedraw) {
    this.chart.redraw();
  }

  // dont keep the regular highcharts behavior
  return false;
};

const showAllSeries = (series: any[]): void => {
  for (let i = 0; i < series.length; ++i) {
    const serie = series[i];
    setSerieVisibility(serie, true);
  }
};

const hideAllSeries = (series: any[]): void => {
  for (let i = 0; i < series.length; ++i) {
    const serie = series[i];
    setSerieVisibility(serie, false);
  }
};

const showClickedSeries = (series: any[], clickedSeriesIndex: number): void => {
  const serie = series[clickedSeriesIndex];
  setSerieVisibility(serie, true);
};

const hideClickedSeries = (series: any[], clickedSeriesIndex: number): void => {
  const serie = series[clickedSeriesIndex];
  setSerieVisibility(serie, false);
};

const setSerieVisibility = (serie: any, visible: boolean): void => {
  if (serie.type === 'packedbubble') {
    setPackedBubbleSerieVisibility(serie, visible);
  } else if (serie?.options?.custom?.visible) {
    serie.setVisible(visible, false);
  }
};

const setPackedBubbleSerieVisibility = (serie: any, visible: boolean): void => {
  const { options } = serie;
  const { custom = {} } = options;

  if (custom.visible) {
    if (custom.keepBubbleSizeConstant) {
      let aClassName: string,
        rClassName: string,
        laClassName: string,
        lrClassName: string,
        opacity: number;

      if (visible) {
        aClassName = 'highcharts-visible-default';
        rClassName = 'highcharts-invisible';
        laClassName = 'highcharts-visible';
        lrClassName = 'highcharts-partially-visible';
        opacity = 1;
      } else {
        aClassName = 'highcharts-invisible';
        rClassName = 'highcharts-visible-default';
        laClassName = 'highcharts-partially-visible';
        lrClassName = 'highcharts-visible';
        opacity = 0;
      }
      serie.opacity = opacity;
      const groupElement = (serie?.group?.element as HTMLElement);

      if (groupElement) {
        groupElement.classList.add(aClassName);
        groupElement.classList.remove(rClassName);
        serie.options.custom = {
          ...custom,
          visibleHInternal: visible
        };
      }

      const legendHiddenColor = serie?.chart?.legend?.itemHiddenStyle?.color;
      const legendElement = (serie?.legendItem?.element as HTMLElement);
      if (legendHiddenColor && legendElement) {
        legendElement.classList.add(laClassName);
        legendElement.classList.remove(lrClassName);
      }

      const serieClassName = `.highcharts-data-labels.highcharts-series-${serie.index}`;
      const chartContainer = serie?.chart?.container as HTMLElement;

      if (chartContainer) {
        const serieElements = chartContainer.querySelectorAll<HTMLElement>(serieClassName);
        serieElements.forEach((serieElement) => {
          serieElement.classList.add(aClassName);
          serieElement.classList.remove(rClassName);
        });
      }
    } else {
      serie.setVisible(visible, false);
      serie.options.custom = {
        ...(serie.options.custom || {}),
        visible
      };
    }
  }
};

const isSeriesVisible = (serie: Series): boolean => {
  if (serie.type === 'packedbubble') {
    const customOptions = serie.options.custom || {};
    return customOptions.visible && customOptions.visibleHInternal;
  } else {
    return serie.visible;
  }
};

const shouldSkipRedraw = (serie: Series): boolean => {
  if (serie.type === 'packedbubble') {
    const customOptions = serie.options.custom || {};
    return customOptions.keepBubbleSizeConstant;
  }
  return false;
};
