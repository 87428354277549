import { CancelToken } from "axios";
import { ResultTransformer } from "../../../datasources/prometheus/result_transformer";
import { BizService, BizEntityApiResult } from "../BizServiceCommon";
import {
  WidgetCompareDataRequest,
  WidgetDataRequest, 
  WidgetAdhocDataRequestV2,
  WidgetAdhocDataRequestV2Download,
} from "../types/exploreTypes";
import { UserPreference, UserPreferenceApiResponse } from "../types";

type SavedWidgetDataRequest = WidgetDataRequest | WidgetAdhocDataRequestV2 | WidgetAdhocDataRequestV2Download | WidgetCompareDataRequest;


class UserPreferenceApiService extends BizService {
  protected resultTransformer = new ResultTransformer();

  async getUserPreference(
    useCaseId?: string,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<UserPreference>> {
    await this.init();
    const subUrl = 'userpreference/read';
    const url = this.getBizEntityUrl(subUrl);
    const filter: Record<string,string[]> = {};
    if (useCaseId) {
      filter['useCaseIds'] = [useCaseId];
    }
    const result: BizEntityApiResult<UserPreference> = {
      data: null,
      error: false,
      message: "",
    };
    try {
      const response = await this.datasource.post<UserPreferenceApiResponse, unknown>(url, filter, { cancelToken });
      result.data = response.data.userPreference;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async saveUserPreference(
    userPreference: UserPreference,
    useCaseId?: string,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<UserPreference>> {
    await this.init();
    let subUrl = 'userpreference/save';
    if (useCaseId) {
      subUrl += `/usecase/${useCaseId}`;
    }
    const url = this.getBizEntityUrl(subUrl);
    const result: BizEntityApiResult<UserPreference> = {
      data: null,
      error: false,
      message: "",
    };

    try {
      const response = await this.datasource.post<UserPreferenceApiResponse, UserPreference>(url, userPreference, { cancelToken });
      if (response.data.statusCode !== 200) {
        this.handleError(response?.data?.responseInfo?.errors?.[0], result);
      } else {
        result.data = response.data.userPreference;
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async deleteUserPreference(
    useCaseId?: string,
    cancelToken?: CancelToken
  ): Promise<BizEntityApiResult<UserPreference>> {
    await this.init();
    const subUrl = 'userpreference/delete';
    const url = this.getBizEntityUrl(subUrl);
    const result: BizEntityApiResult<UserPreference> = {
      data: null,
      error: false,
      message: "",
    };
    const filter: Record<string, string[]> = {};
    if (useCaseId) {
      filter['useCaseIds'] = [useCaseId];
    }
    try {
      const response = await this.datasource.post<UserPreferenceApiResponse, unknown>(url, filter, { cancelToken });
      result.data = response.data.userPreference;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

}

export const userPreferenceApiService = new UserPreferenceApiService();
