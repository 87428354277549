import React from "react";
import { formatNumber } from "../../utils";
import { HorizontalBar } from "../charts/simple-bar/SingleHorizontalBar";
import { IncPercentRenderer } from "../ChangeRenderer";
import IncSmartText from "../SmartText/SmartText";
import TextWithInfoIcon from "./TextWithInfoIcon";

interface Props {
  name: string;
  helpText?: string;
  count: number;
  percent: number;
  barWidth: string;
  barBackgroundColor?: string;
  barColor?: string;
  barTarget?: string;
  direction?: "up" | "down" | "none";
}

const HorizontalBarWithInfo: React.FC<Props> = (props) => {
  const {
    name,
    count,
    barWidth,
    barBackgroundColor = "#F0F1F2",
    barColor,
    barTarget,
    helpText = "",
    percent,
    direction,
  } = props;


  return (
    <div className="horizontal-bar-info">
      <div className="inc-flex-row inc-flex-center-vertical">
        {helpText
          ? <TextWithInfoIcon
            helpText={helpText}
            text={name}
            textClass="info-text"
          />
          : <IncSmartText text={name} textClass="info-text" />
        }
        <div className="count-text">{formatNumber(count)}</div>
        <div className="percent-container">
          {typeof percent === 'number' && <IncPercentRenderer direction={direction} value={(percent)} />}
        </div>
        <div className="inc-flex-grow marginLt12" >
          <HorizontalBar
            backgroundColor={barBackgroundColor}
            barColor={barColor}
            barWidth={barWidth}
            size="xxs"
            target={barTarget}
          />
        </div>
      </div>
    </div>
  );
};

export default HorizontalBarWithInfo;
