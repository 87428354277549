import React, { FC, useCallback, useMemo } from 'react';
import cronParser from "cron-parser";
import { range } from 'lodash';
import { generateId } from '../../utils';
import { getFormattedDateTime } from '../DateTimeFormatter/DateTime';
import { IncDateTimeFormat } from '../DateTimeFormatter/types';
import Cron from './react-js-cron';
import { IncCronProps } from './types';


export const IncCronEditor: FC<IncCronProps> = (props) => {
  const {
    onError,
    setCronExpr,
    cronExpr,
    className: pClassName = '',
    clockFormat = '12-hour-clock',
    clearable = false,
    showFurtherOccurrances
  } = props;

  const uniqId = useMemo(() => generateId(), []);

  const computeFurtherOccurrences = useCallback(() => {
    const parsedExpr = cronParser.parseExpression(cronExpr);
    const nextOccurrances = range(0, 5).map((idx) => {
      const dateIso = parsedExpr.next().toISOString();
      const date = new Date(dateIso);
      const formattedDate = getFormattedDateTime(date, IncDateTimeFormat.full, { withSeconds: true });

      const key = `occurrence-${uniqId}-${dateIso}`;
      const displayValue = `${idx === 0 ? 'next' : 'then'} at ${formattedDate}`;

      return <div className="inc-text-subtext-medium inc-label-common marginTp8" key={key}>
        {displayValue}
      </div>;
    });

    return <div className="marginTp12 inc-cron-editor--occurrences">
      {nextOccurrances}
    </div>;
  }, [cronExpr, uniqId]);

  const className = useMemo(() => ['inc-cron-editor', pClassName].join(' '), [pClassName]);

  return <div className={className}>
    <Cron
      allowedPeriods={['year', 'month', 'week', 'day', 'hour', 'minute']}
      clearButton={clearable}
      clockFormat={clockFormat}
      humanizeLabels
      mode="multiple"
      onError={onError}
      setValue={setCronExpr}
      value={cronExpr}
    />

    {showFurtherOccurrances && computeFurtherOccurrences()}
  </div>;
};
