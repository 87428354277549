import entityLookupData, { usLookupData } from "../../../../../fakeData/mock_entities";

export const REQUEST_COUNT_MERCHANT = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "requests_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1650346089000,
            "to": 1650347889000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_requests_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "1548d45f9b414856b282146d69eda545",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      26,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      26,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1223,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1373,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1404,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1090,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1103,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1050,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1303,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      25,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      28,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1379,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "entityLookupData": entityLookupData,
          "percentageChangeData": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      -13.043478260869565,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -13.636363636363635,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      30,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      44.44444444444444,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      4.545454545454546,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -23.809523809523807,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -12,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      4.3478260869565215,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -11.11111111111111,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23.155522163786627,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -21.73913043478261,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14.8129079042784626,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -17.857142857142858,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      57.14285714285714,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21.428571428571427,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      10.526315789473683,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14.285714285714285,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -22.75,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -12.5,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -12,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      5.555555555555555,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      57.14285714285714,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -11.11111111111111,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -16.666666666666664,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22.857142857142854,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      35.294117647058826,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -18,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      29.411764705882355,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -18.110014104372356,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      4.166666666666666,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -4.3478260869565215,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      75,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16.9186134137151467,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "timeShiftedData": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      25,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      27,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1331,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1379,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      28,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      32,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      25,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      14,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      27,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      17,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      30,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      17,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1418,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1327,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          }
        }
      }
    ]
  }
];

export const REQUEST_COUNT_MERCHANT_US = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "requests_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1650346320000,
            "to": 1650348120000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_requests_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "56d649b9b24c40dc985a3e96df41ed67",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      619,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      480,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      217,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      646,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      401,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      357,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      539,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      162,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      349,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      862,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      223,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      138,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      485,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      152,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      453,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 {userService=\"i_userService:00000000-0000-0000-0000-0003921fa0c8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038ff9a0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003921fa0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      665,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      201,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      237,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 {userService=\"i_userService:00000000-0000-0000-0000-0003921fa0c8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a937020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003921fa0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      726,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      363,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      214,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393dac0c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      776,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      321,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      282,
                      1650346200000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
              ],
              "preLimitSelectionCount": 30
            }
          },
          "entityLookupData": {
            ...usLookupData,
            ...entityLookupData
          },
          "percentageChangeData": {},
          "timeShiftedData": {}
        }
      }
    ]
  }
];
