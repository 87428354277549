import entityLookupData, { usLookupData } from "../../../../../fakeData/mock_entities";

export const ERROR_COUNT_MERCHANT = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "errors_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1646179200000,
            "to": 1646182800000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_errors_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "hasError = true AND fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "cd57d52e8190473287f8d5ebdb5a643b",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "errors_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      402,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      153,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      371,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      299,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fef30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      328,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      480,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      201,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      167,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "entityLookupData": entityLookupData,
          "percentageChangeData": {
            "errors_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      -16,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -16,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -15,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      32,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -18,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -39,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fef30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      32,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      29,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -39,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      -23,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      null,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "timeShiftedData": {
            "errors_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0190a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0d7078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350945050:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fef30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39800c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      25,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          }
        }
      }
    ]
  }
];

export const ERROR_COUNT_MERCHANT_US = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "errors_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1646179200000,
            "to": 1646182800000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_errors_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "hasError = true AND fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "7a7ed0f251c2488bab7fee5cb4c79016",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "errors_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      124,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      181,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      97,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      45,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      87,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      98,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      135,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      138,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      98,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      109,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      121,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d990c8:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      86,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      124,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      89,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      86,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      64,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      51,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc73028:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      126,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      201,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      153,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      62,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 {userService=\"i_userService:00000000-0000-0000-0000-00036b6c40b8:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-00036b6c40b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      53,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 {userService=\"i_userService:00000000-0000-0000-0000-000382ed1058:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-000382ed1058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      52,
                      1646179200000
                    ]
                  ],
                  "refId": "errors_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 {userService=\"i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0\"}",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e0af078:0",
                    "userService": "i_userService:00000000-0000-0000-0000-0003b1e6c0c0:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "entityLookupData": {
            ...entityLookupData,
            ...usLookupData
          },
          "percentageChangeData": {},
          "timeShiftedData": {}
        }
      }
    ]
  }
];
