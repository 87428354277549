import { generateSSTimeseriesData, generateSingleStatData, generateInsightsData } from "./generators";

const dataDefId = "UOJrx1LkD";
const metricId = "T70iDQdeu";

const lookupMin = 130;
const lookupMax = 150;
const percentageChangeValues = [19, 15, -13, -11, -6, -4, 13, 12, 10, 6, 7, 11, -20, -14, -1, -3, -17, 9, 5, -7];

const dataRange: [number, number] = [41000000, 43000000];
const timeShiftRange: [number, number] = [43000000, 45000000];

export const ss1Data = [generateSingleStatData(
  dataDefId,
  metricId,
  1649656800000,
  42997626,
  44997626,
  -0.2866083434801176
)];

export const ssTs1Data = [generateSSTimeseriesData(
  dataDefId,
  metricId,
  dataRange,
  timeShiftRange
)];


export const ssEl1Data = [generateInsightsData(
  dataDefId,
  metricId,
  lookupMin,
  lookupMax,
  percentageChangeValues,
  timeShiftRange
)];
