import { ResponseInfo, UserServiceFieldSliceSet, UserServiceFilterList } from "../types";
import { EntityPropertyValue, PrimKind } from "../../../../core";
import { DrilldownTypes } from "../../triage-v2";
import { BizDataQuery } from "./biz-data-query";

export enum ToolType {
  DIMENSION_ANALYSIS = 'DIMENSION_ANALYSIS',
  CORRELATED_EVENT = 'CORRELATED_EVENT',
  GOOGLE_SEARCH = 'GOOGLE_SEARCH',
}

export interface WorkflowResponse {
  responseInfo: ResponseInfo;
  workflow: Workflow;
}

export interface AddDrillDownStepRequest {
  stepName: string;
  stepDescription: string;
  toolSpec: ToolSpec;
}

export interface UpdateDrillDownStepRequest {
  stepId: string;
  stepName: string;
  stepDescription: string;
  toolSpec: ToolSpec;
}

export interface Workflow {
  name: string;
  description: string;
  id: string;
  icon: string;
  labels: Record<string, string>;
  workflowDef: WorkflowDef;
}

export interface WorkflowList {
  workflowList: GenericWorkflow[];
}

export interface WorkflowDef {
  causeGraphWorkFlow?: CauseGraphWorkFlow;
  toolWorkFlow?: ToolWorkFlow;
  
}

export interface ToolWorkFlow {

}

export interface GenericWorkflow {

}

export interface CauseGraphWorkFlow {
   causeGraph: DrilldownTypes.CauseGraph;
}

export interface ToolSpec {
  dimensionalAnalysisToolSpec?: DimensionalAnalysisToolSpec;
  correlatedEventToolSpec?: CorrelatedEventToolSpec;
  googleSearchToolSpec?: GoogleSearchToolSpec;
}


export interface DimensionalAnalysisToolSpec {
  querySource: string;
  query: BizDataQuery;
  dimensions: UserServiceFieldSliceSet;
  paramValues: Record<string, EntityPropertyValue>;
}


export interface CorrelatedEventToolSpec {
  querySource: string;
  filters: UserServiceFilterList;
  paramValues:  Record<string, EntityPropertyValue>;
}


export interface GoogleSearchToolSpec {
  querySource: string;
  query: string;
  keywords: string[];
  paramValues:  Record<string, EntityPropertyValue>;
}

export interface ToolMetaDataList {
  tools: ToolMetaData[];
}

export interface ToolMetaData {
  name: string;
  description: string;
  paramDescription: string;
  icon: string;
  svgIcon: string;
  svgIconFilePath: string;
  params: ToolParam[];
  type: ToolType;
}

export interface ToolParam {
  param: string;
  primKind: PrimKind;
  description: string;
  paramDisplayName: string;
}