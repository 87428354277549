import { isUndefined } from "lodash";
import React, { forwardRef } from "react";
import Overlay from "react-overlays/Overlay";
import { IncPopperProps } from "./types";

const IncPopper = forwardRef<HTMLElement, IncPopperProps>((props, ref) => {
  const {
    children,
    className = '',
    placement = 'auto',
    anchorEl,
    show,
    overlay,
    container,
    offset: uOffset = {
      x: 10,
      y: 10
    }
  } = props;

  const popperClassname = `inc-popper ${className}`;
  const offset: [number, number] = [isUndefined(uOffset.x) ? 10 : uOffset.x, isUndefined(uOffset.y) ? 10 : uOffset.y];

  return <Overlay
    container={container}
    containerPadding={6}
    flip
    offset={offset}
    placement={placement}
    ref={ref}
    show={show}
    target={anchorEl}
  >
    {({ props, placement }) =>
      <>
        {overlay && <div className="inc-popper-overlay" />}
        <div
          {...props as any}
          className={popperClassname}
          // eslint-disable-next-line react/no-unknown-property
          placement={placement}
        >
          {children}
        </div>
      </>
    }
  </Overlay>;
});

export default IncPopper;
