import { BootConfigPayload } from "./types";

class MockUiApiService {

  fetchBootConfig(): BootConfigPayload {

    return bootConfigResponse as any;
  }
}

const mockUiApiService = new MockUiApiService();
export default mockUiApiService;


export const bootConfigResponse = {
  user: {
    id: 1000,
    email: "anomuser@bicycle.io",
    name: "anomuser",
    login: "anomuser@bicycle.io",
    theme: "",
    orgId: 1000,
    isGrafanaAdmin: false,
    isDisabled: false,
    isExternal: false,
    authLabels: [] as any[],
    updatedAt: "2022-10-03T11:54:13Z",
    createdAt: "2021-08-13T05:20:40Z",
    avatarUrl: "/apptuit/public/img/user_profile.png",
    isMockUser: true,
  },
  org: {
    id: 1000,
    name: "AnomUserOrg",
    address: {
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
    },
  },
  userOrgs: [
    {
      orgId: 1000,
      name: "AnomUserOrg",
      role: "Viewer",
    }
  ],
  preferences: {
    theme: "",
    homeDashboardId: 0,
    timezone: "",
  },
  authType: "password",
  isSupportLoginEnabled: false,
  isSupportSession: false,
  supportContext: null as any,
};
