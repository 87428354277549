import entityLookupData from "../../../../../fakeData/mock_entities";

const tableData = [
  {
    "metricResults": [
      {
        "dataDefinitionId": "requests_count_Merchant",
        "data": {},
        "compareConfigData": {},
        "entityLookupData": {},
        "dataQueryConfig": {
          "timeRange": {
            "from": 1650346089000,
            "to": 1650347889000
          },
          "aggregations": {
            "userService": {
              "fromField": "userService",
              "type": "term",
              "results": [
                {
                  "name": "_inception_requests_count_Merchant_ALL_",
                  "type": "METRICS",
                  "metricType": "count",
                  "params": {
                    "periodicitySecs": 60,
                    "includeSchema": true
                  }
                }
              ]
            }
          },
          "filters": {
            "type": "string",
            "value": "fields.Merchant$Merchant != null"
          },
          "timeoutMillis": 60000,
          "queryId": "1548d45f9b414856b282146d69eda545",
          "datasourceType": "events"
        },
        "postAggResult": {
          "data": {
            "requests_count_Merchant_userService": {
              "data": [
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033a961020:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033a961020:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033afff0a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033afff0a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      26,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0050a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00033b0050a0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1223,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00033b0190a0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509bd078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      23,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e069078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00034e069078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1303,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00034e0d7078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fc8c028:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1369,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350945050:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393da30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      19,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000350999078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350999078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003509d9078:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003509d9078:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000353669018:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000353669018:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc1e0a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc1e0a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      18,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00035cc390a8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00035cc390a8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00036bcbc0b8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00036bcbc0b8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1050,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d14058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d14058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000383d15058:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000383d15058:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003843f00e8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003843f00e8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038b128088:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038b128088:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe820c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe820c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      16,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fe9c0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fe9c0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1090,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00038fef30c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00038fef30c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393d1e0c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393d1e0c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-000393e240c8:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000393e240c8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1373,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cae40e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cae40e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb140e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb140e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      24,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-00039cb670e0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-00039cb670e0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      20,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a1924008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a1924008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1103,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003a3f38008:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003a3f38008:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      1404,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39800c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-000350f550f8:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      21,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b20c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b20c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b39b70c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b39b70c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003b3a4c0c0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      25,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003c34db0d0:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003c34db0d0:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      22,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21ac040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21ac040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      28,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f21d3040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f21d3040:0"
                  },
                  "metricName": ""
                },
                {
                  "datapoints": [
                    [
                      0,
                      1650344400000
                    ]
                  ],
                  "refId": "requests_count_Merchant_userService",
                  "target": " - Merchant:00000000-0000-0000-0000-0003f2200040:0 ",
                  "tags": {
                    "i_entity": "Merchant:00000000-0000-0000-0000-0003f2200040:0"
                  },
                  "metricName": ""
                }
              ],
              "preLimitSelectionCount": 35
            }
          },
          "entityLookupData": entityLookupData
        }
      }
    ]
  }
];

export const getUETableData = () => tableData;
