import appConfig from "../../../../appConfig";
import { request } from "../request";
import { isErrorResponse } from "../utils";
import { BizService, BizEntityApiResult } from "./BizServiceCommon";
import {
  UsageJourneyListResponse, UsageJourneyConfig, UsageJourneyDataPayload, UsageJourneyDataResponse,
  TimeObj, ExploreEntityFilter, SliceSpec, UserServiceFieldSliceSet, UserServiceFilterList, FunnelData
} from "./types";
import {
  FunnelDataResponse, JourneyFunnelDataRequest, FunnelDataRequest, FunnelResultSchemaResponse,
  FunnelResultSchema, FunnelResultSchemaRequest, FunnelQuerySchemaResponse, FunnelQuerySchema
} from "./types/journey-funnel";

class JourneyApiService extends BizService {
  private urlSuffix = '/schema/journey';

  async getJourneys(entityTypeId: string, cohortId?: string) {
    await this.init();
    let journeyURL = entityTypeId;
    if (cohortId) {
      journeyURL += `/cohort/${cohortId}`;
    }
    const url = this.getBizEntityUrl(`${journeyURL}${this.urlSuffix}`);
    const response = await this.datasource.get<UsageJourneyListResponse, null>(url);
    return response?.data?.journeyList;
  }

  async getJourneyConfig(entityTypeId: string, journeyId: string) {
    await this.init();
    const url = this.getBizEntityUrl(`${entityTypeId}${this.urlSuffix}/${journeyId}`);
    const response = await this.datasource.get<UsageJourneyConfig, null>(url);
    return response?.data;
  }

  async getJourneyData(entityTypeId: string, journeyId: string, startTime: number, endTime: number, payload: UsageJourneyDataPayload = {}) {
    await this.init();
    const url = this.getBizEntityUrl(`${entityTypeId}${this.urlSuffix}/${journeyId}/data?startTimeMillis=${startTime}&endTimeMillis=${endTime}`);
    const response = await this.datasource.post<UsageJourneyDataResponse, UsageJourneyDataPayload>(url, payload);
    return response?.data?.nodeData;
  }

  async getJourneyDataAdhocMode(entityTypeId: string, startTime: number, endTime: number, payload: UsageJourneyDataPayload) {
    await this.init();
    const url = this.getBizEntityUrl(`${entityTypeId}${this.urlSuffix}/data?startTimeMillis=${startTime}&endTimeMillis=${endTime}`);
    const response = await this.datasource.post<UsageJourneyDataResponse, UsageJourneyDataPayload>(url, payload);
    return response?.data?.nodeData;
  }

  async createJourneyConfig(entityTypeId: string, payload: UsageJourneyConfig) {
    await this.init();
    const url = this.getBizEntityUrl(`${entityTypeId}${this.urlSuffix}`);
    const response = await this.datasource.post<UsageJourneyListResponse, UsageJourneyConfig>(url, payload);
    return response?.data?.journeyList;
  }

  async getJourneyFunnelData(
    funnelId: string,
    compareTime: TimeObj,
    startTimeMillis: number,
    endTimeMillis: number,
    entityFilters: ExploreEntityFilter[] = []
  ) {
    await this.init();

    const result: BizEntityApiResult<FunnelData> = {
      data: null,
      error: false,
      message: ''
    };

    const payload: JourneyFunnelDataRequest = {
      compareTime,
      entityFilters
    };

    const params = {
      startTimeMillis,
      endTimeMillis
    };

    try {
      const url = this.getBizEntityUrl(`bizJourney/${funnelId}/funnel`);
      const {
        data,
        status,
        statusText
      } = await this.datasource.post<FunnelDataResponse, JourneyFunnelDataRequest>(url, payload, { params });

      if (isErrorResponse(status)) {
        result.error = true;
        result.message = statusText;
      } else {
        const {
          data: funnelData,
          responseInfo,
          statusCode
        } = data;

        if (isErrorResponse(statusCode)) {
          result.error = true;
          result.message = responseInfo.errors?.join(', ') || responseInfo.status || "Error fetching journey funnel data";
        } else {
          result.data = funnelData;
        }
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getFunnelData(
    funnelId: string,
    sliceSpec: SliceSpec[],
    compareTime: TimeObj,
    startTimeMillis: number,
    endTimeMillis: number,
    entityFilters: ExploreEntityFilter[] = [],
    eventFilters: UserServiceFilterList = { userServiceFilters: [] },
  ) {
    await this.init();

    const result: BizEntityApiResult<FunnelData> = {
      data: null,
      error: false,
      message: ''
    };

    const payload: FunnelDataRequest = {
      sliceSpec,
      eventFilters,
      entityFilters
    };

    compareTime && (payload.compareTime = compareTime);

    const params = {
      startTimeMillis,
      endTimeMillis
    };

    try {
      const url = this.getBizEntityUrl(`funnel/${funnelId}/data`);
      const {
        data,
        status,
        statusText
      } = await this.datasource.post<FunnelDataResponse, JourneyFunnelDataRequest>(url, payload, { params });

      if (isErrorResponse(status)) {
        result.error = true;
        result.message = statusText;
      } else {
        const {
          data: funnelData,
          responseInfo,
          statusCode
        } = data;

        if (isErrorResponse(statusCode)) {
          result.error = true;
          result.message = responseInfo.errors?.join(', ') || responseInfo.status || "Error fetching funnel data";
        } else {
          result.data = funnelData;
        }
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getFunnelResultSchema(
    funnelId: string,
    startTimeMillis: number,
    endTimeMillis: number,
    sliceSet: UserServiceFieldSliceSet
  ) {
    await this.init();

    const result: BizEntityApiResult<FunnelResultSchema> = {
      data: {
        entityLookupData: {},
        resultSchema: []
      },
      error: false,
      message: ''
    };

    const params = {
      startTimeMillis,
      endTimeMillis
    };

    try {
      const url = this.getBizEntityUrl(`funnel/${funnelId}/resultSchema`);
      const {
        data,
        status,
        statusText
      } = await this.datasource.post<FunnelResultSchemaResponse, FunnelResultSchemaRequest>(url, sliceSet, { params });

      if (isErrorResponse(status)) {
        result.error = true;
        result.message = statusText;
      } else {
        const {
          entityLookupData,
          resultSchema,
          responseInfo,
          statusCode
        } = data;

        if (isErrorResponse(statusCode)) {
          result.error = true;
          result.message = responseInfo.errors?.join(', ') || responseInfo.status || "Error fetching funnel result schema";
        } else {
          result.data = {
            entityLookupData,
            resultSchema
          };
        }
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getFunnelQuerySchema(funnelId: string) {
    await this.init();

    const result: BizEntityApiResult<FunnelQuerySchema> = {
      data: {
        funnelConfig: null
      },
      error: false,
      message: ''
    };

    const anomShareId = appConfig.anomShareId;
    try {
      const params = anomShareId ? `?shareId=${anomShareId}` : "";
      const url = this.getBizEntityUrl(`funnel/${funnelId}/querySchema${params}`);

      let data,
        status,
        statusText;
      if (anomShareId) {
        const apiUrl = `${appConfig.apiDomainUrl}${url}`;
        const { data: eData, status: eStatus, statusText: eStatusText } =
          await request.get<FunnelQuerySchemaResponse>(apiUrl);
        data = eData;
        status = eStatus;
        statusText = eStatusText;
      } else {
        const { data: eData, status: eStatus, statusText: eStatusText } =
          await this.datasource.get<FunnelQuerySchemaResponse, unknown>(url);
        data = eData;
        status = eStatus;
        statusText = eStatusText;
      }

      if (isErrorResponse(status)) {
        result.error = true;
        result.message = statusText;
      } else {
        const {
          funnelConfig,
          responseInfo,
          statusCode
        } = data;

        if (isErrorResponse(statusCode)) {
          result.error = true;
          result.message = responseInfo.errors?.join(', ') || responseInfo.status || "Error fetching funnel query schema";
        } else {
          result.data = {
            funnelConfig
          };
        }
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }
}

const journeyApiService = new JourneyApiService();

export { journeyApiService };
