import { TimeObj, Slice, UserServiceField, UserServiceFieldSliceSet, SliceSet, BizFieldWithMetaList, BizDataQuery} from "../../explore";

export enum OpThresholdSimpleFunction {
  NA = "NA",
  AVG = "AVG",
  MIN = "MIN",
  MAX = "MAX"
}

export enum OpThresholdSeasonality {
  NONE = 'NONE',
  HOURLY = "HOURLY",
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export interface LookBackThreshold {
  rollingFreq: TimeObj;

  // oneOf
  trueLookBack?: boolean;
  downSampleFunction?: ThresholdDownSampleFunc;
}

export interface ThresholdDownSampleFunc {
  // oneOf
  simpleFunction?: OpThresholdSimpleFunction;
  quantile?: string;
  linearRegression?: boolean;
  simpleMovingAvg?: boolean;
  exponentialWeightedMovingAvg?: boolean;

  params?: Record<string, string>;
}

export interface PeriodicOffsetThreshold {
  baselineDataConfig: BaselineDataConfig;
  downSampleFunction: ThresholdDownSampleFunc;
}

export interface BaselineDataConfig {
  // oneOf
  seasonalDataConfig?: BaselineSeasonalDataConfig;
  nonSeasonalDataConfig?: TimeObj;
}

export interface BaselineSeasonalDataConfig {
  numPeriods: number;
  seasonality: OpThresholdSeasonality;
}

export interface StaticThresholdDef {
  scalar?: number;
  currentQuery?: boolean;
  dataQueryInfo?: BizDataQuery;

  thresholdOp: ThresholdOperation;
  peerThreshold: PeerThreshold; // Peergroup compare

  // oneOf
  lookBackDef?: LookBackThreshold; // non-seasonal
  periodicDef?: PeriodicOffsetThreshold; // seasonal

  /**
   * @deprecated
   */
  offsetThreshold: OffsetThreshold; // Timeshift compare

  /**
   * @deprecated
   */
  rollingFunction: string;

  /**
   * @deprecated
   */
  rollingFreq: TimeObj;
}

export interface OffsetThreshold {
  offset: TimeObj;
}

export interface PeerThreshold {
  peerOfSlice?: Slice;
  peerOfField?: UserServiceField;

  usFieldSlice?: UserServiceFieldSliceSet;
  sliceSet?: SliceSet;
  bizFieldSlice?: BizFieldWithMetaList;

  peerAgg: string;
}

export enum ThresholdType {
  NA = "NA",
  AUTO = "AUTO",
  STATIC = "STATIC"
}

export interface OpThreshold {
  thresholdType: ThresholdType;
  aboveThresholdDef: StaticThresholdDef;
  belowThresholdDef: StaticThresholdDef;
  comparator: CompareOperator;
  labels?: Record<string, string>;
}

export enum CompareOperator {
  None = "None",
  Above = "Above",
  Below = "Below",
  AboveOrBelow = "AboveOrBelow"
}

export enum ThresholdOpType {
  NA = "NA",
  DELTA = "DELTA",
  PERCENTAGE = "PERCENTAGE",
  NUM_STD_DEVS = "NUM_STD_DEVS"
}

export interface ThresholdOperation {
  opType: ThresholdOpType;
  value: number;
}