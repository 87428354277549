import { ss1Data, ssTs1Data, ssEl1Data } from "./stat-1";
import { ss2Data, ssTs2Data, ssEl2Data } from "./stat-2";
import { ss3Data, ssTs3Data, ssEl3Data } from "./stat-3";
import { ss4Data, ssTs4Data, ssEl4Data } from "./stat-4";
import { ss5Data, ssTs5Data, ssEl5Data } from "./stat-5";

const ssData: Record<string, any> = {
  "ECS-single-stat-1": ss1Data,
  "ECS-single-stat-2": ss2Data,
  "ECS-single-stat-3": ss3Data,
  "ECS-single-stat-4": ss4Data,
  "ECS-single-stat-5": ss5Data,
  "ECS-timeseries-1": ssTs1Data,
  "ECS-timeseries-2": ssTs2Data,
  "ECS-timeseries-3": ssTs3Data,
  "ECS-timeseries-4": ssTs4Data,
  "ECS-timeseries-5": ssTs5Data,
  "ECS-entity-list-1": ssEl1Data,
  "ECS-entity-list-2": ssEl2Data,
  "ECS-entity-list-3": ssEl3Data,
  "ECS-entity-list-4": ssEl4Data,
  "ECS-entity-list-5": ssEl5Data
};

export const getECSMockData = (id: string) => (ssData[id] || ss1Data)[0].data;
