import Axios, { CancelToken, CancelTokenSource } from "axios"
import { useCallback, useMemo, useRef } from "react"
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core"
import { ResponseStatus } from "../../explore"
import { Feed } from "../types"
import { useCaseApiService } from "../UseCaseApiService"

type Result = Record<string, Feed[]>;

export const useFetchUseCaseFeed = (useCaseIds: string[], lazy = false) => {
  const initialState = useMemo<InitialState<Result, string>>(() => ({
    data: {},
    error: '',
    isError: false,
    isFetching: !lazy,
    isSuccess: false
  }), [])

  const cancelTokenRef = useRef<CancelTokenSource>();

  const fetchFn = useCallback<FetchFn<Result, string>>(async () => {
    cancelTokenRef.current?.cancel();
    cancelTokenRef.current = Axios.CancelToken.source();

    const result: FetchCallBackResult<Result, string> = {
      data: {},
      error: '',
      isError: false,
      isSuccess: false
    }

    try {
      const errors: string[] = [];

      const {
        data,
        error,
        message,
        cancelled
      } = await useCaseApiService.getFeedForUseCases(useCaseIds, cancelTokenRef.current.token);

      if (!cancelled) {
        if (error) {
          result.error = message;
          result.isError = true;
        } else {
          Object.keys(data?.feedResponses || {}).forEach(useCaseId => {
            const {
              responseInfo,
              feedList
            } = data?.feedResponses?.[useCaseId];

            if (responseInfo?.status === ResponseStatus.FAILED || !feedList) {
              errors.push(responseInfo.errors?.join(", ") || `Error fetching feed for Copilot ${useCaseId}`)
            } else {
              result.data[useCaseId] = feedList;
            }
          })

          result.isError = errors.length > 0;
          result.isSuccess = errors.length === 0;
          result.error = errors.join(', ');
        }
      } else { 
        result.isCancelled = true;
      }
    } catch (err) {
      result.isError = true;
      result.error = err.message || String(err);
    }

    return result;
  }, [useCaseIds])

  return useDataFetch(fetchFn, initialState, lazy);
}