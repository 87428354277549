import ssEntityLookupData from "../../../../../../fakeData/mock_entities";
import { generateMetricKey } from "./common";

export const generateSingleStatData = (
  dataDefId: string,
  metricId: string,
  timestamp: number,
  postAgg: number,
  timeshift: number,
  percentageChange: number
) => {
  const metricKey = generateMetricKey(dataDefId, metricId);
  return {
    "data": [
      {
        "metricResults": [
          {
            "dataDefinitionId": `${dataDefId}`,
            "data": {},
            "compareConfigData": {},
            "entityLookupData": {},
            "dataQueryConfig": {},
            "warnings": [] as any[],
            "postAggResult": {
              "data": {
                [metricKey]: {
                  "data": [
                    {
                      "datapoints": [[
                        postAgg,
                        timestamp
                      ]],
                      "refId": metricKey,
                      "target": " ",
                      "tags": {},
                      "metricName": "",
                      "eTags": {}
                    }
                  ],
                  "schema": [
                    {}
                  ],
                  "preLimitSelectionCount": 1
                }
              },
              "entityLookupData": ssEntityLookupData,
              "percentageChangeData": {
                [metricKey]: {
                  "data": [
                    {
                      "datapoints": [[
                        percentageChange,
                        timestamp
                      ]],
                      "refId": metricKey,
                      "target": " ",
                      "tags": {},
                      "metricName": "",
                      "eTags": {}
                    }
                  ],
                  "schema": [
                    {}
                  ],
                  "preLimitSelectionCount": 1
                }
              },
              "timeShiftedData": {
                [metricKey]: {
                  "data": [
                    {
                      "datapoints": [[
                        timeshift,
                        timestamp
                      ]],
                      "refId": metricKey,
                      "target": " ",
                      "tags": {},
                      "metricName": "",
                      "eTags": {}
                    }
                  ],
                  "schema": [
                    {}
                  ],
                  "preLimitSelectionCount": 1
                }
              }
            }
          }
        ]
      }
    ],
      "entityContext": { }
  }
};