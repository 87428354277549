import React, { useCallback, ReactNode, useState, useMemo, ReactElement, FC } from 'react';
import { IncClickAway, IncPopper, Card, IncRow, IncCol } from '@inception/ui';
import { generateId } from '../../core';
import LoadingSpinner from '../Loading/Loading';
import { EntityDetails } from './types';
import { EntityNameRenderer } from './EntityNameRenderer';

export type EntityPropertiesProps = {
  entityDetails: EntityDetails;
  extSections?: Map<string, Map<string, string>>;
  children?: ReactNode;
  delay?: number; // delay the popover display
};

const DELAY = 500;
let timeout: number | null = null;

// to add async on this, replace the card with EntityProperties from entities/components and make properties optional
export const EntityPropertiesPopover: FC<EntityPropertiesProps> = ((props: EntityPropertiesProps) => {
  const {
    entityDetails,
    children,
    delay = DELAY,
    extSections
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const onMouseEnter = useCallback((target: HTMLDivElement) => {
    setAnchorEl(target);
    timeout = null;
  }, []);

  const delayedMouseEnter = useCallback((target: HTMLDivElement) => {
    timeout = window.setTimeout(() => onMouseEnter(target), delay);
  }, [onMouseEnter, delay]);

  const handleMouseLeave = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    } else {
      setAnchorEl(null);
    }
  }, []);

  const open = Boolean(anchorEl);

  return (
    <div onMouseEnter={(elm) => delayedMouseEnter(elm.currentTarget)} onMouseLeave={handleMouseLeave}>
      {children}
      <IncClickAway
        onClickAway={handleMouseLeave}
      >
        {(ref) => <IncPopper
          anchorEl={anchorEl}
          offset={{
            x: 0,
            y: 0
          }}
          placement="bottom-start"
          ref={ref}
          show={open}
        >
          <Card>
            <EntityPropertiesPopoverContent entityDetails={entityDetails} extSections={extSections} />
          </Card>
        </IncPopper>
        }
      </IncClickAway>
    </div>
  );
});

interface CProps {
  entityDetails: EntityDetails;
  extSections?: Map<string, Map<string, string>>;
}

export const EntityPropertiesPopoverContent: FC<CProps> = (props) => {
  const { entityDetails, extSections } = props;

  const sections = useMemo(() => {
    let sections = new Map<string, Map<string, string>>();

    if (entityDetails?.properties?.length) {
      const propPairs = entityDetails.properties.map(prop => [prop.name, prop.value]) as Array<[string, string]>;
      const propRec = new Map(propPairs);
      sections.set("Properties", propRec);
    }

    if (extSections) {
      sections = new Map([...sections, ...extSections]);
    }

    return sections;
  }, [entityDetails, extSections]);

  const getPropertiesComponents = useCallback((propsMap: Map<string, string>, parentKey: string) => {
    const propertiesCmpts: ReactElement[] = [];
    propsMap.forEach((pValue, pKey) => {
      const key = `${parentKey}-${pKey}`;
      propertiesCmpts.push(
        <IncRow className='name-value-row' key={key}>
          <IncCol className='name' md={6} title={pKey}>
            {pKey}
          </IncCol>
          <IncCol className='value' md={6} title={pValue}>
            {pValue}
          </IncCol>
        </IncRow>
      );
    });
    return propertiesCmpts;
  }, []);

  const sectionCmpts = useMemo(() => {
    const sectionCmpts: ReactElement[] = [];
    sections.forEach((sectionDetails, sectionName) => {
      const key = `${sectionName}-${generateId()}`;
      const propertiesCmpts = getPropertiesComponents(sectionDetails, sectionName);
      sectionCmpts.push(<div className="inc-flex-column marginTp6" key={key}>
        <div className="inc-text-body-medium marginBt4">{sectionName}</div>
        <div className="inc-flex-column">
          {propertiesCmpts}
        </div>
      </div>);
    });
    return sectionCmpts;
  }, [getPropertiesComponents, sections]);

  const loadingProperties = Boolean(!entityDetails);

  return loadingProperties ?
    <LoadingSpinner titleId="common.actions.loading.data.text" />
    :
    <div className="entity-properties-popover">
      <div className="inc-text-body-medium inc-flex-row inc-flex-center-vertical">
        <EntityNameRenderer entityDetails={entityDetails} />
      </div>
      <br />
      {sectionCmpts}
    </div>;
};

