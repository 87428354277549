import { useCallback, useMemo } from "react";
import { FetchCallBackResult, InitialState, useDataFetch } from "../../../../core";
import { UseCaseCatalog, UseCaseCatalogWithSchema, UseCaseSummary, UseCaseSummaryWithSchema } from "../types";
import { useCaseApiService } from "../UseCaseApiService";
import { useCaseCatalogApiService } from "../UseCaseCatalogApiService";

interface Props {
  verticalId: string;
  subVerticalId: string;
  companyName: string;

  sanitizeConfigs?: boolean;
  filterOutDraftOpInsights?: boolean;
}

export const useFetchUseCaseCatalogWithSchema = (props: Props) => {
  const {
    verticalId,
    subVerticalId,
    companyName,
    filterOutDraftOpInsights = false,
    sanitizeConfigs = false
  } = props;

  const initialData = useMemo<InitialState<UseCaseSummaryWithSchema[], string>>(() => ({
    data: [],
    error: null,
    isError: false,
    isFetching: true,
    isSuccess: false,
  }), []);

  const fetchSuggestedUsecases = useCallback(async () => {
    const result: FetchCallBackResult<UseCaseSummaryWithSchema[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message
      } = await useCaseCatalogApiService.getUseCaseCatalogConfigWithSchema(
        verticalId,
        subVerticalId,
        companyName,
        sanitizeConfigs,
        filterOutDraftOpInsights
      );

      if (error) {
        result.error = message;
        result.isError = true;
      } else {
        const useCases = (data.catalogs || []).reduce((useCases: UseCaseSummaryWithSchema[], catalog: UseCaseCatalogWithSchema) => [
          ...useCases,
          ...catalog.useCases
        ], []);
        
        result.data = useCases.sort((a, b) => a.name.localeCompare(b.name));
        result.isError = error;
        result.isSuccess = !result.isError;
        result.error = message;
      }
    } catch (e) {
      result.error = e;
      result.isError = true;
    }
    return result;
  }, [companyName, filterOutDraftOpInsights, sanitizeConfigs, subVerticalId, verticalId]);

  return useDataFetch(fetchSuggestedUsecases, initialData, true);
};
