import { getNumericDataConfiguration } from "../numeric/constants";
import { getCurrencyFormatter, CurrencyType } from "../../../../utils";
import { CurrencyConfiguration } from "./types";

export const currencyTypeVsConfig: Record<CurrencyType, CurrencyConfiguration> = {
  USD: {
    ...(getNumericDataConfiguration("en-US")),
    symbol: "$",
    regex: new RegExp(/^(\$*[0-9]*\.?[0-9]+)(M|m|B|b|K|k|T|t)?$/),
    formatter: getCurrencyFormatter("USD", true)
  },
  INR: {
    ...(getNumericDataConfiguration("en-IN")),
    symbol: "₹",
    quickValues: ["1L", "5L", "10L", "1Cr", "5Cr"],
    regex: new RegExp(/^(₹*[0-9]*\.?[0-9]+)(L|l|Cr|cr|T|t)?$/),
    formatter: getCurrencyFormatter("INR", true)
  }
};
