import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import {  WidgetAlertSummaryRequest2, WidgetAlertsSummaryResponseV3, TriageClustersResponse } from '../types';
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from '../../../../core';
import alertApiService from '../AlertApiService';

type Response = WidgetAlertsSummaryResponseV3 | TriageClustersResponse;

export const useFetchAlertsSummary = (
  request: WidgetAlertSummaryRequest2,
  startTimeMillis: number,
  endTimeMillis: number,
  generateDemoData?: boolean,
  fetchClusters?: boolean,
  prefetchedData?: Response
) => {
  const initialState = useMemo<InitialState<Response, string>>(() => ({
    data: prefetchedData,
    error: null,
    isError: false,
    isFetching: !prefetchedData
  }), [prefetchedData]);

  const fetchFn = useCallback<FetchFn<Response, string>>(async () => {
    const result: FetchCallBackResult<Response, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const {
        data,
        error,
        message,
      } = await alertApiService.getAlertSummaryV3ByRequest(request, startTimeMillis, endTimeMillis, fetchClusters, generateDemoData);

      if (error) {
        result.isError = true;
        result.error = message;
      } else {
        result.isSuccess = true;
        result.data = data;
      }

    } catch (err) {
      result.isError = true;
      const axiosError = (err as AxiosError);
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, []);

  return useDataFetch(fetchFn, initialState, Boolean(prefetchedData));
};
