import { createAction } from "@reduxjs/toolkit";
import { TitleState } from "./types";

const defaultTitleState: TitleState = {
  title: '',
  url: '',
  titleElement: null,
  hideUseCaseSelector: false
};

const setTitlePayload = createAction<TitleState>('set-title-payload');

const titleReducer = (state = defaultTitleState, { type, payload }: any) => {
  switch (type) {
    case 'set-title-payload':
      return {
        ...state,
        ...defaultTitleState,
        ...payload
      };
    default:
      return {
        ...state
      };
  }

};

export {
  titleReducer,
  setTitlePayload
};
