import { EntityAggregationSuggestionResponse } from "../../types";

export const suggestionsResponse: EntityAggregationSuggestionResponse = {
  "entityType": "Merchant",
  "suggestedAggregations": {
    "Annual_Revenue": {
      "kind": "_long",
      "field": "Annual_Revenue",
      "aggregationMeta": {
        "stats": {
          "count": "160",
          "min": 1.0012308E7,
          "max": 7.8494868E7,
          "avg": 4.46626596125E7,
          "sum": 7.146025538E9
        }
      },
      "suggestedAggregationOperator": {
        "histogram": {
        }
      }
    },
    "Country": {
      "kind": "_str",
      "field": "Country",
      "aggregationMeta": {
        "cardinality": {
          "value": "5",
          "ratio": 0.03125
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "i__missing_rels": {
      "kind": "_set",
      "field": "i__missing_rels",
      "description": "Field i__missing_rels is of kind _set, cardinality request not supported",
      "suggestedAggregationOperator": null,
      "aggregationMeta": null
    },
    "Industry": {
      "kind": "_str",
      "field": "Industry",
      "aggregationMeta": {
        "cardinality": {
          "value": "7",
          "ratio": 0.04375
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "Name": {
      "kind": "_str",
      "field": "Name",
      "aggregationMeta": {
        "cardinality": {
          "value": "160",
          "ratio": 1.0
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "Renewal_Date": {
      "kind": "_str",
      "field": "Renewal_Date",
      "aggregationMeta": {
        "cardinality": {
          "value": "15",
          "ratio": 0.09375
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "SignupDate": {
      "kind": "_date",
      "field": "SignupDate",
      "description": "dateHistogram",
      "aggregationMeta": {
        "cardinality": {
          "value": "2",
          "ratio": 0.0125
        }
      },
      "suggestedAggregationOperator": {
        "dateHistogram": {
        }
      }
    },
    "Subscription_Type": {
      "kind": "_str",
      "field": "Subscription_Type",
      "aggregationMeta": {
        "cardinality": {
          "value": "1",
          "ratio": 0.00625
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "Upsellopp": {
      "kind": "_str",
      "field": "Upsellopp",
      "aggregationMeta": {
        "cardinality": {
          "value": "2",
          "ratio": 0.0125
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "Id": {
      "kind": "_str",
      "field": "Id",
      "aggregationMeta": {
        "cardinality": {
          "value": "160",
          "ratio": 1.0
        }
      },
      "suggestedAggregationOperator": {
        "topn": {
        }
      }
    },
    "user_labels": {
      "kind": "_map",
      "field": "user_labels",
      "description": "Field user_labels is of kind _map, cardinality request not supported",
      "suggestedAggregationOperator": null,
      "aggregationMeta": null
    }
  }
};
