import {
  ArrayOperation,
  BooleanOperation,
  DateTimeOperation,
  LogicalOperation,
  NumberOperation,
  ObjectOperation,
  StringOperation
} from "./data/types/BaseOperatorTypes";
import { FieldPrimType } from "./data/types/DataTypes";

export const NumberOpMap: Record<string, NumberOperation> = {
  "=": "eq",
  '!=': "neq",
  '<': "lt",
  '<=': "le",
  '>': "gt",
  '>=': "ge",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const StringOpMap: Record<string, StringOperation> = {
  "in": "in",
  "=": "eq",
  '!=': "neq",
  "=~": "regex",
  "startsWith": "startsWith",
  "endsWith": "endsWith",
  "contains": "contains",
  "notContains": "notContains",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const ArrayOpMap: Record<string, ArrayOperation> = {
  "contains": "contains",
  "notContains": "notContains",
  "isEmpty": "isEmpty",
  "isNotEmpty": "isNotEmpty",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const ObjectOpMap: Record<string, ObjectOperation> = {
  "isNull": "isNull",
  "isNotNull": "isNotNull",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const BooleanOpMap: Record<string, BooleanOperation> = {
  "=": "eq",
  '!=': "neq",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const DateTimeOpMap: Record<string, DateTimeOperation> = {
  "=": "eq",
  '<': "lt",
  '>': "gt",
  '<=': "le",
  '>=': "ge",
  "range": "range",
  "exists": "exists",
  "doesNotExist": "doesNotExist"
};

export const LogicalOpMap: Record<string, LogicalOperation> = {
  ...NumberOpMap,
  ...StringOpMap,
  ...ArrayOpMap,
  ...ObjectOpMap,
  ...BooleanOpMap,
  ...DateTimeOpMap
};

export function getLogicalOperation(op: string): LogicalOperation {
  return LogicalOpMap[op];
}

export function getOpMapFromFieldType(fieldType: FieldPrimType) {

  let OpMap = null;

  switch (fieldType) {
    case "_array":
    case "_array_boolean":
    case "_array_date":
    case "_array_dateTime":
    case "_array_double":
    case "_array_long":
    case "_array_str":
    case "_array_map": {
      OpMap = ArrayOpMap;
      break;
    }

    case "_date":
    case "_datetime": {
      OpMap = DateTimeOpMap;
      break;
    }

    case "_str":
    case "_unset": {
      OpMap = StringOpMap;
      break;
    }

    case "_boolean": {
      OpMap = BooleanOpMap;
      break;
    }

    case "_map":
    case "_objectmap": {
      OpMap = ObjectOpMap;
      break;
    }

    case "_long":
    case "_double": {
      OpMap = NumberOpMap;
      break;
    }

    default:
      OpMap = LogicalOpMap;
      break;
  }

  return OpMap;
}

