import { createAction } from "@reduxjs/toolkit";
import timeSrv from "./TimeSrv";
import { RawTimeRange, TimeRangeState } from "./types";

type Action = 'set-time-range' | 'refresh-time-range' | 'set-compare-time-range'
| 'set-show-compare-selector' | 'set-show-time-range-selector' | 'set-selectors-status' | 'set-disable-time-range-selector' | 'set-disable-refresh'
|'set-is-custom-time-range-initialized';

type SelectorsEnabled = [tr: boolean, cTr: boolean];

export interface TimeRangeReducerPayload {
  type: Action;
  payload?: RawTimeRange | undefined | boolean | SelectorsEnabled;
}

const SET_TIME_RANGE: Action = 'set-time-range';
const SET_COMPARE_TIME_RANGE: Action = 'set-compare-time-range';
const REFRESH_TIME_RANGE: Action = 'refresh-time-range';
const SET_COMPARE_ENABLED: Action = 'set-show-compare-selector';
const SET_TIME_RANGE_ENABLED: Action = 'set-show-time-range-selector';
const SET_SELECTORS_ENABLED: Action = 'set-selectors-status';
const SET_TIME_RANGE_DISABLED: Action = 'set-disable-time-range-selector';
const SET_REFRESH_DISABLED: Action = 'set-disable-refresh';
const SET_IS_CUSTOM_TIME_RANGE_INITIALIZED: Action = 'set-is-custom-time-range-initialized';

const setTimeRangeAction = createAction<RawTimeRange>(SET_TIME_RANGE);
const setCompareTimeRangeAction = createAction<RawTimeRange>(SET_COMPARE_TIME_RANGE);
const refreshAction = createAction<undefined>(REFRESH_TIME_RANGE);
const setCompareEnabledAction = createAction<boolean>(SET_COMPARE_ENABLED);
const setTimeRangeEnabledAction = createAction<boolean>(SET_TIME_RANGE_ENABLED);
const setTimeSelectorsEnabledAction = createAction<SelectorsEnabled>(SET_SELECTORS_ENABLED);
const setTimeRangeSelectorDisabledAction = createAction<boolean>(SET_TIME_RANGE_DISABLED);
const setRefreshDisabledAction = createAction<boolean>(SET_REFRESH_DISABLED);
const setIsCustomTimeRangeInitialized = createAction<boolean>(SET_IS_CUSTOM_TIME_RANGE_INITIALIZED);

const timeRangeReducer = (state: TimeRangeState, { type, payload }: TimeRangeReducerPayload): TimeRangeState => {
  const defaultTr = timeSrv.getTimeRange();
  const defaultCTr = timeSrv.getCompareTimeRange();

  state = state || {
    compareTimeRange: defaultCTr,
    timeRange: defaultTr,
    showCompareSelector: false,
    showTimeRangeSelector: false,
    disableTimeRangeSelector: false,
    disableRefresh: false,
    isCustomTimeRangeInitialized:false
  };

  const trPayload = payload as RawTimeRange;
  const boolPayload = payload as boolean;
  const boolArrPayload = payload as SelectorsEnabled;

  switch (type) {

    case SET_TIME_RANGE: {
      timeSrv.setTimeRange(trPayload.from, trPayload.to, trPayload.timeZone);
      const tr = timeSrv.getTimeRange();
      const cTr = timeSrv.getCompareTimeRange();
      return {
        ...state,
        timeRange: {
          ...defaultTr,
          ...tr
        },
        compareTimeRange: {
          ...defaultCTr,
          ...cTr
        }
      };
    }

    case SET_COMPARE_TIME_RANGE: {
      timeSrv.setCompareTimeRange(trPayload.from);
      const ctr = timeSrv.getCompareTimeRange();
      return {
        ...state,
        compareTimeRange: {
          ...defaultCTr,
          ...ctr
        }
      };
    }

    case REFRESH_TIME_RANGE: {
      timeSrv.refresh();
      const newTimeRange = timeSrv.getTimeRange();
      const newCompareTimeRange = timeSrv.getCompareTimeRange();
      return {
        ...state,
        timeRange: {
          ...defaultTr,
          ...newTimeRange
        },
        compareTimeRange: {
          ...defaultCTr,
          ...newCompareTimeRange
        }
      };
    }

    case SET_COMPARE_ENABLED: {
      return {
        ...state,
        showCompareSelector: boolPayload
      };
    }

    case SET_TIME_RANGE_ENABLED: {
      return {
        ...state,
        showTimeRangeSelector: boolPayload
      };
    }

    case SET_TIME_RANGE_DISABLED: {
      return {
        ...state,
        disableTimeRangeSelector: boolPayload
      };
    }

    case SET_IS_CUSTOM_TIME_RANGE_INITIALIZED: {
      return {
        ...state,
        isCustomTimeRangeInitialized: boolPayload
      };
    }

    case SET_REFRESH_DISABLED: {
      return {
        ...state,
        disableRefresh: boolPayload
      };
    }

    case SET_SELECTORS_ENABLED: {
      return {
        ...state,
        showCompareSelector: boolArrPayload[1],
        showTimeRangeSelector: boolArrPayload[0]
      };
    }

    default:
      return state;
  }
};

export {
  setTimeRangeAction,
  refreshAction,
  timeRangeReducer,
  setCompareTimeRangeAction,
  setCompareEnabledAction,
  setTimeRangeEnabledAction,
  setTimeSelectorsEnabledAction,
  setTimeRangeSelectorDisabledAction,
  setRefreshDisabledAction,
  setIsCustomTimeRangeInitialized
};
