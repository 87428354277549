import { Carousel, CarouselProps } from 'antd';
import React, { FC, useMemo } from 'react';
import { ISaxIcon } from '../../icons';

interface Props extends CarouselProps {
}

export const IncCarousel: FC<Props> = (props) => {
  const {
    className: pClassName = '',
    children,
    accessibility = true,
    arrows = true,
    centerPadding = "10px",
    dotPosition = "bottom",
    dots: dotsCustomisation,
    infinite = false,
    nextArrow = <span className="custom-arrow custom-arrow--left">
      <ISaxIcon iconName="ArrowRight2" style={{ color: 'white' }} variant="Outline" />
    </span>,
    prevArrow = <span className="custom-arrow custom-arrow--left">
      <ISaxIcon iconName="ArrowLeft2" style={{ color: 'white' }} variant="Outline" />
    </span>,
    slidesToScroll = 3,
    slidesToShow = 3,
    ...restProps
  } = props;

  const className = `inc-carousel ${pClassName}`;
  const dots = useMemo<CarouselProps['dots']>(() => {
    if (typeof dotsCustomisation === 'boolean') {
      return dotsCustomisation;
    } else if (typeof dotsCustomisation === 'object') {
      return {
        ...(dotsCustomisation || {}),
        className: `custom-dots ${dotsCustomisation?.className}`
      };
    }

    return {
      className: 'custom-dots'
    };
  }, [dotsCustomisation]);

  return <div className={className}>
    <Carousel
      {...restProps}
      accessibility={accessibility}
      arrows={arrows}
      centerPadding={centerPadding}
      dotPosition={dotPosition}
      dots={dots}
      infinite={infinite}
      nextArrow={nextArrow}
      prevArrow={prevArrow}
      slidesToScroll={slidesToScroll}
      slidesToShow={slidesToShow}
    >
      {children}
    </Carousel>
  </div>;
};
