import { map } from "lodash";
import { TimeSeries } from "../../core";
import { WidgetDataDTO, MetricResultData } from "../../services/api/explore";
import { EntityEnricher, EntityEnricherRegistry } from "./types";
import { isEntity } from "./utils";

export const ExploreEntityEnricher =
  (entityEnricherRegistry: EntityEnricherRegistry): EntityEnricher<WidgetDataDTO> => ({
    type: "explore",
    skipEntityDataFetch: true,
    // parser: function (exploreWidgetData: WidgetDataDTO[], isEntity: (s: string) => boolean): string[] {
    //   const entityIdToTagNameMap: Map<string, string> = new Map();
    //   const entityIds: Set<string> = new Set();
    //   exploreWidgetData.forEach(exploreWidgetDatum => {
    //     exploreWidgetDatum.metricResults.forEach((metricResult) => {
    //       const {
    //         compareConfigData,
    //         data
    //       } = metricResult;

    //       const parseTimeSeries = (dataRec: Record<string, Array<TimeSeries | TableModel>>) => {
    //         map(dataRec, tsArr => {
    //           tsArr.forEach(mr => {
    //             const ts = mr as TimeSeries;
    //             ts.eTags = clone(ts.tags);
    //             for (const [key, value] of Object.entries((ts).tags)) {
    //               const val = value as string;
    //               if (isEntity(val)) {
    //                 entityIds.add(val);
    //                 entityIdToTagNameMap.set(val, key);
    //               }
    //             }
    //           });
    //         });
    //       };

    //       parseTimeSeries(data);
    //       parseTimeSeries(compareConfigData);
    //     });
    //   });


    //   // if no entityIds's simply proceed;
    //   if (entityIds.size === 0) {
    //     return [] as string[];
    //   }
    //   return Array.from(entityIds) as string[];
    // },

    parser: function () {
      return [];
    },

    enrich: function (exploreWidgetData: WidgetDataDTO[]): WidgetDataDTO[] {
      exploreWidgetData.forEach(exploreWidgetDatum => {
        exploreWidgetDatum.metricResults.forEach((metricResult) => {
          const {
            compareConfigData,
            data,
            entityLookupData: entityMap = {},
            postAggResult
          } = metricResult;

          const {
            entityLookupData: postAggEntityMap = {},
            data: postAggData = {},
            percentageChangeData: postAggPercentChangeData = {},
            timeShiftedData: postAggTimeShiftData = {}
          } = postAggResult || {};

          const mergedEntityMap = {
            ...entityMap,
            ...postAggEntityMap
          };

          entityEnricherRegistry.addToEntityToCache(mergedEntityMap);

          const entityIds = Object.keys(mergedEntityMap);

          const enrichTimeSeries = (dataRec: Record<string, MetricResultData>) => {
            map(dataRec, dRec => {
              dRec.data.forEach(mr => {
                const ts = mr as TimeSeries;
                const { tags = {} } = ts;

                ts.eTags = { ...tags };
                for (const [key, value] of Object.entries(tags)) {
                  const val = value as string;
                  if (entityIds.includes(val) && isEntity(val)) {
                    const eName = mergedEntityMap[val];
                    ts.eTags[key] = eName || val;
                  }
                }
              });
            });
          };

          enrichTimeSeries(data);
          enrichTimeSeries(compareConfigData);
          enrichTimeSeries(postAggData);
          enrichTimeSeries(postAggPercentChangeData);
          enrichTimeSeries(postAggTimeShiftData);
        });
      });

      return exploreWidgetData;
    }
  });
