import { CompareQuerySchemaResponse } from "../../services/api/explore";
import { shouldExcludeTag } from "../../core";

export const getSchemaCombinations = (schemaResponse: CompareQuerySchemaResponse) => {
  const validCombinations = new Set<string>();
  schemaResponse?.resultSchema?.forEach(schema => {
    let combinationStr = '';

    const sortedKeys = Object.keys(schema)
      .filter(tag => !shouldExcludeTag(tag, true))
      .sort();

    sortedKeys.forEach(key => {
      const value = schema[key];
      combinationStr += `${key}:${value}`;
    });
    validCombinations.add(combinationStr);
  });
  return validCombinations;
};
