import Token from './Token';

const ALPHA_NUMERIC_EXP = new RegExp(/^[a-z0-9?($|-|_|.|:|)]+$/i);
const SPACE_EXP = new RegExp(/\s$/);

const TokenType = {
  EQ: 'OPERATOR',
  NOT_EQ: 'OPERATOR',
  KEY: 'KEY',
  LONG: 'VALUE',
  STRING: 'VALUE',
  REGEX: 'OPERATOR',
  INVERSE_REGEX: 'OPERATOR',
  GT: 'OPERATOR',
  GT_EQ: 'OPERATOR',
  LT: 'OPERATOR',
  LT_EQ: 'OPERATOR',
  AND: 'CONDITION',
  OR: 'CONDITION',
  LPAREN: 'VALUE',
  RPAREN: 'VALUE',
  COMMA: 'VALUE',
  NOT: 'OPERATOR',
  IN: 'OPERATOR',
  LBRACKET: 'VALUE',
  RBRACKET: 'VALUE',
  TRUE: 'VALUE',
  FALSE: 'VALUE',
  END_OF_FILE: 'END_OF_FILE',
  ILLEGAL: 'ILLEGAL',
};

class Tokenize {
  private expression: string;
  private currentOffset: number;
  private readOffset: number;
  private allTokens: any = [];
  ch: string;

  constructor (expression: string) {
    this.expression = expression.trim();
    this.currentOffset = 0;
    this.ch = this.expression[0];
    this.readOffset = 1;
    this.getAllTokens();
  }

  getExpression() {
    return this.expression;
  }

  private isAlphaNumeric(c: string) {
    return ALPHA_NUMERIC_EXP.test(c);
  }

  getCurrentOffset() {
    return this.currentOffset;
  }

  private isNumber(num: any) {
    return !isNaN(num) && Number(num) === parseInt(num, 10);
  }

  private createToken(tokenType: string, value: any, startPosition: number, endPosition: number) {
    return new Token({ tokenType,
      value,
      startPosition,
      endPosition });
  }

  getAllTokens() {
    while (this.currentOffset < this.expression.length) {
      const token = this.nextToken();

      this.allTokens.push(token);
    }
    return this.allTokens;
  }

  getTokenAtCursorPosition(pos: number) {
    const tokens = [ ...this.allTokens ].filter(token => token);
    let lastToken = null;

    for (let i = 0, len = tokens.length; i < len; i += 1) {
      const token = tokens[i];
      const { endPosition } = token;

      if (endPosition <= pos ) {
        lastToken =  token;
      }
    }
    return lastToken;
  }

  getPreviousTokenAtCursorPosition(pos: number) {
    const tokens = [ ...this.allTokens ].filter(token => token);
    let lastToken = null;

    for (let i = 0, len = tokens.length; i < len; i += 1) {
      const token = tokens[i];
      const { endPosition } = token;

      if (endPosition <= pos && i === len - 2) {
        lastToken =  token;
        break;
      }
    }
    return lastToken;
  }

  nextToken() {
    this.removeWhiteSpace();

    if (this.currentOffset === this.expression.length) {
      return this.createToken(TokenType.END_OF_FILE, '', this.currentOffset, this.currentOffset);
    }
    switch (this.ch) {
      case '!': {
        const startPos = this.currentOffset;
        if (this.expression[this.readOffset] === '=') {
          this.readCurrentChar();
          this.readCurrentChar();
          const token = this.createToken(TokenType.NOT_EQ, '!=', startPos, this.currentOffset);

          return token;
        } else if (this.expression[this.readOffset] === '~') {
          this.readCurrentChar();
          this.readCurrentChar();
          const token = this.createToken(TokenType.INVERSE_REGEX, '!~', startPos, this.currentOffset);

          return token;
        } else {
          this.readCurrentChar();
          const token = this.createToken(TokenType.ILLEGAL, '!', startPos, this.currentOffset);

          return token;
        }
      }
      case '=': {
        const startPos = this.currentOffset;
        if (this.expression[this.readOffset] === '~') {
          this.readCurrentChar();
          this.readCurrentChar();
          const token = this.createToken(TokenType.REGEX, '=~', startPos, this.currentOffset);

          return token;
        } else if (this.expression[this.readOffset] === '=') {
          this.readCurrentChar();
          this.readCurrentChar();

          const token = this.createToken(TokenType.EQ, '==', startPos, this.currentOffset);

          return token;
        }
        this.readCurrentChar();
        const token = this.createToken(TokenType.EQ, '=', startPos, this.currentOffset);

        return token;
      }
      case '>': {
        const startPos = this.currentOffset;
        if (this.expression[this.readOffset] === '=') {
          this.readCurrentChar();
          this.readCurrentChar();

          const token = this.createToken(TokenType.GT_EQ, '>=', startPos, this.currentOffset);
          return token;
        }
        this.readCurrentChar();
        const token = this.createToken(TokenType.GT, '>', startPos, this.currentOffset);

        return token;
      }
      case '<': {
        const startPos = this.currentOffset;
        if (this.expression[this.readOffset] === '=') {
          this.readCurrentChar();
          this.readCurrentChar();
          const token = this.createToken(TokenType.LT_EQ, '<=', startPos, this.readOffset);
          return token;
        }
        this.readCurrentChar();

        const token = this.createToken(TokenType.LT, '<', startPos, this.readOffset);
        return token;
      }
      case '(': {
        this.readCurrentChar();
        const token = this.createToken(TokenType.LPAREN, '(', this.currentOffset, this.readOffset);

        return token;
      }
      case ')': {
        this.readCurrentChar();
        const token = this.createToken(TokenType.RPAREN, ')', this.currentOffset, this.readOffset);
        return token;
      }
      case '[': {
        this.readCurrentChar();
        const token = this.createToken(TokenType.LBRACKET, '[', this.currentOffset, this.readOffset);
        return token;
      }
      case ']': {
        this.readCurrentChar();
        const token = this.createToken(TokenType.RBRACKET, ']', this.currentOffset, this.readOffset);
        return token;
      }
      case '"':
      case '\'': {
        const delim = this.ch;
        const startPos = this.currentOffset;
        this.readCurrentChar();
        const ident = this.readString(delim);

        if (ident) {
          const token = this.createToken(TokenType.STRING, ident, startPos, this.currentOffset);

          return token;
        }
        return;
      }
      case ',': {
        const startPos = this.currentOffset;
        this.readCurrentChar();
        const token = this.createToken(TokenType.COMMA, ',', startPos, this.currentOffset);

        return token;
      }
      default: {
        const startPos = this.currentOffset;
        if (this.isAlphaNumeric(this.ch)) {
          const key = this.readKey();
          const tokenType = this.getTokenType(key);
          const token = this.createToken(tokenType, key, startPos, this.currentOffset);

          return token;
        }
        this.readCurrentChar();
        return this.createToken(TokenType.ILLEGAL, null, startPos, this.currentOffset);
      }
    }
  }

  private getTokenType(value: string) {
    const upperCaseValue = value.toUpperCase();
    switch (upperCaseValue) {
      case "AND":
        return TokenType.AND;
      case "OR":
        return TokenType.OR;
      case "NOT":
        return TokenType.NOT;
      case "IN":
        return TokenType.IN;
      case "TRUE":
        return TokenType.TRUE;
      case "FALSE":
        return TokenType.FALSE;
    }
    if (this.isNumber(value)) {
      return TokenType.LONG;
    }
    return TokenType.KEY;
  }

  private readString(delim: string) {
    const startPos = this.currentOffset;

    this.currentOffset++;
    while (this.currentOffset < this.expression.length && this.expression[this.currentOffset] !== delim) {
      this.currentOffset++;
    }
    if (this.currentOffset >= this.expression.length || this.expression[this.currentOffset] !== delim) {
      return;
    }
    const ident = this.expression.substring(startPos, this.currentOffset);

    this.currentOffset++;
    this.readOffset = this.currentOffset + 1;
    if (this.currentOffset < this.expression.length) {
      this.ch = this.expression[this.currentOffset];
    }
    return ident;
  }

  private readKey() {
    const startPos = this.currentOffset;
    while (this.currentOffset < this.expression.length && this.isAlphaNumeric(this.expression[this.currentOffset])) {
      this.currentOffset++;
    }
    const identifier: string = this.expression.substring(startPos, this.currentOffset);

    this.readOffset = this.currentOffset + 1;
    this.ch = this.currentOffset < this.expression.length ? this.expression[this.currentOffset] : '';
    return identifier;
  }

  private readCurrentChar() {
    if (this.currentOffset < this.expression.length - 1) {
      this.currentOffset = this.readOffset;
      this.ch = this.expression[this.currentOffset];
      this.readOffset++;
    } else {
      this.currentOffset = this.expression.length;
    }
  }

  private removeWhiteSpace() {
    while (SPACE_EXP.test(this.ch)) {
      this.readCurrentChar();
    }
  }
}

export default Tokenize;
