import { EntityPropertyValue as PropertyValue, PrimKind } from "../../../../../core";
import { OpCreationConfig } from "../OperationaliseV2Types";
import type { AlertStatus, SubSegment, Action, ObjectType, AlertSnapshot } from "./alerts";

export const protobufPackage = "models.alerts";

export enum DependencyType {
  "dependency_type_unset" = "dependency_type_unset",
  mandatory = "mandatory",
  optional = "optional",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum FieldViewSelection {
  "view_selection_unset" = "view_selection_unset",
  primary = "primary",
  secondary = "secondary",
  hidden = "hidden",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum UIMode {
  "filter_UI__mode_unset" = "filter_UI__mode_unset",
  text = "text",
  "colour_bar" = "colour_bar",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum FilterDataSource {
  "datasource_unset" = "datasource_unset",
  INLINE = "INLINE",
  REMOTE = "REMOTE",
  UNRECOGNIZED = "UNRECOGNIZED",
}
export interface AlertEventRecord {
  id: string;
  subSegment: SubSegment;
  status: AlertStatus;
  entityType: string;
  startTimeMillis: number;
  endTimeMillis: number;
  duration: number;
  opConfigId: string;
  entityIds: string[];
  eventTypeIds: string[];
  alertParams: Record<string, PropertyValue>;
  additionalData: Array<Record<string, string>>;
  createdAt: number;
  lastUpdatedAt: number;
  actions: Action[];
  lookupData: Record<string, string>;
  disabled: boolean;
  snapshot?: {
    snapshot: AlertSnapshot[];
  };
}

export interface AlertEventRecordAlertParamsEntry {
  key: string;
  value?: PropertyValue;
}

export interface AlertEventRecordLookupDataEntry {
  key: string;
  value: string;
}

export interface AlertEventResponse {
  alertEventRecords: AlertEventRecord[];
}

export interface AlertFilterResponse {
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  alertResponse?: AlertEventResponse;
  alertMetadata?: AlertMetadata;
  countResponse: AlertCountResponseEntry[];
}

export interface AlertFilterResponseEntry {
  alertFilterResponse: AlertFilterResponse;
  errorMessage: string;
}

export interface AlertFilterResponses {
  alertFilterResponses: Record<string, AlertFilterResponseEntry>;
}

export interface AlertMetadata {
  /** Field Definition */
  fieldsData?: AlertFieldsData;
  /** Grid Column Definition */
  gridMetadata?: GridMetadata;
  /** Fixed Filter Panel Definition */
  fixedFilterPanel?: FilterPanel;
  /** Custom Filter Panel Definition */
  customFilterPanel?: FilterPanel;
}

export interface AlertFieldsData {
  fieldInfo: FieldsInfo[];
}

export interface FieldsInfo {
  /**  */
  fieldId: string;
  displayName: string;
  primKind?: PrimKind | undefined;
  objectType?:
  | ObjectType
  | undefined;
  /** will be either data subtype or object subtype */
  subType: string;
  isArray: boolean;
  filterable: boolean;
  sortable: boolean;
  jsonPath: string;
}

export interface GridMetadata {
  columnDef: ColumnDef[];
}

export interface ColumnDef {
  fieldId: string;
  /** Starting from 1 */
  viewOrder: number;
  /** Render mode [text or color bar] */
  uiMode: UIMode;
  /** [Primary, Secondary [show in Column Selector], Hidden] */
  viewSelection: FieldViewSelection;
}

export interface FilterPanel {
  filterPanelElement: FilterPanelElement[];
}

export interface FilterPanelElement {
  fieldId: string;
  displayName: string;
  primKind?: PrimKind | undefined;
  objectType?: ObjectType | undefined;
  subType: string;
  entityType: string;
  multiSelect: boolean;
  /** Starting from 1 [only applicable for Fixed Panel] */
  viewOrder: number;
  filterDependencyFields: Map<string, DependencyType>;
  /** Datasource */
  dataSource: FilterDataSource;
  /** Field Values for Filter Pane if DATA SOURCE IS INLINE */
  valueDistribution: ValueDistribution[];
}

export interface FilterPanelElementFilterDependencyFieldsEntry {
  key: string;
  value: DependencyType;
}

export interface ValueDistribution {
  id: string;
  value?: PropertyValue;
  /** Needed for fields included in Filter Panel with render mode as color bars */
  count: number;
}

export interface AlertHistogramData {
  histogram: number[];
  bins: number[];
  binSize: number;
}

export interface AlertCountResponseEntry {
  primaryGroupBy: string;
  secondaryGroupBy?: string | null;
  groupBy: {
    startTimeMillis: string;
    endTimeMillis: string;
  };
  count: number;
  url: string;
}

export interface AlertCountResponse {
  count: AlertCountResponseEntry[];
}

export interface AlertCountResponsesEntry {
  alertCountResponse: AlertCountResponse;
  errorMessage: string;
}

export interface AlertCountResponses {
  alertCountResponses: Record<string, AlertCountResponsesEntry>;
}

export interface AlertsRequestPayload {
  opIds?: string[];
  opCreationConfig?: OpCreationConfig;
}
