import React, { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { formatNumber } from '../../..';
import { getInceptionTheme } from '../../../themes/ThemeProvider';
import { IncSimpleBarData } from './SimpleBarChart';

interface IncSimpleHorizontalBarChartProps {
  data: IncSimpleBarData[];
  onClick?: (data: IncSimpleBarData) => void;
}

const IncSimpleHorizontalBarChart: React.FC<IncSimpleHorizontalBarChartProps> = ((props) => {
  const [data, setData] = useState<IncSimpleBarData[]>([]);
  const [total, setTotal] = useState<number>(0);

  // This is calculated based on what is the with of the component which wraps IncSimpleBar
  const [maxWidth, setMaxWidth] = useState<number>(0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (props.data && props.data.length > 0) {

      // Get the max value
      const values: number[] = props.data.map(d => d.value);
      setTotal(values.reduce((prev, val) => prev + val, 0));

      // clone props.data and sort it in descending order
      const clonedPropsData = Object.assign([], props.data);
      clonedPropsData.sort(comparator);
      const colors = getInceptionTheme().chartColors;
      clonedPropsData.forEach((x,i) => {
        if (!(x as IncSimpleBarData).color) {
          (x as IncSimpleBarData).color = colors[i];
        }
      });
      const filteredData = (clonedPropsData as IncSimpleBarData[]).filter(x => x.value > 0);
      setData(filteredData);

      // Get the parent container's width
      if (wrapperRef && wrapperRef.current && wrapperRef.current.parentNode) {
        const parentWidth = (wrapperRef.current.parentNode as Element).clientWidth;
        setMaxWidth(parentWidth);
      } else {
        console.log("SimpleBarChart: Unable to get parent width");
      }
    }
  }, [props.data]);

  const bars = useMemo(() => data.map(d => {
    if (data) {
      const barWidth = d.value / total * 100;
      return (
        <div className="bar" key={d.name} style={{ width: `${barWidth}%`,
          backgroundColor: d.color }}
        title={`${d.name} (${formatNumber(d.value)})`} />
      );
    }
    return null;
  }), [data, total]);

  const legends = useMemo(() => data.map(d => {
    if (data) {
      return (
        <div className="legend" key={d.name}>
          <div className="color-box" style={{ backgroundColor: d.color }} />
          <span className='label' title={`${d.name} (${formatNumber(d.value)})`}>{d.name} ({formatNumber(d.value)})</span>
        </div>
      );
    }
    return null;
  }), [data]);

  return (
    <div
      className="inc-simple-horizontal-bar-chart"
      ref={wrapperRef}
      style={{ paddingTop: 24,
        width: maxWidth }}
    >
      <div className="bars-container">{bars}</div>
      <div className="legends-container marginTp24">{legends}</div>
    </div>
  );
});

const comparator = (a: IncSimpleBarData, b: IncSimpleBarData) => a.value > b.value ? -1 : 1;


export default IncSimpleHorizontalBarChart;
