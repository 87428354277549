import appConfig from "../../../../appConfig";
import { Org } from "../../../core";
import { request } from "../request";

interface CurrentOrg {
  id: number;
  name: string;
}

interface ResponseMessage {
  message: string;
}

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

class ProfileApiService {
  async getCurrentOrg(): Promise<CurrentOrg> {
    const url = appConfig.basePath + "/api/org";
    const response = await request.get<CurrentOrg>(url);

    return response.data;
  }

  async setCurrentOrg(orgId: number): Promise<Result<ResponseMessage>> {
    const url = appConfig.basePath + `/api/user/using/${orgId}`;
    const result: Result<ResponseMessage> = {
      data: { message: '' },
      error: false,
      message: ''
    };

    try {
      const response = await request.post<ResponseMessage, any>(url);
      result.data = response.data;
      result.message = result.data.message;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async getUserOrgs(): Promise<Result<Org[]>> {
    const result: Result<Org[]> = {
      data: [],
      error: false,
      message: ''
    };
    const url = appConfig.basePath + "/api/user/orgs";

    try {
      const response = await request.get<Org[]>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }
}

const profileApiService = new ProfileApiService();

export default profileApiService;
