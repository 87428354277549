
/* tslint:disable:import-blacklist ban ban-types */
// import moment, { DurationInputArg1, Moment, MomentInput } from 'moment';
import { BROWSER_TIME_ZONE } from '@inception/ui';
import moment, {Moment, MomentInput, DurationInputArg1} from 'moment-timezone';

export interface DateTimeBuiltinFormat {
  __momentBuiltinFormatBrand: any;
}

export type TimeZoneUtc = 'utc';
export type TimeZoneBrowser = 'browser';
export type TimeZone = TimeZoneBrowser | TimeZoneUtc | string;
export const ISO_8601: DateTimeBuiltinFormat = moment.ISO_8601;
export type DateTimeInput = Date | string | number | Array<string | number> | DateTime; // null | undefined
export type FormatInput = string | DateTimeBuiltinFormat | undefined;
export type DurationInput = string | number | DateTimeDuration;
export type DurationUnit =
    | 'year'
    | 'years'
    | 'y'
    | 'month'
    | 'months'
    | 'M'
    | 'week'
    | 'weeks'
    | 'w'
    | 'day'
    | 'days'
    | 'd'
    | 'hour'
    | 'hours'
    | 'h'
    | 'minute'
    | 'minutes'
    | 'm'
    | 'second'
    | 'seconds'
    | 's'
    | 'millisecond'
    | 'milliseconds'
    | 'ms'
    | 'quarter'
    | 'quarters'
    | 'Q';

export interface DateTimeLocale {
  firstDayOfWeek: () => number;
}

export interface DateTimeDuration {
  asHours: () => number;
  hours: () => number;
  minutes: () => number;
  seconds: () => number;
  asSeconds: () => number;
  humanize: () => string;
  as: (scale: string) => number;
}

export interface DateTime extends Object {
  add: (amount?: DateTimeInput, unit?: DurationUnit) => DateTime;
  set: (unit: DurationUnit, amount: DateTimeInput) => void;
  diff: (amount: DateTimeInput, unit?: DurationUnit, truncate?: boolean) => number;
  endOf: (unitOfTime: DurationUnit) => DateTime;
  format: (formatInput?: FormatInput) => string;
  fromNow: (withoutSuffix?: boolean) => string;
  from: (formaInput: DateTimeInput, withoutSuffix?: boolean) => string;
  isSame: (input?: DateTimeInput, granularity?: DurationUnit) => boolean;
  isValid: () => boolean;
  local: () => DateTime;
  locale: (locale: string) => DateTime;
  startOf: (unitOfTime: DurationUnit) => DateTime;
  subtract: (amount?: DateTimeInput, unit?: DurationUnit) => DateTime;
  toDate: () => Date;
  toISOString: () => string;
  isoWeekday: (day?: number | string) => number | string;
  valueOf: () => number;
  unix: () => number;
  utc: () => DateTime;
  utcOffset: () => number;
  clone: () => DateTime;
  //getters
  hour?: () => number;
  minute?: () => number;
  second?: () => number;
  millisecond?: () => number;
  month?: () => number;
  date?: () => number;
  //setters
  hours?: (h: number) => DateTime;
  minutes?: (m: number) => DateTime;
  seconds?: (s: number) => DateTime;
  milliseconds?: (ms: number) => DateTime;
}

export const setLocale = (language: string) => {
  moment.locale(language);
};

export const getLocaleData = (): DateTimeLocale => moment.localeData();

export const isDateTime = (value: any): value is DateTime => moment.isMoment(value);

export const toUtc = (input?: DateTimeInput, formatInput?: FormatInput): DateTime => moment.utc(input as MomentInput, formatInput) as DateTime;

export const toDuration = (input?: DurationInput, unit?: DurationUnit): DateTimeDuration =>
  moment.duration(input as DurationInputArg1, unit) as DateTimeDuration;

export const dateTime =
  (input?: string | DateTimeInput, timeZone?: TimeZone, formatInput?: FormatInput): DateTime => (timeZone && timeZone !== BROWSER_TIME_ZONE) ?
    typeof input === 'string'?  moment.tz(input, formatInput,timeZone) as DateTime:
      moment.tz(input as MomentInput, timeZone) as DateTime: moment(input as MomentInput, formatInput) as DateTime;

export const dateTimeAsMoment = (input?: DateTimeInput) => dateTime(input) as Moment;

export const dateTimeForTimeZone = (
  timezone?: TimeZone,
  input?: DateTimeInput,
  formatInput?: FormatInput
): DateTime => {
  if (timezone === 'utc') {
    return toUtc(input, formatInput);
  }
  return dateTime(input, timezone);
};
